// Convert a value to a US dollar amount string
export const amountFormat = (value: string | number): string => {
    const numericValue =
        typeof value === "string" ?
            // Remove non-numeric characters
            parseFloat(value.replace(/\D/g, "")) :
            // Backend amounts are stored as pennies, so divide by 100 to get dollars
            value / 100;

    // Return an empty string if the value is not a valid number
    if (Number.isNaN(numericValue)) {
        return "";
    }

    const dollarAmount = numericValue.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    return dollarAmount;
};

// Removes US dollar formatting from a string and returns the value in pennies
export const removeDollarFormatting = (dollarAmount: string) => {
    const amount = dollarAmount.replace(/\D/g, '');
    return Number(amount) * 100;
}

const monthToNumber = {
    "Jan": "01",
    "Feb": "02",
    "Mar": "03",
    "Apr": "04",
    "May": "05",
    "Jun": "06",
    "Jul": "07",
    "Aug": "08",
    "Sep": "09",
    "Oct": "10",
    "Nov": "11",
    "Dec": "12",
};

export const epochToFormattedDateString = (epochDate: number, includedDay: boolean = false) => {
    const utcDate = new Date(Number(epochDate) * 1000).toUTCString();
    const splitDate = utcDate.split(" ");
    const mmddyyy = `${monthToNumber[splitDate[2]]}/${splitDate[1]}/${splitDate[3]}`;
    const mmyyyy = `${monthToNumber[splitDate[2]]}/${splitDate[3]}`;
    return includedDay ? mmddyyy : mmyyyy;
};

