import styles from "./styles.module.scss"

export const CustomBackground = () => {
    return (
        <div className={styles.bgContainer}>
            <div className={styles.bgTop}/>
            <div className={styles.bgMiddle}/>
            <div className={styles.bgBottom}/>
        </div>
    );
};
