 import {
    AddressFieldProps,
    BackendValue,
    FormFieldProps,
    FormProps,
    Forms,
    RadioButtonFormProps,
    RadioButtonProps,
    ToggleSwitchProps,
} from "../../../../types/form-types";
import {
    assignErrorMessagesAndReturnNumErrors,
    getBackendValuesForForm,
    specificAdjustmentsPreUnflatteningFormBody,
} from "./utils";
import {
    flattenDeepObject,
    unflattenObject,
    mergeObjectsFromArray
} from "../../../general-utils";
import { College } from "../../../../types/sparrow-types";

const getBackendFormBody = (formFields: (RadioButtonProps | FormFieldProps)[]) => {
    // For each form field, get the value that has been formatted such that the backend will accept it
    const formattedBackendValues = getBackendValuesForForm(formFields);
    // Further flatten form fields where the associated value is an object. E.g. permanentAddress or fields where there is additional information inferred from the input value, such as the country code for a phone number
    const flattenedBackendFormBody = flattenDeepObject(formattedBackendValues);
    // Make any special adjustments by mutating the form body before creating the final, nested object
    specificAdjustmentsPreUnflatteningFormBody(flattenedBackendFormBody);

    return unflattenObject(flattenedBackendFormBody as any);
}

// Case forms contain references
const handleReferenceForms = (formattedForms: { [key: string]: BackendValue }, forms: Forms) => {
    const referenceForms = forms.filter(form => form.formType?.includes("references"));
    if (!referenceForms.length) return formattedForms;
    const backendFormattedReferenceForms = referenceForms.map(form => {
        return getBackendFormBody((form as FormProps).independentFields);
    });

    return { ...formattedForms, references: backendFormattedReferenceForms };
}

// Case forms contain mailing address
const handleMailingAddress = (formattedForms: { [key: string]: BackendValue }, forms: Forms) => {
    // Find the form that contains the permanent address field
    const formWithPermanentAddress = forms.find(form => form.formType?.includes("permanentAddress"));
    if (!formWithPermanentAddress) return formattedForms;
    // Find the permanent address field
    const permanentAddressField = (formWithPermanentAddress as FormProps).independentFields.find(field => field.name === "permanentAddress");
    // If the permanent address field does not exist or does not ask for mailing address, then return the formatted forms   
    if (!permanentAddressField || !((permanentAddressField as AddressFieldProps).asksForMailingAddress)) return formattedForms;
    // Will return both forms, the form with the permanent address and the form with the mailing address
    const backendFormattedForms = forms.map(form => {
        return getBackendFormBody((form as FormProps).independentFields);
    });

    return { ...formattedForms, ...mergeObjectsFromArray(backendFormattedForms) };
}
// TODO: Review single and multiform logic to consolidate logic 
const handleAllForms = (formattedForms: { [key: string]: BackendValue }, forms: Forms) => {
    const consolidatedForms = forms.reduce((acc: FormFieldProps[], form) => {
        // Check if the form type is "radioButtons"; if so, skip this form.
        if (form.formType === "radioButtons") {
            // You can add a comment here to clarify the logic.
            return acc;
        }
    
        // Spread the independentFields array into the accumulator.
        return [...acc, ...form.independentFields];
    }, []);
    

    return {...formattedForms, ...getBackendFormBody(consolidatedForms)};
    
} 

const handleRadioButtons = (forms:  RadioButtonFormProps[], toggleSwitchFields?: ToggleSwitchProps[]) => {
    const {radioButtonOptions} = forms[0];
    const checkedRadioButton = radioButtonOptions.find(radioButton => radioButton.checked) as RadioButtonProps;
    // If the checked radio button does not have independent fields, then we can just return button itself which will have name and inputValue
    let independentFields: (FormFieldProps | RadioButtonProps)[] = [];
    if(checkedRadioButton.independentFields){
        const checkedButtonWithDefault = checkedRadioButton.defaultButtonValue ? [checkedRadioButton] : [];
        independentFields = [...checkedRadioButton.independentFields, ...checkedButtonWithDefault];
    } else{
        independentFields = [checkedRadioButton];
    }

    return getBackendFormBody([...independentFields, ...toggleSwitchFields ?? []]);
}

const formatFormsForBackend = (
    forms: (RadioButtonFormProps | FormProps)[],
    toggleSwitchFields?: ToggleSwitchProps[]
) => {
    // If there is only one form, then we can just return the backend formatted form body
    if (forms.length === 1) {
        if(forms[0].formType === "radioButtons"){
            return handleRadioButtons(forms as RadioButtonFormProps[], toggleSwitchFields);
        }

        return getBackendFormBody([...forms[0].independentFields, ...toggleSwitchFields ?? []]);
    }
    // If there are multiple forms, then we need to handle the case where there are reference forms
    let formattedForms = {};
    formattedForms = handleReferenceForms(formattedForms, forms);
    formattedForms = handleMailingAddress(formattedForms, forms);
    formattedForms = handleAllForms(formattedForms, forms);
    return { ...formattedForms, ...toggleSwitchFields ? getBackendFormBody(toggleSwitchFields) : [] };

};


export const handleSubmission = (inputs: {
  forms: Forms;
  setForms: React.Dispatch<React.SetStateAction<Forms>>;
  toggleSwitchFields?: ToggleSwitchProps[];
  checkEditsMadePostPrequalSubmission?: (currentForm: { [key: string]: any }) => void;
},
submissionCallback: (backendFormBody: {}) => void
) => {
    const { forms, setForms, toggleSwitchFields, checkEditsMadePostPrequalSubmission } = inputs;

    const [numErrors, updatedFormFields] = assignErrorMessagesAndReturnNumErrors(forms);
    
    if (numErrors) {
        return setForms([...updatedFormFields]);
    }

    const formattedBackendForms = formatFormsForBackend(
        forms,
        (toggleSwitchFields ?? []),
    );


    if("degrees" in formattedBackendForms){
        const degrees = formattedBackendForms.degrees as College;
        formattedBackendForms.degrees = [degrees]
    }

    // Remove ssn field if resubmitting without changing SSN
    if("ssn" in formattedBackendForms && formattedBackendForms.ssn === ""){
        delete formattedBackendForms.ssn;
    }

    checkEditsMadePostPrequalSubmission?.(formattedBackendForms as { [key: string]: any });
    
    submissionCallback(formattedBackendForms);
};
