const styles = {
    header: {
        fontWeight: 600,
        fontSize: "20px",
        lineHeight: "28px",
    },
    subheader: {
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "20px",
        marginTop: "8px",
    },
}

export const LendersHeader = ({image, header, subheader}) => {
    return (
        <div>
            <div style={{ display: "flex", gap: "8px", alignItems: "flex-start" }}>
                <img src={image} alt="" />
                <p style={styles.header}>{header}</p>
            </div>
            <p style={styles.subheader}>{subheader}</p>
        </div>
    )
}
