import { RadioButtons } from "../../common/components/RadioButtons/RadioButtons";
import { Button } from "../../common/components/buttons/button/Button";
import { ButtonTypes } from "../../common/components/buttons/button/Button";
import { useState } from "react";
import { useOnboarding } from "../../common/core/providers/onboarding.provider";
import { UserType } from "../../common/constants/defaultInputs";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { IQuizPutInput } from "../../common/core/services/types/offers-api-types";
import { useStore } from "../../common/core/providers/global.provider";
import { errorLogs } from "../../common/utils/global";
import { PostQuizAnswer } from "../../common/core/services/sparrow-api-handlers/offers-api-handlers";

// active icons
import timeIconActive from "../../assets/icons/lottie-icons/timerSpin.json";
import compareBarsIconActive from "../../assets/icons/lottie-icons/compareBars.json";
import walletIconActive from "../../assets/icons/lottie-icons/credit-card-(payment).json";
import creditCardIconActive from "../../assets/icons/lottie-icons/credit-card-swipe-(credit history).json";

// static icons
import { OffersTablePreferences } from "../../sparrow-ui-library/src/types/sparrow-types";
import ContentLayout from "../../common/components/layouts/content-layout";
import { isMarketplaceEnv } from "../../common/utils/config";
import { CompareBarsIcon } from "../../sparrow-ui-library/src/assets/icons/rates-preferences-icons/compare-bars-icon/CompareBarsIcon";
import { TimerIcon } from "../../sparrow-ui-library/src/assets/icons/rates-preferences-icons/timer-icon/TimerIcon";
import { WalletIcon } from "../../sparrow-ui-library/src/assets/icons/rates-preferences-icons/wallet-icon/WalletIcon";
import { CreditCardIcon } from "../../sparrow-ui-library/src/assets/icons/rates-preferences-icons/credit-card-icon/CreditCardIcon";

export type radioOption = {
    id: string;
    name: string;
    isChecked: boolean;
    value: { title: string; subTitle?: string };
    icon?: any;
    activeIcon?: any;
    isDefault?: boolean;
};

export const radioPSLButtonOptions = (userType: UserType): radioOption[] => {
    const isPAAAS = !isMarketplaceEnv();

    return [
        {
            id: "lowest-total-cost",
            name: OffersTablePreferences.LOWEST_TOTAL_COST,
            isChecked: true,
            value: {
                title: "Save as much as possible",
                subTitle: "Show me pre-qualified rates with the lowest total cost",
            },
            icon: () => <CompareBarsIcon userType={userType} isPAAAS={isPAAAS} />,
            activeIcon: compareBarsIconActive,
            isDefault: true,
        },
        {
            id: "get-out-of-debt",
            name: OffersTablePreferences.SHORTEST_TERM,
            isChecked: false,
            value: {
                title: "Get out of debt ASAP",
                subTitle: "Show me pre-qualified rates with the shortest terms",
            },
            icon: () => <TimerIcon userType={userType} isPAAAS={isPAAAS} />,
            activeIcon: timeIconActive,
        },
        {
            id: "keep-in-shool-payments-low",
            name: OffersTablePreferences.LOWEST_IN_SCHOOL,
            isChecked: false,
            value: {
                title: "Keep in school payments low",
                subTitle: "Show me pre-qualified rates with the lowest monthly in school payments",
            },
            icon: () => <WalletIcon userType={userType} isPAAAS={isPAAAS} />,

            activeIcon: walletIconActive,
        },
        {
            id: "keep-after-school-payments-low",
            name: OffersTablePreferences.LOWEST_MONTHLY_PAYMENT,
            isChecked: false,
            value: {
                title: "Keep after school payments low",
                subTitle:
                    "Show me pre-qualified rates with the lowest monthly after school payments",
            },
            icon: () => <CreditCardIcon userType={userType} isPAAAS={isPAAAS} />,
            activeIcon: creditCardIconActive,
        },
    ] as radioOption[];
};

export const radioSLRButtonOptions = (userType: UserType): radioOption[] => {
    const isPAAAS = !isMarketplaceEnv();

    return [
        {
            id: "lowest-total-cost",
            name: OffersTablePreferences.LOWEST_TOTAL_COST,
            isChecked: true,
            value: {
                title: "Save as much as possible",
                subTitle: "Show me pre-qualified rates with the lowest total cost",
            },
            icon: () => <CompareBarsIcon userType={userType} isPAAAS={isPAAAS} />,
            activeIcon: compareBarsIconActive,
            isDefault: true,
        },
        {
            id: "get-out-of-debt",
            name: OffersTablePreferences.SHORTEST_TERM,
            isChecked: false,
            value: {
                title: "Get out of debt ASAP",
                subTitle: "Show me pre-qualified rates with the shortest terms",
            },
            icon: () => <TimerIcon userType={userType} isPAAAS={isPAAAS} />,
            activeIcon: timeIconActive,
        },
        {
            id: "keep-payments-low",
            name: OffersTablePreferences.LOWEST_MONTHLY_PAYMENT,
            isChecked: false,
            value: {
                title: "Keep payments low",
                subTitle: "Show me pre-qualified rates with the lowest monthly payments",
            },
            icon: () => <CreditCardIcon userType={userType} isPAAAS={isPAAAS} />,
            activeIcon: creditCardIconActive,
        },
    ] as radioOption[];
};

export const SetPreferences = observer(() => {
    const onboarding = useOnboarding();
    const store = useStore();
    const navigate = useNavigate();

    const [selection, setSelection] = useState<OffersTablePreferences>(
        OffersTablePreferences.LOWEST_TOTAL_COST
    );

    const getQuizAnswer = async () => {
        try {
            const requestBody: IQuizPutInput = {
                quizAnswerInput: {
                    id: onboarding.formData.id as string,
                    answer: selection as OffersTablePreferences,
                },
                token: onboarding.jwt as string,
            };

            const quizOutput = await PostQuizAnswer(requestBody);
            if (!quizOutput) return undefined;

            return quizOutput.offersQuizAnswer;
        } catch (err) {
            errorLogs("PostQuizAnswer", err);
            store.setSnackError("Something went wrong. Please try again later.", "error");
        }
    };

    const handleContinue = async () => {
        if (!selection) return;
        store.setLoading(true);
        const offersQuizAnswer = await getQuizAnswer();

        if (!offersQuizAnswer) {
            store.setLoading(false);
            return;
        }

        onboarding.setFormQuizAnswer(offersQuizAnswer);
        const url = onboarding.getPostSubmissionUrl();
        navigate(url);
        
        //placing loading false here to remove loading wheel after the redirect has occurred
        store.setLoading(false);
    };

    const radioButtonOptions = () => {
        if (onboarding.formData.type) {
            return onboarding.formData.type === "PSL"
                ? radioPSLButtonOptions(onboarding.formData.userType as UserType)
                : radioSLRButtonOptions(onboarding.formData.userType as UserType);
        }
    };

    return (
            <ContentLayout pageText= {
                {title: "Select an option below to personalize your pre-qualified rates",
                leftSubText: "Set Your Preferences",
                leftTitleText: `Congrats, ${store.currentUser?.firstName} you're pre-qualified!`    
            }
            } >
            <div style={{ marginBottom: "32px" }}>
                {radioButtonOptions() && (
                    <RadioButtons
                        options={radioButtonOptions()!}
                        hasSubtitles={true}
                        setForm={setSelection}
                        userType={onboarding.formData.userType as UserType}
                        customCheckBorderRadius={7}
                    />
                )}
            </div>
            <Button
                id="continue-button"
                tabIndex={0}
                onClick={handleContinue}
                showIcon={true}
                buttonType={ButtonTypes.PRIMARY}
                userType={onboarding.formData.userType as UserType}
            >
                Personalize my rates
            </Button>
            </ContentLayout>
    );
});
