import { AuthFlowLayout } from "../../common/components/auth-component/authFlowLayout";
import { CompleteSignUpPage } from "./page";

export default function CompleteSignUpWrapper() {
    return (
        <AuthFlowLayout>
            <CompleteSignUpPage />
        </AuthFlowLayout>
    );
}
