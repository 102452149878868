import { InputValue, DropdownOption } from "../../../types/form-types";
// If there is a previously selected value, set the dropdown value to that, otherwise return the input value
export const getDropdownValue = (inputValue: InputValue, dropdownOptions: DropdownOption[]) => {
    if (!inputValue && inputValue !== false) return "";
    //If there is previously selected value, set the dropdown value to that
    const matchingOption = dropdownOptions.find(
        (option) => option.value === inputValue
    );
    if(!matchingOption) return inputValue as string;
    return matchingOption.label;
};
