import { InputText } from "../../../../common/components/inputs/input_text/input_text";
import { AppModal } from "../../../../common/components/modals/AppModal";
import { useEffect, useState } from "react";
import { useStore } from "../../../../common/core/providers/global.provider";
import { Button } from "../../../../common/components/buttons/button/Button";
import { ButtonTypes } from "../../../../common/components/buttons/button/Button";
import { UserType } from "../../../../common/constants/defaultInputs";
import { ButtonGroup } from "../../../../sparrow-ui-library/src/ui-components/buttons";

interface IProfileSecurityPreferencesModal {
    modalToggle: (show: boolean) => void;
    form: {
        email: string;
        firstName: string;
        lastName: string;
        currentPassword: string;
        newPassword: string;
        confirmPassword: string;
        phone_number: string;
    };
    onSubmit: () => void;
    onChange: (key: string, value: any) => void;
}
const ProfileSecurityPreferencesModal = ({
    modalToggle,
    form,
    onSubmit,
    onChange,
}: IProfileSecurityPreferencesModal) => {
    const { setAuthSnackError, authNotifReset } = useStore();
    // Passwords States
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [confirmError1, setConfirmError1] = useState("");
    const [confirmError2, setConfirmError2] = useState("");
    const [pswLengthValid, setPswLengthValid] = useState(false);
    const [pswNumberIncludeValid, setPswNumberIncludeValid] = useState(false);
    const [pswSymbolIncludeValid, setPswSymbolIncludeValid] = useState(false);
    const [pswPasswordIncludeValid, setPswPasswordIncludeValid] = useState(false);
    const [pswLetterCaseValid, setPswLetterCaseValid] = useState(false);
    const [pswFirstNameValid, setPswFirstNameValid] = useState(false);
    const [pswLastNameValid, setPswLastNameValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    
    useEffect(() => {
        passwordErrorHandler();
        checkPasswordValidation();
    }, [form, confirmError1]);
    const onChangePsw = (value: string, confirmPsw = false) => {
        if (!confirmPsw) {
            const error2 =
                form.newPassword.length > 0 && form.confirmPassword !== value
                    ? "Passwords do not match"
                    : "";
            setConfirmError2(error2);
            error2.length ? setAuthSnackError(error2, "error") : authNotifReset();
            onChange("newPassword", value);
            passwordChecker(value);
            checkPasswordValidation();
        } else {
            const error =
                value.length > 0 && form.newPassword !== value ? "Passwords do not match" : "";
            setConfirmError2(error);
            error.length ? setAuthSnackError(error, "error") : authNotifReset();
            onChange("confirmPassword", value);
        }
    };
    const passwordChecker = (password: string) => {
        setPswLengthValid(password.length > 11);
        setPswLetterCaseValid(!!password.match("[A-Z]") && !!password.match("[a-z]")); // For Uppercase/Lowercase Alphabet
        setPswNumberIncludeValid(!!password.match("[0-9]"));
        setPswPasswordIncludeValid(
            !password.includes("password") && !password.includes("Password") && password.length > 0
        );
        setPswFirstNameValid(
            password.length > 0 && !password.toLowerCase().includes(form.firstName.toLowerCase())
        );
        setPswLastNameValid(
            password.length > 0 && !password.toLowerCase().includes(form.lastName.toLowerCase())
        );
        setPswSymbolIncludeValid(!!password.match("[!@#$%^&*(),.?\":{}|<>№;~`'+-/=\\[\\]\\\\_]"));
    };
    const checkPasswordValidation = () => {
        setIsPasswordValid(
            form.currentPassword.length > 11 &&
                pswLengthValid &&
                pswLetterCaseValid &&
                pswNumberIncludeValid &&
                pswPasswordIncludeValid &&
                pswSymbolIncludeValid &&
                pswLastNameValid &&
                pswFirstNameValid &&
                form.newPassword.length > 0 &&
                confirmError1.length === 0 &&
                !confirmError2.length
        );
    };
    const passwordErrorHandler = () => {
        if (!pswFirstNameValid) {
            setConfirmError1("Cannot contain first name");
        }
        if (!pswLastNameValid) {
            setConfirmError1("Cannot contain last name");
        }
        if (!pswLengthValid) {
            setConfirmError1("Must be at least 12 characters");
        }
        if (!pswSymbolIncludeValid) {
            setConfirmError1("Must include at least one symbol");
        }
        if (!pswLetterCaseValid) {
            setConfirmError1("Must include at least one capitalized letter");
        }
        if (!pswPasswordIncludeValid) {
            setConfirmError1("Cannot contain password word");
        }
        if (
            form.newPassword.length === 0 ||
            (pswFirstNameValid &&
                pswLastNameValid &&
                pswLengthValid &&
                pswSymbolIncludeValid &&
                pswLetterCaseValid &&
                pswPasswordIncludeValid)
        ) {
            setConfirmError1("");
        }
        form.newPassword.length ? setAuthSnackError(confirmError1, "error") : authNotifReset();
    };

    return (
        <AppModal
            onClose={() => modalToggle(false)}
            size={"md"}
            showCloseIcon={true}
            customClass={"profile-modal profile-modal-edit"}
            enableMobileView={true}
        >
            <div className="profile-modal-content profile-modal-content-edit">
                <div className="profile-modal-content__head">
                    <h3 className="h3">Security Preferences</h3>
                </div>
                <div className="form-row">
                    <InputText
                        placeholder={"Your password"}
                        type={showCurrentPassword ? "text" : "password"}
                        name={"currentPassword"}
                        label={"Current password"}
                        value={form.currentPassword}
                        showPassword={() => setShowCurrentPassword(!showCurrentPassword)}
                        passwordShown={showCurrentPassword}
                        passwordType={true}
                        onClear={() => onChange("currentPassword", "")}
                        onChange={(e) => onChange("currentPassword", e.target.value)}
                        error={
                            form.currentPassword.length > 0 && form.currentPassword.length < 11
                                ? "The minimum length must be 12 characters"
                                : ""
                        }
                    />
                </div>
                <div className="form-row">
                    <InputText
                        placeholder={"Enter your new password"}
                        type={showNewPassword ? "text" : "password"}
                        name={"newPassword"}
                        label={"New password"}
                        value={form.newPassword}
                        showPassword={() => setShowNewPassword(!showNewPassword)}
                        passwordShown={showNewPassword}
                        passwordType={true}
                        error={confirmError1}
                        onClear={() => onChangePsw("")}
                        onChange={(e: any) => onChangePsw(e.target.value)}
                    />
                </div>
                <div className="form-row">
                    <InputText
                        placeholder={"Enter your new password"}
                        type={showConfirmPassword ? "text" : "password"}
                        name={"confirmPassword"}
                        label={"Confirm new password"}
                        value={form.confirmPassword}
                        showPassword={() => setShowConfirmPassword(!showConfirmPassword)}
                        passwordShown={showConfirmPassword}
                        passwordType={true}
                        error={confirmError2}
                        onClear={() => onChangePsw("", true)}
                        onChange={(e: any) => onChangePsw(e.target.value, true)}
                    />
                </div>
                <ButtonGroup styles={{ marginTop: "32px" }}>
                    <Button
                        disabled={!isPasswordValid}
                        style={{ fontSize: "16px", fontWeight: "500", lineHeight: "24px", height: "fit-content" }}
                        padding={"16px 25px"}
                        userType={UserType.BORROWER}
                        buttonType={ButtonTypes.PRIMARY}
                        onClick={onSubmit}
                    >
                        Change my password
                    </Button>
                </ButtonGroup>
            </div>
        </AppModal>
    );
};

export default ProfileSecurityPreferencesModal;
