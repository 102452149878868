import { useNavigate } from "react-router-dom";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ReactTooltip from "react-tooltip";
import XIcon from "../../../assets/images/icons/XIconHover.svg";

import editIcon from "../../../assets/images/icons/edit.svg";
import editHoverIcon from "../../../assets/images/icons/editHover.svg";

import { UserType } from "../../constants/defaultInputs";
import { useStore } from "../../core/providers/global.provider";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import {
    userInclusionManagementUrl,
    userInclusionSelectUrl,
} from "../../core/routes/navigation-urls";
import { Get } from "../../core/services/clientApi";
import { hypotheticalCosigner, savedCosigner } from "../../core/services/sparrow-api-endpoints";
import { IncludedUserStatus } from "../../../sparrow-ui-library/src/types/sparrow-types";
import { InnerLoading } from "../loading";

import { ResendReminderModal } from "../modals/User_Inclusion_Modals/Resend_Reminder_Modal/ResendReminderModal";
import { useMediaQuery } from "react-responsive";
import { observer } from "mobx-react";
import { Button, ButtonTypes } from "../buttons/button/Button";

interface IHypoCosigner {
    aprSaved: number;
    cosignerFicoRating: {
        lowEnd: number;
        highEnd: number;
        quality: string;
    };
    monthlyAmountSaved: {
        display: string;
        amount: number;
        currency: string;
    };
    totalAmountSaved: {
        display: string;
        amount: number;
        currency: string;
    };
    tip: string;
}

export const getColor = (state: string | undefined) => {
    switch (state) {
        case "Poor":
            return {
                color: "#ED8383",
                percent: 0.8,
            };
        case "Fair":
            return {
                color: "#F2A258",
                percent: 0.6,
            };
        case "Good":
            return {
                color: "#E7DE14",
                percent: 0.4,
            };
        case "Very Good":
            return {
                color: "#B9D658",
                percent: 0.2,
            };
        case "Exceptional":
            return {
                color: "#6FB554",
                percent: 0,
            };
        default:
            return {
                color: "transparent",
                percent: 1,
            };
    }
};

export const CosignerCard = observer(
    ({ id, type, onExit }: { id: string; type: string; onExit?: Function }) => {
        const { setModal } = useStore();
        const { formData, jwt } = useOnboarding();
        const onboarding = useOnboarding();
        const strokeW = 144;
        const [loading, setLoading] = useState(false);
        const [data, setData] = useState<IHypoCosigner | null>(null);
        const navigate = useNavigate();

        const showCosignerCard =
            formData?.userType === UserType.BORROWER &&
            (formData.isCreator ? !!formData.includedUser : !!formData.creator);
        const offersCount = onboarding.getOffersCount();

        // Media Constants
        const isLess767px = useMediaQuery({
            query: "(max-width: 767px)",
        });

        function resendInvite(isReminder: boolean, firstName: string, email: string) {
            setModal(() => (
                <ResendReminderModal firstName={firstName} email={email} isReminder={isReminder} />
            ));
        }

        useEffect(() => {
            if (
                formData?.includedUser?.status === IncludedUserStatus.CONFIRMED &&
                formData?.userType === UserType.BORROWER &&
                id != null
            ) {
                setLoading(true);
                setData(null);
                Get(savedCosigner, jwt, null, `id=${id}`)
                    .then((cosignerValue) => {
                        setData(cosignerValue);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
            ReactTooltip.rebuild();
        }, []);

        function fetchHypotheticalCosignerData(ficoScore: number) {
            let timeoutId: NodeJS.Timer | undefined;
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                timeoutId = undefined;
                if (id != null) {
                    setLoading(true);
                    Get(hypotheticalCosigner, jwt, null, `id=${id}&cosignerFicoScore=${ficoScore}`)
                        .then((value) => {
                            setData(value);
                        })
                        .finally(() => {
                            setLoading(false);
                        });
                }
            }, 600);
        }

        const tooltipContainer: any = document.getElementById("tooltip-container");

        const FicoRating = () => {
            if (
                Object(data).hasOwnProperty("cosignerFicoRating") &&
                data?.cosignerFicoRating !== null &&
                Object(data?.cosignerFicoRating).hasOwnProperty("quality") &&
                Object(data?.cosignerFicoRating).hasOwnProperty("lowEnd") &&
                Object(data?.cosignerFicoRating).hasOwnProperty("highEnd")
            ) {
                return (
                    <>
                        <div data-tip data-for="tooltip-fico" className="card-item__paddings">
                            <div className="card-item card-item-gray">
                                {" "}
                                {/* onClick={() => {showCosignerTip('fico')}} */}
                                <div className="card-item__main layout-row align-center justify-center">
                                    <div className="circle_range_bg">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="94"
                                            height="49"
                                            viewBox="0 0 94 49"
                                            fill="none"
                                        >
                                            <path
                                                d="M92 47C92 22.1472 71.8528 2 47 2C22.1472 2 2 22.1472 2 47"
                                                strokeDasharray={144}
                                                strokeDashoffset={
                                                    strokeW *
                                                    getColor(data?.cosignerFicoRating.quality)
                                                        .percent
                                                }
                                                stroke={
                                                    getColor(data?.cosignerFicoRating.quality).color
                                                }
                                                strokeWidth="4"
                                                strokeLinecap="round"
                                            />
                                        </svg>
                                        <div
                                            className="circle_range_round"
                                            style={{
                                                transform: `rotate(-${
                                                    getColor(data?.cosignerFicoRating.quality)
                                                        .percent * 180
                                                }deg)`,
                                            }}
                                        >
                                            <span
                                                style={{
                                                    borderColor: `${
                                                        getColor(data?.cosignerFicoRating.quality)
                                                            .color
                                                    }`,
                                                }}
                                            />
                                        </div>
                                        <div className="circle_range_main">
                                            <svg
                                                width="92"
                                                height="47"
                                                viewBox="0 0 92 47"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M91 46C91 21.1472 70.8528 1 46 1C21.1472 1 1 21.1472 1 46"
                                                    strokeDasharray="0"
                                                    strokeDashoffset={144}
                                                    stroke="#DEE2E4"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                />
                                            </svg>
                                        </div>
                                        <div className="circle_range_title">
                                            {data?.cosignerFicoRating.quality}
                                        </div>
                                        <div
                                            className="circle_range_card"
                                            style={{
                                                backgroundColor: `${
                                                    getColor(data?.cosignerFicoRating.quality).color
                                                }`,
                                            }}
                                        >
                                            {data?.cosignerFicoRating.lowEnd}-
                                            {data?.cosignerFicoRating.highEnd}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-item__title align-center justify-center layout-row">
                                    FICO Rating
                                </div>
                            </div>
                        </div>
                        {ReactDOM.createPortal(
                            <ReactTooltip
                                id="tooltip-fico"
                                scrollHide={true}
                                place={"left"}
                                type={"light"}
                                className={"cosigner-tooltip-module"}
                                backgroundColor={"white"}
                                effect={"solid"}
                                getContent={function (e) {
                                    return (
                                        <>
                                            <div className="cosigner-tooltip-title">
                                                Your cosigner has a{" "}
                                                <span
                                                    style={{
                                                        color: `${
                                                            getColor(
                                                                data?.cosignerFicoRating.quality
                                                            ).color
                                                        }`,
                                                    }}
                                                >
                                                    {data?.cosignerFicoRating.quality}
                                                </span>{" "}
                                                FICO rating
                                            </div>
                                            <div className="cosigner-tooltip-content">
                                                <p>
                                                    Below is a breakdown of the various FICO ranges:
                                                </p>
                                                <div className="fico-row">
                                                    <div className="fico-item">
                                                        <div className="fico-tip fico-tip-poor">
                                                            <div className="name">Poor</div>
                                                            <div className="value">300 - 579</div>
                                                        </div>
                                                    </div>
                                                    <div className="fico-item">
                                                        <div className="fico-tip fico-tip-fair">
                                                            <div className="name">Fair</div>
                                                            <div className="value">580 - 669</div>
                                                        </div>
                                                    </div>
                                                    <div className="fico-item">
                                                        <div className="fico-tip fico-tip-good">
                                                            <div className="name">Good</div>
                                                            <div className="value">670 - 739</div>
                                                        </div>
                                                    </div>
                                                    <div className="fico-item">
                                                        <div className="fico-tip fico-tip-very_good">
                                                            <div className="name">Very good</div>
                                                            <div className="value">740 - 799</div>
                                                        </div>
                                                    </div>
                                                    <div className="fico-item">
                                                        <div className="fico-tip fico-tip-exceptional">
                                                            <div className="name">Exceptional</div>
                                                            <div className="value">800 - 850</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    );
                                }}
                            />,
                            tooltipContainer
                        )}
                    </>
                );
            } else {
                return <></>;
            }
        };

        const CosignerInvitationInfo = () => {
            if (!showCosignerCard) return <></>;


            const handleResendInvite = () => resendInvite(
                false,
                formData.includedUser?.firstName!,
                formData.includedUser?.email!
            )



            return (
                <>
                    {isLess767px && (
                        <div className={"mobile-modal-header"}>
                            <div className="h4">Cosigner Information</div>
                            <button
                                className="close-cosigner"
                                onClick={() => {
                                    if (onExit) {
                                        onExit();
                                    }
                                }}
                            >
                                <span className="icon-close" />
                            </button>
                        </div>
                    )}
                    <div className="header-cosigner-dropdown-container">
                        <div className={"header-cosigner-dropdown-identity-container"}>
                            <div className={"header-cosigner-dropdown-initials"}>
                                {onboarding.getIncludedUserInitials()}
                            </div>
                            <div className={"header-cosigner-dropdown-name-container"}>
                                <div className={"header-cosigner-dropdown-name-top-row-container"}>
                                    {onboarding.getIncludedUserName()}
                                    {formData.isCreator && (
                                        <img
                                            src={editIcon}
                                            style={{ cursor: "pointer" }}
                                            alt="edit"
                                            onClick={() => navigate(userInclusionManagementUrl)}
                                        />
                                    )}
                                </div>
                                <div
                                    className={"header-cosigner-dropdown-name-bottom-row"}
                                    style={{ color: "#ABB6BD" }}
                                >
                                    {formData.includedUser?.email}
                                </div>
                            </div>
                        </div>
                        <div className={"header-cosigner-dropdown-button-container"}>
                            <Button
                                onClick={handleResendInvite}
                                buttonType={ButtonTypes.SECONDARY}
                                userType={onboarding.formData.userType as UserType}
                                width={"100%"}
                            >
                                Resend invitation
                            </Button>
                            <Button
                                onClick={() => navigate(userInclusionManagementUrl)}
                                buttonType={ButtonTypes.PRIMARY}
                                userType={onboarding.formData.userType as UserType}
                                width={"100%"}
                            >
                                Sign up {formData.includedUser?.firstName}
                            </Button>
                        </div>
                    </div>
                </>
            );
        };

        const CosignerPendingInfo = () => {
            if (!showCosignerCard) return <></>;
            return (
                <div className="header-cosigner-dropdown-container">
                    <div className={"header-cosigner-dropdown-identity-container"}>
                        <div className={"header-cosigner-dropdown-initials"}>
                            {onboarding.getIncludedUserInitials()}
                        </div>
                        <div className={"header-cosigner-dropdown-name-container"}>
                            <div className={"header-cosigner-dropdown-name-top-row-container"}>
                                {onboarding.getIncludedUserName()}
                                {formData.isCreator && (
                                    <img
                                        src={editIcon}
                                        style={{ cursor: "pointer" }}
                                        alt="edit"
                                        onClick={() => navigate(userInclusionManagementUrl)}
                                    />
                                )}
                            </div>
                            <div
                                className={"header-cosigner-dropdown-name-bottom-row"}
                                style={{ color: "#ABB6BD" }}
                            >
                                {formData.includedUser?.email}
                            </div>
                        </div>
                    </div>
                    <div className={"header-cosigner-dropdown-button-container"}>
                        <div
                            className={"header-cosigner-dropdown-button-1"}
                            onClick={() =>
                                resendInvite(
                                    true,
                                    formData.includedUser?.firstName!,
                                    formData.includedUser?.email!
                                )
                            }
                        >
                            {"Send a reminder"}
                        </div>
                        <div
                            className={"header-cosigner-dropdown-button-2"}
                            onClick={() => navigate(userInclusionManagementUrl)}
                        >{`Sign up ${formData.includedUser?.firstName}`}</div>
                    </div>
                </div>
            );
        };

        const CosignerAddInfo = () => {
            return (
                <div className="header-cosigner-dropdown-container">
                    <div className={"header-cosigner-dropdown-identity-container"}>
                        <div className={"header-cosigner-dropdown-name-container"}>
                            <div className={"header-cosigner-dropdown-name-top-row-container"}>
                                Add a cosigner
                                <img
                                    src={XIcon}
                                    style={{ cursor: "pointer" }}
                                    alt="exit"
                                    onClick={() => {
                                        if (onExit) {
                                            onExit();
                                        }
                                    }}
                                />
                            </div>
                            <div
                                className={"header-cosigner-dropdown-name-bottom-row"}
                                style={{ color: "#ABB6BD" }}
                            >
                                Add a cosigner to your pre-qualification request.
                            </div>
                        </div>
                    </div>
                    <div className={"header-cosigner-dropdown-button-container"}>
                        <div className={"header-cosigner-dropdown-button-center-container"}>
                            <div
                                className={"header-cosigner-dropdown-button-center full"}
                                onClick={() => navigate(userInclusionSelectUrl)}
                            >
                                Add Cosigner
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        const CosignerFinishingIncludingInfo = () => {
            return (
                <div className="header-cosigner-dropdown-container">
                    <div className={"header-cosigner-dropdown-identity-container"}>
                        <div className={"header-cosigner-dropdown-initials"}>
                            {onboarding.getIncludedUserInitials()}
                        </div>
                        <div className={"header-cosigner-dropdown-name-container"}>
                            <div className={"header-cosigner-dropdown-name-top-row-container"}>
                                {onboarding.getIncludedUserName()}
                                {formData.isCreator && (
                                    <img
                                        src={editIcon}
                                        style={{ cursor: "pointer" }}
                                        alt="edit"
                                        onClick={() => navigate(userInclusionManagementUrl)}
                                    />
                                )}
                            </div>
                            <div
                                className={"header-cosigner-dropdown-name-bottom-row"}
                                style={{ color: "#ABB6BD" }}
                            >
                                {formData.includedUser?.email}
                            </div>
                        </div>
                    </div>
                    <div className={"header-cosigner-dropdown-button-container"}>
                        <div className={"header-cosigner-dropdown-button-center-container"}>
                            <Button
                                onClick={() => navigate(userInclusionManagementUrl)}
                                buttonType={ButtonTypes.PRIMARY}
                                userType={onboarding.formData.userType as UserType}
                                width={"100%"}
                            >
                                Finish sign up
                            </Button>
                        </div>
                    </div>
                </div>
            );
        };

        const CosignerDeclinedInfo = () => {
            if (!showCosignerCard) return <></>;
            return (
                <div className="header-cosigner-dropdown-container">
                    <div className={"header-cosigner-dropdown-identity-container"}>
                        <div className={"header-cosigner-dropdown-initials"}>
                            {onboarding.getIncludedUserInitials()}
                        </div>
                        <div className={"header-cosigner-dropdown-name-container"}>
                            <div className={"header-cosigner-dropdown-name-top-row-container"}>
                                {onboarding.getIncludedUserName()}
                                {formData.isCreator && (
                                    <img
                                        src={editIcon}
                                        style={{ cursor: "pointer" }}
                                        alt="edit"
                                        onClick={() => navigate(userInclusionManagementUrl)}
                                    />
                                )}
                            </div>
                            <div
                                className={"header-cosigner-dropdown-name-bottom-row"}
                                style={{ color: "#ABB6BD" }}
                            >
                                {formData.includedUser?.email}
                            </div>
                        </div>
                    </div>
                    <div className={"header-cosigner-dropdown-button-container"}>
                        <div className={"header-cosigner-dropdown-button-center-container"}>
                            <div
                                className={"header-cosigner-dropdown-button-center"}
                                onClick={() => navigate(userInclusionManagementUrl)}
                            >{`Delete ${formData.includedUser?.firstName}`}</div>
                        </div>
                    </div>
                </div>
            );
        };

        const CosignerInfo = () => {
            const [isHover, setIsHover] = useState<boolean>(false);
            if (!showCosignerCard) return <></>;
            return (
                <div className="header-cosigner-dropdown-container">
                    <div className={"header-cosigner-dropdown-identity-container"}>
                        <div className={"header-cosigner-dropdown-initials"}>
                            {onboarding.getIncludedUserInitials()}
                        </div>
                        <div className={"header-cosigner-dropdown-name-container"}>
                            <div className={"header-cosigner-dropdown-name-top-row-container"}>
                                {onboarding.getIncludedUserName()}
                                {!onboarding.requestHasExploredRate && formData.isCreator && (
                                    <img
                                        onMouseEnter={() => setIsHover(true)}
                                        onMouseLeave={() => setIsHover(false)}
                                        src={isHover ? editHoverIcon : editIcon}
                                        style={{ cursor: "pointer" }}
                                        alt="pen"
                                        onClick={() => navigate(userInclusionManagementUrl)}
                                    />
                                )}
                            </div>
                            <div className={"header-cosigner-dropdown-name-bottom-row"}>
                                Your cosigner
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        const APRRating = () => {
            if (Object(data).hasOwnProperty("aprSaved")) {
                return (
                    <>
                        <div data-tip data-for={"tooltip-apr"} className="card-item__paddings">
                            <div className="card-item card-item-gray apr-rating">
                                <div className="card-item__value align-center justify-center layout-row">
                                    <div>
                                        {Number(data?.aprSaved).toFixed(2)}
                                        <span>%</span>
                                    </div>
                                </div>
                                <div className="card-item__title align-center justify-center layout-row">
                                    APR Lowered
                                </div>
                            </div>
                        </div>
                        {ReactDOM.createPortal(
                            <ReactTooltip
                                id="tooltip-apr"
                                scrollHide={true}
                                place={"left"}
                                type={"light"}
                                className={"cosigner-tooltip-module"}
                                backgroundColor={"white"}
                                effect={"solid"}
                                getContent={function (e) {
                                    return (
                                        <>
                                            <div className="cosigner-tooltip-title">
                                                Your cosigner’s affect on your APR
                                            </div>
                                            <div className="cosigner-tooltip-content">
                                                <p>
                                                    A cosigner with a{" "}
                                                    {data?.cosignerFicoRating.lowEnd}-
                                                    {data?.cosignerFicoRating.highEnd} FICO score
                                                    could lower your loan’s APR by an average of{" "}
                                                    {Number(data?.aprSaved).toFixed(2)}%.
                                                </p>
                                            </div>
                                        </>
                                    );
                                }}
                            />,
                            tooltipContainer
                        )}
                    </>
                );
            } else {
                return <></>;
            }
        };

        const GreenCard = () => {
            if (
                Object(data).hasOwnProperty("totalAmountSaved") &&
                data?.totalAmountSaved !== null
            ) {
                return (
                    <>
                        <div data-tip data-for={"tooltip-total"} className="card-item__paddings">
                            <div className="card-item card-item-green">
                                <div className="card-item__value align-center justify-center layout-row">
                                    <div>{data?.totalAmountSaved.display}</div>
                                </div>
                                <div className="card-item__title align-center justify-center layout-row">
                                    Total ($) Saved
                                </div>
                            </div>
                        </div>
                        {ReactDOM.createPortal(
                            <ReactTooltip
                                id="tooltip-total"
                                scrollHide={true}
                                place={"left"}
                                type={"light"}
                                className={"cosigner-tooltip-module"}
                                backgroundColor={"white"}
                                effect={"solid"}
                                getContent={function (e) {
                                    return (
                                        <>
                                            <div className="cosigner-tooltip-title">
                                                Your cosigner’s affect on your total payment
                                            </div>
                                            <div className="cosigner-tooltip-content">
                                                <p>
                                                    A cosigner with a{" "}
                                                    {data?.cosignerFicoRating.lowEnd}-
                                                    {data?.cosignerFicoRating.highEnd} FICO score
                                                    could lower your total payment by an average of{" "}
                                                    {data?.totalAmountSaved.display}.
                                                </p>
                                            </div>
                                        </>
                                    );
                                }}
                            />,
                            tooltipContainer
                        )}
                    </>
                );
            } else {
                return <></>;
            }
        };

        const OrangeCard = () => {
            if (
                Object(data).hasOwnProperty("monthlyAmountSaved") &&
                data?.monthlyAmountSaved !== null
            ) {
                return (
                    <>
                        <div data-tip data-for={"tooltip-average"} className="card-item__paddings">
                            <div className="card-item card-item-orange">
                                {" "}
                                {/* onClick={() => {showCosignerTip('monthly')}} */}
                                <div className="card-item__value align-center justify-center layout-row">
                                    <div>{data?.monthlyAmountSaved.display}</div>
                                </div>
                                <div className="card-item__title align-center justify-center layout-row">
                                    Monthly ($) Saved
                                </div>
                            </div>
                        </div>
                        {ReactDOM.createPortal(
                            <ReactTooltip
                                id="tooltip-average"
                                scrollHide={true}
                                place={"left"}
                                type={"light"}
                                className={"cosigner-tooltip-module"}
                                backgroundColor={"white"}
                                effect={"solid"}
                                getContent={function (e) {
                                    return (
                                        <>
                                            <div className="cosigner-tooltip-title">
                                                Your cosigner’s affect on your average monthly
                                                payment
                                            </div>
                                            <div className="cosigner-tooltip-content">
                                                <p>
                                                    A cosigner with a{" "}
                                                    {data?.cosignerFicoRating.lowEnd}-
                                                    {data?.cosignerFicoRating.highEnd} FICO score
                                                    could lower your monthly payment by average of{" "}
                                                    {data?.monthlyAmountSaved.display}.
                                                </p>
                                            </div>
                                        </>
                                    );
                                }}
                            />,
                            tooltipContainer
                        )}
                    </>
                );
            } else {
                return <></>;
            }
        };

        if (type.includes("invitation declined")) {
            return <CosignerDeclinedInfo />;
        }
        if (type.includes("in progress")) {
            return <CosignerFinishingIncludingInfo />;
        }

        if (type.toLowerCase().includes("pending")) {
            return <CosignerPendingInfo />;
        } else if (type.includes("Invitation")) {
            return <CosignerInvitationInfo />;
        } else if (type.includes("included")) {
            return (
                <InnerLoading shown={loading} style={{ borderRadius: 16, overflow: "hidden" }}>
                    {isLess767px && (
                        <div className={"mobile-modal-header"}>
                            <div className="h4">Cosigner Information</div>
                            <button
                                className="close-cosigner"
                                onClick={() => {
                                    if (onExit) {
                                        onExit();
                                    }
                                }}
                            >
                                <span className="icon-close" />
                            </button>
                        </div>
                    )}
                    <CosignerInfo />
                    <div className="cosigner__card-item">
                        <FicoRating />
                        <APRRating />
                        <GreenCard />
                        <OrangeCard />
                    </div>
                </InnerLoading>
            );
        }

        if (offersCount === 0) {
            return <CosignerAddInfo />;
        }

        return (
            <div className="cosigner-hypothetical">
                <InnerLoading shown={loading} style={{ borderRadius: 16 }}>
                    <div className="cosigner__card-item">
                        <FicoRating />
                        <APRRating />
                        <GreenCard />
                        <OrangeCard />
                    </div>
                </InnerLoading>
                <div className="cosigner-info-container">
                    <SliderRange
                        onChange={(v) => fetchHypotheticalCosignerData(v)}
                        loading={loading}
                        hypotheticalName={"Wizard"}
                        onExit={onExit}
                    />
                </div>
            </div>
        );
    }
);

const SliderRange: React.FC<{
    loading: boolean;
    onChange(any: any): void;
    hypotheticalName: string;
    onExit?: Function;
}> = (props) => {
    const [rangeV, setRangeV] = useState(300);
    const navigate = useNavigate();

    useEffect(() => {
        changeValue(300);
    }, []);

    const changeValue = (v: any) => {
        if (!props.loading) {
            setRangeV(v);
            props.onChange(v);
        }
    };

    return (
        <>
            <div className="no_cosigner_card">
                <div className="hypothetical-cosigner-title-container">
                    <div className="hypothetical-cosigner-title">
                        <div className={"hypothetical-cosigner-title-text"}>Wizard</div>
                        <img
                            style={{ cursor: "pointer" }}
                            src={XIcon}
                            alt="x"
                            onClick={() => (props.onExit ? props.onExit() : {})}
                        />
                    </div>
                    <div className="hypothetical-cosigner-subtitle">
                        Ask the Wizard how much you could save by finding the right cosigner.
                    </div>
                </div>
                <div className="hypothetical-cosigner-slider">
                    <Slider value={rangeV} max={850} min={300} onChange={(v) => changeValue(v)} />
                </div>
                <div className={"fico-score"}>{rangeV}</div>
                <div className="fico-tooltip">
                    As you toggle the FICO score above, we’ll show you how much you could save if
                    you included a cosigner with that hypothetical FICO score on your request.
                </div>
            </div>
            <Button
                userType={UserType.BORROWER}
                onClick={() => navigate(userInclusionSelectUrl)}
                buttonType={ButtonTypes.PRIMARY}
                width="100%"
            >
                Add cosigner
            </Button>
        </>
    );
};
