import {
    prequalificationAddressUrl,
    prequalificationAmountUrl,
    prequalificationCitizenshipUrl,
    prequalificationDegreesUrl,
    prequalificationIncomeUrl,
    prequalificationReviewUrl,
    prequalificationExpandedSearchUrl,

} from "../routes/navigation-urls";
import { setPreferencesUrl, selectUserTypeBase } from "../routes/routing-urls";

export const GeneralUrlsEnum = {
    SELECT_USER_TYPE: selectUserTypeBase,
    PREFERENCES: setPreferencesUrl,
};

export const PrequalUrlsEnum = {
    AMOUNT: prequalificationAmountUrl,
    CITIZENSHIP: prequalificationCitizenshipUrl,
    ADDRESS: prequalificationAddressUrl,
    INCOME: prequalificationIncomeUrl,
    DEGREES: prequalificationDegreesUrl,
    REVIEW: prequalificationReviewUrl,
    EXPANDED: prequalificationExpandedSearchUrl,
};
