import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useCheckPathName = (url: string) => {
    const routePathname = useLocation().pathname;

    const [isUrl, setIsUrl] = useState(false);

    // Activate effect when routePathname or url changes
    useEffect(() => {
        setIsUrl(routePathname.includes(url));
    }, [url, routePathname]);

    return isUrl;
};

export default useCheckPathName;
