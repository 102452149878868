"use client";
import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { Loading } from "../../../common/components/loading";
import { AuthNotifications } from "../../../common/components/notification_status/authErrors";
import { useStore } from "../../../common/core/providers/global.provider";
import {
    loginUrl,
    loginStepBorrower,
    loginStepType,
    signUpStepBorrowerUrl,
    signUpSuccessUrl,
    signUpStepTypeUrl,
    dashboardUrl,
} from "../../core/routes/navigation-urls";
import Button from "../../../staticComponents/Buttons/button";
import CustomBg from "../../../staticComponents/CustomBg";
import Logo from "../../../staticComponents/Logo";
import { useLocation, useNavigate } from "react-router-dom";
import {
    baseDomainUrl,
    completeSignUpBase,
    confirmEmailBase,
    confirmEmailResetBase,
    confirmPasswordBase,
    resetPasswordBase,
    ssoBase,
} from "../../core/routes/routing-urls";
import { isSuccessfullyLoggedIn } from "../../core/logout";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { isMarketplaceEnv } from "../../utils/config";
import { PAAASLogo } from "../PAAASLogo";
import { getCurrentYear } from "../../utils/helper_functions/date-functions";
import { getRouteForPAAASLogo } from "../../utils/routing";

export const AuthFlowLayout = observer(({ children }) => {
    const { jwt, paaasCustomization } = useOnboarding();
    const { entryPointUrl } = useStore();
    const routePathname = useLocation().pathname;
    const navigate = useNavigate();
    const isExcludedPaths =
        routePathname !== ssoBase &&
        routePathname !== completeSignUpBase &&
        routePathname !== signUpSuccessUrl;

    const routeBasedInfo = useMemo(() => {
        switch (routePathname) {
            case loginUrl:
            case loginStepType:
            case loginStepBorrower:
                return {
                    bgColor: "#E6C414",
                    backLink: undefined,
                };
            case signUpStepTypeUrl:
            case signUpStepBorrowerUrl:
                return {
                    bgColor: "#6DB353",
                    backLink: undefined,
                    className: `${routePathname?.replace(/[/]/g, " ")}`,
                };
            case signUpSuccessUrl:
                return {
                    bgColor: "#E6C414",
                    backLink: undefined,
                };
            case confirmEmailBase:
                return {
                    bgColor: "#6DB353",
                    backLink: undefined,
                };

            case resetPasswordBase:
            case confirmEmailResetBase:
            case confirmPasswordBase:
                return {
                    bgColor: "#F19048",
                    backLink: loginStepBorrower,
                };
            case ssoBase:
                return {
                    bgColor: "#6DB353",
                    backLink: loginUrl,
                    className: "auth-flow-container--inner-sso",
                };
            case completeSignUpBase:
                return {
                    bgColor: "#F19048",
                    backLink: undefined,
                    onBackLinkHandler: undefined,
                };
            default:
                return {
                    bgColor: "#E6C414",
                    backLink: loginUrl,
                };
        }
    }, [routePathname]);

    useEffect(() => {
        if (isSuccessfullyLoggedIn(jwt) && isExcludedPaths) {
            navigate(entryPointUrl ? entryPointUrl : dashboardUrl, { replace: true });
        }
    }, [jwt]);

    if (routePathname === ssoBase) {
        return <>{children}</>;
    }

    const logoToRender = isMarketplaceEnv() ? (
        <a href={baseDomainUrl} className="header-logo">
            <Logo />
        </a>
    ) : (
        <PAAASLogo
            navigateTo={getRouteForPAAASLogo({
                pathname: loginStepBorrower,
                displayLandingPageView: paaasCustomization?.toggleableViews?.displayLandingPageView,
            })}
        />
    );

    return (
        <>
            {isSuccessfullyLoggedIn(jwt) && isExcludedPaths && <Loading shown />}
            <div className="wrapper section-has-custom-bg wrapper--auth-flow">
                <CustomBg
                    theme="grey"
                    bottomBarWidth="25%"
                    hasBottomBar={isMarketplaceEnv()}
                    bottomBarColor={routeBasedInfo.bgColor}
                />
                <header className="header-wrapper header-wrapper--dark">
                    <div className="container">
                        <div className="row">
                            <div className="col col-logo">{logoToRender}</div>
                        </div>
                    </div>
                </header>
                <div className="auth-flow-container">
                    <div className="container">
                        <AuthNotifications />
                        <div className={`wrap-block`}>
                            {children}
                            {routeBasedInfo.backLink ? (
                                <div className="step-form-back-link">
                                    <Button
                                        text="Cancel"
                                        type="button"
                                        event={() => navigate(routeBasedInfo.backLink)}
                                        variant="text"
                                    />
                                </div>
                            ) : routeBasedInfo.onBackLinkHandler ? (
                                <div className="step-form-back-link">
                                    <Button
                                        text="Cancel"
                                        type="button"
                                        event={routeBasedInfo.onBackLinkHandler}
                                        variant="text"
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="container">
                        <div className="footer-sparrow-copyrights">{`© ${getCurrentYear()} Sparrow Labs, Inc.`}</div>
                    </div>
                </footer>
            </div>
        </>
    );
});
