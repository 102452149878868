import { OfferCardHeaderDetail } from "./OfferCardHeaderDetail";
import { OfferDetail } from "../../OfferCardUtilities";
import { formatPercent } from "../../../../utils/global";

import "../../style.scss";
import { useMediaQuery } from "react-responsive";

interface DetailsProps {
    apr: number;
    term: number;
    totalCost: string;
    offerDetails: OfferDetail[];
    lenderDisclosure: string;
}

export const OfferCardHeaderDetailSection: React.FunctionComponent<DetailsProps> = ({
    lenderDisclosure,
    apr,
    term,
    totalCost,
    offerDetails,
}) => {
    const isMore1200px = useMediaQuery({
        query: "(min-width: 1200px)",
    });
    const isMore1250px = useMediaQuery({
        query: "(min-width: 1250px)",
    });
    const isMore1400px = useMediaQuery({
        query: "(min-width: 1400px)",
    });
    function getTermToolTip(tooltip: string) {
        for (const detail of offerDetails) {
            if (detail.title === tooltip) {
                return detail.tooltip;
            }
        }
        return "";
    }
    return (
        <div className="offer-information-section-container">
            {isMore1200px && (
                <div className="offer-information-wrapper">
                    <OfferCardHeaderDetail
                        title={"APR"}
                        content={lenderDisclosure}
                        value={formatPercent(apr)}
                    />
                </div>
            )}
            {isMore1250px && (
                <div className="offer-information-wrapper">
                    <OfferCardHeaderDetail
                        title={"Term"}
                        content={getTermToolTip("Term")}
                        value={term + " years"}
                    />
                </div>
            )}

            {isMore1400px && (
                <div className="offer-information-wrapper">
                    {" "}
                    <OfferCardHeaderDetail
                        title={"Total Cost"}
                        content={getTermToolTip("Total Cost")}
                        value={totalCost}
                    />
                </div>
            )}
        </div>
    );
};
