import { UserType } from "../../../../types/sparrow-types";
import { getSvgColors } from "../../getSvgColors";

export const TimerIcon = ({
    userType,
    isPAAAS = false,
}: {
    userType: UserType;
    isPAAAS?: boolean;
}) => {
    const { primaryColor, accentColor } = getSvgColors(userType);

    const PAAASVersion = (
        <>
            <g clip-path="url(#clip0_5678_115453)">
                <circle
                    cx="18"
                    cy="23"
                    r="13"
                    fill={
                        isPAAAS
                            ? primaryColor
                            : userType === UserType.BORROWER
                            ? "#185155"
                            : "#185155"
                    }
                />
                <circle
                    cx="18"
                    cy="23"
                    r="9"
                    stroke={
                        isPAAAS
                            ? accentColor
                            : userType === UserType.BORROWER
                            ? "#4EAC6A"
                            : "#4EAC6A"
                    }
                    strokeWidth="2"
                />
                <path
                    d="M33.2664 30.4791C34.2583 30.965 35.4673 30.5575 35.8463 29.52C36.4709 27.8104 36.8478 26.0166 36.9625 24.1932C37.1192 21.703 36.7839 19.2063 35.9757 16.8457C35.1675 14.4851 33.9022 12.3068 32.2522 10.4352C31.044 9.06471 29.6468 7.87831 28.1055 6.91026C27.1701 6.32278 25.965 6.74166 25.4791 7.73359L18.8799 21.204C18.3939 22.1959 18.8041 23.3939 19.796 23.8799L33.2664 30.4791Z"
                    fill={
                        isPAAAS
                            ? accentColor
                            : userType === UserType.BORROWER
                            ? "#8CCE56"
                            : "#8CCE56"
                    }
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.6733 28.7206C30.5206 26.9944 30.9964 25.0527 30.9964 23C30.9964 17.873 28.0285 13.4393 23.717 11.3231L18.8763 21.204C18.3903 22.1959 18.8005 23.3939 19.7924 23.8799L29.6733 28.7206Z"
                    fill={accentColor}
                />
                <rect x="16" y="7" width="4" height="5" fill={accentColor} />
                <rect
                    x="7.82031"
                    y="11.885"
                    width="4"
                    height="3"
                    rx="1"
                    transform="rotate(-35 7.82031 11.885)"
                    fill={accentColor}
                />
                <rect x="13" y="6" width="10" height="2" rx="1" fill={accentColor} />
            </g>
            <defs>
                <clipPath id="clip0_5678_115453">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </>
    );
// TODO: currently the same as marketplaceBorrowerVersion because cosigner version was never designed. This has been approved for now but should be swapped for cosigner version when available
    const marketplaceBorrowerVersion = (
        <>
            <g clip-path="url(#clip0_7658_79389)">
                <circle cx="18" cy="23" r="13" fill="#185155" />
                <circle cx="18" cy="23" r="9" stroke="#4EAC6A" strokeWidth="2" />
                <path
                    d="M33.2664 30.4791C34.2583 30.965 35.4673 30.5575 35.8463 29.52C36.4709 27.8104 36.8478 26.0166 36.9625 24.1932C37.1192 21.703 36.7839 19.2063 35.9757 16.8457C35.1675 14.4851 33.9022 12.3068 32.2522 10.4352C31.044 9.06471 29.6468 7.87831 28.1055 6.91026C27.1701 6.32278 25.965 6.74166 25.4791 7.73359L18.8799 21.204C18.3939 22.1959 18.8041 23.3939 19.796 23.8799L33.2664 30.4791Z"
                    fill="#8CCE56"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.6733 28.7206C30.5206 26.9944 30.9964 25.0527 30.9964 23C30.9964 17.873 28.0285 13.4393 23.717 11.3231L18.8763 21.204C18.3903 22.1959 18.8005 23.3939 19.7924 23.8799L29.6733 28.7206Z"
                    fill="#4EAC6A"
                />
                <rect x="16" y="7" width="4" height="5" fill="#185155" />
                <rect
                    x="7.82031"
                    y="11.885"
                    width="4"
                    height="3"
                    rx="1"
                    transform="rotate(-35 7.82031 11.885)"
                    fill="#185155"
                />
                <rect x="13" y="6" width="10" height="2" rx="1" fill="#185155" />
            </g>
            <defs>
                <clipPath id="clip0_7658_79389">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </>
    );

    const marketplaceCosignerVersion = (
        <>
            <g clip-path="url(#clip0_7658_79389)">
                <circle cx="18" cy="23" r="13" fill="#185155" />
                <circle cx="18" cy="23" r="9" stroke="#4EAC6A" strokeWidth="2" />
                <path
                    d="M33.2664 30.4791C34.2583 30.965 35.4673 30.5575 35.8463 29.52C36.4709 27.8104 36.8478 26.0166 36.9625 24.1932C37.1192 21.703 36.7839 19.2063 35.9757 16.8457C35.1675 14.4851 33.9022 12.3068 32.2522 10.4352C31.044 9.06471 29.6468 7.87831 28.1055 6.91026C27.1701 6.32278 25.965 6.74166 25.4791 7.73359L18.8799 21.204C18.3939 22.1959 18.8041 23.3939 19.796 23.8799L33.2664 30.4791Z"
                    fill="#8CCE56"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M29.6733 28.7206C30.5206 26.9944 30.9964 25.0527 30.9964 23C30.9964 17.873 28.0285 13.4393 23.717 11.3231L18.8763 21.204C18.3903 22.1959 18.8005 23.3939 19.7924 23.8799L29.6733 28.7206Z"
                    fill="#4EAC6A"
                />
                <rect x="16" y="7" width="4" height="5" fill="#185155" />
                <rect
                    x="7.82031"
                    y="11.885"
                    width="4"
                    height="3"
                    rx="1"
                    transform="rotate(-35 7.82031 11.885)"
                    fill="#185155"
                />
                <rect x="13" y="6" width="10" height="2" rx="1" fill="#185155" />
            </g>
            <defs>
                <clipPath id="clip0_7658_79389">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </>
    );

    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <>
                {isPAAAS
                    ? PAAASVersion
                    : userType === UserType.BORROWER
                    ? marketplaceBorrowerVersion
                    : marketplaceCosignerVersion}
            </>
        </svg>
    );
};
