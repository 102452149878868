import { isMarketplaceEnv } from "../../../../../common/utils/config";
import { UserType } from "../../../types/sparrow-types";
import { getSvgColors } from "../getSvgColors";

export const TipsSearchIcon = ({ userType }: { userType: UserType }) => {
    const { primaryColor, accentColor } = getSvgColors(userType);

    const borrowerMarketplaceVersion = (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4.80078" y="3.19922" width="19.2" height="22.4" rx="3" fill="#185155" />
            <path d="M8.80078 14.3984H15.2008" stroke="#4EAC6A" strokeWidth="2" strokeLinecap="round" />
            <path d="M8.80078 8.80078H20.0008" stroke="#4EAC6A" strokeWidth="2" strokeLinecap="round" />
            <path d="M8.80078 20H16.4012" stroke="#4EAC6A" strokeWidth="2" strokeLinecap="round" />
            <circle cx="21.1984" cy="23.6008" r="6.8" fill="#8CCE56" />
            <path d="M24.8008 27.1992L28.1949 30.5933" stroke="#8CCE56" strokeWidth="2" strokeLinecap="round" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9984 17.4022V22.6008C23.9984 24.2576 22.6553 25.6008 20.9984 25.6008H14.6973C14.503 24.9684 14.3984 24.2968 14.3984 23.6008C14.3984 19.8452 17.4429 16.8008 21.1984 16.8008C22.1966 16.8008 23.1445 17.0158 23.9984 17.4022Z" fill="#4EAC6A" />
            <mask id="mask0_8205_7031" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="14" y="16" width="10" height="10">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9984 17.4022V22.6008C23.9984 24.2576 22.6553 25.6008 20.9984 25.6008H14.6973C14.503 24.9684 14.3984 24.2968 14.3984 23.6008C14.3984 19.8452 17.4429 16.8008 21.1984 16.8008C22.1966 16.8008 23.1445 17.0158 23.9984 17.4022Z" fill="#E3FF93" />
            </mask>
            <g mask="url(#mask0_8205_7031)">
                <path d="M10.3984 20H19.1988" stroke="#185155" strokeWidth="2" strokeLinecap="round" />
            </g>
        </svg>
    )

    const cosignerMarketplaceVersion = (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4.80078" y="3.19922" width="19.2" height="22.4" rx="3" fill="#A0584A" />
            <path d="M8.80078 14.4004H15.2008" stroke="#DC7962" strokeWidth="2" strokeLinecap="round" />
            <path d="M8.80078 8.80078H20.0008" stroke="#DC7962" strokeWidth="2" strokeLinecap="round" />
            <path d="M8.80078 20H16.4012" stroke="#DC7962" strokeWidth="2" strokeLinecap="round" />
            <circle cx="21.1984" cy="23.6008" r="6.8" fill="#EEBCB0" />
            <path d="M24.8008 27.1992L28.1949 30.5933" stroke="#EEBCB0" strokeWidth="2" strokeLinecap="round" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9984 17.4022V22.6008C23.9984 24.2576 22.6553 25.6008 20.9984 25.6008H14.6973C14.503 24.9684 14.3984 24.2968 14.3984 23.6008C14.3984 19.8452 17.4429 16.8008 21.1984 16.8008C22.1966 16.8008 23.1445 17.0158 23.9984 17.4022Z" fill="#DC7962" />
            <mask id="mask0_7703_17216" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="14" y="16" width="10" height="10">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M23.9984 17.4022V22.6008C23.9984 24.2576 22.6553 25.6008 20.9984 25.6008H14.6973C14.503 24.9684 14.3984 24.2968 14.3984 23.6008C14.3984 19.8452 17.4429 16.8008 21.1984 16.8008C22.1966 16.8008 23.1445 17.0158 23.9984 17.4022Z" fill="#E3FF93" />
            </mask>
            <g mask="url(#mask0_7703_17216)">
                <path d="M10.3984 20H19.1988" stroke="#A0584A" strokeWidth="2" strokeLinecap="round" />
            </g>
        </svg>

    )

    const getMarketplaceVersion = () => {
        return userType === UserType.BORROWER ? borrowerMarketplaceVersion : cosignerMarketplaceVersion;
    }

    const paaasVersion = (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="TipsSearchIcon"
        >
            <rect
                x="3.61328"
                y="2.27393"
                width="12.0889"
                height="14.1037"
                rx="1.88889"
                fill={primaryColor}
            />
            <path
                d="M6.12891 9.32568H10.1585"
                stroke={accentColor}
                strokeWidth="1.25926"
                strokeLinecap="round"
            />
            <path
                d="M6.12891 5.7998L13.1808 5.7998"
                stroke={accentColor}
                strokeWidth="1.25926"
                strokeLinecap="round"
            />
            <path
                d="M6.12891 12.8516H10.9143"
                stroke={accentColor}
                strokeWidth="1.25926"
                strokeLinecap="round"
            />
            <circle cx="13.9377" cy="15.1184" r="4.28148" fill={accentColor} />
            <path
                d="M16.2031 17.3853L18.3402 19.5223"
                stroke={accentColor}
                strokeWidth="1.25926"
                strokeLinecap="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.6958 11.2146V14.4882C15.6958 15.5314 14.8501 16.3771 13.8069 16.3771H9.84051C9.71818 15.979 9.65234 15.5561 9.65234 15.1179C9.65234 12.7533 11.5692 10.8364 13.9338 10.8364C14.5619 10.8364 15.1584 10.9717 15.6958 11.2146Z"
                fill={accentColor}
            />
        </svg>
    )


    return (isMarketplaceEnv() ? getMarketplaceVersion() : paaasVersion);
};
