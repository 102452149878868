import {AppModal} from "../../../../common/components/modals/AppModal";
import {useEffect, useState} from "react";
import ModalSnackError from "./ModalSnackError";

interface IProfileContactInfoConfirmModal {
    modalToggle: (show: boolean) => void;
    form: {
        email: string;
        firstName: string;
        lastName: string;
        currentPassword: string;
        newPassword: string;
        confirmPassword: string;
        phone_number: string;
        confirmChangeEmailCode: string;
        confirmChangePhoneCode: string
    };
    onSubmit: () => void;
    needVerifyEmail: boolean;
    needVerifyPhone: boolean;
    back: () => void;
    resend: () => void;
    submitError: string | null;
    onChange: (key: string, value: any) => void;
}
const ProfileContactInfoConfirmModal = ({modalToggle, form, onSubmit, needVerifyEmail, needVerifyPhone, back, resend, submitError, onChange}: IProfileContactInfoConfirmModal) => {
    const [isValid, setIsValid] = useState(false);
    const mockLength = ["", "", "", "", "", ""];
    const [formEmail, setFormEmail] = useState<any>(mockLength);
    const [formPhone, setFormPhone] = useState<any>(mockLength);
    useEffect(() => {
        checkValidation();
    }, [formEmail, formPhone])
    const checkValidation = () => {
        let email = formEmail.join("");
        let phone = formPhone.join("");
        if (needVerifyPhone && needVerifyPhone) {
            setIsValid(email.length === 6 && phone.length === 6)
        } else if (needVerifyPhone && !needVerifyEmail) {
            setIsValid(phone.length === 6)
        } else if (!needVerifyPhone && needVerifyEmail) {
            setIsValid(email.length === 6)
        }
    }
    //@ts-ignore
    const onKeyDown = (e, i, formData, setFormData, isEmail = true) => {
        if (e.keyCode === 8) {
            const arr = [...formData];
            const key = isEmail ? "confirmChangeEmailCode" : "confirmChangePhoneCode"
            if (e.target.value !== "" && (i === 5 || e.target.parentElement?.nextSibling)) {
                arr[i] = "";
                setFormData(arr);
                onChange(key, arr.join(""))
            } else if (e.target.parentElement.previousSibling) {
                e.target.parentElement.previousSibling.querySelector("input").focus();
                arr[i - 1] = "";
                setFormData(arr);
                onChange(key, arr.join(""))
            }
        }
    };
    // @ts-ignore
    const onChangeCode = (v, i, formData, setFormData, isEmail = true) => {
        const key = isEmail ? "confirmChangeEmailCode" : "confirmChangePhoneCode"
        if (v.target.value.length === 6) {
            const _str = v.target.value;
            const value = _str.toString();
            let arr: string[] = [...mockLength];
            for (let i = 0; i < value.length; i++) {
                arr[i] = value[i];
            }
            v.target.parentElement.parentElement.lastChild.querySelector("input").focus();
            setFormData(arr);
            onChange(key, arr.join(""))
        } else {
            if (v.target.value.length === 1) {
                if (v.target.value.match(/^\d+$/)) {
                    if (v.target.parentElement.nextSibling) {
                        v.target.parentElement.nextSibling.querySelector("input").focus();
                    }
                    if (formData[i] === "") {
                        const _form = [...formData];
                        _form[i] = v.target.value;
                        setFormData(_form);
                        onChange(key, _form.join(""))
                    }
                }
            }
        }
    };

    return <AppModal onClose={() => modalToggle(false)} size={'md'} showCloseIcon={true} customClass={'profile-modal profile-modal-edit'} enableMobileView={true}>
        <ModalSnackError errorMessage={submitError}/>
        <div className="profile-modal-content profile-modal-content-confirm">
            <button className="back" onClick={back}><span className="icon-arrow-right" style={{transform: 'rotate(-180deg)'}}/> Back</button>
            {needVerifyEmail && <>
                <div className="profile-modal-content__head">
                    <h3 className="h3">Check your email</h3>
                    <p className={'confirm-modal-text'}>Enter them to complete registration. Please enter the 6-digit code we just send to <span>{form.email}</span> to continue.</p>
                    <p className={'confirm-modal-text'}>Didn't receive an email? Check your spam folder or <button className="resend-request" onClick={resend}>Resend</button></p>
                </div>
                <div className="step-form-body">
                    <div className="step-form-block">
                        <div className="code-input-email">
                            {mockLength.map((value, index) => (
                                <div className="input" key={index + "_value"}>
                                    <input
                                        onKeyDown={(e: any) => onKeyDown(e, index, formEmail, setFormEmail)}
                                        value={form[index]}
                                        type="text"
                                        pattern="[0-9]*"
                                        name="code-verification"
                                        onChange={(v) => onChangeCode(v, index, formEmail, setFormEmail)}
                                        maxLength={1}
                                        className={submitError ? 'hasError' : ''}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>}
            {needVerifyPhone && <>
                <div className="profile-modal-content__head">
                    <h3 className="h3">Check your phone</h3>
                    <p className={'confirm-modal-text'}>In order to continue, please enter the 6-digit code we just sent to +{form.phone_number}</p>
                    <p className={'confirm-modal-text'}>Didn't receive a text? Check your connection or <button className="resend-request" onClick={resend}>Resend</button></p>
                </div>
                <div className="step-form-body">
                    <div className="step-form-block">
                        <div className="code-input-email">
                            {mockLength.map((value, index) => (
                                <div className="input" key={index + "_value"}>
                                    <input
                                        onKeyDown={(e: any) => onKeyDown(e, index, formPhone, setFormPhone, false)}
                                        value={form[index]}
                                        type="text"
                                        pattern="[0-9]*"
                                        name="code-verification"
                                        onChange={(v) => onChangeCode(v, index, formPhone, setFormPhone, false)}
                                        maxLength={1}
                                        className={submitError ? 'hasError' : ''}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </>}
            <div className="button-group">
                <button className={`btn btn-green ${!isValid ? 'btn-disable' : ''}`} onClick={onSubmit}>Continue</button>
            </div>
        </div>
    </AppModal>
}

export default ProfileContactInfoConfirmModal