import {
    FormFieldType,
    FormFieldName,
    FormInputsConfig,
    FormsConfig,
} from "../../sparrow-ui-library/src/types/form-types";
import { MembershipElection } from "../../sparrow-ui-library/src/types/sparrow-types/membership-election";

export const getFormsConfig = (inputs: FormsConfig): FormInputsConfig => {
    const { companyName } = inputs as { companyName: string };
    
    return {
        forms: [
            {
                formType: "radioButtons",
                errorMessage: "",
                radioButtonOptions: [
                    {
                        id: "paaas-membership-status-0",
                        name: FormFieldName.PAAASMembershipElection,
                        label: `Yes, I am already a member of ${companyName}`,
                        defaultButtonValue: MembershipElection.MEMBER,
                        inputValue: MembershipElection.MEMBER,
                        checked: false,
                    },
                    {
                        id: "paaas-membership-status-1",
                        name: FormFieldName.PAAASMembershipElection,
                        label: `No, I am not a member of ${companyName}`,
                        checked: false,
                        independentFields: [
                            {
                                id: FormFieldName.PAAASMembershipElection,
                                formFieldType: FormFieldType.ReadonlyDropdown,
                                name: FormFieldName.PAAASMembershipElection,
                                label: "Do you want to become a member to access exclusive benefits (it's free)?",
                                placeholder: "Select",
                                // Dropdown will open with "Yes" by default
                                inputValue: "",
                                backendValue: "",
                                errorMessage: "",
                                dropdownOptions: [
                                    {
                                        label: "Yes",
                                        value: MembershipElection.NON_MEMBER_INTERESTED,
                                    },
                                    {
                                        label: "No",
                                        value: MembershipElection.NON_MEMBER_UNINTERESTED,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    };
};
