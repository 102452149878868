import "./style.scss";
import { useState } from "react";
import GreenCircle from "../../../assets/images/icons/GreenCircle.svg";
import YellowCircle from "../../../assets/images/icons/YellowCircle.svg";
import OrangeCircle from "../../../assets/images/icons/OrangeCircle.svg";
import PurpleCircle from "../../../assets/images/icons/PurpleCircle.svg";
import BlueCircle from "../../../assets/images/icons/BlueCircle.svg";
import GrayCircle from "../../../assets/images/icons/GrayCircle.svg";
import smallXDarkIcon from "../../../assets/images/icons/smallXDark.svg";
import smallXLightIcon from "../../../assets/images/icons/smallXLight.svg";
import { useStore } from "../../core/providers/global.provider";
import { Put } from "../../core/services/clientApi";
import { offersPin } from "../../core/services/sparrow-api-endpoints";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { observer } from "mobx-react";

interface SelectedRateCardProps {
    lender: string;
    term: number;
    apr: number;
    colorIndex: number | null;
    cardIndex: number;
    setChartSelectedRate: (v: number | null) => void;
    offer: any;
    onPinned: Function;
}

export const SelectedRateCard: React.FunctionComponent<SelectedRateCardProps> = observer(
    ({ lender, term, apr, colorIndex, cardIndex, setChartSelectedRate, offer, onPinned }) => {
        const { setLoading } = useStore();
        const { jwt } = useOnboarding();

        const [isHover, setIsHover] = useState<boolean>(false);
        function handleMouseEnter() {
            setChartSelectedRate(cardIndex);
        }

        function handleMouseLeave() {
            setChartSelectedRate(null);
        }

        function getIcon(index: number | null) {
            switch (index) {
                case 0:
                    return YellowCircle;
                case 1:
                    return OrangeCircle;
                case 2:
                    return GreenCircle;
                case 3:
                    return PurpleCircle;
                case 4:
                    return BlueCircle;
                default:
                    return GrayCircle;
            }
        }

        async function unPinOffer() {
            const body = {
                requestId: offer.requestId,
                offerId: offer.id,
                pinned: false,
            };

            setLoading(true);

            try {
                const response = await Put(offersPin, jwt as string, body);
                onPinned(response.offer.id);
            } catch {}

            setLoading(false);
        }
        return (
            <>
                <div
                    className="selected-rate-card-container"
                    onMouseEnter={() => handleMouseEnter()}
                    onMouseLeave={() => handleMouseLeave()}
                >
                    <div className="selected-rate-card-left">
                        <img src={getIcon(colorIndex)} alt="icon" />
                    </div>
                    <div className="selected-rate-card-middle">
                        <div className="selected-rate-card-title">
                            {apr.toFixed(2)}%, {term} years
                        </div>
                        <div className="selected-rate-card-lender">{lender}</div>
                    </div>
                    <div className="selected-rate-card-right">
                        <img
                            onMouseEnter={() => setIsHover(true)}
                            onMouseLeave={() => setIsHover(false)}
                            onClick={() => unPinOffer()}
                            src={isHover ? smallXDarkIcon : smallXLightIcon}
                            alt="exit"
                        />
                    </div>
                </div>
            </>
        );
    }
);
