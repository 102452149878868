import { memo } from "react";

import "../../style.scss";
import { OfferCardHeaderLenderContainer } from "../../header/OfferCardHeaderLenderContainer";
import { OfferCardHeaderApplyButton } from "../../header/interaction/buttons/OfferCardHeaderApplyButton";
import { OfferCardHeaderDetail } from "../../header/detail/OfferCardHeaderDetail";
import { useMediaQuery } from "react-responsive";
import { OfferCardHeaderDetailsForMobile } from "../../header/detail/OfferCardHeaderDetailsForMobile";
import { offerRateRangeExploreEndpoint } from "../../../../core/services/sparrow-api-endpoints";
import { useOnboarding } from "../../../../core/providers/onboarding.provider";
import { Post } from "../../../../core/services/clientApi";
import { iOS } from "../../../../utils/global";
import { FeedbackModal } from "../../../modals/OffersPageModals/FeedbackModal";
import { observer } from "mobx-react";
import { useStore } from "../../../../core/providers/global.provider";

interface RateCardDirectApplyProps {
    logo?: any;
    lenderName?: string;
    tooltip?: string;
    ficoInfo?: {
        title?: string;
        value?: string;
    };
    fixedAPRInfo?: {
        title?: string;
        value?: string;
    };
    variableAPRInfo?: {
        title?: string;
        value?: string;
    };
    personalizedURL?: string;
}

const RateCardDirectApply: React.FunctionComponent<RateCardDirectApplyProps> = observer(({
    logo,
    lenderName,
    tooltip,
    ficoInfo,
    fixedAPRInfo,
    variableAPRInfo,
    personalizedURL,
}) => {
    const {setModal} = useStore();
    const {paaasCustomization} = useOnboarding();
    const isMore1200px = useMediaQuery({
        query: "(min-width: 1200px)",
    });

    const isMore1350px = useMediaQuery({
        query: "(min-width: 1350px)",
    });

    const { jwt, formData } = useOnboarding();

    return (
        <>
            {lenderName && (
                <div className="offer-card">
                    <div className="offer-card-header">
                        <div className="lender-and-detail-header-section">
                            <OfferCardHeaderLenderContainer
                                lenderName={lenderName}
                                imageUrl={logo}
                                isDefault={false}
                            />
                            {isMore1200px && (
                                <div className="offer-information-section-container">
                                    <div className="offer-information-wrapper">
                                        {ficoInfo && (
                                            <OfferCardHeaderDetail
                                                value={ficoInfo.value}
                                                title={ficoInfo.title}
                                            />
                                        )}
                                    </div>
                                    <div className="offer-information-wrapper">
                                        {fixedAPRInfo && (
                                            <OfferCardHeaderDetail
                                                value={fixedAPRInfo.value}
                                                title={fixedAPRInfo.title}
                                                content={
                                                    fixedAPRInfo.value === "N/A"
                                                        ? undefined
                                                        : tooltip
                                                }
                                            />
                                        )}
                                    </div>
                                    {isMore1350px && (
                                        <div className="offer-information-wrapper">
                                            {variableAPRInfo && (
                                                <OfferCardHeaderDetail
                                                    value={variableAPRInfo.value}
                                                    title={variableAPRInfo.title}
                                                    content={
                                                        variableAPRInfo.value === "N/A"
                                                            ? undefined
                                                            : tooltip
                                                    }
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        {!isMore1200px && (
                            <OfferCardHeaderDetailsForMobile
                                offerDetails={[
                                    {
                                        title: ficoInfo?.title,
                                        value: ficoInfo?.value,
                                    },
                                    {
                                        title: fixedAPRInfo?.title,
                                        value: fixedAPRInfo?.value,
                                        tooltip:
                                            fixedAPRInfo?.value === "N/A" ? undefined : tooltip,
                                    },
                                    {
                                        title: variableAPRInfo?.title,
                                        value: variableAPRInfo?.value,
                                        tooltip:
                                            variableAPRInfo?.value === "N/A" ? undefined : tooltip,
                                    },
                                ]}
                            ></OfferCardHeaderDetailsForMobile>
                        )}

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                width: "100%",
                                paddingBottom: !isMore1200px ? "16px" : "0px",
                            }}
                        >
                            <OfferCardHeaderApplyButton
                                isDefault={false}
                                handleApply={async () => {
                                    let tab: any = null;
                                    if (iOS()) {
                                        tab = window.open("about:blank");
                                    }
                                    if (jwt) {
                                        try {
                                            const response: { offer: any, externalUrl: string, ratingOfExperience?: number } = await Post(offerRateRangeExploreEndpoint, jwt, {
                                                lender: lenderName,
                                                requestId: formData.id,
                                            });

                                            if (iOS() && tab) {
                                                tab.location = personalizedURL;
                                                tab.focus();
                                            } else {
                                                window.open(personalizedURL, "_blank");
                                            }
                                            
                                            const displayFeedbackView = paaasCustomization?.toggleableViews.displayFeedbackView;

                                            if (!response.ratingOfExperience && displayFeedbackView) {
                                                return setModal(() => <FeedbackModal />, false, true, "lg", true);
                                            }
                                        } catch (err) {
                                            console.error(err);
                                        }
                                    }
                                }}
                                isActive={false}
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
});

export default memo(RateCardDirectApply);
