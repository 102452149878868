import { CSSProperties, FC, HTMLAttributeAnchorTarget, memo } from "react";
import Spinner from "../../spinner";
import "./style.scss";
import { SwitchTransition, CSSTransition } from "react-transition-group";

interface IRegularButton {
    disabled?: boolean;
    bordered?: boolean;
    hidden?: boolean;
    onSubmit?: () => void;
    onKeyPress?: (e: React.KeyboardEvent) => void;
    link?: string;
    additionalClass?: string;
    id?: string;
    style?: CSSProperties;
    size?: "auto" | "strong";
    tabIndex?: number;
    target?: HTMLAttributeAnchorTarget;
    loading: boolean;
}

const Button: FC<IRegularButton> = ({
    disabled = false,
    children,
    bordered = false,
    hidden = false,
    onSubmit,
    tabIndex = 0,
    link = null,
    additionalClass = "",
    id = "",
    size = "auto",
    style,
    onKeyPress,
    target = "_self",
    loading = false,
}) => {
    return (
        <>
            {link !== null ? (
                <a
                    target={target}
                    id={id}
                    href={link}
                    className={`${bordered ? "btn-secondary" : "btn-primary"}  ${
                        disabled && "disabled"
                    } ${additionalClass} ${
                        size === "strong" ? "btn-strong-size" : ""
                    } with-loading`}
                    style={style}
                    onClick={onSubmit}
                    onKeyPress={onKeyPress}
                    tabIndex={tabIndex}
                >
                    <SwitchTransition>
                        <CSSTransition
                            key={loading ? "Spinner" : "Children"}
                            addEndListener={(node, done) =>
                                node.addEventListener("transitionend", done, false)
                            }
                            classNames="button-fade"
                        >
                            <span>{loading ? <Spinner /> : children}</span>
                        </CSSTransition>
                    </SwitchTransition>
                </a>
            ) : (
                <div
                    id={id}
                    className={`${bordered ? "btn-secondary" : "btn-primary"}  ${
                        disabled && "disabled"
                    } ${additionalClass} ${
                        size === "strong" ? "btn-strong-size" : ""
                    } with-loading`}
                    style={style}
                    onClick={onSubmit}
                    onKeyPress={onKeyPress}
                    tabIndex={tabIndex}
                >
                    <SwitchTransition>
                        <CSSTransition
                            key={loading ? "Spinner" : "Children"}
                            addEndListener={(node, done) =>
                                node.addEventListener("transitionend", done, false)
                            }
                            classNames="button-fade"
                        >
                            <span>{loading ? <Spinner /> : children}</span>
                        </CSSTransition>
                    </SwitchTransition>
                </div>
            )}
        </>
    );
};

export const RegularButtonWithLoading: React.FunctionComponent<IRegularButton> = memo(Button);
