import { useNavigate } from "react-router-dom";

import { dashboardUrl } from "../../../../core/routes/navigation-urls";
import { DashboardIcon } from "../../../../../sparrow-ui-library/src/assets/icons/dashboard-icon";

import "./styles.scss";
import { isDashboardPage } from "../../../../utils/routing";
import { useLocation } from "react-router-dom";

interface ILeftNavigationDashboardButton {
    setMenuExpandDropdown: Function;
    setMenuExpand: Function;
    setShowSideBarHandlerForMobile: Function;
}

export const LeftNavigationDashboardButton = ({
    setMenuExpandDropdown,
    setMenuExpand,
    setShowSideBarHandlerForMobile,
}: ILeftNavigationDashboardButton) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    return (
        <li>
            <button
                onClick={() => {
                    navigate(dashboardUrl);
                    setShowSideBarHandlerForMobile(false);
                    setMenuExpandDropdown(undefined);
                    setMenuExpand(false);
                }}
            >
                <DashboardIcon isActive={isDashboardPage(pathname)} />
                <div>Dashboard</div>
            </button>
        </li>
    );
};
