import { Address } from "../../../sparrow-ui-library/src/constants/default-inputs";
import { housingTypes } from "../../../sparrow-ui-library/src/constants/dropdown-options";
import {
    FormFieldType,
    FormInputsConfig,
    FormFieldName,
    FormsConfig,
} from "../../../sparrow-ui-library/src/types/form-types";
import { PrequalificationRequestInput } from "../../../sparrow-ui-library/src/types/sparrow-types";
import { amountFormat } from "../../../sparrow-ui-library/src/utils/general-utils";
import { UserType } from "../../../sparrow-ui-library/src/types/sparrow-types";

export const getFormsConfig = (inputs: FormsConfig): FormInputsConfig => {
    const { formData, applicableFormData, getApplicableUserText, isSameAsMailingAddress } = inputs as {
        formData: PrequalificationRequestInput;
        applicableFormData: PrequalificationRequestInput;
        getApplicableUserText: (text: string) => string;
        isSameAsMailingAddress: boolean;
    };
    const hasPermAddress = !!applicableFormData.permanentAddress;
    
    return {
        forms: [
            {
                formType: ["permanentAddress"],
                header: "Permanent Address",
                independentFields: [
                    {
                        formFieldType: FormFieldType.AddressAutocomplete,
                        asksForMailingAddress: true,
                        id: "permanentAddress",
                        name: FormFieldName.PermanentAddress,
                        label: getApplicableUserText("Search for your permanent address"),
                        placeholder: "Search",
                        inputValue: hasPermAddress
                            ? { ...applicableFormData.permanentAddress, mailingAddressIsSameAsPermanentAddress: isSameAsMailingAddress }
                            : { ...Address(), mailingAddressIsSameAsPermanentAddress: true },
                        backendValue: "",
                        errorMessage: "",
                        userType: formData?.userType as UserType,
                    },
                ],
            },
            {
                formType: ["mailingAddress"],
                header: "Enter your mailing address",
                independentFields: [
                    {
                        formFieldType: FormFieldType.AddressAutocomplete,
                        isPermanentAddress: false,
                        id: FormFieldName.MailingAddress,
                        name: FormFieldName.MailingAddress,
                        label: getApplicableUserText("Search for your mailing address"),
                        placeholder: "Search",
                        inputValue: hasPermAddress ? applicableFormData.mailingAddress : Address(),
                        backendValue: "",
                        errorMessage: "",
                    },
                ],
            },
            {
                header: "Housing Expense",
                independentFields: [
                    {
                        formFieldType: FormFieldType.ReadonlyDropdown,
                        id: FormFieldName.HousingType,
                        name: FormFieldName.HousingType,
                        label: getApplicableUserText("What's your housing situation?"),
                        placeholder: "Select",
                        inputValue:
                            (applicableFormData.housing && applicableFormData.housing.type) ?? "",
                        backendValue: "",
                        errorMessage: "",
                        dropdownOptions: housingTypes,
                    },
                    {
                        formFieldType: FormFieldType.TextBox,
                        type: "text",
                        id: FormFieldName.HousingPaymentAmount,
                        name: FormFieldName.HousingPaymentAmount,
                        label: getApplicableUserText("What's your housing monthly payment?"),
                        placeholder: "(e.g., $1,500)",
                        inputValue: applicableFormData.housing
                            ? amountFormat(applicableFormData.housing.payment.amount)
                            : "",
                        backendValue: "",
                        errorMessage: "",
                    },
                ],
            },
        ],
    };
};
