import { Get } from "../../core/services/clientApi";
import { degrees, major, school, visaTypesLink } from "../../core/services/sparrow-api-endpoints";

export const getDegrees = () =>
    Get(degrees).then((value) => {
        if (value && value.degrees) {
            // @ts-ignore
            return value.degrees.map((v) => {
                return {
                    value: v,
                    label: v,
                };
            });
        }
    });

export const getVisaTypes = () =>
    Get(visaTypesLink).then((value) => {
        if (value && value.visaTypes) {
            // @ts-ignore
            return value.visaTypes.map((v) => {
                return {
                    value: v,
                    label: v,
                };
            });
        }
    });

export const getSchool = () =>
    Get(school).then((value) => {
        if (value && value.schools) {
            // @ts-ignore
            return value.schools.map((v) => {
                return {
                    value: v,
                    label: v,
                };
            });
        }
    });

export const getMajors = () =>
    Get(major).then((value) => {
        if (value && value.majors) {
            // @ts-ignore
            return value.majors.map((v) => {
                return {
                    value: v,
                    label: v,
                };
            });
        }
    });
