import { observer } from "mobx-react";
import { useOnboarding } from "../../../../../common/core/providers/onboarding.provider";
import { OnboardingStore } from "../../../../../common/store/onboarding";
import { getPqStepContent } from "../../../utils/getPqStepContent";
import { PrequalifyStepCard } from "./PrequalifyStepCard";
import { PopupContentBottomExtension } from "../PopupContentBottomExtension";
import { UserType } from "../../../../../common/constants/defaultInputs";
import { Button, ButtonTypes } from "../../../../../common/components/buttons/button/Button";
import { IncludedUserStatus } from "../../../../../sparrow-ui-library/src/types/sparrow-types";
import "./styles.scss";

/* 
Displayed to Creator and on Included User's account. Does not apply to Creator directly including user.
*/ 
const PrequalifyStepCards: React.FC<{ onboarding: OnboardingStore }> = observer(({ onboarding }) => {
    const isLatestIncompleteStep = (stepIndex: number) => {
        // If the user is not the creator, we need to add one to stepsFinished because we always want to display the loan size step as the first step. Included user does not need to fill out the loan size step.
        return onboarding.formData.isCreator
            ? onboarding.formData.stepsFinished === stepIndex
            : onboarding.formData.stepsFinished + 1 === stepIndex;
    };

    return (
        <div className={`pqStepCards ${onboarding.formData.isCreator && onboarding.formData.includedUser?.status === IncludedUserStatus.DIRECT_STARTED ? "main-dir-inclusion-mobile" : ""}`}>
            {getPqStepContent(onboarding).map((step, i) => {
                return (
                    <PrequalifyStepCard
                        key={step.title}
                        stepTitle={step.title}
                        stepData={step.formData}
                        stepUrl={step.formUrl}
                        isComplete={!!step.formData}
                        isLatestIncomplete={isLatestIncompleteStep(i)}
                    />
                );
            })}
        </div>)

});

/* 
Displayed only to an Included User who has not accepted their invite to join the request on their account
*/
const AcceptInviteContent: React.FC<{ onboarding: OnboardingStore }> = observer(({ onboarding }) => {
    const {companyName} = useOnboarding();

    return (
        <div className="options-container">
            <p>
                {onboarding.formData.creator?.firstName} has invited you to be the
                primary {onboarding.getCreatorUserType()} on their request for
                pre-qualified rates. Once you complete your portion of the form,
                {companyName} will show you and {onboarding.formData.creator?.firstName}{" "}
                your pre-qualified rates.
            </p>
        </div>
    )
});

/*
Displayed only to both the Creator and Included User when either user has explored a pre-qualified rate with a lender 
*/
const BeginNewRequestContent: React.FC<{ onboarding: OnboardingStore }> = observer(
    ({ onboarding }) => {
        return (
            <div className="flex-col-0 already-explored">
                <p className="p-md-14-regular clr-neutral-500">
                    You have already explored a pre-qualified rate with a lender. To explore a
                    different pre-qualified rate, please start a new request.
                </p>
                <Button
                    buttonType={ButtonTypes.PRIMARY}
                    userType={UserType.BORROWER}
                    float="left"
                    onClick={() => {
                        onboarding.resetActiveLoanVariables();
                        onboarding.showDisplayNewRequestMenu();
                    }}
                >
                    Start a new request
                </Button>
            </div>
        );
    }
);

/*
Main component that displays the Prequalify popup's content using the above components
*/
export const PrequalifyPopup = observer(() => {
    const onboarding = useOnboarding();

    const displayAcceptInviteText = onboarding.includedUserIsInvitedAndHasNotAcceptedInvite();

    const { offerExplored } = onboarding.activePostPrequalRequestData?.compareAndSelect ?? {};

    const displayBottomExtension = onboarding.formData.includedUser && !offerExplored;

    return (
        <>
            {/* //If there isn't an explored offer, than user can still edit their request */}
            {!offerExplored && (
                <>
                    {!displayAcceptInviteText && <PrequalifyStepCards onboarding={onboarding} />}
                    {displayAcceptInviteText && <AcceptInviteContent onboarding={onboarding} />}
                </>
            )}

            {/* If there is an explored offer, than user needs to create a new request */}
            {offerExplored && (
                <BeginNewRequestContent onboarding={onboarding} />
            )}
            {/* Display the Prequalify popup's extention only if there is an included user and an offer has not been explored */}
            {displayBottomExtension && <PopupContentBottomExtension />}
        </>
    );
});
