import { LoanType } from "../../sparrow-ui-library/src/types/sparrow-types";
import { Get, Post, Put } from "../core/services/clientApi";
import { base, utmUpdatesEndpoint } from "../core/services/sparrow-api-endpoints";
import { isMarketplaceEnv } from "./config";

export const formVersion = Number(process.env.REACT_APP_FORM_VERSION);

export const errorLogs = (apiHandlerName: string, error) => {
    return `Error in ${apiHandlerName} API handler, error code: ${error.code}, description: ${error.userDescription}`;
};

export const sentenceCase = (str?: string) => {
    if (!str) {
        return "";
    }

    return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getSentenceCase = (str: string) => {
    if (!str) {
        return "";
    }

    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const firstCharUpperCase = (str: string) => {
    const words = str.split(" ");

    const newWords = words.map((word, i) => {
        if (i === 0) {
            return sentenceCase(word);
        } else {
            return word.toLocaleLowerCase();
        }
    });
    return newWords.join(" ");
};

export const EmailEXP =
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

export const checkDollar = (string: string) => {
    if (string === "USD") {
        return "$";
    } else if (string === "$") {
        return "$";
    } else {
        return "";
    }
};


const ORGANIC_UTM_SOURCE = "sparrow-organic-utm-source";
const EMPTY_UTM_VALUE = "empty-utm-value";

export const sendUtms = (token: string) => {
    const localBody = {
        source: localStorage.getItem("source") ?? ORGANIC_UTM_SOURCE,
        campaign: localStorage.getItem("campaign") ?? EMPTY_UTM_VALUE,
        medium: localStorage.getItem("medium") ?? EMPTY_UTM_VALUE,
    };

    const formattedLocalBody = {
        source: localBody.source !== "null" ? localBody.source : ORGANIC_UTM_SOURCE,
        campaign: localBody.campaign !== "null" ? localBody.campaign : EMPTY_UTM_VALUE,
        medium: localBody.medium !== "null" ? localBody.medium : EMPTY_UTM_VALUE,
    };

    if (!formattedLocalBody.source && !formattedLocalBody.campaign && !formattedLocalBody.medium) {
        return;
    }

    Put(utmUpdatesEndpoint, token, formattedLocalBody).catch((e) => {
        console.error(e);
    });
};

export const formatDate: any = (
    _date: number,
    returnGrad = false,
    fullMonth: boolean = false,
    returnYear: boolean = false
) => {
    const date = new Date(new Date().setTime(_date * 1000));
    const { day, month, year } = new Intl.DateTimeFormat("en", {
        day: "numeric",
        month: fullMonth ? "long" : "numeric",
        year: "numeric",
    })
        .formatToParts(date)
        .reduce((acc, part) => {
            if (part.type !== "literal") {
                acc[part.type] = part.value;
            }
            return acc;
        }, Object.create(null));
    // return `${month} ${day}, ${year}`
    if (returnYear) {
        return `${year}`;
    }
    if (fullMonth) {
        return `${month} ${day}, ${year}`;
    }
    if (returnGrad) {
        return `${month < 10 ? "0" : ""}${month}/${year}`;
    }
    return `${month}/${day}/${year}`;
};

export const clearLocalStorageData = () => {
    const utm_source = localStorage.getItem("utm_source");
    localStorage.clear();

    if (utm_source) {
        localStorage.setItem("utm_source", utm_source);
    }
};

export const checkObjectProperty = (object: any, property: string) => {
    if (typeof object === "object") {
        return Object(object).hasOwnProperty(property);
    } else {
        return false;
    }
};

//@ts-ignore
export const linkHandler = (urlParams, token, snackError, history) => {
    const endpointUrl = new URLSearchParams(urlParams).get("endpointUrl");
    const bodyString = new URLSearchParams(urlParams).get("body");
    const body = bodyString ? JSON.parse(bodyString) : {};
    const queryStringParameters = new URLSearchParams(urlParams).get("queryStringParameters");
    const queryParameters = queryStringParameters !== null && JSON.parse(queryStringParameters);
    const requestType = new URLSearchParams(urlParams).get("requestType");
    const redirectUrl = new URLSearchParams(urlParams).get("redirectUrl");
    const redirectLocationName = new URLSearchParams(urlParams).get("redirectLocationName");
    let messageSuccess = "Request Completed";
    let messageError = "Request Error";
    let queryParametersStringify = "";
    let queryParametersStringifySerialize = "";

    // @ts-ignore
    const serialize = (obj) => {
        let str: any = [];
        let p: any;
        for (p in obj)
            if (obj.hasOwnProperty(p)) {
                const strItem: string = encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]);
                str.push(strItem);
            }
        return str.join("&");
    };

    if (queryStringParameters !== null) {
        queryParametersStringifySerialize = serialize(queryParameters);
        queryParametersStringify = "?" + queryParametersStringifySerialize;
    }

    if (endpointUrl !== null) {
        if (requestType === "PUT") {
            Put(base + endpointUrl.substr(1) + queryParametersStringify, token, body)
                .then((value) => {
                    if (endpointUrl.search("resend-email") > 0) {
                        messageSuccess = "Sent! We just resent the invitation to your cosigner.";
                    }
                    if (redirectUrl) {
                        redirectUrl && history.push(redirectUrl);
                    }

                    snackError(messageSuccess, "success");
                })
                .catch((e) => {
                    if (redirectUrl) {
                        redirectUrl && history.push(redirectUrl);
                    }
                    snackError(messageError, "error");
                });
        } else if ("POST") {
            Post(base + endpointUrl.substr(1), token, body)
                .then((value) => {
                    if (redirectUrl) {
                        redirectUrl && history.push(redirectUrl);
                    }
                    if (!redirectUrl && endpointUrl.includes("/users/public/")) {
                        history.push("/");
                    }
                    snackError(messageSuccess, "success");
                })
                .catch((e) => {
                    if (redirectUrl) {
                        redirectUrl && history.push(redirectUrl);
                    }
                    if (!redirectUrl && endpointUrl.includes("/users/public/")) {
                        history.push("/");
                    }
                    snackError(messageError, "error");
                });
        } else if ("GET") {
            Get(base + endpointUrl.substr(1), token, null, queryParametersStringifySerialize)
                .then((value) => {
                    if (redirectUrl) {
                        redirectUrl && history.push(redirectUrl);
                    }
                    snackError(messageSuccess, "success");
                })
                .catch((e) => {
                    if (redirectUrl) {
                        redirectUrl && history.push(redirectUrl);
                    }
                    snackError(messageError, "error");
                });
        }
    }

    if (!endpointUrl && !bodyString && !requestType && !redirectLocationName && redirectUrl) {
        history.push(redirectUrl);
    }
};

export const formatPercent = (number: number) => {
    return Number(number).toFixed(2) + "%";
};

// Disable Default Scroll
export const disableDefaultScroll = (disable: boolean = false) => {
    const body: any = document.querySelector("body");
    const html: any = document.querySelector("html");

    if (html) {
        html.style.overflowY = disable ? "hidden" : "auto";
    }
    if (body) {
        body.style.overflowY = disable ? "hidden" : "auto";
    }
};

// Detect IOS
export const iOS = () => {
    return (
        ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
            navigator.platform
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes("Mac") && "ontouchend" in document) ||
        (navigator.vendor &&
            navigator.vendor.indexOf("Apple") > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf("CriOS") === -1 &&
            navigator.userAgent.indexOf("FxiOS") === -1)
    );
};

export const getArticle = (word: string) => {
    const lowercaseWord = word.toLowerCase();
    const startsWithVowel = /^[aeiou]/.test(lowercaseWord);
    return startsWithVowel ? "an" : "a";
};

export const getPaaasClass = () => {
    return !isMarketplaceEnv() ? "paaas" : "" ;
}

export const getPossesiveCase = (word: string) => {
    const lastLetter = word[word.length - 1];
    return lastLetter === "s" ? `${word}'` : `${word}'s`;
}

// Max loan amount is 100k for PSL and 500k for SLR.
export const getMaxLoanAmount = (loanType: LoanType | null) => {
    // Guard against undefined formData.type
    if (!loanType) return 100000;

    return loanType === "PSL" ? 100000 : 500000;
};