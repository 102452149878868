import { ReactNode, FC } from "react";
import styles from "./styles.module.scss";

interface HomeCardProps {
    icon: ReactNode;
    mainText: string;
    subText: string;
    noIcon?: boolean;
}

export const HomeCard: FC<HomeCardProps> = (props) => {

    return (
        <div className={styles["card-container"]}>
            <div className={`${styles.img} ${styles[props.noIcon ? "noIcon" : ""]}`}>{props.icon}</div>
            <span className={styles.title}>{props.mainText}</span>
            <span className={styles.subtitle}>{props.subText}</span>
        </div>
    );
};