import "./style.scss";

interface IHeaderButton {
    button: {
        text: string;
        url: string;
        isOpensNewTab: boolean;
        isEnableCustomButton: boolean;
    };
}

export const HeaderButton = ({ button }: IHeaderButton) => {
    return (
        <button className="custom-header-button">
            <a
                href={button?.url ? button.url : "#"}
                target={button?.isOpensNewTab ? "_blank" : "_self"}
                rel="noopener noreferrer"
            >
                {button?.text}
            </a>
        </button>
    );
};
