import { MoneyAmountInput } from "../../../../sparrow-ui-library/src/types/sparrow-types";
import { enumRequestStatus } from "../../../models/request";
import { LoanTypes } from "../../../store/onboarding";
import { IRefAmount } from "../../../models/request";
import { OffersTablePreferences } from "../../../../sparrow-ui-library/src/types/sparrow-types";
import { MembershipElection } from '../../../../sparrow-ui-library/src/types/sparrow-types/membership-election';

export interface Range {
    lowEnd: string;
    highEnd: string;
}

export type LenderChecked = { lenderName: string; lenderImageUrl: string };

export interface UserIneligibleReasonsOutput {
    partnersChecked: LenderChecked[];
    reasonsFailed: string[];
}

export enum LoanType {
    VARIABLE = "variable",
    FIXED = "fixed",
}

export interface paymentComponents {
    interest: MoneyAmountInput;
    principal: MoneyAmountInput;
}

export interface GraphSingleDataPoint {
    totalAmountRemaining: paymentComponents;
    totalPaid: paymentComponents;
    monthlyPayment: MoneyAmountInput;
    totalTimeRemaining: number;
    age: number;
    date: number;
}

export interface SingleOfferGraphData {
    offerId: string;
    lender: string;
    term: number;
    apr: number;
    totalCost: MoneyAmountInput;
    averageMonthlyPayment: MoneyAmountInput;
    totalTimeInDebt: number;
    dataPoints: GraphSingleDataPoint[];
}

export type StudentLoanFee = {
    originationFee: number;
    applicationFee: number;
    prepaymentPenalty: number;
};

export interface IOffer {
    requestId: string;
    type: string;
    lender: string;
    loanType: LoanType;
    startingAPR: number;
    aprCap: number;
    fixedAPR: number;
    term: number;
    repaymentTerms: number;
    monthlyPaymentInSchool: MoneyAmountInput;
    monthlyPaymentAfterGrad: MoneyAmountInput;
    averagePayment: MoneyAmountInput;
    amount: MoneyAmountInput;
    totalInterestExpense: MoneyAmountInput;
    discount: number;
    paymentPlan: string;
    id: string;
    declined: boolean;
    explored: boolean;
    pinned: boolean;
    cosignerPinned: boolean;
    createdAt: number;
    isLowestAPR: boolean;
    isLowestAvgMonthly: boolean;
    isShortestTerm: boolean;
    isLowestTotalInterest: boolean;
    graphData: SingleOfferGraphData[];
    imageUrl: string;
    forYou: boolean;
    bookmarked: boolean;
    cosignerBookmarked: boolean;
    satisfactoryAcademicProgressRequired?: boolean;
    pastDueBalancesAllowed?: number;
    personalizedUrl: string;
    applyWithoutCosigner: boolean;
    amountWasRounded: boolean;
    fees?: StudentLoanFee;
}

export interface ICosignerFicoRating {
    lowEnd: number;
    highEnd: number;
    quality: string;
}

export interface IOfferMetadata {
    lowAmountRange: Range;
    moderateAmountRange: Range;
    highAmountRange: Range;
    lowestImmediate: string;
    lowestNonImmediate: string;
    lowestAPRFix: number;
    lowestAPRVar: number;
    offersCount: number;
    cosignerFicoRating: ICosignerFicoRating;
    aprSaved: number;
    totalAmountSaved: MoneyAmountInput;
    monthlyAmountSaved: MoneyAmountInput;
}

export interface IIncomeBasedOffer {
    id: string;
    userId: string;
    requestId: string;
    lender: string;
    createdAt: number;
    amount: IRefAmount;
    term: number;
    pinned: boolean;
    cosignerPinned: boolean;
    declined: boolean;
    converted: boolean;
    explored: boolean;
    imageUrl: string;
    personalizedUrl: string;
    paymentPlan: string;
    projectedSalary: IRefAmount;
    incomeSharePercentage: number;
    maximumPaymentAmount: IRefAmount;
    monthlyPaymentAmount: IRefAmount;
    minimumIncomeThreshold: IRefAmount;
    maximumNumberOfPayments: number;
    apr: number;
    aprWithIncomeOf65k: number;
    aprWithIncomeOf45k: number;
    institutionName: string;
    amountPaidToYou: IRefAmount;
    amountPaidToOthers: IRefAmount;
    amountFinanced: IRefAmount;
    initialFinanceCharges: IRefAmount;
    financeCharge: IRefAmount;
    fixedMonthlyPayment45k: IRefAmount;
    fixedMonthlyPayment65k: IRefAmount;
    totalPayments45k: number;
    totalPayments65k: number;
    monthlyPayment45k: IRefAmount;
    monthlyPayment65k: IRefAmount;
    finalPayment45k: IRefAmount;
    finalPayment65k: IRefAmount;
    cancellationDate: string;
    defermentEndDate: string;
    fixedPaymentStartDate: string;
    fixedPaymentEndDate: string;
    monthlyPaymentStartDate: string;
    monthlyPaymentEndDate: string;
    expectedIncomeBasedPayment: IRefAmount;
    fixedMonthlyPayment: IRefAmount;
    bookmarked: boolean;
    cosignerBookmarked: boolean;
    applyWithoutCosigner: boolean;
    satisfactoryAcademicProgressRequired?: boolean;
    pastDueBalancesAllowed?: number;
}

export enum StudentLoanRequestValidationErrorString {
    NO_VALIDATION_ERROR = "no validation error",
    VALIDATION_ERROR = "validation error",
    TIMEOUT = "validation error - timeout",
    NO_FILE_RETURNED = "validation error - no file returned",
    FROZEN_CREDIT = "validation error - frozen credit",
    LACK_OF_CREDIT = "validation error - lack of credit",
}

//Pre-qual Decision Page PRD: https://docs.google.com/document/d/1KbXcNU5TRvG2N7bby-0kRcoquuy7GRfgdiMGxqXNT3M/edit#heading=h.m9xdzinsu3ss
export enum DeclineReasons {
    BORROWER_FICO_LOW = "Your FICO score is too low",
    COSIGNER_FICO_LOW = "Your cosigner's FICO score is too low",
    BORROWER_DTI_HIGH = "Your debt-to-income ratio is too high",
    COSIGNER_DTI_HIGH = "Your cosigner's debt-to-income ratio is too high",
    BORROWER_INCOME_LOW = "You do not meet the income threshold",
    COSIGNER_INCOME_LOW = "Your cosigner does not meet the income threshold",
    BORROWER_DEROGATORY_REMARK = "You have a derogatory remark on your credit report",
    COSIGNER_DEROGATORY_REMARK = "Your cosigner has a derogatory remark on their credit report",
    BORROWER_CREDIT_HISTORY = "Your credit history is not long enough",
    COSIGNER_CREDIT_HISTORY = "Your cosigner's credit history is not long enough",
    BORROWER_AGE = "You are not old enough yet to be eligible",
    COSIGNER_AGE = "Your cosigner is not old enough yet to be eligible",
    BORROWER_ATTENDS_INELIGIBLE_SCHOOL = "You do not attend an eligible school",
    BORROWER_ATTENDED_INELIGIBLE_SCHOOL = "You did not attend an eligible school",
    BORROWER_COUNTRY = "You are not a citizen of an eligible country",
    COSIGNER_COUNTRY = "Your cosigner is not a citizen of an eligible country",
    BORROWER_STATE = "You are not a resident of an eligible state",
    COSIGNER_STATE = "Your cosigner is not a resident of an eligible state",
}

export type DeclineReasonDisplayMapping = {
    title: DeclineReasons;
    textContent: string;
    cta?: string;
    ctaUrl?: string;
    scrollToApplyDirectly?: boolean;
};

export type DeclinedLender = {
    lenderName: string;
    lenderImageUrl: string;
};

export interface Results {
    borrower: {
        hard: DeclineReasons[];
        soft: DeclineReasons[];
    };
    cosigner: {
        hard: DeclineReasons[];
    };
    declinedLenders: DeclinedLender[];
}

export interface PrequalSubmissionResults {
    status: enumRequestStatus;
    type: LoanTypes;
    offerInformation: {
        offers: IOffer[];
        offerMetadata: IOfferMetadata;
        incomeBasedOffers: IIncomeBasedOffer[];
    };
    declineInformation: UserIneligibleReasonsOutput;
    results: Results;
    validationInformation: {
        errorStatus: StudentLoanRequestValidationErrorString;
        cosignerErrorStatus: StudentLoanRequestValidationErrorString;
    };
    expandedSearchEligible: boolean;
    membershipElection?: MembershipElection;
}

export interface IQuizPutInput {
    quizAnswerInput: {
        id: string;
        answer: OffersTablePreferences;
    };
    token: string;
}
