import { IncludedUser, IncludedUserStatus } from "../../../../../sparrow-ui-library/src/types/sparrow-types";
import { UserType } from "../../../../constants/defaultInputs";
import { OnboardingStore } from "../../../../store/onboarding";
import { DeclineReasons, PrequalSubmissionResults } from "../../types/offers-api-types";
import { StudentLoanRequestValidationErrorString } from "../../types/offers-api-types";

export interface DeclineReasonsToDisplay {
    borrowerValidationError: StudentLoanRequestValidationErrorString[] | undefined;
    cosignerValidationError: StudentLoanRequestValidationErrorString[] | undefined;
    borrowerSoftReasons: DeclineReasons[] | undefined;
    borrowerHardReasons: DeclineReasons[] | undefined;
    cosignerHardReasons: DeclineReasons[] | undefined;
}

const getDeclineReasonsToDisplayOutput = (): DeclineReasonsToDisplay => {
    return {
        borrowerValidationError: undefined,
        cosignerValidationError: undefined,
        borrowerSoftReasons: undefined,
        borrowerHardReasons: undefined,
        cosignerHardReasons: undefined,
    };
};

const getDeclineReasonsFlags = (pqResults: PrequalSubmissionResults) => {
    const { results, validationInformation } = pqResults;
    return {
        borrowerHasValidationError:
            validationInformation.errorStatus !==
            StudentLoanRequestValidationErrorString.NO_VALIDATION_ERROR,
        cosignerHasValidationError:
            validationInformation.cosignerErrorStatus !==
            StudentLoanRequestValidationErrorString.NO_VALIDATION_ERROR,
        borrowerHasSoftReasons: !!results.borrower.soft.length,
        borrowerHasHardReasons: !!results.borrower.hard.length,
        cosignerHasHardReasons: !!results.borrower.hard.length,
    };
};

//PRD: https://docs.google.com/document/d/1KbXcNU5TRvG2N7bby-0kRcoquuy7GRfgdiMGxqXNT3M/edit#heading=h.4jknq64g7udi
const getSoloBorrowerRequestDeclineReasons = (
    pqResults: PrequalSubmissionResults
): DeclineReasonsToDisplay => {
    const { results, validationInformation } = pqResults;
    const declineReasonsToDisplay = getDeclineReasonsToDisplayOutput();
    const declineReasonsFlags = getDeclineReasonsFlags(pqResults);
    const { borrowerHasValidationError, borrowerHasSoftReasons, borrowerHasHardReasons } =
        declineReasonsFlags;

    if (borrowerHasValidationError) {
        declineReasonsToDisplay.borrowerValidationError = [validationInformation.errorStatus];
        return declineReasonsToDisplay;
    }

    if (borrowerHasSoftReasons) {
        declineReasonsToDisplay.borrowerSoftReasons = results.borrower.soft;
        return declineReasonsToDisplay;
    }

    if (borrowerHasHardReasons) {
        declineReasonsToDisplay.borrowerHardReasons = results.borrower.hard;
        return declineReasonsToDisplay;
    }

    return declineReasonsToDisplay;
};

const getValidationErrorReasons = (
    pqResults: PrequalSubmissionResults,
    activeUserType: UserType
): DeclineReasonsToDisplay => {
    const { results, validationInformation } = pqResults;
    const declineReasonsToDisplay = getDeclineReasonsToDisplayOutput();
    const declineReasonsFlags = getDeclineReasonsFlags(pqResults);
    const { borrowerHasValidationError, cosignerHasValidationError, cosignerHasHardReasons } =
        declineReasonsFlags;

    //cosigner validation error should be shown to BOTH the cosigner and the borrower.
    if (cosignerHasValidationError) {
        declineReasonsToDisplay.cosignerValidationError = [
            validationInformation.cosignerErrorStatus,
        ];
    }

    if (borrowerHasValidationError) {
        declineReasonsToDisplay.borrowerValidationError = [validationInformation.errorStatus];

        if (activeUserType === UserType.BORROWER && cosignerHasHardReasons) {
            declineReasonsToDisplay.cosignerHardReasons = results.cosigner.hard;
        }
    }

    return declineReasonsToDisplay;
};

//PRD: https://docs.google.com/document/d/1KbXcNU5TRvG2N7bby-0kRcoquuy7GRfgdiMGxqXNT3M/edit#heading=h.g5gmi324b2dy
const getCosignedRequestDeclineReasons = (
    pqResults: PrequalSubmissionResults,
    activeUserType: UserType
): DeclineReasonsToDisplay => {
    const { results } = pqResults;
    const declineReasonsToDisplay = getDeclineReasonsToDisplayOutput();
    const declineReasonsFlags = getDeclineReasonsFlags(pqResults);
    const {
        borrowerHasValidationError,
        cosignerHasValidationError,
        borrowerHasHardReasons,
        cosignerHasHardReasons,
    } = declineReasonsFlags;

    // if there are validation errors, show only these even though there may be other decline reasons
    if (borrowerHasValidationError || cosignerHasValidationError) {
        return getValidationErrorReasons(pqResults, activeUserType);
    }

    if (cosignerHasHardReasons) {
        declineReasonsToDisplay.cosignerHardReasons = results.cosigner.hard;
    }
    if (activeUserType === UserType.BORROWER && borrowerHasHardReasons) {
        declineReasonsToDisplay.borrowerHardReasons = results.borrower.hard;
    }

    return declineReasonsToDisplay;
};

export const getDeclineReasonsToDisplay = (
    pqResults: PrequalSubmissionResults,
    onboarding: OnboardingStore
): DeclineReasonsToDisplay => {
    const { userType, includedUser } = onboarding.formData;
    const isCosigned =
        (includedUser?.userType === UserType.COSIGNER || userType === UserType.COSIGNER) &&
        (includedUser as IncludedUser).status === IncludedUserStatus.CONFIRMED;
    //solo borrower
    if (!isCosigned) {
        return getSoloBorrowerRequestDeclineReasons(pqResults);
    }
    //cosigned - borrower/cosigner views
    return getCosignedRequestDeclineReasons(pqResults, userType as UserType);
};
