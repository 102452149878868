import { FC } from "react";

import { observer } from "mobx-react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import { isMarketplaceEnv } from "../../../utils/config";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import { dashboardUrl, paaasLandingUrl } from "../../../core/routes/navigation-urls";
import { borrowerOffersRoutingUrl } from "../../../core/routes/routing-urls";
import { FooterBox } from "../LeftNavigationFooterBox/FooterBox";
import { LeftNavigationMenuTop } from "./LeftNavigationMenuTop";
import { RatePageButtons } from "./RatePage/RatePageButtons";
import { MenuItems } from "../../../types/paaas/custom-menu-props";
import { useCheckPathName } from "../../../../sparrow-ui-library/src/hooks";

import "./styles.scss";

interface ILeftNavigationMenuContainer {
    userName?: {
        firstName: string;
        initials: string;
        name: string;
    };
    menuExpand: boolean;
    menuExpandDropdown?: MenuItems;
    getMenuItemClassAndFill: Function;
    setShowSideBarHandlerForMobile: Function;
    setMenuExpandDropdown: Function;
    setMenuExpand: Function;
}

export const LeftNavigationMenuContainer: FC<ILeftNavigationMenuContainer> = observer(
    ({
        userName,
        menuExpand,
        menuExpandDropdown,
        getMenuItemClassAndFill,
        setShowSideBarHandlerForMobile,
        setMenuExpandDropdown,
        setMenuExpand,
    }) => {
        const onboarding = useOnboarding();
        const navigate = useNavigate();
        const pathname = useLocation().pathname;

        // This media query is required for the function handleClick to reset expanded menu
        const isLess1024 = useMediaQuery({ query: "(max-width: 1024px)" });

        const handleClick = () => {
            if (isLess1024) {
                setMenuExpand(true);
                setMenuExpandDropdown(undefined);
                return;
            }
            if (pathname !== dashboardUrl) {
                onboarding.setKeepNewRequestMenuOnLoad(true);
                navigate(dashboardUrl);
            }
            setShowSideBarHandlerForMobile(false);
            setMenuExpand(false);
            onboarding.showDisplayNewRequestMenu();
            onboarding.resetActiveLoanVariables();
        };

        const isRatePage = useCheckPathName(borrowerOffersRoutingUrl);
        const isPAAASLandingPage = useCheckPathName(paaasLandingUrl);

        return (
            <div className={`left-navigation-menu ${isPAAASLandingPage ? "paaasLandingPage" : ""} ${isRatePage ? "decisionPage" : ""} ${!isMarketplaceEnv() ? "has-header-bar" : ""}`}>
                <div className="left-navigation-menu-top-container">
                    <LeftNavigationMenuTop // LeftNavigationMenuTop renders the main section of the menu including dashboard and custom buttons
                        menuExpand={menuExpand}
                        menuExpandDropdown={menuExpandDropdown}
                        handleClick={handleClick}
                        setMenuExpand={setMenuExpand}
                        getMenuItemClassAndFill={getMenuItemClassAndFill}
                        setMenuExpandDropdown={setMenuExpandDropdown}
                        setShowSideBarHandlerForMobile={setShowSideBarHandlerForMobile}
                    />
                    {isRatePage && (
                        <RatePageButtons
                            menuExpand={menuExpand}
                            setMenuExpandDropdown={setMenuExpandDropdown}
                            setMenuExpand={setMenuExpand}
                        />
                    )}
                </div>
                <div
                    className={`left-navigation-menu-bottom-container ${
                        menuExpand ? "expanded" : ""
                    }`}
                >
                    {userName && (
                        <FooterBox
                            userName={userName}
                            setShowSideBarHandlerForMobile={setShowSideBarHandlerForMobile}
                            setMenuExpandDropdown={setMenuExpandDropdown}
                            setMenuExpand={setMenuExpand}
                        />
                    )}
                </div>
            </div>
        );
    }
);
