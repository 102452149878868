import React from "react";

export const ProfessionalLoanIcon = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 8C9 7.44772 9.44772 7 10 7H30C30.5523 7 31 7.44772 31 8V38H9V8Z"
                fill="rgba(var(--borrowerPrimaryColor),1)"
            />
            <path
                d="M13 7H27V5C27 4.44772 26.5523 4 26 4H14C13.4477 4 13 4.44772 13 5V7Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <path
                d="M12 25C12 25.5523 12.4477 26 13 26H15C15.5523 26 16 25.5523 16 25V23C16 22.4477 15.5523 22 15 22H13C12.4477 22 12 22.4477 12 23V25Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <path
                d="M12 19C12 19.5523 12.4477 20 13 20H15C15.5523 20 16 19.5523 16 19V17C16 16.4477 15.5523 16 15 16H13C12.4477 16 12 16.4477 12 17V19Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <path
                d="M12 13C12 13.5523 12.4477 14 13 14H15C15.5523 14 16 13.5523 16 13V11C16 10.4477 15.5523 10 15 10H13C12.4477 10 12 10.4477 12 11V13Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <path
                d="M18 25C18 25.5523 18.4477 26 19 26H21C21.5523 26 22 25.5523 22 25V23C22 22.4477 21.5523 22 21 22H19C18.4477 22 18 22.4477 18 23V25Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <path
                d="M18 19C18 19.5523 18.4477 20 19 20H21C21.5523 20 22 19.5523 22 19V17C22 16.4477 21.5523 16 21 16H19C18.4477 16 18 16.4477 18 17V19Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <path
                d="M18 13C18 13.5523 18.4477 14 19 14H21C21.5523 14 22 13.5523 22 13V11C22 10.4477 21.5523 10 21 10H19C18.4477 10 18 10.4477 18 11V13Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <path
                d="M24 25C24 25.5523 24.4477 26 25 26H27C27.5523 26 28 25.5523 28 25V23C28 22.4477 27.5523 22 27 22H25C24.4477 22 24 22.4477 24 23V25Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <path
                d="M24 19C24 19.5523 24.4477 20 25 20H27C27.5523 20 28 19.5523 28 19V17C28 16.4477 27.5523 16 27 16H25C24.4477 16 24 16.4477 24 17V19Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <path
                d="M24 13C24 13.5523 24.4477 14 25 14H27C27.5523 14 28 13.5523 28 13V11C28 10.4477 27.5523 10 27 10H25C24.4477 10 24 10.4477 24 11V13Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <path
                d="M9 15H7C6.44772 15 6 15.4477 6 16V37C6 37.5523 6.44772 38 7 38H9V15Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <path
                d="M34 16C34 15.4477 33.5523 15 33 15H31V38H33C33.5523 38 34 37.5523 34 37V16Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <path
                d="M16 32C16 30.3431 17.3431 29 19 29H21C22.6569 29 24 30.3431 24 32V38H16V32Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
        </svg>
    );
};
