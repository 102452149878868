import { useNavigate } from "react-router-dom";
import { useOnboarding } from "../../../common/core/providers/onboarding.provider";
import { Button, ButtonTypes } from "../../../common/components/buttons/button/Button";
import { UserContactCard } from "../components/UserContactCard";
import { useStore } from "../../../common/core/providers/global.provider";
import { DeclineInvitationModal } from "../../../common/components/modals/User_Inclusion_Modals/DeclineInvitationModal";
import { observer } from "mobx-react";
import { PrequalUrlsEnum } from "../../../common/core/global_routing/front-end-urls";
import { PostPrequalificationAcceptInvite } from "../../../common/core/services/sparrow-api-handlers/prequalification-api-handlers";
import { AutofillRequestModal } from "../../../common/components/modals/AutofillRequestModal";
/*
Description: ReviewInvite is a page displayed to an invited user when they visit their account and have not yet accepted or rejected the invitation

Invited User Page: https://www.figma.com/file/Vwc8KmfCQ31VS28jOwBwYM/2.1.Sparrow.Borrower.Portal.NEW.Flows?node-id=2790-404340&t=OuvjeuCpOY6oT29P-0
As of: April 25, 2023
*/
export const UserInclusionReviewInvite = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const onboarding = useOnboarding();

    const creatorFirstName = onboarding.formData.creator?.firstName as string;

   
    const handleSecondaryButton = () => {
        store.setModal(() => <DeclineInvitationModal firstName={creatorFirstName} />);
    };

    const handlePrimaryButton = async () => {
        // If the user is eligible for auto-fill, present modal
        // Auto-fill modal will redirect user as necessary so return after call

        await PostPrequalificationAcceptInvite(onboarding.formData.id as string, onboarding.jwt as string);
        
        const autofillProps = onboarding.handleAutofillRequest();
        if(autofillProps){
            store.setModal(() => <AutofillRequestModal {...autofillProps}/>);
            return; 
        }

        navigate(PrequalUrlsEnum.CITIZENSHIP);
    };

    return (
        <>
            <div className="step-form-body">
                <UserContactCard />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        gap: "16px",
                        marginTop: "32px",
                    }}
                >
                    <Button
                        id="previous-button"
                        onClick={handleSecondaryButton}
                        buttonType={ButtonTypes.SECONDARY}
                        userType={onboarding.formData.userType}
                    >
                        Decline
                    </Button>
                    <Button
                        id="continue-button"
                        tabIndex={0}
                        onClick={handlePrimaryButton}
                        buttonType={ButtonTypes.PRIMARY}
                        userType={onboarding.formData.userType}
                    >
                        Continue
                    </Button>
                </div>
            </div>
        </>
    );
});
