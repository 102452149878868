import { MenuItem } from "../../components/MenuItem";
import { useNavigate } from "react-router-dom";
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider";
import { GeneralUrlsEnum } from "../../../../common/core/global_routing/front-end-urls";
import graduationHat from "../../../../assets/icons/lottie-icons/graduation-hat-(student loan).json";
import globe from "../../../../assets/icons/lottie-icons/globe-(international).json";
import dollarSignArrow from "../../../../assets/icons/lottie-icons/dollar-sign-arrow-(refinance).json";
import creditCardSwipe from "../../../../assets/icons/lottie-icons/credit-card-swipe-(credit history).json";
import creditCard from "../../../../assets/icons/lottie-icons/credit-card-(payment).json";
import ribbon from "../../../../assets/icons/lottie-icons/ribbon-(top rated).json";
import "./styles.scss";
import { UndergradLoanIcon, GradLoanIcon, ProfessionalLoanIcon, InternationalLoanIcon ,RefinanceLoanIcon, InternationalRefinanceIcon, } from "../../../../sparrow-ui-library/src/assets/icons/menu-icons";

export const MenuContainer = () => {
    const onboarding = useOnboarding();
    const navigate = useNavigate();

    const clickHandler = (loanType, title) => {
        onboarding.setLoanTypeAndSelection(loanType, title);
        return navigate(GeneralUrlsEnum.SELECT_USER_TYPE);
    };

    return (
        <div className="new-request-menu">
            <p className="title">Start a new request</p>
            <p className="subtitle">Get your pre-qualified rates in as little as 3 minutes.</p>
            <div className="menu-container">
                <MenuItem
                    id="Undergraduate Loan"
                    title="Undergraduate Loan"
                    loanType="PSL"
                    animationData={graduationHat}
                    icon= {()=> <UndergradLoanIcon />}
                    clickHandler={clickHandler}
                />
                <MenuItem
                    id="Graduate Loan"
                    title="Graduate Loan"
                    loanType="PSL"
                    animationData={creditCard}
                    icon= {()=> <GradLoanIcon />}
                    clickHandler={clickHandler}
                />
                <MenuItem
                    id="Professional Degree Loan"
                    title="Professional Degree Loan"
                    loanType="PSL"
                    animationData={ribbon}
                    icon= {()=> <ProfessionalLoanIcon />}
                    clickHandler={clickHandler}
                />
                <MenuItem
                    id="Refinance Student Debt"
                    title="Refinance Student Debt"
                    loanType="SLR"
                    animationData={dollarSignArrow}
                    icon= {()=> <RefinanceLoanIcon />}
                    clickHandler={clickHandler}
                />
                <MenuItem
                    id="International Loan"
                    title="International Loan"
                    loanType="PSL"
                    animationData={globe}
                    icon= {()=> <InternationalLoanIcon />}
                    clickHandler={clickHandler}
                />
                <MenuItem
                    id="International Refinance"
                    title="International Refinance"
                    loanType="SLR"
                    animationData={creditCardSwipe}
                    icon= {()=> <InternationalRefinanceIcon />}
                    clickHandler={clickHandler}
                />
            </div>
        </div>
    );
};
