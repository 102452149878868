import { useState } from "react";
import { observer } from "mobx-react";
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider";
import { RoadmapStepsContainer } from "../RoadmapStepsContainer";
import { RoadmapTopSection } from "../../components/RoadmapTopSection";
import { ExpiredContent } from "../../components/ExpiredContent/ExpiredContent";
import "./styles.scss";

export const RoadmapContainer = observer(() => {
    const onboarding = useOnboarding();
    const [showFullId, setShowFullId] = useState(false);
    const [showFullIdMobile, setShowFullIdMobile] = useState(false);
    
    return (
        <>
            {onboarding.formData && (
                <div className="roadmap-container">
                    {/* Roadmap top section */}
                    <RoadmapTopSection
                        showFullId={showFullId}
                        setShowFullId={setShowFullId}
                        showFullIdMobile={showFullIdMobile}
                        setShowFullIdMobile={setShowFullIdMobile}
                    />
                    {/* Roadmap steps */}
                    {!onboarding.formData.isExpired ? <RoadmapStepsContainer /> : <ExpiredContent />}
                </div>
            )}
        </>
    );
});
