import { ReactNode, createContext, useContext } from "react";
import { useState } from "react";
import { ModalContentInnerProps } from "../ui-components/modal/modal-content/ModalContent";

interface SparrowUIContextProps {
    resetSparrowUIContext: () => void;
    showModal: boolean;
    setShowModal: (value: boolean) => void;
    modalContent: ModalContentInnerProps | undefined;
    setModalContent: (value: ModalContentInnerProps | undefined) => void;
    modalContentRequiresReadThrough: boolean;
    setModalContentRequiresReadThrough: (value: boolean) => void;
    showToastPopup: boolean;
    setToastPopupStatus: (value: boolean | null) => void;
    toastPopupText?: string;
    toastPopupStatus: boolean | null;
    displayToastPopup: (text: string, isCatchErr?: boolean ) => void;
    showReviewCards: boolean;
    displayReviewCards: (value: boolean) => void;
    showReviewHeaderText: boolean;
    reviewHeaderText: {title: string, subtitle: string};
    showMobileTips: boolean;
    setShowMobileTips: (value: boolean) => void;
}

const defaultSparrowUIContext: SparrowUIContextProps = {
    resetSparrowUIContext: () => null,
    showModal: false,
    setShowModal: () => null,
    modalContent: undefined,
    setModalContent: () => null,
    modalContentRequiresReadThrough: false,
    setModalContentRequiresReadThrough: () => null,
    showToastPopup: false,
    toastPopupStatus: null,
    setToastPopupStatus: () => null,
    toastPopupText: undefined,
    displayToastPopup: () => null,
    showReviewCards: false,
    displayReviewCards: () => null,
    showReviewHeaderText: false,
    reviewHeaderText: {title: "", subtitle: ""},
    showMobileTips: false,
    setShowMobileTips: () => null,
};

export const SparrowUIContext = createContext<SparrowUIContextProps>(defaultSparrowUIContext);

export const SparrowUIProvider = ({ children }: { children: ReactNode }) => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState<ModalContentInnerProps | undefined>(undefined);
    const [modalContentRequiresReadThrough, setModalContentRequiresReadThrough] = useState(false);
    const [showToastPopup, setShowToastPopup] = useState(false);
    const [toastPopupText, setToastPopupText] = useState<string | undefined>(undefined);
    const [toastPopupStatus, setToastPopupStatus] = useState<boolean | null>(null);
    const [showReviewCards, setShowReviewCards] = useState(false);
    const [showReviewHeaderText, setShowReviewHeaderText] = useState(false);
    const [showMobileTips, setShowMobileTips] = useState(false);

    const displayToastPopup = (text: string, isCatchError = false) => {
        if (isCatchError) {
            const catchError = `Something went wrong. If the issue persists, please share the error with Customer Support: ${text}`;
            setToastPopupText(catchError);
            setToastPopupStatus(false);
        } else {
            setToastPopupText(text);
            setToastPopupStatus(true);
        }
        
        setShowToastPopup(true);
        setTimeout(() => {
            setShowToastPopup(false);
        }
        , 10000);
    }

    const displayReviewCards = (value: boolean) => {
        setShowReviewCards(value);
        setShowReviewHeaderText(value);
    }

    const resetSparrowUIContext = () => {
        setShowModal(false);
        setModalContent(undefined);
        setModalContentRequiresReadThrough(false);
        setShowToastPopup(false);
        setToastPopupText(undefined);
        setToastPopupStatus(null);
        setShowReviewCards(false);
        setShowReviewHeaderText(false);
        setShowMobileTips(false);
    }

    const value = {
        resetSparrowUIContext,
        showModal,
        setShowModal,
        modalContent,
        setModalContent,
        modalContentRequiresReadThrough,
        setModalContentRequiresReadThrough,
        showToastPopup,
        toastPopupText,
        toastPopupStatus,
        setToastPopupStatus,
        displayToastPopup,
        displayReviewCards,
        showReviewCards,
        showReviewHeaderText,
        reviewHeaderText: {title: "Review your information", subtitle: "Please review your information and ensure that everything is accurate."},
        showMobileTips,
        setShowMobileTips,
    };

    return <SparrowUIContext.Provider value={value}>{children}</SparrowUIContext.Provider>;
};

export const useSparrowUIContext = () => {
    return useContext(SparrowUIContext);
}

