import {
    PhoneInput as PhoneInputLibrary,
    defaultCountries,
    parseCountry,
} from "react-international-phone";
import "react-international-phone/build/index.css";
import { ILimitedPhoneNumber } from "./type";
import { FC, useState } from "react";
import "./style.scss";

const phoneInputProps = {
    name: "phoneNumber",
    required: true,
    autoFocus: false,
    style: {
        width: "100%",
        border: "none",
        borderBottom: "1px solid #D8D8D8",
        fontFamily: "Basier Circle",
        currentColor: "rgba(0,0,0,1)",
        fontSize: "16px",
    },
};

interface PhoneInputProps {
    form: ILimitedPhoneNumber;
    setForm: (phoneNumber: ILimitedPhoneNumber) => void;
    defaultCountryCode: string;
    name: string;
    countries?: string[];
}

export const formatPhoneNumberForBackend = (phoneNumber: string, countryPrefix: string) => {
    return phoneNumber.slice(countryPrefix.length).replace(/[^0-9]/g, "");
};

export const PhoneInput: FC<PhoneInputProps> = ({
    form,
    setForm,
    defaultCountryCode,
    name,
    countries,
}) => {
    const handlePhoneNumberChange = (value: string, countryCode: string) => {
        const globalType = countryCode === "us" ? "Domestic" : "International";
        const dialCode = value.split(" ")[0];

        setPhoneNumber(value);
        setForm({
            ...form,
            [name]: formatPhoneNumberForBackend(value, dialCode),
            globalType: globalType,
            countryPrefix: dialCode.replace("+", ""),
            countryCode: countryCode,
        });
    };

    const [phoneNumber, setPhoneNumber] = useState(
        (form.countryPrefix ?? "") + (form.phoneNumber ?? "")
    );

    const getCountryOptions = () => {
        if (countries) {
            defaultCountries.filter((country) => {
                const { iso2 } = parseCountry(country);
                return countries.includes(iso2);
            });
        }

        return defaultCountries;
    };

    return (
        <PhoneInputLibrary
            defaultCountry={defaultCountryCode}
            value={phoneNumber}
            onChange={(value, country) => handlePhoneNumberChange(value, country)}
            disableCountryGuess={true}
            inputProps={phoneInputProps}
            forceDialCode={true}
            countries={getCountryOptions()}
        />
    );
};
