import { CitizenshipInput } from './../../../types/sparrow-types/citizenship/index';

export const Citizenship = (inputs?: CitizenshipInput): CitizenshipInput => {
    const { type, visaType, homeCountry, hasSSN } = inputs ?? {};

    return {
        type,
        hasSSN,
        // Home country only applicable if not a US citizen or a DACA recipient
        ...(homeCountry ? { homeCountry } : {}),
        //Visa type only applicable if visa holder
        ...(visaType ? { visaType } : {}),
    };
};
