import { useRef, useState } from "react";

import { useMediaQuery } from "react-responsive";
import { MenuItems } from "../../../types/paaas/custom-menu-props";

// TODO: import this function from the UI Library
import { useOnClickOutside } from "../../../core/helpers/events";

interface IHeaderBarDropdown {
    menu: MenuItems;
}

export const HeaderBarDropdown = ({ menu }: IHeaderBarDropdown) => {
    // This use media query is required for setting the useState variable open as well as the useOnClickOutside hook
    const isLess1024 = useMediaQuery({ query: "(max-width: 1024px)" });

    const [open, setOpen] = useState(false);
    const ref = useRef(null);

    const onClickOpen = () => {
        setOpen(!open);
    };

    // This hook will close the dropdown when user clicks outside of it
    useOnClickOutside(ref, () => setOpen(false), isLess1024);

    return (
        <div ref={ref} className="links-item">
            <div className="header__links__wrap-dropdown">
                <div className={`wrap ${open ? "active" : ""}`} onClick={onClickOpen}>
                    {menu.name}
                    <span className="icon-arrow-bottom" />
                </div>
                <ul className={`wrap-dropdown-content ${open ? "active" : ""}`}>
                    {menu.children?.map((child) => (
                        <li key={child.id}>
                            <a
                                href={child.url ? child.url : "#"}
                                target={child.isOpenNewTab ? "_blank" : "_self"}
                                className="header__links__wrap-dropdown-link"
                                rel="noopener noreferrer"
                            >
                                {child.name}
                                <span className="icon-arrow-right" />
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};
