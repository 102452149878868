import { OnboardingStore } from "../../../store/onboarding";
import { getExpandedSearchMetadata } from "./prequalification-api-helper-functions";

export const getAndSetExpandedSearchMetadata = async (onboarding: OnboardingStore) => {
    if (onboarding.expandedSearchIsOutOfSeason || onboarding.expandedSearchDates) {
        return;
    }

    const expandedSearchMetadata = await getExpandedSearchMetadata();
    if (!expandedSearchMetadata) {
        return;
    }

    onboarding.setExpandedSearchOutOfSeasonAndDates({
        dates: expandedSearchMetadata.dueDates,
        outOfSeason: expandedSearchMetadata.isOutOfSeason,
    });
};
