import React from "react";
import { useNavigate } from "react-router-dom";
import { useOnboarding } from "../../../../../../common/core/providers/onboarding.provider";
import { CompleteStatusIcon } from "../../../../../../sparrow-ui-library/src/assets/icons/dashboard-status-icons";
import "./styles.scss";
import { UserType } from "../../../../../../sparrow-ui-library/src/types/sparrow-types";
import { PencilIcon } from "../../../../../../sparrow-ui-library/src/assets/icons";

interface PrequalifyStepCardProps {
    stepTitle: string;
    stepData: string | undefined;
    stepUrl: string | undefined;
    isComplete: boolean;
    isLatestIncomplete: boolean;
    isDirectInclusion?: boolean;
}

//This is an individual card that displays on the PqStepPopup for any given step in prequalification to show the user the status of where they are in prequal
export const PrequalifyStepCard: React.FC<PrequalifyStepCardProps> = (props) => {
    const navigate = useNavigate();
    const onboarding = useOnboarding();

    const displayEditIcon = () =>  {
        if(onboarding.creatorIsIncludingUser) {
            return false;
        }   

        if(props.stepTitle === "Loan size" && onboarding.formData.isIncludedUser){
            return false;
        }

        return !(onboarding.formData.stepsFinished === onboarding.formData.stepsTotal);
    }

    /*
    This component is reused for both the active user and during direct inclusion.
    If the component is being used for direct inclusion, we want to return the opposite user type, so that the relevant color theme 
    associated with that user type is used.
    */ 
    const getRelevantUserType = () => {
        return props.isDirectInclusion ? onboarding.getOppositeUserType() : onboarding.formData.userType;
    }

    return (
        <div
            className={`pq-step-card-container ${props.stepTitle !== "empty" ? "complete" : ""} ${
                onboarding.formData.userType
            }`}
        >
                    <div className={`status-bar ${props.isComplete ? `${getRelevantUserType()} complete` : ""}`}><div className="mobile-only">{props.isComplete ? <CompleteStatusIcon userType={getRelevantUserType() as UserType} /> : null }</div></div>
                    <p className="step-title">{props.stepTitle}</p>
                    <div className="flex-container-0" style={{ gap: "8px" }}>
                        {props.isComplete ? (
                            <>
                                <p className="step-data">{props.stepData}</p>
                                {displayEditIcon() && (
                                 <PencilIcon onClick={() => {
                                    onboarding.setEditsMadePostPrequalSubmission(false);
                                    navigate(props.stepUrl as string);
                                }} userType={onboarding.formData.userType as UserType}/>
                                )}
                            </>
                        ) : (
                            // latest incomplete step has orange coloring, whereas other incomplete steps have gray coloring
                            <p
                                className={`step-data ${
                                    props.isLatestIncomplete ? "latest-incomplete" : "incomplete"
                                }`}
                            >
                                Incomplete
                            </p>
                        )}
                    </div>

        </div>
    );
};
