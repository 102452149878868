"use client";
import React from "react";

import "./popup-text.scss";

interface IInfoModal {
    title?: string;
    size?: string;
    blur?: boolean;
    noPadding?: boolean;
    resetOutside?: boolean;
    onClose: () => void;
    customClass?: string;
    showCloseIcon?: boolean;
    enableMobileView?: boolean;
    closeButtonPosition?: "left" | "right";
}

export const AppModal: React.FunctionComponent<IInfoModal & React.PropsWithChildren<{}>> = ({
    showCloseIcon = false,
    closeButtonPosition = "right",
    ...props
}) => {
    const clickOutside = (e: any) => {
        if (e.currentTarget === e.target && props.resetOutside) {
            props.onClose();
        }
    };

    function getClassName() {
        return `popup-wrapper app-modal 
        ${!!props.blur ? "modal-blur" : ""} 
        ${!!props.customClass ? props.customClass : ""} 
        ${!!props.size ? props.size : ""} 
        ${props.enableMobileView ? "popup-wrapper-mobile-view" : ""}
        `;
    }

    return (
        <>
            <div className={getClassName()} onClick={clickOutside}>
                <div className="popup">
                    {showCloseIcon && (
                        <button
                            className={`close-modal ${closeButtonPosition}`}
                            onClick={() => props.onClose()}
                        >
                            <span className="icon-close" />
                        </button>
                    )}
                    <div className={"popup-content" + (props.noPadding ? "-no-padding" : "")}>
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
};
