"use client";
import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import { observer } from "mobx-react";
import { useStore } from "../../core/providers/global.provider";

const ErrorIcon = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12.4989" cy="12" r="11" fill="#F32B32" />
        <path
            d="M12.4987 15L10.8909 6.96116C10.688 5.94659 11.464 5 12.4987 5C13.5333 5 14.3093 5.94659 14.1064 6.96116L12.4987 15Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle cx="12.4987" cy="18" r="1" fill="white" />
    </svg>
);

const SuccessIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="11.999" cy="12" r="11" fill="#6DB353" />
        <path
            d="M7.50659 11.8495L11.1851 15.528L17.1785 9.5346"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const AuthNotifications = observer(() => {
    const { authNotif, authNotifReset } = useStore();
    const [isPaused, setIsPaused] = useState(false);

    const onClose = useCallback(() => {
        authNotifReset();
        //clearTimeout(timer);
    }, []);

    useEffect(() => {
        let timer: NodeJS.Timer | undefined = undefined;
        if (authNotif.title) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            if (!isPaused) {
                timer = setTimeout(() => {
                    onClose();
                    clearTimeout(timer);
                }, 4000);
            } else {
                clearTimeout(timer);
            }
        } else {
            clearTimeout(timer);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [authNotif.title, isPaused]);

    return (
        <>
            {authNotif && authNotif.title != null && (
                <div
                    onMouseEnter={() => setIsPaused(true)}
                    onMouseLeave={() => setIsPaused(false)}
                    className={`${
                        authNotif?.type === "success" ? "success" : "error"
                    } notification_status--auth`}
                >
                    {authNotif?.linkUrl ? (
                        <a href={authNotif.linkUrl} onClick={onClose}>
                            {authNotif.type === "error" && <ErrorIcon />}
                            {authNotif.type === "success" && <SuccessIcon />}
                            <span dangerouslySetInnerHTML={{ __html: authNotif?.title }} />
                        </a>
                    ) : (
                        <>
                            {authNotif.type === "error" && <ErrorIcon />}
                            {authNotif.type === "success" && <SuccessIcon />}
                            <span dangerouslySetInnerHTML={{ __html: authNotif?.title }} />
                        </>
                    )}
                </div>
            )}
        </>
    );
});
