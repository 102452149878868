import { useEffect, useState } from "react";
import { useSparrowUIFormsContext } from "../../../context";
import { AddressFieldProps, FormFieldName, FormFieldType, SetInputValue } from "../../../types/form-types";
import { formatToSentenceCase } from "../../../utils/general-utils";
import { InputDropdown } from "..";
import { ToggleSwitch } from "../../buttons";
import ManualAddress from "./components/ManualAddress";
import EditAddressSection from "./components/EditAddressSection";
import { UserType } from "../../../types/sparrow-types";
import { RequiredAddressFields } from "../../../constants/default-inputs";
import { getErrorMessageFunction } from "../../../utils/ui-library-utils";
import "./address-autocomplete.styles.css";
interface AddressAutocompleteProps extends AddressFieldProps {
    setInputValue: SetInputValue;
    userType?: UserType;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
    id,
    inputValue,
    label,
    placeholder,
    setInputValue,
    name,
    isPermanentAddress = true,
    asksForMailingAddress = false,
    userType
}) => {
    const { activeFormIndex, showMailingAddress, setShowMailingAddress, isMailingAddressSameAsPermanentAddress, setIsMailingAddressSameAsPermanentAddress } = useSparrowUIFormsContext();
    const [showFullAddress, setShowFullAddress] = useState<boolean>(false);
    // State for manual address inputs
    const [showManualAddress, setShowManualAddress] = useState<boolean>(false);
    // State for mailing address toggle switch
    const [toggleValueMailingAddressIsSame, setToggleValueMailingAddressIsSame] = useState<boolean>(isMailingAddressSameAsPermanentAddress);
    /* State for tracking if the user has clicked on an option in the dropdown 
    - important to handle the case where google api does not return addressLine1 and the user enters a new input in the input field instead of entering address manually*/
    const [optionClicked, setOptionClicked] = useState<boolean>(false);
    
    useEffect(() => {
        const requiredAddressFieldsKeys = Object.keys(RequiredAddressFields());
        const numRequiredAddressFields = requiredAddressFieldsKeys.length;
        const numValuesFilled = requiredAddressFieldsKeys.filter(key => !!inputValue[key as FormFieldName]).length;
        
        if(optionClicked && numValuesFilled > 0 && numValuesFilled < numRequiredAddressFields) {
            setShowManualAddress(true);
        }

        if(!optionClicked) {
            setShowManualAddress(false);
        }
        // Prevent full address from showing if the user has the manual address section open
        if(!showManualAddress){
        // Only if all fields are valid should the full address be shown. Otherwise we risk some fields displaying undefined
            const allFieldsValid = requiredAddressFieldsKeys.every(key => {
                const formFieldName = key as FormFieldName;
                // Zip code field is a special case because validation depends on the country code value
                if(formFieldName === FormFieldName.Zip){
                    if(!inputValue[FormFieldName.CountryCode]) return false;
                    if(inputValue[FormFieldName.CountryCode] === 'US' && (inputValue[FormFieldName.Zip] ?? "").length !== 5) return false;
                    return true;
                }

                const errorMessageFunction = getErrorMessageFunction(formFieldName);
                return !errorMessageFunction(inputValue[formFieldName] ?? "", formFieldName)?.length    
            });

            setShowFullAddress(allFieldsValid);
        }

    }, [optionClicked, inputValue]);

    const toggleSetInputValue = (name: FormFieldName, value: boolean) => {
        inputValue.mailingAddressIsSameAsPermanentAddress = value;
        // Update this state because it is used in useHandlePermanentAddressChanges
        setIsMailingAddressSameAsPermanentAddress(value);
        // Note: this state will be handled by handlePermanentAddressChanges in useHandlePermanentAddressChanges.tsx
        setInputValue(name, { ...inputValue });
        // This is state handled by FormsContext, because it will display another form in a FormContainer with mailing address inputs
        setShowMailingAddress(!showMailingAddress);
        // This is state that tracks the toggle switch value
        setToggleValueMailingAddressIsSame(value);
    }
    // Opens the manual address section on click of the pencil icon
    const handleEditAddress = () => {
        setShowManualAddress(true)
        setShowFullAddress(false)
    };

    // Closes the manual address section on click of the cancel button and does not update the address
    const handleCancel = () => {
        setShowManualAddress(false);
        setShowFullAddress(!!inputValue.addressLine1);
    }

    return (
        <>
            <InputDropdown
                isAddressAutocomplete={true}
                backendValue={inputValue}
                dropdownOptions={[]}
                formFieldType={FormFieldType.AddressAutocomplete}
                id={id}
                includeDropdownButton={false}
                inputValue={inputValue}
                label={label}
                name={name}
                placeholder={formatToSentenceCase(placeholder)}
                setInputValue={setInputValue}
                type="text"
                setOptionClicked={setOptionClicked}
            />

            {showFullAddress && (
                <EditAddressSection
                    inputValue={inputValue}
                    handleEditAddress={handleEditAddress}
                />
            )}

            {showManualAddress && (
                <ManualAddress
                    isPermanentAddress={isPermanentAddress}
                    setShowManualAddress={setShowManualAddress}
                    inputValue={inputValue}
                    setManualAddressInputs={(value) => setInputValue(name, value)}
                    handleCancel={handleCancel}
                />
            )}

            {asksForMailingAddress && <ToggleSwitch
                id={`${FormFieldName.MailingAddressIsSame}-${activeFormIndex}`}
                name={FormFieldName.MailingAddressIsSame}
                inputValue={toggleValueMailingAddressIsSame}
                setInputValue={(_, value) => toggleSetInputValue(name, value as boolean)}
                formFieldType={FormFieldType.ToggleSwitch}
                textContent="Mailing address is the same as physical address"
                usedInForm={true}
                handleConsent={false}
                userType={userType}
            />}

        </>
    );
};

export default AddressAutocomplete;
