import { UserType } from "../../../../common/constants/defaultInputs";
import { firstCharUpperCase, getPaaasClass } from "../../../../common/utils/global";
import { getRoadmapStepLottie, getRoadmapStepIcon } from "../../../../assets/icons/lottie-icons/lottie-get-functions/Roadmap_Lottie_Icons";
import { LottieIcon } from "../../../../common/components/LottieIcon/LottieIcon";
import { observer } from "mobx-react";
import { ProgressStatuses } from "../../../../common/models/request";
import { isMarketplaceEnv } from "../../../../common/utils/config";
import { CompleteStatusIcon, IncompleteStatusIcon } from "../../../../sparrow-ui-library/src/assets/icons/dashboard-status-icons";
import "./styles.scss";

interface StepProps {
    id: string;
    stepNum: number;
    progressStatus: ProgressStatuses;
    stateOfStep: {
        isActive: boolean;
        isLatestIncompleteStep: boolean;
        isCompleteStep: boolean;
    }
    userType: UserType;
    onStepClick: (e) => void;
}



//Returns the active classes to apply based on the state of a step as complete, latestIncomplete, or active. Default state is incomplete
const activeClasses = (
    isActive: boolean,
    isLatestIncompleteStep: boolean,
    isCompleteStep: boolean
) => {
    const activeClass = isActive ? "active" : "";
    const latestIncompleteStepClass = isLatestIncompleteStep ? "latest-incomplete-step" : "";
    const completeStepClass = isCompleteStep ? "complete-step" : "";

    return `${activeClass} ${latestIncompleteStepClass} ${completeStepClass} ${getPaaasClass()}`;
};

export const RoadmapStep: React.FC<StepProps> = observer((props) => {
    const { isActive, isLatestIncompleteStep, isCompleteStep } = props.stateOfStep;

    const activeClass = activeClasses(isActive, isLatestIncompleteStep, isCompleteStep);
    //display check if step is complete, elipses if step is latest incomplete step, or nothing if step is incomplete

    return (
        <div
            id={props.progressStatus}
            className={`rm-step-container ${activeClass} ${
                isCompleteStep ? "complete" : ""
            }}`}
            onClick={props.onStepClick}
        >
            <div className={`step-status ${activeClass}`}>
                {isCompleteStep ? <CompleteStatusIcon userType={props.userType} /> : isLatestIncompleteStep ? <IncompleteStatusIcon userType={props.userType} /> : null}
            </div>
            <div className="top-section">
                <p className={`step ${activeClass} ${props.userType}`}>Step {props.stepNum}</p>
                <div className={`icon ${activeClass}`}>
                    {isMarketplaceEnv() ? 
                    
                    <LottieIcon
                        animationData={getRoadmapStepLottie(props.progressStatus, props.userType)}
                        options={{
                            height: 40,
                            width: 40,
                        }} />
                        :
                        getRoadmapStepIcon(props.progressStatus, props.userType)}
                </div>
            </div>
            <div className={`bottom-content ${activeClass} ${props.userType}`}>
                <p className={`${activeClass} ${props.userType}`}>
                    {firstCharUpperCase(props.progressStatus)}
                </p>
            </div>
        </div>
    );
});
