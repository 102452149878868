import { FormFieldType, FormFieldName, FormFieldProps, FormsConfig } from "../../../sparrow-ui-library/src/types/form-types";
import {
    citizenshipTypes,
    countries,
} from "../../../sparrow-ui-library/src/constants/dropdown-options";
import { PrequalificationRequestInput } from "../../../sparrow-ui-library/src/types/sparrow-types";
import { AsyncDropdownValues } from "../../../sparrow-ui-library/src/types/marketplace-paaas-types";

export const getDependentFieldsConfig = (inputs: FormsConfig): {[x: string]: FormFieldProps} => {
    const { applicableFormData, getApplicableUserText, asyncDropdownValues } = inputs as {
        applicableFormData: PrequalificationRequestInput;
        getApplicableUserText: (text: string) => string;
        asyncDropdownValues: AsyncDropdownValues;
      };

    return {
        visaStatus: {
            id: "",
            formFieldType: FormFieldType.InputDropdown,
            name: FormFieldName.CitizenshipStatusVisaType,
            label: getApplicableUserText("What type of visa do you hold?"),
            placeholder: "Select",
            inputValue: applicableFormData.citizenshipStatus.visaType ?? "",
            backendValue: "",
            errorMessage: "",
            dropdownOptions: asyncDropdownValues ? asyncDropdownValues.visaTypes : [],
            independentFieldName: FormFieldName.CitizenshipStatusType,
            dependentTriggerValue: citizenshipTypes[3].value, // Visa Holder
        },
        homeCountry: {
            id: "",
            formFieldType: FormFieldType.InputDropdown,
            name: FormFieldName.CitizenshipStatusHomeCountry,
            label: getApplicableUserText("Where are you from?"),
            placeholder: "Select",
            inputValue: applicableFormData.citizenshipStatus.homeCountry ?? "",
            backendValue: "",
            errorMessage: "",
            dropdownOptions: countries,
            independentFieldName: FormFieldName.CitizenshipStatusType,
            dependentTriggerValue: citizenshipTypes.slice(1, 5), 
        },
    };
};
