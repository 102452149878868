import React from 'react'
import { PencilIcon } from '../../../../assets/icons'
import { AddressInput } from '../../../../types/sparrow-types';

interface EditAddressSectionProps {
    inputValue: AddressInput;
    handleEditAddress: () => void;
}

const EditAddressSection: React.FC<EditAddressSectionProps> = ({
    inputValue,
    handleEditAddress
}) => {

    const getFullAddress = () => {
        const { addressLine1, city, state, zip, countryCode } = inputValue;
        return `${addressLine1}, ${city}, ${state} ${zip} ${countryCode}`;
    };

    return (
        <div className="EditAddressSection">
            <span>{getFullAddress()}</span>
            <PencilIcon onClick={handleEditAddress} />
        </div>
    )
}

export default EditAddressSection;

