import { FC } from "react"
import { UserType } from "../../../types/sparrow-types"
import { getSvgColors } from "../getSvgColors"

export const TwoPersonIcon:FC<{userType?: UserType}> = ({userType = UserType.BORROWER}) => {
    const {primaryColor, accentColor} = getSvgColors(userType);
    
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11 25C13.7614 25 16 20.9832 16 18.1429C16 15.3025 13.7614 13 11 13C8.23858 13 6 15.3025 6 18.1429C6 20.9832 8.23858 25 11 25Z" fill={primaryColor} />
            <path d="M25.5 22C29.6421 22 33 16.3095 33 12.2857C33 8.26192 29.6421 5 25.5 5C21.3579 5 18 8.26192 18 12.2857C18 16.3095 21.3579 22 25.5 22Z" fill={accentColor} />
            <path d="M1.04496 30.5934C2.13215 24.9589 6.08262 21 10.618 21H11.382C15.9174 21 19.8678 24.9589 20.955 30.5934C21.1912 31.8172 20.4674 33 19.4823 33H2.51773C1.53264 33 0.808821 31.8172 1.04496 30.5934Z" fill={primaryColor}/>
            <path d="M12.0607 31.7912C13.5284 24.2785 18.8615 19 24.9843 19H26.0157C32.1385 19 37.4716 24.2785 38.9393 31.7912C39.2581 33.423 38.2809 35 36.9511 35H14.0489C12.7191 35 11.7419 33.423 12.0607 31.7912Z" fill={accentColor} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.5151 22.8442C14.3649 25.0849 12.7662 28.1799 12.0607 31.7912C11.9797 32.206 11.9824 32.6173 12.0533 33H19.4823C20.4674 33 21.1912 31.8173 20.955 30.5934C20.3142 27.2724 18.6787 24.5335 16.5151 22.8442Z" fill={accentColor} />
        </svg>
    )
}
