import { useState } from "react";

import questionImg from "../../../assets/images/icons/icon_delete.svg";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { useStore } from "../../core/providers/global.provider";
import { PostPrequalificationRequestDecline } from "../../core/services/sparrow-api-handlers/prequalification-api-handlers";
import { observer } from "mobx-react";
import { dashboardUrl } from "../../core/routes/navigation-urls";
import { useNavigate } from "react-router-dom";
import removeDecimals from "../../utils/removeDecimals";

const getShortId = (id: string) => {
    return `...${id.substring(0, 4)}`;
}

export const DeleteLoanModal = observer((props) => {
    const onboarding = useOnboarding();
    const navigate = useNavigate();
    const store = useStore();
    const onDelete = async (id: string) => {
        store.setLoading(true);
        store.setModal(null);

        try {
            await PostPrequalificationRequestDecline(id, onboarding.jwt as string);
            navigate(dashboardUrl);
            onboarding.deleteRequestFromLoanRequests(id);
            store.setSnackError("Your loan request has been deleted.", "success");
        } catch (err) {
            console.error(err);
            store.setSnackError("Something went wrong. Please try again later.", "error");
        }

        store.setLoading(false);
    };

    const [cancelHover, setCancelHover] = useState<boolean>(false);
    const [deleteHover, setDeleteHover] = useState<boolean>(false);

    const shortId = getShortId(onboarding.formData.id as string);

    return (
        <div className="modal-content">
            <button className="close-modal" onClick={store.modalResetData}>
                <span className={"icon-close"} />
            </button>
            <img src={questionImg} alt="" />
            <div className="h3">
                {`Are you sure you want to delete your ${removeDecimals(
                    onboarding.formData.loanAmount.display
                )} ${onboarding.formData.loanSelectionType} request?`}
            </div>
            <p>
                After deletion, you will not be able to access any of the information associated with request ID {shortId}. To check your pre-qualified rates, you will need to start a new request.
            </p>
            <div className="button-group" style={{ display: "flex", justifyContent: "center" }}>
                <button
                    className="btn btn-bordered btn-strong-size"
                    onClick={() => {
                        onDelete(onboarding.formData.id as string);
                    }}
                    style={{ background: deleteHover ? "#F1F5F8" : "white" }}
                    onMouseEnter={() => setDeleteHover(true)}
                    onMouseLeave={() => setDeleteHover(false)}
                >
                    Delete
                </button>
                <button
                    className={"btn btn-black btn-strong-size"}
                    onClick={store.modalResetData}
                    style={{ background: cancelHover ? "#525966" : "black" }}
                    onMouseEnter={() => setCancelHover(true)}
                    onMouseLeave={() => setCancelHover(false)}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
});
