export const nameFormat = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
};

export const emailFormat = (value: string) => {
    return value.toLowerCase();
};

export const ssnFormat = (value: string) => {
    let formattedSSN = value
        .replace(/\D/g, "") // Remove non-numeric characters
        .slice(0, 9); // Limit input to 9 digits

    if (formattedSSN.length > 5) {
        formattedSSN = `${formattedSSN.slice(0, 3)}-${formattedSSN.slice(
            3,
            5
        )}-${formattedSSN.slice(5)}`;
    } else if (formattedSSN.length > 3) {
        formattedSSN = `${formattedSSN.slice(0, 3)}-${formattedSSN.slice(3)}`;
    }
    return formattedSSN;
};

export const validateMonth = (month: string) => {
    const numericMonth = parseInt(month, 10);
    return numericMonth >= 1 && numericMonth <= 12;
};

export const validateDay = (day: string) => {
    const numericDay = parseInt(day, 10);
    return numericDay >= 1 && numericDay <= 31;
};

export const validateYear = (year: string, params: {isDobDate: boolean} = {isDobDate: true}) => {
    const currentYear = new Date().getFullYear();
    const numericYear = parseInt(year, 10);
    const minYear = currentYear - 100;
    if (params.isDobDate) {
        return numericYear >= minYear && numericYear <= currentYear;
    }    

    return numericYear >= minYear;
};

export const standardDateFormat = (value: string, isDobDate: boolean = false) => {
    let formattedDate = value
        .replace(/\D/g, "") // Remove non-numeric characters
        .slice(0, 8); // Limit input to 8 digits

    let month = formattedDate.slice(0, 2);
    let day = formattedDate.slice(2, 4);
    let year = formattedDate.slice(4);

    if (month.length === 2 && !validateMonth(month)) {
        month = month.slice(0, 1);
    }

    if (day.length === 2 && !validateDay(day)) {
        day = day.slice(0, 1);
    }

    if (year.length === 4 && !validateYear(year, {isDobDate})) {
        year = year.slice(0, 3);
    }

    formattedDate = `${month}${day}${year}`;

    if (formattedDate.length > 4) {
        formattedDate = `${month}/${day}/${year}`;
    } else if (formattedDate.length > 2) {
        formattedDate = `${month}/${day}`;
    }

    return formattedDate;
};

export const gradDateFormat = (value: string) => {
    let formattedDate = value
        .replace(/\D/g, "") // Remove non-numeric characters
        .slice(0, 6); // Limit input to 6 digits

    let month = formattedDate.slice(0, 2);
    let year = formattedDate.slice(2);
    

    if (month.length === 2 && !validateMonth(month)) {
        month = month.slice(0, 1);
    }

    if (year.length === 4 && !validateYear(year, {isDobDate: false})) {
        year = year.slice(0, 3);
    }

    formattedDate = `${month}${year}`;

    if (formattedDate.length > 2) {
        formattedDate = `${month}/${year}`;
    } else {
        formattedDate = `${month}`;
    }

    return formattedDate;
};

export const zipCodeFormat = (value: string) => {
    return value.replace(/[^a-zA-Z0-9]/g, "");
};

export const gpaFormat = (value: string): string => {
    // Prevents user from entering consecutive digits prior to their first decimal point
    if (value.length === 2 && !value.includes(".")) return value[0];

    const gpa = value.replace(/[^0-9.]/g, "");

    return gpa.slice(0, 4);
};

export const hoursCompletedFormat = (value: string): string => {
    const hours = value.replace(/[^0-9]/g, "");
    return hours.slice(0, 3);
}