import "../../../style.scss";

interface BookmarkProps {
    icon: any;
    toggle: Function;
    isExpand: boolean;
}

export const OfferCardHeaderBookmarkButton: React.FunctionComponent<BookmarkProps> = ({
    icon,
    toggle,
    isExpand,
}) => {
    return (
        <button className={`bookmark-button ${isExpand ? "active" : ""}`} onClick={() => toggle()}>
            <img className={`bookmarked`} src={icon} alt="bookmark" />
            {isExpand ? "Bookmark" : ""}
        </button>
    );
};
