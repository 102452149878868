import { UserType } from "../../../../constants/defaultInputs";
import { OnboardingStore } from "../../../../store/onboarding";
import { PrequalUrlsEnum } from "../../../global_routing/front-end-urls";
import {
    userInclusionManagementUrl,
    userInclusionSelectUrl,
} from "../../../routes/navigation-urls";
import {
    DeclineReasonDisplayMapping,
    DeclineReasons,
    StudentLoanRequestValidationErrorString,
} from "../../types/offers-api-types";
import { DeclineReasonsToDisplay } from "./get-decline-reasons";
import { getPossesiveCase } from "../../../../utils/global";
import { isMarketplaceEnv } from "../../../../utils/config";

interface validationErrorsData {
    title: string;
    textContent: string;
    cta: string;
    ctaUrl: string;
}

interface validationErrorsOutput {
    borrowerValidationErrorOutput: validationErrorsData[] | [];
    cosignerValidationErrorOutput: validationErrorsData[] | [];
}

//returns true if the active account user type has a validation error
const checkErrorFromActiveUser = (validationErrorUser: string, onboarding: OnboardingStore) => {
    const { userType } = onboarding.formData;

    return (
        (validationErrorUser === "borrowerValidationError" && userType === UserType.BORROWER) ||
        (validationErrorUser === "cosignerValidationError" && userType === UserType.COSIGNER)
    );
};

const getOppositeUserType = (validationErrorUser: string, onboarding: OnboardingStore) => {
    const { userType, isCreator } = onboarding.formData;

    // if active user isCreator, then the opposite user is the included user, otherwise the opposite user is the creator
    const oppositeUserFirstName = isCreator
        ? onboarding.formData.includedUser?.firstName
        : onboarding.formData.creator?.firstName;

    if (validationErrorUser === "borrowerValidationError" && userType === UserType.COSIGNER) {
        return { userType: UserType.BORROWER, firstName: oppositeUserFirstName };
    }

    if (validationErrorUser === "cosignerValidationError" && userType === UserType.BORROWER) {
        return { userType: UserType.COSIGNER, firstName: oppositeUserFirstName };
    }
    // to avoid undefined return
    return { userType: "", firstName: "" };
};

const validationErrorContent = (
    validationErrorUser: string,
    validationErrorValue: StudentLoanRequestValidationErrorString,
    onboarding: OnboardingStore
) => {
    const { includedUser } = onboarding.formData;

    const errorIsFromActiveUser = checkErrorFromActiveUser(validationErrorUser, onboarding);

    const oppositeUserType = getOppositeUserType(validationErrorUser, onboarding);

    const isSoloBorrowerCreator =
        onboarding.checkIsBorrowerCreator() && !onboarding.checkCreatorIncludedUser();

    const getCtaNoFileReturned = () => {
        if (!errorIsFromActiveUser) {
            return `Verify ${includedUser?.firstName}'s information`;
        }

        if (isSoloBorrowerCreator) {
            return "Add a cosigner";
        }

        return "Verify my personal information";
    };

    if (
        validationErrorValue === StudentLoanRequestValidationErrorString.NO_FILE_RETURNED ||
        validationErrorValue === StudentLoanRequestValidationErrorString.VALIDATION_ERROR ||
        validationErrorValue === StudentLoanRequestValidationErrorString.LACK_OF_CREDIT
    ) {
        return {
            title: `Unable to verify ${
                !errorIsFromActiveUser ? `your ${oppositeUserType.userType}'s` : "your"
            } credit profile`,
            textContent: `
            Based on the information entered, we were unable to find ${
                !errorIsFromActiveUser ? `your ${oppositeUserType.userType}'s` : "your"
            } credit profile. This can happen if ${
                !errorIsFromActiveUser ? `your ${oppositeUserType.userType} has` : "you have"
            } not established a credit history yet or if ${
                !errorIsFromActiveUser ? `your ${oppositeUserType.userType} has` : "you have"
            } entered identity information incorrectly. If you know ${
                !errorIsFromActiveUser ? `your ${oppositeUserType.userType} has` : "you have"
            } established credit, please make sure that ${
                !errorIsFromActiveUser ? `their` : "your"
            } SSN, permanent address, first name, and last name have been entered correctly. ${
                isSoloBorrowerCreator ? "Otherwise, try adding a cosigner." : ""
            }`,
            cta: getCtaNoFileReturned(),
        };
    }

    return {
        title: `Your ${
            !errorIsFromActiveUser ? `${oppositeUserType.userType}'s` : ""
        } credit file is frozen`,
        textContent: `When attempting to complete your ${
            !errorIsFromActiveUser ? `${oppositeUserType.userType}'s` : ""
        } soft credit pull, we received an error indicating that ${
            !errorIsFromActiveUser ? "they froze their" : "you froze your"
        } credit file. Unfortunately, if we can't view ${
            !errorIsFromActiveUser ? "their" : "your"
        } credit file, we can't provide pre-qualified rates. Once ${
            !errorIsFromActiveUser ? `they` : "you"
        } have unfrozen ${
            !errorIsFromActiveUser ? `their` : "your"
        } credit file with TransUnion, contact customer support and we'll re-run your request.`,
        cta: `${!errorIsFromActiveUser ? "Ask" : "Unfreeze"} ${
            !errorIsFromActiveUser ? `${oppositeUserType.firstName}` : "my"
        } ${!errorIsFromActiveUser ? "to unfreeze their" : ""} credit file`,
    };
};

export const mapValidationErrors = (
    declineReasons: DeclineReasonsToDisplay,
    onboarding: OnboardingStore
) => {
    const { borrowerValidationError, cosignerValidationError } = declineReasons;
    if (!borrowerValidationError && !cosignerValidationError) return null;
    const validationErrors = { borrowerValidationError, cosignerValidationError };

    let validationErrorsOutput: validationErrorsOutput = {
        borrowerValidationErrorOutput: [],
        cosignerValidationErrorOutput: [],
    };

    const getOutputKey = (key: string) => {
        return key === "borrowerValidationError"
            ? "borrowerValidationErrorOutput"
            : "cosignerValidationErrorOutput";
    };

    const getCtaUrlNoFileReturned = (isActiveUser: boolean, onboarding: OnboardingStore) => {
        if (!isActiveUser) {
            return userInclusionManagementUrl;
        }

        if (onboarding.checkIsBorrowerCreator() && !onboarding.checkCreatorIncludedUser()) {
            return userInclusionSelectUrl;
        }

        return PrequalUrlsEnum.REVIEW;
    };

    //Loop through borrower / cosigner validation errors
    Object.keys(validationErrors).forEach((validationErrorUser) => {
        if (!validationErrors[validationErrorUser]) return;
        const isActiveUser = checkErrorFromActiveUser(validationErrorUser, onboarding);

        // No file returned or validation error
        if (
            (
                validationErrors[validationErrorUser] as StudentLoanRequestValidationErrorString[]
            )[0] === StudentLoanRequestValidationErrorString.NO_FILE_RETURNED ||
            (
                validationErrors[validationErrorUser] as StudentLoanRequestValidationErrorString[]
            )[0] === StudentLoanRequestValidationErrorString.VALIDATION_ERROR ||
            (
                validationErrors[validationErrorUser] as StudentLoanRequestValidationErrorString[]
            )[0] === StudentLoanRequestValidationErrorString.LACK_OF_CREDIT
        ) {
            const { title, textContent, cta } = validationErrorContent(
                validationErrorUser,
                validationErrors[validationErrorUser][0],
                onboarding
            );

            const outputKey = getOutputKey(validationErrorUser);

            validationErrorsOutput[outputKey] = [
                {
                    title,
                    textContent,
                    cta,
                    ctaUrl: getCtaUrlNoFileReturned(isActiveUser, onboarding),
                },
            ];
        }

        // Frozen credit
        if (
            (
                validationErrors[validationErrorUser] as StudentLoanRequestValidationErrorString[]
            )[0] === StudentLoanRequestValidationErrorString.FROZEN_CREDIT
        ) {
            const { title, textContent, cta } = validationErrorContent(
                validationErrorUser,
                validationErrors[validationErrorUser][0],
                onboarding
            );
            const outputKey = getOutputKey(validationErrorUser);
            validationErrorsOutput[outputKey] = [
                { title, textContent, cta, ctaUrl: "https://www.transunion.com/credit-freeze" },
            ];
        }
    });

    return validationErrorsOutput;
};

const getDeclineReasonsTitleAndTextContent = (
    companyName: string,
    declineReason: DeclineReasons
): DeclineReasonDisplayMapping | undefined => {
    const isCosignerReason = declineReason.includes("cosigner");
    const isMarketplace = isMarketplaceEnv();

    if (declineReason === DeclineReasons.BORROWER_STATE || declineReason === DeclineReasons.COSIGNER_STATE) {
        return {
            title: declineReason,
            textContent: `It looks like your ${isCosignerReason ? "cosigner’s " : ""
                }state residency does not meet the requirements of several of the lenders ${isMarketplace
                    ? "that Sparrow partners with"
                    : `on ${getPossesiveCase(companyName)} platform`
                }.`,
        };
    }

    if (declineReason === DeclineReasons.BORROWER_COUNTRY || declineReason === DeclineReasons.COSIGNER_COUNTRY) {
        return {
            title: declineReason,
            textContent: `It looks like your ${isCosignerReason ? "cosigner’s " : ""
                }citizenship or residency status does not meet the requirements of the lenders ${isMarketplace
                    ? "that Sparrow partners with"
                    : `on ${getPossesiveCase(companyName)} platform`
                }.`,
        };
    }

    if (declineReason === DeclineReasons.BORROWER_FICO_LOW || declineReason === DeclineReasons.COSIGNER_FICO_LOW) {
        return {
            title: declineReason,
            textContent: `Several lenders on ${getPossesiveCase(
                companyName
            )} platform use FICO as a determining factor for eligibility. Unfortunately, based on your ${isCosignerReason ? "cosigner's" : ""
                } credit report, it appears that ${isCosignerReason ? "their" : "your"
                } credit is not strong enough to pre-qualify with these lenders.`,
        };
    }

    if (declineReason === DeclineReasons.BORROWER_DTI_HIGH || declineReason === DeclineReasons.COSIGNER_DTI_HIGH) {
        return {
            title: declineReason,
            textContent: `Several lenders on ${getPossesiveCase(
                companyName
            )} platform use the ratio of your debt relative to your income as a determining factor for eligibility. Unfortunately, based on your ${isCosignerReason ? "cosigner's" : ""
                } credit report, it appears that the ratio of ${isCosignerReason ? "their" : "your"
                } debt to income is too high to pre-qualify with these lenders.`,
        };
    }

    if (declineReason === DeclineReasons.BORROWER_INCOME_LOW || declineReason === DeclineReasons.COSIGNER_INCOME_LOW) {
        return {
            title: declineReason,
            textContent: `Several lenders on ${getPossesiveCase(
                companyName
            )} platform use income as a determining factor for eligibility. Unfortunately, it appears that your ${isCosignerReason ? "cosigner's" : ""
                } income is insufficient to pre-qualify with these lenders.`,
        };

    }


    if (declineReason === DeclineReasons.BORROWER_DEROGATORY_REMARK || declineReason === DeclineReasons.COSIGNER_DEROGATORY_REMARK) {
        return {
            title: declineReason,
            textContent: `Some of the lenders on ${getPossesiveCase(
                companyName
            )} platform will not lend to ${isCosignerReason ? "cosigners" : "borrowers"
                } with derogatory marks on their credit reports. A derogatory mark can include late payments, collection accounts, bankruptcy, charge-offs, and other negative marks on ${isCosignerReason ? "a" : "your"
                } credit report.`,
        };
    }

    if (declineReason === DeclineReasons.BORROWER_CREDIT_HISTORY || declineReason === DeclineReasons.COSIGNER_CREDIT_HISTORY) {
        return {
            title: declineReason,
            textContent: `Several lenders on ${getPossesiveCase(
                companyName
            )} platform use credit history as a determining factor for eligibility. Unfortunately, it appears that your ${isCosignerReason ? "cosigner's" : ""
                } credit history is insufficient to pre-qualify with these lenders.`,
        };
    }

    if (declineReason === DeclineReasons.BORROWER_AGE || declineReason === DeclineReasons.COSIGNER_AGE) {
        return {
            title: declineReason,
            textContent:
                "Unfortunately, all or some of the lenders that we tried to pre-qualify you with require that an individual be at least the age of majority in their state in order to borrow a loan.",
        };

    }

    if (declineReason === DeclineReasons.BORROWER_ATTENDS_INELIGIBLE_SCHOOL || declineReason === DeclineReasons.BORROWER_ATTENDED_INELIGIBLE_SCHOOL) {
        return {
            title: declineReason,
            textContent:
                "Unfortunately, all or some of the lenders that we tried to pre-qualify you with do not appear to offer loans at your school.",
        };
    }

    return undefined;

};

const getDeclineReasonsContent = ({
    companyName,
    declineReasons,
    isSoftDecline = true,
}: {
    companyName: string;
    declineReasons: DeclineReasons[];
    isSoftDecline?: boolean;
}): (DeclineReasonDisplayMapping | undefined)[] => {
    let reasonsTextAndContent = declineReasons.map((reason) =>
        getDeclineReasonsTitleAndTextContent(companyName, reason)
    );
    //NOTE: this ordering is important. There are some lender decline reasons returned from the backend to which we do not have tooltips for. We want to filter the undefineds out first, then add the cta to the first reason.
    reasonsTextAndContent = reasonsTextAndContent.filter((reason) => reason !== undefined);
    if (reasonsTextAndContent[0]) {
        reasonsTextAndContent[0].cta = isSoftDecline ? "Add a cosigner" : "Apply directly";
        reasonsTextAndContent[0].ctaUrl = isSoftDecline ? userInclusionSelectUrl : undefined;
        reasonsTextAndContent[0].scrollToApplyDirectly = !isSoftDecline;
    }
    return reasonsTextAndContent;
};

interface DeclineReasonsOutput {
    borrowerSoftReasons: ({ title: DeclineReasons; textContent: string } | undefined)[];
    borrowerHardReasons: ({ title: DeclineReasons; textContent: string } | undefined)[];
    cosignerHardReasons: ({ title: DeclineReasons; textContent: string } | undefined)[];
}

export const mapDeclineReasons = (declineReasons: DeclineReasonsToDisplay, companyName: string) => {
    const { borrowerSoftReasons, borrowerHardReasons, cosignerHardReasons } = declineReasons;
    if (!borrowerSoftReasons && !borrowerHardReasons && !cosignerHardReasons) return null;

    const declineReasonsOutput: DeclineReasonsOutput = {
        borrowerSoftReasons: [],
        borrowerHardReasons: [],
        cosignerHardReasons: [],
    };

    if (borrowerSoftReasons?.length) {
        declineReasonsOutput.borrowerSoftReasons = getDeclineReasonsContent({
            companyName,
            declineReasons: borrowerSoftReasons,
        });
    }

    if (borrowerHardReasons?.length) {
        declineReasonsOutput.borrowerHardReasons = getDeclineReasonsContent({
            companyName,
            declineReasons: borrowerHardReasons,
            isSoftDecline: false,
        });
    }

    if (cosignerHardReasons?.length) {
        declineReasonsOutput.cosignerHardReasons = getDeclineReasonsContent({
            companyName,
            declineReasons: cosignerHardReasons,
            isSoftDecline: false,
        });
    }

    return declineReasonsOutput;
};

export const mapValidationAndDeclineReasons = (
    declineReasons: DeclineReasonsToDisplay,
    onboarding: OnboardingStore
) => {
    const validationErrorsOutput = mapValidationErrors(declineReasons, onboarding);
    const declineReasonsOutput = mapDeclineReasons(declineReasons, onboarding.companyName);

    return { validationErrorsOutput, declineReasonsOutput };
};
