import { UserType } from "../../../types/sparrow-types";
import { getSvgColors } from "../getSvgColors";

import "./styles.css";

export const TipsLightBulb = ({userType} : {userType: UserType}) => {
    const { primaryColor } = getSvgColors(userType);

    return (
        <svg
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`TipsLightBulb ${userType}`}
        >
            <circle cx="16.5" cy="17.5" r="15.5" fill={primaryColor} />
            <path
                d="M13.6258 22.5H19.3758M14.5841 25.25H18.4174M13.6258 19.75C13.6267 17.9167 13.1476 17.4583 12.1883 16.5417C11.2299 15.625 10.7738 14.6964 10.7508 13.3333C10.7057 10.5375 12.6674 8.75 16.5008 8.75C20.335 8.75 22.2977 10.5375 22.2508 13.3333C22.2287 14.6964 21.7716 15.625 20.8133 16.5417C19.8559 17.4583 19.3767 17.9167 19.3758 19.75"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
