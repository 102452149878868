import { FC } from "react";
import { useStore } from "../../../core/providers/global.provider";
import { Button, ButtonTypes } from "../../buttons/button/Button";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import { useNavigate } from "react-router-dom";
import { userInclusionInformationUrl } from "../../../core/routes/navigation-urls";
import { ModalWrapper } from "../modal-wrapper";
import { PrequalUrlsEnum } from "../../../core/global_routing/front-end-urls";
import { observer } from "mobx-react";
import { ButtonWrapper } from "../../buttons/button/ButtonWrapper";

interface IConfirmDirectPermissionModal {
    invitedUserType: string;
    emailRegistered: boolean;
}

export const ConfirmDirectPermissionModal: FC<IConfirmDirectPermissionModal> = observer(
    ({ invitedUserType, emailRegistered }) => {
        const store = useStore();
        const onboarding = useOnboarding();
        const navigate = useNavigate();

        const handleClick = () => {
            //if we decide to directly include the invited user after having initially invited them, then we route to the citizenship page
            if (emailRegistered) {
                onboarding.setCreatorIsIncludingUser(true);
                navigate(PrequalUrlsEnum.CITIZENSHIP);
                //if we are on the add user page, then we route to the user inclusion information page so we can capture the invited user's information
            } else {
                navigate(userInclusionInformationUrl);
            }
            store.modalResetData();
        };

        return (
            <ModalWrapper>
                <h3
                    style={{
                        fontSize: "24px",
                        lineHeight: "32px",
                        fontWeight: "600",
                        marginBottom: "12px",
                        overflowWrap: "anywhere",
                    }}
                >
                    {`Do you have your ${invitedUserType}'s permission?`}
                </h3>
                <p
                    style={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: "400",
                        marginBottom: "24px",
                        color: "#878F99",
                        overflowWrap: "anywhere",
                    }}
                >
                    {`Please confirm that you have permission from your ${invitedUserType} to share their information.`}
                </p>
                <ButtonWrapper centerButtons={true}>
                    <Button
                        id="previous-button"
                        onClick={store.modalResetData}
                        buttonType={ButtonTypes.SECONDARY}
                        userType={onboarding.formData.userType}
                    >
                        I don't have permission
                    </Button>
                    <Button
                        id="continue-button"
                        tabIndex={0}
                        onClick={handleClick}
                        buttonType={ButtonTypes.PRIMARY}
                        userType={onboarding.formData.userType}
                    >
                        Yes, I have permission
                    </Button>
                </ButtonWrapper>
            </ModalWrapper>
        );
    }
);
