import { useState, Dispatch, SetStateAction } from "react"
import { LoanType } from "../../../../../../../sparrow-ui-library/src/types/sparrow-types"
import styles from "./styles.module.scss"

export const LoanTypeButtons = ({setLoanType}: {setLoanType: Dispatch<SetStateAction<LoanType>>}) => {
    const [activeButton, setActiveButton] = useState(true)

    const RadioButton = ({ id, active, label }) => {
        const handleClick = () => {
            if(active) return
            setActiveButton(!activeButton)
            setLoanType(id)
        }


        return (
            <div className={styles.radioContainer} onClick={handleClick}>
                <div className={`${styles.radioInput} ${active ? styles.active : ""}`}></div>
                <div className={styles.radioInputLabel}>
                    {label}
                </div>
            </div>
        )
    }

    return (
        <>
            <p className={styles.txt}>Select the type of loan</p>
            <div className={styles.radioBtnsContainer}>
                <RadioButton id="PSL" active={activeButton} label="Private student loan" />
                <RadioButton id="SLR" active={!activeButton} label="Student loan refinancing" />
            </div>
        </>
    )
}
