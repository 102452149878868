import { useOnboarding } from "../../../../../common/core/providers/onboarding.provider";
import { observer } from "mobx-react";
import { getReviewCardsText, ReviewCardText } from "../../../utils/dynamic-page-text";
import { ReviewCard } from "./ReviewCard";
import { sentenceCase } from "../../../../../common/utils/global";
import { useNavigate } from "react-router-dom";
import { useSparrowUIContext, useSparrowUIFormsContext } from "../../../../../sparrow-ui-library/src/context";

const styles = {
    pTag: {
        fontSize: "20px",
        lineHeight: "28px",
        fontWeight: "600",
        marginTop: "32px",
        marginBottom: "16px",
    },
};

export const ReviewCards = observer(() => {
    const onboarding = useOnboarding();
    const {setForms} = useSparrowUIFormsContext(); 
    const { displayReviewCards } = useSparrowUIContext();
    const navigate = useNavigate();

    const reviewCardsText = getReviewCardsText(onboarding);

    const onEditHandler = (item: ReviewCardText, isDirectInclusion: boolean = false) => {
        onboarding.setCreatorIsIncludingUser(isDirectInclusion);
        //each review card should always have an edit url, except for the ssn card which exists in the review page
        if (item.editUrl) { 
            // important to reset forms before navigating to a page with forms or else last shown form will be displayed
            setForms([]);
            displayReviewCards(false);
            navigate(item.editUrl)
        };
    };

    return (
        <>
            {reviewCardsText.activeUser.map((item) => {
                return (
                    <ReviewCard
                        key={item.title}
                        id={item.id}
                        title={item.title}
                        icon={item.icon}
                        reviewFieldTitle={item.reviewFieldTitle}
                        reviewFieldValue={item.reviewFieldValue}
                        subFields={item.subFields}
                        editUrl={item.editUrl}
                        onEdit={() => onEditHandler(item)}
                    />
                );
            })}
            {/* If a Creator reaches the review step after including a user, then display their included user's information as well*/}
            {onboarding.creatorIsIncludingUser && (
                <>
                    <p style={styles.pTag}>
                        {`${sentenceCase(
                            onboarding.formData.includedUser?.userType as string
                        )} information`}
                    </p>

                    {reviewCardsText.includedUser?.length &&
                        reviewCardsText.includedUser.map((item) => {
                            return (
                                <ReviewCard
                                    key={item.title}
                                    id={item.id}
                                    title={item.title}
                                    icon={item.icon}
                                    reviewFieldTitle={item.reviewFieldTitle}
                                    reviewFieldValue={item.reviewFieldValue}
                                    subFields={item.subFields}
                                    editUrl={item.editUrl}
                                    onEdit={() => onEditHandler(item, true)}
                                />
                            );
                        })}
                </>
            )}
        </>
    );
});
