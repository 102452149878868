import { useSparrowUIFormsContext } from "../../context";
import { AddressFieldProps, FormType } from "../../types/form-types";
import { Address } from "../../constants/default-inputs";
import { deepClone } from "../../utils/general-utils";
import { FormProps } from "../../types/form-types";

const useHandlePermanentAddressChanges = () => {
    const { forms, setForms } = useSparrowUIFormsContext();
    // Finds the address field in a form with the given formType and fieldName
    const getAddressField = ({
        formType,
        fieldName,
    }: {
        formType: FormType;
        fieldName: string;
    }) => {
        // Typecase to FormProps[], given radio button forms do not need to support mailing address changes
        const formWithAddressField = (forms as FormProps[]).find((form) =>
            form.formType?.includes(formType)
        );
        return formWithAddressField?.independentFields.find(
            (field) => field.name === fieldName
        ) as AddressFieldProps;
    };

    //Updates the mailing address field based on if the mailing address is the same as the permanent address
    const updateMailingAddressField = (
        permanentAddressField: AddressFieldProps,
        mailingAddressField: AddressFieldProps
    ) => {
        // If the mailing address is the same as the permanent address, update the mailing address field with the permanent address field
        mailingAddressField.inputValue = permanentAddressField.inputValue.mailingAddressIsSameAsPermanentAddress ? { ...permanentAddressField.inputValue } : Address();   
    };

    const handlePermanentAddressChanges = () => {
        const permanentAddressField = getAddressField({
            formType: "permanentAddress",
            fieldName: "permanentAddress",
        });
        if (!permanentAddressField || !permanentAddressField.asksForMailingAddress) return;
        const mailingAddressField = getAddressField({
            formType: "mailingAddress",
            fieldName: "mailingAddress",
        });

        if (!mailingAddressField) return;
        // Directly update the mailing address field
        updateMailingAddressField(permanentAddressField, mailingAddressField);
        // Update form state
        setForms(deepClone(forms));
    };

    return handlePermanentAddressChanges;
}

export default useHandlePermanentAddressChanges