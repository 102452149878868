const alphabetRegex = /^[a-zA-Z]+$/;
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+\-/=?^_`{|}~@]+$/;

export const alphabetCheck = (value: string) => {
    return alphabetRegex.test(value);
}

export const middleInitialCheck = (value: string) => {
    return alphabetRegex.test(value) && value.length === 1;
}

export const emailCheck = (value: string) => {
    return !/\s/.test(value) && emailRegex.test(value);
}