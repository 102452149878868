"use client";
import React, { useEffect, useState } from "react";
import SlideToggle from "react-slide-toggle";
import { useMediaQuery } from "react-responsive";

import { useStore } from "../../../../core/providers/global.provider";
import { Put } from "../../../../core/services/clientApi";
import { offersPin, offerBookmark } from "../../../../core/services/sparrow-api-endpoints";
import { IOffer, LoanType } from "../../../../core/services/types/offers-api-types";
import { isDefined } from "../../../../utils/helper_functions/validation-functions";
import { formatMoneyAmount } from "../../../../utils/money";
import { OffersInteractionLimitModal } from "../../../modals/OffersPageModals/OffersInteractionLimitModal";
import { OfferCardApplyMessage } from "../../OfferCardApplyMessage";
import { OfferDetail, calculateOfferDetails } from "../../OfferCardUtilities";
import { OfferCardBodyDetailSection } from "../../body/OfferCardBodyDetailSection";
import { OfferCardHeaderLenderContainer } from "../../header/OfferCardHeaderLenderContainer";
import { OfferCardHeaderDetailSection } from "../../header/detail/OfferCardHeaderDetailSection";
import { OfferCardHeaderDetailsForMobile } from "../../header/detail/OfferCardHeaderDetailsForMobile";
import { OfferCardHeaderInteractionButtons } from "../../header/interaction/OfferCardHeaderInteractionButtons";
import { OfferCardHeaderInteractionContainer } from "../../header/interaction/OfferCardHeaderInteractionContainer";

import "../../style.scss";
import { UserType } from "../../../../constants/defaultInputs";
import { useOnboarding } from "../../../../core/providers/onboarding.provider";

import orangeInfoIcon from "../../../../../assets/images/icons/orangeInfoIcon.svg";
import { observer } from "mobx-react";
import { IIncomeBasedOffer } from "../../../../core/services/types/offers-api-types";
import { OffersTablePreferencesFrontend } from "../../../../../sparrow-ui-library/src/types/sparrow-types/offers-table-preferences";

interface RateCardDefaultProps {
    offer: IOffer | IIncomeBasedOffer;
    staredCount: number;
    staredChange: Function;
    onPinned: Function;
    bookmarkedCount: number;
    bookmarkedChange: Function;
    onBookmarked: Function;
    lenderDisclosure: string;
    pinnedOfferIndex?: number;
    bestRateLabel?: OffersTablePreferencesFrontend;
    chartSelectedRate: number | null;
    setChartSelectedRate: (v: number | null) => void;
}

export const RateCardDefault: React.FunctionComponent<RateCardDefaultProps> = observer(
    ({
        offer,
        staredCount,
        staredChange,
        onPinned,
        bookmarkedCount,
        bookmarkedChange,
        onBookmarked,
        lenderDisclosure,
        pinnedOfferIndex,
        bestRateLabel,
        chartSelectedRate,
        setChartSelectedRate,
    }) => {
        const { setSnackError, setModal, modalResetData, setLoading } = useStore();

        const onboarding = useOnboarding();
        const isCosigner = onboarding.formData.userType === UserType.COSIGNER;

        const [offerDetails, setOfferDetails] = useState<OfferDetail[]>([]);
        const [pinCurrentValue, setPin] = useState<boolean>(
            isCosigner ? !!offer.cosignerPinned : !!offer.pinned
        );
        const [bookmarkCurrentValue, setBookmark] = useState<boolean>(
            isCosigner ? !!offer.cosignerBookmarked : !!offer.bookmarked
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [lenderName, setLenderName] = useState<string>(offer.lender);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [personalizedURL, setPersonalizedURL] = useState<string>(offer.personalizedUrl);
        const [displayCongrats, setDisplayCongrats] = useState<boolean>(false);

        async function pinToggle() {
            const pinValue = pinCurrentValue;
            const body = {
                requestId: offer.requestId,
                offerId: offer.id,
                pinned: !pinValue,
            };

            // If we have 5 or more pinned offers and the value of the current pin value is false
            // This means we are trying to pin another unpinned offer
            if (staredCount >= 5 && !pinValue) {
                setModal(
                    () => (
                        <OffersInteractionLimitModal
                            title={"You can graph up to 5 rates at a time"}
                            onSubmit={modalResetData}
                        />
                    ),
                    false,
                    true,
                    "sm",
                    false,
                    true
                );
                return;
            }

            setLoading(true);

            try {
                const response = await Put(offersPin, onboarding.jwt as string, body);
                if (response.offer) {
                    setPin(!!response.offer.pinned);
                    onPinned(response.offer.id);
                } else {
                    setPin(false);
                    setSnackError("unable to load offers", "error");
                }
                staredChange(staredCount, pinValue);
            } catch {}

            setLoading(false);
        }

        async function bookmarkToggle() {
            const body = {
                requestId: offer.requestId,
                offerId: offer.id,
                bookmarked: !bookmarkCurrentValue,
            };

            if (bookmarkedCount >= 5 && !bookmarkCurrentValue) {
                setModal(
                    () => (
                        <OffersInteractionLimitModal
                            title={"You can only bookmark up to 5 rates at a time"}
                            onSubmit={modalResetData}
                        />
                    ),
                    false,
                    true,
                    "sm",
                    false,
                    true
                );
                return;
            }

            setLoading(true);

            const response = await Put(offerBookmark, onboarding.jwt as string, body);
            if (isDefined(response.offer)) {
                setBookmark(
                    isCosigner ? response.offer.cosignerBookmarked : response.offer.bookmarked
                );
                onBookmarked(response.offer.id);
            } else {
                setSnackError("unable to load offers", "error");
            }
            bookmarkedChange(bookmarkedCount, bookmarkCurrentValue);

            setLoading(false);
        }

        useEffect(() => {
            setOfferDetails(calculateOfferDetails(offer as IOffer));
            setPin(isCosigner ? offer.cosignerPinned : offer.pinned);
            setBookmark(isCosigner ? offer.cosignerBookmarked : offer.bookmarked);
        }, []);

        const isMore1200px = useMediaQuery({
            query: "(min-width: 1200px)",
        });

        function getHighlight() {
            if (chartSelectedRate != null && chartSelectedRate === pinnedOfferIndex) {
                return "chart-selected-" + pinnedOfferIndex;
            }
            return "";
        }

        function handleMouseEnter() {
            if (isDefined(pinnedOfferIndex)) {
                setChartSelectedRate(pinnedOfferIndex ?? null);
            }
        }

        function handleMouseLeave() {
            if (isDefined(pinnedOfferIndex)) {
                setChartSelectedRate(null);
            }
        }

        function showApplyWithoutCosigner() {
            return onboarding.formData.userType === UserType.BORROWER && offer.applyWithoutCosigner;
        }

        return (
            <>
                <SlideToggle collapsed>
                    {({ toggle, setCollapsibleElement, progress }) => (
                        <div
                            className={getHighlight() + " offer-card"}
                            onMouseEnter={() => handleMouseEnter()}
                            onMouseLeave={() => handleMouseLeave()}
                        >
                            <div className="offer-card-header">
                                <div className="lender-and-detail-header-section">
                                    <OfferCardHeaderLenderContainer
                                        progress={progress}
                                        toggle={toggle}
                                        lenderName={lenderName}
                                        imageUrl={offer.imageUrl}
                                        bestRateLabel={bestRateLabel}
                                        isDefault={true}
                                    />
                                    {!progress && isMore1200px && (
                                        <OfferCardHeaderDetailSection
                                            apr={
                                                (offer as IOffer).loanType === LoanType.FIXED
                                                    ? (offer as IOffer).fixedAPR
                                                    : (offer as IOffer).startingAPR
                                            }
                                            totalCost={
                                                formatMoneyAmount(
                                                    (offer.amount.amount as number) +
                                                        ((offer as IOffer).totalInterestExpense
                                                            .amount as number)
                                                ).display.split(".")[0]
                                            }
                                            term={offer.term}
                                            offerDetails={offerDetails}
                                            lenderDisclosure={lenderDisclosure}
                                        />
                                    )}
                                </div>
                                {isMore1200px && (
                                    <OfferCardHeaderInteractionContainer
                                        offer={offer}
                                        progress={progress}
                                        toggle={toggle}
                                        pinToggle={pinToggle}
                                        bookmarkToggle={bookmarkToggle}
                                        pinCurrentValue={pinCurrentValue}
                                        bookmarkCurrentValue={bookmarkCurrentValue}
                                        pinnedOfferIndex={pinnedOfferIndex}
                                        personalizedURL={personalizedURL}
                                        onApply={setDisplayCongrats}
                                        applyWithoutCosigner={showApplyWithoutCosigner()}
                                    />
                                )}
                                {!isMore1200px && (
                                    <OfferCardHeaderDetailsForMobile offerDetails={offerDetails} />
                                )}
                            </div>
                            {showApplyWithoutCosigner() && isMore1200px && progress === 0 && (
                                <div className="apply-without-cosigner">
                                    <img alt="info" src={orangeInfoIcon} />
                                    {`You were able to pre-qualify for this rate without your cosigner. If you decide to take the next step and apply with this lender, you should do so without including ${
                                        onboarding.formData.isCreator
                                            ? onboarding.formData.includedUser?.firstName
                                            : onboarding.formData.creator?.firstName
                                    }.`}
                                </div>
                            )}
                            <div
                                className={`${progress === 1 ? "offer-card-body-visible" : ""}`}
                                ref={setCollapsibleElement}
                            >
                                <div className={`offer-card-body`}>
                                    <OfferCardBodyDetailSection
                                        offerDetails={offerDetails}
                                        lenderDisclosure={lenderDisclosure}
                                    />
                                </div>
                            </div>
                            {!isMore1200px && (
                                <OfferCardHeaderInteractionButtons
                                    offer={offer}
                                    progress={progress}
                                    pinToggle={pinToggle}
                                    bookmarkToggle={bookmarkToggle}
                                    pinCurrentValue={pinCurrentValue}
                                    bookmarkCurrentValue={bookmarkCurrentValue}
                                    pinnedOfferIndex={pinnedOfferIndex}
                                    personalizedURL={personalizedURL}
                                    onApply={setDisplayCongrats}
                                    applyWithoutCosigner={showApplyWithoutCosigner()}
                                ></OfferCardHeaderInteractionButtons>
                            )}
                            {showApplyWithoutCosigner() && (progress === 1 || !isMore1200px) && (
                                <div className="apply-without-cosigner">
                                    <img alt="info" src={orangeInfoIcon} />
                                    {`You were able to pre-qualify for this rate without your cosigner. If you decide to take the next step and apply with this lender, you should do so without including ${
                                        onboarding.formData.isCreator
                                            ? onboarding.formData.includedUser?.firstName
                                            : onboarding.formData.creator?.firstName
                                    }.`}
                                </div>
                            )}
                        </div>
                    )}
                </SlideToggle>
                {displayCongrats ? (
                    <OfferCardApplyMessage handleClick={setDisplayCongrats} />
                ) : (
                    <></>
                )}
            </>
        );
    }
);
