import "./gray-questionmark-icon.styles.css";

export const GrayQuestionmarkIcon = () => {
    return (
        <svg
            className="GrayQuestionmarkIcon"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M14 8C14 9.5913 13.3679 11.1174 12.2426 12.2426C11.1174 13.3679 9.5913 14 8 14C6.4087 14 4.88258 13.3679 3.75736 12.2426C2.63214 11.1174 2 9.5913 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2C9.5913 2 11.1174 2.63214 12.2426 3.75736C13.3679 4.88258 14 6.4087 14 8ZM6.122 6.52475H6.74075C6.84425 6.52475 6.92675 6.44 6.94025 6.33725C7.00775 5.84525 7.34525 5.48675 7.94675 5.48675C8.46125 5.48675 8.93225 5.744 8.93225 6.36275C8.93225 6.839 8.65175 7.058 8.2085 7.391C7.70375 7.75775 7.304 8.186 7.3325 8.88125L7.33475 9.044C7.33554 9.09321 7.35564 9.14013 7.39072 9.17465C7.4258 9.20916 7.47304 9.22851 7.52225 9.2285H8.1305C8.18023 9.2285 8.22792 9.20875 8.26308 9.17358C8.29825 9.13842 8.318 9.09073 8.318 9.041V8.96225C8.318 8.42375 8.52275 8.267 9.0755 7.84775C9.53225 7.5005 10.0085 7.115 10.0085 6.30575C10.0085 5.1725 9.0515 4.625 8.00375 4.625C7.0535 4.625 6.0125 5.0675 5.94125 6.3395C5.94022 6.36372 5.94416 6.3879 5.95282 6.41054C5.96149 6.43318 5.97469 6.45381 5.99162 6.47116C6.00855 6.48851 6.02885 6.50222 6.05127 6.51143C6.07369 6.52065 6.09776 6.52518 6.122 6.52475ZM7.86575 11.357C8.32325 11.357 8.6375 11.0615 8.6375 10.6617C8.6375 10.2478 8.3225 9.95675 7.86575 9.95675C7.42775 9.95675 7.109 10.2478 7.109 10.6617C7.109 11.0615 7.42775 11.357 7.8665 11.357H7.86575Z"
                fill="#CCD6DD"
            />
        </svg>
    );
};
