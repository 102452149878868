"use client";
import "../../../assets/styles/global.scss";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useStore } from "../../../common/core/providers/global.provider";
import { useOnboarding } from "../../../common/core/providers/onboarding.provider";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import ReactGA from "react-ga";
import { checkObjectProperty, linkHandler, sendUtms } from "../../../common/utils/global";
import { dashboardUrl, signUpStepTypeUrl } from "../../../common/core/routes/navigation-urls";
import { LinkButton } from "../../../common/components/buttons/link_button";
import { InputText } from "../../../common/components/inputs/input_text/input_text";
import { observer } from "mobx-react";
import gLogo from "../../../assets/images/gmail.svg";
import { RegularButtonWithLoading } from "../../../common/components/buttons/regular_button/RegularButtonWithLoading";
import { useLocation, useSearchParams } from "react-router-dom";
import { confirmEmailLoginBase, resetPasswordBase } from "../../../common/core/routes/routing-urls";
import { GetCurrentUserAndUpdateStore } from "../../../common/core/services/sparrow-api-handlers/profile-api-handlers";
import { isMarketplaceEnv } from "../../../common/utils/config";
import { AuthHandler } from "../../../common/core/auth";

export const SignInPage = observer(() => {
    const {
        setUserData,
        setSnackError,
        setAuthSnackError,
        authNotif,
        saveLoginToStore,
        getSocket,
    } = useStore();
    const { setJwt, setRouteState, paaasCustomization } = useOnboarding();

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [password, setPassword] = useState("");
    const [showPsw, setShowPsw] = useState(false);
    const [valid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const routerPathname = useLocation().pathname;
    const [searchParams] = useSearchParams();

    const RegEXP =
        '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

    const onEmailChange = (value: string) => {
        const errorMess = !value.match(RegEXP) && value.length > 0 ? "Email is not valid" : "";
        setEmailError(errorMess);
        setEmail(value);
    };

    useEffect(() => {
        if (authNotif && authNotif.type === "error") {
            setEmailError(" ");
            setPasswordError(" ");
        } else {
            setEmailError("");
            setPasswordError("");
        }
    }, [authNotif?.type]);

    const onPasswordChange = (value: string) => {
        setPassword(value);
        const errorMess =
            value.length < 12 && value.length > 0 ? "The minimum length must be 12 characters" : "";
        setPasswordError(errorMess);
    };

    const checkValidation = useCallback(() => {
        setIsValid(
            password.length > 0 && email.length > 0 && !emailError.length && !passwordError.length
        );
    }, [email.length, emailError.length, password.length, passwordError.length]);

    const onSubmit = useCallback(async () => {
        setLoading(true);
        try {
            const data = await AuthHandler.signIn({
                username: email,
                password: password,
                partnerId: paaasCustomization?.partnerId,
            });
            getSocket();
            setJwt(data.signInUserSession.idToken.jwtToken);

            await GetCurrentUserAndUpdateStore({
                jwt: data.signInUserSession.idToken.jwtToken,
                setUserData,
            });
            ReactGA.set({ userId: data.attributes.sub });
            if (checkObjectProperty(window, "dataLayer")) {
                (window as any).dataLayer.push({
                    userId: data.attributes.sub,
                    event: "authentication",
                });
            }
            sendUtms(data.signInUserSession.idToken.jwtToken);

            let redirectUrl = searchParams.get("redirects");
            if (!!searchParams.toString()) {
                if (redirectUrl) {
                    const restParamsIndex = searchParams.toString().indexOf("&");
                    const restParams =
                        restParamsIndex > 0
                            ? "?" +
                              searchParams
                                  .toString()
                                  .substring(restParamsIndex + 1, searchParams.toString().length)
                            : "";
                    navigate(redirectUrl + restParams);
                } else {
                    linkHandler(
                        searchParams.toString(),
                        data.signInUserSession.idToken.jwtToken,
                        setSnackError,
                        navigate
                    );
                }
            } else {
                navigate(dashboardUrl);
            }
        } catch (e: any) {
            // In case of Login Error
            if (e.message === "User is not confirmed.") {
                AuthHandler.resendSignUp(email, paaasCustomization?.partnerId)
                    .then(() => {
                        saveLoginToStore({
                            email: email,
                            password: password,
                        });
                        setLoading(false);
                        setRouteState({ email: email });
                        navigate(confirmEmailLoginBase);
                        // { pathname: confirmEmailLoginBase, state: { email: email } }
                    })
                    .catch((e) => {
                        setLoading(false);
                        setAuthSnackError(e.message, "error");
                    });
            } else if (e.message !== "Pending sign-in attempt already in progress") {
                if (
                    e.message === "User does not exist." ||
                    e.message === "Incorrect username or password."
                ) {
                    setTimeout(() => {
                        setLoading(false);
                        setAuthSnackError(
                            "That email and password combination is incorrect.",
                            "error"
                        );
                        // custom error lag
                    }, 3000);
                } else {
                    setTimeout(() => {
                        setLoading(false);
                        setAuthSnackError(e.message, "error");
                        // custom error lag
                    }, 3000);
                }
            }
        }
    }, [email, password, searchParams]);

    useEffect(() => {
        const onSubmitEnterKey = async (e: KeyboardEvent) => {
            if (valid && e.key === "Enter" && routerPathname === "/login") {
                await onSubmit();
            }
        };
        document.addEventListener("keypress", onSubmitEnterKey);
        return () => {
            document.removeEventListener("keypress", onSubmitEnterKey);
        };
    }, [valid, routerPathname]);

    useEffect(() => {
        checkValidation();
    }, [email, password]);

    return (
        <div className="auth-flow-container--inner">
            <div className="step-form-header">
                <h2 className="h2">Sign in to your account</h2>
                <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                    <div className="subtitle">Don't have an account?</div>
                    <LinkButton link={signUpStepTypeUrl} underline={true}>
                        Sign Up
                    </LinkButton>
                </div>
            </div>
            <div className="step-form-body">
                <div className="step-form-block">
                    <div className="form-row">
                        <InputText
                            value={email}
                            name="email"
                            type="email"
                            label="Email"
                            placeholder="Your Email"
                            error={emailError}
                            onChange={(e: any) => onEmailChange(e.target.value)}
                            onClear={() => onEmailChange("")}
                        />
                    </div>
                    <div className="form-row">
                        <InputText
                            value={password}
                            name="password"
                            type={showPsw ? "text" : "password"}
                            label={
                                <>
                                    Password
                                    <div className="forgot-password text-right">
                                        <LinkButton link={resetPasswordBase} underline={true}>
                                            Forgot password?
                                        </LinkButton>
                                    </div>
                                </>
                            }
                            placeholder="Your Password"
                            error={passwordError}
                            passwordType={true}
                            passwordShown={showPsw}
                            showPassword={() => setShowPsw(!showPsw)}
                            onChange={(e: any) => onPasswordChange(e.target.value)}
                            onClear={() => onPasswordChange("")}
                        />
                    </div>
                </div>
            </div>
            <div className="step-form-footer">
                <div className="button-group text-right">
                    <RegularButtonWithLoading
                        loading={loading}
                        id="credentialed-login-link"
                        onSubmit={onSubmit}
                        disabled={!valid}
                    >
                        Continue
                    </RegularButtonWithLoading>
                </div>
                {isMarketplaceEnv() && (
                    <div className="button-group text-center">
                        <button
                            onClick={() => {
                                AuthHandler.federatedSignIn(CognitoHostedUIIdentityProvider.Google);
                            }}
                            className="sso-button"
                        >
                            <img src={gLogo} alt={""} />
                            Sign in via Gmail
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
});
