import { observer } from "mobx-react";
import { useOnboarding } from "../../../../../common/core/providers/onboarding.provider";
import removeDecimals from "../../../../../common/utils/removeDecimals";
import { formatDateAsMMMDDYYYY } from "../../../../../common/utils/helper_functions/date-functions";
import { LenderDataPopup } from "./LenderDataPopup";
import { formatMoneyAmount } from "../../../../../common/utils/money";
import { getArticle } from "../../../../../common/utils/global";
import "./styles.scss";


export const ApplyWithLenderPopup = observer(() => {
    const onboarding = useOnboarding();
    const {companyName} = onboarding;

    const lastExploredOffer =
        onboarding.activePostPrequalRequestData?.applyWithLender?.lastExploredOffer;
    const lenderData = onboarding.activePostPrequalRequestData?.applyWithLender?.lenderData;

    const getLastExploredTime = () => {
        const userType = onboarding.formData.userType;

        if (!userType) {
            return 0;
        }

        if (userType === "borrower") {
            const exploredOffers = lastExploredOffer?.exploredAtTimes as number[];
            return exploredOffers[exploredOffers.length - 1];
        }

        const cosignerExploredOffers = lastExploredOffer?.cosignerExploredAtTimes as number[];
        return cosignerExploredOffers[cosignerExploredOffers.length - 1];
    };

    const getLenderName = () => {
        if (lenderData) {
            return lenderData.lenderName;
        }

        return lastExploredOffer?.lender;
    };

    const getLoanAmount = () => {
        if (lenderData) {
            return lenderData.loanSize
                ? formatMoneyAmount(lenderData.loanSize, false).display
                : onboarding.formData.loanAmount.display;
        }

        return lastExploredOffer?.amount.display;
    };

    return (
        <div className="rm-apply-with-lender-popup">
            {(lenderData || lastExploredOffer) && (
                <div className="awl-lender-group-data">
                    <>
                        <div className="awl-lender-data">
                            <p className="p-md-14-regular clr-neutral-500">Latest rate explored:</p>
                            <div className="awl-lender">
                                <img
                                    style={{ width: "24px", height: "24px" }}
                                    src={
                                        onboarding.activePostPrequalRequestData?.applyWithLender
                                            ?.lenderLogo
                                    }
                                    alt="lender logo"
                                />
                                <p className="p-lg-16-semi-bold">{getLenderName()}</p>
                            </div>
                        </div>
                        <div className="awl-lender-data">
                            <p className="p-md-14-regular clr-neutral-500">Loan size:</p>
                            <div>
                                <p className="p-lg-16-semi-bold">
                                    {removeDecimals(getLoanAmount() as string)}
                                </p>
                            </div>
                        </div>
                    </>

                    {lenderData && <LenderDataPopup lenderData={lenderData} />}
                </div>
            )}
            {/* NOTE: if this component is being used for steps 4 and 5, this will never show because those steps require there to be lender data */}
            {!lenderData && lastExploredOffer && (
                <p className="p-md-14-regular clr-neutral-500" style={{ marginTop: "16px" }}>
                    You explored {getArticle(lastExploredOffer.lender)} {lastExploredOffer.lender} pre-qualified rate on{" "}
                    {formatDateAsMMMDDYYYY(getLastExploredTime())}. Sometimes there is a lag between
                    what {companyName} sees and what lenders report back to us. If you have already
                    applied with a lender and are wondering about the status of your loan, please
                    contact the lender directly.
                </p>
            )}
        </div>
    );
});
