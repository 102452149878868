"use client";
import { FC, useEffect, useRef, useState } from "react";
import Lottie, { LottieRefCurrentProps } from "lottie-react";
import "./style.scss";
interface IIconAnimated {
    animateJson: any;
    props?: any | null;
    loop?: boolean;
    autoplay?: boolean;
    delay?: number;
    isPaused?: boolean;
    event?: any;
    size?: number;
    fallback?: string;
}
const IconAnimated: FC<IIconAnimated> = ({
    animateJson,
    loop = true,
    autoplay = true,
    isPaused = true,
    size,
    fallback,
}) => {
    const [animData, setAnimData] = useState<any>(null);

    useEffect(() => {
        if (animateJson) {
            setAnimData(animateJson);
        }
    }, [animateJson]);

    const lottieRef: React.MutableRefObject<LottieRefCurrentProps | null> = useRef(null);

    useEffect(() => {
        if (isPaused) {
            lottieRef.current?.pause();
        } else {
            lottieRef.current?.play();
        }
    }, [isPaused]);

    if (!animateJson) {
        return <></>;
    }

    return (
        <div className="animated-ico">
            {!animData && fallback && <img src={fallback} alt="" width={size} height={size} />}
            {animData && (
                <Lottie
                    lottieRef={lottieRef}
                    autoplay={autoplay}
                    animationData={animData}
                    loop={loop}
                    style={
                        size
                            ? {
                                  width: size + "px",
                                  height: size + "px",
                              }
                            : {}
                    }
                />
            )}
        </div>
    );
};
export default IconAnimated;
