import { useState } from "react";
import Slider from "rc-slider";
import { FormFieldType, LoamAmountFieldProps, SetInputValue } from "../../../types/form-types";
import { UserType } from "../../../types/sparrow-types";
import TextBox from "../text-box";
import SliderStyles from "./SliderStyles";
import { amountFormat, removeDollarFormatting } from "../../../utils/general-utils";
import "./loan-amount.styles.scss";

interface LoanSliderProps extends LoamAmountFieldProps {
    userType: UserType;
    setInputValue: SetInputValue;
}

// Input value can either be modified by the slider or the text box
const LoanAmount: React.FC<LoanSliderProps> = ({
    userType,
    minValue,
    maxValue,
    inputValue,
    setInputValue,
    id,
    label,
    placeholder,
    name,
    backendValue,
}) => {

    // Input values are strings represented as dollars
    const [sliderValue, setSliderValue] = useState(removeDollarFormatting(inputValue as string) / 100);
    
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        const amount = removeDollarFormatting(value) / 100;
        if(amount > maxValue) return;
        setInputValue(name, value)
        setSliderValue(amount)
    }

    const handleSliderChange = (value: number) => {
        setSliderValue(value);
        setInputValue(name, String(value));
    };

    return (
        <>
            <TextBox
            formFieldType={FormFieldType.TextBox}
                id={id}
                type="text"
                label={label}
                placeholder={placeholder}
                inputValue={inputValue}
                name={name}
                backendValue={backendValue}
                onChange={handleInputChange}
                />
            <SliderStyles userType={userType} />
            <Slider
                value={sliderValue}
                max={maxValue}
                min={minValue}
                onChange={handleSliderChange}
                className={`Slider ${userType}}`}
            />
            <div className="SliderBounds">
                <span>{amountFormat(String(minValue))}</span>
                <span>{amountFormat(String(maxValue))}</span>
            </div>
        </>
    );
};

export default LoanAmount;