import { PAAASLogo } from "../../../../PAAASLogo"
import { isMarketplaceEnv } from "../../../../../utils/config"
import { dashboardUrl } from "../../../../../core/routes/navigation-urls"
import { SparrowIcon } from "../../../../../../sparrow-ui-library/src/assets/icons/sparrow-icon/SparrowIcon"

export const Logo = () => {
    return (
        <>
            {isMarketplaceEnv() ? (
                <a href="/">
                    <SparrowIcon color="#fff" />
                </a>
            ) : (
                <PAAASLogo navigateTo={dashboardUrl} />
            )}
        </>
    )
}
