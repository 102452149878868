import { HomeCard } from "../../components/home-card/HomeCard";
import { oneFormSectionContent } from "../../constants/home-cards-content";
import { SectionWrapper } from "../../components/section-wrapper/SectionWrapper";
import { SectionHeader } from "../../components/section-header/SectionHeader";
import { Button } from "../../../../common/components/buttons/button/Button";
import { UserType } from "../../../../common/constants/defaultInputs";
import styles from "./styles.module.scss";
import { ButtonTypes } from "../../../../common/components/buttons/button/Button";
import { useNavigate } from "react-router-dom";
import { signUpStepBorrowerUrl } from "../../../../common/core/routes/navigation-urls";

export const OneFormSection = () => {
    const navigate = useNavigate();

    return (
        <SectionWrapper hasBackground={true}>
            <div className={styles.sectionContainer}>
                <SectionHeader
                    sectionText="How It Works"
                    title="One form for lenders across the nation"
                />
            </div>
            <div className={styles["card-content"]}>
                {oneFormSectionContent.map((card, i) => (
                    <HomeCard key={`homeCard-${i}`} {...card} noIcon={true} />
                ))}
            </div>
            <Button
                buttonType={ButtonTypes.PRIMARY}
                userType={UserType.BORROWER}
                showIcon={true}
                style={{ fontSize: "16px", fontWeight: "500", float: "left", marginTop: "30px" }}
                onClick={() => navigate(signUpStepBorrowerUrl) }
            >Find my rate
            </Button>
        </SectionWrapper>

    );
};
