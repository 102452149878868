import { Button, ButtonTypes } from "../../../buttons/button/Button";

import "./styles.scss";

interface ILeftNavigationStartRequestButton {
    handleClick: () => void;
}

export const LeftNavigationStartRequestButton = ({
    handleClick,
}: ILeftNavigationStartRequestButton) => {
    return (
        <div className="left-nav-start-request-button">
            <Button buttonType={ButtonTypes.PRIMARY} onClick={handleClick} showIcon={true}>
                Start a new request
            </Button>
        </div>
    );
};
