import {
    FormFieldType,
    FormFieldName,
    FormInputsConfig,
    FormsConfig
} from "../../../sparrow-ui-library/src/types/form-types";
import { employedTypes } from "../../../sparrow-ui-library/src/constants/dropdown-options";
import { amountFormat } from "../../../sparrow-ui-library/src/utils/general-utils";
import { PrequalificationRequestInput } from "../../../sparrow-ui-library/src/types/sparrow-types";

export const getFormsConfig = (inputs: FormsConfig): FormInputsConfig => {
    const { applicableFormData, getApplicableUserText } = inputs as {
        applicableFormData: PrequalificationRequestInput;
        getApplicableUserText: (text: string) => string;
    };
    const hasIncomeType = !!(applicableFormData?.income && applicableFormData.income.type);

    return {
        forms: [
            {
                header: getApplicableUserText("What's your employment status?"),
                independentFields: [
                    {
                        formFieldType: FormFieldType.ReadonlyDropdown,
                        id: FormFieldName.IncomeType,
                        name: FormFieldName.IncomeType,
                        label: getApplicableUserText("Select your employment status"),
                        placeholder: "Select",
                        inputValue: hasIncomeType ? applicableFormData.income.type : "",
                        backendValue: "",
                        errorMessage: "",
                        dropdownOptions: employedTypes,
                    },
                ],
            },
            {
                header: getApplicableUserText("What's your annual primary pre-tax income?"),
                independentFields: [
                    {
                        formFieldType: FormFieldType.TextBox,
                        id: FormFieldName.IncomeAmount,
                        name: FormFieldName.IncomeAmount,
                        label: getApplicableUserText("Enter your annual primary pre-tax income. ($0 is allowed)"),
                        placeholder: "(e.g., $50,000)",
                        inputValue: hasIncomeType
                            ? amountFormat(applicableFormData.income.amount.amount)
                            : "",
                        backendValue: "",
                        errorMessage: "",
                        type: "text",
                    },
                ],
            },
        ],
    };
};
