import { FC } from "react";

interface FeedbackStarProps {
    id: string;
    isSelected: boolean;
    handleClick: (e: React.MouseEvent<SVGSVGElement>) => void;
}

export const FeedbackStar: FC<FeedbackStarProps> = (props) => {
    const { id, isSelected, handleClick } = props;

    return (
        <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id={id}
            onClick={handleClick}
        >
            
            <path
                d="M32 51.7982L47.5195 60.8013C50.3617 62.4513 53.8395 60.0122 53.0916 56.9275L48.978 39.9973L62.7025 28.591C65.2081 26.5106 63.8618 22.565 60.5709 22.3139L42.5084 20.8433L35.4405 4.84571C34.169 1.94032 29.831 1.94032 28.5595 4.84571L21.4916 20.8074L3.4291 22.278C0.138208 22.5291 -1.20807 26.4747 1.2975 28.5551L15.022 39.9615L10.9084 56.8916C10.1605 59.9764 13.6383 62.4155 16.4805 60.7655L32 51.7982Z"
                style={isSelected ? { fill: "#E6C414" } : { stroke: "#E6C414" }}
            />
        </svg>
    );
  };
  