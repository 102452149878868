import {InputText} from "../../../../common/components/inputs/input_text/input_text";
import {AppModal} from "../../../../common/components/modals/AppModal";
import {useEffect, useState} from "react";
import {CountryCode} from "../../../../common/components/country_code";

import { Button } from "../../../../common/components/buttons/button/Button";
import { ButtonTypes } from "../../../../common/components/buttons/button/Button";
import { UserType } from "../../../../common/constants/defaultInputs";
import { ButtonGroup } from "../../../../sparrow-ui-library/src/ui-components/buttons";

interface IProfileContactInfoModal {
    modalToggle: (show: boolean) => void;
    form: {
        email: string;
        firstName: string;
        lastName: string;
        currentPassword: string;
        newPassword: string;
        confirmPassword: string;
        phone_number: string;
    };
    onSubmit: () => void;
    onChange: (key: string, value: any) => void;
    setNeedVerifyEmail: (state: boolean) => void;
    setNeedVerifyPhone: (state: boolean) => void;
}
const ProfileContactInfoModal = ({modalToggle, form, onSubmit, onChange, setNeedVerifyEmail, setNeedVerifyPhone}: IProfileContactInfoModal) => {
    const [isValid, setIsValid] = useState(false);
    useEffect(() => {
        checkValidation();
    }, [form])
    const checkValidation = () => {
        setIsValid(!!form.email.match(RegEXP) && form.phone_number.length === 11)
    }
    const RegEXP = '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';

    return <AppModal onClose={() => modalToggle(false)} size={'md'} showCloseIcon={true} customClass={'profile-modal profile-modal-edit'} enableMobileView={true}>
        <div className="profile-modal-content profile-modal-content-edit">
            <div className="profile-modal-content__head">
                <h3 className="h3">Contact Information</h3>
            </div>
            <CountryCode
                name="phoneNumber"
                value={form.phone_number}
                onChange={(value: any) => {
                    onChange("phone_number", value)
                    setNeedVerifyPhone(true)
                }}
                error={''}
                onClear={() => onChange("phone_number" , "1")}
            />
            <div className="form-row">
                <InputText
                    placeholder={'Email address'}
                    type={'text'}
                    name={'email'}
                    label={'Email address'}
                    value={form.email}
                    onClear={() => onChange('email', '')}
                    onChange={(e) => {
                        onChange('email', e.target.value)
                        setNeedVerifyEmail(true)
                    }}
                    error={form.email.length > 0 && !form.email.match(RegEXP) ? "Email is not valid" : ''}
                />
            </div>

            <ButtonGroup styles={{ marginTop: "32px" }}>
                    <Button
                        disabled={!isValid}
                        style={{ fontSize: "16px", fontWeight: "500", lineHeight: "24px", height: "fit-content" }}
                        padding={"16px 25px"}
                        userType={UserType.BORROWER}
                        buttonType={ButtonTypes.PRIMARY}
                        onClick={onSubmit}
                    >
                        Update my contact information
                    </Button>
                </ButtonGroup>
        </div>
    </AppModal>
}

export default ProfileContactInfoModal