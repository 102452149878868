// TODO: finish moving remaining constants/types over from sparrow-ui-library before deleting this file


export enum UserType {
    BORROWER = "borrower",
    COSIGNER = "cosigner",
}

export interface ICitizenshipStatus {
    type: string;
    homeCountry: string;
    hasSSN: boolean | string;
    visaType: string;
}

export const CitizenshipStatus = (): ICitizenshipStatus => {
    return {
        type: "",
        homeCountry: "",
        hasSSN: "",
        visaType: "",
    };
};

export interface ICollege {
    university: string;
    gradDate: number | string;
    degreeType: string;
    major: string;
}

export const College = (): ICollege => {
    return {
        university: "",
        gradDate: "",
        degreeType: "",
        major: "",
    };
};
