import { observer } from 'mobx-react';
import { PopupIncludedUserContent } from '../PrequalifyContent/IncludedUserSection/PopupIncludedUserContent';
import { useOnboarding } from '../../../../../common/core/providers/onboarding.provider';
import { IncludedUserButton } from '../SharedPopupComponents/IncludedUserButton';
import {
   ProgressStatuses
} from '../../../../../common/models/request';
import { IncludedUserStatus } from '../../../../../sparrow-ui-library/src/types/sparrow-types';
import { UserType } from '../../../../../common/constants/defaultInputs';
import { PrequalifyStepCard } from '../PrequalifyContent/PrequalifyStepCard';
import { getPqStepContent } from '../../../utils/getPqStepContent';
import { AddCosigner } from '../SharedPopupComponents/IncludedUserComponents/AddCosigner';
import { OnboardingStore } from '../../../../../common/store/onboarding';
import "./styles.scss"

const getWrapperStyle = (onboarding: OnboardingStore) => {
    const { includedUser } = onboarding.formData;

    const showIncludedUserStepsClassName =
    onboarding.creatorIsIncludingUser || onboarding.formData.isIncludedUser ? "show-steps" : "show-no-steps";


    if (includedUser?.status === IncludedUserStatus.DIRECT_STARTED) {
        return `pu-bottom-ext-wrapper direct-started ${showIncludedUserStepsClassName}`
    }

    return `pu-bottom-ext-wrapper ${showIncludedUserStepsClassName}`;
}

export const PopupContentBottomExtension:React.FC<{progressStatus?:ProgressStatuses}> = observer(({progressStatus}) => {
    const onboarding = useOnboarding();

    const directInclusionPqStepContent = getPqStepContent(onboarding, true);

    const callerIsBorrowerCreator =
        onboarding.formData.isCreator && onboarding.formData.userType === UserType.BORROWER;

    const { offerExplored } = onboarding.activePostPrequalRequestData?.compareAndSelect ?? {};

    const displayIncludedUserButton = () => {
        const includedUserStatus = onboarding.formData.includedUser?.status;
        // Only display button if the user is a creator and has an included user or if the included user has not accepted their invite to join the request
        return (onboarding.formData.isCreator && onboarding.formData.includedUser) || (includedUserStatus  === IncludedUserStatus.INVITE_PENDING);
    }

    return (
        <div className={`${getWrapperStyle(onboarding)} ${progressStatus === ProgressStatuses.CompareAndSelect ? "cs-dir-incl" : ""}`}>
            <PopupIncludedUserContent>
                {!offerExplored && <>
                    {displayIncludedUserButton() && (
                        <IncludedUserButton />
                    )}
                    {callerIsBorrowerCreator && !onboarding.formData.includedUser && (
                        <AddCosigner />
                    )}
                </>}
            </PopupIncludedUserContent>

            {/* Only display if Creator is in the process of directly including their Included User*/}
            {(onboarding.formData.isCreator && onboarding.formData.includedUser?.status === IncludedUserStatus.DIRECT_STARTED) && (
                    <div className={"pqStepCards dir-inclusion"}>
                        {directInclusionPqStepContent.map((step, i) => {
                            return (
                                <PrequalifyStepCard
                                    key={step.title}
                                    stepTitle={step.title}
                                    stepData={step.formData}
                                    stepUrl={step.formUrl}
                                    isComplete={!!step.formData}
                                    isLatestIncomplete={onboarding.formData.includedStepsFinished === i }
                                    isDirectInclusion={true} //We want to use the color theme for the opposite user type
                                />
                            );
                        })}
                    </div>
            )}
            {/* isMobileButton boolean applies css logic to max-width screen size of 990px, shifting button below inclusion status and steps*/}
            {displayIncludedUserButton() && (
                        <IncludedUserButton isMobileButton={true}/>
                    )}

        </div>
    )
});
