import { FormFieldName, FormProps, RadioButtonFormProps, RadioButtonProps } from "../../../../../types/form-types";
import { FormFieldProps } from "../../../../../types/form-types";
import { getErrorMessageFunction, getFunctionForBackendValueFormat } from "../../..";
import { deepClone } from "../../../../general-utils";

/**
 * @description Assigns error messages to each form field (if any) and returns the number of errors. Function runs on submission as a second input validation check.
 * @param forms 
 * @returns Array of length 2. First element is the number of errors. Second element is the updated forms array.
 */
export const assignErrorMessagesAndReturnNumErrors = (forms: (RadioButtonFormProps | FormProps)[]): [number, (FormProps | RadioButtonFormProps )[]] => {
    let numErrors = 0;

    
    const updatedForms = forms.map((form: FormProps | RadioButtonFormProps) => {

        if(!(form.formType === "radioButtons")){
            const updatedFormFields = form.independentFields.map((formField) => {
                const errorMessageFunction = getErrorMessageFunction(formField.name);
                const errorMessage = errorMessageFunction?.(formField.inputValue, formField.name);
    
                formField.errorMessage = errorMessage ?? "";
                numErrors += errorMessage ? 1 : 0;
    
                return formField;
            });
    
            return { ...form, independentFields: updatedFormFields };
        }

        if(!form.optionSelected){
            form.errorMessage = "Please make a selection from one of the options above";
            numErrors += 1;
            return form;
        } 

        const updatedRadioButtonOptions = form.radioButtonOptions.map((radioButtonOption) => {
            if(!radioButtonOption.independentFields || !radioButtonOption.checked) return radioButtonOption;

            const updatedFormFields = radioButtonOption.independentFields.map((formField) => {
                const errorMessageFunction = getErrorMessageFunction(formField.name);
                const errorMessage = errorMessageFunction?.(formField.inputValue, formField.name);
    
                formField.errorMessage = errorMessage ?? "";
                numErrors += errorMessage ? 1 : 0;
    
                return formField;
            });

            return {...radioButtonOption, independentFields: updatedFormFields};
        });

        return {...form, radioButtonOptions: updatedRadioButtonOptions};

        
    });

    return [numErrors, updatedForms];
};

/**
 * @description Returns an object with the form field names as keys and the corresponding backend values.
 * @param formFields
 * @returns [key: FormFieldName]: BackendValue
 */
export const getBackendValuesForForm = (formFields:(RadioButtonProps | FormFieldProps)[]): { [key: string]: any } => {

    const formattedBackendValues = formFields.reduce((acc, formField) => {
        // Copy the form field to avoid mutating the original form field
        const copyOfFormField = deepClone(formField);
        // Get the function that formats the input value for the backend
        const functionForBackendValueFormat = getFunctionForBackendValueFormat(copyOfFormField.name);
        // Get the formatted value for the backend if the function exists, otherwise use the original input value
        const backendValue = functionForBackendValueFormat
            ? functionForBackendValueFormat(copyOfFormField.inputValue as any)
            : copyOfFormField.inputValue;

            // Return the form field name and the formatted value for the backend pair
        return { ...acc, [copyOfFormField.name]: backendValue };
    }, {})

    return formattedBackendValues;
}

/**
 * @description Catch all that handles mutations to the formatted backend values object before unflattening it for the backend.
 * @param formattedBackendValues
 * @returns void
 */
export const specificAdjustmentsPreUnflatteningFormBody = (formattedBackendValues: {
    [key: string]: string | number | null | undefined;
}) => {
    if (FormFieldName.IsSameAsPhoneNumber in formattedBackendValues) {
        if (formattedBackendValues[FormFieldName.IsSameAsPhoneNumber] === null) {
            formattedBackendValues[FormFieldName.WhatsAppNumber] = null;
        } else if (formattedBackendValues[FormFieldName.IsSameAsPhoneNumber]) {
            formattedBackendValues[FormFieldName.WhatsAppNumber] =
                formattedBackendValues["phoneNumber.phoneNumber"];
        }
    }
};
