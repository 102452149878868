import { PAAASLogo } from "../../../../common/components/PAAASLogo"
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider"
import { FooterLinks } from "./components/FooterLinks"
import styles from "./styles.module.scss"
import { observer } from "mobx-react"

export const Footer = observer(() => {
  const onboarding = useOnboarding();
  const footerItems = onboarding?.paaasCustomHeader?.menu.items ?? [];

  return (
    <footer className={styles.footerWrapper}>
      <div className={styles.container}>
        <div style={{ width: "calc(25% + 100px" }}>
          <PAAASLogo />
        </div>
        {!!footerItems.length && <div className={styles.gridContainer}>
          {footerItems.map((item, i) => {
            return <FooterLinks key={item.id} {...item} />
          })}
        </div>}
      </div>
    </footer>
  )
});
