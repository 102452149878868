import "./style.scss";
import rateModalIcon from "../../../../assets/images/icons/rateModalIcon.svg";
interface ModalProps {
    title: string;
    onSubmit: Function;
}

export const OffersInteractionLimitModal: React.FunctionComponent<ModalProps> = ({
    title,
    onSubmit,
}) => {
    return (
        <>
            <div className="rate-modal-container">
                <div className="rate-modal-text-container">
                    <img src={rateModalIcon} alt="icon" />
                    <div className="rate-modal-title">{title}</div>
                    <div className="rate-modal-subtitle">
                        In order to add a different rate to your comparison graphs, please unselect
                        one of the rates you are currently graphing.
                    </div>
                    <div onClick={() => onSubmit()} className={"rate-modal-button"}>
                        Got it!
                    </div>
                </div>
            </div>
        </>
    );
};
