import { useState } from "react";
import "./style.scss";
import { useOnboarding } from "../../core/providers/onboarding.provider";

interface BarChartSelectProps {
    onSelectCallback: Function;
}
export const BarChartSelect: React.FunctionComponent<BarChartSelectProps> = ({
    onSelectCallback,
}) => {
    const onboarding = useOnboarding();

    const isPSL = onboarding.formData.type === "PSL";

    const mock = [
        { label: "Total cost", value: "totalCost" },
        {
            label: isPSL ? "Payment after school" : "Monthly payment",
            value: "paymentAfterSchool",
        },
    ];
    const [selected, setSelected] = useState(mock[0]);

    return (
        <div className="bar-chart-select-container">
            <div
                className="bar-chart-select-option"
                onClick={() => {
                    setSelected(mock[0]);
                    onSelectCallback("totalCost");
                }}
            >
                Total cost
            </div>
            <div className="absolute-bar-select">
                <div
                    className={
                        "select-highlight " + (selected.value === "totalCost" ? "left" : "right")
                    }
                ></div>
            </div>
            <div
                className="bar-chart-select-option"
                onClick={() => {
                    setSelected(mock[1]);
                    onSelectCallback("paymentAfterSchool");
                }}
            >
                {isPSL ? "Payment after school" : "Est. monthly payment"}
            </div>
        </div>
    );
};
