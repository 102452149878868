import { ReactNode } from "react";
// TODO: Review which styles are actually needed / are truly global in nature
import "../../../assets/styles/global.scss"
import "../../../assets/styles/layouts.scss"
import "../../../assets/styles/root.scss";
import "../../../assets/styles/main.scss";
import "../../../assets/styles/header.scss";
import "../../../assets/styles/auth-flow/style.scss";
import "../../../assets/styles/svg-icons.styles.scss";
import "../../../common/components/loading/style.scss";
import "../../../common/components/modals/style.scss";
import "../../../common/components/inputs/input_text/style.scss";

const AppStylesWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
    return <>{children}</>;
};

export default AppStylesWrapper;

