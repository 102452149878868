import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { GenericBubble } from "../../../../components/Bubble";
import { SingleSelectDropdown } from "../../../../components/SelectDropdown/SingleSelectDropdown";
import { setViewportHeight } from "../../../../../hooks/use-init-viewport-height/useInitViewportHeight";
import { AppModal } from "../../../../components/modals/AppModal";
import { useOnboarding } from "../../../../core/providers/onboarding.provider";
import "./sort-by-style.scss";
import { OffersTablePreferences } from "../../../../../sparrow-ui-library/src/types/sparrow-types";

interface SortByProps {
    setSecondaryRank: Function;
    secondaryRank: OffersTablePreferences;
    primaryRank: OffersTablePreferences;
}

export const SortBy: React.FunctionComponent<SortByProps> = observer(
    
    ({ setSecondaryRank, secondaryRank, primaryRank }) => {
        const { formData, setShowMobileFilter, hasActiveFilters } = useOnboarding();

        const [isOpen, setIsOpen] = useState(false);
        const isPSL = formData.type === "PSL";

        const [checkboxMapping, setCheckboxMapping] = useState(getSecondaryRankMapping(isPSL));

        useEffect(() => {
            setCheckboxMapping(getSecondaryRankMapping(isPSL));
        }, [primaryRank]);

        function getSecondaryRankMapping(isPSL: boolean) {
            const mapping: { [key: string]: boolean } = {};

            if (primaryRank !== OffersTablePreferences.LOWEST_APR) {
                mapping["Lowest APR"] = false;
            }

            if (primaryRank !== OffersTablePreferences.LOWEST_TOTAL_COST) {
                mapping["Lowest total cost"] = false;
            }

            if (primaryRank !== OffersTablePreferences.SHORTEST_TERM) {
                mapping["Shortest term"] = false;
            }

            if (primaryRank !== OffersTablePreferences.LOWEST_MONTHLY_PAYMENT) {
                mapping[isPSL ? "Lowest after school payments" : "Lowest monthly payments"] = false;
            }

            if (
                formData.type === "PSL" &&
                primaryRank !== OffersTablePreferences.LOWEST_IN_SCHOOL
            ) {
                mapping["Lowest in school payments"] = false;
            }

            return mapping;
        }

        useEffect(() => {
            for (const option in checkboxMapping) {
                if (checkboxMapping[option]) {
                    setSecondaryRank(secondaryRankOptions[option]);
                }
            }
        }, [checkboxMapping]);

        const textRef = useRef<HTMLDivElement>(null);
        const dropDownRef = useRef<HTMLDivElement>(null);

        const isMore1380 = useMediaQuery({
            query: "(min-width: 1380px)",
        });

        const isLess767 = useMediaQuery({
            query: "(max-width: 767px)",
        });

        const sortModalDropdownHandler = (open: boolean) => {
            setIsOpen(open);
        };

        const secondaryRankOptions =
            formData.type === "PSL"
                ? {
                      "Lowest APR": "lowestAPR",
                      "Lowest total cost": "lowestTotalCost",
                      "Shortest term": "shortestTerm",
                      "Lowest in school payments": "lowestInSchool",
                      "Lowest after school payments": "lowestMonthlyPayment",
                  }
                : {
                      "Lowest APR": "lowestAPR",
                      "Lowest total cost": "lowestTotalCost",
                      "Shortest term": "shortestTerm",
                      "Lowest monthly payments": "lowestMonthlyPayment",
                  };

        function getTextBasedOnQuizAnswer(answer: OffersTablePreferences) {
            switch (answer) {
                case OffersTablePreferences.LOWEST_TOTAL_COST:
                    return {
                        title: "Save as much as possible (pre-qualified rates with the lowest total cost)",
                        sortBy: "Lowest total cost",
                    };
                case OffersTablePreferences.SHORTEST_TERM:
                    return {
                        title: "Get out of debt ASAP (pre-qualified rates with the shortest terms)",
                        sortBy: "Shortest term",
                    };
                case OffersTablePreferences.LOWEST_IN_SCHOOL:
                    return {
                        title: "Keep in school payments low (pre-qualified rates with the lowest monthly in school payments)",
                        sortBy: "Lowest in school payments",
                    };
                case OffersTablePreferences.LOWEST_MONTHLY_PAYMENT:
                    return {
                        title: `Keep ${
                            isPSL ? "after school payments" : "monthly payments"
                        } low (Rates with lowest monthly ${isPSL ? "after school " : ""}payments)`,
                        sortBy: isPSL ? "Lowest after school payments" : "Lowest monthly payments",
                    };
                case OffersTablePreferences.LOWEST_APR:
                    return {
                        title: "N/A",
                        sortBy: "Lowest APR",
                    };
                default:
                    return {
                        title: "",
                        sortBy: "",
                    };
            }
        }

        return (
            <>
                <div className={"for-you-filter-block"}>
                    <div
                        ref={textRef}
                        className={"for-you-sort-button-container " + (isOpen ? "active" : "")}
                        onClick={() => sortModalDropdownHandler(!isOpen)}
                    >
                        <div className="for-you-sort-by-text">Sort by:</div>
                        <div className="for-you-sort-by-option">
                            <div>{getTextBasedOnQuizAnswer(secondaryRank).sortBy}</div>
                            {
                                <div
                                    className="bubble-wrapper-filter"
                                    ref={dropDownRef}
                                    style={{
                                        left: isMore1380
                                            ? textRef.current?.getBoundingClientRect().left
                                                ? textRef.current?.getBoundingClientRect().left - 80
                                                : 40
                                            : textRef.current?.getBoundingClientRect().left ?? 40,
                                        marginTop: "40px",
                                    }}
                                >
                                    {isOpen && (
                                        <>
                                            {!isLess767 && (
                                                <GenericBubble>
                                                    <SingleSelectDropdown
                                                        title={"Sort by"}
                                                        onExit={setIsOpen}
                                                        checkboxMapping={checkboxMapping}
                                                        setCheckboxMapping={setCheckboxMapping}
                                                    />
                                                </GenericBubble>
                                            )}
                                        </>
                                    )}
                                </div>
                            }
                        </div>
                        <span className="icon-arrow-bottom" />
                    </div>
                    <button
                        className={`trigger-filter ${hasActiveFilters ? "active" : ""} ${formData.userType}`}
                        onClick={() => {
                            setViewportHeight();
                            setShowMobileFilter(true);
                        }}
                    >
                        <span className="icon-filter" />
                    </button>
                </div>
                {isOpen && isLess767 && (
                    <AppModal
                        onClose={() => setIsOpen(false)}
                        showCloseIcon={true}
                        enableMobileView={true}
                        title={"Sort by"}
                        noPadding={true}
                        customClass={"modal-sort"}
                    >
                        <div className="bubble-wrapper-sort" ref={dropDownRef}>
                            <GenericBubble>
                                <SingleSelectDropdown
                                    title={"Sort by"}
                                    onExit={setIsOpen}
                                    checkboxMapping={checkboxMapping}
                                    setCheckboxMapping={setCheckboxMapping}
                                    isMobile={true}
                                />
                            </GenericBubble>
                        </div>
                        <button className={"btn btn-green"} onClick={() => setIsOpen(false)}>
                            Apply <span className="icon-check" />
                        </button>
                    </AppModal>
                )}
            </>
        );
    }
);
