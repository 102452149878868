import {
    DependentFormFieldProps,
    FormProps,
    Forms,
    RadioButtonFormProps,
} from "../../../types/form-types";
import { FormContainer } from "..";
import { useSparrowUIFormsContext } from "../../../context";
import { RadioButtonsContainer } from "..";
import "./form-wrapper.css";

interface MultiFormWrapperProps {
    forms: Forms;
    setForms: React.Dispatch<React.SetStateAction<Forms>>;
    dependentFields?: DependentFormFieldProps[];
    // Provides an instance of the forms array with the default values for each form. Used to reset the forms array to its default state.
    getNewFormsInstance?: () => RadioButtonFormProps[];
}

/* FormWrapper is a wrapper component that renders a FormContainer for each form in the forms array. Typically there will be only one form in the array,
but this component is designed to also support multiple forms.*/
const FormWrapper: React.FC<MultiFormWrapperProps> = ({ forms, setForms, getNewFormsInstance }) => {
    const { showMailingAddress } = useSparrowUIFormsContext();

    return (
        <div className="MainFormWrapper">
            {!!forms.length &&
                forms.map((form: FormProps | RadioButtonFormProps, index: number) => {
                    // If the form is a mailing address form and showMailingAddress is false, don't render the form.
                    if (form.formType?.includes("mailingAddress") && !showMailingAddress) {
                        return null;
                    }

                    if ((form as RadioButtonFormProps).formType?.includes("radioButtons")) {
                        return (
                            <RadioButtonsContainer
                            key={`rb-form-${index}`}
                            form={form as RadioButtonFormProps}
                            formRadioButtons={(form as RadioButtonFormProps).radioButtonOptions}
                            forms={forms as RadioButtonFormProps[]}
                            setForms={setForms as React.Dispatch<React.SetStateAction<RadioButtonFormProps[]>>}
                            formIndex={index}
                            getNewFormsInstance={getNewFormsInstance as () => RadioButtonFormProps[]}
                            />
                        );
                    }

                    return (
                        <FormContainer
                            key={`form-${index}`}
                            formInputs={form as FormProps}
                            forms={forms as FormProps[]}
                            setForms={setForms as React.Dispatch<React.SetStateAction<FormProps[]>>}
                            formIndex={index}
                        />
                    );
                })}
        </div>
    );
};

export default FormWrapper;
