import { useState } from "react";
import { LottieIcon } from "../../../../common/components/LottieIcon/LottieIcon";
import { isMarketplaceEnv } from "../../../../common/utils/config";
import "./styles.scss";

export const MenuItem = ({ id, title, loanType, animationData, clickHandler, icon }) => {
    const [play, setPlay] = useState(false);

    return (
        <button
            id={id}
            className="menu-item"
            onMouseEnter={() => setPlay(true)}
            onMouseLeave={() => setPlay(false)}
            onClick={() => clickHandler(loanType, title)}
        >
            <div className="content-container">
                <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
                    {isMarketplaceEnv() ?
                    <LottieIcon
                        options={{ height: 40, width: 40, autoplay: false, play: play }}
                        animationData={animationData}
                    /> :
                    icon()}
                    <span>{title}</span>
                </div>
                <span className="icon-arrow-right" />
            </div>
        </button>
    );
};
