import { useMemo } from "react";
import { observer } from "mobx-react";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { useStore } from "../../core/providers/global.provider";
import leftArrowIcon from "../../../assets/images/icons/arrowLeft.svg";

import { useNavigate } from "react-router-dom";
import { dashboardUrl } from "../../core/routes/navigation-urls";
import { GlobalStore } from "../../store/global";
import { UserType } from "../../constants/defaultInputs";

const getHeaderText = (offersCount: number, store: GlobalStore, userType: UserType) => {
    const userName = store.getUserName();

    const headerTitle = (
        <p className={"header-label-title"}>
            {offersCount ? (
                <>
                    {userName?.name.split(" ")[0]}, you pre-qualified for{" "}
                    <span className={`header-label-number-of-rates ${userType}`}>{offersCount} rates!</span>
                </>
            ) : (
                <>{userName?.name.split(" ")[0]}, we were unable to pre-qualify you</>
            )}
        </p>
    );

    const headerSubtitle = (
        <p className="header-label-subtitle">
            {offersCount
                ? 'Once you have found the best pre-qualified rate for you, click "Apply" and we\'ll take you to the right place.'
                : "You can review why we were unable to find you any pre-qualified rates at this time and review your options below."}
        </p>
    );

    return {
        headerTitle,
        headerSubtitle,
    };
};

export const PqDecisionsTextHeader = observer((props) => {
    const onboarding = useOnboarding();
    const store = useStore();
    const { currentUser } = store;
    const offersCount = onboarding.pqRequestResults?.offerInformation?.offerMetadata?.offersCount;
    const headerText = useMemo(
        () => getHeaderText(offersCount as number, store, onboarding.formData.userType as UserType),
        [offersCount, currentUser]
    );

    const navigate = useNavigate();

    function handleBack() {
        return navigate(dashboardUrl);
    }

    return (
        <>
            <div className="header-label-container">
                <div className="header-label-container-title">
                    <button className="back-dashboard-header">
                        <img alt="" src={leftArrowIcon} onClick={() => handleBack()} />
                    </button>
                </div>
                <div className="header-label-title-subtitle-container">
                    <div className={"header-label-title-container"}>
                        {headerText.headerTitle}
                    </div>
                </div>
            </div>
            <div className="header-label-container">
                <div className="header-label-title-subtitle-container">
                    {headerText.headerSubtitle}
                </div>
            </div>
        </>
    );
});
