import { userInclusionSelectUrl, userInclusionInformationUrl, userInclusionManagementUrl, userInclusionReviewInviteUrl } from "../../../common/core/routes/navigation-urls";
import { enumUserInclusionType } from "../../../common/store/onboarding";
import { useOnboarding } from "../../../common/core/providers/onboarding.provider";
import { getStatusBasedText } from "./helper-functions";
import { IncludedUser, UserType } from "../../../sparrow-ui-library/src/types/sparrow-types";


export const useGetPageText = (pathName: string) => {
const {getOppositeUserType, userInclusionType, formData} = useOnboarding();
const includedUser = getOppositeUserType();
const creatorsName = formData.creator?.firstName as string;
const isDirectInclusion = userInclusionType === enumUserInclusionType.DIRECT;
// These variables are only used in the management page, whereby the included user is already confirmed, therefore we can destructure the included user from the formData
const {firstName, userType, status} = formData.includedUser as IncludedUser ?? {};
const statusBasedText = getStatusBasedText({invitedUserName: firstName, invitedUserType: userType, status});

const pageText = {
    [userInclusionSelectUrl]: {
        title: `How do you want to include your ${includedUser}?`, 
        subtitle: `If you have your ${includedUser}'s permission, signing them up now is the fastest way to see your pre-qualified rates. Otherwise, you can invite your ${includedUser} to include their information.`,
    },
    [userInclusionInformationUrl]: {
        title: `${isDirectInclusion ? `Who is your ${includedUser}` : "Who should we invite"}`, 
        subtitle: `${isDirectInclusion ? `We use this information to create an account for your ${includedUser} so they can check their rates too.` : `We need your ${includedUser}'s contact information in order to invite them to join your request.`}`,
    },
    [userInclusionManagementUrl]: {
        title: statusBasedText.title,
        subtitle: statusBasedText.subTitle,
    },
    [userInclusionReviewInviteUrl]: {
        title: `Review ${creatorsName}'s pre-qualification request`,
        subtitle: `${creatorsName} has invited you to be the ${userType === UserType.BORROWER ? "primary borrower" : "cosigner"} on their request for pre-qualified rates. Once you complete your portion of the form, {companyName} will show you and ${creatorsName} your pre-qualified rates.`,
    }
}
    return pageText[pathName];
}
