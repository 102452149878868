export enum FormFieldName {
    AcademicPeriod = "highestDegree.academicPeriod",
    AddressLine1 = "addressLine1",
    AddressLine2 = "addressLine2",
    CitizenshipStatus = "citizenshipStatus",
    CitizenshipStatusHasSSN = "citizenshipStatus.hasSSN",
    CitizenshipStatusHomeCountry = "citizenshipStatus.homeCountry",
    CitizenshipStatusType = "citizenshipStatus.type",
    CitizenshipStatusVisaType = "citizenshipStatus.visaType",
    City = "city",
    CountryCode = "countryCode",
    DegreeType = "highestDegree.degreeType",
    DOB = "dob",
    ECommunicationAllowed = "eCommunicationAllowed",
    Email = "email",
    EmployerName = "employment.employer.name",
    EmploymentStatus = "employment.employmentStatus",
    EmploymentType = "employment.employmentType",
    FirstName = "firstName",
    GradDate = "highestDegree.gradDate",
    EmploymentIncome = "employment.income",
    EmploymentIncomeType = "employment.incomeType",
    IncomeAmount = "income.amount.amount",
    IncomeType = "income.type",
    IsSameAsPhoneNumber = "phoneNumber.isSameAsWhatsApp",
    LastName = "lastName",
    LenderPrivacyPolicyAllowed = "lenderPrivacyPolicyAllowed",
    LoanAmount = "loanAmount.amount",
    MailingAddress = "mailingAddress",
    MailingAddressAddressLine1 = "mailingAddress.addressLine1",
    MailingAddressAddressLine2 = "mailingAddress.addressLine2",
    MailingAddressCity = "mailingAddress.city",
    MailingAddressCountry = "mailingAddress.country",
    MailingAddressIsSame = "mailingAddressIsSame",
    MailingAddressState = "mailingAddress.state",
    MailingAddressZipCode = "mailingAddress.zip",
    Major = "highestDegree.major",
    MiddleInitial = "middleInitial",
    EmploymentOccupation = "employment.occupation",
    PermanentAddress = "permanentAddress",
    PermanentAddressAddressLine1 = "permanentAddress.addressLine1",
    PermanentAddressAddressLine2 = "permanentAddress.addressLine2",
    PermanentAddressCity = "permanentAddress.city",
    PermanentAddressCountry = "permanentAddress.country",
    PermanentAddressState = "permanentAddress.state",
    PermanentAddressZipCode = "permanentAddress.zip",
    PhoneNumber= "phoneNumber",
    PhoneNumberCountryCode = "phoneNumber.countryCode",
    PhoneNumberCountryPrefix = "phoneNumber.countryPrefix",
    PhoneNumberGlobalType = "phoneNumber.globalType",
    PhoneNumberIsSameASWhatsApp = "phoneNumber.isSameAsWhatsApp",
    PhoneNumberMobileCommunicationAllowed = "phoneNumber.mobileCommunicationAllowed",
    PhoneNumberTimeZone = "phoneNumber.timeZone",
    PhoneNumberType = "phoneNumber.type",
    School = "highestDegree.university",
    SSN = "ssn",
    State = "state",
    Suffix = "suffix",
    WhatsAppNumber = "phoneNumber.whatsAppNumber",
    YearInSchool = "highestDegree.yearInSchool",
    Zip = "zip",
    HousingPaymentAmount = "housing.payment.amount",
    HousingType = "housing.type",
    DegreesDegreeType = "degrees.degreeType",
    DegreesMajor = "degrees.major",
    DegreesUniversity = "degrees.university",
    DegreesGradDate = "degrees.gradDate",
    Enrollment = "enrollment",
    GenericToggle = "genericToggle",
    ExpandedSearchGpaCumulative = "gpaCumulative",
    ExpandedSearchGpaLastSemester = "gpaLastSemester",
    ExpandedSearchHoursCompletedCurrentSemester = "hoursCompletedCurrentSemester",
    ExpandedSearchSchoolYearStartDate = "schoolYearStartDate",
    ExpandedSearchSchoolYearEndDate = "schoolYearEndDate",
    PAAASMembershipElection = "membershipElection",
    
}

