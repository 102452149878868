import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { getFormsConfig } from "./forms-config";
import { FormWrapper } from "../../../sparrow-ui-library/src/ui-components/form-components";
import { handleSubmission } from "../../../sparrow-ui-library/src/utils/ui-library-utils";
import { useOnboarding } from "../../../common/core/providers/onboarding.provider";
import { PrequalUrlsEnum } from "../../../common/core/global_routing/front-end-urls";
import { useSparrowUIFormsContext, useSparrowUIContext } from "../../../sparrow-ui-library/src/context";
import { PrequalificationRequestInput } from "../../../sparrow-ui-library/src/types/sparrow-types";
import { NavigationFooter } from "../components/NavigationFooter";
import { UserType } from "../../../sparrow-ui-library/src/types/sparrow-types";

export const PqDegrees = observer(({ pathName }: { pathName: string }) => {
    const onboarding = useOnboarding();
    const [componentMounted, setComponentMounted] = useState(false);
    // Store variables and methods
    const { displayToastPopup } = useSparrowUIContext();
    const { applicableFormData } = onboarding.getApplicableFormData();

    const { forms, setForms } = useSparrowUIFormsContext();

    useEffect(() => {
        // Need to clear out the prior forms before running the rest of the code, otherwise radioButtons may be undefined
        if (!componentMounted) {
            if (onboarding.asyncDropdownValues) setComponentMounted(true);
            return setForms([]);
        }

        const formsConfig = getFormsConfig({
            applicableFormData: applicableFormData as PrequalificationRequestInput,
            getApplicableUserText: onboarding.getApplicableUserText,
            asyncDropdownValues: onboarding.asyncDropdownValues,
        });

        setForms(formsConfig.forms);
    }, [componentMounted]);

    // Page routing
    const navigate = useNavigate();
    const { prevStep, nextStep } = onboarding.getStepRouting(pathName);

    // Submit handler
    const handleSubmit = () =>
        handleSubmission({ forms, setForms, checkEditsMadePostPrequalSubmission: onboarding.checkEditsMadePostPrequalSubmission }, async (formattedBackendForms) => {
            //Take formatted formData and send to backend
            try {
                const response = await onboarding.prequalRequestHandler({
                    formattedBackendForms,
                    pathName: PrequalUrlsEnum.DEGREES,
                });
                if (!response) return;
                // If successful, navigate to next step
                navigate(nextStep);
            } catch (err: any) {
                onboarding.setPqLoader(false);
                displayToastPopup(err.userDescription as string, true);
            }
        });

    return (
        <>
            {!!forms.length && <FormWrapper forms={forms} setForms={setForms} />}
            <div style={{ marginTop: "32px" }}>
                <div style={{ marginTop: "32px" }}>
                    <NavigationFooter
                        userType={onboarding.formData.userType as UserType}
                        steps={{ prevStep, nextStep }}
                        handleSubmit={handleSubmit}
                    />
                </div>
            </div>
        </>
    );
});
