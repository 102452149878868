import { FC } from "react";
import { TipsContent } from "../tips/tips-content";
import { PageText } from "../../types/content-layout-props";
import { Tip } from "../../types/tip";
import styles from "./styles.module.scss";

interface LeftSideContentProps {
    pageText: PageText;
    tips?: Tip[];
}

export const LeftSideContent:FC<LeftSideContentProps> = (props) => {
    const{ pageText, tips } = props;

    return (
        <>
        {!!tips?.length && <TipsContent tips={tips} />}
            {!!pageText.leftTitleText && (
                <div className={styles.nonTipsContent}>
                    {!!pageText.leftSubText && (
                        <div className={styles.leftSubText}>{pageText.leftSubText}</div>
                    )}
                    <div className={styles.leftTitleText}>{pageText.leftTitleText}</div>
                </div>
            )}
        </>
    );
};


