export const UndergradLoanIcon = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8 19.75C8 18.6454 8.89543 17.75 10 17.75H30C31.1046 17.75 32 18.6454 32 19.75V25.9542C32 26.7535 31.5242 27.4759 30.79 27.7916L22.0386 31.5544C20.7851 32.0934 19.3657 32.0966 18.1097 31.5634L9.21842 27.7886C8.47971 27.475 8 26.7502 8 25.9477V19.75Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <path
                d="M3.42468 18.0954C1.89165 17.3754 1.89165 15.1949 3.42467 14.4749L17.7353 7.75359C19.3511 6.9947 21.2209 6.9947 22.8367 7.75359L37.1474 14.4749C38.6804 15.1949 38.6804 17.3754 37.1474 18.0954L22.8367 24.8167C21.2209 25.5756 19.3511 25.5756 17.7353 24.8167L3.42468 18.0954Z"
                fill="rgba(var(--borrowerPrimaryColor),1)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32 20.5122L22.835 24.8168C21.2192 25.5757 19.3494 25.5757 17.7336 24.8168L8 20.2452V18.75C8 17.6455 8.89543 16.7501 9.99999 16.7501L30 16.75C31.1046 16.75 32 17.6454 32 18.75V20.5122Z"
                fill="rgba(var(--borrowerPrimaryColor),1)"
            />
            <path
                d="M37 21V31"
                stroke="rgba(var(--borrowerAccentColor),1)"
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    );
};
