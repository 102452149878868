import { OfferDetail } from "../../OfferCardUtilities";
import { OfferCardBodyTooltip } from "../../body/OfferCardBodyTooltip";

import "../../style.scss";

interface DetailsProps {
    offerDetails: OfferDetail[];
}

export const OfferCardHeaderDetailsForMobile: React.FunctionComponent<DetailsProps> = ({
    offerDetails,
}) => {
    const firstTwoDetails = offerDetails.slice(0, 3);
    return (
        <>
            {firstTwoDetails.map((detail) => (
                <div className="offer-card-detail" key={detail.title}>
                    <div className="offer-card-detail-title-container">
                        <div className="offer-card-detail-title">{detail.title}</div>
                        {detail.tooltip && detail.title && (
                            <OfferCardBodyTooltip content={detail.tooltip} id={detail.title} />
                        )}
                    </div>
                    <div className="offer-card-detail-dots"></div>
                    <div className="offer-card-detail-value-container">
                        <div className="offer-card-detail-extra-text">
                            {detail.additionalValueText}
                        </div>
                        <div className="offer-card-detail-value">{detail.value}</div>
                    </div>
                </div>
            ))}
        </>
    );
};
