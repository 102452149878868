"use client";
import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

import "./style.scss";
import { GenericBubble } from "../Bubble";
import { MultiSelectDropdown } from "../SelectDropdown/MultiSelectDropdown";
import { SingleSelectDropdown } from "../SelectDropdown/SingleSelectDropdown";
import {
    OfferFilterTabs,
    getHeaderTooltipsForFilterDropdown,
    getOptionTooltipsForFilterDropdown,
} from "./utilities";
import ReactDOM from "react-dom";

interface OfferFilterDropdownTabProps {
    isMultiSelect: boolean;
    getAdditionalOnChangeFunction: Function;
    closeAllFilters: Function;
    setCheckboxMapping: Function;
    checkboxMapping: {};
    config: {
        name: OfferFilterTabs;
        defaultIcon: any;
        activeIcon: any;
    };
    xPos: number;
    maxXPos: number;
    isOpen: boolean;
    positionRef: any;
    isMobile?: boolean;
    icon?: string | null;
}

export const OfferFilterDropdownTab: React.FunctionComponent<OfferFilterDropdownTabProps> = ({
    isMultiSelect,
    getAdditionalOnChangeFunction,
    closeAllFilters,
    setCheckboxMapping,
    checkboxMapping,
    config,
    xPos,
    maxXPos,
    isOpen,
    positionRef,
    isMobile = false,
    icon = null,
}) => {
    const isMore1024px = useMediaQuery({
        query: "(min-width: 1024px)",
    });

    const filterDropdownContainer: any = document.getElementById("filter-dropdown-container");

    const [startPos, setStartPos] = useState<number>(
        Math.min(maxXPos - 240 + (isMore1024px ? 240 : 0), xPos)
    );

    const handleResize = () => {
        setStartPos(Math.min(startPos, window.innerWidth - 240 - (isMore1024px ? 240 : 0)));
    };
    useEffect(() => {
        window.addEventListener("resize", handleResize, false);
    }, []);

    const dropDownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (isOpen) {
                if (
                    dropDownRef.current &&
                    !dropDownRef.current.contains(event.target as Node) &&
                    !positionRef.current.contains(event.target as Node)
                ) {
                    closeAllFilters();
                }
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropDownRef, isOpen]);

    return (
        <>
            {isMobile ? (
                <>
                    <div className="mobile-filters-items">
                        {isMultiSelect ? (
                            <GenericBubble>
                                <MultiSelectDropdown
                                    title={config.name}
                                    onExit={closeAllFilters}
                                    checkboxMapping={checkboxMapping}
                                    setCheckboxMapping={setCheckboxMapping}
                                    onChangeExtraProcessing={getAdditionalOnChangeFunction(
                                        config.name
                                    )}
                                    headerTooltipContent={getHeaderTooltipsForFilterDropdown(
                                        config.name
                                    )}
                                    optionTooltipContent={getOptionTooltipsForFilterDropdown(
                                        config.name
                                    )}
                                    isMobile={true}
                                    icon={icon}
                                />
                            </GenericBubble>
                        ) : (
                            <GenericBubble>
                                <SingleSelectDropdown
                                    title={config.name}
                                    onExit={closeAllFilters}
                                    checkboxMapping={checkboxMapping}
                                    setCheckboxMapping={setCheckboxMapping}
                                    onChangeExtraProcessing={getAdditionalOnChangeFunction(
                                        config.name
                                    )}
                                    headerTooltipContent={getHeaderTooltipsForFilterDropdown(
                                        config.name
                                    )}
                                    optionTooltipContent={getOptionTooltipsForFilterDropdown(
                                        config.name
                                    )}
                                    isMobile={true}
                                    icon={icon}
                                />
                            </GenericBubble>
                        )}
                    </div>
                </>
            ) : (
                <>
                    {ReactDOM.createPortal(
                        <div
                            className="bubble-wrapper-filter"
                            style={{ left: startPos }}
                            ref={dropDownRef}
                        >
                            {isMultiSelect ? (
                                <GenericBubble>
                                    <MultiSelectDropdown
                                        title={config.name}
                                        onExit={closeAllFilters}
                                        checkboxMapping={checkboxMapping}
                                        setCheckboxMapping={setCheckboxMapping}
                                        onChangeExtraProcessing={getAdditionalOnChangeFunction(
                                            config.name
                                        )}
                                        headerTooltipContent={getHeaderTooltipsForFilterDropdown(
                                            config.name
                                        )}
                                        optionTooltipContent={getOptionTooltipsForFilterDropdown(
                                            config.name
                                        )}
                                    />
                                </GenericBubble>
                            ) : (
                                <GenericBubble>
                                    <SingleSelectDropdown
                                        title={config.name}
                                        onExit={closeAllFilters}
                                        checkboxMapping={checkboxMapping}
                                        setCheckboxMapping={setCheckboxMapping}
                                        onChangeExtraProcessing={getAdditionalOnChangeFunction(
                                            config.name
                                        )}
                                        headerTooltipContent={getHeaderTooltipsForFilterDropdown(
                                            config.name
                                        )}
                                        optionTooltipContent={getOptionTooltipsForFilterDropdown(
                                            config.name
                                        )}
                                    />
                                </GenericBubble>
                            )}
                        </div>,
                        filterDropdownContainer
                    )}
                </>
            )}
        </>
    );
};
