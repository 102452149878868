import { PrequalificationRequestInput } from "../../sparrow-ui-library/src/types/sparrow-types";

const undergradPrograms = [
    "Bachelors Degree",
    "Undecided",
    "Other Bachelor",
    "Bachelor of Arts (B.A.)",
    "Bachelor of Science (B.S.)",
    "Bachelor of Fine Arts (B.F.A.)",
    "Bachelor of Applied Science (B.A.S.)",
];

export function getWordPressTableNameFromFormData(formData: PrequalificationRequestInput) {
    const hasSSN = formData.ssn && formData.ssn.length > 0;
    if (formData.type === "SLR") {
        if (!hasSSN) {
            // "International Refinance"
            return "4638";
        }

        // "Refinance Student Debt"
        return "4616";
    }

    if (!hasSSN) {
        // International Student Loan
        return "4637";
    }

    if (!undergradPrograms.includes(formData.degrees[0]?.degreeType)) {
        // "Graduate Loan"
        return "4611";
    }

    return "4588";
}
