import { useEffect, useState } from "react";

export const getUserDashHeaderHeight = () => {
    const header = document.querySelector(".user-dash-header");
    const headerHeight = header?.getBoundingClientRect().height ?? 0;
    const margin = Number(window.getComputedStyle(header!).getPropertyValue("margin-bottom").replace("px", ""));
    return headerHeight + margin ?? 0;
};

const getTotalMainSectionWrapperHeight = () => {
    const userDashHeaderHeight = getUserDashHeaderHeight();
    const requestContainerHeight = document.querySelector(".request-container")?.getBoundingClientRect().height ?? 0;
    const stepPopupHeight = document.querySelector(".rm-popup-content-container")?.getBoundingClientRect().height ?? 0;
    // Popup is 20px higher from the bottom of the request container and 40px represents the "padding-bottom" 
    return userDashHeaderHeight + requestContainerHeight + stepPopupHeight + 20 + 40;
}

const setNavWrapperHeight = () => {
    const totalMainSectionWrapperHeight = getTotalMainSectionWrapperHeight();
    document.documentElement.style.setProperty("--main-section-wrapper-height", `${totalMainSectionWrapperHeight}px`);    
}
const useInitMainWrapperHeight = (popupIsPresent: boolean) => {
    
    const [initialized, setInitalized] = useState(false);
    
    useEffect(() => {
      // We adjust the height of the wrapper once the popup is mounted. The popup is mounted after the completion of an async request, which is why we need to ensure that it is mounted before we adjust the height of the wrapper. 
      if(popupIsPresent && !initialized){
          setNavWrapperHeight();
          setInitalized(true);
      }    

      // Define the handler function for resizing
      const handleResize = () => {
        setNavWrapperHeight();
      };
  
      // Attach the resize event listener
      window.addEventListener("resize", handleResize);
  
      // Cleanup: Remove the event listener when the component unmounts
      return () => {
        window.removeEventListener("resize", handleResize);
      };
      
    }, [popupIsPresent]);
  };

  export default useInitMainWrapperHeight;
  