import { FC, useRef } from "react";

import { observer } from "mobx-react";
import { useLocation } from "react-router-dom";

import { useOnboarding } from "../../core/providers/onboarding.provider";
import { isMarketplaceEnv } from "../../utils/config";
import { AdvertiserDisclosureButton } from "../PAAASHeaderBar/AdvertiserDisclosure";
import { HeaderButton } from "../PAAASHeaderBar/HeaderButton";
import { PAAASLogo } from "../PAAASLogo";
import { MobileNavTrigger } from "./components/MobileNavTrigger";

import { SparrowIcon } from "../../../sparrow-ui-library/src/assets/icons/sparrow-icon/SparrowIcon";
import { getRouteForPAAASLogo } from "../../utils/routing";
import "./style.scss";

const MobileNavigationHeader: FC<{setShowSideBarHandlerForMobile?: (state: boolean) => void}> = observer(({setShowSideBarHandlerForMobile}) => {
    const { hasCompare, showCompareComponent, setShowCompareComponent, paaasCustomization } = useOnboarding();
    const head = useRef(null);
    const pathname = useLocation().pathname;

    const { paaasCustomHeader } = useOnboarding();

    return (
        <div className={`mobile-navigation-header`} ref={head}>
            <div className="mobile-navigation-header__row">
                <div className="mobile-navigation-header__row-left">
                    <div className="mobile-navigation-header__col">
                        <MobileNavTrigger {...{ setShowSideBarHandlerForMobile }} />
                    </div>
                    <div className="mobile-navigation-header__col">
                        {isMarketplaceEnv() ? (
                            <a href="/">
                                <SparrowIcon />
                            </a>
                        ) : (
                            <PAAASLogo
                                navigateTo={getRouteForPAAASLogo({
                                    pathname,
                                    displayLandingPageView: paaasCustomization?.toggleableViews?.displayLandingPageView,
                                })}
                            />
                        )}
                    </div>
                </div>
                <div className="mobile-navigation-header__row-right">
                    {!isMarketplaceEnv() && (
                        <div className={"sign-top-button-group"}>
                            {!paaasCustomHeader?.disableAdvertiserDisclosure && (
                                <div
                                    className={
                                        "mobile-navigation-header-advertiser-disclosure-button"
                                    }
                                >
                                    <AdvertiserDisclosureButton isLeftAligned={true} />
                                </div>
                            )}

                            {paaasCustomHeader?.button?.isEnableCustomButton && (
                                <div className={"mobile-navigation-header-custom-button"}>
                                    <HeaderButton button={paaasCustomHeader.button} />
                                </div>
                            )}
                        </div>
                    )}
                    {hasCompare && pathname.includes("rates") && (
                        <button
                            className={`button-trigger  button-trigger-compare active ${
                                showCompareComponent ? "open" : ""
                            }`}
                            onClick={() => {
                                setShowCompareComponent(!showCompareComponent);
                            }}
                        >
                            {showCompareComponent ? (
                                <span className="icon-close" />
                            ) : (
                                <span className="icon-compare" />
                            )}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
});

export default MobileNavigationHeader;
