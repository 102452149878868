"use client";
import { FC, PropsWithChildren, useEffect, useState } from "react";
import { UserType } from "../../constants/defaultInputs";
import { RoundCheckBox } from "./RoundCheckBox/RoundCheckBox";
import { LottieIcon } from "../LottieIcon/LottieIcon";
import { useMediaQuery } from "react-responsive";
import "./styles.scss";
import { isMarketplaceEnv } from "../../utils/config";

interface IRadioButtons {
    userType?: UserType;
    options: {
        id: string;
        name: string;
        isChecked: boolean;
        value: { title: string; subTitle?: string };
        icon?: any;
        activeIcon?: any;
        isDefault?: boolean;
        paaasSvg?:() => JSX.Element
    }[];
    setForm: (value: any) => void;
    hasSubtitles?: boolean;
    customCheckBorderRadius?: number;
}

export const RadioButtons: FC<PropsWithChildren<IRadioButtons>> = ({
    userType,
    options,
    setForm,
    children,
}) => {
    const [activeRadioButtons, setActiveRadioButtons] = useState(options);
    const isMobile = useMediaQuery({ query: "(max-width: 560px)" });

    useEffect(() => {
        const newOptions = options.map((item) => {
            item.isChecked = !!activeRadioButtons.find((currentItem) => currentItem.id === item.id)
                ?.isChecked;
            return item;
        });
        setActiveRadioButtons(newOptions);
    }, [options]);

    const onRadioChange = (e) => {
        setForm(e.target.name);

        const newOptions = options.map((item) => {
            item.isChecked = item.name === e.target.name ? true : false;
            return item;
        });
        setActiveRadioButtons(newOptions);
    };

    //NOTE: because userType may be undefined, we'll always default to the borrower theme (sparrow green)
    const includeBorder =
        userType === UserType.COSIGNER ? "isActive-cosigner" : "isActive-borrower";

    return (
        <>
            {activeRadioButtons.map((item) => {
                return (
                    <div
                        key={item.id}
                        className={`radio-button ${item.isChecked ? includeBorder : ""}`}
                    >
                        <div className="radio-button-content">
                                {!isMobile ? (
                                    <>
                                        {item.activeIcon && (
                                            <div>
                                                {(item.isChecked && isMarketplaceEnv()) && (
                                                    <LottieIcon
                                                        options={{
                                                            height: 40,
                                                            width: 40,
                                                            autoplay: item.isChecked,
                                                        }}
                                                        animationData={item.activeIcon}
                                                    />
                                                )} 
                                                
                                                
                                                    <>
                                                    {!isMarketplaceEnv() && item.icon()}
                                                    </>
                                                
                                            </div>
                                        )}
                                        {item.paaasSvg && item.paaasSvg()}
                                    </>
                                ) : null}
                            <input
                                checked={item.isChecked}
                                id={item.id}
                                name={item.name}
                                type="radio"
                                onChange={onRadioChange}
                                className="radio-button-input"
                            />
                            <label
                                htmlFor={item.id}
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div className="rb-text-box">
                                    <p className="rb-title">{item.value.title}</p>
                                    {item.value.subTitle && (
                                        <p className="rb-subtitle">{item.value.subTitle}</p>
                                    )}
                                    {isMobile && item.isDefault && (
                                        <div className="default-label">
                                            <p>Default</p>
                                        </div>
                                    )}
                                </div>
                                <div style={{ display: "flex", alignItems: "center", gap: "32px" }}>
                                    {!isMobile && item.isDefault && (
                                        <div className="default-label">
                                            <p>Default</p>
                                        </div>
                                    )}
                                    <RoundCheckBox isChecked={item.isChecked} userType={userType} />
                                </div>
                            </label>
                        </div>
                        {children}
                    </div>
                );
            })}
        </>
    );
};
