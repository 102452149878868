import { FC } from "react";
import SlideToggle from "react-slide-toggle";
import './style.scss';
import HtmlRenderer from "../../../../../../common/components/html-renderer";


interface IFaqItem {
    answer: string
    question: string
    collapsed?: boolean
    activeColor?: string | null
}

const FaqItem: FC<IFaqItem> = ({ answer, question, collapsed = true, activeColor = null }) => {
    return <SlideToggle
        collapsed={collapsed}
        bestPerformance={true}
    >

        {({ toggle, setCollapsibleElement, progress }) => (
            <>
                <div className={`faq-collapse`}>
                    {/* @ts-ignore */}
                    <button className={`faq-toggle ${progress && 'active'}`} onClick={toggle} style={{ color: progress && activeColor ? activeColor : null }}>
                        {question}
                        <span className="icon-arrow-bottom" />
                    </button>
                    <div className="faq-collapse-block" ref={setCollapsibleElement}>
                        <HtmlRenderer htmlString={answer} />
                    </div>
                </div>
            </>
        )}
    </SlideToggle>
}
export default FaqItem