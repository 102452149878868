import { Route, Routes } from "react-router-dom";
import {
    borrowerOffersRoutingUrl, borrowerProfileRoutingUrl,
} from "../../common/core/routes/routing-urls";
import { OffersPage } from "./offers/lender-offers/page";
import { NavigationWrapper } from "../../common/components/NavigationWrapper/NavigationWrapper";
import Profile from "./profile";

export const BorrowerRoutes = () => {
    return (
        <>
            <NavigationWrapper>
                <Routes>
                    <Route path={borrowerOffersRoutingUrl} element={<OffersPage />} />
                    <Route path={borrowerProfileRoutingUrl} element={<Profile />} />
                </Routes>
            </NavigationWrapper>
        </>
    );
};
