import {
    FormFieldType,
    FormFieldName,
    FormInputsConfig,
    FormsConfig
} from "../../../sparrow-ui-library/src/types/form-types";
import { PrequalificationRequestInput } from "../../../sparrow-ui-library/src/types/sparrow-types";
import { epochToFormattedDateString } from "../../../sparrow-ui-library/src/utils/general-utils";

export const getFormsConfig = (inputs: FormsConfig): FormInputsConfig => {
    const { applicableFormData, getApplicableUserText, asyncDropdownValues } = inputs as {
        applicableFormData: PrequalificationRequestInput;
        getApplicableUserText: (text: string) => string;
        asyncDropdownValues:  any;
      };
    const degreeObject = applicableFormData?.degrees[0];
    const hasDegreeObject = !!degreeObject;
    // Check to see if the degree object is present and if all fields are filled out (no field has an empty string value)
    // Note gradDate is an epoch number so we account for 0 by checking for empty strings. 
    const hasDegrees = hasDegreeObject && !(Object.keys(degreeObject).filter((key) => degreeObject[key] === "").length)

    return {
        forms: [
            {
                independentFields: [
                    {
                        formFieldType: FormFieldType.InputDropdown,
                        id: FormFieldName.DegreesUniversity,
                        name: FormFieldName.DegreesUniversity,
                        label: "School",
                        placeholder: "Select a school",
                        inputValue: hasDegrees ? applicableFormData.degrees[0].university : "",
                        backendValue: "",
                        errorMessage: "",
                        dropdownOptions: asyncDropdownValues ? asyncDropdownValues.school : [],
                    },
                    {
                        formFieldType: FormFieldType.InputDropdown,
                        id: FormFieldName.DegreesDegreeType,
                        name: FormFieldName.DegreesDegreeType,
                        label: "Degree type",
                        placeholder: "Select a degree type",
                        inputValue: hasDegrees ? applicableFormData.degrees[0].degreeType : "",
                        backendValue: "",
                        errorMessage: "",
                        dropdownOptions: asyncDropdownValues ? asyncDropdownValues.degrees : [],
                    },
                    {
                        formFieldType: FormFieldType.InputDropdown,
                        id: FormFieldName.DegreesMajor,
                        name: FormFieldName.DegreesMajor,
                        label: "Major or concentration",
                        placeholder: "Select a major or concentration",
                        inputValue: hasDegrees ? applicableFormData.degrees[0].major : "",
                        backendValue: "",
                        errorMessage: "",
                        dropdownOptions: asyncDropdownValues ? asyncDropdownValues.majors : [],
                    },
                    {
                        formFieldType: FormFieldType.TextBox,
                        id: FormFieldName.DegreesGradDate,
                        name: FormFieldName.DegreesGradDate,
                        label: "Estimate graduation date",
                        placeholder: "MM/YYYY",
                        inputValue: hasDegrees ? epochToFormattedDateString(applicableFormData?.degrees[0].gradDate as number) : "",
                        backendValue: "",
                        errorMessage: "",
                        type: "text",
                    },
                    {
                        formFieldType: FormFieldType.ReadonlyDropdown,
                        id: FormFieldName.Enrollment,
                        name: FormFieldName.Enrollment,
                        label: "Enrollment status",
                        placeholder: getApplicableUserText("Select your enrollment status"),
                        inputValue: applicableFormData.enrollment ?? "",
                        backendValue: "",   
                        errorMessage: "",
                        dropdownOptions: [
                            { label: "Full time", value: "Full-Time Student" },
                            { label: "Halftime", value: "More than half-time" },
                            { label: "Less than halftime", value: "Less than half-time" },
                            { label: "Not enrolled", value: "Not currently enrolled" },
                        ],
                    },
                    
                ],
            },
        ],
    };
};
