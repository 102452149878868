import { DashboardContainer } from "../../features/Dashboard/containers/DashboardContainer";
import { NavigationWrapper } from "../../common/components/NavigationWrapper/NavigationWrapper";

export const Dashboard = () => {

    return (
        <NavigationWrapper>
            <DashboardContainer />
        </NavigationWrapper>
    );
};
