"use client";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import dataAnim from "../../../../assets/lottieJSON/offerLoadingWithoutSteps.json";
import "../../../components/loading/style.scss";

import "../style.scss";
import { isMarketplaceEnv } from "../../../utils/config";
import { useOnboarding } from "../../../core/providers/onboarding.provider";


//@ts-ignore
export const LoadingOffersPage = (props) => {
    const { companyName } = useOnboarding();

    const [step, setStep] = useState(1);

    useEffect(() => {
        let counter: number = 1;
        const stepInterval = setInterval(() => {
            if (counter < 3) {
                counter += 1;
            } else {
                counter = 1;
            }
            setStep(counter);
        }, 6000);
        return () => clearInterval(stepInterval);
    }, []);

    useEffect(() => {
        document.body.classList.add("no-scroll");

        return () => {
            document.body.classList.remove("no-scroll");
        };
    }, []);
    return (
        <div
            className={`onboard-home onboard-home--loading-offers onboard-home-coming-soon-page ${
                typeof props.customClass === "string" ? props.customClass : ""
            }`}
        >
            <div className="onboard-home__content coming-soon-page loading-offers">
                <div
                    className="loading-offers-content"
                    style={{ display: "flex", flexDirection: "column"}}
                >
                    {isMarketplaceEnv() ? (
                        <Lottie
                            loop={true}
                            autoplay={true}
                            animationData={dataAnim}
                            style={{
                                width: "100%",
                                height: "100%",
                                maxWidth: "150px",
                                overflow: "hidden",
                                margin: "0px auto",
                            }}
                        />
                    ) : (
                        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                        <div className="loader-dots" style={{position: "static"}}>
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                            <span />
                        </div>
                        </div>
                    )}
                    <div className="loading-offers-text">
                        <div className={`loading-offers-text-item ${step === 1 ? "active" : ""}`}>
                            <div className="h2 h2-app">Evaluating your information</div>
                            <p className="text-grey little">
                                We’re reviewing the information you submitted
                            </p>
                        </div>
                        <div className={`loading-offers-text-item ${step === 2 ? "active" : ""}`}>
                            <div className="h2 h2-app">Running your eligibility</div>
                            <p className="text-grey little">
                                We’re checking your eligibility with every applicable <br /> lender
                                on the {companyName} platform
                            </p>
                        </div>
                        <div className={`loading-offers-text-item ${step === 3 ? "active" : ""}`}>
                            <div className="h2 h2-app">The competition has begun</div>
                            <p className="text-grey little">
                                Lenders are submitting their best pre-qualified rates <br />
                                for your request
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
