type ModalIcon = "edit" | "delete";

// TODO: convert other included user modal icons to svgs and include in this function
export const getIncludedUserModalSvg = (modalIcon: ModalIcon) => {
    switch (modalIcon) {
        case "delete":
            return (
                <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle cx="20" cy="20" r="20" fill="#F32B32" />
                    <path
                        d="M28 14H24V13C24 12.2044 23.6839 11.4413 23.1213 10.8787C22.5587 10.3161 21.7956 10 21 10H19C18.2044 10 17.4413 10.3161 16.8787 10.8787C16.3161 11.4413 16 12.2044 16 13V14H12C11.7348 14 11.4804 14.1054 11.2929 14.2929C11.1054 14.4804 11 14.7348 11 15C11 15.2652 11.1054 15.5196 11.2929 15.7071C11.4804 15.8946 11.7348 16 12 16H13V27C13 27.7956 13.3161 28.5587 13.8787 29.1213C14.4413 29.6839 15.2044 30 16 30H24C24.7956 30 25.5587 29.6839 26.1213 29.1213C26.6839 28.5587 27 27.7956 27 27V16H28C28.2652 16 28.5196 15.8946 28.7071 15.7071C28.8946 15.5196 29 15.2652 29 15C29 14.7348 28.8946 14.4804 28.7071 14.2929C28.5196 14.1054 28.2652 14 28 14ZM18 13C18 12.7348 18.1054 12.4804 18.2929 12.2929C18.4804 12.1054 18.7348 12 19 12H21C21.2652 12 21.5196 12.1054 21.7071 12.2929C21.8946 12.4804 22 12.7348 22 13V14H18V13ZM25 27C25 27.2652 24.8946 27.5196 24.7071 27.7071C24.5196 27.8946 24.2652 28 24 28H16C15.7348 28 15.4804 27.8946 15.2929 27.7071C15.1054 27.5196 15 27.2652 15 27V16H25V27Z"
                        fill="white"
                    />
                </svg>
            );
        case "edit":
            return (
                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="20" cy="20" r="20" fill="#F32B32" />
                    <circle cx="20" cy="20" r="11" fill="#F32B32" />
                    <path d="M20 23L18.3922 14.9612C18.1893 13.9466 18.9653 13 20 13C21.0347 13 21.8107 13.9466 21.6078 14.9612L20 23Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <circle cx="20" cy="26" r="1" fill="white" />
                </svg>

            );
    }
};
