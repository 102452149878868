import SparrowUIStylesWrapper from "../sparrow-ui-styles-wrapper";
import { SparrowUIProvider, SparrowUIFormsProvider } from "../../context";

const SparrowProvider = ({ children }: { children: React.ReactNode }) => {
    return (
        <SparrowUIStylesWrapper>
            <SparrowUIFormsProvider>
                <SparrowUIProvider>
                    {children}
                </SparrowUIProvider>
            </SparrowUIFormsProvider>
        </SparrowUIStylesWrapper>
    )
}

export default SparrowProvider;