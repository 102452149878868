import { useStore } from "../../../core/providers/global.provider";
import { AdvertiserRider } from "../../Riders/riders/AdvertiserRider";
import "./style.scss";

interface IAdvertiserDisclosureButton {
    isLeftAligned?: boolean;
}

// TODO: Update to utilize Alex's new modal component
export const AdvertiserDisclosureButton = ({ isLeftAligned }: IAdvertiserDisclosureButton) => {
    const {setModal} = useStore();

    return (
        <>
            <button
                className={`advertiser-disclosure-button ${isLeftAligned ? "left-aligned" : ""}`}
                onClick={() => setModal(() => <AdvertiserRider />)}>
                Advertiser Disclosure
            </button>
        </>
    );
};
