"use client";
import { useStore } from "../../common/core/providers/global.provider";
import { useOnboarding } from "../../common/core/providers/onboarding.provider";
import { dashboardUrl, signUpStepBorrowerUrl } from "../../common/core/routes/navigation-urls";
import { InnerLoading } from "../../common/components/loading";
import { Get } from "../../common/core/services/clientApi";
import {
    userMissingFields,
    UserMissingFieldsType,
} from "../../common/core/services/sparrow-api-endpoints";
import { useNavigate } from "react-router-dom";
import { completeSignUpBase } from "../../common/core/routes/routing-urls";
import { logoutUser } from "../../common/core/logout";
import { GetCurrentUserAndUpdateStore } from "../../common/core/services/sparrow-api-handlers/profile-api-handlers";
import { AuthHandler } from "../../common/core/auth";

export const SSOInterceptorPage = () => {
    const store = useStore();
    const onboarding = useOnboarding();
    const navigate = useNavigate();

    const {
        setUserData,
        setProtectedForm,
        setSnackError,
        setSsoUserData,
        setUserHasMissingFields,
    } = store;
    const { setJwt } = onboarding;

    AuthHandler.currentAuthenticatedUser()
        .then(async (authData) => {
            if (authData) {
                setJwt(authData.signInUserSession?.idToken?.jwtToken);
                await GetCurrentUserAndUpdateStore({
                    jwt: authData.signInUserSession?.idToken.jwtToken,
                    setUserData,
                });
                if (authData.hasOwnProperty("attributes")) {
                    setProtectedForm(true);
                    navigate(dashboardUrl);
                } else {
                    Get(userMissingFields, authData.signInUserSession.idToken.jwtToken)
                        .then((resp: Partial<UserMissingFieldsType>) => {
                            setUserHasMissingFields(Boolean(resp.isMissingFields));
                            if (resp.isMissingFields) {
                                setSsoUserData({
                                    ...resp,
                                    email: authData.signInUserSession?.idToken?.payload?.email,
                                });
                                navigate(completeSignUpBase);
                            } else {
                                navigate(dashboardUrl);
                            }
                        })
                        .catch((e) => {
                            logoutUser({
                                store: store,
                                onboarding: onboarding,
                                navigate: navigate,
                            });
                        });
                }
            } else {
                setSnackError(
                    "An error occurred while signing up with Google account, please try using the sign up page",
                    "error"
                );
                navigate(signUpStepBorrowerUrl);
            }
        })
        .catch((e) => {
            e?.message
                ? setSnackError(e.message, "error")
                : setSnackError(
                      "Authentication error. You`ll be redirected to sign up page",
                      "error"
                  );
            navigate(signUpStepBorrowerUrl);
        });

    return (
        <div className="auth-flow-container--inner-sso">
            <InnerLoading shown />
        </div>
    );
};
