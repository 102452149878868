import { TemplateCustomizeStylesProps } from "../../../common/types/paaas";
import { hexToRgb } from "../helpers";

const setHeaderStyles = ({ logo, button, menu }: TemplateCustomizeStylesProps['header']) => {
    const handleMenuAlign = (menuAlign: string) => {
        switch (menuAlign) {
            case 'left':
                return 'flex-start';
            case 'right':
                return 'flex-end';
            case 'center':
                return 'center';
            default:
                return 'center';
        }
    }

    document.documentElement.style.setProperty('--logoSize', `${logo.size}px`);
    document.documentElement.style.setProperty('--topMenuAlign', handleMenuAlign(menu.menuAlign));

}

const setFontStyles = ({ mainFont, titleFont, customFont }: TemplateCustomizeStylesProps['fonts']) => {
    document.documentElement.style.setProperty('--mainFont', mainFont.name);
    document.documentElement.style.setProperty('--titleFont',titleFont.name);
}

const setColorStyles = ({ borrower, cosigner }: TemplateCustomizeStylesProps['colors']) => {
     const {mainColor: borrowerPrimaryColor, accentColor: borrowerAccentColor} = borrower;
    const {mainColor: cosignerPrimaryColor, accentColor: cosignerAccentColor} = cosigner;
    // TODO: all of these will need to be updated once we have borrower / cosigner colors
    
    document.documentElement.style.setProperty('--borrowerPrimaryColor', hexToRgb(borrowerPrimaryColor));
    document.documentElement.style.setProperty('--borrowerAccentColor', hexToRgb(borrowerAccentColor));
    
    document.documentElement.style.setProperty('--cosignerPrimaryColor', hexToRgb(cosignerPrimaryColor));
    document.documentElement.style.setProperty('--cosignerAccentColor', hexToRgb(cosignerAccentColor));
    
    //TODO: determine why there are two versions of primaryColor
    document.documentElement.style.setProperty('--primaryColorRGB', hexToRgb(borrowerPrimaryColor));
    
    document.documentElement.style.setProperty('--accentColorRGB', hexToRgb(borrowerAccentColor));
}

const setButtonStyles = ({ borrower, cosigner }: TemplateCustomizeStylesProps['buttons']) => {
    const { primary: borrowerPrimary, secondary: borrowerSecondary, link: borrowerLink } = borrower;
    const { primary: cosignerPrimary, secondary: cosignerSecondary, link: cosignerLink } = cosigner;

    document.documentElement.style.setProperty('--borrowerButtonPrimaryFont', borrowerPrimary.font.name);
    document.documentElement.style.setProperty('--borrowerButtonPrimaryColor', borrowerPrimary.textColor);
    document.documentElement.style.setProperty('--borrowerButtonPrimaryBorderColor', borrowerPrimary.borderColor ?? '#2D8F43');
    document.documentElement.style.setProperty('--borrowerButtonPrimaryBackground', borrowerPrimary.fillColor ?? '#2D8F43');
    document.documentElement.style.setProperty('--borrowerButtonPrimaryBorderRadius', borrowerPrimary.corners ?? '0');
    document.documentElement.style.setProperty('--borrowerButtonPrimaryTextTransform', borrowerPrimary.isCapslock ? 'uppercase' : 'inherit');
    
    
    document.documentElement.style.setProperty('--borrowerButtonSecondaryFont', borrowerSecondary.font.name);
    document.documentElement.style.setProperty('--borrowerButtonSecondaryColor', borrowerSecondary.textColor);
    document.documentElement.style.setProperty('--borrowerButtonSecondaryBorderColor', borrowerSecondary.borderColor ?? '#2D8F43');
    document.documentElement.style.setProperty('--borrowerButtonSecondaryBackgroundColor', borrowerSecondary.fillColor ?? 'transparent');
    document.documentElement.style.setProperty('--borrowerButtonSecondaryBorderRadius', borrowerSecondary.corners ?? '0');
    document.documentElement.style.setProperty('--borrowerButtonSecondaryTextTransform', borrowerSecondary.isCapslock ? 'uppercase' : 'inherit');
    
    document.documentElement.style.setProperty('--borrowerLinkFont', borrowerLink.font.name);
    document.documentElement.style.setProperty('--borrowerLinkColor', borrowerLink.textColor);
    document.documentElement.style.setProperty('--borrowerLinkTextTransform', borrowerLink.isCapslock ? 'uppercase' : 'inherit');
    document.documentElement.style.setProperty('--borrowerLinkTextDecoration', borrowerLink.isUnderline ? 'underline' : 'none');

    document.documentElement.style.setProperty('--cosignerButtonPrimaryFont', cosignerPrimary.font.name);
    document.documentElement.style.setProperty('--cosignerButtonPrimaryColor', cosignerPrimary.textColor);
    document.documentElement.style.setProperty('--cosignerButtonPrimaryBorderColor', cosignerPrimary.borderColor ?? '#2D8F43');
    document.documentElement.style.setProperty('--cosignerButtonPrimaryBackground', cosignerPrimary.fillColor ?? '#2D8F43');
    document.documentElement.style.setProperty('--cosignerButtonPrimaryBorderRadius', cosignerPrimary.corners ?? '0');
    document.documentElement.style.setProperty('--cosignerButtonPrimaryTextTransform', cosignerPrimary.isCapslock ? 'uppercase' : 'inherit');
    
    
    document.documentElement.style.setProperty('--cosignerButtonSecondaryFont', cosignerSecondary.font.name);
    document.documentElement.style.setProperty('--cosignerButtonSecondaryColor', cosignerSecondary.textColor);
    document.documentElement.style.setProperty('--cosignerButtonSecondaryBorderColor', cosignerSecondary.borderColor ?? '#2D8F43');
    document.documentElement.style.setProperty('--cosignerButtonSecondaryBackgroundColor', cosignerSecondary.fillColor ?? 'transparent');
    document.documentElement.style.setProperty('--cosignerButtonSecondaryBorderRadius', cosignerSecondary.corners ?? '0');
    document.documentElement.style.setProperty('--cosignerButtonSecondaryTextTransform', cosignerSecondary.isCapslock ? 'uppercase' : 'inherit');
    
    document.documentElement.style.setProperty('--cosignerLinkFont', cosignerLink.font.name);
    document.documentElement.style.setProperty('--cosignerLinkColor', cosignerLink.textColor);
    document.documentElement.style.setProperty('--cosignerLinkTextTransform', cosignerLink.isCapslock ? 'uppercase' : 'inherit');
    document.documentElement.style.setProperty('--cosignerLinkTextDecoration', cosignerLink.isUnderline ? 'underline' : 'none');

}

export const setCustomStyles = (styles: TemplateCustomizeStylesProps) => {
    const { header, fonts, colors, buttons } = styles;
    setHeaderStyles(header);
    setFontStyles(fonts);
    setColorStyles(colors);
    setButtonStyles(buttons);
};