import { Page } from "react-pdf";

const Pages = ({ numberOfPages }: { numberOfPages: number }) => {
    /* Use the "Array.from" method to create an array of specified length (numberOfPages) 
    and generate a Page component with a unique key and pageNumber for each element*/
    return (
        <>
            {Array.from({ length: numberOfPages }, (_, i) => {
                return <Page key={i + 1} pageNumber={i + 1} />;
            })}
        </>
    );
};

export default Pages;
