"use client";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { RegularButtonWithLoading } from "../../common/components/buttons/regular_button/RegularButtonWithLoading";
import { InputText } from "../../common/components/inputs/input_text/input_text";
import { useStore } from "../../common/core/providers/global.provider";
import { useOnboarding } from "../../common/core/providers/onboarding.provider";
import { loginStepBorrower } from "../../common/core/routes/navigation-urls";
import { AuthHandler } from "../../common/core/auth";

export const ConfirmPassword = () => {
    const { setSnackError, setAuthSnackError, authNotifReset } = useStore();
    const { routeState, paaasCustomization } = useOnboarding();
    const [password1, setPassword1] = useState("");
    const [password2, setPassword2] = useState("");
    const [confirmError1, setConfirmError1] = useState("");
    const [confirmError2, setConfirmError2] = useState("");
    const [showPsw, setShowPsw] = useState(false);
    const [showPsw2, setShowPsw2] = useState(false);
    const [valid, setIsValid] = useState(false);
    const [emailFromReset, setEmailFromReset] = useState();
    const [codeFromCheck, setCodeFromCheck] = useState();
    const [pswLengthValid, setPswLengthValid] = useState(false);
    const [pswNumberIncludeValid, setPswNumberIncludeValid] = useState(false);
    const [pswSymbolIncludeValid, setPswSymbolIncludeValid] = useState(false);
    const [pswPasswordIncludeValid, setPswPasswordIncludeValid] = useState(false);
    const [pswLetterCaseValid, setPswLetterCaseValid] = useState(false);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        checkValidation();
        const checkComesFrom: any = routeState;

        // @ts-ignore
        if (routeState && routeState.email !== undefined) {
            // @ts-ignore
            setEmailFromReset(checkComesFrom && routeState.email);
            setCodeFromCheck(checkComesFrom && checkComesFrom.code);
        }
        // @ts-ignore
        if (!routeState) {
            navigate(loginStepBorrower);
        }
    }, [password1, password2]);

    const passwordChecker = (password: string) => {
        setPswLengthValid(password.length > 11);
        setPswLetterCaseValid(!!password.match("[A-Z]") && !!password.match("[a-z]")); // For Uppercase/Lowercase Alphabet
        setPswNumberIncludeValid(!!password.match("[0-9]"));
        setPswPasswordIncludeValid(
            !password.includes("password") && !password.includes("Password") && password.length > 0
        );
        setPswSymbolIncludeValid(!!password.match("[!@#$%^&*(),.?\":{}|<>№;~`'+-/=\\[\\]\\\\_]"));
    };
    const checkValidation = () => {
        setIsValid(
            pswLengthValid &&
                pswLetterCaseValid &&
                pswNumberIncludeValid &&
                pswPasswordIncludeValid &&
                pswSymbolIncludeValid &&
                password2.length > 0 &&
                !confirmError1.length &&
                !confirmError2.length
        );
    };

    const onChangePsw = (value: string, confirmPsw = false) => {
        if (!confirmPsw) {
            const error =
                value.length < 12 && value.length > 0
                    ? "The minimum length must be 12 characters"
                    : "";
            const error2 =
                password2.length > 0 && password2 !== value ? "Passwords do not match" : "";
            setConfirmError2(error2);
            error2.length ? setAuthSnackError(error2, "error") : authNotifReset();
            setConfirmError1(error);
            setPassword1(value);
            passwordChecker(value);
            checkValidation();
        } else {
            const error = value.length > 0 && password1 !== value ? "Passwords do not match" : "";
            setConfirmError2(error);
            error.length ? setAuthSnackError(error, "error") : authNotifReset();
            setPassword2(value);
        }
    };

    const onSubmit = async () => {
        setLoading(true);
        try {
            await AuthHandler.forgotPasswordSubmit({
                email: emailFromReset!,
                code: codeFromCheck!,
                password: password1,
                partnerId: paaasCustomization?.partnerId,
            });
            setSnackError("Success! Your password has been updated.", "success");
            setTimeout(() => {
                navigate(loginStepBorrower);
            }, 2500);
        } catch (e: any) {
            setLoading(false);
            setSnackError(e.message, "error");
        }
    };

    return (
        <div className="auth-flow-container--inner">
            <div className="step-form-header">
                <h2 className="h2">Set your new password</h2>
                <div className="subtitle">
                    Enter and confirm your new password for your{" "}
                    <span className="highlighted">{emailFromReset}</span> account below
                </div>
            </div>

            <div className="step-form-body">
                <div className="step-form-block">
                    <div className="form-row">
                        <div className="form-col-12 form-col">
                            <InputText
                                value={password1}
                                name="old-password"
                                type={showPsw ? "text" : "password"}
                                label="New Password"
                                placeholder="Enter new password"
                                onChange={(e: any) => onChangePsw(e.target.value)}
                                error={confirmError1}
                                passwordType={true}
                                passwordShown={showPsw}
                                showPassword={() => setShowPsw(!showPsw)}
                                onClear={() => onChangePsw("")}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-col-12 form-col">
                            <InputText
                                value={password2}
                                name="new-password"
                                type={showPsw2 ? "text" : "password"}
                                label="Confirm Password"
                                placeholder="Confirm new password"
                                error={confirmError2}
                                passwordType={true}
                                passwordShown={showPsw2}
                                showPassword={() => setShowPsw2(!showPsw2)}
                                onChange={(e: any) => onChangePsw(e.target.value, true)}
                                onClear={() => onChangePsw("", true)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="step-form-footer">
                <div className="button-group text-center">
                    <RegularButtonWithLoading
                        loading={loading}
                        onSubmit={onSubmit}
                        disabled={!valid}
                    >
                        Continue
                    </RegularButtonWithLoading>
                    {/* <Button customClass={'confirm-btn-home'} text="Go to Homepage" type="link" variant="text" buttonStyle="white" url={borrowerHomeUrl} /> */}
                </div>
            </div>
        </div>
    );
};
