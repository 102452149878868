import { MenuItems } from "../../../types/paaas/custom-menu-props";
import { MenuPopup } from "../LeftNavigationRequestPopup/MenuPopup";
import { RequestPopup } from "../LeftNavigationRequestPopup/RequestPopup";

interface ILeftNavigationExpandedMenu {
    menuExpandDropdown?: MenuItems;
}

export const LeftNavigationExpandedMenu = ({ menuExpandDropdown }: ILeftNavigationExpandedMenu) => {
    return menuExpandDropdown ? (
        <MenuPopup menuExpandDropdown={menuExpandDropdown} />
    ) : (
        <RequestPopup />
    );
};
