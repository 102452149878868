import { UserType } from "../../constants/defaultInputs";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { OfferHeaderBorrowerDropdown } from "./OfferHeaderBorrowerDropdown";
import { OfferHeaderCosignerDropdown } from "./OfferHeaderCosignerDropdown";

interface IncludedUserProps {
    toggle: boolean;
    text: string;
    onExit?: Function;
}

export const OfferHeaderIncludedUserInput: React.FunctionComponent<IncludedUserProps> = ({
    toggle,
    text,
    onExit,
}) => {
    const { formData } = useOnboarding();

    const isBorrowerInitiated = formData.userType === UserType.BORROWER;

    return (
        <>
            <div className="header-dropdown-input-container">
                {isBorrowerInitiated ? (
                    <OfferHeaderCosignerDropdown text={text} onExit={onExit} />
                ) : (
                    <OfferHeaderBorrowerDropdown onExit={onExit}/>
                )}
            </div>
        </>
    );
};
