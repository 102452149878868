import { FC } from "react";
import { observer } from "mobx-react";
import { ApplyWithLenderDataLenderData, LenderStatuses } from "../../../../../common/models/request";
import { SVG_LENDER_STATUS } from "../../../../../assets/icons/SVG_Lender_Status";
import { useStore } from "../../../../../common/core/providers/global.provider";
import { CancelledDeniedModal } from "./Modal/CancelledDeniedModal";
import { sentenceCase } from "../../../../../common/utils/global";

interface LenderDataPopupProps {
    lenderData: ApplyWithLenderDataLenderData;
}

const lenderStatusIcon = (lenderStatus: LenderStatuses) => {
    switch (lenderStatus) {
        case LenderStatuses.APPROVED:
            return SVG_LENDER_STATUS.approved;
        case LenderStatuses.CANCELLED:
            return SVG_LENDER_STATUS.canceledDenied;
        case LenderStatuses.DENIED:
            return SVG_LENDER_STATUS.canceledDenied;
        case LenderStatuses.DISBURSED:
            return SVG_LENDER_STATUS.disbursed;
        case LenderStatuses.SIGNED:
            return SVG_LENDER_STATUS.signed;
        case LenderStatuses.STARTED:
            return SVG_LENDER_STATUS.started;
        case LenderStatuses.SUBMITTED:
            return SVG_LENDER_STATUS.submitted;
        default:
            return SVG_LENDER_STATUS.started;
    }
};

export const LenderDataPopup: FC<LenderDataPopupProps> = observer(({ lenderData }) => {
    const store = useStore();

    const modalHandler = () => {
        store.setModal(() => (
            <CancelledDeniedModal
                lenderStatus={lenderData?.lenderStatus}
                lenderName={lenderData?.lenderName}
            />
        ));
    };

    return (
        <div className="awl-lender-data">
            <p className="p-md-14-regular clr-neutral-500">Lender reported status:</p>
            <div className="awl-lender-status">
                <p className="p-lg-16-semi-bold">{sentenceCase(lenderData.lenderStatus)}</p>
                <img src={lenderStatusIcon(lenderData.lenderStatus)} alt="lender status icon" />
                <div className="awl-learn">
                    {(lenderData.lenderStatus === LenderStatuses.CANCELLED ||
                        lenderData.lenderStatus === LenderStatuses.DENIED) && (
                        <>
                            <p style={{ color: "#F32B32" }} onClick={modalHandler}>
                                Learn why
                            </p>
                            <span style={{ color: "#F32B32" }} className="icon-arrow-right" />
                        </>
                    )}
                </div>
            </div>
        </div>
    );
});
