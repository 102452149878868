
interface ICardDashboard {
    icon: string
    title: string
    value: string
    isCosigner: boolean
    customClass?: string
}
const CardDashboard = ({icon, isCosigner, title, value, customClass}: ICardDashboard) => {
    return <div className={`interface-card ${isCosigner ? 'interface-card__cosigner' : ''}`}>
        <div className={`interface-card-title ${customClass}`}>
            <span className={icon}/> {title}
        </div>
        <div className={`interface-card-value ${customClass}`}>
            {value} <span className={isCosigner ? 'icon-dashboard-plus' : 'icon-arrow-bottom'}/>
        </div>
    </div>
}
export default CardDashboard