import { CSSProperties } from "react";
import Lottie from "lottie-react";
import { useStore } from "../../core/providers/global.provider";
import dataAnim from "./animation/data2.json";
import "./style.scss";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { observer } from "mobx-react";
import { isMarketplaceEnv } from "../../utils/config";

const LoaderDots = () => {
    return (
        <div className="loader-dots">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
        </div>
    );
};

export const InnerLoading = (props: {
    shown: boolean;
    children?: React.ReactNode;
    style?: CSSProperties;
}) => {
    return (
        <>
            <div className="loader-parent" style={props.style}>
                <div className={`${props.shown ? "active" : ""} loader-wrapper-small`}>
                    <div className="loader">
                        <div id="logo-animation">
                            {isMarketplaceEnv() ? (
                                <Lottie loop={true} autoPlay={true} animationData={dataAnim} />
                            ) : (
                                <LoaderDots />
                            )}
                        </div>
                        <span />
                    </div>
                </div>
                {props.children}
            </div>
        </>
    );
};

export const Loading = (props: { shown: boolean; children?: React.ReactNode }) => {
    return (
        <>
            <div className={`${props.shown ? "active" : ""} loader-wrapper`}>
                <div className="loader">
                    <div id="logo-animation">
                        {isMarketplaceEnv() ? (
                            <Lottie loop={true} autoPlay={true} animationData={dataAnim} />
                        ) : (
                            <LoaderDots />
                        )}
                    </div>
                    <span />
                </div>
            </div>
        </>
    );
};

export const Loader = observer(() => {
    const { loading } = useStore();
    // TODO: remove old loader from store once all forms have been updated to use this new loader
    const { loading: newLoading } = useOnboarding();

    return (
        <>
            <div className={`${loading || newLoading ? "active" : ""} loader-wrapper`}>
                <div className="loader">
                    <div id="logo-animation">
                        {isMarketplaceEnv() ? (
                            <Lottie loop={true} autoPlay={true} animationData={dataAnim} />
                        ) : (
                            <LoaderDots />
                        )}
                    </div>
                    <span />
                </div>
            </div>
        </>
    );
});
