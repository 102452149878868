import { useLocation } from "react-router-dom";
import { useSparrowUIContext } from "../../../../sparrow-ui-library/src/context";
import { TipsLightBulb } from "../../../../sparrow-ui-library/src/assets/icons/tips-icons";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import { observer } from "mobx-react";
import { UserType } from "../../../../sparrow-ui-library/src/types/sparrow-types";
import { FC } from "react";
import { isPAAASLandingUrl } from "../../../utils/routing";

// If the Mobile Navigation Header is showing, we either show a non-tips page hamburger or a tips page tips icon
export const MobileNavTrigger: FC<{setShowSideBarHandlerForMobile?: (state: boolean) => void}> = observer(({setShowSideBarHandlerForMobile}) => {
    const routePathname = useLocation().pathname;
    const { showMobileTips, setShowMobileTips } = useSparrowUIContext();
    const onboarding = useOnboarding();

    const isNonTipsPage = () => {
        // pathnames for pages that don't have tips
        if(isPAAASLandingUrl(routePathname)) return true;

        return ["dashboard", "rates"].filter((page) => routePathname.includes(page)).length;
    };
    
    return (
        <>  
        {/* Display hamburger icon if this is not a tips page, where we display the lightbulb */}
            {isNonTipsPage() ? (
                <button
                    className={"mobile-navigation-header__trigger"}
                    onClick={() => setShowSideBarHandlerForMobile?.(true)}
                >
                    <span />
                    <span />
                </button>
            ) : (
                <button onClick={() => setShowMobileTips(!showMobileTips)}>
                    <TipsLightBulb userType={onboarding.formData.userType ?? UserType.BORROWER }/>
                </button>
            )}
        </>
    );
});
