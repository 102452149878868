"use client";
import React, { useEffect, useState } from "react";
import Select, { createFilter } from "react-select";
import AsyncSelect from "react-select/async";
import "./style.scss";
import { observer } from "mobx-react";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import { UserType } from "../../../constants/defaultInputs";

interface IInputSelect {
    id?: string;
    name?: string;
    placeholder: string;
    label: string;
    value: any;
    error?: string | null;
    onChange: any;
    col?: number;
    options: any;
    isSearchable?: boolean;
    useAsync?: boolean;
    tabIndex?: number;
    autoFocus?: boolean;
    openMenuOnFocus?: boolean;
    filterOption?: (option: any, rawInput: string) => boolean;
}

export const InputSelect: React.FunctionComponent<IInputSelect> = observer(
    ({ useAsync = false, tabIndex = 0, ...props }) => {
        const { formData } = useOnboarding();
        const [state, setState] = useState();
        const [loading, setLoading] = useState(false);
        const [defaultV, setDefaultV] = useState<any>([]);
        const [focused, setFocused] = useState(false);
        const isBorrowerInitiated = formData.userType === UserType.BORROWER;

        useEffect(() => {
            const init = props.options.find((v: any) => v.value === props.value);
            setState(init);
        }, [props.value, props.options]);

        useEffect(() => {
            if (useAsync) {
                const arr = [...props.options].splice(0, 500);
                setDefaultV(arr);
            }
        }, [props.options]);

        const onSelect = (e: any) => {
            setState(e);
            props.onChange(e.value);
        };

        const promiseOptions = (inputValue: string) => {
            return new Promise<any>((resolve) => {
                if (loading) return;
                setLoading(true);
                setTimeout(() => {
                    resolve(filterColors(inputValue));
                    setLoading(false);
                }, 50);
            });
        };

        const filterColors = (inputValue: string) => {
            let options = [...props.options];
            let substrings = inputValue.trim().split(" "); // trim spaces and split user input to array
            let arr = options.filter((i) => {
                return substrings.every((s) => {
                    // check is option value or label contains all words
                    const transformedS = s.toLowerCase().replace(/[^A-Z0-9]+/gi, ""); // make word lowercase and delete all special characters and spaces
                    const checkValue = i.value.toLowerCase().replace(/[^A-Z0-9]+/gi, "");
                    const checkLabel = i.label.toLowerCase().replace(/[^A-Z0-9]+/gi, "");
                    return (
                        checkValue.indexOf(transformedS) !== -1 ||
                        checkLabel.indexOf(transformedS) !== -1
                    );
                });
            });

            if (arr.length > 500) {
                return [...arr].splice(0, 500);
            }
            return arr;
        };

        const colorStyles = {
            option: (styles, { isFocused }) => {
                return {
                    ...styles,
                    color: isFocused ? (isBorrowerInitiated ? "#6DB353" : "#DC7962") : "black",
                };
            },
        };
        return (
            <div className={`form-col-${props.col ? props.col : 12} form-col`}>
                <div
                    className={`${props.error && props.error.length ? "error" : ""} form-group ${
                        focused ? "form-group--focused" : ""
                    }`}
                >
                    {useAsync ? (
                        <AsyncSelect
                            styles={colorStyles}
                            value={state}
                            defaultOptions={defaultV}
                            placeholder={props.placeholder}
                            className="input-select"
                            classNamePrefix="input-select"
                            tabIndex={tabIndex.toString()}
                            cacheOptions
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            openMenuOnFocus={props.openMenuOnFocus}
                            autoFocus={props.autoFocus}
                            isLoading={loading}
                            filterOption={props.filterOption}
                            isSearchable={props.isSearchable ?? false}
                            onChange={(e) => onSelect(e)}
                            loadOptions={promiseOptions}
                            id={props.id}
                            name={props.name}
                            instanceId={props.id}
                        />
                    ) : (
                        <Select
                            styles={colorStyles}
                            value={state}
                            options={props.options}
                            placeholder={props.placeholder}
                            className="input-select"
                            classNamePrefix="input-select"
                            onFocus={() => setFocused(true)}
                            onBlur={() => setFocused(false)}
                            openMenuOnFocus={props.openMenuOnFocus}
                            autoFocus={props.autoFocus}
                            tabIndex={tabIndex.toString()}
                            filterOption={createFilter({ ignoreAccents: false })}
                            isSearchable={props.isSearchable ?? false}
                            onChange={(e) => onSelect(e)}
                            id={props.id}
                            name={props.name}
                            instanceId={props.id}
                        />
                    )}
                    <label>{props.label}</label>
                </div>
            </div>
        );
    }
);
