import { Signer } from "@aws-amplify/core";
import { IMessageEvent, w3cwebsocket as WebSocket } from "websocket";
import { GetRequestPrequalSubmissionResults } from "../sparrow-api-handlers/offers-api-handlers";
import { PrequalSubmissionResults } from "../types/offers-api-types";
import { webSocketUrl } from "../sparrow-api-endpoints";
import { AuthHandler } from "../../auth";

const WEB_SOCKET_MESSAGE = new Set([
    "Offers Received",
    "No Offers",
    "Error while validating information",
]);

/**
 * Creates a new websocket connection and returns the websocket object
 * @returns Promise<WebSocket | null>
 * @param contextStore: ContextStore
 * @param requestBody: IPostPutPrequalificationRequest
 * @return Promise<Wesocket | null>
 */
const createNewWebSocket = async (): Promise<WebSocket | null> => {
    const credentials = await AuthHandler.currentCredentials();
    if (!credentials) return null;

    const { accessKeyId, secretAccessKey, sessionToken } = credentials;

    const accessInfo = {
        access_key: accessKeyId,
        secret_key: secretAccessKey,
        session_token: sessionToken,
    };

    const signedHTTPRequest = Signer.signUrl(webSocketUrl, accessInfo);
    return new WebSocket(signedHTTPRequest);
};

/**
 * Creates a new web socket and connects to it. Once the web socket receives a message, it will call the getRequestResultsAndUrl function
 * @param requestId: string
 * @param token: string
 * @returns Promise<string | null>
 */
export const connectToWebSocketAndGetRequestResults = async (
    requestId,
    token
): Promise<PrequalSubmissionResults | undefined> => {
    return new Promise(async (resolve, reject) => {
        const webSocket = await createNewWebSocket();

        if (!webSocket) {
            resolve(undefined);
            return;
        }

        let timeoutId;

        webSocket.onopen = () => {
            console.log("WebSocket Client Connected");
            timeoutId = setTimeout(() => {
                webSocket.close();
                console.log("WebSocket Client Disconnected")
                resolve(undefined);
            }, 240000); // 4 minute timeout
        };

        webSocket.onmessage = async (message: IMessageEvent) => {
            // Clear the timeout since a message was received
            clearTimeout(timeoutId);
            webSocket.close();
            console.log("WebSocket Client Disconnected")
            if (WEB_SOCKET_MESSAGE.has(message.data as string)) {
                const requestResults = await GetRequestPrequalSubmissionResults(requestId, token);

                if (!requestResults) {
                    resolve(undefined);
                    return;
                }
                // Successful response
                resolve(requestResults);
            }
        };
    });
};