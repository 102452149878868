import downArrow from "../../../../../assets/images/icons/down-arrow.svg";

interface IAccordionItemProps {
    text: {
        title: string;
        textContent: string;
    };
    active: boolean;
    onToggle: () => void;
}

const AccordionItem: React.FC<IAccordionItemProps> = ({ text, active, onToggle }) => {
    return (
        <div className={`accordion-item ${active ? "active" : ""}`}>
            <div className="accordion-button" onClick={onToggle}>
                <p>{text.title}</p>

                <img
                    className={`arrow-icon ${active ? "active" : ""}`}
                    src={downArrow}
                    alt="down-arrow"
                />
            </div>
            <div className={`answer_wrapper ${active ? "active" : ""}`}>
                <p>{text.textContent}</p>
            </div>
        </div>
    );
};

export default AccordionItem;
