import { FC } from "react";
import styles from "./styles.module.scss";

interface SectionHeaderProps {
    sectionText: string;
    title: string;
    subtitle?: string;
}


export const SectionHeader: FC<SectionHeaderProps> = (props) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.sectionText}>{props.sectionText}</div>
            <h2 className={styles.title}>
                {props.title}
            </h2>
            {props.subtitle && <p className={styles.subtitle}>
                {props.subtitle}
            </p>}
        </div>
    )
}
