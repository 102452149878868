import orangeIcon from "../../../../../assets/images/unable-to-prequalify/orange-error-circle.svg";

import "../../DeclineReasonsDisplay/styles.scss";

export const DeclineReasonsTitleSection = ({ hasOnlyOneDeclineReason, reasons }) => {
    return (
        <div className="title-section">
            {hasOnlyOneDeclineReason ? (
                <img src={orangeIcon} alt="orange icon" />
            ) : (
                <div className="circle-icon">
                    <p>{reasons.length}</p>
                </div>
            )}
            <p>
                {hasOnlyOneDeclineReason
                    ? reasons[0].title
                    : "The following reasons impacted our ability to pre-qualify you"}
            </p>
        </div>
    );
};
