import { GetPublic } from "../../../../common/core/services/clientApi";
import { paaasCustomization } from "../../paaas-base-url";
import {
    ColorsInput,
    FontsInput,
    TemplateCustomizeStylesProps,
    ToggleableViews,
} from "../../../../common/types/paaas";

export type PAAASEditorInput = {
    values: { [key: string]: any };
    mainNodes: { [key: string]: any };
    customize: {
        [key: string]: any;
        styles: TemplateCustomizeStylesProps;
        toggleableViews: ToggleableViews;
    };
};
export interface PAAASCustomizationResp {
    partnerId: string;
    logoUrl: string;
    faviconUrl: string;
    colors: ColorsInput;
    fonts: FontsInput;
    logoSize?: number;
    editorTemplate: {
        template: PAAASEditorInput;
        updatedAt: number;
        affiliateId: string;
    };
    name: string;
    privacyPolicyUrl: string;
    termsOfServiceUrl: string;
    removeDisallowedStatesAndCountries?: boolean;
    // icons: deprecated on BE
}

export const getPAAASCustomization = async (affiliateId: string) => {
    try {
        const paaasCustomizationsResp: PAAASCustomizationResp = await GetPublic(
            paaasCustomization,
            null,
            `partnerId=${affiliateId}`
        );
        return paaasCustomizationsResp;
    } catch (error) {
        console.log(error);
    }
};
