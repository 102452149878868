import crossIcon from "../../../assets/images/icons/cross.svg";
import { useEffect } from "react";

import { InputSelect } from "../inputs/input_select";
import { useOnboarding } from "../../core/providers/onboarding.provider";

interface SchoolSearchProps {
    title: string;
    toggle: boolean;
    value: number;
    setToggle: Function;
    onChange: Function;
}

export const OfferHeaderSchoolSearch: React.FunctionComponent<SchoolSearchProps> = ({
    title,
    toggle,
    setToggle,
    value,
    onChange,
}) => {
    const { school, formData, fetchFormFieldsValues } = useOnboarding();

    useEffect(() => {
        fetchFormFieldsValues();
    }, []);

    return (
        <>
            <div className={`header-dropdown-popup ${toggle ? "active" : ""} school`}>
                <div className="header-dropdown-input-container">
                    <div className="header-dropdown-input-title-container">
                        <div className="header-dropdown-input-title">{title}</div>
                        <div>
                            <img
                                src={crossIcon}
                                alt="crossIcon"
                                onClick={() => setToggle(!toggle)}
                            />
                        </div>
                    </div>
                </div>
                <InputSelect
                    col={formData.type === "PSL" ? 12 : 6}
                    placeholder={""}
                    options={school}
                    label=""
                    isSearchable={true}
                    useAsync={true}
                    tabIndex={1}
                    autoFocus
                    id="university"
                    name="university"
                    onChange={(value: string) => {
                        onChange("university", value);
                    }}
                    value={formData.degrees[0].university}
                />
            </div>
        </>
    );
};
