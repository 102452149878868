import React from "react";
import { PhoneInput as ReactPhoneInput } from "react-international-phone";
import {
    FormFieldName,
    PhoneFieldProps,
    PhoneInputValue,
    SetInputValue,
} from "../../../types/form-types";
import "react-international-phone/build/index.css";
import "./styles.css";
  
interface PhoneInputProps extends PhoneFieldProps {
    countryCode?: string;
    inputValue: PhoneInputValue;
    setInputValue: SetInputValue;
    disableCountryGuess?: boolean;
    forceDialCode?: boolean;
}

const PhoneInput: React.FC<PhoneInputProps> = ({
    name,
    label,
    required,
    inputValue,
    setInputValue,
    disableCountryGuess,
    forceDialCode,
}) => {
    const [onFirstRender, setOnFirstRender] = React.useState(true);
    const defaultCountry = inputValue.countryCode ?? "us";
    /* PhoneInput is used for both phone number and WhatsApp number fields. isWhatsAppNumber is true only if a user has selected that their
    WhatsApp number is not the same as their primary phone number. We do not collect any subfields for WhatsApp number such as countryCode*/
    const isWhatsAppNumber = name === FormFieldName.WhatsAppNumber;
    const handleInputChange = (value: string, countryCode: string) => {
        // ReactPhoneInput calls onChange on first render with the countryPrefix as the value. This prevents that from happening.
        
        if (onFirstRender) {
            setOnFirstRender(false);
            return;
        }
        
        if (!value) return;
        const dialCode = value.split(" ")[0];

        const updatedInputValue = !isWhatsAppNumber ? {
            phoneNumber: value as string,
            countryCode: countryCode,
            globalType: countryCode === "us" ? "Domestic" : "International",
            countryPrefix: dialCode.replace("+", ""),
        } :
            value;

        setInputValue(name, updatedInputValue);
    };
    return (
        <div>
            <label className="FieldLabel">{label}</label>
            <div className="InputFieldContainer">
                <ReactPhoneInput
                    defaultCountry={defaultCountry}
                    value={inputValue.phoneNumber}
                    onChange={(value, countryCode) => handleInputChange(value, countryCode)}
                    inputProps={{ name, required, className: "react-international-phone-input-overwrite" }}
                    disableCountryGuess={disableCountryGuess ?? true}
                    forceDialCode={forceDialCode ?? true}
                />
            </div>
        </div>
    );
};

export default PhoneInput;
