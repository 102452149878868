import { useStore } from "../../core/providers/global.provider";
import { observer } from "mobx-react";
import { ButtonWrapper } from "../buttons/button/ButtonWrapper";
import { Button, ButtonTypes } from "../buttons/button/Button";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { useNavigate } from "react-router-dom";
import { prequalificationExpandedSearchUrl } from "../../core/routes/navigation-urls";
import { useEffect } from "react";
import { seenExpandedSearch } from "../../../app/prequalification/utils/sparrow-api-handlers/prequalification-api-handlers";
import { ExpandedSearchIcon } from "../../../sparrow-ui-library/src/assets/icons/expanded-search-icon/ExpandedSearchIcon";
import { UserType } from "../../constants/defaultInputs";

export const ExpandedSearchModal = observer(() => {
    const store = useStore();
    const onboarding = useOnboarding();
    const navigate = useNavigate();

    const handleClick = () => {
        store.modalResetData();
        navigate(prequalificationExpandedSearchUrl);
    };

    useEffect(() => {
        seenExpandedSearch(
            {
                id: onboarding.formData?.id as string,
            },
            onboarding
        );
    }, []);

    return (
        <div className="modal-content">
            <button className="close-modal" onClick={store.modalResetData}>
                <span className={"icon-close"} />
            </button>
            <ExpandedSearchIcon userType={onboarding.formData.userType as UserType}/>
            <div style={{ marginBottom: "32px" }}>
                <h3>Expand your search</h3>
                <p>
                    You may be able to pre-qualify for additional rates by answering a few more
                    questions. It’s just one step and should take you less than a minute!
                </p>
            </div>
            <ButtonWrapper centerButtons={true}>
                <Button
                    id="secondary-button"
                    onClick={store.modalResetData}
                    buttonType={ButtonTypes.SECONDARY}
                    userType={onboarding.formData.userType}
                >
                    Skip for now
                </Button>
                <Button
                    id="primary-button"
                    tabIndex={0}
                    onClick={handleClick}
                    buttonType={ButtonTypes.PRIMARY}
                    userType={onboarding.formData.userType}
                >
                    Expand my search
                </Button>
            </ButtonWrapper>
        </div>
    );
});
