
export const BookIcon = () => {
    return (
        <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.62619 2.15231C1.62619 2.15231 2.53583 0.973145 4.65833 0.973145C6.78083 0.973145 7.69048 2.15231 7.69048 2.15231V10.4065C7.69048 10.4065 6.78083 9.81689 4.65833 9.81689C2.53583 9.81689 1.62619 10.4065 1.62619 10.4065V2.15231ZM7.69048 2.15231C7.69048 2.15231 8.60012 0.973145 10.7226 0.973145C12.8451 0.973145 13.7548 2.15231 13.7548 2.15231V10.4065C13.7548 10.4065 12.8451 9.81689 10.7226 9.81689C8.60012 9.81689 7.69048 10.4065 7.69048 10.4065V2.15231Z"
                stroke="rgba(var(--borrowerPrimaryColor), 1)" strokeWidth="1.34762" strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>
    )
}
