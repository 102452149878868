'use client';
import React, {useEffect, useState} from 'react'
import { Scrollbar } from 'react-scrollbars-custom';
interface ICustomScrollbar {
    children: React.ReactNode,
    horizontal?: boolean
    vertical?: boolean
    width?: string | number
    height?: number | string
    setHeightEvent?: any
    setWidthEvent?: any
    customCLassName?: string | null
    id?: string
}
const CustomScrollbar = (
    {
        children,
        horizontal = false,
        vertical = true,
        width = '100%',
        height = 150,
        setHeightEvent = null,
        setWidthEvent = null,
        customCLassName = null,
        id = ''
    }: ICustomScrollbar) => {

    const [defaultHeight, setDefaultHeight] = useState<any>(height)
    useEffect(() => {
        if (setHeightEvent && setHeightEvent()) {
            setDefaultHeight(setHeightEvent())
            window.addEventListener('resize', () => setDefaultHeight(setHeightEvent()))
            return window.removeEventListener('resize', () => setDefaultHeight(setHeightEvent()))
        }
    }, [])
    if (!children) {
        return <></>
    }
    return <div className={`scroller-wrapper`} style={{width: '100%', height: defaultHeight}}>
        <Scrollbar style={{ width: width, height: defaultHeight }}>
            <div className={`scrollbar-content ${customCLassName ? customCLassName : ''}`} id={id}>{children}</div>
        </Scrollbar>
    </div>
}

export default CustomScrollbar