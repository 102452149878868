import { Fragment, ReactNode } from "react";
import { FormFieldContainer } from "..";
import { FormFieldProps, FormFieldType, SetInputValue } from "../../../types/form-types";
import "./FormContent.css";

const FormContent: React.FC<{
    formFields: FormFieldProps[];
    handleInputChange: SetInputValue;
    children?: ReactNode;
    className?: string;
}> = ({ formFields, handleInputChange, children, className = "" }) => {
    return (
        // Stop propagation of click events to avoid unwanted side effects from using form tag
        <form className={`FormContent ${className}`} onClick={(e) => {e.stopPropagation()}}>
            {/* option A: to be used with js objects */}
            {formFields
                // While we include ToggleSwitch in the formFields array, we don't want to render them in this component
                .filter((formField) => formField.formFieldType !== FormFieldType.ToggleSwitch)
                .map((formField, i) => {
                    let k = i === 0 ? i :  2 * i ;
                    return (
                        <Fragment key={formField.name}>
                            {k < formFields.length && (
                                <div className={`FormRowContent`}>
                                    <FormFieldContainer
                                        formField={formFields[k]}
                                        handleInputChange={handleInputChange}
                                    />
                                    {k + 1 < formFields.length && (
                                        <FormFieldContainer
                                            formField={formFields[k + 1]}
                                            handleInputChange={handleInputChange}
                                        />
                                    )}
                                </div>
                            )}
                        </Fragment>
                    );
                })}
            {children}
        </form>
    );
};

export default FormContent;
