import { FC, MouseEventHandler } from "react";
import questionToolTip from "../../../../../src/assets/images/tooltip-question-grey.svg";
import styles from "./styles.module.scss"

export const IllustrativeDisclosure: FC<{handleDisclosureClick: MouseEventHandler<HTMLImageElement>, className?: string}> = (props) => {
    return (
        <div className={`${styles.illustrativeText} ${props.className ? styles[props.className] : ""}`}>
            <span>Illustrative purposes, actual results may vary.</span>
            <span style={{ display: "flex", gap: "4px" }}>
                Pre-qualified rates are not a firm offer of credit.
                <img className={styles.tooltip} width={12} src={questionToolTip} alt="tooltip" onClick={props.handleDisclosureClick} />
            </span>
        </div>
    )
}
