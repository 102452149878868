import {
    alphabetCheck,
    emailCheck,
    middleInitialCheck
} from "./input-restriction-functions";

export const inputRestrictionFunctionObject: { [key: string]: (value: string) => boolean } = {
    firstName: alphabetCheck,
    lastName: alphabetCheck,
    middleInitial: middleInitialCheck,
    email: emailCheck,
};

export const getInputRestrictionFunction = (fieldName: string) => {
    return inputRestrictionFunctionObject[fieldName];
};
