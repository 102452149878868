import { FC } from "react";
import { Button } from "../../buttons/button/Button";
import { ButtonTypes } from "../../buttons/button/Button";
import { useStore } from "../../../core/providers/global.provider";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import { UserType } from "../../../constants/defaultInputs";
import borrowerBirdIcon from "../../../../assets/images/icons/borrowerBird.svg";
import cosignerBirdIcon from "../../../../assets/images/icons/cosignerBird.svg";
import { ModalWrapper } from "../modal-wrapper";
import { PutRequestInviteAdd } from "../../../core/services/sparrow-api-handlers/prequalification-api-handlers";
import { errorLogs } from "../../../utils/global";
import { useNavigate } from "react-router-dom";
import { IPostInviteAddRequest } from "../../../core/services/sparrow-api-handlers/prequalification-api-handlers";
import { userInclusionManagementUrl } from "../../../core/routes/navigation-urls";
import { PrequalUrlsEnum } from "../../../core/global_routing/front-end-urls";
import { enumUserInclusionType } from "../../../store/onboarding";
import { observer } from "mobx-react";
import { ButtonWrapper } from "../../buttons/button/ButtonWrapper";
import { getActivePrequalSteps } from "../../../core/global_routing/route-steps";
import { isMarketplaceEnv } from "../../../utils/config";
import { ConfirmIcon } from "../../../../sparrow-ui-library/src/assets/icons/confirm-icon/ConfirmIcon";

interface IConfirmUserModal {
    form: {
        firstName: string;
        lastName: string;
        email: string;
    };
    continueButtonText: string;
}

export const ConfirmUserModal: FC<IConfirmUserModal> = observer((props) => {
    const store = useStore();
    const onboarding = useOnboarding();
    const navigate = useNavigate();

    const { form, continueButtonText } = props;

    const handleOnSubmit = async () => {
        const requestBody: IPostInviteAddRequest = {
            inclusionType: onboarding.userInclusionType as enumUserInclusionType,
            newRequestFields: {
                id: onboarding.formData.id,
                firstName: form.firstName,
                lastName: form.lastName,
                email: form.email.toLowerCase(),
            },
            token: onboarding.jwt as string,
        };

        try {
            store.setLoading(true);
            const prequalificationRequestOutput = await PutRequestInviteAdd(requestBody);
            if (prequalificationRequestOutput) {
                onboarding.saveFormInStoreAndStorage(prequalificationRequestOutput);
            }
            store.modalResetData();
            if (onboarding.userInclusionType === enumUserInclusionType.DIRECT) {
                onboarding.setCreatorIsIncludingUser(true);
                onboarding.setHasSSN();

                const activeSteps = getActivePrequalSteps(onboarding);
                onboarding.setActiveSteps(activeSteps);
                navigate(PrequalUrlsEnum.CITIZENSHIP);
                return store.setLoading(false);
            }

            navigate(userInclusionManagementUrl);
        } catch (err) {
            errorLogs("PostRequestInviteAdd", err);
            store.setSnackError("Something went wrong. Please try again later.", "error");
        }
        store.setLoading(false);
    };

    const activeIcon = {
        icon:
            onboarding.formData.userType === UserType.BORROWER
                ? borrowerBirdIcon
                : cosignerBirdIcon,
        alt:
            onboarding.formData.userType === UserType.BORROWER
                ? "Borrower Bird Icon"
                : "Cosigner Bird Icon",
    };

    const getIcon = () => {
        return isMarketplaceEnv() ? (
            <img src={activeIcon.icon} alt={activeIcon.alt} width={40} height={40} />
        ) : (
            
            <ConfirmIcon userType={onboarding.formData.userType as UserType}/>
        );
    };

    return (
        <ModalWrapper>
            {getIcon()}
            <h3
                style={{
                    fontSize: "24px",
                    lineHeight: "32px",
                    fontWeight: "600",
                    marginBottom: "12px",
                    overflowWrap: "anywhere",
                }}
            >{`Please confirm ${form.firstName}’s contact`}</h3>
            <p
                style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "400",
                    marginBottom: "24px",
                    color: "#878F99",
                    overflowWrap: "anywhere",
                }}
            >
                {`Prior to including ${form.firstName}, please ensure that the email below is correct. This email will have access to any rates you pre-qualify for in addition to your contact information.`}
            </p>
            <p
                style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "400",
                    marginBottom: "24px",
                    color: "#151B26",
                    overflowWrap: "anywhere",
                }}
            >
                {form.email}
            </p>
            <ButtonWrapper centerButtons={true}>
                <Button
                    id="previous-button"
                    onClick={store.modalResetData}
                    buttonType={ButtonTypes.SECONDARY}
                    userType={onboarding.formData.userType}
                >
                    Cancel
                </Button>
                <Button
                    id="continue-button"
                    tabIndex={0}
                    onClick={handleOnSubmit}
                    buttonType={ButtonTypes.PRIMARY}
                    userType={onboarding.formData.userType}
                >
                    {continueButtonText}
                </Button>
            </ButtonWrapper>
        </ModalWrapper>
    );
});
