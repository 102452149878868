import "./style.scss";
import ProfileHead from "./components/Head";
import ProfileCardInfo from "./components/ProfileCardInfo";
import { useEffect, useState } from "react";
import { Loading } from "../../../common/components/loading";
import { useStore } from "../../../common/core/providers/global.provider";
import { observer } from "mobx-react";
import ProfileDeleteModal from "./components/ProfileDeleteModal";
import ProfilePersonalInfoModal from "./components/ProfilePersonalInfoModal";
import ProfileSecurityPreferencesModal from "./components/ProfileSecurityPreferencesModal";
import ProfileContactInfoModal from "./components/ProfileContactInfoModal";
import ProfileContactInfoConfirmModal from "./components/ProfileContactInfoConfirmModal";
import {
    GetCurrentUserAndUpdateStore,
    PutUpdateUserAttributes,
} from "../../../common/core/services/sparrow-api-handlers/profile-api-handlers";
import { useOnboarding } from "../../../common/core/providers/onboarding.provider";
import { IUserData } from "../../../common/models/userData";
import { Auth } from "@aws-amplify/auth";
import { useSparrowUIContext } from "../../../sparrow-ui-library/src/context";
import ToastPopup from "../../../sparrow-ui-library/src/ui-components/toast-popup";


const Profile = observer(() => {
    const { showToastPopup, displayToastPopup}= useSparrowUIContext();
    const { currentUser, setUserData } = useStore();
    const { jwt } = useOnboarding();
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState({
        email: "",
        firstName: "",
        lastName: "",
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        phone_number: "",
        confirmChangeEmailCode: "",
        confirmChangePhoneCode: "",
    });
    const [personalInfo, setPersonalInfo] = useState([
        {
            title: "First name",
            value: "",
        },
        {
            title: "Last name",
            value: "",
        },
    ]);
    const [securityPreferences] = useState([
        {
            title: "Password",
            value: "••••••••••••••",
        },
    ]);
    const [contactInfo, setContactInfo] = useState([
        {
            title: "Email",
            value: "",
        },
        {
            title: "Phone number",
            value: "",
        },
    ]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [submitError, setSubmitError] = useState<string | null>(null);

    // Modals States
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [successDelete, setSuccessDelete] = useState(false);
    const [showPersonalInfoModal, setShowPersonalInfoModal] = useState(false);
    const [showSecurityPreferencesModal, setShowSecurityPreferencesModal] = useState(false);
    const [showContactInfoModal, setShowContactInfoModal] = useState(false);
    const [showContactConfirmInfoModal, setShowContactConfirmInfoModal] = useState(false);
    const [needVerifyPhone, setNeedVerifyPhone] = useState(false);
    const [needVerifyEmail, setNeedVerifyEmail] = useState(false);

    useEffect(() => {
        GetCurrentUserAndUpdateStore({ jwt: jwt as string, setUserData });
    }, []);

    useEffect(() => {
        if (currentUser) {
            const { email, firstName, lastName, phoneNumber } = currentUser;
            setForm({
                ...form,
                email: email,
                lastName: lastName,
                firstName: firstName,
                phone_number: phoneNumber
                    ? phoneNumber.includes("+")
                        ? phoneNumber.slice(1, phoneNumber.length)
                        : phoneNumber
                    : "",
            });
            setPersonalInfo([
                {
                    title: "First name",
                    value: firstName,
                },
                {
                    title: "Last name",
                    value: lastName,
                },
            ]);
            setContactInfo([
                {
                    title: "Email",
                    value: email,
                },
                {
                    title: "Phone number",
                    value: phoneNumber,
                },
            ]);
        }
    }, [currentUser]);

    const onFormChange = (key: string, value: any) => {
        setForm({ ...form, [key]: value });
    };

    const onBackToEditContactInfo = () => {
        setShowContactConfirmInfoModal(false);
        setShowContactInfoModal(true);
    };

    const onPersonalInfoUpdate = async () => {
        setIsLoading(true);
        // if success

        const { firstName, lastName } = form;
        const body = { firstName, lastName };

        try {
            if (!jwt) return

            await PutUpdateUserAttributes({ body, token: jwt });
            displayToastPopup("Your personal information has been updated successfully.", false);

            setUserData({
                ...(currentUser as IUserData),
                firstName: form.firstName,
                lastName: form.lastName,
            });


            setPersonalInfo([
                {
                    title: "First name",
                    value: form.firstName,
                },
                {
                    title: "Last name",
                    value: form.lastName,
                },
            ]);
        } catch (err: any) {
            displayToastPopup(err.userDescription as string, true);
        }

        setShowPersonalInfoModal(false);
        setIsLoading(false);
    };

    const onPasswordUpdate = async () => {
        setIsLoading(true);

        const { newPassword, currentPassword } = form;
        try {
            await Auth.currentAuthenticatedUser().then((user) => {
                return Auth.changePassword(user, currentPassword, newPassword).then(() => {
                    displayToastPopup("Your password has been changed successfully.", false);
                });
            });
        } catch (err: any) {
            displayToastPopup(err.userDescription as string, true);
        }

        setShowSecurityPreferencesModal(false);
        setIsLoading(false);
        setForm({ ...form, newPassword: "", currentPassword: "", confirmPassword: "" });
    };

    const onDeleteAccountHandler = () => {
        setIsLoading(true);
        // if success
        // Remove setTimeout when the endpoint is connected
        setTimeout(() => {
            setSuccessDelete(true);
            setIsLoading(false);
        }, 1000);
    };

    const onContactInfoUpdate = () => {
        // If the data have not been changed and do not require confirmation
        if (!needVerifyEmail && !needVerifyPhone) {
            setShowContactInfoModal(false);
        } else {
            setIsLoading(true);
            // if success
            // Remove setTimeout when the endpoint is connected
            setTimeout(() => {
                setShowContactInfoModal(false);
                setShowContactConfirmInfoModal(true);
                setIsLoading(false);
            }, 1000);
        }
    };

    const onContactInfoConfirm = () => {
        setIsLoading(true);
        if (needVerifyPhone) {
            // Do something
        }
        if (needVerifyEmail) {
            // Do something
        }
        setTimeout(() => {
            setShowContactConfirmInfoModal(false);
            setIsLoading(false);
        }, 1000);

        /**
         * Enable if Error
         */
        // setSubmitError('The code you entered is incorrect')
        /**
         */
    };

    return (
        <>
            <div className={"profile-wrapper"}>
                <Loading shown={isLoading} />
                <ProfileHead
                    onLogout={() => { }}
                    onDelete={() => {
                        setShowDeleteModal(true);
                    }}
                />
                <div className="profile-info">
                    <ProfileCardInfo
                        title={"Personal Information"}
                        onEdit={() => setShowPersonalInfoModal(true)}
                        info={personalInfo}
                    />
                    <ProfileCardInfo
                        title={"Security Preferences"}
                        onEdit={() => setShowSecurityPreferencesModal(true)}
                        info={securityPreferences}
                    />
                    <ProfileCardInfo
                        title={"Contact Information"}
                        info={contactInfo}
                    />
                </div>
                    {showToastPopup && <ToastPopup />}
            </div>
            {showDeleteModal && (
                <ProfileDeleteModal
                    isSuccessDelete={successDelete}
                    modalToggle={setShowDeleteModal}
                    form={form}
                    onSubmit={onDeleteAccountHandler}
                />
            )}
            {showPersonalInfoModal && (
                <ProfilePersonalInfoModal
                    modalToggle={setShowPersonalInfoModal}
                    form={form}
                    onSubmit={onPersonalInfoUpdate}
                    onChange={onFormChange}
                />
            )}
            {showSecurityPreferencesModal && (
                <ProfileSecurityPreferencesModal
                    modalToggle={setShowSecurityPreferencesModal}
                    form={form}
                    onSubmit={onPasswordUpdate}
                    onChange={onFormChange}
                />
            )}
            {showContactInfoModal && (
                <ProfileContactInfoModal
                    modalToggle={setShowContactInfoModal}
                    form={form}
                    onSubmit={onContactInfoUpdate}
                    onChange={onFormChange}
                    setNeedVerifyEmail={setNeedVerifyEmail}
                    setNeedVerifyPhone={setNeedVerifyPhone}
                />
            )}
            {showContactConfirmInfoModal && (
                <ProfileContactInfoConfirmModal
                    modalToggle={setShowContactConfirmInfoModal}
                    form={form}
                    onSubmit={onContactInfoConfirm}
                    needVerifyEmail={needVerifyEmail}
                    needVerifyPhone={needVerifyPhone}
                    back={onBackToEditContactInfo}
                    resend={onContactInfoUpdate}
                    submitError={submitError}
                    onChange={onFormChange}
                />
            )}
        </>
    );
});
export default Profile;
