import { CompleteStatusIcon } from "../../assets/icons/dashboard-status-icons";
import { useSparrowUIContext } from "../../context";
import "./toast-popup.styles.css";

const ErrIcon = (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12.5" cy="12" r="11" fill="#F32B32" />
        <path
            d="M12.5 15L10.8922 6.96116C10.6893 5.94659 11.4653 5 12.5 5C13.5347 5 14.3107 5.94659 14.1078 6.96116L12.5 15Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle cx="12.5" cy="18" r="1" fill="white" />
    </svg>
);


const ToastPopup = () => {
    const {toastPopupText, toastPopupStatus} = useSparrowUIContext();
    return <div className={`toastContainer ${toastPopupStatus ? "" : "err"}`}>
        <div className="toastContent">
            {toastPopupStatus ? <CompleteStatusIcon /> : ErrIcon}
            <span>{toastPopupText}</span>
        </div>
    </div>;
};

export default ToastPopup;