import React, { useState, useRef, useEffect } from "react";
import { TextBox } from "..";
import {
    AriaAttributesProps,
    BaseDropdownFieldProps,
    DropdownOption,
    FormFieldType,
    SetInputValue,
} from "../../../types/form-types";
import { Address } from "../../../constants/default-inputs";
import { getAddressSuggestions, getPlaceDetails } from "../../../utils/ui-library-utils";
import { formatToSentenceCase } from "../../../utils/general-utils";
import { useHandleDropdownBlur } from "../../../hooks/use-handle-dropdown-blur/useHandleDropdownBlur";
import { poweredByGoogleImage } from "../../../assets/images";
import { ChevronDownIcon } from "../../../assets/icons";
import { AddressInput } from "../../../types/sparrow-types";
import { getDropdownValue } from "./utils";
import "./Dropdown.css";

const noResultsReturnedValue = "No results returned";

export interface InputDropdownProps extends BaseDropdownFieldProps, AriaAttributesProps {
    type?: string;
    includeDropdownButton?: boolean;
    isAddressAutocomplete?: boolean;
    setInputValue: SetInputValue;
    setOptionClicked?: React.Dispatch<React.SetStateAction<boolean>>;
}

const InputDropdown = ({
    ariaLabel = "input field",
    includeDropdownButton = true,
    type = "text",
    isAddressAutocomplete = false,
    id,
    label,
    placeholder,
    inputValue = "",
    setInputValue,
    dropdownOptions,
    name,
    backendValue,
    setOptionClicked
}: InputDropdownProps) => {
    /*We distinguish between inputValue and dropdownValue because dropdown option labels and values may be different 
    e.g. for countries inputValues are country codes, where as dropdownValues are the full country names*/
    const [dropdownValue, setDropdownValue] = useState<string>(getDropdownValue(inputValue, dropdownOptions));
    
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [filteredDropdownOptions, setFilteredDropdownOptions] =
        useState<DropdownOption[]>(dropdownOptions);

    useEffect(() => {
        setFilteredDropdownOptions(dropdownOptions);
    }, [dropdownOptions]);
    // Track the index of the focused option
    const [focusedDropdownOptionIndex, setFocusedDropdownOptionIndex] = useState<number>(0);
    // Refs
    const inputRef = useRef<HTMLInputElement>(null);
    const dropdownRef = useRef<HTMLUListElement>(null);
    useHandleDropdownBlur(isDropdownOpen, setIsDropdownOpen, dropdownRef);

    // // Handler for when a user types in the input field
    const handleInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;

        if (isAddressAutocomplete) {
            // Use Google Maps API to get address suggestions if dropdown is being used for address autocomplete
            if (!value) {
                setIsDropdownOpen(false);
                setInputValue(name, value);
                return;
            }

            setInputValue(name, { ...Address(), fullAddress: value });
            setOptionClicked?.(false);
            await getAddressSuggestions(value, setFilteredDropdownOptions, setIsDropdownOpen);
            return;
        }

        setInputValue(name, value);
        setDropdownValue(value);

        // Reduce the set of dropdown options based on user's input
        const newDropdownOptions = dropdownOptions.filter((option) =>
            option.label.toLowerCase().startsWith(value.toLowerCase())
        );

        if (value && !newDropdownOptions.length) {
            setFilteredDropdownOptions([
                { value: noResultsReturnedValue, label: noResultsReturnedValue },
            ]);
        } else if (!value) {
            setFilteredDropdownOptions(dropdownOptions);
            // setIsDropdownOpen(true);
        } else {
            setFilteredDropdownOptions(newDropdownOptions);
        }

        // If the user clears the input field, close the dropdown otherwise open it
        // return setIsDropdownOpen(!value ? false : true);
    };

    // Handler for when a user clicks on an option
    const handleOptionClick = async (option: DropdownOption) => {
        if (isAddressAutocomplete) {
            const addressObject = await getPlaceDetails(option.place_id as string);
            if (!addressObject) return;
            setInputValue(name, { ...addressObject, fullAddress: "" });
            setOptionClicked?.(true);
            return;
        }

        if (option.value === noResultsReturnedValue) {
            return;
        }

        setInputValue(name, option.value);
        setDropdownValue(option.label);
        setIsDropdownOpen(false);
    };

    // Allows user to key down from the input field to the first dropdown option
    const handleInputFieldKeyboardNavigation = (event: React.KeyboardEvent<HTMLInputElement>) => {
        // If user hits 'Backspace' and there is a value in the input field of a certain length, clear the input field
        if (event.key === "Backspace") {
            /* If the dropdown component is used for Address Autocomplete, clear the dropdown options if the user clears the input field so that the results
            associated with the last input don't display on mouse click of TextBox*/
            if (isAddressAutocomplete && inputValue && (inputValue as string)?.length >= 3) {
                setInputValue(name, "");
                setIsDropdownOpen(false);
                setFocusedDropdownOptionIndex(0);
                return setFilteredDropdownOptions([]);
            }

            if (!isAddressAutocomplete && inputValue) {
                setInputValue(name, "");
                setDropdownValue("");
                setFilteredDropdownOptions(dropdownOptions);
                setIsDropdownOpen(true);
            }
        }

        if (event.key === "Enter") {
            event.preventDefault();
            if (filteredDropdownOptions[0].value === noResultsReturnedValue) return;
            
            if (isDropdownOpen && filteredDropdownOptions.length) {
                setInputValue(name, filteredDropdownOptions[0].value);
                setDropdownValue(filteredDropdownOptions[0].label);
                setIsDropdownOpen(!isDropdownOpen ? true : false);
            }
        }

        if (event.key === "ArrowDown" && isDropdownOpen && dropdownRef.current) {
            const firstDropdownOption = dropdownRef.current.firstChild as HTMLElement;
            firstDropdownOption.focus();
        }
    };
    // Allows users to navigate dropdown options with keyboard
    const handleDropdownKeyboardNavigation = (
        event: React.KeyboardEvent<HTMLLIElement>,
        index: number,
        inputRef: React.RefObject<HTMLInputElement>
    ) => {
        if (
            filteredDropdownOptions.length &&
            filteredDropdownOptions[0].value === noResultsReturnedValue
        )
            return;

        if (event.key === "ArrowDown") {
            event.preventDefault();
        }

        // If user hits 'Enter', then update the inputValue to the user's selection
        if (event.key === "Enter") {
            handleOptionClick(filteredDropdownOptions[index]);
        }

        if (event.key === "ArrowUp") {
            // If user hits up arrow and they're on the first option, bring focus to input field
            if (index === 0 && inputRef.current) {
                inputRef.current.focus();
                return;
            }
            // If user hits up arrow and they're not on the first dropdown option, bring focus to the previous dropdown option
            if (index > 0 && dropdownRef.current) {
                const previousOption = dropdownRef.current.childNodes[index - 1] as HTMLElement;
                previousOption.focus();
            }

            // If user hits down arrow and they're not on the last dropdown option, bring focus to the next dropdown option
        }

        if (event.key === "ArrowDown" && dropdownRef.current) {
            if (index < filteredDropdownOptions.length - 1) {
                const nextOption = dropdownRef.current.childNodes[index + 1] as HTMLElement;
                nextOption.focus();
                return;
            }
            const firstOption = dropdownRef.current.childNodes[0] as HTMLElement;
            firstOption.focus();
        }
    };

    const handleTextBoxClick = () => {
        if (!filteredDropdownOptions.length) return;

        if (isAddressAutocomplete) {
            if (!inputValue) return;
        }

        setIsDropdownOpen(true);
    };

    // Sets the index of the focused option to apply a "focused" class name for styling purposes
    const handleOptionFocus = (index: number) => {
        setFocusedDropdownOptionIndex(index);
    };

    return (
        <>
            <TextBox
                formFieldType={FormFieldType.TextBox}
                id={id}
                name={name}
                label={label}
                placeholder={formatToSentenceCase(placeholder)}
                inputValue={
                    isAddressAutocomplete ? (inputValue as AddressInput).fullAddress as string : dropdownValue as string
                }
                backendValue={backendValue}
                onChange={handleInputChange}
                onKeyDownHandler={handleInputFieldKeyboardNavigation}
                ref={inputRef}
                type={type}
                onClick={handleTextBoxClick}
            >
                {includeDropdownButton && (
                    <button
                        className={`ToggleButton ${isDropdownOpen ? "open" : ""}`}
                        onClick={(e) => {
                            e.preventDefault();
                            setIsDropdownOpen(!isDropdownOpen)}
                        }
                            
                        aria-expanded={isDropdownOpen}
                        aria-controls="Dropdown"
                    >
                        <ChevronDownIcon />
                    </button>
                )}
                {isDropdownOpen && filteredDropdownOptions.length ? (
                    <div className="DropdownContainer">
                        <ul
                            id={`${id}-dropdown`}
                            className={`Dropdown ${isAddressAutocomplete ? "ExtendHeight" : ""}`}
                            ref={dropdownRef}
                            role="listbox"
                            aria-label={ariaLabel}
                        >
                            {filteredDropdownOptions.map((option, index) => (
                                <li
                                    key={`${index}-list-option`}
                                    onClick={() => handleOptionClick(option)}
                                    onKeyDown={(event) =>
                                        handleDropdownKeyboardNavigation(event, index, inputRef)
                                    }
                                    onFocus={() => handleOptionFocus(index)}
                                    tabIndex={index}
                                    className={`DropdownOption ${
                                        focusedDropdownOptionIndex === index ? "focused" : ""
                                    }`}
                                    role="option"
                                    aria-selected={inputValue === option.value ? "true" : "false"}
                                >
                                    {option.label}
                                </li>
                            ))}
                            {isAddressAutocomplete && (
                                <li className="DropdownOption PoweredByGoogle">
                                    <img width={100} src={poweredByGoogleImage} alt="Google logo" />
                                </li>
                            )}
                        </ul>
                    </div>
                ) : null}
            </TextBox>
        </>
    );
};

export default InputDropdown;
