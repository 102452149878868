import { useNavigate } from "react-router-dom";
import { dashboardUrl } from "../../../../common/core/routes/navigation-urls";

interface IProfileHead {
    onDelete: () => void;
    onLogout: () => void;
}
const ProfileHead = ({ onDelete, onLogout }: IProfileHead) => {
    const navigate = useNavigate();
    return (
        <div className={"profile-head"}>
            <div className="row">
                <div className="col profile-head__back">
                    <button className={"back-btn"} onClick={() => navigate(dashboardUrl)}>
                        <span
                            className={"icon-arrow-right"}
                            style={{ transform: "rotate(-180deg)", display: "block" }}
                        />
                    </button>
                </div>
                <div className="col profile-head__title">
                    <h1 className={"h2"}>Account Settings</h1>
                    <p className={"muted"}>
                        View and update your account settings, including personal information and security preferences.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ProfileHead;
