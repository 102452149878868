import { observer } from "mobx-react";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useStore } from "../../../../common/core/providers/global.provider";
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider";
import { dashboardUrl, loginUrl } from "../../../../common/core/routes/navigation-urls";
import { GetPublic } from "../../../../common/core/services/clientApi";
import {
    offersTooltips,
    wordpressRateTable,
    wordpressLender,
} from "../../../../common/core/services/sparrow-api-endpoints";
import {
    OffersTable,
    WordpressRateTable,
} from "../../../../common/views/offers/components/table/table";
import {
    IOffer,
    IIncomeBasedOffer,
    LenderChecked,
} from "../../../../common/core/services/types/offers-api-types";
import {
    GetLenderLinks,
    GetRequestPrequalSubmissionResults,
} from "../../../../common/core/services/sparrow-api-handlers/offers-api-handlers";

import { OfferHeader } from "../../../../common/components/OfferHeader";

import { UnableToPrequalifyContainer } from "../../../../common/components/UnableToPrequalify/UnableToPrequalifyContainer";

import { DecisionPageDisclosure } from "./disclosure";
import { ApplyDirectlyContainer } from "../../../../common/components/ApplyDirectlyContainer/ApplyDirectlyContainer";
import { displayExpandedSearchModal } from "../../utils/offers-handlers";
import { getAndSetExpandedSearchMetadata } from "../../../../common/core/services/sparrow-api-helper-functions/expanded-search-helper-functions";
import { getWordPressTableNameFromFormData } from "../../../../common/utils/wordpress";

export type LenderToolTip = {
    lender_name: string;
    apr: { show_tooltip: boolean; tooltip: string };
};

export function convertRateLenderNameToDisclosureLenderName(lenderName: string) {
    const lenderMap = {
        "INvestEd Student Loan": "INvestEd",
        "INvestEd Refi Loan": "INvestEd",
        EDvestinU: "EdvestinU",
    };

    return lenderMap[lenderName] ?? lenderName;
}

/**
 * This function returns the correct lender disclosure for a given lender
 * @param lender
 * @returns
 */
export function getLenderDisclosure(lender: string, tooltips: LenderToolTip[]) {
    for (const tooltip of tooltips) {
        if (tooltip.lender_name === convertRateLenderNameToDisclosureLenderName(lender)) {
            return tooltip.apr.tooltip;
        }
    }
    return "";
}

export const OffersPage = observer(() => {
    const onboarding = useOnboarding();
    const navigate = useNavigate();
    const store = useStore();

    const { formData, jwt } = useOnboarding();

    const [offersData, setOffersData] = useState<(IOffer | IIncomeBasedOffer)[] | null>(null);

    const [pageLoaded, setPageLoaded] = useState(false);

    const [wordpressLenderContent, setWordpressLenderContent] = useState<WordpressRateTable[]>([]);

    const [lenderLinks, setLenderLinks] = useState<any>({});
    const [lendersRanPrequalificationOn, setLendersRanPrequalificationOn] = useState<string[]>([]);
    const [tooltips, setTooltips] = useState<LenderToolTip[]>([]);

    const [showedExpandedSearchModal, setShowedExpandedSearchModal] = useState(false);

    function getWordpressTableID() {
        return getWordPressTableNameFromFormData(formData);
    }

    /**
     * On component first render:
     */
    useEffect(() => {
        const getTooltipData = async () => {
            const tooltipData = await GetPublic(
                `${offersTooltips}?slug=${formData.type?.toLowerCase()}`
            );
            if (
                tooltipData &&
                tooltipData.length > 0 &&
                tooltipData[0].hasOwnProperty("acf") &&
                tooltipData[0].acf.hasOwnProperty("lenders")
            ) {
                setTooltips(tooltipData[0].acf.lenders);
            }
        };

        getTooltipData();
    }, []);

    useEffect(() => {
        // declare the data fetching function
        const fetchWordpressData = async () => {
            const table = await GetPublic(`${wordpressRateTable}${getWordpressTableID()}`);

            const ids: number[] = [];

            for (const item of table?.acf?.lender_table) {
                if ((item as any).select_lenders[0]) {
                    ids.push((item as any).select_lenders[0]);
                }
            }

            const lenderPromises: any[] = [];

            for (const id of ids) {
                lenderPromises.push(
                    GetPublic(`${wordpressLender}${id}`).then(
                        (wordpressLenderData) => wordpressLenderData.acf
                    )
                );
            }
            const lenders = await Promise.all(lenderPromises);

            setWordpressLenderContent(lenders);
        };

        fetchWordpressData();
    }, []);

    useEffect(() => {
        // Verify jwt exists
        if (!jwt) {
            navigate(loginUrl);
        }

        // Verify form data exists
        if (!formData.id) {
            navigate(dashboardUrl);
        }

        // get offer and disclosure data
        const getAllData = async () => {
            await Promise.all([getRequestResults(), getLenderLinks()]).catch();
        };
        getAndSetExpandedSearchMetadata(onboarding);
        getAllData();
        setPageLoaded(true);
    }, [onboarding.formData.offersQuizAnswer, onboarding.formData.cosigner?.offersQuizAnswer]);

    const getRequestResults = async () => {
        let standardOffers: IOffer[] = [];
        let ibOffers: IIncomeBasedOffer[] = [];

        let lendersChecked: LenderChecked[] = [];
        store.setLoading(true);
        try {
            const results = await GetRequestPrequalSubmissionResults(
                formData.id as string,
                jwt as string
            );
            if (!results) return null;
            lendersChecked = results.declineInformation.partnersChecked;
            onboarding.setPqRequestResults(results);
            standardOffers = results.offerInformation.offers;

            store.setLoading(false);
            if (!showedExpandedSearchModal) {
                displayExpandedSearchModal({ onboarding, store });
                setShowedExpandedSearchModal(true);
            }
            // ibOffers = results.offerInformation.incomeBasedOffers;
        } catch {
            navigate(dashboardUrl);
        }
        const allOffers = [...standardOffers, ...ibOffers];

        setLendersRanPrequalificationOn(
            lendersChecked.map((lender) => lender.lenderName.toLowerCase())
        );
        setOffersData(allOffers);
    };

    const getLenderLinks = async () => {
        try {
            const results = await GetLenderLinks(formData.id as string, jwt as string);
            if (!results) return null;

            const links = results.links;

            const newLinks = {};
            for (const lender of links) {
                newLinks[lender.lender.toLowerCase()] = lender.link;
            }

            setLenderLinks(newLinks);
        } catch {}
    };

    const setToPinnedOrBookmarked = async () => {
        await getRequestResults();
    };

    const applyDirectlyRef = useRef<HTMLInputElement>(null);

    return (
        <>
            {/* This is the offers table view */}

            <>
                <div className="offers">
                    {offersData && pageLoaded && <OfferHeader />}

                    {offersData && (
                        <>
                            <div className="table-offer-wrapper">
                                <OffersTable
                                    offersData={offersData}
                                    setToPinnedOrBookmarked={setToPinnedOrBookmarked}
                                    tooltips={tooltips}
                                />
                            </div>

                            {onboarding.pqRequestResults && (
                                <div style={{ paddingTop: "40px" }}>
                                    <UnableToPrequalifyContainer
                                        applyDirectlyRef={applyDirectlyRef}
                                    />
                                </div>
                            )}

                            {!!wordpressLenderContent.length && (
                                <ApplyDirectlyContainer
                                    applyDirectlyRef={applyDirectlyRef}
                                    wordpressLenderContent={wordpressLenderContent}
                                    lendersRanPrequalificationOn={lendersRanPrequalificationOn}
                                    lenderLinks={lenderLinks}
                                    tooltips={tooltips}
                                />
                            )}

                            <DecisionPageDisclosure />
                        </>
                    )}
                </div>
            </>
        </>
    );
});
