import React from "react";
import { FormContent } from "..";
import {
    DropdownFieldProps,
    FormFieldName,
    FormFieldProps,
    FormFieldType,
    FormProps,
    InputValue,
    NameFieldProps,
} from "../../../types/form-types";
import {
    getErrorMessageFunction,
    getSpecialFormattingFunction,
    handleDependentFields,
} from "../../../utils/ui-library-utils";
import { checkNameIsMiddleInitialOrSuffix as checkInputIsMiddleInitialOrSuffix } from "../../../utils/general-utils";
import { usePredefinedFormFields, useHandlePermanentAddressChanges} from "../../../hooks";
import { useSparrowUIFormsContext } from "../../../context";

import "./FormContainer.css";
interface FormContainerProps {
    className?: string;
    formInputs: FormProps;
    setForms: React.Dispatch<React.SetStateAction<FormProps[]>>;
    formIndex: number;
    forms: FormProps[];
}

const FormContainer: React.FC<FormContainerProps> = ({
    className = "",
    formInputs,
    formIndex,
    forms,
    setForms,
}) => {
    const {setActiveFormIndex} = useSparrowUIFormsContext();
    const handlePermanentAddressChanges = useHandlePermanentAddressChanges();

    const [formFields, setFormFields, setFetchNewAsyncDropdownOptions] = usePredefinedFormFields(
        formInputs.independentFields
    );

    const { header, dependentFields } = formInputs;

    const updateLocalFormFieldsAndForms = (inputs: {
        formFields: FormFieldProps[], 
        setFormFields: React.Dispatch<React.SetStateAction<FormFieldProps[]>>,
        forms: FormProps[],
        setForms: React.Dispatch<React.SetStateAction<FormProps[]>>
    }) => {
        const { formFields, setFormFields, forms, setForms } = inputs;

        const newFormFields = [...formFields];
        setFormFields(newFormFields);
        const newForms = [...forms];
        newForms[formIndex].independentFields = newFormFields;
        setForms(newForms);
    };

    // Handle changes for middle initial or suffix fields
    const handleMiddleInitialOrSuffixChange = (name: FormFieldName, value: InputValue) => {
        // Find the nameField that has the corresponding secondary field for the middle initial or suffix
        const nameField = formFields.find(
            (field) => (field as NameFieldProps).secondaryFieldId === name
        );

        // If the nameField is found, update its input value
        if (nameField) {
            (nameField as NameFieldProps).secondaryNameFieldInputValue = value;
        }

        // Update the formFields state
        updateLocalFormFieldsAndForms(
            { formFields, setFormFields, forms, setForms }
        )        
    };

    const updateFieldInputValue = (field: FormFieldProps, value: InputValue) => {
        // For input values that have additional fields populated only after a given input value e.g. country code, country prefix, etc. is inferred from phone number input
        if (typeof field.inputValue === "object" && typeof value === "object" && value !== null) {
            return { ...field.inputValue, ...value };
        }

        return value;
    };

    // Handle input changes for all form fields
    const handleInputChange = (name: FormFieldName, value: InputValue) => {        
        // Get the special formatting function for the input field, if input field requires special formatting
        setActiveFormIndex(formIndex);
        
        const specialFormattingFunction = getSpecialFormattingFunction(name);
        // Apply the special formatting function to the input value, if available

        const independentFieldValue = specialFormattingFunction
            ? specialFormattingFunction(value as string)
            : value;

        // Check if the input change comes from a middle initial or suffix field
        const isMiddleInitialOrSuffix = checkInputIsMiddleInitialOrSuffix(name);
        if (isMiddleInitialOrSuffix) {
            // Handle change for middle initial or suffix fields
            return handleMiddleInitialOrSuffixChange(name, independentFieldValue);
        }
        
        // For all other fields
        const independentField = formFields.find((field) => field.name === name);

        if (!independentField) return;
        // Update the input value for the field
        independentField.inputValue = updateFieldInputValue(independentField, independentFieldValue);
        // Special handling to match mailing address input value to permanent address input value if mailing address is same as permanent address
        if(independentField.name === FormFieldName.PermanentAddress) {
            handlePermanentAddressChanges();
        }
        
        const messageFunction = getErrorMessageFunction(name);
        // If the field has an error message function, then update the error message for the field
        if (messageFunction) {
            // Delayed error message for effect
            setTimeout(() => {
                independentField.errorMessage = messageFunction(independentFieldValue as string, name, formFields) as string;
                setFormFields([...formFields]);
            }, 1000);
        }

        if (dependentFields) {
            // Handle dependent fields to be shown for dropdown selections of independent fields
            handleDependentFields({
                independentField: independentField as DropdownFieldProps,
                independentFieldValue,
                independentFields: formFields,
                dependentFields,
            });

            // If the field is a dropdown, set the fetchNewAsyncDropdownOptions flag to true to fetch any new dropdown options by dependent dropdowns
            if (
                independentField.formFieldType === FormFieldType.InputDropdown ||
                independentField.formFieldType === FormFieldType.ReadonlyDropdown
            ) {
                setFetchNewAsyncDropdownOptions(true);
            }
        }

         // Update the formFields state
        updateLocalFormFieldsAndForms(
            { formFields, setFormFields, forms, setForms }
        )    
    };



    return (
        <div className={`FormContainer ${className}`}>
            {header && <h3 className="FormContainerHeader">{header}</h3>}
            <FormContent formFields={formFields} handleInputChange={handleInputChange} />
        </div>
    );
};

export default FormContainer;
