import React from "react";

export const InternationalLoanIcon = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z"
                fill="rgba(var(--borrowerPrimaryColor),1)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.8514 25.7663L30.1137 22.4957C29.5346 21.989 28.7162 21.8594 28.0089 22.1626L24.338 23.7358C23.7117 24.0042 23.2651 24.5731 23.1531 25.2453L22.8283 27.1938C22.6467 28.2833 23.3828 29.3138 24.4723 29.4954L26.8673 29.8945C27.2785 29.9631 27.658 30.1584 27.9527 30.4531L29.2828 31.7832C31.2761 30.2107 32.8607 28.1433 33.8514 25.7663Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M35.8146 15.0984C35.8139 15.1 35.8132 15.1015 35.8125 15.1031L35.8163 15.1002L35.8146 15.0984Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <path
                d="M28.332 11.7985V8.64882C28.332 7.93749 27.9542 7.27968 27.3398 6.92126L20.5664 2.97011C19.1245 2.129 17.4294 1.83086 15.7871 2.12947L14.8467 2.30044C11.2562 2.95327 8.15785 5.20451 6.42768 8.41769L5.4106 10.3065C4.0622 12.8107 3.57223 15.688 4.01581 18.4974L5.36966 27.0717C5.59078 28.4721 7.15846 29.2042 8.37418 28.4748L9.02769 28.0827C9.6301 27.7212 9.9987 27.0702 9.9987 26.3677V21.407C9.9987 21.0322 10.104 20.6649 10.3027 20.347L13.0737 15.9134C13.6714 14.9572 14.9409 14.6838 15.8791 15.3093L18.5296 17.0762C18.939 17.3492 19.4347 17.4616 19.9218 17.392L24.2146 16.7788C24.7183 16.7068 25.1756 16.4456 25.4935 16.0483L27.8938 13.0479C28.1775 12.6933 28.332 12.2527 28.332 11.7985Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
        </svg>
    );
};
