import { observer } from "mobx-react";
import { useEffect } from "react";
import { MenuContainer } from "../MenuContainer";
import { getPaaasClass, sendUtms } from "../../../../common/utils/global";
import { getSortedUserProgressRoadmaps } from "../../../../common/core/services/sparrow-api-helper-functions/prequalification-api-helper-functions";
import { RoadmapContainer } from "../RoadmapContainer";
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider";
import { UserDashboardHeader } from "../../components/UserDashboardHeader/UserDashboardHeader";
import { UserType } from "../../../../common/constants/defaultInputs";
import { Loading } from "../../../../common/components/loading";
import { useState } from "react";
import "./styles.scss";
import { getAndSetExpandedSearchMetadata } from "../../../../common/core/services/sparrow-api-helper-functions/expanded-search-helper-functions";
import { useSparrowUIContext, useSparrowUIFormsContext } from "../../../../sparrow-ui-library/src/context";
import useInitMainWrapperHeight from "../../../../hooks/use-init-main-wrapper-height";

export const DashboardContainer = observer(() => {
    const onboarding = useOnboarding();
    const {resetSparrowUIFormsContext} = useSparrowUIFormsContext();
    const {resetSparrowUIContext, displayToastPopup} = useSparrowUIContext();
    const [loading, setLoading] = useState(true);

    /*
    Reset variables related to active loan request or selection to default states
    Reset variables related to user inclusion to default states
    // */
    useEffect(() => {
        resetSparrowUIFormsContext();
        resetSparrowUIContext();
        onboarding.resetDashboardVariables();
    }, []);

    useEffect(() => {
        if (onboarding.jwt) {
            sendUtms(onboarding.jwt as string);
        }
    }, [onboarding.jwt]);

    useEffect(() => {
        /*
        This will: 
        1) get all loan requests for the user
        2) set the default active loan request to the latest loan request
        3) get the status of each step associated with the request's roadmap i.e., complete, incomplete, latest incomplete
        4) display the roadmap for the active loan request instead of the new request menu 
        */

        const setLoanRequests = async () => {
            if (!onboarding.jwt) return;
            try{
                setLoading(true);
                const {sortedRoadmaps, membershipElection} = await getSortedUserProgressRoadmaps(onboarding.jwt);
                onboarding.setPaaasMembershipElection(membershipElection);

                if (!sortedRoadmaps.length) {
                    onboarding.resetDashboardVariables();
                    onboarding.setLoanRequests([], false);
                    onboarding.setDashboardMainContent();
                    setLoading(false);
                    return;
                }
                /* 
                    Populate loan request history with all loan requests 
                    If we have an active loan request already, don't overwrite it
                    However, if its just default data (aka login), then overwrite it
                    */
                onboarding.setLoanRequests(sortedRoadmaps, !!onboarding.formData.id);
                onboarding.setDashboardMainContent();
            } catch (err: any) {
                displayToastPopup(err.userDescription as string, true);
            }
            setLoading(false);
        };
        getAndSetExpandedSearchMetadata(onboarding);
        setLoanRequests();
    }, []);

    const requestContainerBackgroundColor = () => {

        if (onboarding.displayNewRequestMenu) {
            return `${UserType.BORROWER} ${getPaaasClass()}`;
        }

        return `${onboarding.formData.userType} ${getPaaasClass()}`;
    };
    
    // This hook is used to set the height of the main wrapper. We need to wait until the popup content is rendered to set the height
    useInitMainWrapperHeight(!!document.querySelector(".rm-popup-content-container"));

    return (
        <>
            <Loading shown={loading} />
            <UserDashboardHeader />
            {/*We default to borrower for className if we have none so we don't have an empty background*/}
            <div className={`request-container ${requestContainerBackgroundColor()}`}>
                {/* Display New Request Menu */}
                {onboarding.displayNewRequestMenu && <MenuContainer />}
                {/* Display Roadmap */}
                {onboarding.displayRoadmap && <RoadmapContainer />}
            </div>
        </>
    );
});
