import removeDecimals from "./removeDecimals";

export type MoneyAmountInput = {
    display: string;
    amount: number;
    currency: string;
};

export function formatMoneyAmount(amount: number, fromCents = true, showDecimals = true): MoneyAmountInput {
    const formattedAmount = !fromCents ? amount * 100 : amount;
    const roundedAmount = Math.round((formattedAmount / 100 + Number.EPSILON) * 100) / 100;

    // Add padding zero to display if cents are a multiple of 10
    let display = "$" + new Intl.NumberFormat("en-US").format(roundedAmount);
    if (display.includes(".") && display.split(".")[1].length === 1) {
        display = display + "0";
    }



    return {
        amount: formattedAmount,
        display: showDecimals ? display : removeDecimals(display),
        currency: "USD",
    };
}

export function formatMoneyAmountAsPositive(amount: number, fromCents = true): MoneyAmountInput {
    return formatMoneyAmount(amount >= 0 ? amount : 0, fromCents);
}
