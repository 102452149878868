export enum OfferFilterTabs {
    Bookmarked = "Bookmarked",
    LoanType = "Loan type",
    RateType = "Rate type",
    RepaymentPlan = "Repayment plan",
    Lender = "Lender",
    Term = "Term",
    SAP = "Satisfactory academic progress",
    PastDueBalances = "Past due balance",
    Other = "Other",
}

export type OfferFiltersOptions = {
    sapOptions: string[];
    lenderOptions: string[];
    pastDueBalanceOptions: string[];
    termOptions: string[];
    paymentPlanOptions: string[];
    loanTypeOptions: string[];
    rateTypeOptions: string[];
    otherOptions?: string[];
};

export function getHeaderTooltipsForFilterDropdown(filter: OfferFilterTabs) {
    switch (filter) {
        case OfferFilterTabs.Term:
            return {
                title: "Term",
                content: "The term is how long you have to repay the loan.",
            };
        case OfferFilterTabs.SAP:
            return {
                title: "Satisfactory academic progress (SAP)",
                content:
                    "Some lenders require you to maintain satisfactory academic progress (SAP) to borrow. Your SAP status is determined by your school.",
            };
        case OfferFilterTabs.PastDueBalances:
            return {
                title: "Past due balances",
                content: "Some lenders will not allow borrowing to cover past due balances.",
            };
        default:
            return undefined;
    }
}
export function getOptionTooltipsForFilterDropdown(
    filter: OfferFilterTabs
): { [key: string]: { title: string; content: string } } | undefined {
    switch (filter) {
        case OfferFilterTabs.LoanType:
            return {
                "Standard (APR)": {
                    title: "Standard (APR)",
                    content:
                        "Standard loans are by far the most common and payments are based on an APR.",
                },
                "Alternative (Income based)": {
                    title: "Alternative (Income based)",
                    content:
                        "Alternative (income based) loans base your monthly payment on your income. Meaning, your payments fluctuate as your income changes.",
                },
            };
        case OfferFilterTabs.RateType:
            return {
                Fixed: {
                    title: "Fixed APR",
                    content:
                        "A fixed rate loan has the same interest rate for the entirety of the borrowing period.",
                },
                Variable: {
                    title: "Variable APR",
                    content:
                        "A variable rate loan has a starting interest rate that may go up or down over the borrowing period.",
                },
                "Income based": {
                    title: "Income based",
                    content:
                        "An income based loan uses your income as the basis for your interest rate.",
                },
            };
        case OfferFilterTabs.RepaymentPlan:
            return {
                Immediate: {
                    title: "Immediate",
                    content:
                        "Payments on both interest and principal begin the month after origination with an immediate repayment plan.",
                },
                "Interest only": {
                    title: "Interest only",
                    content:
                        "Interest payments begin the month after origination on an interest only repayment plan. Following graduation, you’ll begin making interest and principal payments on a monthly basis.",
                },
                "Fixed (partial interest)": {
                    title: "Fixed (partial interest)",
                    content:
                        "Fixed payments (i.e. $25 / month) begin the month after origination on a fixed repayment plan. Following graduation, you’ll begin making interest and principal payments on a monthly basis.",
                },
                "Fully deferred": {
                    title: "Fully deferred",
                    content:
                        "You will not make any payments during school on a fully deferred repayment plan. Following graduation, you’ll begin making interest and principal payments on a monthly basis.",
                },
            };
        default:
            return undefined;
    }
}
