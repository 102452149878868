import styles from './styles.module.scss'
import { observer } from 'mobx-react'
import { useOnboarding } from '../../../../common/core/providers/onboarding.provider'
import { formatDateAsMMMDDYYYY } from '../../../../common/utils/helper_functions/date-functions'
import { Button, ButtonTypes } from '../../../../common/components/buttons/button/Button'
import { ExpiredIconSvg } from '../../../../assets/icons/ExpiredIconSvg'

export const ExpiredContent = observer(() => {
    const onboarding = useOnboarding();

    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.box}>
                    <ExpiredIconSvg fillColor={onboarding.getActiveUserColorScheme()} />
                    <p className="p-md-lg-20-semi-bold">
                        Your previous request from {formatDateAsMMMDDYYYY(onboarding.formData.createdAt as number)} has expired!
                        Please start a new request with your most recent information.
                    </p>
                </div>
                <Button
                    userType={onboarding.formData.userType}
                    buttonType={ButtonTypes.PRIMARY}
                    showIcon={true}
                    onClick={() => {
                        onboarding.resetActiveLoanVariables();
                        onboarding.showDisplayNewRequestMenu();
                    }}
                >
                    Start a new request
                </Button>
            </div>
        </div>
    )
});
