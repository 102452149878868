import { observer } from 'mobx-react'
import { useOnboarding } from '../../core/providers/onboarding.provider'
import { FC } from 'react'
import "./styles.scss"


export enum IconTypeEnum {
    edit = "edit",
    delete = "delete",
}

interface IIconButton {
    iconType: IconTypeEnum
    onClick: () => void
    isReview?: boolean
}

export const IconButton: FC<IIconButton> = observer((props) => {
    const onboarding = useOnboarding();
    const userType = onboarding.formData.userType;
    const spanName = props.iconType === IconTypeEnum.edit ?  `icon-pen` : `icon-${props.iconType}`


    return (
        <button
            className={`${props.isReview ? `edit-icon-review ${userType}` : `card-btn card-${props.iconType}`}`}
            onClick={props.onClick}
        >
            <span className={spanName} />
        </button>
    )
});