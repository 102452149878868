import { observer } from "mobx-react";
import { useStore } from "../../../../common/core/providers/global.provider";
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider";
import { sentenceCase } from "../../../../common/utils/global";
import { UserType } from "../../../../common/constants/defaultInputs";
import { TrashIconSvg } from "../../../../assets/icons/TrashIconSvg";
import { DeleteLoanModal } from "../../../../common/components/modals/DeleteLoanModal";
import { useMediaQuery } from "react-responsive";
import removeDecimals from "../../../../common/utils/removeDecimals";
import { utcToFormattedDateString } from "../../../../common/utils/helper_functions/date-functions";
import eyeIcon from "../../../../assets/images/eye.svg";

const getRequestId = (id: string, showFullId: boolean) => {
    if(!id) return null;
    if (showFullId) return `ID ${id}`;
    return `ID ... ${id.slice(-12)}`;
}

interface RoadmapTopSectionProps {
    showFullId: boolean;
    setShowFullId: React.Dispatch<React.SetStateAction<boolean>>;
    showFullIdMobile: boolean;
    setShowFullIdMobile: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RoadmapTopSection: React.FC<RoadmapTopSectionProps> = observer((props) => {
    const onboarding = useOnboarding();
    const store = useStore();
    const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

    const handleRequestIdHover = () => props.setShowFullId(!props.showFullId);

    const onDelete = () => {
        store.setModal(() => <DeleteLoanModal id={onboarding.formData.id} />);
    };

    return (
        <>
            <div className="flex-container-1">
                <p className="loan-selection-type">
                    {onboarding.formData.loanSelectionType}
                </p>
                <div className="flex-container-1" style={{ gap: "24px" }}>
                    {!isMobile && <p className="request-id" onMouseEnter={handleRequestIdHover} onMouseLeave={handleRequestIdHover}>
                        {getRequestId(onboarding.formData.id as string, props.showFullId)}
                    </p>}
                    <p className="user-type">
                        {sentenceCase(onboarding.formData.userType as UserType)}
                    </p>
                    <TrashIconSvg 
                        className="ti-svg-roadmap"
                        onClick={onDelete}
                    />
                </div>
            </div>
            <div className="flex-container-1">
                <p className="loan-amount">
                    {removeDecimals(onboarding.formData.loanAmount.display)}
                </p>
                {!isMobile && <div className="flex-container-1" style={{ gap: "24px" }}>
                    <p className="dates">
                        <span>Created: </span>{" "}
                        {utcToFormattedDateString(onboarding.formData.createdAt as number)}
                    </p>
                    <p className="dates">
                        <span>Last activity: </span>
                        {utcToFormattedDateString(onboarding.formData.updatedAt as number)}
                    </p>
                </div>}
            </div>

            {/* Mobile version. TODO: Review and refactor if needed */}
            {isMobile && <div className="information-block">
                {onboarding.formData.id &&
                    <div className="wrap-information">
                        <p className="title-info">ID</p>
                        <div className="separate" />
                        <div className="wrap-value">
                            <p className="value-info">
                                {!props.showFullIdMobile ?
                                    `...${(onboarding.formData.id as string).slice(-12)}`
                                    : `${onboarding.formData.id}`}
                            </p>
                            <div className={`wrap-icon ${props.showFullIdMobile ? "active" : ""}`}>
                                <img
                                    src={eyeIcon}
                                    alt="eye"
                                    className={`eye-icon`}
                                    onClick={() => props.setShowFullIdMobile(!props.showFullIdMobile)}
                                />
                            </div>
                        </div>
                    </div>}
                {onboarding.formData.createdAt &&
                    <div className="wrap-information">
                        <p className="title-info">Created:</p>
                        <div className="separate" />
                        <p className="value-info">
                            {utcToFormattedDateString(onboarding.formData.createdAt as number)}
                        </p>
                    </div>}
                {onboarding.formData.updatedAt &&
                    <div className="wrap-information">
                        <p className="title-info">Last activity:</p>
                        <div className="separate" />
                        <p className="value-info">
                            {utcToFormattedDateString(onboarding.formData.updatedAt as number)}
                        </p>
                    </div>}
            </div>}
        </>
    )
});
