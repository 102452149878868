// TODO: refactor to use ui library
import { useOnboarding } from "../../../common/core/providers/onboarding.provider";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { InputText } from "../../../common/components/inputs/input_text/input_text";
import { EmailEXP } from "../../../common/utils/global";
import { useStore } from "../../../common/core/providers/global.provider";
import { Button } from "../../../common/components/buttons/button/Button";
import { ButtonTypes } from "../../../common/components/buttons/button/Button";
import { enumUserInclusionType } from "../../../common/store/onboarding";
import { ConfirmUserModal } from "../../../common/components/modals/User_Inclusion_Modals/ConfirmUserModal";
import { sentenceCase } from "../../../common/utils/global";
import { userInclusionSelectUrl } from "../../../common/core/routes/navigation-urls";
import { observer } from "mobx-react";
import { ButtonWrapper } from "../../../common/components/buttons/button/ButtonWrapper";

export const UserInclusionInformation = observer(() => {
    const navigate = useNavigate();
    const onboarding = useOnboarding();
    const store = useStore();
    const [form, setForm] = useState({ firstName: "", lastName: "", email: "", confirm: "" });
    const [errorMessage, setErrorMessage] = useState({
        firstName: "",
        lastName: "",
        email: "",
        confirm: "",
    });

    const isIncludingDirectly = onboarding.userInclusionType === enumUserInclusionType.DIRECT;

    const compareEmail = (value: string, key: string) => {
        if (!value.length || !value.match(EmailEXP)) {
            return setErrorMessage({ ...errorMessage, [key]: "Email is not valid" });
        }

        if (key === "confirm" && form.email.match(EmailEXP)) {
            if (value.toLowerCase() !== form.email.toLowerCase()) {
                return setErrorMessage({ ...errorMessage, [key]: "Emails do not match" });
            }

            if (value.toLowerCase() === store.currentUser?.email) {
                return setErrorMessage({
                    ...errorMessage,
                    [key]: `Your ${onboarding.getOppositeUserType()} cannot have the same email as you`,
                });
            }
        }

        if (key === "email" && form.confirm.match(EmailEXP)) {
            if (value.toLowerCase() !== form.confirm.toLowerCase()) {
                return setErrorMessage({ ...errorMessage, [key]: "Emails do not match" });
            }

            if (value.toLowerCase() === store.currentUser?.email) {
                return setErrorMessage({
                    ...errorMessage,
                    [key]: `Your ${onboarding.getOppositeUserType()} cannot have the same email as you`,
                });
            }
        }

        setErrorMessage({ ...errorMessage, [key]: "" });
    };

    const handleChange = (e) => {
        let { name, value } = e.target;
        if (name === "firstName" || name === "lastName") {
            setErrorMessage({
                ...errorMessage,
                [name]: value.length < 2 ? "Must be at least 2 characters" : "",
            });
        }
        if (name === "email" || name === "confirm") {
            compareEmail(value, name);
        }
        setForm({ ...form, [name]: value });
    };

    const backUrl = () => navigate(userInclusionSelectUrl);

    const continueButtonText = isIncludingDirectly ? "Include" : "Invite";

    const handleOnSubmit = () => {
        const emptyFields = Object.keys(form).filter((key) => !form[key]);
        const errorMessages = Object.keys(errorMessage).filter((key) => errorMessage[key]);

        if (emptyFields.length || errorMessages.length) {
            return;
        }

        store.setModal(
            () => <ConfirmUserModal form={form} continueButtonText={continueButtonText} />,
            false,
            true,
            "sm"
        );
    };

    return (
        <>
            <div className="FormContainer">
                <h3 className="h3" style={{marginBottom: "24px"}}>{`${sentenceCase(
                    onboarding.invitedUserType || ""
                )}’s Basic Information`}</h3>

                <div className="form-row">
                    <InputText
                        value={form.firstName}
                        name="firstName"
                        type="text"
                        col={6}
                        label="First name"
                        placeholder="First name"
                        error={errorMessage.firstName}
                        onChange={handleChange}
                        onClear={() => setForm({ ...form, firstName: "" })}
                    />
                    <InputText
                        value={form.lastName}
                        name="lastName"
                        type="text"
                        col={6}
                        label="Last name"
                        placeholder="Last name"
                        error={errorMessage.lastName}
                        onChange={handleChange}
                        onClear={() => setForm({ ...form, lastName: "" })}
                    />
                </div>
                <div className="form-row">
                    <InputText
                        value={form.email}
                        name="email"
                        type="text"
                        col={6}
                        label="Email"
                        placeholder="Email"
                        error={errorMessage.email}
                        onChange={handleChange}
                        onClear={() => setForm({ ...form, email: "" })}
                    />
                    <InputText
                        value={form.confirm}
                        name="confirm"
                        type="text"
                        col={6}
                        error={errorMessage.confirm}
                        label="Confirm email"
                        placeholder="Confirm email"
                        onChange={handleChange}
                        onClear={() => setForm({ ...form, confirm: "" })}
                    />
                </div>
            </div>
            <div style={{marginTop: "32px"}}/>
            <ButtonWrapper>
                <Button
                    id="previous-button"
                    onClick={backUrl}
                    buttonType={ButtonTypes.SECONDARY}
                    userType={onboarding.formData.userType}
                >
                    Back
                </Button>
                <Button
                    id="continue-button"
                    tabIndex={0}
                    onClick={handleOnSubmit}
                    showIcon={true}
                    buttonType={ButtonTypes.PRIMARY}
                    userType={onboarding.formData.userType}
                >
                    {continueButtonText}
                </Button>
            </ButtonWrapper>
        </>
    );
});
