import { observer } from "mobx-react";
import { useOnboarding } from "../../../../../../common/core/providers/onboarding.provider";
import { sentenceCase } from "../../../../../../common/utils/global";
import { IncludedUserStatus } from "../../../../../../sparrow-ui-library/src/types/sparrow-types";
import { getIncludedUserStatusSvg } from "../../../../../../assets/icons/svg-get-functions/getIncludedUserStatusSvg";
import "./included-user-status.styles.scss";
import { OnboardingStore } from "../../../../../../common/store/onboarding";

const getIncludedUserStatusText = (status: IncludedUserStatus) => {
    switch (status) {
        case IncludedUserStatus.DIRECT_STARTED:
            return "Sign up in progress";
        case IncludedUserStatus.INVITE_PENDING:
            return "Invitation sent";
        case IncludedUserStatus.INVITE_STARTED:
            return "Invitation in progress";
        case IncludedUserStatus.INVITE_REJECTED:
            return "Invitation declined";
        case IncludedUserStatus.CONFIRMED:
            return "Included";
    }
};

const getIncludedUserText = (onboarding: OnboardingStore) => {
    const oppositeUserType = onboarding.formData.isCreator ? onboarding.formData.includedUser?.userType : onboarding.getCreatorUserType();
    const includedUserStatusText = onboarding.formData.isCreator ? getIncludedUserStatusText(onboarding.formData.includedUser?.status as IncludedUserStatus) : "Included";

    return `${sentenceCase(oppositeUserType)} (${includedUserStatusText})`;
}

export const IncludedUserStatusComponent = observer(() => {
    const onboarding = useOnboarding();

    const status = onboarding.formData.isCreator ? onboarding.formData.includedUser?.status : IncludedUserStatus.CONFIRMED; 

    return (
        <>
            {onboarding.formData.includedUser && (
                <div>
                    <p className="incl-user-status">{getIncludedUserText(onboarding)}</p>
                    <div className="incl-user-box">
                        {getIncludedUserStatusSvg(status as IncludedUserStatus)}
                        <p>{onboarding.getIncludedUserName()}</p>
                    </div>
                </div>
            )}
        </>
    );
});
