import { Fragment } from "react";
import { useOnboarding } from "../../../../../core/providers/onboarding.provider";
import "./progress-bar.styles.scss";

interface ProgressBarProps {
    numSteps: number;
    activeStep: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ numSteps, activeStep }) => {
    const {formData} = useOnboarding();
    return (
        <div className="ProgressBar">
            {Array.from({ length: numSteps }, (_, i) => (
                /* Using Fragment here to avoid having to add a wrapper div. 
                Fragment is a built-in React component that allows us to group a list of children 
                without adding extra nodes to the DOM and supports the key attribute.*/
                <Fragment key={i}>
                    <div
                        className={`ProgressBarCircle ${formData.userType} ${i === activeStep ? "active" : ""} ${
                            i < activeStep ? "complete" : ""
                        }`}
                    />
                    {i !== numSteps - 1 && (
                        <div className={`ProgressBarLine ${formData.userType} ${i < activeStep ? "complete" : ""}`} />
                    )}
                </Fragment>
            ))}
        </div>
    );
};

