import "../../../style.scss";

interface ExpandProps {
    toggle: Function;
    progress: number;
}

export const OfferCardHeaderExpandButton: React.FunctionComponent<ExpandProps> = ({
    toggle,
    progress,
}) => {
    return (
        <button className={`expand-button ${progress && "active"}`} onClick={() => toggle()}>
            <span className="icon-arrow-bottom" />
        </button>
    );
};
