import { useOnboarding } from "../../core/providers/onboarding.provider";
import editIcon from "../../../assets/images/icons/edit.svg";
import "./style.scss";
import { userInclusionManagementUrl } from "../../core/routes/navigation-urls";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import React from "react";
import { getPaaasClass } from "../../utils/global";
import { UndergradLoanIcon } from "../../../sparrow-ui-library/src/assets/icons/menu-icons";

interface IOfferHeaderBorrowerDropdown {
    onExit?: any;
}
export const OfferHeaderBorrowerDropdown = ({ onExit }: IOfferHeaderBorrowerDropdown) => {
    const onboarding = useOnboarding();
    const { formData } = onboarding;

    const navigate = useNavigate();

    function getBorrowerName() {
        const firstName = formData.includedUser?.firstName ?? "";
        const lastName = formData.includedUser?.lastName ?? "";
        return firstName + " " + lastName;
    }

    function getBorrowerInitials() {
        const firstName = formData.includedUser?.firstName.substring(0, 1) ?? "";
        const lastName = formData.includedUser?.lastName.substring(0, 1) ?? "";
        return firstName + lastName;
    }

    function handleEdit() {
        return navigate(userInclusionManagementUrl);
    }
    // Media Constants
    const isLess767px = useMediaQuery({
        query: "(max-width: 767px)",
    });
    return (
        <>
            <div className={"header-borrower-dropdown-container"}>
                {isLess767px && (
                    <div className={"mobile-modal-header"}>
                        <div className="h4">Borrower Information</div>
                        <button
                            className="close-cosigner"
                            onClick={() => {
                                if (onExit) {
                                    onExit();
                                }
                            }}
                        >
                            <span className="icon-close" />
                        </button>
                    </div>
                )}
                <div className={"header-borrower-dropdown-identity-container"}>
                    <div className={"header-borrower-dropdown-initials"}>
                        {getBorrowerInitials()}
                    </div>
                    <div className={"header-borrower-dropdown-name-container"}>
                        <div className={"header-borrower-dropdown-name-top-row-container"}>
                            {getBorrowerName()}
                            {formData.isCreator && (
                                <img
                                    src={editIcon}
                                    alt="pen"
                                    onClick={() => handleEdit()}
                                    style={{ cursor: "pointer" }}
                                />
                            )}
                        </div>
                        <div className={"header-borrower-dropdown-name-bottom-row"}>
                            Your borrower
                        </div>
                    </div>
                </div>
                <div className={"header-borrower-dropdown-university-container"}>
                    <UndergradLoanIcon />
                    <p>{formData.degrees[0].university}</p>
                </div>
                <div className={"header-borrower-dropdown-education-container"}>
                    <div className={"header-borrower-dropdown-degree-type-container"}>
                        <div className={"header-borrower-dropdown-degree-type"}>
                            {formData.degrees[0].degreeType}
                        </div>
                        <div className={"header-borrower-dropdown-label"}>Degree type</div>
                    </div>
                    <div className={"header-borrower-dropdown-major-container"}>
                        <div className={`header-borrower-dropdown-major ${getPaaasClass()}`}>
                            {formData.degrees[0].major}
                        </div>
                        <div className={"header-borrower-dropdown-label"}>
                            Major or concentration
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
