import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";

import { useOnboarding } from "../../core/providers/onboarding.provider";
import { formatDate } from "../../utils/global";
import {
    LineChartDataset,
    StandardStudentLoanOfferGraphOutput,
} from "../../utils/graphing/utils/types";
import { QuestionTooltip } from "../tooltips";
import "./style.scss";
import moment from "moment";
import ReactDOMServer from "react-dom/server";
import { convertNumberOfDaysIntoXYearsYMonthsZDays } from "./RateChartUtilities";
import { isDefined } from "../../utils/helper_functions/validation-functions";
import { useMediaQuery } from "react-responsive";
import { observer } from "mobx-react";

export type LineChartData = {
    labels: string[];
    datasets: LineChartDataset[];
    toolTipDataPoints: any;
};

interface LineChartProps {
    offerData: any[];
    colors: string[];
}

const customTooltipLine = (context, cartTooltips) => {
    const { chart, tooltip } = context;
    let tooltipEl = chart.canvas.parentNode.parentNode.querySelector("div.custom_tooltip_line");

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
        tooltipEl.style.opacity = 0;
        return <></>;
    }

    // Remove old children
    if (tooltipEl.firstChild != null) {
        tooltipEl.firstChild.remove();
    }

    const chartId = tooltip.dataPoints[0].dataIndex;
    const lineId = tooltip.dataPoints[0].datasetIndex;
    const tooltips = cartTooltips[lineId];
    const date = moment(tooltip.dataPoints[0].raw.x).format("MM/DD/YYYY");

    const { offsetLeft: positionX, offsetTop: positionY, offsetWidth: width } = chart.canvas;

    const _tooltipPos =
        tooltip.caretX - tooltip.width / 2 >= 0 && tooltip.caretX + tooltip.width / 2 <= width
            ? tooltip.caretX
            : tooltip.caretX + tooltip.width / 2 >= width
            ? width - tooltip.width / 2
            : tooltip.width / 2;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + _tooltipPos + "px";
    tooltipEl.style.top = positionY + tooltip.caretY + "px";
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";
    tooltipEl.style.zIndex = 1010;

    const timeRemaining = convertNumberOfDaysIntoXYearsYMonthsZDays(
        tooltips.dataPoints[chartId].totalTimeRemaining
    );

    tooltipEl.innerHTML = ReactDOMServer.renderToStaticMarkup(
        <>
            <div className="l-row">
                <h5>
                    {isDefined(tooltips.dataPoints[chartId].age)
                        ? "When you’re " +
                          tooltips.dataPoints[chartId].age +
                          " years old on " +
                          date
                        : "On " + date}
                </h5>
            </div>
            <div className="l-row">
                <h5>Total Amount Remaining</h5>
                <div className="l-row-inner">
                    <div>Interest</div>
                    <div className="remaining-interest-value">
                        {tooltips.dataPoints[chartId].totalAmountRemaining.interest.display}
                    </div>
                </div>
                <div className="l-row-inner">
                    <div>Principal</div>
                    <div className="remaining-principal-value">
                        {tooltips.dataPoints[chartId].totalAmountRemaining.principal.display}
                    </div>
                </div>
            </div>
            <div className="l-row">
                <h5>Total Amount Paid</h5>
                <div className="l-row-inner">
                    <div>Interest</div>
                    <div className="remaining-interest-value">
                        {tooltips.dataPoints[chartId].totalPaid.interest.display}
                    </div>
                </div>
                <div className="l-row-inner">
                    <div>Principal</div>
                    <div className="remaining-principal-value">
                        {tooltips.dataPoints[chartId].totalPaid.principal.display}
                    </div>
                </div>
            </div>
            <div className="l-row">
                <div className="l-row-inner">
                    <h5>Payments After School</h5>
                    <div className="average-value">{tooltips.averageMonthlyPayment.display}</div>
                </div>
            </div>
            <div className="l-row">
                <div className="l-row-inner">
                    <h5>Total time remaining until loan paid in full</h5>
                    <div className="remaining-time-value">
                        {timeRemaining.years > 0 && (
                            <div>
                                {timeRemaining.years} year{timeRemaining.years > 1 ? "s" : ""}
                            </div>
                        )}
                        {timeRemaining.months > 0 && (
                            <div>
                                {timeRemaining.months} month{timeRemaining.months > 1 ? "s" : ""}
                            </div>
                        )}
                        {timeRemaining.days > 0 && (
                            <div>
                                {timeRemaining.days} day{timeRemaining.days > 1 ? "s" : ""}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export const LineChart: React.FunctionComponent<LineChartProps> = observer(
    ({ offerData, colors }) => {
        const { graphData } = useOnboarding();

        const [chartData, setChartData] = useState<LineChartData>({
            labels: [],
            datasets: [],
            toolTipDataPoints: [],
        });

        useEffect(() => {
            if (graphData) {
                convertGraphDataToLineChartData(offerData);
            }
        }, [graphData, colors]);

        const isLess767 = useMediaQuery({
            query: "(max-width: 767px)",
        });

        function convertGraphDataToLineChartData(data: any[]) {
            const standardGraphData = graphData!.standardGraphData;

            const lineChartData = {
                labels: obtainChartLabels(standardGraphData),
                datasets: processOffersForDatasets(standardGraphData),
                toolTipDataPoints: standardGraphData,
            };
            setChartData(lineChartData);
        }

        function obtainChartLabels(allData: StandardStudentLoanOfferGraphOutput[]) {
            const labels: string[] = [];
            for (const dataPoint of allData[0].dataPoints) {
                labels.push(formatDate(dataPoint.date));
            }
            return labels;
        }

        function processOffersForDatasets(allData: StandardStudentLoanOfferGraphOutput[]) {
            const dataSets: LineChartDataset[] = allData.map((data, index) => {
                return {
                    label: data.offerId,
                    data: getDatapointsForDataset(data),
                    borderColor: colors[index],
                    backgroundColor: colors[index],
                    tension: 0.4,
                    pointRadius: 0,
                    pointHoverRadius: 8,
                    pointHoverBackgroundColor: colors[index],
                    hoverBorderColor: "#FFF",
                    pointHoverBorderWidth: 4,
                };
            });

            return dataSets;
        }

        function getDatapointsForDataset(data: StandardStudentLoanOfferGraphOutput) {
            const dataPoints: { x: number; y: number }[] = [];
            for (const point of data.dataPoints) {
                dataPoints.push({
                    x: point.date * 1000,
                    y: point.totalAmountRemaining.principal.amount / 100,
                });
            }
            return dataPoints;
        }

        const lineOptions: any = {
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
                tooltip: {
                    enabled: false,
                    intersect: false,
                    mode: "nearest",
                    external: (tooltipModel) =>
                        customTooltipLine(tooltipModel, chartData.toolTipDataPoints),
                },
                legend: {
                    display: false,
                },
            },
            hover: {
                mode: "nearest",
                intersect: false,
            },
            scales: {
                y: {
                    ticks: {
                        count: 7,
                        padding: 10,
                        beginAtZero: true,
                        font: {
                            size: 12,
                        },
                        autoSkipPadding: 10,
                        color: "#ABB6BD",
                        // Include a dollar sign in the ticks
                        callback: (value, index, values) => {
                            return (
                                "$" +
                                new Intl.NumberFormat("en-GB", {
                                    maximumSignificantDigits: 2,
                                }).format(value)
                            );
                        },
                    },
                    grid: {
                        drawTicks: true,
                        tickMarkLength: 50,
                        borderDash: [5, 10],
                        color: "rgba(193,192,196,.25)",
                        drawBorder: false,
                        zeroLineColor: "rgba(193,192,196,.25)",
                        zeroLineBorderDash: [5, 10],
                        tickColor: "transparent",
                    },
                },
                x: {
                    ticks: {
                        padding: 10,
                        color: "#ABB6BD",
                        font: {
                            size: 12,
                        },
                        skip: true,
                        autoSkipPadding: 20,
                        callback: function (value, index, ticks) {
                            return "'" + value.slice(-2);
                        },
                        maxRotation: 0,
                        minRotation: 0,
                    },
                    type: "time",
                    time: {
                        displayFormats: {
                            minute: "HH:mm",
                            hour: "dd/MM HH:mm",
                            month: "yyyy",
                            quarter: "yyyy",
                            year: "yyyy",
                        },
                    },
                    grid: {
                        drawTicks: false,
                        display: false,
                        drawBorder: false,
                        tickColor: "transparent",
                    },
                },
            },
        };
        const linerChartTooltip =
            "<p>Hover your cursor over the graph below to see how your payments on a particular loan change over time.</p>" +
            "<p>This graph is called a loan amortization chart. Assuming you make minimum monthly payments, it shows you how much you’ve paid toward your loan’s interest and principal at any month in the future.</p>" +
            "<p>Star two loans with different terms to visualize how a payment plan determines the amount of time you’ll spend in debt.</p>";

        return (
            <div className="rate-chart-column">
                <div className="layout-row align-center bar-chart space-between">
                    <div className="chart-title">
                        Your payments over time
                        <QuestionTooltip
                            title="Your payments over time"
                            content={linerChartTooltip}
                        />
                    </div>
                </div>
                <div className="custom_tooltip_line" />
                <div className="line-chart-wrapper">
                    {offerData.length > 0 && (
                        <Line
                            // @ts-ignore
                            options={lineOptions}
                            data={chartData}
                            id={"line-chart_id"}
                            height={isLess767 ? 218 : 250}
                            redraw={false}
                        />
                    )}
                </div>
            </div>
        );
    }
);
