import { OfferCardBodyTooltip } from "./OfferCardBodyTooltip";
import { OfferDetail } from "../OfferCardUtilities";

import "../style.scss";

interface DetailsProps {
    lenderDisclosure: string;
    detail: OfferDetail;
}

export const OfferCardBodyDetail: React.FunctionComponent<DetailsProps> = ({
    lenderDisclosure,
    detail,
}) => {
    return (
        <div className="offer-card-detail" key={detail.title}>
            <div className="offer-card-detail-title-container">
                <p className="offer-card-detail-title">{detail.title}</p>
                <OfferCardBodyTooltip
                    content={detail.title === "APR" ? lenderDisclosure : detail.tooltip!}
                    id={detail.title!}
                />
            </div>
            <div className="offer-card-detail-dots"></div>
            <div className="offer-card-detail-value-container">
                <p className="offer-card-detail-extra-text">{detail.additionalValueText}</p>
                <p className="offer-card-detail-value">{detail.value}</p>
            </div>
        </div>
    );
};
