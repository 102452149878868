import { useOnboarding } from "../../../../../common/core/providers/onboarding.provider";
import { observer } from "mobx-react";
import { ProgressStatuses, RoadmapProgress } from "../../../../../common/models/request";
import { Button, ButtonTypes } from "../../../../../common/components/buttons/button/Button";
import { useNavigate } from "react-router-dom";
import { formatMoneyAmount } from "../../../../../common/utils/money";
import { PopupContentBottomExtension } from "../PopupContentBottomExtension";
import "./styles.scss";
import { prequalificationExpandedSearchUrl } from "../../../../../common/core/routes/navigation-urls";
import { UserType } from "../../../../../common/constants/defaultInputs";

export const CompareAndSelectPopup = observer(() => {
    const onboarding = useOnboarding();
    const navigate = useNavigate();

    const { offersData } =
        (onboarding.activePostPrequalRequestData as RoadmapProgress).compareAndSelect ?? {};

    const isPSL = onboarding.formData.type === "PSL";

    const getMoreOptionsContentAndNavigation = () => {
        if (onboarding.isEligibleAndHasNotSubmittedExpandedSearch()) {
            return {
                popupContent: `To ensure that we have exhaustively searched for as many pre-qualified rates as possible, we need some additional information ${
                    onboarding.formData.userType === UserType.COSIGNER ? "about your borrower" : ""
                }.`,
                buttonText: "Expand my search",
                onClick: () => {
                    navigate(prequalificationExpandedSearchUrl);
                },
            };
        }

        return {
            popupContent:
                "Unfortunately, we were unable to find pre-qualified rates based on the information you provided. Don't worry, you still have options!",
            buttonText: "Show me my options",
            onClick: () => {
                const url = onboarding.getPostSubmissionUrl();
                navigate(url);
            },
        };
    };

    return (
        // User has three or more offers, or one or more offers and is not eligible for expanded search or submitted
        <>
            {Number(offersData.numberOfOffers) >= 3 ||
            (Number(offersData.numberOfOffers) > 0 &&
                !onboarding.isEligibleAndHasNotSubmittedExpandedSearch()) ? (
                <div className="compareAndSelectPopup-wrapper">
                    <div className={"compareAndSelectPopup-item"}>
                        <p className="cs-pu-category">Pre-qualified rates</p>
                        <p className="cs-pu-value">{offersData.numberOfOffers}</p>
                    </div>
                    <div className={"compareAndSelectPopup-item"}>
                        <p className="cs-pu-category">Lowest fixed APR</p>
                        <p className="cs-pu-value">
                            {offersData.lowestFixedApr ? offersData.lowestFixedApr + "%" : "N/A"}
                        </p>
                    </div>
                    <div className={"compareAndSelectPopup-item"}>
                        <p className="cs-pu-category">Lowest variable APR</p>
                        <p className="cs-pu-value">
                            {offersData.lowestVariableApr
                                ? offersData.lowestVariableApr + "%"
                                : "N/A"}
                        </p>
                    </div>
                    {isPSL && (
                        <div className={"compareAndSelectPopup-item"}>
                            <p className="cs-pu-category">Lowest monthly payment in school</p>
                            <p className="cs-pu-value">
                                {
                                    formatMoneyAmount(
                                        Number(offersData.lowestMonthlyPaymentInSchool),
                                        false,
                                        false
                                    ).display
                                }
                            </p>
                        </div>
                    )}
                    <div className={"compareAndSelectPopup-item"}>
                        <p className="cs-pu-category">
                            {isPSL
                                ? "Lowest monthly payment after graduation"
                                : "Lowest monthly payment"}
                        </p>
                        <p className="cs-pu-value">
                            {
                                formatMoneyAmount(
                                    Number(offersData.lowestMonthlyPaymentAfterGrad),
                                    false,
                                    false
                                ).display
                            }
                        </p>
                    </div>
                    <div className={"compareAndSelectPopup-item"}>
                        <p className="cs-pu-category">Lowest total loan cost</p>
                        <p className="cs-pu-value">
                            {
                                formatMoneyAmount(
                                    Number(offersData.lowestTotalLoanCost),
                                    false,
                                    false
                                ).display
                            }
                        </p>
                    </div>
                </div>
            ) : (
                // if user has no offers or less than 3
                <div className="flex-container-1 options-container">
                    <p>{getMoreOptionsContentAndNavigation().popupContent}</p>
                    <Button
                        buttonType={ButtonTypes.PRIMARY}
                        userType={onboarding.formData.userType}
                        onClick={getMoreOptionsContentAndNavigation().onClick}
                    >
                        {getMoreOptionsContentAndNavigation().buttonText}
                    </Button>
                </div>
            )}
            <PopupContentBottomExtension progressStatus={ProgressStatuses.CompareAndSelect} />
        </>
    );
});
