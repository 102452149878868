interface IOfferCard {
    logo:  string
    lender: string
    termType: string | null
    apr: string
    term: string
    total: string
    isStared: string | null
}
const OfferCard = ({lender, apr, isStared, logo, term, termType, total}: IOfferCard) => {
    return <div className={'offer-card-interface'}>
        <div className="offer-card-interface-col offer-card-interface-col-info">
            <div className="offer-card-interface-col offer-card-interface-col-logo">
                <img src={logo} alt={''} width={36} height={36} />
            </div>
            <div>
                <div className={'name value'}>{lender}</div>
                {termType && <div className={'tern-type'}>{termType}</div>}
            </div>
        </div>
        <div className="offer-card-interface-col offer-card-interface-col-apr">
            <div className="offer-card-interface-title">APR <span className={'icon-dashboard-tooltip'}/></div>
            <div className="value">{apr}</div>
        </div>
        <div className="offer-card-interface-col offer-card-interface-col-term">
            <div className="offer-card-interface-title">Term</div>
            <div className="value">{term}</div>
        </div>
        <div className="offer-card-interface-col offer-card-interface-col-total">
            <div className="offer-card-interface-title">Total cost</div>
            <div className="value">{total}</div>
        </div>
        <div className="offer-card-interface-col offer-card-interface-col-btns">
            <div className="offer-card-interface-round">
                <span style={{color: `${isStared ? isStared : '#151B26'}`}} className={`${isStared ? 'icon-dashboard-analytics-fill' : 'icon-dashboard-analytics'}`}/>
            </div>
            <div className="offer-card-interface-round">
                <span className={`icon-dashboard-bookmark`}/>
            </div>
            <div className="offer-card-interface-primary">
                Apply
            </div>
            <div className="offer-card-interface-round offer-card-interface-round__toggle">
                <span className={`icon-arrow-bottom`}/>
            </div>
        </div>
    </div>
}
export default OfferCard