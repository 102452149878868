import "./styles.scss";

interface ILeftNavigationBackToMenuButton {
    setMenuExpandDropdown: Function;
    setMenuExpand: Function;
    menuExpand: boolean;
}

export const LeftNavigationBackToMenuButton = ({
    setMenuExpandDropdown,
    setMenuExpand,
    menuExpand,
}: ILeftNavigationBackToMenuButton) => {
    return (
        <div
            className={`left-navigation-replaced-menu-back ${menuExpand ? "active" : ""}`}
            onClick={() => {
                setMenuExpand(false);
                setMenuExpandDropdown(undefined);
            }}
        >
            <span className="icon-arrow-right back-arrow"></span>
            Back to menu
        </div>
    );
};
