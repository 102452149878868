"use client";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { observer } from "mobx-react";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import ReactGA from "react-ga";
import gLogo from "../../../assets/images/gmail.svg";
import { LinkButton } from "../../../common/components/buttons/link_button";
import { RegularButtonWithLoading } from "../../../common/components/buttons/regular_button/RegularButtonWithLoading";
import { CountryCode } from "../../../common/components/country_code";
import { InputText } from "../../../common/components/inputs/input_text/input_text";
import { capitalize } from "../../../common/core/helpers/events";
import { useStore } from "../../../common/core/providers/global.provider";
import {
    loginStepBorrower,
    privacyUrl,
    signUpStepTypeUrl,
    termsUrl,
} from "../../../common/core/routes/navigation-urls";
import { checkObjectProperty, getPossesiveCase } from "../../../common/utils/global";
import "./styles.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { confirmEmailBase } from "../../../common/core/routes/routing-urls";
import { isMarketplaceEnv } from "../../../common/utils/config";
import { AuthHandler } from "../../../common/core/auth";
import { useOnboarding } from "../../../common/core/providers/onboarding.provider";

export const CreateAccountSparrow: FC = observer(() => {
    const { registerData, saveToStore, reset, setAuthSnackError } = useStore();
    const { setRouteState, paaasTopLevelFields, paaasCustomization, companyName } = useOnboarding();
    const [pswLengthValid, setPswLengthValid] = useState(false);
    const [pswNumberIncludeValid, setPswNumberIncludeValid] = useState(false);
    const [pswSymbolIncludeValid, setPswSymbolIncludeValid] = useState(false);
    const [pswNameIncludeValid, setPswNameIncludeValid] = useState(false);
    const [pswPasswordIncludeValid, setPswPasswordIncludeValid] = useState(false);
    const [pswLetterCaseValid, setPswLetterCaseValid] = useState(false);
    const [phoneValid, setPhoneValid] = useState("");
    const [firstPageLoad, setFirstPageLoad] = useState(true);
    const [showPsw, setShowPsw] = useState(false);
    const [loading, setLoading] = useState(false);
    const [componentMounted, setComponentMounted] = useState(false);

    const [form, setForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone_number: "",
        password: "",
    });
    const [valid, setIsValid] = useState(false);
    const navigate = useNavigate();
    const routePathname = useLocation().pathname;

    const RegEXP =
        '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
    const reg = "^[a-zA-Z]*$";

    const validationMsg = {
        firstName:
            form.firstName.length > 0 && form.firstName.length < 2
                ? "The minimum length must be 2 characters"
                : null,
        lastName:
            form.lastName.length > 0 && form.lastName.length < 2
                ? "The minimum length must be 2 characters"
                : null,
        email: form.email.length > 0 && !form.email.match(RegEXP) ? "Email is not valid" : null,
        phone_number: form.phone_number.length === 11 ? null : "",
        password: null,
    };

    useEffect(() => {
        setComponentMounted(true);
    }, []);

    const onValueChange = (value: string, key: any) => {
        if (key === "firstName" || key === "lastName") {
            if (value.match(reg) == null) return;
            passwordChecker(form.password);
            setForm({ ...form, [key]: capitalize(value) });
        } else {
            if (key === "password") {
                setFirstPageLoad(false);
                passwordChecker(value);
            }
            // React phone component will run this function on first render so we include this flag to prevent a false error message
            if (key === "phone_number" && componentMounted) {
                setPhoneValid(value.length > 0 && value.length < 11 ? "Invalid phone number" : "");
            }
            setForm({ ...form, [key]: value });
        }
    };

    const passwordChecker = (password: string) => {
        setPswLengthValid(password.length > 11);
        setPswLetterCaseValid(!!password.match("[A-Z]") && !!password.match("[a-z]")); // For Uppercase/Lowercase Alphabet
        setPswNumberIncludeValid(!!password.match("[0-9]"));
        setPswNameIncludeValid(
            password.length > 0 &&
                form.password.length > 0 &&
                form.firstName.length > 0 &&
                form.lastName.length > 0 &&
                !password.toLowerCase().includes(form.firstName.toLowerCase()) &&
                !password.toLowerCase().includes(form.lastName.toLowerCase())
        );
        setPswPasswordIncludeValid(
            !password.includes("password") && !password.includes("Password") && password.length > 0
        );
        setPswSymbolIncludeValid(!!password.match("[!@#$%^&*(),.?\":{}|<>№;~`'+-/=\\[\\]\\\\_]"));
    };

    const passwordError = useMemo(() => {
        if (firstPageLoad) return "";
        if (!pswLengthValid) return "Password must contain twelve or more characters";
        if (!pswLetterCaseValid) return "Password must contain 1 uppercase and 1 lowercase";
        if (!pswNameIncludeValid) return "Password cannot contain your first or last name";
        if (!pswNumberIncludeValid) return "Password must include one number";
        if (!pswPasswordIncludeValid) return "Password cannot contain the word 'password'";
        if (!pswSymbolIncludeValid) return "Password must include one symbol";
        return "";
    }, [
        pswLengthValid,
        pswLetterCaseValid,
        pswNameIncludeValid,
        pswNumberIncludeValid,
        pswPasswordIncludeValid,
        pswSymbolIncludeValid,
    ]);

    const checkValidation = () => {
        const errArr = Object.entries(form).filter(([key, value]) => {
            // @ts-ignore
            return validationMsg[key] !== null || value.length === 0;
        });
        setIsValid(
            !errArr.length &&
                pswLengthValid &&
                pswLetterCaseValid &&
                pswNameIncludeValid &&
                pswNumberIncludeValid &&
                pswPasswordIncludeValid &&
                pswSymbolIncludeValid
        );
    };

    const onSubmit = useCallback(() => {
        setLoading(true);
        AuthHandler.signUp({
            username: form.email.toLowerCase(),
            password: form.password,
            attributes: {
                phone_number: "+" + form.phone_number,
                name: form.firstName,
                family_name: form.lastName,
            },
            partnerId: paaasCustomization?.partnerId,
        })
            .then((e) => {
                ReactGA.set({ userId: e.userSub });
                if (checkObjectProperty(window, "dataLayer")) {
                    (window as any).dataLayer.push({
                        userId: e.userSub,
                        event: "preregistration",
                    });
                }
                saveToStore(form);
                setLoading(false);
                setRouteState({ email: form.email });
                navigate(confirmEmailBase);
            })
            .catch((e) => {
                setLoading(false);
                if (e.message === "An account with the given email already exists.") {
                    setAuthSnackError(
                        `An account with the email entered already exists. Want to <span style="text-decoration: underline;">sign in</span>?`,
                        "error",
                        loginStepBorrower
                    );
                } else {
                    setAuthSnackError(e.message, "error");
                }
            });
    }, [form]);

    const onLeave = useCallback(() => {
        reset();
    }, []);

    useEffect(() => {
        if (registerData != null) {
            setForm(registerData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        checkValidation();
        passwordChecker(form.password);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pswLengthValid,
        pswNumberIncludeValid,
        pswSymbolIncludeValid,
        pswNameIncludeValid,
        pswPasswordIncludeValid,
        pswLetterCaseValid,
        form,
    ]);

    useEffect(() => {
        const onSubmitEnterKey = async (e: KeyboardEvent) => {
            if (valid && e.key === "Enter" && routePathname === "/sign-up") {
                await onSubmit();
            }
        };
        document.addEventListener("keypress", onSubmitEnterKey);
        return () => {
            document.removeEventListener("keypress", onSubmitEnterKey);
        };
    }, [valid, routePathname]);

    const privacyText = () => {
        const constantProps = {
            target: "_blank",
            underline: false,
        };

        return (
            <span>
                By proceeding, I agree to {getPossesiveCase(companyName)}
                {paaasTopLevelFields &&
                    <>
                        <LinkButton {...constantProps} link={paaasTopLevelFields.termsOfServiceUrl}>Terms of Service</LinkButton> and
                        <LinkButton {...constantProps} link={paaasTopLevelFields.privacyPolicyUrl}>Privacy Policy</LinkButton>
                        , as well as their partner, Sparrow Labs, Inc.'s
                    </>}
                <LinkButton {...constantProps} link={termsUrl}>Terms of Service</LinkButton> and
                <LinkButton {...constantProps} link={privacyUrl}>Privacy Policy</LinkButton>.
            </span>
        );
    }

    return (
        <div className="sign-up_borrower">
            <div className="step-form-sign-up">
                {isMarketplaceEnv() && (
                    <a className="step-form-overhead" href={signUpStepTypeUrl}>
                        <span className="icon-arrow-right" />
                        Change account type
                    </a>
                )}
                <div className="step-form-header">
                    <h2 className="h2">Create an account</h2>
                    <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
                        <div className="subtitle">Already have an account?</div>{" "}
                        <LinkButton link={loginStepBorrower} underline={true} onLeave={onLeave}>
                            Sign in
                        </LinkButton>
                    </div>
                </div>
                <div className="step-form-body">
                    <div className="step-form-block">
                        <div className="form-wrapper">
                            <div className="form-row">
                                <InputText
                                    value={form.firstName}
                                    name="firstName"
                                    type="text"
                                    label="First name"
                                    placeholder="Enter first name"
                                    error={validationMsg.firstName}
                                    onChange={(e: any) =>
                                        onValueChange(e.target.value, e.target.name)
                                    }
                                    onClear={() => onValueChange("", "firstName")}
                                    maxLength={60}
                                />
                            </div>
                            <div className="form-row">
                                <InputText
                                    value={form.lastName}
                                    name="lastName"
                                    type="text"
                                    label="Last name"
                                    placeholder="Enter last name"
                                    error={validationMsg.lastName}
                                    onChange={(e: any) =>
                                        onValueChange(e.target.value, e.target.name)
                                    }
                                    onClear={() => onValueChange("", "lastName")}
                                    maxLength={60}
                                />
                            </div>
                        </div>
                        <div className="form-wrapper">
                            <div className="form-row">
                                <InputText
                                    value={form.email}
                                    name="email"
                                    type="email"
                                    label="Email"
                                    placeholder="Enter email"
                                    error={validationMsg.email}
                                    onChange={(e: any) =>
                                        onValueChange(e.target.value, e.target.name)
                                    }
                                    onClear={() => onValueChange("", "email")}
                                />
                            </div>
                            <div className="form-row">
                                <div className="form-col-12 form-col">
                                    <CountryCode
                                        name="phoneNumber"
                                        value={form.phone_number}
                                        onChange={(value: any) =>
                                            onValueChange(value, "phone_number")
                                        }
                                        error={phoneValid}
                                        onClear={() => onValueChange("1", "phone_number")}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <InputText
                                value={form.password}
                                name="password"
                                type={showPsw ? "text" : "password"}
                                label="Password"
                                placeholder="Enter password"
                                onChange={(e: any) => onValueChange(e.target.value, e.target.name)}
                                error={passwordError}
                                passwordType={true}
                                onClear={() => onValueChange("", "password")}
                                passwordShown={showPsw}
                                showPassword={() => setShowPsw(!showPsw)}
                            />
                        </div>
                        <div className="form-row confirm-checkbox">
                            {/* <CheckboxSimple id='agreement' isChecked={agreeCheckbox} handleChange={(e)=> setAgreeCheckbox(e.target.checked)}/> */}
                            <div className="privacy-text">
                                {privacyText()}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="step-form-footer">
                    <div className="button-group button-group-privacy">
                        <RegularButtonWithLoading
                            loading={loading}
                            id="registration-submission-link"
                            onSubmit={() => onSubmit()}
                            disabled={!valid}
                        >
                            Continue
                        </RegularButtonWithLoading>
                    </div>
                    {isMarketplaceEnv() && (
                        <div className="button-group text-center">
                            <button
                                onClick={() =>
                                    AuthHandler.federatedSignIn(
                                        CognitoHostedUIIdentityProvider.Google
                                    )
                                }
                                className="sso-button"
                            >
                                <img src={gLogo} alt={""} />
                                Sign in via Gmail
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
});
