import { LendersHeader } from "../LendersHeader";
import redLock from "../../../../assets/images/unable-to-prequalify/red-lock.svg";
import { observer } from "mobx-react";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import "./styles.scss";

// TODO: Adding carousel buttons to scroll through declined lenders and appear only when there is a need to scroll in either direction

const getAndSortDeclinedLenders = (
    partnersChecked: { lenderName: string; lenderImageUrl: string }[] | undefined
) => {
    return partnersChecked?.slice()?.sort((a, b) => a.lenderName.localeCompare(b.lenderName));
};

export const DeclinedLendersContainer = observer(() => {
    const onboarding = useOnboarding();
    const partnersChecked = onboarding.pqRequestResults?.results.declinedLenders;
    const declinedLenders = getAndSortDeclinedLenders(partnersChecked);

    return (
        <div className="declined-lenders-container">
            <LendersHeader
                image={redLock}
                header="We were unable to pre-qualify you with these lenders"
                subheader="Take a look at some of the reasons we were unable to pre-qualify you and potential actions you can take below."
            />
            <div className="declined-lender-section">
                {declinedLenders?.map((lender) => {
                    return (
                        <div className="declined-lender-box" key={lender.lenderName}>
                            <img src={lender.lenderImageUrl} alt={lender.lenderName} />
                            {lender.lenderName}
                        </div>
                    );
                })}
            </div>
        </div>
    );
});
