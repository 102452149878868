import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { getFormsConfig } from "./forms-config";
import {
    FormFieldName,
    FormFieldProps,
    RadioButtonFormProps,
} from "../../../sparrow-ui-library/src/types/form-types";
import { FormWrapper } from "../../../sparrow-ui-library/src/ui-components/form-components";
import { handleSubmission } from "../../../sparrow-ui-library/src/utils/ui-library-utils";
import { useOnboarding } from "../../../common/core/providers/onboarding.provider";
import { PrequalUrlsEnum } from "../../../common/core/global_routing/front-end-urls";
import { NavigationFooter } from "../components/NavigationFooter";
import { UserType } from "../../../common/constants/defaultInputs";

import { Citizenship } from "../../../sparrow-ui-library/src/constants/default-inputs/citizenship";
import { useSparrowUIFormsContext, useSparrowUIContext } from "../../../sparrow-ui-library/src/context";
import { PrequalificationRequestInput } from "../../../sparrow-ui-library/src/types/sparrow-types";

export const PqCitizenship = observer(({ pathName }: { pathName: string }) => {
    const [componentMounted, setComponentMounted] = useState(false);
    // Store variables and methods
    const onboarding = useOnboarding();
    
    const { displayToastPopup } = useSparrowUIContext();

    const { applicableFormData } = onboarding.getApplicableFormData();

    const { forms, setForms } = useSparrowUIFormsContext();


    /* This is a citizenship status only useEffect that determines whether to use short steps or not based on a non-US citizen's SSN election, 
    in order to trigger an instant re-render in the progress bar. It's current definition depends on the structure of the form not changing, excluding
    the order of the independent fields where SSN election is made i.e., the ordering of the independent fields can change and this useEffect will still behave as expected*/
    useEffect(() => {
        // Need to clear out the prior forms before running the rest of the code, otherwise radioButtons may be undefined
        if (!componentMounted) {
            if (onboarding.asyncDropdownValues) setComponentMounted(true);
            // Page specific forms
            const formsConfig = getFormsConfig({
                applicableFormData: applicableFormData as PrequalificationRequestInput,
                getApplicableUserText: onboarding.getApplicableUserText,
                asyncDropdownValues: onboarding.asyncDropdownValues,
            });

            return setForms(formsConfig.forms);
        }
        // ALWAYS ASSUMES - that the first form is a RadioButtonForm
        const radioButtons = forms[0] as RadioButtonFormProps;
        // ALWAYS ASSUMES - that the second option in the radio button form is the one with the independent fields
        const independentFields = radioButtons.radioButtonOptions[1]
            .independentFields as FormFieldProps[];
        // We always ask for SSN if the user selects they're not a citizen, therefore we can assume the field is always present
        const hasSsnField = independentFields.find(
            (field) => field.name === FormFieldName.CitizenshipStatusHasSSN
        ) as FormFieldProps;

        if (typeof hasSsnField.inputValue === "boolean") {
            onboarding.setUseShortSteps(!hasSsnField.inputValue);
        }
    }, [componentMounted, forms]);

    // Page routing
    const navigate = useNavigate();
    const { prevStep, nextStep } = onboarding.getStepRouting(pathName);

    // Submit handler
    const handleSubmit = () =>
        handleSubmission(
            {
                forms,
                setForms,
                checkEditsMadePostPrequalSubmission: onboarding.checkEditsMadePostPrequalSubmission,
            },
            async (formattedBackendForms) => {
                //Take formatted formData and send to backend
                try {
                    const response = await onboarding.prequalRequestHandler({
                        formattedBackendForms,
                        pathName: PrequalUrlsEnum.CITIZENSHIP,
                    });
                    if (!response) return;
                    // If successful, navigate to next step
                    navigate(nextStep);
                } catch (err: any) {
                    onboarding.setPqLoader(false);
                    displayToastPopup(err.userDescription as string, true);
                }
            }
        );



    const getNewFormsInstance = () => {
        (applicableFormData as PrequalificationRequestInput).citizenshipStatus = Citizenship();
        const formsConfig = getFormsConfig({
            applicableFormData: applicableFormData as PrequalificationRequestInput,
                getApplicableUserText: onboarding.getApplicableUserText,
                asyncDropdownValues: onboarding.asyncDropdownValues,
        });
        return formsConfig.forms as RadioButtonFormProps[];
    };

    return (
        <>
            {!!forms.length && <FormWrapper forms={forms} setForms={setForms} getNewFormsInstance={getNewFormsInstance} />}
            <div style={{ marginTop: "32px" }}>
                <NavigationFooter
                    userType={onboarding.formData.userType as UserType}
                    steps={{ prevStep, nextStep }}
                    handleSubmit={handleSubmit}
                />
            </div>
        </>
    );
});
