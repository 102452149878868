import { Scrollbar } from "react-scrollbars-custom";
import bookmarkFilterDefault from "../../../assets/images/icons/bookmarkFilterDefault.svg";
import checkboxIcon from "../../../assets/images/icons/checkbox.svg";
import { OfferFilterTab } from "./OfferFilterTab";
import React from "react";
import { Button } from "../buttons/button/Button";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { ButtonTypes } from "../buttons/button/Button";

interface IMobileFilter {
    showMobileFilter: boolean;
    setShowMobileFilter: () => void;
    hasBookmarked: boolean;
    bookmarkActive: boolean;
    bookmarkHandler: () => void;
    filterConfig: any;
    hasFilters: (name: any) => any;
    getCheckboxMapping: any;
    filterOptions: any;
    updateFilterOptions: any;
    closeAllFilters: any;
    openSingleFilter: any;
    positionRef: any;
    clearFilters: () => void;
    openMapping: any;
}
const MobileFilter = ({
    setShowMobileFilter,
    showMobileFilter,
    hasBookmarked,
    bookmarkActive,
    bookmarkHandler,
    filterConfig,
    hasFilters,
    getCheckboxMapping,
    filterOptions,
    updateFilterOptions,
    closeAllFilters,
    openSingleFilter,
    positionRef,
    clearFilters,
    openMapping,
}: IMobileFilter) => {
    const { formData } = useOnboarding();
    return (
        <div className={`mobile-filter ${showMobileFilter ? "active" : ""}`}>
            <div className="mobile-filter-head">
                <button className={"close-filter"} onClick={() => setShowMobileFilter()}>
                    <span className={"icon-close"} />
                </button>
                <div className="h4">Filters</div>
            </div>
            <div className="mobile-filter-body">
                <Scrollbar
                    noScrollX={true}
                    style={{ width: "100%", height: "calc(var(--viewport-height) - 165px)" }}
                >
                    <div className="mobile-filter-content">
                        {hasBookmarked && (
                            <div className="mobile-filter-block">
                                <div className="mobile-filter-block__body">
                                    <div className="mobile-filters-items">
                                        <div className="box">
                                            <div className="offer-filter-dropdown-select-container">
                                                <div className="offer-filter-dropdown-select-title-container">
                                                    <div className="offer-filter-dropdown-select-title">
                                                        <div className="filter-img">
                                                            <img
                                                                src={bookmarkFilterDefault}
                                                                alt="Repayment plan"
                                                            />
                                                        </div>
                                                        Bookmarked
                                                        <div
                                                            className={`offer-filter-dropdown-select-footer-text ${
                                                                bookmarkActive ? "active" : ""
                                                            }`}
                                                            onClick={bookmarkHandler}
                                                        >
                                                            Clear
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    className="dropdown-checkbox"
                                                    onClick={bookmarkHandler}
                                                >
                                                    {bookmarkActive ? (
                                                        <img src={checkboxIcon} alt="selected" />
                                                    ) : (
                                                        <div
                                                            className={`legend_select_icon_multi ${
                                                                bookmarkActive ? "active" : ""
                                                            }`}
                                                        />
                                                    )}
                                                    <p
                                                        className={`checkbox-label-scrollable ${
                                                            bookmarkActive ? `selected ${formData.userType}` : ""
                                                        }`}
                                                    >
                                                        Bookmarked
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {filterConfig.map((config, index) => {
                            if (hasFilters(config.name)) {
                                return (
                                    <OfferFilterTab
                                        key={config.name}
                                        config={config}
                                        checkboxMapping={getCheckboxMapping(config.name).mapping}
                                        setCheckboxMapping={getCheckboxMapping(config.name).setter}
                                        filterOptions={filterOptions}
                                        updateFilterOptions={updateFilterOptions}
                                        isOpen={openMapping[config.name]}
                                        closeAllFilters={closeAllFilters}
                                        openSingleFilter={openSingleFilter}
                                        maxXPos={
                                            positionRef?.current
                                                ? positionRef?.current?.clientWidth
                                                : 0
                                        }
                                        isMobile={true}
                                    />
                                );
                            }
                            return <></>;
                        })}
                    </div>
                </Scrollbar>
            </div>
            <div className="mobile-filter-footer">
                        <Button
                            buttonType={ButtonTypes.SECONDARY}
                            onClick={() => clearFilters()}
                            userType={formData.userType}
                            showIcon={true}
                            width={"100%"}
                        >
                            Clear filters
                        </Button>
                        <Button
                            buttonType={ButtonTypes.PRIMARY}
                            onClick={() => setShowMobileFilter()}
                            userType={formData.userType}
                            showIcon={true}
                            width={"100%"}
                        >
                            Apply
                        </Button>                
                    </div>
                </div>
    );
};

export default MobileFilter;
