import { PhoneInputValue } from "../../../../types/form-types";
import { Consent } from "../../../../types/sparrow-types";
import { getCurrentUnixTime } from "../../../general-utils";

export const removeSsnFormatting = (ssn: string) => {
    return ssn.includes("•") ? null : ssn.replace(/\D/g, '');
};

export const dateToEpoch = (dateString: string) => {
    //MM/YYYY format case
    const splitDate = dateString.split("/"); 
    if(splitDate.length === 2) {
        const [month, year] = splitDate;
        return Date.parse(`${year}-${month}-01`) / 1000;
    }
    
    return Date.parse(dateString) / 1000;
};

export const removePhoneNumberFormatting = (phoneNumber: PhoneInputValue | string) => {
    const phoneWithoutCountryCode = (typeof phoneNumber === "string" ? phoneNumber : phoneNumber.phoneNumber)
      .split(" ")
      .slice(1)
      .join("")
      .replace(/\D/g, '');
    
    // Case where phone number is WhatsApp number, which does not require other PhoneInputValue fields such as countryCode and countryPrefix
    if (typeof phoneNumber === "string") {
      return phoneWithoutCountryCode;
    }
  
    phoneNumber.phoneNumber = phoneWithoutCountryCode;
    if(phoneNumber.isSameAsWhatsApp) {
      phoneNumber.whatsAppNumber = phoneWithoutCountryCode;
    }

    
    return phoneNumber;
  };

  export const includeTimeOfResponse = (consentInput: Consent) => {
    if(!consentInput.timeOfResponse){ 
      consentInput.timeOfResponse = getCurrentUnixTime();
      return consentInput;
    }

    return consentInput;
  }
