export enum OffersTablePreferences {
    LOWEST_TOTAL_COST = "lowestTotalCost",
    SHORTEST_TERM = "shortestTerm",
    LOWEST_IN_SCHOOL = "lowestInSchool",
    LOWEST_MONTHLY_PAYMENT = "lowestMonthlyPayment",
    LOWEST_APR = "lowestAPR",
    AUTO_ROUNDED = "autoRounded",
}

export enum OffersTablePreferencesFrontend {
    LOWEST_TOTAL_COST = "Lowest total cost",
    SHORTEST_TERM = "Shortest term",
    LOWEST_IN_SCHOOL = "Lowest in school payment",
    LOWEST_MONTHLY_PAYMENT_PSL = "Lowest after school payment",
    LOWEST_MONTHLY_PAYMENT_SLR = "Lowest monthly payment",
    LOWEST_APR = "Lowest APR",
    AUTO_ROUNDED = "Loan size out of range",
}
