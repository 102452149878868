import { useState } from "react";
import { SearchIcon } from "../../icons/SearchIcon";
import { SearchAmountModal } from "./components/SearchAmountModal";
import styles from "../styles.module.scss";

export const LoanAmountButton = () => {

    const [showModal, setShowModal] = useState(false);

    return (
        <>  
        <div style={{position: "relative", marginLeft: "10px"}}>
            <button className={styles.loanAmountBtn} onClick={() => setShowModal(true)}>
                <span className={styles.howMuchTxt}>How much do you want to borrow?</span>
                <div className={styles.searchWrapper}>
                    <SearchIcon />
                    <span className={styles.searchTxt}>Search</span>
                </div>
            </button>
            {showModal && <SearchAmountModal setShowModal = {setShowModal} />}
        </div>
        </>
    );
};
