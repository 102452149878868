import { FC } from "react";

import { observer } from "mobx-react";

import { RequestProgress } from "../../LeftNavigationRequestProgressSidebar/RequestProgress";
import { LeftNavigationCustomMenuButtons } from "./LeftNavigationCustomMenuButtons";

import "./styles.scss";

interface IRatePageButtons {
    menuExpand: boolean;
    setMenuExpandDropdown: Function;
    setMenuExpand: Function;
}

export const RatePageButtons: FC<IRatePageButtons> = observer(
    ({ menuExpand, setMenuExpandDropdown, setMenuExpand }) => {
        return (
            <>
                <div className={"request-progress-and-divider"}>
                    <RequestProgress />
                    <div className="request-progress-divider bottom" />
                </div>
                <LeftNavigationCustomMenuButtons // LeftNavigationCustomMenuButtons displays the custom buttons on the rate page. We place it here because the rate page requires the display to be under the request progress section.
                    menuExpand={menuExpand}
                    setMenuExpandDropdown={setMenuExpandDropdown}
                    setMenuExpand={setMenuExpand}
                />
            </>
        );
    }
);
