import { UserType } from "../../../types/sparrow-types";
import { getSvgColors } from "../getSvgColors";

export const CompleteStatusIcon = ({ userType }: { userType?: UserType }) => {
    const { primaryColor } = userType ? getSvgColors(userType) : {primaryColor: undefined};
    
    return (
        <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="11" fill={primaryColor ?? "#6DB353"} />
            <path
                d="M7.50854 11.8495L11.1871 15.528L17.1805 9.5346"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};


