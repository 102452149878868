import { BookIcon } from "../../assets/interface_dashboard/BookIcon"
import styles from "./styles.module.scss"

export const StudentCard = () => {
    return (
        <div className={styles.container}>
            <div className={styles.collegeInfo}>
                <div className={styles.book}>
                    <BookIcon />
                </div>
                <div>
                <div style={{fontWeight: "600", marginBottom: "2.17px"}}>
                    Stanford University
                </div>
                <div>
                    B.S. | Management Science and Engineering
                </div>

                </div>
            </div>
            <div>

                <div className={styles.rowInfo}>
                    <div>Enrollment status</div>
                    <div>Full-time</div>
                </div>
                <div className={styles.rowInfo}>
                    <div>Graduation date</div>
                    <div>June 2025</div>
                </div>
            </div>
        </div>
    )
}
