import { useEffect, useState } from "react";
import { AddressInput } from "../../../../types/sparrow-types";
import { FormFieldName, InputValue, FormFieldProps } from "../../../../types/form-types";
import { FormFieldContainer } from "../..";
import {
    getSpecialFormattingFunction,
    getErrorMessageFunction,
} from "../../../../utils/ui-library-utils";
import { getFormConfig } from "./manual-address-form-config";
import { Button, ButtonTypes } from "../../../../../../common/components/buttons/button/Button";
import { ButtonGroup } from "../../../buttons";
import { useOnboarding } from "../../../../../../common/core/providers/onboarding.provider";
import { RequiredAddressFields } from "../../../../constants/default-inputs";

interface ManualAddressProps {
    isPermanentAddress: boolean;
    setShowManualAddress: React.Dispatch<React.SetStateAction<boolean>>;
    setManualAddressInputs: (addressInput: AddressInput) => void;
    handleCancel?: () => void;
    inputValue: AddressInput;
}

const ManualAddress: React.FC<ManualAddressProps> = ({
    inputValue,
    setShowManualAddress,
    setManualAddressInputs,
    handleCancel,
}) => {
    const { formData } = useOnboarding();

    const [formFields, setFormFields] = useState<FormFieldProps[]>(getFormConfig(inputValue));

  

    useEffect(() => {
        
        const requiredAddressFieldsKeys = Object.keys(RequiredAddressFields({includeLine2: true}));
        
        const updatedFormFields = requiredAddressFieldsKeys.map((key) => {
            // formField keys are identical to requiredAddressFieldsKeys so we always get a field back
            const field = formFields.find((field) => field.name === key) as FormFieldProps;
            const messageFunction = getErrorMessageFunction(key);
            
            field.errorMessage = messageFunction(
                field.inputValue ?? "",
                key as FormFieldName,
                formFields
            ) as string;
            return field;
        });

        setFormFields([...updatedFormFields]);
    }, []);

    const handleInputChange = (name: FormFieldName, value: InputValue) => {
        const field = formFields.find((field) => field.name === name);

        if (!field) return;
        const specialFormattingFunction = getSpecialFormattingFunction(name);
        // Apply the special formatting function to the input value, if available
        const formattedValue = specialFormattingFunction
            ? specialFormattingFunction(value as string)
            : value;

        const messageFunction = getErrorMessageFunction(name);
        // If the field has an error message function, then update the error message for the field
        if (messageFunction) {
            // Delayed error message for effect
            setTimeout(() => {
                field.errorMessage = messageFunction(
                    formattedValue as string,
                    name,
                    formFields
                ) as string;
                setFormFields([...formFields]);
            }, 1000);
        }

        if (!field) return;
        // Update the input value for the field
        field.inputValue = formattedValue;
        setFormFields([...formFields]);
    };

    const handleAddressUpdate = (e) => {
        // Prevents the default form submission behavior
        e.preventDefault();

        let numErrors = 0;
        const address = formFields.reduce((acc, field) => {
            const messageFunction = getErrorMessageFunction(field.name);
            // If the field has an error message function, then update the error message for the field
            if (messageFunction) {
                const message = messageFunction(
                    field.inputValue as string,
                    field.name,
                    formFields
                ) as string;
                field.errorMessage = message;
                if (message) numErrors++;
            }

            acc[field.name] = field.inputValue;
            return acc;
        }, {} as AddressInput);

        // If there are any errors, then don't submit the form
        if (numErrors > 0) return setFormFields([...formFields]);
        // If all of the manual address fields are valid, then update the Address component's state will all of the address inputs
        setManualAddressInputs(address);
        // Close the manual address form after updating state
        setShowManualAddress(false);
    };

    return (
        <div className="ManualAddressWrapper">
            <div className="FormContent">
                {formFields.map((field, i) => {
                    // maps over the form fields and renders them in pairs, with k incremented by 2 each time
                    let k = i === 0 ? i : 2 * i;

                    return (
                        <>
                            {k < formFields.length && (
                                // Static array order and fixed array length
                                <div className="FormRowContent" key={`${field.name}-${i}`}>
                                    <FormFieldContainer
                                        formField={formFields[k]}
                                        handleInputChange={handleInputChange}
                                    />
                                    <FormFieldContainer
                                        formField={formFields[k + 1]}
                                        handleInputChange={handleInputChange}
                                    />
                                </div>
                            )}
                        </>
                    );
                })}
            </div>
            <div className="ManualAddressBottomSection">
                <ButtonGroup styles={{ gap: "24px" }}>
                    <button className="ManualAddressCancel" onClick={handleCancel}>
                        Cancel
                    </button>
                    {/* TODO: consolidate button logic to remain in library. Currently using marketplace button component */}
                    {/* <Button
                        buttonType={ButtonType.Primary}
                        buttonStyle={ButtonStyle.CD}
                        onClick={handleAddressUpdate}
                        text="Update address"
                    /> */}
                    <Button
                        userType={formData?.userType}
                        buttonType={ButtonTypes.PRIMARY}
                        onClick={handleAddressUpdate}
                    >
                        Update address
                    </Button>
                </ButtonGroup>
            </div>
        </div>
    );
};

export default ManualAddress;
