import React from "react";
import "./style.scss";
import { Button } from "../../../../components/buttons/button/Button";
import { useOnboarding } from "../../../../core/providers/onboarding.provider";
import { ButtonTypes } from "../../../../components/buttons/button/Button";

interface NoOffersProps {
    clearOffers: Function;
}

export const NoOffers: React.FunctionComponent<NoOffersProps> = ({ clearOffers }) => {
    const {formData} = useOnboarding();

    return (
        <div className="no-offers-section">
            <div className="no-offers-text-1">No options match your filters</div>
            <div className="no-offers-text-2">Clear your filters to see results</div>
            <Button
                    buttonType={ButtonTypes.PRIMARY}
                    onClick={() => clearOffers()}
                    userType={formData.userType}
                    showIcon={true}
                >
                    Clear all filters
                </Button>
        </div>
    );
};
