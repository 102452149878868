export const GradLoanIcon = () => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="10"
                y="25"
                width="22"
                height="17"
                rx="2"
                transform="rotate(-90 10 25)"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <rect x="6" y="15" width="29" height="22" rx="4" fill="rgba(var(--borrowerPrimaryColor),1)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 23C10 24.1046 10.8954 25 12 25H25C26.1046 25 27 24.1046 27 23V15H10V23Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
            <path
                d="M29 26C29 24.8954 29.8954 24 31 24H35V28H31C29.8954 28 29 27.1046 29 26Z"
                fill="rgba(var(--borrowerAccentColor),1)"
            />
        </svg>
    );
};
