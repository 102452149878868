import { useEffect, useRef, useState } from "react";
import OfferCard from "../../../../components/OfferCard";
import RateCharts from "../../../../components/RateCharts/index";
import { OfferFilters } from "../../../../components/OfferFilters";
import { useOnboarding } from "../../../../core/providers/onboarding.provider";
import { ForYouSection } from "./ForYou";
import {
    OffersTablePreferences,
    OffersTablePreferencesFrontend,
} from "../../../../../sparrow-ui-library/src/types/sparrow-types";
import { OfferFiltersOptions } from "../../../../components/OfferFilters/utilities";
import {
    getPinnedMapping,
    parseOfferPastDueBalance,
    parseOfferSAP,
    parseOfferRepaymentPlan,
} from "./utilities/TableUtilities";
import { getBestRateLabels } from "./utilities/BestRateUtilities";
import { extractForYouOffersFromAllOtherOffers, filterOffers } from "./utilities/FilterUtilities";
import { sortOffersByRank } from "./utilities/SortingUtilities";
import SlideToggle from "react-slide-toggle";
import { UserType } from "../../../../constants/defaultInputs";
import { NoOffers } from "./NoOffers";
import { useMediaQuery } from "react-responsive";
import { observer } from "mobx-react";

import { convertStringOrNumberToNumber } from "../../../../components/RateCharts/RateChartUtilities";
import { StandardOfferGraphDataInput } from "../../../../utils/graphing/utils/types";
import { generateGraphDataForOffers } from "../../../../utils/graphing";
import { SortBy } from "./SortBy";
import {
    LenderToolTip,
    getLenderDisclosure,
} from "../../../../../app/borrower/offers/lender-offers/page";
import {
    IOffer,
    IIncomeBasedOffer,
} from "../../../../../common/core/services/types/offers-api-types";
import "./style.scss";

interface OffersTableProps {
    offersData: (IOffer | IIncomeBasedOffer)[];
    setToPinnedOrBookmarked: Function;
    tooltips: LenderToolTip[];
}

export type WordpressRateTable = {
    lender_logo: string;
    lender_name: string;
    lender_name_from_tooltip_table: string;
    minimum_credit_score: string;
    variable_apr: string;
    variable_apr_title: string;
    fixed_apr: string;
    fixed_apr_title: string;
};

export const OffersTable: React.FC<OffersTableProps> = observer(
    ({ offersData, setToPinnedOrBookmarked, tooltips }) => {
        const {
            formData,
            setHasActiveFilters,
            setHasBookmark,
            showCompareComponent,
            setHasCompare,
            setGraphData,
        } = useOnboarding();

        const isCosigner = formData.userType === UserType.COSIGNER;

        // Offers
        const [forYouOffers, setForYouOffers] = useState<(IOffer | IIncomeBasedOffer)[]>([]);
        const [allOtherOffers, setAllOtherOffers] = useState<(IOffer | IIncomeBasedOffer)[]>([]);

        const [forYouOffersFiltered, setForYouOffersFiltered] = useState<
            (IOffer | IIncomeBasedOffer)[]
        >([]);
        const [allOtherOffersFiltered, setAllOtherOffersFiltered] = useState<
            (IOffer | IIncomeBasedOffer)[]
        >([]);

        const [chartOffers, setChartOffers] = useState<any[]>([]);

        const hasOffers = offersData.length !== 0;

        // Bookmarking
        const [bookmarkedCount, setBookmarkedCount] = useState(0);
        const [bookmarkActive, setBookmarkActive] = useState(false);

        // Pinning
        const [pinnedMapping, setPinnedMapping] = useState<{ [Key: string]: number }>({});
        const [staredCount, setStaredCount] = useState(0);

        // Sorting ranks
        const [primaryRank, setPrimaryRank] = useState<OffersTablePreferences | null>(
            formData.userType === UserType.BORROWER
                ? formData.offersQuizAnswer
                : formData.cosigner?.offersQuizAnswer ?? null
        );

        // initialize as lowest total cost
        const [secondaryRank, setSecondaryRank] = useState(
            OffersTablePreferences.LOWEST_TOTAL_COST
        );

        // Best rate labels
        const [labelMapping, setLabelMapping] = useState<{
            [Key: string]: OffersTablePreferencesFrontend;
        }>({});

        // Filter options
        const [filterOptions, setFilterOptions] = useState<OfferFiltersOptions>({
            sapOptions: [],
            lenderOptions: [],
            pastDueBalanceOptions: [],
            termOptions: [],
            paymentPlanOptions: [],
            loanTypeOptions: [],
            rateTypeOptions: [],
            otherOptions: [],
        });

        // Active filters options
        const [sapCheckboxMapping, setSAPCheckboxMapping] = useState({});
        const [lenderCheckboxMapping, setLenderCheckboxMapping] = useState({});
        const [pastDueBalanceCheckboxMapping, setPastDueBalanceCheckboxMapping] = useState({});
        const [termCheckboxMapping, setTermCheckboxMapping] = useState({});
        const [paymentPlanCheckboxMapping, setPaymentPlanCheckboxMapping] = useState({});
        const [loanTypeCheckboxMapping, setLoanTypeCheckboxMapping] = useState({});
        const [rateTypeCheckboxMapping, setRateTypeCheckboxMapping] = useState({});

        // chart selected rate
        const [chartSelectedRate, setChartSelectedRate] = useState<number | null>(null);

        const isBorrowerInitiated = formData.userType === UserType.BORROWER;

        const isLess767 = useMediaQuery({
            query: "(max-width: 767px)",
        });

        /**
         * On component first render:
         */
        useEffect(() => {
            initializeOfferSubset(offersData);
            initializeDropdownOptions();
        }, []);

        /**
         * On offer data update:
         */
        useEffect(() => {
            initializeOfferSubset(offersData);
        }, [offersData]);

        /**
         * On for you / all other offers update:
         */
        useEffect(() => {
            processPinnedColoring();
        }, [forYouOffers, allOtherOffers]);

        /**
         * On quiz answer update update:
         */
        useEffect(() => {
            if (formData.userType === UserType.BORROWER) {
                setPrimaryRank(formData.offersQuizAnswer);
            } else {
                setPrimaryRank(formData.cosigner?.offersQuizAnswer ?? null);
            }

            setSecondaryRank(OffersTablePreferences.LOWEST_TOTAL_COST);

            initializeOfferSubset(offersData);
        }, [formData.cosigner?.offersQuizAnswer, formData.offersQuizAnswer]);

        /**
         * On rank update
         */
        useEffect(() => {
            initializeOfferSubset(offersData, filterOptions);
            initializeBestRateLabels();
        }, [primaryRank, secondaryRank]);

        /**
         * On chart update:
         */
        useEffect(() => {
            setHasCompare(chartOffers.length > 0);
        }, [chartOffers]);

        /**
         * On filter update
         * Adding a state for the filter button. If there are selected filters, the mobile filters button becomes active
         */
        useEffect(() => {
            let hasFilter: any = [];
            Object.keys(filterOptions).forEach((key) => {
                if (filterOptions[key].length > 0) {
                    hasFilter.push(key);
                }
            });
            setHasActiveFilters(hasFilter.length > 0);
        }, [filterOptions]);

        /**
         * This function calculates the best rate labels for rates.
         */
        function initializeBestRateLabels() {
            const nextLabelMapping = getBestRateLabels({
                isPSL: formData.type === "PSL",
                labelMapping: {},
                offersData,
                primaryRank,
                secondaryRank,
            });

            setLabelMapping(nextLabelMapping);
        }

        /**
         * This function initializes forYouOffers and allOtherOffers.
         * @param offers
         */
        function initializeOfferSubset(offers: any[], newFilters?: OfferFiltersOptions) {
            const chartOffersTemp: any[] = [];

            let pinnedCount = 0;
            let bookmarkedCount = 0;

            for (const offer of offers) {
                if (offer.pinned && !isCosigner) {
                    pinnedCount = pinnedCount + 1;
                }

                if (offer.bookmarked && !isCosigner) {
                    bookmarkedCount = bookmarkedCount + 1;
                }

                if (offer.cosignerPinned && isCosigner) {
                    pinnedCount = pinnedCount + 1;
                }

                if (offer.cosignerBookmarked && isCosigner) {
                    bookmarkedCount = bookmarkedCount + 1;
                }
            }

            setStaredCount(pinnedCount);
            setBookmarkedCount(bookmarkedCount);
            setHasBookmark(bookmarkedCount > 0);

            const nextMapping = {};

            // sort offers
            let sortedOffers = sortOffersByRank({
                offers: offers,
                primaryRank: primaryRank!,
                secondaryRank,
            });

            for (const offer of sortedOffers) {
                if (offer.pinned && !isCosigner) {
                    nextMapping[offer.id] = Object.keys(nextMapping).length;
                    chartOffersTemp.push(offer);
                }
                if (offer.cosignerPinned && isCosigner) {
                    nextMapping[offer.id] = Object.keys(nextMapping).length;
                    chartOffersTemp.push(offer);
                }
            }

            // Set chart offers and pinned mapping
            setChartOffersOnChange(chartOffersTemp);
            setPinnedMapping(nextMapping);

            // Set initial for you and all other offers
            const initialOfferBreakdown = extractForYouOffersFromAllOtherOffers(sortedOffers);
            setForYouOffers(initialOfferBreakdown.forYou);
            setAllOtherOffers(initialOfferBreakdown.allOther);

            if (newFilters) {
                sortedOffers = filterOffers(sortedOffers, newFilters);
            }

            // Set filtered for you and all other offers
            if (newFilters) {
                const newOfferBreakdown = extractForYouOffersFromAllOtherOffers(sortedOffers);
                setForYouOffersFiltered(newOfferBreakdown.forYou);
                setAllOtherOffersFiltered(newOfferBreakdown.allOther);
            } else {
                const oldOfferBreakdown = extractForYouOffersFromAllOtherOffers(sortedOffers);
                setForYouOffersFiltered(filterOffers(oldOfferBreakdown.forYou, filterOptions));
                setAllOtherOffersFiltered(filterOffers(oldOfferBreakdown.allOther, filterOptions));
            }
        }

        const setChartOffersOnChange = (chartOffersTemp: any[]) => {
            const oldOfferIds = chartOffers.sort((a, b) => a.id - b.id).map((offer) => offer.id);
            const newOfferIds = chartOffersTemp
                .sort((a, b) => a.id - b.id)
                .map((offer) => offer.id);

            let changed = false;
            oldOfferIds.forEach((oldOfferId, index) => {
                if (oldOfferId !== newOfferIds[index]) {
                    changed = true;
                }
            });
            newOfferIds.forEach((newOfferId, index) => {
                if (newOfferId !== oldOfferIds[index]) {
                    changed = true;
                }
            });

            if (changed) {
                setChartOffers(chartOffersTemp);
            }
        };

        useEffect(() => {
            if (chartOffers.length > 0) {
                const graphData = generateGraphDataForOffers({
                    offers: chartOffers as StandardOfferGraphDataInput[],
                    incomeBasedOffers: [],
                    gradDate: convertStringOrNumberToNumber(formData.degrees[0].gradDate),
                    dob: convertStringOrNumberToNumber(formData.dob as number),
                    isBorrowerInitiated: isBorrowerInitiated,
                });

                setGraphData(graphData);
            }
        }, [chartOffers]);

        /**
         * This function sets the dropdown options based on the previous set of dropdown options as well as the offers.
         */
        function initializeDropdownOptions() {
            const loanTypeOptions: string[] = [];
            const rateTypeOptions: string[] = [];
            const repaymentPlanOptions: string[] = [];
            const lenderOptions: string[] = [];
            const termOptions: string[] = [
                "5 years or less",
                "7 years or less",
                "10 years or less",
                "15 years or less",
                "20 years or less",
            ];
            const sapOptions: string[] = [];
            const pastDueBalanceOptions: string[] = [];

            for (const offer of offersData) {
                // rate type
                const offerRateType =
                    (offer as IOffer).loanType.charAt(0).toUpperCase() +
                    (offer as IOffer).loanType.slice(1);
                if (!rateTypeOptions.includes(offerRateType)) {
                    rateTypeOptions.push(offerRateType);
                }

                // repayment plan
                const offerRepaymentPlan = parseOfferRepaymentPlan(offer.paymentPlan);
                if (!repaymentPlanOptions.includes(offerRepaymentPlan)) {
                    repaymentPlanOptions.push(offerRepaymentPlan);
                }
                // lender
                const offerLender = offer.lender;
                if (!lenderOptions.includes(offerLender)) {
                    lenderOptions.push(offerLender);
                }

                // sap
                const offerSAP = parseOfferSAP(offer.satisfactoryAcademicProgressRequired);
                if (!sapOptions.includes(offerSAP)) {
                    sapOptions.push(offerSAP);
                }
                // past due balance
                const offerPastDueBalance = parseOfferPastDueBalance(offer.pastDueBalancesAllowed);
                if (!pastDueBalanceOptions.includes(offerPastDueBalance)) {
                    pastDueBalanceOptions.push(offerPastDueBalance);
                }
            }

            const tempLoanTypeOptions = {};
            for (const option of loanTypeOptions) {
                tempLoanTypeOptions[option] = false;
            }
            setLoanTypeCheckboxMapping(tempLoanTypeOptions);

            const tempRateTypeOptions = {};
            for (const option of rateTypeOptions) {
                tempRateTypeOptions[option] = false;
            }
            setRateTypeCheckboxMapping(tempRateTypeOptions);

            const tempRepaymentPlanOptions = {};
            for (const option of repaymentPlanOptions) {
                tempRepaymentPlanOptions[option] = false;
            }
            setPaymentPlanCheckboxMapping(tempRepaymentPlanOptions);

            const tempLenderOptions = {};
            for (const option of lenderOptions) {
                tempLenderOptions[option] = false;
            }
            setLenderCheckboxMapping(tempLenderOptions);

            const tempTermOptions = {};
            for (const option of termOptions) {
                tempTermOptions[option] = false;
            }
            setTermCheckboxMapping(tempTermOptions);

            const tempSAPOptions = {};
            for (const option of sapOptions) {
                tempSAPOptions[option] = false;
            }
            setSAPCheckboxMapping(tempSAPOptions);

            const tempPastDueBalanceOptions = {};
            for (const option of pastDueBalanceOptions) {
                tempPastDueBalanceOptions[option] = false;
            }
            setPastDueBalanceCheckboxMapping(tempPastDueBalanceOptions);
        }

        /**
         * This function updates the pinned color mapping
         */
        function processPinnedColoring() {
            const nextMapping = getPinnedMapping({
                forYouOffers: forYouOffersFiltered,
                allOtherOffers: allOtherOffersFiltered,
                isCosigner,
            });
            setPinnedMapping(nextMapping);
        }

        /**
         * This function updates the pinned count
         * @param count
         * @param pined
         */
        function changeStaredCount(count: number, pined: boolean) {
            pined ? count-- : count++;
            setStaredCount(count);
        }

        /**
         * This function updates the bookmarked count
         * @param count
         * @param bookmarked
         */
        function changeBookmarkedCount(count: number, bookmarked: boolean) {
            bookmarked ? count-- : count++;
            setBookmarkedCount(count);
            setHasBookmark(count > 0);
        }

        /**
         * This function updates the filter options
         * @param newFilters
         */
        function updateFilterOptions(newFilters: OfferFiltersOptions) {
            setFilterOptions(newFilters);
            initializeOfferSubset(offersData, newFilters);
        }

        function handleClearOffers() {
            updateFilterOptions({
                sapOptions: [],
                lenderOptions: [],
                pastDueBalanceOptions: [],
                termOptions: [],
                paymentPlanOptions: [],
                loanTypeOptions: [],
                rateTypeOptions: [],
                otherOptions: [],
            });
            setSAPCheckboxMapping(resetMapping(sapCheckboxMapping));
            setLenderCheckboxMapping(resetMapping(lenderCheckboxMapping));
            setPastDueBalanceCheckboxMapping(resetMapping(pastDueBalanceCheckboxMapping));
            setTermCheckboxMapping(resetMapping(termCheckboxMapping));
            setPaymentPlanCheckboxMapping(resetMapping(paymentPlanCheckboxMapping));
            setLoanTypeCheckboxMapping(resetMapping(loanTypeCheckboxMapping));
            setRateTypeCheckboxMapping(resetMapping(rateTypeCheckboxMapping));
            setBookmarkActive(false);
            setHasActiveFilters(false);
        }

        function resetMapping(mapping: { [key: string]: String }) {
            const newMapping = {};
            for (const key in mapping) {
                newMapping[key] = false;
            }
            return newMapping;
        }

        const topOfOtherRatesRef = useRef<HTMLDivElement>(null);

        return (
            <>
                {hasOffers && isLess767 && chartOffers.length > 0 && (
                    <div
                        className={`mobile-collapse-charts ${showCompareComponent ? "active" : ""}`}
                    >
                        <RateCharts
                            offerData={chartOffers}
                            chartSelectedRate={chartSelectedRate}
                            setChartSelectedRate={setChartSelectedRate}
                            onPinned={setToPinnedOrBookmarked}
                            isMobileView={true}
                        />
                    </div>
                )}
                <div className="offer-table-container">
                    {hasOffers && (
                        <div className="filters-container">
                            <OfferFilters
                                hasBookmarked={bookmarkedCount > 0}
                                filterOptions={filterOptions}
                                updateFilterOptions={updateFilterOptions}
                                filterSetters={{
                                    setSAPCheckboxMapping: setSAPCheckboxMapping,
                                    setLenderCheckboxMapping: setLenderCheckboxMapping,
                                    setPastDueBalanceCheckboxMapping:
                                        setPastDueBalanceCheckboxMapping,
                                    setTermCheckboxMapping: setTermCheckboxMapping,
                                    setPaymentPlanCheckboxMapping: setPaymentPlanCheckboxMapping,
                                    setLoanTypeCheckboxMapping: setLoanTypeCheckboxMapping,
                                    setRateTypeCheckboxMapping: setRateTypeCheckboxMapping,
                                }}
                                filterValues={{
                                    sapCheckboxMapping: sapCheckboxMapping,
                                    lenderCheckboxMapping: lenderCheckboxMapping,
                                    pastDueBalanceCheckboxMapping: pastDueBalanceCheckboxMapping,
                                    termCheckboxMapping: termCheckboxMapping,
                                    paymentPlanCheckboxMapping: paymentPlanCheckboxMapping,
                                    loanTypeCheckboxMapping: loanTypeCheckboxMapping,
                                    rateTypeCheckboxMapping: rateTypeCheckboxMapping,
                                }}
                                bookmarkActive={bookmarkActive}
                                setBookmarkActive={setBookmarkActive}
                            />
                        </div>
                    )}

                    {chartOffers.length > 0 && (
                        <RateCharts
                            offerData={chartOffers}
                            chartSelectedRate={chartSelectedRate}
                            setChartSelectedRate={setChartSelectedRate}
                            onPinned={setToPinnedOrBookmarked}
                            isMobileView={true}
                        />
                    )}

                    {forYouOffersFiltered.length !== 0 && (
                        <div className="for-you-header-container">
                            <ForYouSection
                                secondaryRank={secondaryRank}
                                setSecondaryRank={setSecondaryRank}
                                numberOfForYouOffers={forYouOffersFiltered.length}
                                totalNumberOfOffers={offersData.length}
                                primaryRank={primaryRank!}
                            />
                        </div>
                    )}
                    {forYouOffersFiltered.length !== 0 && (
                        <div className="for-you-rates-container">
                            {forYouOffersFiltered.map((offer) => {
                                return (
                                    <OfferCard
                                        key={offer.id}
                                        offer={offer}
                                        pinnedOfferIndex={
                                            offer.id in pinnedMapping
                                                ? pinnedMapping[offer.id]
                                                : undefined
                                        }
                                        chartSelectedRate={chartSelectedRate}
                                        setChartSelectedRate={setChartSelectedRate}
                                        staredCount={staredCount}
                                        staredChange={changeStaredCount}
                                        onPinned={setToPinnedOrBookmarked}
                                        onBookmarked={setToPinnedOrBookmarked}
                                        bookmarkedCount={bookmarkedCount}
                                        bookmarkedChange={changeBookmarkedCount}
                                        bestRateLabel={
                                            offer.id in labelMapping
                                                ? labelMapping[offer.id]
                                                : undefined
                                        }
                                        lenderDisclosure={getLenderDisclosure(
                                            offer.lender,
                                            tooltips
                                        )}
                                        isDefault={true}
                                    />
                                );
                            })}
                        </div>
                    )}
                    {allOtherOffersFiltered.length !== 0 && (
                        <>
                            <div ref={topOfOtherRatesRef} className={"other-rates-title-container"}>
                                <div className="other-rates-title-section">
                                    <div className="other-rates-title">Other rates</div>
                                    <div className="other-rates-subtitle">{`(displaying ${allOtherOffersFiltered.length} of ${offersData.length} rates)`}</div>
                                </div>
                                {forYouOffersFiltered.length === 0 && (
                                    <SortBy
                                        secondaryRank={secondaryRank}
                                        setSecondaryRank={setSecondaryRank}
                                        primaryRank={primaryRank!}
                                    />
                                )}
                            </div>
                            <SlideToggle collapsed>
                                {({ toggle, setCollapsibleElement, progress }) => (
                                    <>
                                        <div className="other-rates-container">
                                            {allOtherOffersFiltered.map((offer, index) => {
                                                const shouldRender = index < 10;
                                                const isLast = index === 9;

                                                return (
                                                    <div
                                                        key={offer.id}
                                                        className={
                                                            shouldRender
                                                                ? `other-rates-row ${
                                                                      isLast ? "last-row" : ""
                                                                  }`
                                                                : "other-rates-empty-row"
                                                        }
                                                    >
                                                        {shouldRender && (
                                                            <OfferCard
                                                                offer={offer}
                                                                pinnedOfferIndex={
                                                                    offer.id in pinnedMapping
                                                                        ? pinnedMapping[offer.id]
                                                                        : undefined
                                                                }
                                                                chartSelectedRate={
                                                                    chartSelectedRate
                                                                }
                                                                setChartSelectedRate={
                                                                    setChartSelectedRate
                                                                }
                                                                staredCount={staredCount}
                                                                staredChange={changeStaredCount}
                                                                onPinned={setToPinnedOrBookmarked}
                                                                onBookmarked={
                                                                    setToPinnedOrBookmarked
                                                                }
                                                                bookmarkedCount={bookmarkedCount}
                                                                bookmarkedChange={
                                                                    changeBookmarkedCount
                                                                }
                                                                bestRateLabel={
                                                                    offer.id in labelMapping
                                                                        ? labelMapping[offer.id]
                                                                        : undefined
                                                                }
                                                                lenderDisclosure={getLenderDisclosure(
                                                                    offer.lender,
                                                                    tooltips
                                                                )}
                                                                isDefault={true}
                                                            />
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        <div
                                            className="other-rates-container"
                                            ref={setCollapsibleElement}
                                        >
                                            {allOtherOffersFiltered.map((offer, index) => {
                                                const shouldRender = index >= 10;
                                                const isFirst = index === 10;

                                                return (
                                                    <div
                                                        key={offer.id}
                                                        className={
                                                            shouldRender
                                                                ? `other-rates-row ${
                                                                      isFirst ? "first-row" : ""
                                                                  }`
                                                                : "other-rates-empty-row"
                                                        }
                                                    >
                                                        {shouldRender && (
                                                            <OfferCard
                                                                key={offer.id}
                                                                offer={offer}
                                                                pinnedOfferIndex={
                                                                    offer.id in pinnedMapping
                                                                        ? pinnedMapping[offer.id]
                                                                        : undefined
                                                                }
                                                                chartSelectedRate={
                                                                    chartSelectedRate
                                                                }
                                                                setChartSelectedRate={
                                                                    setChartSelectedRate
                                                                }
                                                                staredCount={staredCount}
                                                                staredChange={changeStaredCount}
                                                                onPinned={setToPinnedOrBookmarked}
                                                                onBookmarked={
                                                                    setToPinnedOrBookmarked
                                                                }
                                                                bookmarkedCount={bookmarkedCount}
                                                                bookmarkedChange={
                                                                    changeBookmarkedCount
                                                                }
                                                                bestRateLabel={
                                                                    offer.id in labelMapping
                                                                        ? labelMapping[offer.id]
                                                                        : undefined
                                                                }
                                                                lenderDisclosure={getLenderDisclosure(
                                                                    offer.lender,
                                                                    tooltips
                                                                )}
                                                                isDefault={true}
                                                            />
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {allOtherOffersFiltered.length > 10 && (
                                            <div
                                                className={
                                                    "rate-toggle " +
                                                    (progress !== 0 ? "active" : "")
                                                }
                                                onClick={() => {
                                                    toggle();
                                                    if (
                                                        progress === 1 &&
                                                        topOfOtherRatesRef.current
                                                    ) {
                                                        window.scrollTo({
                                                            top: topOfOtherRatesRef.current
                                                                .offsetTop,
                                                            behavior: "smooth",
                                                        });
                                                    }
                                                }}
                                            >
                                                {progress === 1
                                                    ? "Show less"
                                                    : `Show ${
                                                          allOtherOffersFiltered.length - 10
                                                      } more rates`}
                                                <span className="icon-arrow-bottom" />
                                            </div>
                                        )}
                                    </>
                                )}
                            </SlideToggle>
                        </>
                    )}

                    {hasOffers &&
                        allOtherOffersFiltered.length === 0 &&
                        forYouOffersFiltered.length === 0 && (
                            <NoOffers clearOffers={handleClearOffers} />
                        )}
                </div>
            </>
        );
    }
);
