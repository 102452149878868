import { useState } from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";

import { useStore } from "../../core/providers/global.provider";
import { borrowerOffersRoutingUrl } from "../../core/routes/routing-urls";
import { isMarketplaceEnv } from "../../utils/config";
import { LeftNavigationMenuContainer } from "./LeftNavigationMenu/LeftNavigationMenuContainer";
import { LeftNavigationBackToMenuButton } from "./LeftNavigationMenu/Buttons/LeftNavigationBackToMenuButton";
import { LeftNavigationExpandedMenu } from "./LeftNavigationMenu/LeftNavigationExpandedMenu";
import { LeftNavigationHeaderBar } from "./LeftNavigationHeaderBar";
import { MenuItems } from "../../types/paaas/custom-menu-props";
import { useCheckPathName } from "../../../sparrow-ui-library/src/hooks";

import "./style.scss";

interface ILeftNavigationBar {
    showSidebarOnMobile?: boolean;
    setShowSideBarHandlerForMobile: (state: boolean) => void;
}
export const LeftNavigationBar: React.FC<ILeftNavigationBar> = observer(
    ({ showSidebarOnMobile, setShowSideBarHandlerForMobile }) => {
        const store = useStore();
        const routePathname = useLocation().pathname;

        const isRatePage = useCheckPathName(borrowerOffersRoutingUrl);

        const userName = store.getUserName();

        const [menuExpandDropdown, setMenuExpandDropdown] = useState<MenuItems | undefined>(
            undefined
        );
        const [menuExpand, setMenuExpand] = useState<boolean>(false);

        const getMenuItemClassAndFill = (url: string) => {
            return routePathname === url
                ? { class: "active", fill: "#6DB353" }
                : { class: "", fill: "#9EA9B0" };
        };

        return (
            <>
                <div className={`left-navigation-bar-bg ${showSidebarOnMobile ? "active" : ""}`} />
                <aside
                    className={`left-navigation-bar ${
                        isRatePage ? "left-navigation-bar-full-width" : ""
                    } ${showSidebarOnMobile ? "active" : ""}`}
                >
                    <LeftNavigationBackToMenuButton
                        setMenuExpandDropdown={setMenuExpandDropdown}
                        setMenuExpand={setMenuExpand}
                        menuExpand={menuExpand}
                    />
                    <LeftNavigationHeaderBar
                        menuExpand={menuExpand}
                        setShowSideBarHandlerForMobile={setShowSideBarHandlerForMobile}
                        setMenuExpandDropdown={setMenuExpandDropdown}
                        setMenuExpand={setMenuExpand}
                        showSidebarOnMobile={showSidebarOnMobile}
                    />
                    <LeftNavigationMenuContainer
                        userName={userName}
                        menuExpand={menuExpand}
                        menuExpandDropdown={menuExpandDropdown}
                        getMenuItemClassAndFill={getMenuItemClassAndFill}
                        setShowSideBarHandlerForMobile={setShowSideBarHandlerForMobile}
                        setMenuExpandDropdown={setMenuExpandDropdown}
                        setMenuExpand={setMenuExpand}
                    />
                </aside>
                {/* TODO: popups for left-navigation are only supported for PAAAS sites */}
                {!isMarketplaceEnv() && (
                    <aside className={`left-navigation-popup ${menuExpand ? "active" : ""}`}>
                        <LeftNavigationExpandedMenu menuExpandDropdown={menuExpandDropdown} />
                    </aside>
                )}
            </>
        );
    }
);
