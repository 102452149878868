//https://www.npmjs.com/package/google-maps
//https://developers.google.com/maps/documentation/javascript/examples/places-queryprediction

import { Loader } from "google-maps";
import { DropdownOption } from "../../../types/form-types/dropdown-option";
import { AddressInput } from "../../../types/sparrow-types";

const googleMapsClient = new Loader("AIzaSyBMpK5UJ5XiEDH7-rsCu3VvNaCTl0DEWaw", {
    libraries: ["places"],
});

export const getAddressSuggestions = async (
    inputValue: string,
    setDropdownOptions: React.Dispatch<React.SetStateAction<DropdownOption[]>>,
    setDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
    const google = await googleMapsClient.load();
    const service = new google.maps.places.AutocompleteService();

    const displaySuggestions = (resp: any) => {
        const suggestions = resp.map((prediction: any) => {
            return {
                label: prediction.description,
                value: prediction.description,
                place_id: prediction.place_id,
            };
        });

        setDropdownOptions(suggestions);
        setDropdownOpen(true);
    };

    service.getPlacePredictions(
        { input: inputValue, componentRestrictions: { country: "US" } },
        displaySuggestions
    );
};

// https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingAddressTypes
export const getAddressObject = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
    //Find address components
    const streetNumber = addressComponents.find((component) =>
        component.types.includes("street_number")
    );
    const streetName = addressComponents.find((component) => component.types.includes("route"));
    const cityComponent = addressComponents.find(
        (component) =>
            component.types.includes("locality") ||
            component.types.includes("administrative_area_level_1")
    );
    const stateComponent = addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1")
    );
    const zipComponent = addressComponents.find((component) =>
        component.types.includes("postal_code")
    );
    const countryComponent = addressComponents.find((component) =>
        component.types.includes("country")
    );
    //Get relevant data from address components
    // We add in ?? "" and trim in order to ensure that we don't get undefined in the value if a field is missing
    // We used to get "undefined undefined" as the addressLine1 if we couldn't find streetNumber and streetName
    // Now we would just get " " which would then trimmed to "" and then be marked invalid as we need an addressLine1
    const addressLine1 = `${streetNumber?.long_name ?? ""} ${streetName?.long_name ?? ""}`.trim();
    const city = cityComponent?.long_name;
    const state = stateComponent?.short_name;
    const zip = zipComponent?.long_name;
    const countryCode = countryComponent?.short_name;

    return {
        addressLine1,
        city,
        state,
        zip,
        countryCode,
    };
};

export const getPlaceDetails = async (placeId: string): Promise<AddressInput | undefined> => {
    const google = await googleMapsClient.load();
    const geocode = new google.maps.Geocoder();

    let addressObject;
    try {
        await geocode.geocode(
            { placeId },
            (results) => {
                if (!results) return;
                addressObject = getAddressObject(results[0].address_components)
            }
        );

        if (addressObject) return addressObject;
    } catch (e) {
        console.log(e);
    }

    return addressObject;
};
