// TODO: this file will either be removed or refactored after migrating pq pages to using the sparrow UI library. Currently updating types / functions to avoid breaking the app. 
import { OnboardingStore } from "../../../common/store/onboarding";
import {
    UserType,
    ICollege,
    College,
} from "../../../common/constants/defaultInputs";
import { PrequalUrlsEnum } from "../../../common/core/global_routing/front-end-urls";
import {
    getLoanAmountPageText,
    getCitizenshipPageText,
    getIncomePageText,
    getAddressPageText,
    getDegreePageText,
    getReviewPageText,
    getExpandedSearchPageText,
} from "./dynamic-page-text";
import { PrequalificationRequestInput } from "../../../sparrow-ui-library/src/types/sparrow-types";


export interface IDegreesStep {
    degrees: ICollege;
    enrollment: string;
}

export interface IReviewStep {
    ssn: string | null;
    dob: string | number;
}

/**
 * Returns the user we are currently adding information for a given step
 * @param userType
 * @param onboarding
 * @return UserType
 */
export const getUserType = (userType: UserType, onboarding: OnboardingStore): UserType => {
    if (onboarding.creatorIsIncludingUser) {
        return userType === UserType.BORROWER ? UserType.COSIGNER : UserType.BORROWER;
    }

    return userType;
};

const getDegreeUserForm = (
    formData: PrequalificationRequestInput
): {
    activeForm: { degrees: ICollege; enrollment: string };
} => {
    return {
        activeForm: {
            degrees: formData.degrees?.length ? formData.degrees[0] : College(),
            enrollment: formData.enrollment ?? "",
        },
    };
};

const getReviewUserForm = (activeUserType: UserType, formData: PrequalificationRequestInput) => {
    if (activeUserType === UserType.BORROWER) {
        const { ssn, dob } = formData;
        return {
            activeForm: { ssn, dob },
        };
    }

    return {
        activeForm: {
            ssn: formData.cosigner?.ssn ?? "",
            dob: formData.cosigner?.dob ?? "",
        },
    };
};

/**
 * Returns the required user information to be filled out during a specific prequalification step.
 * If the user is directly including another user, the activeUserType will be the opposite of the userType passed in.
 * @param userType
 * @param pathName
 * @param onboarding
 * @returns {activeForm:  IDegreesStep | IReviewStep; activeUserType: UserType} | null
 */
export const getStepData = (setDataInputs: {
    userType: UserType;
    pathName: string;
    onboarding: OnboardingStore;
}): {
    activeForm:
        | IDegreesStep
        | IReviewStep
        | null;
    activeUserType: UserType;
    pageText: { title: string; subtitle: string; subheader?: string; nextStep?: string };
} | null => {
    const { formData } = setDataInputs.onboarding;
    const activeUserType = getUserType(setDataInputs.userType, setDataInputs.onboarding);

    switch (setDataInputs.pathName) {
        case PrequalUrlsEnum.AMOUNT:
            return {
                // TODO: if we keep this retrieval format, this should return the requested loan amount
                activeForm: null,
                activeUserType,
                pageText: getLoanAmountPageText(setDataInputs.onboarding),
            };

        case PrequalUrlsEnum.CITIZENSHIP:
            return {
                activeForm: null,
                activeUserType,
                pageText: getCitizenshipPageText(setDataInputs.onboarding),
            };
        case PrequalUrlsEnum.ADDRESS:
            return {
                activeForm: null,
                activeUserType,
                pageText: getAddressPageText(setDataInputs.onboarding),
            };
        case PrequalUrlsEnum.INCOME:
            return {
                activeForm: null,
                activeUserType,
                pageText: getIncomePageText(setDataInputs.onboarding),
            };
        case PrequalUrlsEnum.DEGREES:
            return {
                ...getDegreeUserForm(formData),
                activeUserType,
                pageText: getDegreePageText(setDataInputs.onboarding),
            };
        case PrequalUrlsEnum.REVIEW:
            return {
                ...getReviewUserForm(activeUserType, formData),
                activeUserType,
                pageText: getReviewPageText(setDataInputs.onboarding),
            };
        case PrequalUrlsEnum.EXPANDED:
            return {
                activeForm: null,
                activeUserType,
                pageText: getExpandedSearchPageText(setDataInputs.onboarding),
            };
        default:
            return null;
    }
};
