import { FC, PropsWithChildren, useEffect, useState } from "react";

import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import { Loading } from "../loading";
import { useStore } from "../../core/providers/global.provider";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { dashboardUrl, loginStepBorrower } from "../../core/routes/navigation-urls";
import { completeSignUpBase } from "../../core/routes/routing-urls";
import { isSuccessfullyLoggedIn } from "../../core/logout";
import { disableDefaultScroll, getPaaasClass } from "../../utils/global";
import { isMarketplaceEnv } from "../../utils/config";
import MobileNavigationHeader from "../MobileNavigationHeader";
import { LeftNavigationBar } from "../LeftNavigationBar";
import { useCheckPathName } from "../../../sparrow-ui-library/src/hooks";

import "./style.scss";
import "../../../assets/styles/typography.scss";
import "../../../common/components/buttons/back_arrow/style.scss";
import "../../../common/components/sign_modals/style.scss";
import { PAAASFooter } from "../layouts/content-layout/components/paaas-footer";
import { PAAASHeaderBar } from "../PAAASHeaderBar";
import { borrowerOffersRoutingUrl } from "../../core/routes/routing-urls";

export const NavigationWrapper: FC<PropsWithChildren<{}>> = observer(({ children }) => {
    const navigate = useNavigate();
    const { setLoading, userHasMissingFields } = useStore();
    const [showSidebarOnMobile, setShowSidebarOnMobile] = useState(false);
    const onboarding = useOnboarding();
    const routePathname = useLocation().pathname;
    const isRatePage = useCheckPathName(borrowerOffersRoutingUrl);
    const isDashboard = useCheckPathName(dashboardUrl);


    // Media Constants
    // This media query is required for setting disableDefaultScroll within the setShowSideBarHandlerForMobile function
    const isLess768px = useMediaQuery({
        query: "(max-width: 768px)",
    });

    useEffect(() => {
        if (!isSuccessfullyLoggedIn(onboarding.jwt)) {
            setLoading(false);
            navigate(loginStepBorrower);
        }
    }, [onboarding.jwt]);

    useEffect(() => {
        if (userHasMissingFields) {
            setLoading(false);
            navigate(completeSignUpBase);
        }
    }, [userHasMissingFields]);

    const setShowSideBarHandlerForMobile = (state: boolean) => {
        setShowSidebarOnMobile(state);
        if (isLess768px) {
            disableDefaultScroll(state);
        }
    };

    const getMainSectionWrapperClasses = () => {
        let classes = "main-section-wrapper";
        classes += ` ${getPaaasClass()}`;
        classes += isRatePage ? " decision-page" : "";
        classes += isDashboard ? " dashboard-page" : "";        

        return classes;
    };

    return (
        <>
            {(!onboarding.jwt || userHasMissingFields) && <Loading shown />}
            {/* <SessionTimer /> */}
            {!isMarketplaceEnv() && <PAAASHeaderBar />}
                <div
                    className={
                        `navigation-wrapper 
                        ${routePathname === "/borrower/profile" ? "navigation-wrapper_profile" : ""}
                        ${isMarketplaceEnv() ? "marketplace" : ""}
                        `}
                >
                    <MobileNavigationHeader
                        setShowSideBarHandlerForMobile={setShowSideBarHandlerForMobile}
                    />
                    <LeftNavigationBar
                        showSidebarOnMobile={showSidebarOnMobile}
                        setShowSideBarHandlerForMobile={setShowSideBarHandlerForMobile}
                    />
                    <div id="tooltip-container" />
                    <div className={getMainSectionWrapperClasses()}>{children}</div>
                </div>
            {!isMarketplaceEnv() && <PAAASFooter />}
        </>
    );
});
