import { AuthHandler } from "../auth";

export const Post = async (url: string, token: string, formData: any) => {
    // await getAccessJwtToken();
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData),
    });
    const resp = await response.json();

    if (response.status === 200) {
        return resp;
    } else {
        throw resp;
    }
};

export const Get = async (
    url: string,
    token: string | null = null,
    id: string | null = null,
    params: any = null
) => {
    let paramsUrl;
    if (id) {
        paramsUrl = `${url}?id=${id}`;
    } else if (params) {
        paramsUrl = `${url}?${params}`;
    } else {
        paramsUrl = url;
    }

    const header = { "Content-Type": "application/json;charset=utf-8" };
    if (token != null) {
        header["Authorization" as keyof typeof header] = "Bearer " + token;
    }
    const response = await fetch(paramsUrl, {
        method: "GET",
        headers: header,
    });
    const resp = await response.json();
    if (response.status === 200) {
        return resp;
    } else {
        throw resp;
    }
};

export const GetPublic = async (url: string, id: string | null = null, params: any = null) => {
    // await getAccessJwtToken();
    let paramsUrl;
    if (id) {
        paramsUrl = `${url}?id=${id}`;
    } else if (params) {
        paramsUrl = `${url}?${params}`;
    } else {
        paramsUrl = url;
    }
    const response = await fetch(paramsUrl, {
        method: "GET",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
        },
    });
    const resp = await response.json();
    if (response.status === 200) {
        return resp;
    } else {
        console.log("ERROR", resp);
        throw resp;
    }
};

export const Put = async (url: string, token: string, formData: any) => {
    // await getAccessJwtToken();

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json;charset=utf-8",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(formData),
    });
    const resp = await response.json();
    if (response.status === 200) {
        return resp;
    } else {
        throw resp;
    }
};

export const Delete = async (url: string, token: string, id: string) => {
    try {
        const response = await fetch(`${url}?requestId=${id}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
                Authorization: "Bearer " + token,
            },
        });
        // await getAccessJwtToken();

        return await response.json();
    } catch (e) {
        console.log("ERROR", e);
        throw e;
    }
};

export const getCredSocket = async () => {
    return await AuthHandler.currentCredentials();
};
