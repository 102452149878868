import { OffersTablePreferences } from "../../../../../../sparrow-ui-library/src/types/sparrow-types";
import { isDefined } from "../../../../../utils/helper_functions/validation-functions";
import { getPrimaryRankValue, getSecondaryRankValue } from "./TableUtilities";

/**
 * This function sorts the offers based on primary and secondary rank. It prioritizes primary rank before secondary rank.
 * @param offers
 * @returns
 */
export function sortOffersByRank(params: {
    offers: any[];
    primaryRank: OffersTablePreferences;
    secondaryRank: OffersTablePreferences;
}) {
    const { offers, primaryRank, secondaryRank } = params;
    offers.sort(function (offerA, offerB) {
        // Filter out auto rounded rates; These are prioritized last
        if (offerA.amountWasRounded && !offerB.amountWasRounded) return 1;
        if (offerB.amountWasRounded && !offerA.amountWasRounded) return -1;

        // Primary rank sorting
        const primaryRankValueA = getPrimaryRankValue(offerA, primaryRank);
        const primaryRankValueB = getPrimaryRankValue(offerB, primaryRank);
        if (primaryRankValueA < primaryRankValueB) {
            return -1;
        } else if (primaryRankValueA > primaryRankValueB) {
            return 1;
        }

        // Secondary rank sorting
        const secondaryRankValueA = getSecondaryRankValue({ offer: offerA, secondaryRank });
        const secondaryRankValueB = getSecondaryRankValue({ offer: offerB, secondaryRank });
        if (secondaryRankValueA < secondaryRankValueB) {
            return -1;
        } else if (secondaryRankValueA > secondaryRankValueB) {
            return 1;
        }

        // Both offers are equal
        return 0;
    });
    return offers;
}

/**
 * This function gets the offer's in school payment. It defaults to MAX_SAFE_INTEGER
 * @param offer
 * @returns
 */
export function getOfferValueForInSchoolPayment(offer: any) {
    return isDefined(offer?.monthlyPaymentInSchool?.amount)
        ? offer.monthlyPaymentInSchool.amount
        : 0;
}

/**
 * This function gets the offer's after school payment. It defaults to MAX_SAFE_INTEGER
 * @param offer
 * @returns
 */
export function getOfferValueForAfterSchoolPayment(offer: any) {
    if (offer.type === "PSL") {
        return isDefined(offer?.monthlyPaymentAfterGrad?.amount)
            ? offer.monthlyPaymentAfterGrad.amount
            : 0;
    } else {
        return isDefined(offer?.averagePayment?.amount) ? offer.averagePayment.amount : 0;
    }
}

/**
 * This function gets the offer's total cost. It defaults to MAX_SAFE_INTEGER
 * @param offer
 * @returns
 */
export function getOfferValueForTotalCost(offer: any) {
    return isDefined(offer?.amount?.amount) && isDefined(offer?.totalInterestExpense?.amount)
        ? offer.amount.amount + offer.totalInterestExpense.amount
        : Number.MAX_SAFE_INTEGER;
}

/**
 * This function gets the offer's term. It defaults to MAX_SAFE_INTEGER
 * @param offer
 * @returns
 */
export function getOfferValueForShortestTerm(offer: any) {
    return isDefined(offer?.term) ? offer.term : Number.MAX_SAFE_INTEGER;
}

/**
 * This function gets the offer's APR
 * @param offer
 * @returns
 */
export function getOfferValueForLowestAPR(offer: any) {
    return offer.loanType === "fixed" ? offer.fixedAPR : offer.startingAPR;
}
