import { usStates, countries } from "../../../../constants/dropdown-options"
import { FormFieldName, FormFieldProps, FormFieldType } from "../../../../types/form-types"
import { AddressInput } from "../../../../types/sparrow-types"

export const getFormConfig = (inputs: AddressInput): FormFieldProps[]  => {
    const{addressLine1, addressLine2, city, countryCode, zip, state} = inputs;

    return [
        {
            formFieldType: FormFieldType.TextBox,
            type: "text",
            id: FormFieldName.AddressLine1,
            name: FormFieldName.AddressLine1,
            label: "Address Line 1",
            placeholder: "Address Line 1",
            inputValue: addressLine1,
            backendValue: "",
            errorMessage: "",
        },
        {
            formFieldType: FormFieldType.TextBox,
            type: "text",
            id: FormFieldName.AddressLine2,
            name: FormFieldName.AddressLine2,
            label: "Address Line 2 (optional)",
            placeholder: "Address Line 2",
            inputValue: addressLine2,
            backendValue: "",
            errorMessage: "",
        },
        {
            formFieldType: FormFieldType.TextBox,
            type: "text",
            id: FormFieldName.City,
            name: FormFieldName.City,
            label: "City",
            placeholder: "City",
            inputValue: city,
            backendValue: "",
            errorMessage: "",
        },
        {
            formFieldType: FormFieldType.TextBox,
            type: "text",
            id: FormFieldName.Zip,
            name: FormFieldName.Zip,
            label: "ZIP code",
            placeholder: "ZIP code",
            inputValue: zip,
            backendValue: "",
            errorMessage: "",
        },
        {
            formFieldType: FormFieldType.InputDropdown,
            id: FormFieldName.State,
            name: FormFieldName.State,
            label: "State",
            placeholder: "State",
            inputValue: state,
            backendValue: "",
            errorMessage: "",
            dropdownOptions: usStates,
        },
        {
            formFieldType: FormFieldType.InputDropdown,
            id: FormFieldName.CountryCode,
            name: FormFieldName.CountryCode,
            label: "Country",
            placeholder: "Country",
            inputValue: countryCode,
            backendValue: "",
            errorMessage: "",
            dropdownOptions: countries,
        }
    ]
}