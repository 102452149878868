import { ReactNode, ChangeEvent } from "react";
import {
  AddressAutocomplete,
  Dropdown,
  TextBox,
  InputDropdown,
  PhoneInput,
  NameField,
  LoanAmount,
} from "..";
import {
  DropdownOption,
  FormFieldProps,
  FormFieldType,
  PhoneInputValue,
  SetInputValue,
} from "../../../types/form-types";
import { UserType } from "../../../types/sparrow-types";
import "./FormFieldContainer.css";

const FormFieldContainer: React.FC<{
  formField?: FormFieldProps;
  children?: ReactNode;
  className?: string;
  handleInputChange: SetInputValue;
}> = ({ formField, handleInputChange, children, className = "" }) => {
  return (
    <div className={`FormField ${className}`}>
      {formField && (
        <>
          {(formField.formFieldType === FormFieldType.NameField) && (
            <NameField
              dropdownOptions={
                formField.dropdownOptions ?? []
              }
              formFieldType={formField.formFieldType}
              inputValue={formField.inputValue}
              backendValue={formField.backendValue}
              key={formField.id}
              label={formField.label}
              name={formField.name}
              nameFieldType={formField.nameFieldType}
              placeholder={formField.placeholder}
              id={formField.id}
              secondaryFieldId={formField.secondaryFieldId}
              secondaryFieldName={formField.secondaryFieldName}
              secondaryNameFieldInputValue={
                formField.secondaryNameFieldInputValue
              }
              setInputValue={handleInputChange}
            />
          )}
          {formField.formFieldType === FormFieldType.ReadonlyDropdown && (
            <Dropdown
              formFieldType={formField.formFieldType}
              name={formField.name}
              key={formField.id}
              label={formField.label}
              placeholder={formField.placeholder}
              inputValue={formField.inputValue}
              backendValue={formField.backendValue}
              id={formField.id}
              setInputValue={handleInputChange}
              dropdownOptions={formField.dropdownOptions as DropdownOption[]}
              setADefaultIndexValue={formField.setADefaultIndexValue}
              defaultIndexValue={formField.defaultIndexValue}
            />
          )}
          {formField.formFieldType === FormFieldType.InputDropdown && (
            <InputDropdown
              formFieldType={formField.formFieldType}
              name={formField.name}
              key={formField.id}
              label={formField.label}
              placeholder={formField.placeholder}
              inputValue={formField.inputValue}
              backendValue={formField.backendValue}
              id={formField.id}
              setInputValue={handleInputChange}
              dropdownOptions={formField.dropdownOptions as DropdownOption[]}
            />
          )}
          {formField.formFieldType === FormFieldType.TextBox && (
            <TextBox
              formFieldType={formField.formFieldType}
              name={formField.name}
              key={formField.id}
              label={formField.label}
              placeholder={formField.placeholder}
              type={formField.type}
              inputValue={formField.inputValue}
              backendValue={formField.backendValue}
              id={formField.id}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange(formField.name, e.target.value)}
              hasToolTip={formField.hasToolTip}
              toolTipText={formField.toolTipText}
            />
          )}
          {formField.formFieldType === FormFieldType.PhoneInput && (
            <PhoneInput
              formFieldType={formField.formFieldType}
              name={formField.name}
              key={formField.id}
              label={formField.label}
              placeholder={formField.placeholder}
              inputValue={formField.inputValue as PhoneInputValue}
              backendValue={formField.backendValue}
              id={formField.id}
              setInputValue={handleInputChange}
              />
            )}
          {formField.formFieldType === FormFieldType.AddressAutocomplete && (
            <AddressAutocomplete
              formFieldType={formField.formFieldType}
              name={formField.name}
              key={formField.id}
              label={formField.label}
              placeholder={formField.placeholder}
              inputValue={formField.inputValue}
              backendValue={formField.backendValue}
              id={formField.id}
              setInputValue={handleInputChange}
              isPermanentAddress={formField.isPermanentAddress}
              asksForMailingAddress={formField.asksForMailingAddress}
              mailingAddressIsSameAsPermanentAddress={formField.mailingAddressIsSameAsPermanentAddress}
              userType={formField.userType as UserType}
            />
          )}
          {formField.formFieldType === FormFieldType.LoanAmount && (
            <LoanAmount
              formFieldType={formField.formFieldType}
              name={formField.name}
              key={formField.id}
              label={formField.label}
              placeholder={formField.placeholder}
              inputValue={formField.inputValue}
              backendValue={formField.backendValue}
              id={formField.id}
              setInputValue={handleInputChange}
              userType={formField.userType as UserType}
              minValue={formField.minValue}
              maxValue={formField.maxValue}
              />
            )
          }
          {!!formField.errorMessage && <span className="ErrorMessage">{formField.errorMessage}</span>}
        </>
      )}
      {children}
    </div>
  );
};

export default FormFieldContainer;