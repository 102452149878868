import styles from "./styles.module.scss";

const VerticalLines = () => {
    return (
        <div className={styles.vertLinesContainer}>
            <div className={styles.vertLinesInnerContainer}>
                <div  className={styles.vertLine} />
                <div  className={styles.vertLine} />
                <div  className={styles.vertLine} />
                <div  className={styles.vertLine} />
                <div  className={styles.vertLine} />
            </div>
        </div>
    );
};

export default VerticalLines;
