import { UserType } from "../../../types/sparrow-types";
import "./pencil-icon.styles.css";

interface PencilIconProps {
    onClick: React.MouseEventHandler<SVGSVGElement>;
    userType?: UserType;
}

const PencilIcon: React.FC<PencilIconProps> = ({ onClick, userType = "borrower" }) => {
    return (
        <svg
            className={`PencilIcon ${userType}`}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <path
                d="M15.9994 0.504883C15.102 0.504883 14.2048 0.845069 13.5247 1.52539L2.45736 12.5918C2.24738 12.8018 2.09359 13.0629 2.01205 13.3496L0.528653 18.5439C0.492069 18.6725 0.490489 18.8084 0.524076 18.9378C0.557663 19.0671 0.625197 19.1852 0.719692 19.2797C0.814187 19.3742 0.932213 19.4417 1.06156 19.4753C1.19091 19.5089 1.32688 19.5073 1.45541 19.4707L6.65072 17.9873C6.65105 17.987 6.65137 17.9867 6.6517 17.9863C6.93646 17.9045 7.19696 17.7526 7.40756 17.542L18.474 6.47461C19.8345 5.11405 19.8345 2.88595 18.474 1.52539C17.794 0.845069 16.8967 0.504883 15.9994 0.504883ZM15.9994 1.99609C16.5098 1.99609 17.0194 2.19276 17.4124 2.58594C17.4128 2.58594 17.4131 2.58594 17.4134 2.58594C18.2009 3.37337 18.2009 4.62663 17.4134 5.41406L16.4437 6.38379L13.6156 3.55566L14.5853 2.58594C14.9783 2.19276 15.489 1.99609 15.9994 1.99609ZM12.555 4.61621L15.3831 7.44434L6.34604 16.4814C6.31672 16.5106 6.27959 16.5329 6.23764 16.5449L2.34115 17.6582L3.45443 13.7607C3.45443 13.7604 3.45443 13.7601 3.45443 13.7598C3.4659 13.7195 3.4869 13.6834 3.51791 13.6523L12.555 4.61621Z"
                fill="#878F99"
            />
        </svg>
    );
};

export default PencilIcon;
