import { Amplify } from "@aws-amplify/core";
import { observer } from "mobx-react";
import { useStore } from "../../../core/providers/global.provider";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import useSaveJWT from "../../auth-component/useSaveJwt";
import { clearUserData } from "../../../core/logout";
import { amplifyConfig } from "./amplify-config";
import { AuthHandler } from "../../../core/auth";

Amplify.configure(amplifyConfig);

const AwsAuthInitializationProvider = ({ children }) => {
    const store = useStore();
    const onboarding = useOnboarding();
    const saveUser = useSaveJWT();

    const initializeAuth = async () => {
        try {
            const authenticatedUser = await AuthHandler.currentAuthenticatedUser();
            if (!authenticatedUser) return;
            saveUser(authenticatedUser);
        } catch (error) {
            clearUserData({ onboarding, store });
        }
    };

    initializeAuth();

    return children;
};

export default observer(AwsAuthInitializationProvider);
