// TODO: change path once copied back to the library
import { 
    asyncDegreeTypes, 
    asyncMajors1, 
    asyncMajors2, 
    asyncSchoolsList1, 
    asyncSchoolsList2,
    asyncAcademicPeriods1,
    asyncAcademicPeriods2,
    asyncAcademicYears1,
    asyncAcademicYears2, 
    asyncPhoneNumberTypes,
    asyncTimeZones
} from "../../../../mock-data/dropDownOptions";

export const getDegreeTypeOptions = () => {
    return asyncDegreeTypes;
}

export const getSchoolOptions = (value: string) => {
    if(value === "Graduate Degree") {
        return asyncSchoolsList2;
    }
    return asyncSchoolsList1;
}

export const getMajorOptions = (value: string) => {
    if(value === "New York University") {
        return asyncMajors2;
    }

    if(value === "University of California, Berkeley") {
        return asyncMajors1;
    }

    return asyncMajors2;
}

export const getYearInSchoolOptions = (value: string) => {
    if(value === "New York University") {
        return asyncAcademicYears2;
    }

    if(value === "University of California, Berkeley") {
        return asyncAcademicYears1;
    }

    return asyncAcademicYears2;
}

export const getAcademicPeriodOptions = (value: string) => {
    if(value === "New York University") {
        return asyncAcademicPeriods2;
    }

    if(value === "University of California, Berkeley") {
        return asyncAcademicPeriods1;
    }

    return asyncAcademicPeriods2;
}

export const getPhoneNumberTypeOptions = () => {
    return asyncPhoneNumberTypes;
}

export const getTimeZoneOptions = () => {
    return asyncTimeZones;
}
    
