import { AuthFlowLayout } from "../../common/components/auth-component/authFlowLayout";
import { ResetPassword } from "./page";

export default function ResetPasswordWrapper() {
    return (
        <AuthFlowLayout>
            <ResetPassword />
        </AuthFlowLayout>
    );
}
