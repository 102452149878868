
interface IModalSnackError {
    errorMessage: string | null
}
const ModalSnackError = ({errorMessage = null}: IModalSnackError) => {
    if (!errorMessage) {
        return <></>
    }
    return <div className={'modalSnackError'}>
        <div className="modalSnackError-content">
            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12.499" cy="12" r="11" fill="#F32B32"/>
                <path d="M12.499 15L10.8913 6.96116C10.6883 5.94659 11.4644 5 12.499 5C13.5337 5 14.3097 5.94659 14.1068 6.96116L12.499 15Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <circle cx="12.499" cy="18" r="1" fill="white"/>
            </svg>
            <span>{errorMessage}</span>
        </div>
    </div>
}

export default ModalSnackError