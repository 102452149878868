import { useStore } from "../../../../common/core/providers/global.provider";
import SlideToggle from "react-slide-toggle";
import { useEffect, useState } from "react";
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider";
import { observer } from "mobx-react";
import { RequestHistoryContainer } from "../RequestHistory/RequestHistoryContainer";
import styles from "./styles.module.scss";
import "./styles.scss";

// TODO: This needs to just be the request history container. The header should be a separate component.

export const UserDashboardHeader = observer(() => {
    const store = useStore();
    const onboarding = useOnboarding();
    const { getUserName } = store;
    const userName = getUserName();

    const [loanRequests, setLoanRequests] = useState(onboarding.loanRequests);

    useEffect(() => {
        setLoanRequests(onboarding.loanRequests);
    }, [onboarding.loanRequests]);

    return (
        <div className="user-dash-header">
            {userName && <div className="user-dash-header-name-container">
                <div className="user-dash-header-title">{`Hi, ${userName.firstName}!`}</div>
                <div className="user-dash-header-subtitle">Monitor your student loan progress.</div>
            </div>}
            <div className={styles["request-history-wrapper"]}>
                {loanRequests.length ? (
                    <>  
                        <div>
                            <p className="p-md-14-regular clr-neutral-500">Request History</p>
                        </div>
                        <div className={styles["request-history-container"]}>
                            <SlideToggle collapsed>
                                {({ toggle, setCollapsibleElement, progress, toggleState }) => (
                                    <>
                                        <RequestHistoryContainer
                                            toggle={toggle}
                                            setCollapsibleElement={setCollapsibleElement}
                                            progress={progress}
                                            loanRequests={loanRequests}
                                            toggleState={toggleState}
                                        />
                                    </>
                                )}
                            </SlideToggle>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
});