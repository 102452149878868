import './style.scss'
import {FC} from "react";
interface IBgLines {
    theme?: 'light' | 'dark'
    customClass?: string | null
}
const BgLines:FC<IBgLines> = ({theme= 'dark', customClass= null}) => {
    return <div className={`vertical-lines vertical-lines-${theme} ${customClass ? customClass : ''}`}>
        <div className="container">
            <div className="line line1"/>
            <div className="line line2"/>
            <div className="line line3"/>
            <div className="line line4"/>
            <div className="line line5"/>
        </div>
    </div>
}

export default BgLines