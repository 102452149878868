import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { getFormsConfig } from "./forms-config";

import { FormWrapper } from "../../../sparrow-ui-library/src/ui-components/form-components";
import { handleSubmission } from "../../../sparrow-ui-library/src/utils/ui-library-utils";
import { useSparrowUIContext, useSparrowUIFormsContext } from "../../../sparrow-ui-library/src/context";

import { useOnboarding } from "../../../common/core/providers/onboarding.provider";
import { PrequalUrlsEnum } from "../../../common/core/global_routing/front-end-urls";
import { NavigationFooter } from "../components/NavigationFooter";
import { UserType } from "../../../sparrow-ui-library/src/types/sparrow-types";

import { PrequalificationRequestInput } from "../../../sparrow-ui-library/src/types/sparrow-types";


export const PqAddress = observer(({ pathName }: { pathName: string }) => {
    const { forms, setForms, setShowMailingAddress, setIsMailingAddressSameAsPermanentAddress } = useSparrowUIFormsContext();
    const { displayToastPopup } = useSparrowUIContext();
    const [componentMounted, setComponentMounted] = useState(false);

    // Store variables and methods
    const {
        formData,
        getApplicableFormData,
        getApplicableUserText,
        checkPermanentAddressIsSameAsMailingAddress,
        prequalRequestHandler,
        getStepRouting,
        setPqLoader,
        checkEditsMadePostPrequalSubmission,
    } = useOnboarding();
    const { applicableFormData } = getApplicableFormData();
    const isSameAsMailingAddress = checkPermanentAddressIsSameAsMailingAddress();
    // Page specific forms
    const formsConfig = getFormsConfig({
        formData,
        applicableFormData: applicableFormData as PrequalificationRequestInput,
        getApplicableUserText,
        isSameAsMailingAddress
    });

    useEffect(() => {
        if (!componentMounted) {
            setForms([]);
            return setComponentMounted(true);
        }
        setForms(formsConfig.forms);
        /* Note: the developer here knows that this page asks for mailing address so we can assume that the mailing address field is always present
        therefore, we do not need any condition to run this check. Any change in this assumption should include a condition to run this call*/
        setShowMailingAddress(!isSameAsMailingAddress);
        setIsMailingAddressSameAsPermanentAddress(isSameAsMailingAddress);
    }, [componentMounted]);

    // Page routing
    const navigate = useNavigate();
    const { prevStep, nextStep } = getStepRouting(pathName);

    // Submit handler
    const handleSubmit = () =>
        handleSubmission({ forms, setForms, checkEditsMadePostPrequalSubmission }, async (formattedBackendForms) => {
            //Take formatted formData and send to backend
            try {
                const response = await prequalRequestHandler({
                    formattedBackendForms,
                    pathName: PrequalUrlsEnum.ADDRESS,
                });
                if (!response) return;
                // If successful, navigate to next step
                navigate(nextStep);
            } catch (err: any) {
                setPqLoader(false);
                displayToastPopup(err.userDescription as string, true);
            }
        });

    return (
        <>
            {!!forms.length && <FormWrapper forms={forms} setForms={setForms} />}
            <div style={{ marginTop: "32px" }}>
                <NavigationFooter
                    userType={formData.userType as UserType}
                    steps={{ prevStep, nextStep }}
                    handleSubmit={handleSubmit}
                />
            </div>
        </>
    );
});
