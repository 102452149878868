import { MoneyAmountInput } from "../../sparrow-ui-library/src/types/sparrow-types";
import { IncomeInput } from "../../sparrow-ui-library/src/constants/default-inputs";
import { AddressInput, CitizenshipInput, HousingInput, PrequalificationRequestInput, } from "../../sparrow-ui-library/src/types/sparrow-types";
import { OffersTablePreferences } from "../../sparrow-ui-library/src/types/sparrow-types";

export enum enumRequestStatus {
    STARTED = "started",
    SUBMITTED = "submitted",
    OFFERS = "offers",
    NO_OFFERS = "no offers",
    VALIDATION_ERROR = "validation error",
}

export enum ProgressStatuses {
    "ValidationError" = "Validation Error",
    "Prequalify" = "Pre-qualify",
    "CompareAndSelect" = "Compare And Select",
    "ApplyWithLender" = "Apply With A Lender",
    "FinalizeLoan" = "Finalize Your Loan",
    "ReceiveFunds" = "Receive Your Funds",
    "Complete" = "Complete",
}
export type OfferInput = {
    id: string;
    userId?: string;
    cosignerId?: string;
    requestId: string;
    lender: string;
    createdAt?: number;
    amount: MoneyAmountInput;
    offerCode?: string;
    term: number;

    declined?: boolean;
    converted?: boolean;

    explored?: boolean;
    exploredAt?: number;
    exploredAtTimes?: number[];

    cosignerExplored?: boolean;
    cosignerExploredAt?: number;
    cosignerExploredAtTimes?: number[];

    forYou?: boolean;
    cosignerForYou?: boolean;

    personalizedUrl?: string;
    imageUrl?: string;

    submissionId?: string;

    pinned?: boolean;
    cosignerPinned?: boolean;

    bookmarked?: boolean;
    cosignerBookmarked?: boolean;
};

export enum PaymentPlans {
    "Immediate" = 1,
    "Immediate Repay",
    "Interest Only",
    "Flat Payment",
    "Minimum $25 Payment",
    "Deferred",
    "Immediate interest and principal payments",
    "Income Based",
}

export type PaymentPlansString = keyof typeof PaymentPlans;

export type StudentLoanOfferInput = OfferInput & {
    paymentPlan: PaymentPlansString;
    type: "PSL" | "SLR";
};

export enum LenderStatuses {
    STARTED = "started",
    SUBMITTED = "submitted",
    DENIED = "denied",
    CANCELLED = "cancelled",
    APPROVED = "approved",
    SIGNED = "signed",
    DISBURSED = "disbursed",
}

type PrequalifyProgress = {
    request: PrequalificationRequestInput;
};

type CompareAndSelectData = {
    offersData: {
        type: string;
        numberOfOffers?: string;
        lowestFixedApr?: string;
        lowestVariableApr?: string;
        lowestMonthlyPaymentInSchool?: string;
        lowestMonthlyPaymentAfterGrad?: string;
        lowestTotalLoanCost?: string;
    };
    offerExplored: boolean; //indicates if a user creator or included user has explored an offer
};

export type ApplyWithLenderDataLenderData = {
    lenderName: string;
    loanSize?: number;
    lenderStatus: LenderStatuses;
};

type ApplyWithLenderData = {
    lastExploredOffer: StudentLoanOfferInput;
    lenderData: ApplyWithLenderDataLenderData;
    lenderLogo: string;
    requestCancelled: boolean;
    requestDenied: boolean;
};

type FinalizeLoanData = ApplyWithLenderData;
type ReceiveFundsData = ApplyWithLenderData;

export interface RoadmapProgress {
    prequalify: PrequalifyProgress;
    applicationStatus: ProgressStatuses;
    compareAndSelect: CompareAndSelectData;
    applyWithLender: ApplyWithLenderData;
    finalizeLoan: FinalizeLoanData;
    receiveFunds: ReceiveFundsData;
}

export interface ProgressRoadmap {
    requestId: string;
    progress: RoadmapProgress;
}

export interface IRefAmount {
    currency: string;
    amount: number | string;
    display: string;
}

export interface IRefCosigner {
    firstName: string;
    lastName: string;
    dob: string | number;
    email: string;
    citizenshipStatus: CitizenshipInput;
    permanentAddress: AddressInput;
    mailingAddress: AddressInput;
    income: IncomeInput;
    additionalIncome?: IncomeInput;
    employment: string;
    housingPayment: MoneyAmountInput;
    ssn: string;
    confirmed: boolean;
    studentSignedUp: boolean;
    submitted?: boolean;
    housing?: HousingInput;
    offersQuizAnswer: OffersTablePreferences | null;
}
export interface IExpandedSearchNeeded {
    couldBeShown: boolean;
    shouldBeShown: boolean;
}
