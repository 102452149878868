import "../../style.scss";

interface PinProps {
    icon: any;
    toggle: Function;
    isExpand: boolean;
}

export const OfferCardHeaderPinButton: React.FunctionComponent<PinProps> = ({
    icon,
    toggle,
    isExpand,
}) => {
    return (
        <button className={`pin-button ${isExpand ? "active" : ""}`} onClick={() => toggle()}>
            <img src={icon} alt="pin" />
            {isExpand ? "Compare" : ""}
        </button>
    );
};
