import { EmployedTypes, OtherIncomeTypes } from "../../../types/sparrow-types";

export const employedTypes = [
    { label: "Employed - Full Time", value: EmployedTypes.FullTime },
    { label: "Employed - Part Time", value: EmployedTypes.PartTime },
    { label: "Employed - Self Employed", value: EmployedTypes.SelfEmployed },
    { label: "Employed - Military", value: EmployedTypes.Military },
    { label: "Employed - Future Employed", value: EmployedTypes.FutureEmployed },
    { label: "Unemployed", value: OtherIncomeTypes.Unemployed },
    { label: "Retired", value: OtherIncomeTypes.Retired },
];