import { Route, Routes } from "react-router-dom";

import {
    prequalificationAddressRoutingUrl,
    prequalificationAmountRoutingUrl,
    prequalificationCitizenshipRoutingUrl,
    prequalificationDegreesRoutingUrl,
    prequalificationIncomeRoutingUrl,
    prequalificationReviewRoutingUrl,
    prequalificationExpandedSearchRoutingUrl,
} from "../../common/core/routes/routing-urls";
import { PqAmount } from "./amount/page";
import { PqCitizenship } from "./citizenship/page";
import { PqAddress } from "./address/page";
import { PqIncome } from "./income/page";
import { PqDegrees } from "./degrees/page";
import { PqReview } from "./review/page";
import { ExpandedSearch } from "./expanded-search/page";

export const PrequalificationRoutes = ({pathName}: {pathName: string}) => {
    return (
        <>
            <Routes>
                <Route path={prequalificationAmountRoutingUrl} element={<PqAmount pathName={pathName} />} />
                <Route path={prequalificationCitizenshipRoutingUrl} element={<PqCitizenship pathName={pathName} />} />
                <Route path={prequalificationAddressRoutingUrl} element={<PqAddress pathName={pathName} />} />
                <Route path={prequalificationIncomeRoutingUrl} element={<PqIncome pathName={pathName} />} />
                <Route path={prequalificationDegreesRoutingUrl} element={<PqDegrees pathName={pathName} />} />
                <Route path={prequalificationReviewRoutingUrl} element={<PqReview pathName={pathName} />} />
                <Route path={prequalificationExpandedSearchRoutingUrl} element={<ExpandedSearch pathName={pathName}/>} />
            </Routes>
        </>
    );
};
