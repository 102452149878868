import { GrayQuestionmarkIcon } from "../../assets/icons";
import "./form-label.styles.css";

const FormLabel = ({
    hasTooltip =  false,
    tooltipText =  "",
    label,
    id
}) => {

    return (
        <label 
        className="FieldLabel" 
        htmlFor={id}>
            {label}
            {hasTooltip && (
                <div className = "question-tt-container">
                    <div className="callout"><span>{tooltipText}</span></div>
                    <GrayQuestionmarkIcon />
                </div>
            )}
        </label>
    );
};

export default FormLabel;
