import { Button, ButtonTypes } from "../../../../../../common/components/buttons/button/Button";
import { userInclusionSelectUrl } from "../../../../../../common/core/routes/navigation-urls";
import { useOnboarding } from "../../../../../../common/core/providers/onboarding.provider";
import { useNavigate } from "react-router-dom";
import { UserType } from "../../../../../../common/constants/defaultInputs";
import { observer } from "mobx-react";


export const AddCosigner = observer(() => {
    const onboarding = useOnboarding();
    const navigate = useNavigate();

    return (
        <div className="flex-container-1 flex-col-reverse-990" style={{gap: "24px"}}>
            <Button
                buttonType={ButtonTypes.SECONDARY}
                userType={onboarding.formData.userType as UserType}
                onClick={() => navigate(userInclusionSelectUrl)}
            >
                Add a cosigner
            </Button>
            <p className="p-md-14-regular clr-neutral-500">
                Including a creditworthy cosigner may increase the number of rates you pre-qualify
                for and improve the terms you receive from lenders. Check out our help center for
                more information on cosigners.
            </p>
        </div>
    );
});

