import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { getFormsConfig } from "./forms-config";
import { FormWrapper } from "../../../sparrow-ui-library/src/ui-components/form-components";
import { handleSubmission } from "../../../sparrow-ui-library/src/utils/ui-library-utils";
import { FormProps } from "../../../sparrow-ui-library/src/types/form-types";

import { useOnboarding } from "../../../common/core/providers/onboarding.provider";
import { PrequalUrlsEnum } from "../../../common/core/global_routing/front-end-urls";
import { ReviewContent } from "./components/review-content/ReviewContent";
import { ReviewCards } from "./components/review-information/ReviewCards";
import { NavigationFooter } from "../components/NavigationFooter";
import { UserType } from "../../../sparrow-ui-library/src/types/sparrow-types";

import { useSparrowUIFormsContext, useSparrowUIContext} from "../../../sparrow-ui-library/src/context";
import { PrequalificationRequestInput } from "../../../sparrow-ui-library/src/types/sparrow-types";
import { dateToEpoch } from "../../../sparrow-ui-library/src/utils/ui-library-utils/get-function-for-backend-value-format/input-format-for-backend-functions";

export const PqReview = observer(({ pathName }: { pathName: string }) => {
    const [componentMounted, setComponentMounted] = useState(false);
    const [formsRendered, setFormsRendered] = useState(false);
    // Store variables and methods
    const onboarding = useOnboarding();
    const { forms, setForms } = useSparrowUIFormsContext();
    const { displayToastPopup, displayReviewCards, showReviewCards } = useSparrowUIContext();

    const { applicableFormData } = onboarding.getApplicableFormData();

    useEffect(() => {
        // Need to clear out the prior forms before running the rest of the code, otherwise radioButtons may be undefined
        if (!componentMounted) {
            onboarding.setReviewStepReached(true);
            setComponentMounted(true);
            return setForms([]);
        }


        const formsConfig = getFormsConfig({
            formData: onboarding.formData,
            companyName: onboarding.companyName,
            applicableFormData: applicableFormData as PrequalificationRequestInput,
            getApplicableUserText: onboarding.getApplicableUserText,
            creatorIsIncludingUser: onboarding.creatorIsIncludingUser,
        });

        setForms(formsConfig.forms);
        setFormsRendered(true);
    }, [componentMounted]);

    useEffect(() => {
        if(!formsRendered) return;
        const formFields: {dob: string | number; ssn?: string } = (forms[0] as FormProps).independentFields
        .reduce((acc, field) => {
            return { ...acc, [field.name]: field.inputValue };
        }, {dob: ""});

        formFields.dob = dateToEpoch(formFields.dob as string);
        if(formFields.dob !== (applicableFormData as PrequalificationRequestInput).dob){
            onboarding.setEditsMadePostPrequalSubmission(true);
        } else {
            onboarding.setEditsMadePostPrequalSubmission(false);
        }
        // This is only true if the user has previously submitted a prequal request. If this is absent, we can assume that it's empty or has been cleared out
        if(!formFields.ssn?.includes("•")){
            onboarding.setEditsMadePostPrequalSubmission(true);
        }

    }, [formsRendered,forms])

    // Page routing
    const navigate = useNavigate();
    const { prevStep } = onboarding.getStepRouting(pathName);
    
    const [disclosureToggle, setDisclosureToggle] = useState(!onboarding.isRequestSubmission());

    useEffect(() => {
        setDisclosureToggle(!onboarding.isRequestSubmission());
    }, [onboarding.editsMadePostPrequalSubmission]);

    const handleDisclosureAgreement = () => {
        if (disclosureToggle) return true;
        displayToastPopup("You must read and agree to the disclosure");
        return false;
    };

    // Submit handler
    const handleSubmit = () => {
        handleSubmission({ forms, setForms }, async (formattedBackendForms) => {
            if (!handleDisclosureAgreement()) return;
            //Take formatted formData and send to backend
            try {
                const url = await onboarding.prequalRequestHandler({
                    formattedBackendForms,
                    pathName: PrequalUrlsEnum.REVIEW,
                });
                
                //if url is undefined, it means the WebSocket request timed out   
                if (!url){
                    onboarding.setOffersLoader(false);
                    displayToastPopup("WebSocket connection timed out without response. Please resubmit request or try again later.", true);
                    return;
                };

                navigate(url as string);
            } catch (err: any) {
                onboarding.setPqLoader(false);
                onboarding.setOffersLoader(false);
                displayToastPopup(err.userDescription as string, true);
            }
        });
    };

    return (
        <>
            {showReviewCards && <ReviewCards />}

            <div style={{ marginTop: "16px" }} />

            {!!forms.length && <FormWrapper forms={forms} setForms={setForms} />}

            <div style={{ marginTop: "16px" }} />

            <ReviewContent toggle={disclosureToggle} setToggle={setDisclosureToggle} />

            <div className="ReviewCardsFooter">
                <button className="ReviewCardsButton" onClick={() => displayReviewCards(!showReviewCards)}>
                    {showReviewCards ? "Collapse my information" : "Review my information"}
                </button>
                <NavigationFooter
                    userType={onboarding.formData.userType as UserType}
                    steps={{ prevStep, nextStep: undefined }}
                    handleSubmit={handleSubmit}
                />
            </div>
        </>
    );
});