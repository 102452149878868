import { FC } from "react";

import { observer } from "mobx-react";

import { AdvertiserDisclosureButton } from "./AdvertiserDisclosure";
import { HeaderBarLinks } from "./HeaderBarLinks";
import { HeaderButton } from "./HeaderButton";
import { PAAASLogo } from "../PAAASLogo/index";

import "./style.scss";
import { HeaderBarDropdown } from "./HeaderBarDropdown";
import { dashboardUrl, loginStepBorrower } from "../../core/routes/navigation-urls";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { ModalMenuType } from "../../types/paaas/custom-menu-props";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, ButtonTypes } from "../buttons/button/Button";
import { UserType } from "../../../sparrow-ui-library/src/types/sparrow-types";
import { isPAAASLandingUrl, getRouteForPAAASLogo } from "../../utils/routing";

export const PAAASHeaderBar: FC = observer(() => {
    const { pathname } = useLocation();
    const { paaasCustomHeader, paaasCustomization, jwt } = useOnboarding();
    const navigate = useNavigate();

    return (
        <div className={"sign-top-header"}>
            {/*If current page is the dashboard page,then on click of PAAAS Logo, route user to PAAAS landing page*/}
            <PAAASLogo
                navigateTo={getRouteForPAAASLogo({
                    pathname,
                    displayLandingPageView:
                        paaasCustomization?.toggleableViews?.displayLandingPageView,
                })}
            />
            <div className="sign-top-header_menu">
                {paaasCustomHeader?.menu.items &&
                    paaasCustomHeader.menu.items.map((menuItem) => {
                        return menuItem.type === ModalMenuType.Link ? (
                            <HeaderBarLinks key={menuItem.id} button={menuItem} />
                        ) : (
                            <HeaderBarDropdown key={menuItem.id} menu={menuItem} />
                        );
                    })}
            </div>
            <div className={"sign-top-button-group"}>
                {!paaasCustomHeader?.disableAdvertiserDisclosure && <AdvertiserDisclosureButton />}
                {paaasCustomHeader?.button?.isEnableCustomButton && (
                    <HeaderButton button={paaasCustomHeader.button} />
                )}
                {isPAAASLandingUrl(pathname) && (
                    <Button
                        buttonType={ButtonTypes.PRIMARY}
                        userType={UserType.BORROWER}
                        showIcon={true}
                        onClick={() => navigate(jwt ? dashboardUrl : loginStepBorrower)}
                    >
                        {jwt ? "Dashboard" : "Sign In"}
                    </Button>
                )}
            </div>
        </div>
    );
});

