import React, { useEffect, memo } from "react";
import "chartjs-adapter-moment";
import "./style.scss";
import { LineChart } from "./LineChart";
import { BarChart } from "./BarChart";
import { SelectedRateCard } from "./SelectedRateCard";
import { useMediaQuery } from "react-responsive";
import { Scrollbar } from "react-scrollbars-custom";

interface RateChartsProps {
    offerData: any[];
    chartSelectedRate: number | null;
    setChartSelectedRate: (v: number | null) => void;
    onPinned: Function;
    isMobileView?: boolean;
}

const RateCharts: React.FunctionComponent<RateChartsProps> = ({
    offerData,
    chartSelectedRate,
    setChartSelectedRate,
    onPinned,
    isMobileView = false,
}) => {
    useEffect(() => {
        setChartSelectedRate(null);
    }, [offerData]);

    const colors = ["#E6C414", "#F19048", "#6DB353", "#A461E8", "#6177E8"];

    function obtainProcessedColors() {
        if (chartSelectedRate !== null) {
            const grayColors = ["#CCD6DD", "#CCD6DD", "#CCD6DD", "#CCD6DD", "#CCD6DD"];
            grayColors[chartSelectedRate] = colors[chartSelectedRate];
            return grayColors.slice(0, offerData.length);
        }
        return colors.slice(0, offerData.length);
    }

    const isLess1200 = useMediaQuery({
        query: "(max-width: 1200px)",
    });

    return (
        <>
            <div className={`charts ${isMobileView ? "charts__mobile-view" : ""}`}>
                {(!isLess1200 || isMobileView) && (
                    <LineChart offerData={offerData} colors={obtainProcessedColors()} />
                )}
                <BarChart offerData={offerData} colors={obtainProcessedColors()} />
            </div>
            <div className={"selected-rate-cards-container"}>
                <Scrollbar
                    momentum={true}
                    noScrollY={true}
                    style={{ width: "100%", height: "65px" }}
                >
                    <div className={"selected-rate-cards-container__content"}>
                        {offerData.map((offer, index) => (
                            <SelectedRateCard
                                setChartSelectedRate={setChartSelectedRate}
                                cardIndex={index}
                                colorIndex={
                                    chartSelectedRate === null || chartSelectedRate === index
                                        ? index
                                        : null
                                }
                                key={offer.id}
                                lender={offer.lender}
                                apr={
                                    offer.loanType === "fixed" ? offer.fixedAPR : offer.startingAPR
                                }
                                term={offer.term}
                                offer={offer}
                                onPinned={onPinned}
                            />
                        ))}
                    </div>
                </Scrollbar>
            </div>
        </>
    );
};

export default memo(RateCharts);
