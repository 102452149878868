import { FormFieldName, FormFieldProps, PhoneInputValue } from "../../../../types/form-types";
import { epochToFormattedDateString, removeDollarFormatting } from "../../../general-utils";
import { dateToEpoch, removePhoneNumberFormatting } from "../../get-function-for-backend-value-format/input-format-for-backend-functions";

export const emailMessage = (value: string) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (value.length === 0) {
        return "Please enter your email";
    }
    if (!emailRegex.test(value)) {
        return "Please enter a valid email";
    }
    return null;
};

const isTestCaseSSN = (ssn: string): boolean => {
    if (ssn.length === 6) {
        return ssn === "100-00-0";
    }

    if (ssn.length === 11) {
        return ssn.startsWith("100-00-0");
    }

    return false;
}

export const ssnMessage = (value: string) => {
    // Inclusive of dashes
    if(value.length === 11) return null;
    
    if (Number(value[0]) === 9) {
        // SSA will not issue SSNs beginning with the number “9”
        return 'SSN cannot begin with the number "9"';
    }

    // Check for "666" and "000" in positions 1 – 3
    if (value.length >= 3) {
        const firstThreeDigits = value.slice(0, 3);
        if (firstThreeDigits === "666") {
            return 'SSN cannot begin with the number "666"';
        }
        if (firstThreeDigits === "000") {
            return 'SSN cannot begin with the number "000"';
        }
    }

    // Check for "00" in positions 4 – 5
    if (value.length >= 6) {
        const groupNumber = value.slice(4, 6);
        if (groupNumber === "00" && !isTestCaseSSN(value)) {
            return 'SSN cannot have a group number of "00"';
        }
    }

    // Check for "0000" in positions 6 – 9
    if (value.length >= 11) {
        const serialNumber = value.slice(7, 11);
        if (serialNumber === "0000" && !isTestCaseSSN(value)) {
            return 'SSN cannot have a serial number of "0000"';
        }
    }

    return null;
}

export const isDateValid = (dateString: string) => {
    // Regular expression to validate the date format (MM/DD/YYYY)
    const dateRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2]\d|3[0-1])\/\d{4}$/;

    // Check if the date string matches the required format
    if (!dateRegex.test(dateString)) {
        return "Please ensure the date is in the format MM/DD/YYYY";
    }

    const [month, day, year] = dateString.split('/').map(Number);

    // Check if the day is valid for the given month and year
    const daysInMonth = getDaysInMonth(month, year);
    if (day > daysInMonth) {
        return "The date provided is invalid";
    }

    return null;
};

const isLeapYear = (year: number): boolean => {
    // A leap year is divisible by 4, except for years that are divisible by 100 but not divisible by 400
    return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
};

const getDaysInMonth = (month: number, year: number): number => {
    // Array with the number of days in each month (non-leap year)
    const daysInMonth = [
        31, // January
        28, // February (non-leap year)
        31, // March
        30, // April
        31, // May
        30, // June
        31, // July
        31, // August
        30, // September
        31, // October
        30, // November
        31, // December
    ];

    // Check for February in a leap year (29 days)
    if (month === 2 && isLeapYear(year)) {
        return 29;
    }

    // Return the number of days for the given month
    return daysInMonth[month - 1];
};

export const incomeMessage = (value: string) => {
    if (removeDollarFormatting(value) > 2000000000) {
        return "Income cannot exceed $20,000,000";
    }

    return null;
}

export const phoneNumberMessage = (value: PhoneInputValue | string) => {
    const phoneNumber = typeof value === "string" ? value : value.phoneNumber;
    const phoneNumberWithoutCountryCode = removePhoneNumberFormatting(phoneNumber) as string;

    if (!phoneNumberWithoutCountryCode) {
        return "Please enter your phone number";
    }

    if (phoneNumberWithoutCountryCode.length < 10) {
        return "Please enter a valid phone number";
    }

    return null;
}

export const zipCodeMessage = (value: string, name: string, form: FormFieldProps[]) => {
    const countryCode = form.find(field => field.name === FormFieldName.CountryCode)?.inputValue;
    if (!countryCode) return null;

    if (countryCode !== "US") {
        return value.length > 20 ? "Zip code cannot exceed 20 characters" : null;
    }

    if (value.length !== 5) {
        return "Please enter a valid five digit US zip code";
    }

    return null;
}

export const loanAmountMessage = (value: string) => {
    if (removeDollarFormatting(value) / 100 < 1000) {
        return "The minimum loan size is $1,000";
    }

    return null;
}

export const gpaMessage = (value: string) => {
    if (Number(value) > 5) {
        return "GPA cannot exceed 5.00";
    }

    if(value.length < 4) {
        return "Please enter a three digit GPA";
    }

    return null;
}

export const expandedSearchDateMessage = (value: string, name: FormFieldName) => {
    if (value.length !== 10) {
        return "Please enter a date in the format MM/DD/YYYY";
    }

    const esDates = JSON.parse(sessionStorage.getItem("esDates") as string);
    const {earliestStartDate, latestStartDate, earliestEndDate, latestEndDate} = esDates;
    
    const epochDate = dateToEpoch(value);

    if(name === FormFieldName.ExpandedSearchSchoolYearStartDate){
        if(epochDate < earliestStartDate){
            return `The earliest start date is ${epochToFormattedDateString(earliestStartDate)}`;
        }

        if(epochDate > latestStartDate){
            return `The latest start date is ${epochToFormattedDateString(latestStartDate)}`;
        }
    }

    if(name === FormFieldName.ExpandedSearchSchoolYearEndDate){
        if(epochDate < earliestEndDate){
            return `The earliest end date is ${epochToFormattedDateString(earliestEndDate)}`;
        }

        if(epochDate > latestEndDate){
            return `The latest end date is ${epochToFormattedDateString(latestEndDate)}`;
        }
    }
 
    return null;
}


export const textBoxMessage = (value: string, name: FormFieldName, form?: FormFieldProps[]) => {

    if (value.length === 0) {
        switch (name) {
            case FormFieldName.FirstName:
                return "Please enter your first name";
            case FormFieldName.LastName:
                return "Please enter your last name";
            case FormFieldName.SSN:
                return "Please enter your 9-digit SSN";
            case FormFieldName.Email:
                return emailMessage(value);
            case FormFieldName.EmploymentIncome:
                return "Please enter your annual income";
            case FormFieldName.DOB:
                return "Please enter a date in the format MM/DD/YYYY";
            case FormFieldName.AddressLine1:
                return "Please enter your address";
            case FormFieldName.AddressLine2:
            return "";
            case FormFieldName.City:
                return "Please enter your city";
            case FormFieldName.State:
                return "Please enter your state";
            case FormFieldName.Zip:
                return "Please enter your zip code";
            case FormFieldName.LoanAmount:
                return "Please enter a loan amount";
            case FormFieldName.HousingPaymentAmount:
                return "Please enter an amount. $0 is allowed";
            case FormFieldName.IncomeAmount:
                return "Please enter an amount. $0 is allowed";
            case FormFieldName.ExpandedSearchGpaCumulative:
                return "Please enter a cumulative GPA";
            case FormFieldName.ExpandedSearchGpaLastSemester:
                return "Please enter the last semester GPA";
            case FormFieldName.ExpandedSearchHoursCompletedCurrentSemester:
                return "Please enter the number of hours you completed last semester";
            case FormFieldName.ExpandedSearchSchoolYearStartDate:
                return "Please enter a date in the format MM/DD/YYYY";
            case FormFieldName.ExpandedSearchSchoolYearEndDate:
                return "Please enter a date in the format MM/DD/YYYY";
            default:
                return "Please enter a value";
        }
    }

    switch (name) {
        case FormFieldName.Email:
            return emailMessage(value);
        case FormFieldName.SSN:
            return ssnMessage(value);
        case FormFieldName.DOB:
            return isDateValid(value);
        case FormFieldName.EmploymentIncome:
            return incomeMessage(value);
        case FormFieldName.IncomeAmount:
        return incomeMessage(value);
        case FormFieldName.Zip:
            return zipCodeMessage(value, name, form as FormFieldProps[]);
        case FormFieldName.LoanAmount:
            return loanAmountMessage(value);
        case FormFieldName.ExpandedSearchGpaCumulative:
            return gpaMessage(value);
        case FormFieldName.ExpandedSearchGpaLastSemester:
            return gpaMessage(value);
        case FormFieldName.ExpandedSearchSchoolYearStartDate:
            return expandedSearchDateMessage(value, name);
        case FormFieldName.ExpandedSearchSchoolYearEndDate:
            return expandedSearchDateMessage(value, name);
        default:
            return null;
    }

}

export const employerNameMessage = (value: string) => {
    if (value.length === 0) {
        return "Please enter your employer's name";
    }
    return null;
}

export const amountMessage = (value: string) => {
    if (value.length === 0) {
        return "Please enter an amount";
    }
    return null;
}


export const dropdownMessage = (value: string | boolean) => {
    if (!value && value !== false ) {
        return "Please select an option";
    }
    return null;
}