import {FC} from "react";
import './style.scss'
import BgLines from "../BgLines";
interface ICustomBg {
    theme?: 'dark' | 'grey' | 'white'
    hasTopBar?: boolean
    hasBottomBar?: boolean
    topBarColor?: string
    bottomBarColor?: string
    withoutTopBorder?: boolean
    barSize?: 'wide' | 'regular'
    topBarWidth?: string
    bottomBarWidth?: string
    zIndex?: number
}
const CustomBg:FC<ICustomBg> = (
    {
        theme = 'grey',
        hasTopBar= false,
        bottomBarColor,
        hasBottomBar= false,
        withoutTopBorder= false,
        topBarColor,
        barSize = 'regular',
        topBarWidth,
        bottomBarWidth,
        zIndex
    }) => {

    return <div className={`custom-bg custom-bg-${theme}`} style={{zIndex: zIndex}}>
        <div className={`custom-bg-top-border ${withoutTopBorder ? 'straight' : ''}`}>
            {hasTopBar && 
                <div 
                    className={`custom-bg-bar custom-bg-bar-top custom-bg-bar-size-${barSize}`} 
                    style={{
                        background: topBarColor && topBarColor,
                        width: topBarWidth && topBarWidth,
                    }}
                />}
        </div>
        <div className="custom-bg-center-border"/>
        <div className="custom-bg-bottom-border">
            {hasBottomBar && 
                <div 
                    className={`custom-bg-bar custom-bg-bar-bottom custom-bg-bar-size-${barSize}`} 
                    style={{
                        backgroundColor: bottomBarColor && bottomBarColor,
                        width: bottomBarWidth && bottomBarWidth
                    }}
                />}
        </div>
        <BgLines theme={theme === 'dark' ? 'light' : 'dark'}/>


    </div>
}

export default CustomBg