import { useState } from "react";
import { observer } from "mobx-react";
import { RadioButtons } from "../../common/components/RadioButtons/RadioButtons";
import { useNavigate } from "react-router-dom";
import { useOnboarding } from "../../common/core/providers/onboarding.provider";
import { Button, ButtonTypes } from "../../common/components/buttons/button/Button";
import { PrequalUrlsEnum } from "../../common/core/global_routing/front-end-urls";

import graduationHatActive from "../../assets/icons/lottie-icons/graduation-hat-(student loan).json";
import cosignerPersonActive from "../../assets/icons/lottie-icons/cosigner-parent.json";
import graduationHat from "../../assets/images/icons/graduationHat.svg";
import cosignerPerson from "../../assets/images/icons/cosignerParent.svg";
import { UserType } from "../../common/constants/defaultInputs";
import { ButtonWrapper } from "../../common/components/buttons/button/ButtonWrapper";
import ContentLayout from "../../common/components/layouts/content-layout";
import { isMarketplaceEnv } from "../../common/utils/config";
import { UndergradLoanIcon } from "../../sparrow-ui-library/src/assets/icons/menu-icons";
import { CosignerIcon } from "../../sparrow-ui-library/src/assets/icons/cosigner-icon";
import { useSparrowUIContext } from "../../sparrow-ui-library/src/context";

const radioButtonOptions = () => {
    const isMarketplace = isMarketplaceEnv();

    return [
        {
            id: "borrower",
            name: "borrower",
            isChecked: false,
            value: { title: "Borrower (often a student)" },
            icon: graduationHat,
            activeIcon: isMarketplace ? graduationHatActive : undefined,
            paaasSvg: !isMarketplace ? UndergradLoanIcon : undefined,
        },
        {
            id: "cosigner",
            name: "cosigner",
            isChecked: false,
            value: { title: "Cosigner (often a parent)" },
            icon: cosignerPerson,
            activeIcon: isMarketplace ? cosignerPersonActive : undefined,
            paaasSvg: !isMarketplace ? CosignerIcon : undefined,
        },
    ];
};

export const SelectUserType = observer(() => {
    const navigate = useNavigate();
    const { displayToastPopup } = useSparrowUIContext();
    const onboarding = useOnboarding();
    const [userType, setUserType] = useState(null);

    const onKeyEnterPressOnButton = (e: React.KeyboardEvent | KeyboardEvent) => {
        if (e.key === "Enter") {
            return handleContinue();
        }
    };

    const handleContinue = () => {
        if(!userType) return displayToastPopup("Please select a user type");
        //set user type based on form selection
        onboarding.setUserType(userType);
        //if a borrower already has a loan request in progress, we need to check if it's the same type of loan they're trying to apply for
        const isBorrowerAndHasLoanRequest = onboarding.loanRequests.some((loanRequest) => {
            return (
                loanRequest.userType === "borrower" &&
                loanRequest.type === onboarding.formData.type &&
                loanRequest.status === "started"
            );
        });
        //show error if borrower has both PSL and SLR loan requests in progress
        if (userType === "borrower" && isBorrowerAndHasLoanRequest) {
            displayToastPopup(
                `You already have a ${
                    onboarding.formData.type === "PSL"
                        ? "private student loan"
                        : "student loan refinancing"
                } request in progress. Please complete that request before starting a new one.`
            );
            return;
        }


        navigate(PrequalUrlsEnum.AMOUNT);
    };

    return (
        <ContentLayout
            pageText={{
                title: "Are you the borrower or cosigner?",
                subtitle: "You can start a pre-qualification request as a borrower or a cosigner.",
            }}
        >
            <div className="step-form-body">
                <RadioButtons
                    options={radioButtonOptions()}
                    setForm={setUserType}
                    customCheckBorderRadius={7}
                    userType={UserType.BORROWER}
                />
            </div>

            <div className="step-form-footer">
                <ButtonWrapper>
                    <Button
                        id="button"
                        tabIndex={0}
                        onKeyPress={(e) => onKeyEnterPressOnButton(e)}
                        onClick={handleContinue}
                        showIcon={true}
                        buttonType={ButtonTypes.PRIMARY}
                    >
                        Continue
                    </Button>
                </ButtonWrapper>
            </div>
        </ContentLayout>
    );
});
