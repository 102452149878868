export function convertStringOrNumberToNumber(input: string | number) {
    if (typeof input === "string") {
        return parseInt(input);
    }
    return input;
}

export function convertNumberOfDaysIntoXYearsYMonthsZDays(days: number) {
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + days);

    const startYear = startDate.getFullYear();
    const daysInFebuary =
        (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
    const daysInMonth = [31, daysInFebuary, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let yearDiff = endDate.getFullYear() - startYear;
    let monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
    }
    let dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
        if (monthDiff > 0) {
            monthDiff--;
        } else {
            yearDiff--;
            monthDiff = 11;
        }
        dayDiff += daysInMonth[startDate.getMonth()];
    }

    return {
        years: yearDiff,
        months: monthDiff,
        days: dayDiff,
    };
}
