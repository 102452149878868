import "./style.scss";
import { useEffect, useState, useRef } from "react";
import { UserType } from "../../../constants/defaultInputs";
import { useStore } from "../../../core/providers/global.provider";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import {
    IQuizPutInput,
} from "../../../core/services/types/offers-api-types";
import { RadioButtons } from "../../RadioButtons/RadioButtons";
import { Button, ButtonTypes } from "../../buttons/button/Button";
import {
    radioOption,
    radioPSLButtonOptions,
    radioSLRButtonOptions,
} from "../../../../app/set-preferences/page";
import { PostQuizAnswer } from "../../../core/services/sparrow-api-handlers/offers-api-handlers";
import { errorLogs } from "../../../utils/global";
import { observer } from "mobx-react";

import retakeQuizBackground from "../../../../assets/images/backgrounds/retakeQuizBackground.svg";
import retakeQuizBackgroundWhite from "../../../../assets/images/backgrounds/retakeQuizBackgroundWhite.svg";
import { OffersTablePreferences } from "../../../../sparrow-ui-library/src/types/sparrow-types";
import { PrefModalPAAASBackground } from "../../../../sparrow-ui-library/src/assets/icons/pref-modal-paaas-background/PrefModalPAAASBackground";
import { isMarketplaceEnv } from "../../../utils/config";


export const SetPreferencesModal = observer(() => {
    const onboarding = useOnboarding();
    const store = useStore();

    const [selection, setSelection] = useState<OffersTablePreferences>(
        (onboarding.formData.userType === UserType.BORROWER
            ? onboarding.formData.offersQuizAnswer
            : onboarding.formData.cosigner?.offersQuizAnswer) ??
            OffersTablePreferences.LOWEST_TOTAL_COST
    );

    const getQuizAnswer = async () => {
        try {
            const requestBody: IQuizPutInput = {
                quizAnswerInput: {
                    id: onboarding.formData.id as string,
                    answer: selection as OffersTablePreferences,
                },
                token: onboarding.jwt as string,
            };

            const quizOutput = await PostQuizAnswer(requestBody);
            if (!quizOutput) return undefined;

            return quizOutput.offersQuizAnswer;
        } catch (err) {
            errorLogs("PostQuizAnswer", err);
            store.setSnackError("Something went wrong. Please try again later.", "error");
        }
    };

    const handleContinue = async () => {
        if (!selection) return;
        store.setLoading(true);
        const offersQuizAnswer = await getQuizAnswer();

        if (!offersQuizAnswer) {
            store.setLoading(false);
            return;
        }

        onboarding.setFormQuizAnswer(offersQuizAnswer);

        store.setLoading(false);
        store.modalResetData();
    };

    const radioButtonOptions = () => {
        return onboarding.formData.type === "PSL"
            ? radioPSLButtonOptions(onboarding.formData.userType as UserType)
            : radioSLRButtonOptions(onboarding.formData.userType as UserType);
    };
    const modalRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                store.modalResetData();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalRef]);

    function setCurrentSelection(currentOptions: radioOption[]) {
        const updatedOptions: radioOption[] = [];
        for (const option of currentOptions) {
            if (option.name === selection) {
                updatedOptions.push({ ...option, isChecked: true });
            } else {
                updatedOptions.push({ ...option, isChecked: false });
            }
        }
        return updatedOptions;
    }

    const getTopBackground = () => {
        const isMarketplace = isMarketplaceEnv();
        return isMarketplace ? (
            <>
                <img alt="background" src={retakeQuizBackground} className="retake-background" />
                <img
                    alt="background"
                    src={retakeQuizBackgroundWhite}
                    className="retake-background-white"
                />
            </>
        ) : (
            <div className="retake-background">
                <PrefModalPAAASBackground userType={onboarding.formData.userType as UserType}/>
            </div>
        );
    };


    return (
        <>
            <div className={"retake-quiz-modal"} ref={modalRef}>
                {getTopBackground()}
                <div className="retake-title-container">
                    <div className="retake-title">Set Your Preferences</div>
                    <div className="retake-subtitle">What’s your primary objective?</div>
                </div>
                <div className="retake-radio-buttons">
                    <RadioButtons
                        options={setCurrentSelection(radioButtonOptions())}
                        hasSubtitles={true}
                        setForm={setSelection}
                        userType={onboarding.formData.userType as UserType}
                    />
                </div>
                <Button
                    id="continue-button"
                    tabIndex={0}
                    onClick={handleContinue}
                    showIcon={true}
                    buttonType={ButtonTypes.PRIMARY}
                    userType={onboarding.formData.userType as UserType}
                >
                    Personalize my rates
                </Button>
            </div>
        </>
    );
});
