import { observer } from "mobx-react";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import { ModalWrapper } from "../../modals/modal-wrapper";

export const AdvertiserRider = observer(() => {
    const { companyName } = useOnboarding();

    return (
        <>
        <ModalWrapper>
        <h2>Advertiser Disclosure</h2>
            <div style={{overflowY: "scroll", height: "90%"}}>
            <p>
                <strong>
                    {companyName} may receive compensation from the Lender Partners that will be
                    presented when a user selects a product and completes a loan with a Lender
                    Partner. Participation and other factors may impact if, how and where products
                    appear on this site including the order in which they appear or whether a lender
                    is featured on the site. {companyName} does not include all student loan lenders
                    or all types of offers available in the marketplace.
                </strong>
            </p>
            <br />
            <p>
                Student loan providers determine underwriting criteria necessary for approval. You
                should review each Lender Partner’s terms and conditions to determine which loan
                works best for you and your own personal financial situation. All rates, fees, and
                terms are presented without guarantee and are subject to change pursuant to each
                provider's or affiliate’s discretion. There is never a guarantee you will be
                approved for credit or that upon approval you will qualify for the advertised rates,
                fees, or terms that were shown.
            </p>
            <br />
            <p>
                <strong>NOTE:</strong> Federal regulations require lenders to provide every
                potential borrower with disclosure information before they apply for a student loan.
                The lender you select is required to provide you with an Application Disclosure and
                an Approval Disclosure within the application process before you accept the terms
                and conditions of your loan.
            </p>
            <br />
            <p>
                {companyName} is not a lender or creditor, it does not offer, extend or alter credit
                terms. Our website offers an online advertising service that allows lenders to
                present information about their products to consumers so that they may shop and
                compare information supplied by lenders. Only participating Lending Partners can
                perform the full application and deliver the required disclosures.
            </p>
            <br />
            <p>
                You may choose to contact/speak with one or more of these lenders to determine what
                your actual terms may be. Only a lender can provide you with a formal application
                for credit, your inquiry form here is merely an expression of interest and/or intent
                to obtain credit or assistance. You must discuss your actual credit situation and
                fill out the lender’s required documents prior to obtaining an extension of credit.
                Lender Partners may not have the best or the lowest rates so you are encouraged to
                continue to shop and compare additional lenders, credit unions, local financial
                institutions, etc. to ensure you are truly getting the best terms for your
                situation.
            </p>
            </div>
            </ModalWrapper>
        </>
    );
});
