import { isMarketplaceEnv } from "../../../../utils/config";
import { LeftNavigationCustomButtons } from "../Buttons/LeftNavigationCustomButtons";

import "./styles.scss";

interface ILeftNavigationCustomMenuButtons {
    menuExpand: boolean;
    setMenuExpandDropdown: Function;
    setMenuExpand: Function;
}

export const LeftNavigationCustomMenuButtons = ({
    menuExpand,
    setMenuExpandDropdown,
    setMenuExpand,
}: ILeftNavigationCustomMenuButtons) => {
    const displayButtons = !isMarketplaceEnv() && !menuExpand;
    return (
        <div className={"paaas-left-navigation-custom-menu-buttons"}>
            <ul>
                {displayButtons && (
                    <LeftNavigationCustomButtons
                        setMenuExpandDropdown={setMenuExpandDropdown}
                        setMenuExpand={setMenuExpand}
                    />
                )}
            </ul>
        </div>
    );
};
