import { observer } from "mobx-react";
import { UserType } from "../../../../common/constants/defaultInputs";
import { getSentenceCase } from "../../../../common/utils/global";
import { ProgressStatuses } from "../../../../common/models/request";
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider";
import { PrequalifyContinueButton, PrequalifyEditButton, PrequalifyDeleteButton, PostPrequalButtons } from "./PopupContentTopSectionButtons";
import { OnboardingStore } from "../../../../common/store/onboarding";
import styles from "./styles.module.scss";

const offersData = (onboarding: OnboardingStore) => {
    return {
        offersCount: Number(
            onboarding.activePostPrequalRequestData?.compareAndSelect?.offersData.numberOfOffers
        ),
        offerExplored: onboarding.activePostPrequalRequestData?.compareAndSelect?.offerExplored,
        lenderData: onboarding.activePostPrequalRequestData?.applyWithLender?.lenderData,
    }
}

export const PopupContentTopSection = observer(({ stepContent }) => {
    const onboarding = useOnboarding();
    const { offersCount, lenderData, offerExplored } = offersData(onboarding);

    const displayContinueButton = () => {
        // If offer is explored, we don't want to display the continue button
        if (offerExplored) return false;
        // If the user is a borrower, we want to display the continue button if the user has not completed prequal
        if (onboarding.formData.userType === UserType.BORROWER) return onboarding.formData.status === "started";

        //Cosigner creator case
        if (onboarding.formData.isCreator && onboarding.formData.userType === UserType.COSIGNER && onboarding.formData.cosigner) {
            // If the user is a cosigner creator, we want to display the continue button if the user has yet included a user
            // If the cosigner has indicated they do not have an SSN, we do not want to display the continue button
            return  onboarding.formData.cosigner?.citizenshipStatus.hasSSN !== false && !onboarding.formData.includedUser
        }
        //Cosigner invited user case 
        return onboarding.formData?.cosigner?.dob === null ?? false;
    };

    const displayPrequalButtons = () => {
        return (
            stepContent === ProgressStatuses.Prequalify &&
            !onboarding.includedUserIsInvitedAndHasNotAcceptedInvite() &&
            !lenderData
        );
    };

    const displayCompareAndSelectButton = () => {
        return stepContent === ProgressStatuses.CompareAndSelect && !!offersCount && !lenderData
    }

    const displayApplyWithLenderButton = () => {
        return stepContent === ProgressStatuses.ApplyWithLender && !lenderData;
    };

    return (
        <div className="popupContentTopSection-wrapper flex-container-1">
            {/* Popup step */}
            <p className="p-md-14-medium">
                <span className={`${styles.stepTitle} ${styles[`${onboarding.formData.userType}`]}`}>{getSentenceCase(stepContent)}: </span>
                {getSentenceCase(onboarding.formData.loanSelectionType)}
            </p>

            {/* Prequalify popup buttons */}
            {displayPrequalButtons() && (
                <div className="flex-container-1" style={{ gap: "8px" }}>
                    {displayContinueButton() ? (
                        <PrequalifyContinueButton onboarding={onboarding} />
                    ) : (
                        <PrequalifyEditButton onboarding={onboarding} offerExplored={offerExplored} />
                    )}
                    <PrequalifyDeleteButton onboarding={onboarding} offerExplored={offerExplored} />
                </div>
            )}

            {/* Compare and select popup button */}
            {displayCompareAndSelectButton() && (
                <PostPrequalButtons onboarding={onboarding} buttonText="See my rates" />
            )}

            {/* Apply with lender popup button */}
            {displayApplyWithLenderButton() && (
                <PostPrequalButtons onboarding={onboarding} buttonText="Explore a different rate" />
            )}
        </div>
    );
});
