import { IOffer, LoanType } from "../../core/services/types/offers-api-types";
import { formatPercent } from "../../utils/global";
import { isDefined } from "../../utils/helper_functions/validation-functions";
import { formatMoneyAmount } from "../../utils/money";

export type OfferDetail = {
    title?: string;
    tooltip?: string;
    additionalValueText?: string;
    value?: string;
};

/**
 * This function calculates the offer details. Possible details include:
 * 1. Loan size
 * 2. APR
 * 3. Rate Type
 * 4. Term
 * 5. Total Cost
 * 6. Payments in school
 * 7. Payments after school
 * 8. Repayment Plan
 * 9. Discount
 * 10. Origination Fee
 * Note, if an offer does not contain the information required, that detail is not added.
 */
export function calculateOfferDetails(offer: IOffer): OfferDetail[] {
    const offerDetails: OfferDetail[] = [];

    // For lenders that only offer loans in specific increments, backend rounds the loan size to the closest increment offered by the lender.
    if (offer.amountWasRounded) {
        offerDetails.push({
            title: "Loan size",
            tooltip:
                "This lender only offers loans in specific increments. In order to pre-qualify you, your requested loan size was rounded to the closest increment offered by this lender.",
            additionalValueText: "(Auto-rounded)",
            value: formatMoneyAmount(offer.amount.amount as number).display,
        });
    }

    if (offer.loanType === LoanType.FIXED && isDefined(offer.fixedAPR)) {
        offerDetails.push({
            title: "APR",
            tooltip:
                "An Annual Percentage Rate (APR) is the interest rate applied to a student loan, plus additional fees. APR is expressed as a percentage and is calculated on a yearly basis.",
            value: formatPercent(offer.fixedAPR),
        });
    }

    if (offer.loanType === LoanType.VARIABLE && isDefined(offer.startingAPR)) {
        offerDetails.push({
            title: "APR",
            tooltip:
                "An Annual Percentage Rate (APR) is the interest rate applied to a student loan, plus additional fees. APR is expressed as a percentage and is calculated on a yearly basis.",
            value: formatPercent(offer.startingAPR),
        });
    }

    if (isDefined(offer.term)) {
        offerDetails.push({
            title: "Term",
            tooltip:
                "A term is the length of time a borrower has to repay their debt in full. Generally, the higher the monthly payment, the shorter the loan term and the lower the monthly payment, the longer the loan term.",
            value: offer.term + " years",
        });
    }

    if (isDefined(offer.amount.amount) && isDefined(offer.totalInterestExpense.amount)) {
        offerDetails.push({
            title: "Total Cost",
            tooltip:
                "The estimated total cost of borrowing a loan including the principal amount borrowed, the total interest expense accrued over the term of the loan, and any fees if applicable.",
            value: formatMoneyAmount(
                (offer.amount.amount as number) + (offer.totalInterestExpense.amount as number)
            ).display.split(".")[0],
        });
    }

    if (offer.loanType === LoanType.FIXED && isDefined(offer.fixedAPR)) {
        offerDetails.push({
            title: "Rate Type",
            tooltip:
                "There are two types of interest rates for standard student loans: fixed and variable.  A fixed rate loan has the same interest rate for the entirety of the borrowing period, while variable rate loans have an interest rate that changes over time. Income based repayment applies to alternative loans and monthly payments are generally a fixed percentage of your annual pre-tax income.",
            value: "Fixed",
        });
    }

    if (offer.loanType === LoanType.VARIABLE && isDefined(offer.startingAPR)) {
        offerDetails.push({
            title: "Rate Type",
            tooltip:
                "There are two types of interest rates for standard student loans: fixed and variable.  A fixed rate loan has the same interest rate for the entirety of the borrowing period, while variable rate loans have an interest rate that changes over time. Income based repayment applies to alternative loans and monthly payments are generally a fixed percentage of your annual pre-tax income.",
            value: "Variable",
        });
    }

    if (isDefined(offer.monthlyPaymentInSchool)) {
        offerDetails.push({
            title: "Payments in school",
            tooltip:
                "During school, you may be expected to make payments on your student loans. The size of these monthly payments depends on your payment plan. If you don’t make monthly payments during school, your loan balance will rise.",
            value: isDefined(offer.monthlyPaymentInSchool.amount)
                ? formatMoneyAmount(offer.monthlyPaymentInSchool.amount as number).display.split(
                      "."
                  )[0]
                : "N/A",
        });
    }

    if (offer.type === "PSL") {
        if (isDefined(offer.monthlyPaymentAfterGrad)) {
            offerDetails.push({
                title: "Payments after school",
                tooltip:
                    "After school, you’ll be expected to begin making full monthly payments on your student loans. The size of these monthly payments depends on your loan term, APR, and principal balance. Some lenders provide a six month “grace period” before full payments begin. During the “grace period” payments aren’t due but interest accrues.",
                value: isDefined(offer.monthlyPaymentAfterGrad.amount)
                    ? formatMoneyAmount(
                          offer.monthlyPaymentAfterGrad.amount as number
                      ).display.split(".")[0]
                    : "N/A",
            });
        }
    } else {
        if (isDefined(offer.averagePayment)) {
            offerDetails.push({
                title: "Monthly payment",
                tooltip:
                    "The size of these monthly payments depends on your loan term, APR, and principal balance.",
                value: isDefined(offer.averagePayment.amount)
                    ? formatMoneyAmount(offer.averagePayment.amount as number).display.split(".")[0]
                    : "N/A",
            });
        }
    }

    if (isDefined(offer.paymentPlan)) {
        offerDetails.push({
            title: "Repayment Plan",
            tooltip:
                "A repayment plan is a way to pay back a loan over an extended period of time. For standard private student loans, there are four common payment plans: Immediate, interest only, fixed (partial interest), or fully deferred.",
            value: offer.paymentPlan,
        });
    }

    // Note: we want to display N/A for 0 offer discount
    offerDetails.push({
        title: "Discount",
        tooltip:
            "A discount is a benefit that lenders may offer to lower your monthly payment. Lenders provide discounts to encourage good borrowing behavior. ",
        value: isDefined(offer.discount) ? formatPercent(offer.discount) : "N/A",
    });

    if (isDefined(offer.fees)) {
        offerDetails.push({
            title: "Origination Fee",
            tooltip:
                "An origination fee is a fee charged by a lender to cover the cost of processing the loan and is usually expressed as a percentage of the loan size. Typically this fee is deducted from the amount of money the lender disburses.",
            value: isDefined(offer.fees?.originationFee)
                ? formatPercent(offer.fees?.originationFee as number)
                : "N/A",
        });
    }

    return offerDetails;
}
