import { Auth, CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { isMarketplaceEnv } from "../../utils/config";

export class AuthHandler {
    static async currentAuthenticatedUser() {
        return Auth.currentAuthenticatedUser();
    }

    static async currentCredentials() {
        return Auth.currentCredentials();
    }

    // Requires partnerId for PAAAS
    static async signIn(params: { username: string; password: string; partnerId?: string }) {
        const { username, password, partnerId } = params;
        return Auth.signIn({
            username: this.addPartnerIdToEmail(username, partnerId),
            password,
        });
    }

    static async signOut() {
        return Auth.signOut();
    }

    // Requires partnerId for PAAAS
    static async signUp(params: {
        username: string;
        password: string;
        attributes: {
            phone_number: string;
            name: string;
            family_name: string;
        };
        partnerId?: string;
    }) {
        const { username, password, attributes, partnerId } = params;

        return Auth.signUp({
            password,
            attributes,
            username: this.addPartnerIdToEmail(username, partnerId),
        });
    }

    // Requires partnerId for PAAAS
    static async resendSignUp(email: string, partnerId?: string) {
        return Auth.resendSignUp(this.addPartnerIdToEmail(email, partnerId));
    }

    // Requires partnerId for PAAAS
    static async confirmSignUp(email: string, form: string, partnerId?: string) {
        return Auth.confirmSignUp(this.addPartnerIdToEmail(email, partnerId), form);
    }

    static async changePassword(params: {
        user: any;
        currentPassword: string;
        newPassword: string;
    }) {
        const { user, currentPassword, newPassword } = params;
        return Auth.changePassword(user, currentPassword, newPassword);
    }

    static async federatedSignIn(provider: CognitoHostedUIIdentityProvider) {
        return Auth.federatedSignIn({
            provider,
        });
    }

    // Requires partnerId for PAAAS
    static async forgotPassword(email: string, partnerId?: string) {
        return Auth.forgotPassword(this.addPartnerIdToEmail(email, partnerId));
    }

    // Requires partnerId for PAAAS
    static async forgotPasswordSubmit(params: {
        email: string;
        code: string;
        password: string;
        partnerId?: string;
    }) {
        const { email, code, password, partnerId } = params;
        return Auth.forgotPasswordSubmit(
            this.addPartnerIdToEmail(email, partnerId),
            code,
            password
        );
    }

    /**
     * Add partnerId to email if the site is a PAAAS site
     * @param email
     * @param partnerId
     * @returns string email
     */
    private static addPartnerIdToEmail(email: string, partnerId?: string) {
        const emailSplitByAt = email.split("@");

        // Return email under following conditions:
        // 1. Missing partnerId
        // 2. Incorrectly formatted email
        // 3. Is marketplace environment
        if (!partnerId || emailSplitByAt.length !== 2 || isMarketplaceEnv()) return email;

        const firstHalf = emailSplitByAt[0];
        const secondHalf = emailSplitByAt[1];

        return `${firstHalf}+${partnerId}@${secondHalf}`;
    }
}
