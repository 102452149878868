import { observer } from "mobx-react";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import { ModalWrapper } from "../../modals/modal-wrapper";

export const IntendedAudienceRider = observer(() => {
        const { companyName } = useOnboarding();

        return (
            <>
                <ModalWrapper>
                    <h2>Intended Audience</h2>
                    <div style={{ overflowY: "scroll", height: "90%" }}>
                        <p>
                            <strong>Prequalified rates are potential, not offers of credit.</strong>
                        </p>
                        <br />
                        <p>
                            This prequalification process is intended to provide you with
                            preliminary information regarding potential options for a loan with one
                            of our Lender Partners. {companyName} is not a lender and does not make
                            credit decisions or employ its own credit criteria. A prequalification
                            inquiry is not an application for credit.
                        </p>{" "}
                        <br />
                        <p>
                            <strong>No Guarantees:</strong>
                        </p>
                        <br />
                        <p>
                            {companyName} makes no representation, warranties or guarantees about
                            your eligibility for credit or for a particular loan. {companyName} and
                            its Lender Partners do not have prequalified information for all
                            circumstances and may be unable to provide a prequalified rate in
                            response to every inquiry. Lender Partners reserve the right to change
                            or withdraw the prequalified rates at any time. Additionally, receiving
                            a prequalified rate does not guarantee that your chosen Lender Partner
                            will extend an offer of credit. All credit decisions, including loan
                            approval, if any, are determined by our Lender Partners, in their sole
                            discretion. There are some Lender Partners on the {companyName} platform
                            who do not participate in providing prequalified rates to users; in
                            these circumstances, you may apply with these Lender Partners directly.
                        </p>
                        <br />
                        <p>
                            <strong>Discounts:</strong>
                        </p>
                        <br />
                        <p>
                            If a Lender Partner offers a discount (typically 0.25% for automatic
                            payment “autopay”), the discounted offer will be displayed with a green
                            tag. When viewing the collapsed list of Lender Partner offers, the
                            offers with discounts are depicted with a green tag placed on the upper
                            left hand corner of the Lender Partner’s logo. The rate provided
                            directly to you from the Lender Partner may not include the autopay
                            discount and you may not qualify for the autopay discount. Note that
                            autopay discounts are applied differently by different Lender Partners.
                            Some Lender Partners will apply the discount to lower the principal
                            amount of the loan, instead of applying the discount to the monthly
                            payment. When the Lender Partner does this your monthly payment will
                            appear higher, but your loan balance gets paid off sooner. Other Lender
                            Partners will apply the discount to the monthly payment, making your
                            monthly payment lower. The manner in which these discounts are
                            determined and applied are at the Lender Partner’s sole discretion.
                            Please inquire about the autopay discount directly with the Lender
                            Partner. For consistency, for the offers displayed with discounts,{" "}
                            {companyName} displays the discount as though the discount is applied to
                            the monthly payment. Accordingly, the manner in which {companyName}{" "}
                            displays the monthly payment information is for illustrative purposes
                            only; the actual monthly payment information will be provided directly
                            to you by the Lender Partner.
                        </p>
                        <br />
                        <p>
                            <strong>Additional Information:</strong>
                        </p>
                        <br />
                        <p>
                            Speak with each provider about the actual term and rate you may qualify
                            for based on your credit history, ability to repay the loan, and
                            personal financial situation. Lenders may charge an origination fee. Be
                            sure to ask for and review information about all fees, costs, and terms
                            associated with each loan product. Your state, credit history, credit
                            score, personal financial situation, and lender underwriting criteria
                            can impact the amount, fees, terms, and rates offered. In some cases,
                            lenders may require you have an account with them already and for a
                            prescribed period of time in order to qualify for better rates on their
                            loan products. Ask the lender for details.
                        </p>
                        <br />
                        <p>
                            When making a decision on the options provided to you by our Lender
                            Partners, {companyName} encourages you to seek professional advice
                            before making a decision to ensure that you are making an informed
                            decision based on your personal financial circumstances. Your decision
                            to move forward with a particular Lender Partner and loan product is
                            solely your decision and your responsibility. While {companyName} seeks
                            to provide you with information on a wide choice of potential loan
                            options, remember that there may be additional borrowing, consolidation
                            and/or refinancing options available. Also, with respect to student
                            loans and student loan refinancing, keep in mind that Federal loans come
                            with many benefits and protections such as deferment, forbearance, and
                            loan forgiveness programs which will be lost when refinancing into a
                            private loan option. While some private lenders offer some of these same
                            benefits, not all do. Therefore, it is your responsibility to review the
                            Lender Partner’s disclosures carefully before selecting a private
                            student loan option or refinancing your federal loans with private
                            loans. For more information on Federal student loans visit{" "}
                            <a href="studentaid.ed.gov" rel="noreferrer" target="_blank">
                                studentaid.ed.gov
                            </a>
                            .
                        </p>
                        <br />
                        <p>
                            You should contact your tax professional or other financial advisor to
                            determine if you can actually realize savings by refinancing when it can
                            extend the life of your current loan. You should ask the lender about
                            all terms, rates, fees and costs associated with each product and if you
                            will realize a net tangible benefit from the same. Rates are not
                            guaranteed and change daily or more frequently. Lenders that perform the
                            actual underwriting will have to determine if you meet their
                            underwriting criteria which is unknown to
                            {companyName} at the time that advertising is displayed.
                        </p>
                    </div>
                </ModalWrapper>
            </>
        );
    }
);
