import { dashboardUrl } from "../../../common/core/routes/navigation-urls";
import { useNavigate } from "react-router-dom";
import { useOnboarding } from "../../../common/core/providers/onboarding.provider";
import { Button, ButtonTypes } from "../../../common/components/buttons/button/Button";
import { UserContactCard } from "../components/UserContactCard";
import { getStatusBasedText } from "../utils/helper-functions";
import { useStore } from "../../../common/core/providers/global.provider";
import { IncludedUserStatus } from "../../../sparrow-ui-library/src/types/sparrow-types";
import { ResendReminderModal } from "../../../common/components/modals/User_Inclusion_Modals/Resend_Reminder_Modal/ResendReminderModal";
import { ConfirmDirectPermissionModal } from "../../../common/components/modals/User_Inclusion_Modals/ConfirmDirectPermissionModal";
import { DeleteEditInvitedUserModal } from "../../../common/components/modals/User_Inclusion_Modals/DeleteEditInvitedUserModal";
import { getLatestIncompleteStep } from "../../../common/core/global_routing/route-steps";
import { observer } from "mobx-react";
import { GetProgressRoadmaps } from "../../../common/core/services/sparrow-api-handlers/prequalification-api-handlers";
import { useEffect } from "react";
import { ButtonWrapper } from "../../../common/components/buttons/button/ButtonWrapper";

export const UserInclusionManagement = observer(() => {
    const store = useStore();
    const navigate = useNavigate();
    const onboarding = useOnboarding();

    useEffect(() => {
        const getUserProgress = async () => {
            if(!onboarding.jwt) return;
            const response = await GetProgressRoadmaps(onboarding.jwt);
            if (response.roadmaps.length) {
                onboarding.setLoanRequests(response.roadmaps, true);
            }
        };

        getUserProgress();
    }, []);

    //guard statement
    if (!onboarding.formData.includedUser) {
        navigate(dashboardUrl);
        return <></>;
    }

    const { firstName, email, status } = onboarding.formData.includedUser;
    const invitedUserType = onboarding.getOppositeUserType();

    const statusBasedText = getStatusBasedText({
        invitedUserName: firstName,
        invitedUserType,
        status,
    });

    const handlePrimaryButton = () => {
        //set the invited user type
        onboarding.setCreatorIsIncludingUser(true);
        onboarding.setInvitedUserType(invitedUserType);
        onboarding.setUseShortSteps();
        //if the request creator has started including their user, then they should be directed to the citizenship page
        if (status === IncludedUserStatus.DIRECT_STARTED) {
            //get the pathname for the page the creator has yet to complete for their included user
            return navigate(getLatestIncompleteStep(onboarding));
        }
        //if the invited user is confirmed and creator wants to edit information, then they should be directed to the review page
        if (status === IncludedUserStatus.CONFIRMED) {
            onboarding.setEditsMadePostPrequalSubmission(false);
            return store.setModal(() => (
                <DeleteEditInvitedUserModal
                    firstName={firstName}
                    email={email}
                    status={status}
                    invitedUserType={invitedUserType}
                    isEditModal={true} //display edit modal version of this modal component
                />
            ));
        }
        //if the invited user rejected the invitation, then the creator should be directed to the delete modal
        if (status === IncludedUserStatus.INVITE_REJECTED) {
            return store.setModal(() => (
                <DeleteEditInvitedUserModal
                    firstName={firstName}
                    email={email}
                    status={status}
                    invitedUserType={invitedUserType}
                />
            ));
        }
        //if the creator now wants to directly include the invited user, they must first confirm permission to do so
        return store.setModal(() => (
            <ConfirmDirectPermissionModal
                invitedUserType={invitedUserType}
                emailRegistered={true}
            />
        ));
    };

    //only incluse resend invitation or send a reminder secondary button if the user is in the invite pending or invite started status
    const hasSecondaryButton =
        status === IncludedUserStatus.INVITE_PENDING ||
        status === IncludedUserStatus.INVITE_STARTED;
    // display relevant secondary button modal based on the status of the user
    const handleSecondaryButton = () => {
        if (status === IncludedUserStatus.INVITE_STARTED) {
            return store.setModal(() => (
                <ResendReminderModal firstName={firstName} email={email} isReminder={true} />
            ));
        }
        return store.setModal(() => (
            <ResendReminderModal firstName={firstName} email={email} isReminder={false} />
        ));
    };

    const hidePrimaryButton =
        onboarding.formData.includedUser?.status === IncludedUserStatus.CONFIRMED &&
        onboarding.formData.includedUser.inclusionType === "invited";

    return (
        <>
                <UserContactCard isManagementCard={true} statusBasedText={statusBasedText} />
                <div style={{ marginTop: "32px" }}>
                    <ButtonWrapper>
                        {hasSecondaryButton && (
                            <Button
                                id="previous-button"
                                onClick={handleSecondaryButton}
                                buttonType={ButtonTypes.SECONDARY}
                                userType={onboarding.formData.userType}
                            >
                                {statusBasedText.secondaryButtonText}
                            </Button>
                        )}
                        {!hidePrimaryButton && (
                            <Button
                                id="continue-button"
                                tabIndex={0}
                                onClick={handlePrimaryButton}
                                buttonType={ButtonTypes.PRIMARY}
                                userType={onboarding.formData.userType}
                            >
                                {statusBasedText.primaryButtonText}
                            </Button>
                        )}
                    </ButtonWrapper>
                </div>
        </>
    );
});
