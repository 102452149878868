import { observer } from "mobx-react";
import { useStore } from "../../core/providers/global.provider";
import { AppModal } from "./AppModal";
import "./style.scss";

export const GlobalModal: React.FunctionComponent = observer(() => {
    const { modalResetData, modal } = useStore();

    const onClose = () => {
        modalResetData();
    };

    return (
        <>
            {modal.child && (
                <AppModal
                    blur={modal.blur}
                    size={modal.size}
                    onClose={() => onClose()}
                    noPadding={modal.noPadding}
                    enableMobileView={modal.enableMobileView}
                >
                    {modal.child()}
                </AppModal>
            )}
        </>
    );
});
