import ReactDOM from "react-dom";
import "./style.scss";
import greenCheckIcon from "../../../assets/images/icons/greenCheck.svg";
import XIcon from "../../../assets/images/icons/X.svg";
import XHoverIcon from "../../../assets/images/icons/XIconHover.svg";

import { useRef, useEffect, useState } from "react";

interface ApplyMessageProps {
    handleClick: Function;
}

export const OfferCardApplyMessage: React.FunctionComponent<ApplyMessageProps> = ({
    handleClick,
}) => {
    const popupContainer: any = document.getElementById("tooltip-container");

    const dropDownRef = useRef<HTMLDivElement>(null);

    const [hover, setHover] = useState<boolean>(false);

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropDownRef.current && !dropDownRef.current.contains(event.target as Node)) {
                handleClick(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropDownRef]);

    return (
        <>
            {ReactDOM.createPortal(
                <>
                    <div className="popup-container" ref={dropDownRef}>
                        <div className="apply-message-popup-container">
                            <div className="apply-message-popup-container-first-section">
                                <div>
                                    <img src={greenCheckIcon} alt="check" />
                                </div>
                                <div className="apply-message-content-container">
                                    <div className="apply-message-title">
                                        Congrats on selecting a rate
                                    </div>
                                    <div className="apply-message-subtitle">
                                        Once you have applied with a lender, you can monitor your
                                        progress from your dashboard.
                                    </div>
                                </div>
                            </div>
                            <div
                                className={"close-tip-modal-trigger"}
                                onClick={() => handleClick(false)}
                                onMouseEnter={() => setHover(true)}
                                onMouseLeave={() => setHover(false)}
                            >
                                <img src={hover ? XHoverIcon : XIcon} alt="x" />
                            </div>
                        </div>
                    </div>
                </>,
                popupContainer
            )}
        </>
    );
};
