import { UserType } from "../../../types/sparrow-types";
import { getSvgColors } from "../getSvgColors";

export const ConfirmIcon = ({ userType }: { userType: UserType }) => {
    const { primaryColor, accentColor } = getSvgColors(userType);
    
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M22.8698 3.41422C23.6509 2.63317 24.9172 2.63317 25.6982 3.41422L37.0119 14.7279C37.793 15.509 37.793 16.7753 37.0119 17.5564L21.401 33.1673C20.5167 34.0516 19.3783 34.6374 18.1447 34.843L5.83272 36.895C4.47908 37.1206 3.30553 35.9471 3.53113 34.5934L5.58314 22.2814C5.78872 21.0479 6.3746 19.9094 7.25886 19.0252L22.8698 3.41422Z"
                fill={primaryColor}
            />
            <path
                d="M22.4953 32.0728L21.4008 33.1674C20.5165 34.0516 19.378 34.6375 18.1445 34.8431L5.83247 36.8951C4.47883 37.1207 3.30528 35.9472 3.53089 34.5935L5.58289 22.2815C5.78848 21.048 6.37435 19.9095 7.25862 19.0252L8.35319 17.9307C11.5962 18.8924 14.6527 20.6533 17.2127 23.2133C19.7727 25.7733 21.5336 28.8298 22.4953 32.0728Z"
                fill={accentColor}
            />
            <path
                d="M12.1165 35.8478L5.83247 36.8951C4.47883 37.1207 3.30528 35.9472 3.53089 34.5936L4.57822 28.3096C6.35794 28.7119 8.04896 29.6059 9.43453 30.9915C10.8201 32.3771 11.7141 34.0681 12.1165 35.8478Z"
                fill={primaryColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.9683 33.3241L14.9687 33.3226L14.9711 33.3136L14.9818 33.2735C14.9916 33.2373 15.0066 33.1827 15.0266 33.1125C15.0666 32.972 15.1264 32.7696 15.2042 32.5273C15.3609 32.0395 15.5859 31.4049 15.8637 30.7926C16.1483 30.1655 16.4579 29.6304 16.7624 29.2925C16.9122 29.1264 17.0252 29.0482 17.0923 29.0164C17.1053 29.0103 17.1154 29.0064 17.1228 29.004C17.1405 29.0278 17.1675 29.0733 17.2001 29.1547C17.2969 29.3965 17.367 29.7904 17.3831 30.3407C17.4148 31.4276 17.2307 32.8115 17.0024 34.0496C16.8332 34.9677 17.2967 35.7574 17.9237 36.1717C18.5549 36.5887 19.4771 36.7043 20.253 36.1406L21.7916 35.0228L22.7383 36.2838C23.3752 37.1321 24.5635 37.3356 25.4463 36.7476L26.0921 36.3176H30C30.5523 36.3176 31 35.8698 31 35.3176C31 34.7653 30.5523 34.3176 30 34.3176H26.0921C25.6976 34.3176 25.3119 34.4343 24.9835 34.6529L24.3377 35.083L23.391 33.822C22.7352 32.9484 21.4997 32.7627 20.616 33.4047L19.0775 34.5225C19.0767 34.5231 19.076 34.5236 19.0753 34.524C19.0675 34.5228 19.0495 34.5184 19.0263 34.5031C18.9977 34.4841 18.9803 34.4611 18.9729 34.4454C18.9697 34.4386 18.9686 34.4339 18.9682 34.4309C18.9679 34.4284 18.9673 34.4228 18.9693 34.4122C19.2041 33.1386 19.4203 31.5865 19.3823 30.2823C19.3634 29.637 19.2811 28.9716 19.0569 28.4115C18.8278 27.8393 18.3857 27.2379 17.5967 27.0532C16.5702 26.813 15.7677 27.4089 15.2769 27.9535C14.7669 28.5192 14.3563 29.2745 14.0424 29.9663C13.7218 30.6728 13.4703 31.3856 13.3001 31.9156C13.2144 32.1822 13.1482 32.4062 13.103 32.5648C13.0804 32.6442 13.063 32.7074 13.0511 32.7516L13.0372 32.8034L13.0334 32.818L13.0323 32.8224L13.0319 32.8238C13.0319 32.824 13.0317 32.8247 14 33.0743L13.0319 32.8238C12.8941 33.3586 13.2156 33.9048 13.7504 34.0426C14.2852 34.1805 14.8303 33.8588 14.9683 33.3241ZM17.111 28.9895C17.1033 28.9852 17.0999 28.9815 17.1001 28.9807C17.1003 28.9799 17.1041 28.9821 17.111 28.9895Z"
                fill={primaryColor}
            />
        </svg>
    );
};
