import { useState, useEffect } from "react";
import { QuestionTooltip } from "../tooltips";
import { Bar } from "react-chartjs-2";
import { StandardStudentLoanOfferGraphOutput } from "../../utils/graphing/utils/types";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import "./style.scss";
import { BarChartSelect } from "./BarChartSelect";
import { useMediaQuery } from "react-responsive";

interface BarChartProps {
    colors: string[];
    offerData: any[];
}

export type BarChartData = {
    labels: string[];
    datasets: BarChartDataset[];
};

export type BarChartDataset = {
    data: number[];
    backgroundColor: string[];
    borderWidth: number;
    barThickness: number;
    borderRadius: number[];
};

export const BarChart: React.FunctionComponent<BarChartProps> = ({ offerData, colors }) => {
    const { graphData } = useOnboarding();

    const [chartData, setChartData] = useState<BarChartData>({ labels: [], datasets: [] });
    const [barChartType, setBarChartType] = useState<string>("totalCost");

    useEffect(() => {
        if (graphData) {
            convertGraphDataToBarChartData(offerData);
        }
    }, [graphData, barChartType, colors]);

    function convertGraphDataToBarChartData(data: any) {
        const standardGraphData = graphData!.standardGraphData;
        const barChartData = {
            labels: obtainChartLabels(standardGraphData),
            datasets: [processOffersForDatasets(standardGraphData)],
        };
        setChartData(barChartData);
    }

    function obtainChartLabels(allData: StandardStudentLoanOfferGraphOutput[]) {
        const labels: string[] = [];
        for (const data of allData) {
            labels.push(data.lender);
        }
        return labels;
    }

    const isLess1200 = useMediaQuery({
        query: "(max-width: 1200px)",
    });
    const isLess767 = useMediaQuery({
        query: "(max-width: 767px)",
    });

    function processOffersForDatasets(allData: StandardStudentLoanOfferGraphOutput[]) {
        const dataset: BarChartDataset = {
            data: [],
            backgroundColor: colors,
            borderWidth: 0,
            barThickness:
                allData.length === 5
                    ? 30
                    : allData.length > 3 && allData.length < 5 && isLess1200
                    ? 20
                    : 40,
            borderRadius: [20, 20, 20, 20, 20],
        };
        for (const data of allData) {
            if (barChartType === "totalCost") {
                dataset.data.push(data.totalCost.amount / 100);
            } else if (barChartType === "paymentAfterSchool") {
                dataset.data.push(data.averageMonthlyPayment.amount / 100);
            }
        }
        return dataset;
    }

    const customTooltipBar = (context, type) => {
        const { chart, tooltip } = context;
        let tooltipEl = chart.canvas.parentNode.parentNode.querySelector("div.custom_tooltip_bar");

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Remove old children
        if (tooltipEl.firstChild != null) {
            tooltipEl.firstChild.remove();
        }

        const body1 = document.createElement("div");

        const title = document.createElement("div");
        const text = document.createElement("div");
        title.textContent =
            "$" + new Intl.NumberFormat("en-GB").format(tooltip.dataPoints[0].raw).split(".")[0];
        text.textContent = type === "totalCost" ? "Total Cost" : "Monthly Payment";
        body1.appendChild(title);
        body1.appendChild(text);

        tooltipEl.appendChild(body1);

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + "px";
        tooltipEl.style.top = positionY + tooltip.caretY + "px";
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";
    };

    const options = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            tooltip: {
                enabled: false,
                intersect: true,
                mode: "index",
                // @ts-ignore
                external: (tooltipModel) => customTooltipBar(tooltipModel, barChartType),
            },
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                stacked: true,
                ticks: {
                    padding: 10,
                    beginAtZero: true,
                    font: {
                        size: 12,
                    },
                    count: 7,
                    autoSkipPadding: 10,
                    color: "#ABB6BD",
                    // Include a dollar sign in the ticks
                    // @ts-ignore
                    callback: (value, index, values) => {
                        return (
                            "$" +
                            new Intl.NumberFormat("en-GB", { maximumSignificantDigits: 2 }).format(
                                value
                            )
                        );
                    },
                },
                grid: {
                    drawTicks: true,
                    tickMarkLength: 50,
                    borderDash: [5, 10],
                    color: "rgba(193,192,196,.25)",
                    drawBorder: false,
                    zeroLineColor: "rgba(193,192,196,.25)",
                    zeroLineBorderDash: [5, 10],
                    tickColor: "transparent",
                },
            },
            x: {
                display: false,
                ticks: {
                    padding: 0,
                },
                grid: {
                    drawTicks: false,
                    display: false,
                    drawBorder: false,
                    tickColor: "transparent",
                },
            },
        },
    };

    const barChartTooltip =
        `<p>Hover your cursor over the graph below to see each starred loan’s total cost and monthly payment.</p>` +
        `<p>The estimated total cost and monthly payment of each loan includes its principal, total interest expense, and any fees (if applicable).</p>` +
        `<p>You may see an inverse relationship between monthly payment and total cost. Usually, the more you pay per month, the less the loan costs over time.</p>`;
    return (
        <div className="rate-chart-column">
            <div className="layout-row align-center bar-chart space-between">
                <h3 className="chart-title">
                    Estimated cost <QuestionTooltip title="Loan Costs" content={barChartTooltip} />
                </h3>
                {/* @ts-ignore */}
                <BarChartSelect onSelectCallback={(v) => setBarChartType(v)} />
            </div>
            <div className="custom_tooltip_bar" />
            <div className="bar-chart-wrapper">
                {offerData.length > 0 && (
                    <Bar
                        // @ts-ignore
                        options={options}
                        data={chartData}
                        id={"bar-chart_id"}
                        key={"bar-chart"}
                        height={isLess767 ? 218 : 250}
                        redraw={false}
                    />
                )}
            </div>
        </div>
    );
};
