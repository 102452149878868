import { FC } from "react";
import "./style.scss";

interface IButton {
    id?: string;
    text: string;
    event?: any;
    type?: "link" | "button";
    url?: string;
    icon?: string | null;
    iconPosition?: "left" | "right";
    buttonStyle?: "white" | "green" | "blue" | "border-green" | "black" | "peach";
    customClass?: string | null;
    variant?: "solid" | "text";
    target?: "_self" | "_blank";
    bordered?: boolean;
    disabled?: boolean;
    download?: boolean;
    linkPrefetch?: boolean;
    className?: string;
}

const Button: FC<IButton> = ({
    id,
    text,
    event = null,
    type = "a",
    url = "",
    icon = null,
    iconPosition = "right",
    buttonStyle = "green",
    customClass = null,
    variant = "solid",
    bordered = false,
    target = "_self",
    disabled = false,
    download = false,
    className,
}) => {
    return type === "a" ? (
        <a
            id={id}
            href={url}
            target={target}
            download={download}
            className={`btn btn-${buttonStyle} ${disabled ? "disabled" : ""} ${
                bordered ? "btn-bordered" : ""
            } btn-style-${variant} ${icon ? "btn-with-arrow" : ""} ${
                customClass ? customClass : ""
            }`}
            onClick={event}
        >
            {icon && iconPosition === "left" && <span className={icon} />}
            {text}
            {icon && iconPosition === "right" && <span className={icon} />}
        </a>
    ) : (
        <button
            id={id}
            className={
                className
                    ? className
                    : `btn btn-${buttonStyle} ${disabled ? "disabled" : ""} ${
                          bordered ? "btn-bordered" : ""
                      } btn-style-${variant} ${icon ? "btn-with-arrow" : ""} ${
                          customClass ? customClass : ""
                      }`
            }
            onClick={event}
        >
            {icon && iconPosition === "left" && <span className={icon} />} {text}{" "}
            {icon && iconPosition === "right" && <span className={icon} />}
        </button>
    );
};

export default Button;
