
import './style.scss';

interface ICard {
    content: string;
}

export const InputTooltip: React.FunctionComponent<ICard> = ({ content }) => {
    return (
        <>
            <div className="input-tooltip-wrapper">
                <button className="input-tooltip-trigger"></button>
                <div className="input-tooltip-content">
                    <div className="input-tooltip-data">
                        <p>{content}</p>
                    </div>
                </div>
            </div>
        </>
    );
};