//TODO: delete this file once copied to the library
export const employmentStatusOptions = [
    { value: "Salaried / Hourly", label: "Salaried / Hourly" },
    { value: "Self-employed", label: "Self-employed" },

];

export const occupationOptions = [
    { value: "Architect", label: "Architect" },
    { value: "Doctor", label: "Doctor" },
    { value: "Engineer", label: "Engineer" },
    { value: "Lawyer", label: "Lawyer" },
    { value: "Teacher", label: "Teacher" },
];

export const incomeTypeOptions = [
    { value: "Commission", label: "Commission" },
    { value: "Household", label: "Household" },
    { value: "Rental Income", label: "Rental Income" },
    { value: "None", label: "None" },
];

export const schoolsList = [
    { value: "University of California, Berkeley", label: "University of California, Berkeley" },
    { value: "University of California, Los Angeles", label: "University of California, Los Angeles" },
    { value: "University of California, San Diego", label: "University of California, San Diego" },
    { value: "University of California, Davis", label: "University of California, Davis" },
    { value: "University of California, Irvine", label: "University of California, Irvine" },
];

export const degreeTypes = [
    { value: "Undergraduate Degree", label: "Undergraduate Degree" },
    { value: "Graduate Degree", label: "Graduate Degree" },
];

export const asyncDegreeTypes = [
    { value: "Undergraduate Degree", label: "Undergraduate Degree" },
    { value: "Graduate Degree", label: "Graduate Degree" },
];

export const asyncMajors1 = [
    { value: "Computer Science", label: "Computer Science" },
    { value: "Electrical Engineering", label: "Electrical Engineering" },
    { value: "Mechanical Engineering", label: "Mechanical Engineering" },
    { value: "Civil Engineering", label: "Civil Engineering" },
    { value: "Chemical Engineering", label: "Chemical Engineering" },
];

export const asyncMajors2 = [
    { value: "Business", label: "Business" },
    { value: "Law", label: "Law" },
    { value: "Medicine", label: "Medicine" },
    { value: "Dentistry", label: "Dentistry" },
    { value: "Pharmacy", label: "Pharmacy" },
];

export const asyncSchoolsList1 = [
    { value: "University of California, Berkeley", label: "University of California, Berkeley" },
    { value: "University of California, Los Angeles", label: "University of California, Los Angeles" },
    { value: "University of California, San Diego", label: "University of California, San Diego" },
    { value: "University of California, Davis", label: "University of California, Davis" },
    { value: "University of California, Irvine", label: "University of California, Irvine" },
];

export const asyncSchoolsList2 = [
    { value: "New York University", label: "New York University" },
    { value: "Columbia University", label: "Columbia University" },
    { value: "Cornell University", label: "Cornell University" },
    { value: "Princeton University", label: "Princeton University" },
]


export const asyncAcademicPeriods1 = [
    { value: "Fall 2022", label: "Fall 2022" },
    { value: "Fall 2022 and Spring 2023", label: "Fall 2022 and Spring 2023" },
    { value: "Winter - 2023", label: "Winter - 2023" },
    { value: "Spring - 2023", label: "Spring - 2023" },
    { value: "Summer - 2023", label: "Summer - 2023" },
    { value: "Fall - 2023", label: "Fall - 2023" },
    { value: "Fall - 2023 and Spring - 2024", label: "Fall - 2023 and Spring - 2024" },
    { value: "Fall - 2023, Winter and Spring - 2024", label: "Fall - 2023, Winter and Spring - 2024" },
];

export const asyncAcademicPeriods2 = [
    { value: "Fall 2030", label: "Fall 2030" },
    { value: "Fall 2030 and Spring 2031", label: "Fall 2030 and Spring 2031" },
    { value: "Winter - 2031", label: "Winter - 2031" },
    { value: "Spring - 2031", label: "Spring - 2031" },
    { value: "Summer - 2031", label: "Summer - 2031" },
    { value: "Fall - 2031", label: "Fall - 2031" },
    { value: "Fall - 2031 and Spring - 2032", label: "Fall - 2031 and Spring - 2032" },
]

export const asyncAcademicYears1 = [
    { value: "Freshman", label: "Freshman" },
    { value: "Sophomore", label: "Sophomore" },
    { value: "Junior", label: "Junior" },
    { value: "Senior", label: "Senior" },
    { value: "Fifth Year Undergrad", label: "Fifth Year Undergrad" },
    { value: "Recently Graduated", label: "Recently Graduated" },
]

export const asyncAcademicYears2 = [
    { value: "Junior", label: "Junior" },
    { value: "Senior", label: "Senior" },
    { value: "Fifth Year Undergrad", label: "Fifth Year Undergrad" },
    { value: "Recently Graduated", label: "Recently Graduated" },
]

export const suffixOptions = [
    { value: "Jr.", label: "Jr." },
    { value: "Sr.", label: "Sr." },
    { value: "II", label: "II" },
    { value: "III", label: "III" },
    { value: "IV", label: "IV" },
    { value: "V", label: "V" },
    { value: "VI", label: "VI" },
    { value: "VII", label: "VII" },
    { value: "VIII", label: "VIII" },
    { value: "IX", label: "IX" },
    { value: "X", label: "X" },
];

export const whatAppOptions = [
    { value: true, label: "My WhatsApp is the same as my mobile number" },
    { value: false, label: "My WhatsApp is different than my mobile number" },
    { value: null, label: "I do not have a whatsapp account" }
];

export const asyncPhoneNumberTypes = [
    { value: "Home Phone", label: "Home Phone" },
    { value: "Business Phone", label: "Business Phone" },
    { value: "Mobile Phone", label: "Mobile Phone" },
];

export const asyncTimeZones = [
    { value: "Pacific Time", label: "Pacific Time" },
    { value: "Mountain Time", label: "Mountain Time" },
    { value: "Central Time", label: "Central Time" },
    { value: "Eastern Time", label: "Eastern Time" },
];