import "./styles/colors/colors.primary.css";
import "./styles/colors/colors.secondary.css";
import "./styles/colors/colors.neutral.css";
import "./styles/colors/colors.success.css";
import "./styles/colors/colors.error.css";
import "./styles/ui-library-styles.css";
import "./styles/typography.css";
import { ReactNode } from "react";

const SparrowUIStylesWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
    return <>{children}</>;
};

export default SparrowUIStylesWrapper;
