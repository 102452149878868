import { FC } from "react";

import { observer } from "mobx-react";

import { isMarketplaceEnv } from "../../../utils/config";
import { baseDomainUrl, borrowerOffersRoutingUrl } from "../../../core/routes/routing-urls";
import { PAAASLogo } from "../../PAAASLogo";
import { useCheckPathName } from "../../../../sparrow-ui-library/src/hooks";
import { useLocation } from "react-router-dom";
import { SparrowIcon } from "../../../../sparrow-ui-library/src/assets/icons/sparrow-icon/SparrowIcon";
import { getRouteForPAAASLogo } from "../../../utils/routing";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import "./styles.scss";

interface ILeftNavigationHeaderBar {
    menuExpand: boolean;
    setShowSideBarHandlerForMobile: Function;
    setMenuExpandDropdown: Function;
    setMenuExpand: Function;
    showSidebarOnMobile?: boolean;
}

export const LeftNavigationHeaderBar: FC<ILeftNavigationHeaderBar> = observer(
    ({
        menuExpand,
        setShowSideBarHandlerForMobile,
        setMenuExpandDropdown,
        setMenuExpand,
        showSidebarOnMobile,
    }) => {
        const { paaasCustomization } = useOnboarding();
        const isRatePage = useCheckPathName(borrowerOffersRoutingUrl);
        const pathname = useLocation().pathname;

        const closeSideBar = () => {
            setShowSideBarHandlerForMobile(false);
            setMenuExpandDropdown(undefined);
            setMenuExpand(false);
        };

        return (
            <div className={`left-navigation-bar-header ${menuExpand ? "expanded" : "active"}`}>
                {!isRatePage && (
                    <button className="left-navigation-bar-close" onClick={() => closeSideBar()}>
                        <span className={"icon-close"} />
                    </button>
                )}
                {isMarketplaceEnv() && (
                    <a href={baseDomainUrl} className="logo">
                        <SparrowIcon />
                    </a>
                )}
                {isRatePage && showSidebarOnMobile && (
                    <button className="sidebar-trigger" onClick={() => closeSideBar()}>
                        <span className={"icon-close"} />
                    </button>
                )}
                {!isMarketplaceEnv() && (
                    <div className={"left-navigation-bar-header-paaas-logo"}>
                        <PAAASLogo
                            navigateTo={getRouteForPAAASLogo({
                                pathname,
                                displayLandingPageView:
                                    paaasCustomization?.toggleableViews?.displayLandingPageView,
                            })}
                        />
                    </div>
                )}
            </div>
        );
    }
);
