import { parseOfferRepaymentPlan } from "../../views/offers/components/table/utilities/TableUtilities";
import { MoneyAmountInput, formatMoneyAmount, formatMoneyAmountAsPositive } from "../money";
import {
    calculateMonthlyInSchool,
    calculateMonthlyPaymentAfterGrad,
    calculateTotalInterestExpense,
    calculateTotalPaidForLoan,
} from "./utils/calculation";
import {
    calculateAge,
    calculateMonthsLeftUntilGraduation,
    getNextDate,
    getTimeFromDateString,
} from "./utils/time";
import {
    OfferPaymentPlan,
    PSLStandardOfferGraphDataInput,
    SLRStandardOfferGraphDataInput,
    StandardOfferGraphDataInput,
    StandardStudentLoanOfferGraphDataPoint,
    StandardStudentLoanOfferGraphOutput,
} from "./utils/types";

function paymentPlanIsImmediate(paymentPlan: OfferPaymentPlan) {
    return (
        paymentPlan === "Immediate" ||
        paymentPlan === "Immediate Repay" ||
        paymentPlan === "Immediate interest and principal payments"
    );
}

function paymentPlanIsImmediateOrDeferred(paymentPlan: OfferPaymentPlan) {
    return paymentPlanIsImmediate(paymentPlan) || paymentPlan === "Deferred";
}

function calculateTotalTimeInDebtOfOffer(params: {
    offer: StandardOfferGraphDataInput;
    monthsLeft: number;
}) {
    const { offer, monthsLeft } = params;

    if (paymentPlanIsImmediate(offer.paymentPlan)) {
        return {
            totalTimeInDebt: offer.repaymentTerms,
            timeTillImmediateRepay: 0,
        };
    }

    if (!offer.gracePeriod) {
        offer.gracePeriod = 0;
    }
    let lengthTillImmediateRepay = monthsLeft + offer.gracePeriod;
    if (lengthTillImmediateRepay < 0) {
        lengthTillImmediateRepay = 0;
    }

    return {
        totalTimeInDebt: offer.repaymentTerms + lengthTillImmediateRepay,
        timeTillImmediateRepay: lengthTillImmediateRepay,
    };
}

function calculateAverageMonthlyPayment(params: {
    offer: StandardOfferGraphDataInput;
    totalCost: MoneyAmountInput;
    totalTimeInDebt: number;
}) {
    const { offer, totalCost, totalTimeInDebt } = params;

    const paymentPlan = offer.paymentPlan;
    if (offer.type === "PSL") {
        if (paymentPlanIsImmediateOrDeferred(paymentPlan)) {
            return (offer as PSLStandardOfferGraphDataInput).monthlyPaymentAfterGrad;
        }
        return formatMoneyAmount(totalCost.amount / totalTimeInDebt, true);
    }

    return (offer as SLRStandardOfferGraphDataInput).averagePayment;
}

function calculateInterest(principal: number, rate: number) {
    return (principal * rate) / 1200;
}

function generateOfferGraphDataPoint(params: {
    offer: StandardOfferGraphDataInput;
    gradDate: number;
    dob: number;
}) {
    const { offer, gradDate, dob } = params;

    const monthsLeft = calculateMonthsLeftUntilGraduation(gradDate);
    const { totalTimeInDebt, timeTillImmediateRepay } = calculateTotalTimeInDebtOfOffer({
        offer: offer,
        monthsLeft: monthsLeft,
    });
    const rate = (offer.loanType === "variable" ? offer.startingAPR : offer.fixedAPR) as number;

    const totalInterest = calculateTotalInterestExpense({
        interestRate: rate,
        lengthInMonths: offer.repaymentTerms,
        principal: offer.amount.amount,
        paymentPlan: parseOfferRepaymentPlan(offer.paymentPlan),
        gradDate: gradDate,
        gracePeriod: offer.gracePeriod ?? 0,
    });

    const monthlyPaymentInSchool = calculateMonthlyInSchool({
        interestRate: rate,
        lengthInMonths: offer.repaymentTerms,
        principal: offer.amount.amount,
        paymentPlan: parseOfferRepaymentPlan(offer.paymentPlan),
    });

    const monthlyPaymentAfterGrad = calculateMonthlyPaymentAfterGrad({
        interestRate: rate,
        lengthInMonths: offer.repaymentTerms,
        principal: offer.amount.amount,
        paymentPlan: parseOfferRepaymentPlan(offer.paymentPlan),
        gradDate: gradDate,
        gracePeriod: offer.gracePeriod ?? 0,
    });

    const totalCost = calculateTotalPaidForLoan({
        interestRate: rate,
        lengthInMonths: offer.repaymentTerms,
        principal: offer.amount.amount,
        paymentPlan: parseOfferRepaymentPlan(offer.paymentPlan),
        gradDate: gradDate,
        gracePeriod: offer.gracePeriod ?? 0,
    });

    const principalAtStartOfLoan = offer.amount.amount;

    const dataPoints: StandardStudentLoanOfferGraphDataPoint[] = [];

    const outputObject: Partial<StandardStudentLoanOfferGraphOutput> = {
        offerId: offer.id,
        apr: rate,
        lender: offer.lender,
        term: offer.term,
        totalCost: totalCost,
        totalTimeInDebt: totalTimeInDebt,
        averageMonthlyPayment: calculateAverageMonthlyPayment({
            offer: offer,
            totalCost: totalCost,
            totalTimeInDebt: totalTimeInDebt,
        }),
        dataPoints: dataPoints,
    };

    let date: string | undefined;
    let totalInterestLeft = totalInterest.amount;
    let totalPrincipalLeft = offer.amount.amount;
    let totalInterestPaid = 0;
    let totalPrincipalPaid = 0;

    let principalToCalculateInterestOn = principalAtStartOfLoan;
    for (let j = 0; j < timeTillImmediateRepay; j++) {
        date = getNextDate(date);

        const interest = calculateInterest(principalToCalculateInterestOn, rate);
        const amountGoingTowardsInterest = Math.min(monthlyPaymentInSchool.amount, interest);
        const amountOfPrincipalPaid = Math.max(monthlyPaymentInSchool.amount - interest, 0);

        const interestLeft = formatMoneyAmountAsPositive(
            totalInterestLeft - amountGoingTowardsInterest,
            true
        );
        const principalLeft = formatMoneyAmountAsPositive(
            totalPrincipalLeft - (monthlyPaymentInSchool.amount - interest),
            true
        );

        totalInterestPaid += amountGoingTowardsInterest;
        totalPrincipalPaid += amountOfPrincipalPaid;
        principalToCalculateInterestOn -= amountOfPrincipalPaid;

        const interestPaid = formatMoneyAmountAsPositive(totalInterestPaid, true);
        const principalPaid = formatMoneyAmountAsPositive(totalPrincipalPaid, true);

        totalInterestLeft -= amountGoingTowardsInterest;
        totalPrincipalLeft -= monthlyPaymentInSchool.amount - interest;

        const dataPoint: StandardStudentLoanOfferGraphDataPoint = {
            date: getTimeFromDateString(date),
            age: calculateAge(dob, date),
            totalTimeRemaining: (totalTimeInDebt - j) * 30,
            monthlyPayment: monthlyPaymentInSchool,
            totalAmountRemaining: {
                interest: interestLeft,
                principal: principalLeft,
            },
            totalPaid: {
                interest: interestPaid,
                principal: principalPaid,
            },
        };

        dataPoints.push(dataPoint);
    }

    for (let j = timeTillImmediateRepay; j < totalTimeInDebt; j++) {
        date = getNextDate(date);

        const interest = calculateInterest(totalPrincipalLeft, rate);
        const minimumPayed = Math.min(monthlyPaymentAfterGrad.amount, interest);
        const interestLeft = formatMoneyAmountAsPositive(totalInterestLeft - minimumPayed, true);
        const principalLeft = formatMoneyAmountAsPositive(
            totalPrincipalLeft - (monthlyPaymentAfterGrad.amount - interest),
            true
        );

        totalInterestPaid += minimumPayed;
        totalPrincipalPaid += monthlyPaymentAfterGrad.amount - interest;

        const interestPaid = formatMoneyAmountAsPositive(totalInterestPaid, true);
        const principalPaid = formatMoneyAmountAsPositive(totalPrincipalPaid, true);

        totalInterestLeft -= minimumPayed;
        totalPrincipalLeft -= monthlyPaymentAfterGrad.amount - interest;

        const dataPoint: StandardStudentLoanOfferGraphDataPoint = {
            date: getTimeFromDateString(date),
            age: calculateAge(dob, date),
            totalTimeRemaining: (totalTimeInDebt - j) * 30,
            monthlyPayment: monthlyPaymentAfterGrad,
            totalAmountRemaining: {
                interest: interestLeft,
                principal: principalLeft,
            },
            totalPaid: {
                interest: interestPaid,
                principal: principalPaid,
            },
        };

        dataPoints.push(dataPoint);
    }
    outputObject.dataPoints = dataPoints;

    return outputObject as StandardStudentLoanOfferGraphOutput;
}

export function generateStandardOfferGraphData(params: {
    offers: StandardOfferGraphDataInput[];
    gradDate: number;
    dob: number;
}) {
    const { offers, gradDate, dob } = params;

    const output: StandardStudentLoanOfferGraphOutput[] = [];
    for (const offer of offers) {
        output.push(
            generateOfferGraphDataPoint({
                offer,
                gradDate,
                dob,
            })
        );
    }

    return output;
}
