interface Styles {
    width?: string;
    height?: string;
    fill?: string;
    stroke?: string;
}

interface SvgIconProps {
    pathDefinition: string | { d: string; stroke: string }[];
    styles?: Styles;
    targetClassName?: string;
    onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

export const SvgIcon: React.FC<SvgIconProps> = ({
    pathDefinition,
    styles,
    targetClassName,
    onClick,
}) => {
    const height = styles?.height ?? "24";
    const width = styles?.width ?? "24";
    const fill = styles?.fill ?? "none";

    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={targetClassName}
            onClick={onClick}
        >
            {Array.isArray(pathDefinition) ? (
                pathDefinition.map((definition) => (
                    <path
                        key={definition.d + "_key"}
                        d={definition.d}
                        stroke={definition.stroke}
                        fill={fill}
                    />
                ))
            ) : (
                <path d={pathDefinition} fill={fill} />
            )}
            ;
        </svg>
    );
};
