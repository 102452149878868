import {
    SparrowApi_POST_SubmitRequest,
    SparrowApi_POST_RequestExpandedSearchSubmit,
    SparrowApi_PUT_RequestExpandedSearch,
} from "./../core/services/sparrow-api-endpoints";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { computed, makeAutoObservable, runInAction } from "mobx";
import { clearPersistedStore, makePersistable, stopPersisting } from "mobx-persist-store";
import {
    IExpandedSearchNeeded,
    ProgressStatuses,
    ProgressRoadmap,
    RoadmapProgress,
    enumRequestStatus,
} from "../models/request";
import { UserType } from "../constants/defaultInputs";
import { PrequalSubmissionResults } from "../core/services/types/offers-api-types";
import { getDegrees, getMajors, getSchool, getVisaTypes } from "../views/onboarding/utils";
import {
    StandardStudentLoanOfferGraphOutput,
    IncomeBasedStudentLoanOfferGraphOutput,
} from "../utils/graphing/utils/types";
import { disableDefaultScroll, sentenceCase } from "../utils/global";
import { CosignerInput, ExpandedSearchDates } from "../../sparrow-ui-library/src/types/sparrow-types";
import { getEpochTimestamp } from "../utils/helper_functions/date-functions";
import { getPAAASCustomization, getPAAASAffiliateId } from "../../services/paaas/endpoint-requests";
import { TemplateCustomizeStylesProps, ToggleableViews } from "../types/paaas";
import { isMarketplaceEnv } from "../utils/config";
import { setCustomStyles } from "../../utils/paaas";
import { PrequalUrlsEnum } from "../core/global_routing/front-end-urls";
import {
    IPostPutPrequalificationRequest,
    IPostInviteAddRequest,
    IUserInput,
} from "../core/services/sparrow-api-handlers/prequalification-api-handlers";
import {
    PostPutPrequalificationRequest,
    PutRequestInviteAdd,
} from "../core/services/sparrow-api-handlers/prequalification-api-handlers";
import { Citizenship } from "../../sparrow-ui-library/src/constants/default-inputs/citizenship";
import { PrequalificationRequest } from "../../sparrow-ui-library/src/constants/default-inputs/prequalification-request";
import {
    IncludedUserStatus,
    OffersTablePreferences,
    PrequalificationRequestInput,
} from "../../sparrow-ui-library/src/types/sparrow-types";
import { Cosigner } from "../../sparrow-ui-library/src/constants/default-inputs/cosigner";
import { RequiredAddressFields } from "../../sparrow-ui-library/src/constants/default-inputs";
import { AsyncDropdownValues } from "../../sparrow-ui-library/src/types/marketplace-paaas-types";
import { Post, Put } from "../core/services/clientApi";
import { borrowerOffersUrl, userInclusionManagementUrl, userInclusionSelectUrl } from "../core/routes/navigation-urls";
import { membershipElectionUrl, setPreferencesUrl } from './../core/routes/routing-urls';
import { connectToWebSocketAndGetRequestResults } from "../core/services/sparrow-api-helper-functions/connect-to-websocket-and-get-request-results";
import { flattenDeepObject } from "../../sparrow-ui-library/src/utils/general-utils";
import { PAAASTopLevelFields } from "../types/paaas/paaas-top-level-fields";
import { MembershipElection } from '../../sparrow-ui-library/src/types/sparrow-types/membership-election';


type RouteStatePropsType = {
    email?: string;
    code?: string;
    cosignerInvited?: boolean;
    includeCosignerNow?: boolean;
    id?: string;
    type?: string;
    editFormID?: string;
    isEdit?: boolean;
    afterCosignerDelete?: boolean;
    SSNStatusBeforeEdit?: boolean;
    fromReview?: boolean;
    userData?: any;
};
export type LoanTypes = "PSL" | "SLR";
export enum enumUserInclusionType {
    DIRECT = "direct",
    INVITED = "invited",
}

export type LoanSelectionTypes =
    | "Undergraduate Loan"
    | "Graduate Loan"
    | "Refinance Student Debt"
    | "Professional Degree Loan"
    | "International Loan"
    | "International Refinance"
    | "University Landing Page";

export interface RoadmapStepState {
    progressStatus: ProgressStatuses;
    isCompleteStep: boolean;
    isLatestIncompleteStep: boolean;
    isActive: boolean;
}

export class OnboardingStore {
    activeLoanSelection: { type: string; loanSelectionType: string } | null =
        !!sessionStorage.getItem("activeLoanSelection")
            ? JSON.parse(sessionStorage.getItem("activeLoanSelection") || "")
            : null;
    activeProgressRoadmap: RoadmapStepState[] | undefined = undefined;
    activePostPrequalRequestData: Partial<RoadmapProgress> | undefined = undefined;
    activeSteps: string[] = [];
    creatorIsIncludingUser: boolean = false;
    displayNewRequestMenu: boolean = true;
    displayRoadmap: boolean = false;
    keepNewRequestMenuOnLoad: boolean = false;
    degrees: any = [];
    expandedSearchNeeded: IExpandedSearchNeeded = { couldBeShown: false, shouldBeShown: false };
    formData: PrequalificationRequestInput = !!sessionStorage.getItem("activeLoanRequest")
        ? JSON.parse(sessionStorage.getItem("activeLoanRequest") || "")
        : JSON.parse(JSON.stringify(PrequalificationRequest()));
    graphData?: {
        standardGraphData: StandardStudentLoanOfferGraphOutput[];
        incomeBasedGraphData: IncomeBasedStudentLoanOfferGraphOutput[] | undefined;
    };
    hasSSN?: boolean = undefined;
    invitedUserType: UserType | null = null;
    isAdditionalFieldsSoftSubmitted: boolean = true;
    jwt: string | null = sessionStorage.getItem("jwt") ?? null;
    loanRequests: PrequalificationRequestInput[] = !!sessionStorage.getItem("loanRequests")
        ? JSON.parse(sessionStorage.getItem("loanRequests") || "")
        : [];

    loanSelectionType: LoanSelectionTypes | null = null;
    majors = [];
    postPrequalRequestsData: ({ id: string } & Partial<RoadmapProgress>)[] = [];
    pqRequestResults: PrequalSubmissionResults | undefined = undefined;
    progressRoadmapStatuses: {
        id: string;
        progressStatus: ProgressStatuses;
        offerExplored?: boolean;
    }[] = [];
    quizAnswer: OffersTablePreferences | null = null;
    reviewStepReached: boolean = false;
    routeState: RouteStatePropsType | null = null;
    school = [];
    showOffersLoader = false;
    showProgress: boolean = false;
    showSignInModal = false;
    stepPathName: string | null = null;
    type: LoanTypes | null = null;
    userInclusionType: enumUserInclusionType | null = null;
    userType: UserType | null = null;
    visaTypes = [];
    asyncDropdownValues: AsyncDropdownValues | undefined = !!sessionStorage.getItem("OS")
        ? JSON.parse(sessionStorage.getItem("OS") || "").asyncDropdownValues
        : undefined;
    expandedSearchIsOutOfSeason = false;
    expandedSearchDates: ExpandedSearchDates | undefined = undefined;
    loading: boolean = false;

    editsMadePostPrequalSubmission: boolean = false;

    // Show Mobile Filter / Active Mobile Filters / Mobile Bookmarks / Mobile Compare vars
    showMobileFilter = false;
    hasActiveFilters = false;
    hasBookmark = false;
    hasCompare = false;
    showCompareComponent = false;
    requestHasExploredRate = false;
    companyName = !!sessionStorage.getItem("OS")
        ? JSON.parse(sessionStorage.getItem("OS") || "").companyName
        : "Sparrow";
    paaasTopLevelFields: PAAASTopLevelFields = !!sessionStorage.getItem("OS")
    ? JSON.parse(sessionStorage.getItem("OS") || "").paaasTopLevelFields
    : undefined;
    paaasCustomization: {partnerId: string, styles: TemplateCustomizeStylesProps, toggleableViews: ToggleableViews} | undefined = !!sessionStorage.getItem("OS")
    ? JSON.parse(sessionStorage.getItem("OS") || "").paaasCustomization
    : undefined;
    paaasCustomStyles: any = undefined;
    paaasCustomHeader?: TemplateCustomizeStylesProps["header"] = undefined;
    paaasCustomColors?: TemplateCustomizeStylesProps["colors"] = undefined;
    paaasCustomFonts?: TemplateCustomizeStylesProps["fonts"] = undefined;
    paaasCustomButtons?: TemplateCustomizeStylesProps["buttons"] = undefined;
    paaasCustomFooter?: TemplateCustomizeStylesProps["footer"] = undefined;
    paaasMembershipElection: MembershipElection | undefined = !!sessionStorage.getItem("OS")
    ? JSON.parse(sessionStorage.getItem("OS") || "").paaasCustomization
    : undefined;
        
    constructor() {
        /* This line makes the current instance (this) of the class observable. 
        When any property within this instance changes, MobX will be able to detect it 
        and update any reactive components that depend on that property.*/
        makeAutoObservable(this);
        /* This line makes the observable instance persistable, meaning its state can be saved 
        and restored across page reloads or browser sessions.*/
        makePersistable(this, {
            name: "OS",
            properties: [
                "companyName",
                "degrees",
                "visaTypes",
                "majors",
                "school",
                "stepPathName",
                "jwt",
                "showProgress",
                "expandedSearchNeeded",
                "formData",
                "quizAnswer",
                "graphData",
                "pqRequestResults",
                "progressRoadmapStatuses",
                "postPrequalRequestsData",
                "type",
                "loanSelectionType",
                "routeState",
                "isAdditionalFieldsSoftSubmitted",
                "invitedUserType",
                "userInclusionType",
                "creatorIsIncludingUser",
                "activeSteps",
                "hasSSN",
                "expandedSearchIsOutOfSeason",
                "expandedSearchDates",
                "paaasCustomization",
                "paaasTopLevelFields",
                "paaasMembershipElection",
                "asyncDropdownValues",
            ],
            storage: window.sessionStorage,
        });

        //Check if it's a PAAAS environment and fetch the customization data
        if (!isMarketplaceEnv()) {
            this.getAndSetPAAASCustomization();
        }
    }
    /**
     * @description Returns the formData object for the active user, unless they are directly including the other user
     * in which case it will return the formData object for the included user. Also returns the userType of the active user.
     * @returns {applicableFormData: PrequalificationRequest, userType: UserType}
     */
    getApplicableFormData = () => {
        const activeUserIsBorrower = this.formData.userType === UserType.BORROWER;
        // if the active user is the borrower and they are including themselves, use the borrower's formData and vice versa
        const selfInclusionData = activeUserIsBorrower ? this.formData : this.formData.cosigner;
        // if the active user is the borrower and they are directly including the cosigner, use the cosigner's formData and vice versa
        const directInclusionData = activeUserIsBorrower ? this.formData.cosigner : this.formData;

        const applicableFormData = this.creatorIsIncludingUser
            ? directInclusionData
            : selfInclusionData;

        return {
            applicableFormData,
            userType: this.formData.userType, //active userType is always top level in the formData object
        };
    };

    // Returns text based on if the question is being directed to the active user or the directly included user
    getApplicableUserText = (text: string) => {
        if (!this.creatorIsIncludingUser) return text;

        const includedUser = this.formData.includedUser?.userType as UserType;
        const mapping = {
            "your housing": "their housing",
            your: `your ${includedUser}'s`,
            "Do you": `Does your ${includedUser}`,
            "are you": `is your ${includedUser}`,
        };
        let applicableText = text;
        // Note: assumes only one key will be found
        Object.keys(mapping).forEach((key) => {
            // \b is a word boundary, so we only replace the exact string
            const regex = new RegExp(`\\b${key}\\b`);
            if (regex.test(text)) {
                applicableText = applicableText.replace(regex, mapping[key]);
            }
        });

        return applicableText;
    };
    
    getPostSubmissionUrl = (): typeof setPreferencesUrl | typeof borrowerOffersUrl | typeof membershipElectionUrl => {

        // If paaasCustomization is present then the app is in a PAAAS environment 
        const displayMembershipView = this.paaasCustomization?.toggleableViews.displayMembershipView;
        const { applicableFormData } = this.getApplicableFormData() as { applicableFormData: PrequalificationRequestInput | CosignerInput };

        const setPreferencesPageVisited = !!applicableFormData?.offersQuizAnswer;
        // If borrower prequalified they must visit the preferences page
        if(this.formData.status === enumRequestStatus.OFFERS && !setPreferencesPageVisited) return setPreferencesUrl;

        // If PAAAS environment and displayMembershipView is true, then the membership election page will be displayed
        if (displayMembershipView) {
            // Visit preferences page first if borrower prequalified 
            if(this.formData.status === enumRequestStatus.OFFERS && !setPreferencesPageVisited) return setPreferencesUrl;

            // Show membership election page during a session only if the user has not responded to the membership election prompt
            // Should a user resubmit a request, and the user indicated they are not a member, only then will the prompt show again - handled by prequalRequestHandler
            if(!this.paaasMembershipElection) return membershipElectionUrl;
        }

        return borrowerOffersUrl;
    };

    checkPermanentAddressIsSameAsMailingAddress = () => {
        // if the user has submitted their address information, then addressLine1 will be populated. If not, true by default.
        const { applicableFormData } = this.getApplicableFormData();

        if (!applicableFormData?.permanentAddress) return true;
        const addressFields = Object.keys(RequiredAddressFields());

        for (const field of addressFields) {
            if (
                applicableFormData.permanentAddress[field] !== applicableFormData.mailingAddress[field]
            ) {
                return false;
            }
        }

        return true;
    };

    checkEditsMadePostPrequalSubmission = (currentForm: {[key: string]: any}) => {
        const submitted = this.formData.status !== "started";
        if (!submitted) return false;
        // Check to see if the currentForm is the degrees form. The degrees form exists as an array of length 1 and not an object.
        const currentFormIsDegrees = !!currentForm.degrees;

        const currentPageFlattenedFormData = currentFormIsDegrees ? {...currentForm.degrees[0], enrollment: currentForm.enrollment} : flattenDeepObject(this.attachFormBodyToUserType(currentForm));
        const submittedFlattenedFormData = currentFormIsDegrees ? {...this.formData.degrees[0], enrollment: this.formData.enrollment} : flattenDeepObject(this.formData);

        for(const key in currentPageFlattenedFormData) {
            if(submittedFlattenedFormData[key] !== currentPageFlattenedFormData[key]) {
                return this.editsMadePostPrequalSubmission = true;
            }
        }

        return this.editsMadePostPrequalSubmission = false;
    }

    isRequestSubmission = () => {
        const newRequestNoId = !this.formData.status;
        const newRequestStarted = this.formData.status === "started";
        const directInclusionStarted = this.creatorIsIncludingUser && this.formData.includedUser?.status !== IncludedUserStatus.CONFIRMED;
        const submittedRequest = this.formData.status !== "started";

        // first submission
        if(newRequestNoId || newRequestStarted || directInclusionStarted) return true;
        // resubmission 
        return submittedRequest && this.editsMadePostPrequalSubmission;
    }

    setShowMobileFilter = (show: boolean = false) => {
        this.showMobileFilter = show;
        disableDefaultScroll(show);
    };
    setHasActiveFilters = (hasFilter: boolean = false) => {
        this.hasActiveFilters = hasFilter;
    };
    setHasBookmark = (bookmark: boolean = false) => {
        this.hasBookmark = bookmark;
    };
    setHasCompare = (compare: boolean = false) => {
        this.hasCompare = compare;
    };
    setShowCompareComponent = (show: boolean = false) => {
        this.showCompareComponent = show;
        disableDefaultScroll(show);
    };

    
    includedUserIsInvitedAndHasNotAcceptedInvite() {
        return (
            this.formData.isIncludedUser &&
            this.formData.includedUser?.status === IncludedUserStatus.INVITE_PENDING
        );
    }

    setExpandedSearchOutOfSeasonAndDates = (params: {
        dates?: ExpandedSearchDates | undefined;
        outOfSeason: boolean;
    }) => {
        const { dates, outOfSeason } = params;
        this.expandedSearchIsOutOfSeason = outOfSeason;
        this.expandedSearchDates = dates ?? undefined;
    };

    clearFormQuizAnswer() {
        this.formData.offersQuizAnswer = null;
    }

    setFormQuizAnswer = (quizAnswer: OffersTablePreferences) => {
        if (this.formData.userType === UserType.BORROWER) {
            this.formData.offersQuizAnswer = quizAnswer;
        } else if (this.formData.cosigner) {
            this.formData.cosigner.offersQuizAnswer = quizAnswer;
        }
        sessionStorage.setItem("activeLoanRequest", JSON.stringify(this.formData));
        sessionStorage.setItem("activeLoanRequestId", this.formData.id as string);
    };

    setActiveFormData = (formData: PrequalificationRequestInput) => {
        this.formData = formData;
        this.setUseShortSteps();
        sessionStorage.setItem("activeLoanRequest", JSON.stringify(formData));
        sessionStorage.setItem("activeLoanRequestId", this.formData.id as string);
    };

    setActiveSteps = (steps: string[]) => {
        this.activeSteps = steps;
    };

    setActiveProgressRoadmap = (progressRoadmapSteps: RoadmapStepState[]) => {
        this.activeProgressRoadmap = progressRoadmapSteps;
    };

    setActivePostPrequalRequestData = (
        postPrequalRequestData: Partial<RoadmapProgress> | undefined
    ) => {
        this.activePostPrequalRequestData = postPrequalRequestData;
    };

    setHasSSN = (hasSSN?: boolean | undefined) => {
        this.hasSSN = hasSSN;
    };

    setGraphData = (graphData?: {
        standardGraphData: StandardStudentLoanOfferGraphOutput[];
        incomeBasedGraphData: IncomeBasedStudentLoanOfferGraphOutput[] | undefined;
    }) => {
        this.graphData = graphData;
    };

    setPaaasMembershipElection = (membershipElection: MembershipElection | undefined) => {
        this.paaasMembershipElection = membershipElection;
    }

    updateLoanRequests = (formData: PrequalificationRequestInput) => {
        this.loanRequests = this.loanRequests.map((request) => {
            if (request.id === formData.id) {
                return formData;
            }
            return request;
        });
        sessionStorage.setItem("loanRequests", JSON.stringify(this.loanRequests));
    };

    getLabelUserText = (capitalized: boolean = false) => {
        if (this.creatorIsIncludingUser) {
            if (capitalized) {
                return sentenceCase(this.formData.includedUser?.userType) + "'s";
            }

            return this.formData.includedUser?.userType + "'s";
        }

        return "";
    };

    getActiveUserColorScheme = () => {
        return this.formData.userType === UserType.BORROWER ? "#6DB353" : "#DC7962";
    };

    //set the type of user initiating the prequalification request
    setUserType = (type: UserType | null) => {
        this.userType = type;
        this.formData.userType = type;
        this.updateActiveFormAndLoanRequests();
    };
    //set the type of the invited user
    setInvitedUserType = (type: UserType | null) => {
        this.invitedUserType = type;
    };

    setCreatorIsIncludingUser = (state: boolean) => {
        this.creatorIsIncludingUser = state;
    };

    setUserInclusionType = (type: enumUserInclusionType | null) => {
        this.userInclusionType = type;
    };

    setPqRequestResults = (results: PrequalSubmissionResults) => {
        this.pqRequestResults = results;
    };

    getOffersCount = () => {
        return (
            (this.pqRequestResults?.offerInformation.offers.length ?? 0) +
            (this.pqRequestResults?.offerInformation.incomeBasedOffers.length ?? 0)
        );
    };

    setDashboardMainContent = () => {
        if (this.loanRequests.length && !this.keepNewRequestMenuOnLoad) {
            this.showDisplayRoadmap();
        } else {
            this.showDisplayNewRequestMenu();
        }
    };

    setKeepNewRequestMenuOnLoad = (state: boolean) => {
        this.keepNewRequestMenuOnLoad = state;
    };

    showDisplayNewRequestMenu = () => {
        this.displayNewRequestMenu = true;
        this.displayRoadmap = false;
    };

    showDisplayRoadmap = () => {
        this.displayNewRequestMenu = false;
        this.displayRoadmap = true;
    };

    resetActiveLoanRequest = () => {
        this.formData = PrequalificationRequest();
        this.activePostPrequalRequestData = undefined;
        this.activeLoanSelection = null;
        this.creatorIsIncludingUser = false;
        this.reviewStepReached = false;
        sessionStorage.removeItem("activeLoanSelection");
        sessionStorage.removeItem("activeLoanRequest");
        sessionStorage.removeItem("activeLoanRequestId");
    };

    resetActiveLoanVariables = () => {
        this.resetActiveLoanRequest();
        this.reviewStepReached = false;
        this.hasSSN = undefined;
        this.pqRequestResults = undefined;
        this.editsMadePostPrequalSubmission = false;
    };

    // called when a loan request is deleted. Will update the active loan request to latest loan request if there is one, otherwise will reset the active loan request
    setLatestLoanRequest = () => {
        if (this.loanRequests.length) {
            const latestRequestId = this.loanRequests[0].id;
            this.setActiveLoanRequestData(latestRequestId as string);
            return;
        }

        this.resetActiveLoanVariables();
        this.showDisplayNewRequestMenu();
    };

    checkIsBorrower = (formData?: PrequalificationRequestInput) => {
        if (formData) {
            return formData.userType === UserType.BORROWER;
        }

        return this.formData.userType === UserType.BORROWER;
    };

    checkIsBorrowerCreator = () => {
        return this.checkIsBorrower() && this.formData.isCreator;
    };

    checkCreatorIncludedUser = () => {
        return this.formData.isCreator && this.formData.includedUser;
    };

    getOppositeUserType = () => {
        return this.formData.userType === UserType.BORROWER ? UserType.COSIGNER : UserType.BORROWER;
    };

    getCreatorUserType = () => {
        return this.formData.includedUser?.userType === UserType.BORROWER
            ? UserType.COSIGNER
            : UserType.BORROWER;
    };

    getUserTypeFormData = (userType: UserType) => {
        return userType === UserType.BORROWER ? this.formData : this.formData.cosigner;
    };

    getExpandedSearchDates = () => {
        const currentYear = new Date().getFullYear();
        // TODO: replace logic with future endpoint associated with Funding U
        const earliestStartDate =
            this.expandedSearchDates?.startMin ?? getEpochTimestamp(`08/01/${currentYear}`);
        const latestStartDate =
            this.expandedSearchDates?.startMax ?? getEpochTimestamp(`03/01/${currentYear + 1}`);

        const earliestEndDate =
            this.expandedSearchDates?.endMin ?? getEpochTimestamp(`03/01/${currentYear + 1}`);
        const latestEndDate =
            this.expandedSearchDates?.endMax ?? getEpochTimestamp(`07/01/${currentYear + 1}`);

        return {
            earliestStartDate,
            latestStartDate,
            earliestEndDate,
            latestEndDate,
        };
    };

    /**
     * @description This function takes in the progress status of a given loan request and returns an array of objects representing the state of each step in the roadmap.
    By default a step is incomplete unless otherwise specified by the below properties.
    * @param progress: ProgressStatuses 
    * @returns RoadmapStepState[]
    */
    getRoadmapStepStates = (progress: ProgressStatuses): RoadmapStepState[] => {
        const orderOfSteps = [
            ProgressStatuses.Prequalify,
            ProgressStatuses.CompareAndSelect,
            ProgressStatuses.ApplyWithLender,
            ProgressStatuses.FinalizeLoan,
            ProgressStatuses.ReceiveFunds,
            ProgressStatuses.Complete,
        ];

        const latestIncompleteStepIndex = orderOfSteps.findIndex((step) => {
            return progress === step;
        });

        let roadmapStepStates: RoadmapStepState[] = [];

        for (let i = 0; i < orderOfSteps.length; i++) {
            if (orderOfSteps[i] === ProgressStatuses.Complete) {
                continue;
            }

            roadmapStepStates.push({
                progressStatus: orderOfSteps[i],
                isCompleteStep: i < latestIncompleteStepIndex,
                isLatestIncompleteStep:
                    i === latestIncompleteStepIndex || (i === 4 && latestIncompleteStepIndex === 5),
                isActive:
                    i === latestIncompleteStepIndex || (i === 4 && latestIncompleteStepIndex === 5),
            });
        }

        return roadmapStepStates;
    };

    /**
     *
     * @description This function takes in the pathname of the current step and returns the previous and next step in the prequal flow.
     * @returns {prevStep: string, nextStep: string}
     */
    getStepRouting = (pathname: string): { prevStep: string; nextStep: string } => {
        const currentStepIndex = this.activeSteps.indexOf(pathname);
        const lastStepIndex = this.activeSteps.length - 1;

        const prevStep = this.activeSteps[currentStepIndex - 1] ?? this.activeSteps[0];

        const nextStep = this.reviewStepReached
            ? PrequalUrlsEnum.REVIEW
            : this.activeSteps[currentStepIndex + 1] ?? this.activeSteps[lastStepIndex];

        return {
            prevStep,
            nextStep,
        };
    };

    // sets formData and roadmap data. TODO: refactor to use one function to retrieve all data
    setActiveLoanRequestData = (id: string) => {
        if (!this.loanRequests.length) return;

        // set active form data
        const requestFormData = this.loanRequests.find((request) => request.id === id);

        if (requestFormData) {
            this.setActiveFormData(requestFormData);
        }

        //set active roadmap data
        const activeRoadmap = this.progressRoadmapStatuses.find((roadmap) => roadmap.id === id);

        if (activeRoadmap) {
            const roadmapStepStates = this.getRoadmapStepStates(activeRoadmap.progressStatus);
            this.setActiveProgressRoadmap(roadmapStepStates);
        }

        //set active post pq data
        const postPqRoadmapData = this.postPrequalRequestsData.find((request) => request.id === id);

        if (postPqRoadmapData) {
            this.setActivePostPrequalRequestData(postPqRoadmapData);
        } else {
            this.setActivePostPrequalRequestData(undefined);
        }

        // TODO: this variable could create side effects, should not be used
        this.requestHasExploredRate = !!activeRoadmap?.offerExplored;
        // display the active roadmap
        this.showDisplayRoadmap();
        return;
    };

    resetUserInclusionVariables = () => {
        this.invitedUserType = null;
        this.userInclusionType = null;
        this.creatorIsIncludingUser = false;
        this.hasSSN = undefined;
        this.reviewStepReached = false;
    };

    resetDashboardVariables = () => {
        this.resetUserInclusionVariables();
    };

    setQuizAnswer = (answer: OffersTablePreferences) => {
        this.quizAnswer = answer;
    };

    setIsAdditionalFieldsSoftSubmitted = (state: boolean) => {
        this.isAdditionalFieldsSoftSubmitted = state;
    };

    setExpandedSearchNeeded = (obj: IExpandedSearchNeeded) => {
        this.expandedSearchNeeded = obj;
    };

    setJwt = (token: string | null) => {
        this.jwt = token;
        if (token) {
            sessionStorage.setItem("jwt", token);
        } else {
            sessionStorage.removeItem("jwt");
        }
    };

    setSubmit = (state: boolean) => {
        // this.previouslySubmitted = state;
        this.formData.submitted = false;
        this.updateActiveFormAndLoanRequests();
    };

    setReviewStepReached = (value: boolean) => {
        this.reviewStepReached = value;
    };

    setEditsMadePostPrequalSubmission = (value: boolean) => {
        this.editsMadePostPrequalSubmission = value;
    }

    setLoanTypeAndSelection = (type: LoanTypes, loanSelectionType: LoanSelectionTypes) => {
        this.activeLoanSelection = { type, loanSelectionType };
        sessionStorage.setItem("activeLoanSelection", JSON.stringify(this.activeLoanSelection));

        this.formData = JSON.parse(JSON.stringify(PrequalificationRequest()));
        this.formData.type = type;
        this.formData.loanSelectionType = loanSelectionType;
        this.updateActiveFormAndLoanRequests();

        this.type = type;
        this.loanSelectionType = loanSelectionType;
    };

    updateActiveFormAndLoanRequests = () => {
        this.setActiveFormData(this.formData);
        this.updateLoanRequests(this.formData);
    };

    fetchFormFieldsValues = async () => {
        if (this.asyncDropdownValues) return;
        try {
            const [degreesData, visaTypesData, majorsData, schoolData] = await Promise.all([
                getDegrees(),
                getVisaTypes(),
                getMajors(),
                getSchool(),
            ]);

            runInAction(() => {
                this.asyncDropdownValues = {
                    degrees: degreesData,
                    visaTypes: visaTypesData,
                    majors: majorsData,
                    school: schoolData,
                };
            });
        } catch (err) {
            console.log(err);
        }
    };

    setUseShortSteps = (value?: boolean) => {
        /*
        This block is only used if the user has selected an ssn option, but has NOT yet submitted their form. 
        The reason for this is to make sure there aren't any side effects with the progress bar if the user goes to the previous step 
        before submitting their ssn election. 
        Otherwise we use enter the rest of the function and evaluate the user's ssn election based on their previously submitted data.
        */
        if ((this.hasSSN = typeof value === "boolean")) {
            return (this.hasSSN = !value);
        }

        //instantiate CitizenshipStatus for borrower if it doesn't exist
        if (!this.formData.citizenshipStatus) this.formData.citizenshipStatus = Citizenship();
        //instantiate CitizenshipStatus for cosigner if it doesn't exist
        if (!this.formData.cosigner) this.formData.cosigner = Cosigner();
        if (!this.formData.cosigner?.citizenshipStatus)
            this.formData.cosigner.citizenshipStatus = Citizenship();

        if (!this.creatorIsIncludingUser) {
            if (this.formData.userType === UserType.BORROWER) {
                return (this.hasSSN =
                    typeof this.formData.citizenshipStatus.hasSSN === "boolean"
                        ? this.formData.citizenshipStatus.hasSSN
                        : undefined);
            }

            if (this.formData.userType === UserType.COSIGNER) {
                return (this.hasSSN =
                    typeof this.formData.cosigner?.citizenshipStatus.hasSSN === "boolean"
                        ? this.formData.cosigner?.citizenshipStatus.hasSSN
                        : undefined);
            }
        }

        if (this.formData.includedUser?.userType === UserType.BORROWER) {
            return (this.hasSSN =
                typeof this.formData.citizenshipStatus.hasSSN === "boolean"
                    ? this.formData.citizenshipStatus.hasSSN
                    : undefined);
        }

        if (this.formData.includedUser?.userType === UserType.COSIGNER) {
            return (this.hasSSN =
                typeof this.formData.cosigner.citizenshipStatus.hasSSN === "boolean"
                    ? this.formData.cosigner.citizenshipStatus.hasSSN
                    : undefined);
        }

        return false;
    };

    @computed get useShortSteps() {
        return this.hasSSN === false;
    }

    getFormattedLoanRequestWithValues = (params: {
        latestForm: PrequalificationRequestInput;
        oldForm: PrequalificationRequestInput;
        missingFields?: { [key: string]: any } | undefined;
    }) => {
        const { latestForm, oldForm, missingFields } = params;

        const newestForm = { ...oldForm };

        for (const field in latestForm) {
            newestForm[field] = latestForm[field];
        }
        if (missingFields) {
            for (const field in missingFields) {
                newestForm[field] = missingFields[field];
            }
        }

        return newestForm;
    };

    //values is any array of request objects
    setLoanRequests = (values: ProgressRoadmap[], updateCurrent: boolean) => {
        this.loanRequests = [];
        this.postPrequalRequestsData = [];
        this.progressRoadmapStatuses = [];
        // @ts-ignore
        values.forEach((value) => {
            const updatedForm = this.getFormattedLoanRequestWithValues({
                latestForm: value.progress.prequalify.request,
                oldForm: PrequalificationRequest(),
            });
            this.loanRequests.push(updatedForm);
            this.progressRoadmapStatuses.push({
                id: value.requestId,
                progressStatus: value.progress.applicationStatus,
                offerExplored: value.progress.compareAndSelect?.offerExplored,
            });
            this.postPrequalRequestsData.push({
                id: value.requestId,
                compareAndSelect: value.progress.compareAndSelect,
                applyWithLender: value.progress.applyWithLender,
                finalizeLoan: value.progress.finalizeLoan,
                receiveFunds: value.progress.receiveFunds,
            });
        });
        //saving loan requests to session storage
        sessionStorage.setItem("loanRequests", JSON.stringify(this.loanRequests));

        if (updateCurrent) {
            const currentRoadmap = values.find((value) => value.requestId === this.formData.id);
            const currentRequest = this.loanRequests.find(
                (request) => request.id === this.formData.id
            );
            if (currentRoadmap && currentRequest) {
                this.updateLoanRequest(currentRoadmap, currentRequest);
                return;
            } else {
                // If we don't find the current request, it means it no longer exists for us, so we need to remove it
                // This could occur if an included user is on another page, and their creator deletes them
                // Or, if a user is signed in on multiple devices, and they delete the request from one of them
                this.resetActiveLoanVariables();
            }
        }
        if (this.loanRequests.length) {
            this.updateLoanRequest(values[0], this.loanRequests[0]);
        }
    };

    updateLoanRequest = (
        progressRoadmap: ProgressRoadmap,
        request: PrequalificationRequestInput
    ) => {
        //set the active loan request to the latest loan request
        this.setActiveFormData(request);
        //get the status of each step for the active roadmap
        const roadmapSteps = this.getRoadmapStepStates(progressRoadmap.progress.applicationStatus);
        this.setActiveProgressRoadmap(roadmapSteps);

        //get the postPqData for the active loan request
        if (progressRoadmap.progress.applicationStatus !== ProgressStatuses.Prequalify) {
            this.setActivePostPrequalRequestData(progressRoadmap.progress);
        } else {
            this.setActivePostPrequalRequestData(undefined);
        }
    };

    //
    deleteRequestFromLoanRequests = (id: string | number) => {
        this.loanRequests = this.loanRequests.filter((request) => request.id !== id);
        sessionStorage.setItem("loanRequests", JSON.stringify(this.loanRequests));
        this.setLatestLoanRequest();
    };

    saveFormInStoreAndStorage = (
        value: PrequalificationRequestInput,
        missingFields: { [key: string]: any } | undefined = undefined
    ) => {
        this.formData = this.getFormattedLoanRequestWithValues({
            latestForm: value,
            oldForm: this.formData,
            missingFields: missingFields,
        });
        this.updateActiveFormAndLoanRequests();
        //add to loanRequests if new id
        const index = this.loanRequests.findIndex((request) => request.id === this.formData.id);
        if (index === -1) {
            this.loanRequests.push(this.formData);
        } else {
            this.loanRequests[index] = this.formData;
        }
        sessionStorage.setItem("loanRequests", JSON.stringify(this.loanRequests));
    };

    //todo: figure out which one of these to remove, essentially doing the same thing
    /*********************************************************************************/
    deleteForm = (id: string | number) => {
        this.reviewStepReached = false;
        this.stepPathName = null;
        this.formData = JSON.parse(JSON.stringify(PrequalificationRequest()));
    };

    reset = (full = false) => {
        this.reviewStepReached = false;
        this.stepPathName = null;
        this.formData = JSON.parse(JSON.stringify(PrequalificationRequest()));
        this.routeState = null;
        if (full) {
            sessionStorage.removeItem("PSLFormData");
            sessionStorage.removeItem("SLRFormData");
        }
    };

    @computed get getStepsTotal() {
        return this.formData.stepsTotal;
    }

    @computed get getStepsCurrent() {
        return this.formData.stepsFinished;
    }

    setRouteState = (state: RouteStatePropsType | null) => {
        this.routeState = state;
    };

    setSignInModal = (v: boolean) => {
        this.showSignInModal = v;
    };

    setPqLoader = (v: boolean) => {
        this.loading = v;
    };

    setOffersLoader = (v: boolean) => {
            this.showOffersLoader = v;
    };

    closeOffersLoader = (v: boolean) => {
        this.showOffersLoader = v;
    };

    clearOnbStoredDate() {
        stopPersisting(this);
        clearPersistedStore(this);
    }

    isEligibleAndHasNotSubmittedExpandedSearch() {
        return (
            this.formData.expandedSearchEligible &&
            !this.formData.expandedSearchSubmitted &&
            !this.expandedSearchIsOutOfSeason
        );
    }

    getIncludedUserName() {
        if (this.formData.isCreator) {
            return (
                this.formData.includedUser?.firstName + " " + this.formData.includedUser?.lastName
            );
        }
        return this.formData.creator?.firstName + " " + this.formData.creator?.lastName;
    }

    getIncludedUserInitials() {
        if (this.formData.isCreator) {
            return (
                (this.formData.includedUser?.firstName[0] ?? "") +
                (this.formData.includedUser?.lastName[0] ?? "")
            );
        }
        return (
            (this.formData.creator?.firstName[0] ?? "") + (this.formData.creator?.lastName[0] ?? "")
        );
    }

    checkActiveUserTypeHasSSN = (userType: UserType) => {
        if (userType === UserType.BORROWER) {
            return this.formData.citizenshipStatus.hasSSN;
        }

        return this.formData.cosigner?.citizenshipStatus.hasSSN;
    };

    getMostRecentSubmittedLoanRequestOfSameTypeForUser = () => {
        if (!this.loanRequests?.length) return;
    
        const { userType, loanSelectionType } = this.formData;

        const loanRequestsOfSameTypeForUser = this.loanRequests.filter(
            (request) =>
                // Request was initiated by the user
                !request.isIncludedUser && 
                // Request matches current userType
                request.userType === userType &&
                // Request matches current loanSelectionType
                request.loanSelectionType === loanSelectionType &&
                //Request has been submitted
                request.submitted
        );
    
        const loanRequests = loanRequestsOfSameTypeForUser?.sort(
            (a, b) => (b.updatedAt ?? (b.createdAt as number)) - (a.updatedAt ?? (a.createdAt as number))
        );
    
        // Return most recent request - sorted in descending order by updatedAt
        return loanRequests?.[0];
    };

    handleAutofillRequest = (loanAmount?: number) => {
        const mostRecentRequestOfSameTypeForUser = this.getMostRecentSubmittedLoanRequestOfSameTypeForUser();
        if (!this.formData.id && mostRecentRequestOfSameTypeForUser) {
            const autofillProps = {
                loanRequestToClone: mostRecentRequestOfSameTypeForUser,
                loanAmount: loanAmount ?? Number(this.formData.loanAmount.amount),
            };
            return autofillProps;
        }
    };

    /**
     * Start: prequalRequestHandler logic
     *********************************************************************************************************************
     */

    //  We display a different loader for submission of a pq step vs getting offers
    getLoaderType = () => {
        const { isCreator, userType } = this.formData;
        if (userType === UserType.COSIGNER && isCreator && !this.creatorIsIncludingUser) {
            return this.setPqLoader;
        }

        return this.setOffersLoader;
    };

    // This function is called when the user submits the first step of the prequal flow
    //  TODO: incorporate autofill feature back in once rest of prequal is done
    handleFirstStep = ({
        formattedBackendForms,
        loanSelectionType,
        type,
    }: {
        formattedBackendForms: { [key: string]: any };
        loanSelectionType: string;
        type: string;
    }) => {
        // Set loanSelectionType and type in formattedBackendForms
        formattedBackendForms.loanSelectionType = loanSelectionType;
        formattedBackendForms.type = type;

        // Check if the request originates from the University Landing Page
        const isFromUniversity = !!localStorage.getItem("partnerLoanSelectionType");

        // If the request is from the University Landing Page, update loanSelectionType
        if (isFromUniversity) {
            formattedBackendForms.loanSelectionType = "University Landing Page";
        }
    };

    checkAllDataFullySubmitted = async (id: string, token: string) => {
        try {
            const response: { isFullySubmitted: boolean } = await Post(
                SparrowApi_POST_SubmitRequest,
                token,
                { id }
            );
            // If the request was initiated by a cosigner, they need to add their borrower to the request
            if (!response.isFullySubmitted) {
                this.loading = false;
                // If the cosigner has already added their borrower, redirect them to the management page, else redirect them to select how they would like to include their borrower
                return this.formData.includedUser
                    ? userInclusionManagementUrl
                    : userInclusionSelectUrl;
            }
            return "submitted";
        } catch (err) {
            console.log(err);
            this.loading = false;
        }
    };

    //This function is called to attach formBody to the correct user type
    attachFormBodyToUserType = (
        formBody: Partial<PrequalificationRequestInput>
    ):
        | Partial<PrequalificationRequestInput>
        | { cosigner: Partial<PrequalificationRequestInput> } => {
        // Case where this is called in first step (no id exists yet). Loan amount is top level so cosigner initiated requests would not require the cosigner field
        if (!this.formData.id) return formBody;

        const { formData, creatorIsIncludingUser } = this;
        const activeUserIsBorrower = formData.userType === UserType.BORROWER;

        // User including data about themselves
        const selfIncludedData = activeUserIsBorrower ? formBody : { cosigner: formBody };

        // User including data about the other user
        const directInclusionData = activeUserIsBorrower ? { cosigner: formBody } : formBody;

        // Return correct data based on whether the user is including themselves or the other user
        return creatorIsIncludingUser ? directInclusionData : selfIncludedData;
    };

    //Build the request body for the prequalification request
    getRequestBody = (
        formBody: Partial<PrequalificationRequestInput>
    ): IPostPutPrequalificationRequest | IPostInviteAddRequest => {
        const { id, userType } = this.formData;
        const { jwt, creatorIsIncludingUser } = this;

        if (!userType || !jwt) {
            return {} as IPostPutPrequalificationRequest;
        }
        // assign the formBody to the correct user type e.g. {cosigner: formBody} if request contains cosigner data
        const requestFormBody = this.attachFormBodyToUserType(formBody);

        if (creatorIsIncludingUser) {
            //Build the request for direct inclusion
            const newRequestFields = {
                id,
                ...requestFormBody,
            } as Partial<PrequalificationRequestInput> & IUserInput;

            return {
                inclusionType: enumUserInclusionType.DIRECT,
                newRequestFields,
                token: jwt,
            };
        }

        //Build the request for self inclusion
        return {
            userType,
            prequalificationRequestPostPutInput: {
                id,
                ...requestFormBody,
            },
            token: jwt,
            requestType: id ? "PUT" : "POST",
        };
    };

    // Expanded Search request handler
    expandedSearchRequestHandler = async (formattedBackendForms) => {
        const { jwt } = this;
        if (!jwt) return;

        await Put(SparrowApi_PUT_RequestExpandedSearch, jwt, {
            id: this.formData.id,
            ...formattedBackendForms,
        });
        const response = await Post(SparrowApi_POST_RequestExpandedSearchSubmit, jwt, {
            id: this.formData.id,
        });
        this.saveFormInStoreAndStorage(response.request);

        await connectToWebSocketAndGetRequestResults(this.formData.id, jwt);
    };

    /**
     *
     * @description This function is called after input fields have been validated when the user submits a step in the prequal flow.
     * @returns IPostPutPrequalificationRequest
     */

    prequalRequestHandler = async ({
        formattedBackendForms,
        pathName,
    }: {
        formattedBackendForms: { [key: string]: any };
        pathName: string;
    }) => {
        const { type, loanSelectionType } = this.formData;
        if (!type) return;

        if(pathName!==PrequalUrlsEnum.REVIEW && !this.isRequestSubmission()) {
            return "success";
        }

        if (pathName === PrequalUrlsEnum.REVIEW && !this.isRequestSubmission()) {
            return borrowerOffersUrl;
        }

        //Modify formattedBackendForms if this function is called in the first step of the prequal flow
        if (pathName === PrequalUrlsEnum.AMOUNT) {
            this.handleFirstStep({ formattedBackendForms, loanSelectionType, type });
        }

        if (pathName === PrequalUrlsEnum.EXPANDED) {
            await this.expandedSearchRequestHandler(formattedBackendForms);
            return "success";
        }

        const requestBody = this.getRequestBody(formattedBackendForms) as
            | IPostPutPrequalificationRequest
            | IPostInviteAddRequest;

        let prequalResponse: PrequalificationRequestInput | null = null;

        // try {
        this.loading = true;
        // Submit form data
        prequalResponse = this.creatorIsIncludingUser
            ? await PutRequestInviteAdd(requestBody as IPostInviteAddRequest)
            : await PostPutPrequalificationRequest(requestBody as IPostPutPrequalificationRequest);
        if (prequalResponse) {
            this.saveFormInStoreAndStorage(prequalResponse);
        }

        if (pathName === PrequalUrlsEnum.REVIEW) {
            // Form submission (submit for FMR)
            const response = await this.checkAllDataFullySubmitted(
                this.formData.id as string,
                requestBody.token
            );
            // If not fully submitted, will return appropriate url to redirect to
            if (response !== "submitted") {
                return response;
            }

            this.loading = false;
            this.getLoaderType()(true);

            /*** Routing logic derived from the requestResults object returned from submission ***/

            // Get results and url to route to quiz or pq decisions page (declined) based on response. If url is undefined, handle response separately
            const requestResults = await connectToWebSocketAndGetRequestResults(this.formData.id as string, requestBody.token);
            // If the membershipElection field is present, set it in store - this is a PAAAS only field
            this.paaasMembershipElection = requestResults?.membershipElection;
            // The user must be re-prompted for election if they previosly elected a non-member response
            if(this.paaasMembershipElection !== MembershipElection.MEMBER) {
                this.paaasMembershipElection = undefined;
            }

            this.getLoaderType()(false);
            
            //If no results remain on the Review page - a toast error will be provided to the user 
            if(!requestResults) return; 
            //If the user has prequalified, route to /set-preferences
            if(requestResults.status === enumRequestStatus.OFFERS){
                return setPreferencesUrl;
            }
            //If the user did not pre-qualify and (PAAAS environment with displayMembershipView toggled on, and they are not a member), route to /membership-election
            if(this.paaasCustomization?.toggleableViews.displayMembershipView && requestResults.membershipElection !== MembershipElection.MEMBER){
                return membershipElectionUrl;
            }

            return borrowerOffersUrl;
;
        }

        this.loading = false;
        return "success";
    };

    /**
     * End: prequalRequestHandler logic
     *********************************************************************************************************************
     */

    setPAAASCustomizationVariables = (styles: TemplateCustomizeStylesProps) => {
        const { header, colors, fonts, buttons, footer } = styles;
        this.paaasCustomHeader = header;
        this.paaasCustomColors = colors;
        this.paaasCustomFonts = fonts;
        this.paaasCustomButtons = buttons;
        this.paaasCustomFooter = footer;
    };
    /**
     * @description This function is called the store is instantiated only if the site is being used for PAAAS.
     * An async request will be made if paaasCustomization is not already set in sessionStorage.
     */
    getAndSetPAAASCustomization = async () => {
        //Get the affiliate id associated with the urls and so the default colors will be briefly shown.
        if (!this.paaasCustomization) {
            try {
                this.loading = true;
                const affiliateIdResp = await getPAAASAffiliateId();
                if (!affiliateIdResp) return;
                //Get the customization associated with the affiliate id
                const customizationResp = await getPAAASCustomization(affiliateIdResp.affiliateId);
                if (!customizationResp) return;
                const { styles, toggleableViews } = customizationResp.editorTemplate.template.customize;
                this.paaasCustomization = { styles, toggleableViews, partnerId: affiliateIdResp.affiliateId };
                this.paaasTopLevelFields.privacyPolicyUrl = customizationResp.privacyPolicyUrl;
                this.paaasTopLevelFields.termsOfServiceUrl = customizationResp.termsOfServiceUrl;
                this.companyName = customizationResp.name;
                this.paaasCustomization.partnerId = affiliateIdResp.affiliateId;
            } catch (err) {
                console.log(err);
            }
            this.loading = false;
        }

        if (!this.paaasCustomization) return;
        this.setPAAASCustomizationVariables(this.paaasCustomization.styles);
        // Directly updates css variables
        setCustomStyles(this.paaasCustomization.styles);

        this.loading = false;
    };
}
