import React from "react";
import { getCurrentUnixTime } from "../../../utils/general-utils";
import { FormFieldName, ToggleSwitchProps } from "../../../types/form-types";
import { useToggleTextComponent } from "../../../hooks";
import { Consent } from "../../../types/sparrow-types";
import { LinkContent } from "../../../types/ui-components";

import "./toggle-switch.styles.css";
interface ToggleSwitchComponentProps extends ToggleSwitchProps {
    toggleSwitchClassName?: string;
    textClassName?: string;
    textContent?: string;
    usedInForm?: boolean;
    handleConsent?: boolean;
    setInputValue: (name: FormFieldName, value: boolean | Consent) => void;
    linkContent?: LinkContent;
}

const ToggleSwitch: React.FC<ToggleSwitchComponentProps> = ({
    id,
    name,
    toggleSwitchClassName = "",
    textClassName = "",
    textContent = "",
    usedInForm = false,
    handleConsent = true,
    inputValue,
    setInputValue,
    linkContent,
    userType
}) => {
    const textComponent = useToggleTextComponent(textContent, linkContent);

    const handleToggleSwitchChange = (name: FormFieldName, value: boolean) => {
        //If the toggle switch is used to handle consent, then the value should be a Consent object, otherwise it should be a boolean
        const newInputValue = handleConsent ? {
            consented: value,
            timeOfResponse: getCurrentUnixTime(),
        } : value;

        setInputValue(name, newInputValue);
    };

    return (
        <div className={`ToggleSwitch ${usedInForm ? "UsedInForm" : ""}${toggleSwitchClassName} ${userType}`}>
            <div>
                <input
                    type="checkbox"
                    id={id}
                    name={name}
                    checked={
                        handleConsent ? (inputValue as Consent).consented : (inputValue as boolean)
                    }
                    onChange={(e) => {
                        handleToggleSwitchChange(name, e.target.checked)
                    }}
                />
                <label htmlFor={id}>
                    <span className="ToggleSwitchContent"></span>
                </label>
            </div>
            <span className={`ToggleSwitchText ${textClassName}`}>{textComponent}</span>
        </div>
    );
};

export default ToggleSwitch;
