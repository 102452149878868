import { OnboardingStore } from "../../../../store/onboarding";
import { PrequalSubmissionResults } from "../../types/offers-api-types";
import { getDeclineReasonsToDisplay } from "./get-decline-reasons";
import { mapValidationAndDeclineReasons } from "./map-decline-reasons-with-content-and-cta";

export const getValidationAndDeclineReasonsData = (
    pqResults: PrequalSubmissionResults,
    onboarding: OnboardingStore
) => {
    const declineReasonsToDisplay = getDeclineReasonsToDisplay(pqResults, onboarding);
    const validationAndDeclineReasonsData = mapValidationAndDeclineReasons(
        declineReasonsToDisplay,
        onboarding
    );
    return validationAndDeclineReasonsData;
};
