import { AuthFlowLayout } from "../../common/components/auth-component/authFlowLayout";
import { LoginRoutes } from "./routes";

export default function LoginWrapper() {
    return (
        <AuthFlowLayout>
            <LoginRoutes />
        </AuthFlowLayout>
    );
}
