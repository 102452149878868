import { MenuItems } from "../../../../../common/types/paaas";
import { ModalMenuType } from "../../../../../common/types/paaas";
import styles from "./styles.module.scss"

export const FooterLinks = (footerItem: MenuItems) => {
    const isDropdown = footerItem.type === ModalMenuType.Dropdown;
  
    const aTagAttrs = (item: MenuItems) => {
      return {
        target: item.isOpenNewTab ? "_blank" : "",
        rel: item.isOpenNewTab ? "noopener noreferrer" : "",
      }
    }
  
    return (
      <div className={styles.linksContainer}>
        {isDropdown ?
          <>
            <h4 className={styles.linkName}>{footerItem.name}</h4>
            <ul>
              {footerItem.children?.map((item) => {
                return <li key={item.id}><a className={styles.link} href={item.url} {...aTagAttrs(item)}>{item.name}</a></li>
              })}
            </ul>
          </> :
          <h4 className={styles.linkName}><a className={styles.link} href={footerItem.url} {...aTagAttrs(footerItem)}>{footerItem.name}</a></h4>}
  
      </div>
    )
  }