"use client";
import { FC, useState } from "react";
import crestFallback from "../../../assets/images/static/fallback-icons/crest.svg";
import studentFallback from "../../../assets/images/static/fallback-icons/student-loan.svg";
import crestIconAnimation from "../../../assets/lottieJSON/icons/crest/data.json";
import hat from "../../../assets/lottieJSON/icons/Student/data.json";
import IconAnimated from "../../../staticComponents/IconAnimation";
import { RegularButtonWithLoading } from "../../components/buttons/regular_button/RegularButtonWithLoading";

interface AuthInitStepProps {
    stepTitle: string;
    stepSubTitle: React.ReactNode;
    nextStepUrl: string;
    crestUrl: string;
    type?: "sign-in" | "sign-up";
}

const AuthInitStep: FC<AuthInitStepProps> = ({ type = "sign-up", ...props }) => {
    const [nextStep, setNextStep] = useState<string>();
    const [isBorrowerPaused, setIsBorrowerPaused] = useState(true);
    const [isCrestPaused, setIsCrestPaused] = useState(true);
    const [loading, setLoading] = useState(false);

    return (
        <>
            <div className="step-form-header">
                <h2 className="h2">{props.stepTitle}</h2>
                <div className="">{props.stepSubTitle}</div>
            </div>
            <div className="step-form-body">
                <div
                    id={"borrower-account-type-button"}
                    onMouseEnter={() => setIsBorrowerPaused(false)}
                    onMouseLeave={() => setIsBorrowerPaused(true)}
                    className={`account-type-container ${
                        nextStep === props.nextStepUrl ? "active" : ""
                    }`}
                    onClick={() => setNextStep(props.nextStepUrl)}
                >
                    <IconAnimated
                        animateJson={hat}
                        loop
                        size={40}
                        autoplay={false}
                        fallback={studentFallback}
                        isPaused={isBorrowerPaused}
                    />
                    <div className="account-type--inner">
                        <h5>Borrower or Cosigner</h5>
                        {type === "sign-up" ? (
                            <p>Find the most affordable student loan</p>
                        ) : (
                            <p>Conquer your student loan search</p>
                        )}
                    </div>
                </div>
                <div
                    id={"crest-account-type-button"}
                    onMouseEnter={() => setIsCrestPaused(false)}
                    onMouseLeave={() => setIsCrestPaused(true)}
                    className={`account-type-container ${
                        nextStep === props.crestUrl ? "active" : ""
                    }`}
                    onClick={() => setNextStep(props.crestUrl)}
                >
                    <IconAnimated
                        animateJson={crestIconAnimation}
                        loop
                        size={40}
                        autoplay={false}
                        fallback={crestFallback}
                        isPaused={isCrestPaused}
                    />
                    <div className="account-type--inner">
                        <h5>Crest</h5>
                        {type === "sign-up" ? (
                            <p>Launch a white-labeled student loan marketplace</p>
                        ) : (
                            <p>Manage your student loan marketplace</p>
                        )}
                    </div>
                </div>
            </div>
            <div className="step-form-footer">
                <div className="button-group">
                    <RegularButtonWithLoading
                        id={"account-type-continue-button"}
                        loading={loading}
                        target={nextStep === props.crestUrl ? "_blank" : "_self"}
                        onSubmit={() => (nextStep !== props.crestUrl ? setLoading(true) : null)}
                        link={nextStep}
                        disabled={!nextStep}
                    >
                        Continue
                    </RegularButtonWithLoading>
                </div>
            </div>
        </>
    );
};

export default AuthInitStep;
