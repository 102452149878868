import { OfferFiltersOptions } from "../../../../../components/OfferFilters/utilities";
import { isDefined } from "../../../../../utils/helper_functions/validation-functions";

/**
 * This function determines if an offer satisfies the term filters
 * @param filters
 * @param offer
 * @returns
 */
export function offerSatisfiesTermFilters(filters: string[], offer: any) {
    for (const filter of filters) {
        const filterTermValue = filter.split(" ")[0];
        if (offer?.term > parseInt(filterTermValue)) {
            return false;
        }
    }
    return true;
}

/**
 * This function determines if an offer satisfies the past due balance filters
 * @param filters
 * @param offer
 * @returns
 */
export function offerSatisfiesSAPFilters(filters: string[], offer: any) {
    for (const filter of filters) {
        if (filter === "Not reported" && isDefined(offer?.satisfactoryAcademicProgressRequired)) {
            return false;
        } else if (
            filter === "Yes" &&
            (!isDefined(offer?.satisfactoryAcademicProgressRequired) ||
                !offer?.satisfactoryAcademicProgressRequired)
        ) {
            return false;
        } else if (
            filter === "No" &&
            (!isDefined(offer?.satisfactoryAcademicProgressRequired) ||
                offer?.satisfactoryAcademicProgressRequired)
        ) {
            return false;
        }
    }
    return true;
}

/**
 * This function determines if an offer satisfies the SAP filters
 * @param filters
 * @param offer
 * @returns
 */
export function offerSatisfiesPastDueBalancesFilters(filters: string[], offer: any) {
    for (const filter of filters) {
        if (filter === "Not reported" && isDefined(offer?.pastDueBalancesAllowed)) {
            return false;
        } else if (filter === "Within 6 months" && offer?.pastDueBalancesAllowed !== 6) {
            return false;
        } else if (filter === "Within 1 year" && offer?.pastDueBalancesAllowed !== 12) {
            return false;
        } else if (filter === "Not allowed" && offer?.pastDueBalancesAllowed !== 0) {
            return false;
        }
    }
    return true;
}

/**
 * This function determines if an offer satisfies the other filters
 * Currently active filters:
 * 1. Bookmarked
 * @param filters
 * @param offer
 * @returns
 */
export function offerSatisfiesOtherFilters(filters: string[], offer: any) {
    for (const filter of filters) {
        if (filter === "bookmarked") {
            if (!offer.bookmarked && !offer.cosignerBookmarked) return false;
        }
    }
    return true;
}

/**
 * This function determines if an offer satisfies the lender filters
 * @param filters
 * @param offer
 * @returns
 */
export function offerSatisfiesLenderFilters(filters: string[], offer: any) {
    return filters.includes(offer.lender);
}

/**
 * This function determines if an offer satisfies the payment plan filters
 * @param filters
 * @param offer
 * @returns
 */
export function offerSatisfiesPaymentPlanFilters(filters: string[], offer: any) {
    const paymentPlan = offer.paymentPlan;
    for (const filter of filters) {
        if (
            filter === "Immediate" &&
            (paymentPlan === "Immediate" || paymentPlan === "Immediate Repay")
        ) {
            return true;
        } else if (filter === "Interest only" && paymentPlan === "Interest Only") {
            return true;
        } else if (
            filter === "Fixed (partial interest)" &&
            (paymentPlan === "Minimum $25 Payment" || paymentPlan === "Flat payment")
        ) {
            return true;
        } else if (filter === "Fully deferred" && paymentPlan === "Deferred") {
            return true;
        }
    }
    return false;
}

/**
 * This function determines if an offer satisfies the loan type filters
 * @param filters
 * @param offer
 * @returns
 */
export function offerSatisfiesLoanTypeFilters(filters: string[], offer: any) {
    // note this is not correct
    return filters.includes(offer.loanType);
}

/**
 * This function determines if an offer satisfies the rate type filters
 * @param filters
 * @param offer
 * @returns
 */
export function offerSatisfiesRateTypeFilters(filters: string[], offer: any) {
    return filters.includes(offer.loanType.charAt(0).toUpperCase() + offer.loanType.slice(1));
}

/**
 * This function splits the offer data into two sets of offers - For You and All Other
 * The For You offers are capped at 5 total and do not allow for auto rounded offers.
 * @param offers All offer data
 * @returns
 */
export function extractForYouOffersFromAllOtherOffers(offers: any[]): {
    forYou: any[];
    allOther: any[];
} {
    const forYou: any[] = [];
    const allOther: any[] = [];

    for (const offer of offers) {
        if (offer.amountWasRounded || forYou.length >= 5) {
            allOther.push(offer);
        } else {
            forYou.push(offer);
        }
    }

    return {
        forYou,
        allOther,
    };
}

/**
 * This function iterates through all offers and applies filters
 * @param offers
 * @returns
 */
export function filterOffers(offers: any[], newFilterOptions: OfferFiltersOptions) {
    const filteredOffers: any[] = [];
    for (const offer of offers) {
        // sap
        if (newFilterOptions.sapOptions.length) {
            if (!offerSatisfiesSAPFilters(newFilterOptions.sapOptions, offer)) {
                continue;
            }
        }
        // past due balance
        if (newFilterOptions.pastDueBalanceOptions.length) {
            if (
                !offerSatisfiesPastDueBalancesFilters(newFilterOptions.pastDueBalanceOptions, offer)
            ) {
                continue;
            }
        }
        // term
        if (newFilterOptions.termOptions.length) {
            if (!offerSatisfiesTermFilters(newFilterOptions.termOptions, offer)) {
                continue;
            }
        }
        // lender
        if (newFilterOptions.lenderOptions.length) {
            if (!offerSatisfiesLenderFilters(newFilterOptions.lenderOptions, offer)) {
                continue;
            }
        }
        // rate type
        if (newFilterOptions.rateTypeOptions.length) {
            if (!offerSatisfiesRateTypeFilters(newFilterOptions.rateTypeOptions, offer)) {
                continue;
            }
        }
        // loan type
        if (newFilterOptions.loanTypeOptions.length) {
            if (!offerSatisfiesLoanTypeFilters(newFilterOptions.loanTypeOptions, offer)) {
                continue;
            }
        }
        // repayment type
        if (newFilterOptions.paymentPlanOptions.length) {
            if (!offerSatisfiesPaymentPlanFilters(newFilterOptions.paymentPlanOptions, offer)) {
                continue;
            }
        }

        // other
        if (newFilterOptions.otherOptions?.length) {
            if (!offerSatisfiesOtherFilters(newFilterOptions.otherOptions, offer)) {
                continue;
            }
        }

        filteredOffers.push(offer);
    }

    return filteredOffers;
}
