import React from "react";
import { 
    AriaAttributesProps,
    TextBoxEventHandlers, 
    TextBoxProps,
    FormFieldName 
} from "../../../types/form-types";
import { getInputRestrictionFunction } from "../../../utils/ui-library-utils";
import { formatToSentenceCase } from "../../../utils/general-utils";
import FormLabel from "../../form-label";
import "./TextBox.css";
import "../form.styles.css";

const TextBox = React.forwardRef(({
    autoComplete = "off",
    ariaLabel = "input field",
    type = "text",
    id,
    label,
    placeholder,
    inputValue = "",
    children,
    onChange,
    onKeyDownHandler,
    onClick,
    onBlur,
    onFocus,
    style,
    name,
    hasToolTip,
    toolTipText
}: (TextBoxProps & TextBoxEventHandlers & AriaAttributesProps), ref) => {

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
      
        if (!value) {
          onChange(e);
          return;
        }
      
        const inputRestrictionFunction = getInputRestrictionFunction(name);
        if (!inputRestrictionFunction || inputRestrictionFunction(value)) {
          onChange(e);
        }
      };

    const handleKeyDownEvents = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === "Enter"){
            e.preventDefault();
        }

        if(name === FormFieldName.SSN && inputValue){
            if(e.key === "Backspace" && (inputValue as string).length === 11){
                const newEvent = e as any;
                newEvent.target.value = "";
                onChange(newEvent)
                return;
            }
        }
        
        if(!onKeyDownHandler) return;
        // This is a specific handler provided by a UI component that makes use of the TextBox component and has specific key down behavior  
        onKeyDownHandler(e);
    }

    return (
        <div>
            <FormLabel
                label={label}
                id={id}
                hasTooltip={hasToolTip}
                tooltipText={toolTipText}
            />
            <div className="InputFieldContainer">
                <input
                    autoComplete={autoComplete}
                    className="TextboxInput InputOverflow"
                    id={id}
                    onBlur={onBlur}
                    onChange={handleInputChange}
                    onClick={onClick}
                    onFocus={onFocus}
                    onKeyDown={handleKeyDownEvents}
                    placeholder={formatToSentenceCase(placeholder)}
                    ref={ref as any}
                    type={type}
                    value={inputValue as string}
                />
                {children}
            </div>
        </div>
    );
});

export default TextBox;
