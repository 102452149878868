import { UserType } from "../../../../types/sparrow-types";

export const LenderTypeIcon = ({
    userType,
    isActive,
}: {
    userType: UserType;
    isActive: boolean;
}) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.97245 1.334C7.89049 1.33849 7.81089 1.36308 7.74068 1.40562L3.20422 4.15496C2.50153 4.58112 1.9998 5.29322 1.9998 6.16668C1.99981 6.29929 2.05249 6.42646 2.14626 6.52022C2.24002 6.61399 2.36719 6.66667 2.4998 6.66668H3.33221H4.33221H6.33221H7.33221H8.99884H9.99884H11.6655H12.6655H13.4998C13.6324 6.66667 13.7596 6.61399 13.8533 6.52022C13.9471 6.42646 13.9998 6.29929 13.9998 6.16668C13.9998 5.29322 13.4987 4.58112 12.796 4.15496L8.25891 1.40562C8.17281 1.35345 8.07297 1.32849 7.97245 1.334ZM7.9998 2.41799L12.2771 5.01043C12.5844 5.19677 12.7562 5.4161 12.867 5.66668H3.13261C3.24341 5.4161 3.4152 5.19677 3.72245 5.01043L7.9998 2.41799ZM7.9998 3.66668C7.82299 3.66668 7.65342 3.73692 7.52839 3.86195C7.40337 3.98697 7.33313 4.15654 7.33313 4.33335C7.33313 4.51016 7.40337 4.67973 7.52839 4.80475C7.65342 4.92978 7.82299 5.00002 7.9998 5.00002C8.17661 5.00002 8.34618 4.92978 8.4712 4.80475C8.59623 4.67973 8.66646 4.51016 8.66646 4.33335C8.66646 4.15654 8.59623 3.98697 8.4712 3.86195C8.34618 3.73692 8.17661 3.66668 7.9998 3.66668ZM2.4998 13.3333C2.43354 13.3324 2.36776 13.3447 2.30628 13.3694C2.24479 13.3941 2.18883 13.4307 2.14165 13.4773C2.09446 13.5238 2.05699 13.5792 2.03142 13.6404C2.00585 13.7015 1.99268 13.7671 1.99268 13.8333C1.99268 13.8996 2.00585 13.9652 2.03142 14.0263C2.05699 14.0875 2.09446 14.1429 2.14165 14.1894C2.18883 14.236 2.24479 14.2726 2.30628 14.2973C2.36776 14.322 2.43354 14.3343 2.4998 14.3333H13.4998C13.5661 14.3343 13.6318 14.322 13.6933 14.2973C13.7548 14.2726 13.8108 14.236 13.8579 14.1894C13.9051 14.1429 13.9426 14.0875 13.9682 14.0263C13.9937 13.9652 14.0069 13.8996 14.0069 13.8333C14.0069 13.7671 13.9937 13.7015 13.9682 13.6404C13.9426 13.5792 13.9051 13.5238 13.8579 13.4773C13.8108 13.4307 13.7548 13.3941 13.6933 13.3694C13.6318 13.3447 13.5661 13.3324 13.4998 13.3333H2.4998Z"
                fill={isActive ? `rgba(var(--${userType}PrimaryColor), 1)` : "#525966"}
            />
            <path
                d="M4.33221 6.66668H3.33221L3.33313 12.1667C3.33314 12.2993 3.38583 12.4265 3.47959 12.5202C3.57336 12.614 3.70053 12.6667 3.83313 12.6667H12.1665C12.2991 12.6667 12.4262 12.614 12.52 12.5202C12.6138 12.4265 12.6664 12.2993 12.6665 12.1667L12.6655 6.66668H11.6655L11.6665 11.6667L9.99976 11.6667L9.99884 6.66668H8.99884L8.99976 11.6667L8.33313 11.6667L7.33313 11.6667L7.33221 6.66668H6.33221L6.33313 11.6667L5.9998 11.6667H4.9998H4.33313L4.33221 6.66668Z"
                fill={isActive ? `rgba(var(--${userType}PrimaryColor), 1)` : "#525966"}
            />
        </svg>
    );
};
