import {
    prequalificationAmountUrl,
    prequalificationCitizenshipUrl,
    prequalificationAddressUrl,
    prequalificationIncomeUrl,
    prequalificationDegreesUrl,
    prequalificationReviewUrl,
    prequalificationExpandedSearchUrl,
    userInclusionInformationUrl,
    userInclusionSelectUrl,
    userInclusionManagementUrl,
    userInclusionReviewInviteUrl,
} from "../common/core/routes/navigation-urls";
import { selectUserTypeBase } from "../common/core/routes/routing-urls";
import { isMarketplaceEnv } from "../common/utils/config";
import { useOnboarding } from "../common/core/providers/onboarding.provider";
import { UserType } from "../sparrow-ui-library/src/types/sparrow-types";
import { IncludedUserStatus } from "../sparrow-ui-library/src/types/sparrow-types";
import { getPossesiveCase } from "../common/utils/global";


export const useGetTips = (pathName: string) => {
    const {creatorIsIncludingUser, formData, getOppositeUserType, companyName} = useOnboarding();
    const firstTitle = isMarketplaceEnv() ? "Tips from the Nest" : `Tips from ${companyName}`;
    const isSelfIncludedCosigner = !creatorIsIncludingUser && formData?.userType === UserType.COSIGNER;
    const isDirectIncludedCosigner = creatorIsIncludingUser && formData?.includedUser?.userType === UserType.COSIGNER;
    const includedUser = getOppositeUserType();
    
    const tipsObject = {
        [selectUserTypeBase]: [
            {
                title: firstTitle,
                body: "If you are searching for a loan to cover your education costs, please select borrower. If you are looking to help a borrower pre-qualify, please select cosigner.",
            },
            {
                title: "What is a cosigner?",
                body: "A cosigner is a person who takes legal responsibility to make payments on a student loan if the borrower fails to make payments.",
            },
            {
                title: "Do I need a cosigner?",
                body: "You do not need a cosigner to pre-qualify! However, adding a creditworthy cosigner can improve your eligibility.",
            },
            {
                title: "Can I add a cosigner later?",
                body: "Of course! You can always add a cosigner after submitting your solo request.",
            },
        ],
        [prequalificationAmountUrl]: [
            {
                title: firstTitle,
                body: "Your loan amount should not exceed the total cost of attendance minus any financial aid received.",
            },
            {
                title: `Can I estimate how much ${isSelfIncludedCosigner ? "we" : "I"} need?`,
                body: `Yes. The loan amount that you input on ${companyName} can be an estimate. You can always adjust the loan amount later after submitting your information.`,
            },
            {
                title: `Can ${isSelfIncludedCosigner ? "we" : "I"} borrow for the whole year?`,
                body: "Yes. You can borrow for the whole year or just a semester.",
            },
            {
                title: `How does ${isSelfIncludedCosigner ? "our" : "my"} loan get disbursed?`,
                body: `Your loan is disbursed to your ${isSelfIncludedCosigner ? "borrower's" : ""} school, not directly to you. Your ${isSelfIncludedCosigner ? "borrower's" : ""} school sets the date of disbursement which is usually around the beginning of the semester.`,
            },
        ],
        [prequalificationCitizenshipUrl]: [
            {
                title: firstTitle,
                body: "Lenders are typically able to provide loans to U.S. citizens, visa holders, DACA recipients, and international citizens.",
            },
            {
                title: `Can ${creatorIsIncludingUser ? `my ${includedUser}` : "I"} pre-qualify as a foreign citizen?`,
                body: `Yes. ${(isSelfIncludedCosigner || isDirectIncludedCosigner) ? `So long as ${creatorIsIncludingUser ? "they" : "you"} have a SSN, ${creatorIsIncludingUser ? "they" : "you"} can pre-qualify as a cosigner on ${companyName}'s platform.` : ` Foreign citizens can pre-qualify for loans on ${getPossesiveCase(companyName)} platform.`}`,
            },
            {
                title: `Will ${companyName} verify my ${creatorIsIncludingUser ? `${includedUser}'s` : ""} citizenship?`,
                body: `No. ${companyName} does not verify your ${creatorIsIncludingUser ? `${includedUser}'s` : ""} citizenship. Your lender will verify your ${creatorIsIncludingUser ? `${includedUser}'s` : ""} citizenship once you go through their approval process.`,
            },
            {
                title: `Can ${creatorIsIncludingUser ? `my ${includedUser}` : "I"} pre-qualify if ${creatorIsIncludingUser ? "they" : "I"} don't have a SSN?`,
                body: `${(isSelfIncludedCosigner || isDirectIncludedCosigner) ? 
                `Unfortunately, the lenders on ${getPossesiveCase(companyName)} platform require cosigners to have a SSN in order to pre-qualify.` : 
                `${creatorIsIncludingUser ? `The lenders on ${getPossesiveCase(companyName)} platform require either the borrower or the cosigner to have a SSN in order to pre-qualify.` : "Yes. You can pre-qualify without a SSN. However, to increase your eligibility, you're encouraged to include your SSN or apply with a cosigner who has a SSN, after you have submitted your information."}`}`,
            },
        ],
        [prequalificationAddressUrl]: [
            {
                title: firstTitle,
                body: `Your ${creatorIsIncludingUser ? `${includedUser}'s` : ""} permanent address is a physical address where ${creatorIsIncludingUser ? `your ${includedUser} permanently resides` : "you permanently reside"}. This is typically the address displayed on ${creatorIsIncludingUser ? `your ${includedUser}'s` : "your"} driver's license or government issued ID.`,
            },
            {
                title: "Which address should I use?",
                body: `Use the address on your ${creatorIsIncludingUser ? `${includedUser}'s` : ""} driver's license or government issued ID.${isSelfIncludedCosigner ? "" : " For many students, this is the address where their parents or guardians live."}`,
            },
            {
                title: "What housing expense should I include?",
                body: `Housing expense refers to your ${creatorIsIncludingUser ? `${includedUser}'s` : ""} monthly rent or mortgage payments. If ${creatorIsIncludingUser ? `your ${includedUser} shares their` : "you share your"} rent or mortgage payments with another person, only include the amount ${creatorIsIncludingUser ? "they" : "you"} pay each month. If ${creatorIsIncludingUser ? "they" : "you"} have no housing expenses, please input zero.`,
            },
        ],
        [prequalificationIncomeUrl] : [
            {
                title: firstTitle,
                body: `Including ${creatorIsIncludingUser ? `your ${includedUser}'s` : "an"} income is optional, although highly encouraged. You don't have to be exact as lenders will verify ${creatorIsIncludingUser ? "their" : "your"} income later.`
            },
            {
                title: `What's considered "Employed?"`,
                body: `${creatorIsIncludingUser ? `Your ${includedUser} is` : "You are"} considered "employed" if ${creatorIsIncludingUser ? "they" : "you"} work for pay or profit. This includes part-time and temporary work, as well as regular full-time employment.`
            },
            {
                title: `What if ${creatorIsIncludingUser ? `my ${includedUser} doesn't` : "I don't"} have income?`,
                body: `Some lenders may be willing to provide a loan if ${creatorIsIncludingUser ? `your ${includedUser} does not` : "you do not"} have an income, but you may be required to satisfy additional conditions${isSelfIncludedCosigner ? "." : ", like having a cosigner."}`
            },
            {
                title: `What if ${creatorIsIncludingUser ? `my ${includedUser} is` : "I'm"} unemployed?`,
                body: `Some lenders may be willing to provide a loan if ${creatorIsIncludingUser ? `your ${includedUser} is` : "you are"} unemployed, but ${creatorIsIncludingUser ? "they" : "you"} may be required to satisfy additional conditions.`
            }
        ],
        [prequalificationDegreesUrl] : [
            {
                title: firstTitle,
                body: `Only include the highest degree that ${creatorIsIncludingUser ? "your borrower has earned or is in" : "you've earned or are in"} the process of earning.`,
            },
            {
                title: `What if ${creatorIsIncludingUser ? "my borrower is not" : "I'm not" } enrolled full time?`,
                body: `Certain lenders on the ${companyName} platform will lend to students who are enrolled less than full time.`
            },
            {
                title: `Why can't I find my ${creatorIsIncludingUser ? "borrower's" : "" } school?`,
                body: `Double check the spelling of your ${creatorIsIncludingUser ? "borrower's" : "" } school. For example, if you searched for “UNC” try “University of North Carolina” instead.`
            },
            {
                title: "Does graduation date affect eligibility?",
                body: "Lenders are typically willing to provide loans to Freshman, Sophomores, Juniors, Seniors, Graduates, PhDs, and pre-professionals."
            }
        ],
        [prequalificationReviewUrl] : [
            {
                title: firstTitle,
                body: `Submitting for pre-qualification on ${companyName} does not impact your credit score${creatorIsIncludingUser ? ` or your ${includedUser}'s credit score` : ""}.`,
            },
            {
                title: `Why are you asking for my ${creatorIsIncludingUser ? `${includedUser}'s` : ""} SSN?`,
                body: `We use your ${creatorIsIncludingUser ? `${includedUser}'s SSN to verify their identity and perform a soft pull of their credit, which will not affect their credit score.` : "SSN to verify your identity and perform a soft pull of your credit, which will not affect your credit score."}`
            }, 
            {
                title: "How long does it take to get rates?",
                body: `Pre-qualification decisions are returned within minutes. During this time, your pre-qualified eligibility will be checked with every applicable lender on ${getPossesiveCase(companyName)} platform.`
            },
            {
                title: `Will this impact my ${creatorIsIncludingUser ? `${includedUser}'s` : ""} credit score?`,
                body: `No. A soft-credit pull does not impact your credit score${creatorIsIncludingUser ? ` or your ${includedUser}'s credit score` : ""}.`,
            }
        ],
        [prequalificationExpandedSearchUrl] : [
            {
                title: firstTitle,
                body: `We need to expand our search to check your ${formData?.userType === UserType.COSIGNER ? "borrower's" : ""} eligibility with additional lenders who use specialized eligibility criteria.`
            },
            {
                title: "Why is additional information needed?",
                body:  `Some of the lenders on ${getPossesiveCase(companyName)} platform use academic performance as a key input in assessing your ${formData?.userType === UserType.COSIGNER ? "borrower's" : ""} eligibility. This information is required to check your ${formData?.userType === UserType.COSIGNER ? "borrower's" : ""} eligibility with these lenders.`
            }, 
            {
                title: `${formData?.userType === UserType.COSIGNER ? "Does my borrower need an official transcript?" : "Do I need an official transcript?"}`,
                body: `Not right now. Lenders will verify your ${formData?.userType === UserType.COSIGNER ? "borrower's" : ""} academic history and transcript as part of the loan application process.`
            }
        ],
        [userInclusionSelectUrl] : [
            {
                title: firstTitle,
                body: `${isSelfIncludedCosigner ? "You must include your borrower by either inviting them or entering their information directly in order to receive pre-qualified rates for your cosigned request." :
                "A cosigner can be any creditworthy individual with a FICO score that is at least around 700 and is a U.S. Citizen or Permanent Resident with a SSN."}`
            },
            {
                title: `How does signing up my ${includedUser} work?`,
                body: `If you have your ${includedUser}'s permission, the fastest way to include them is to enter their information yourself. If that doesn't work, you can invite your ${includedUser} to fill out their portion of the form independently.`
            },
            {
                title: `What happens after I invite my ${includedUser}?`,
                body: "We'll send an invitation to the email address that you provide and you can track the status of the invite from your dashboard."
            },
            {
                title: `${isSelfIncludedCosigner ? "Do I have to include a borrower?" : "Can I change my cosigner?"}`,
                body: `${isSelfIncludedCosigner ? `Yes. In order to pre-qualify for a cosigned request on ${companyName} you must include a primary borrower.` :
                 "Yes. You can always remove your existing cosigner and add a new cosigner later."}`
            },
        ],
            [userInclusionInformationUrl] : [
                {
                    title: firstTitle,
                    body: `We need your ${includedUser}'s contact information to verify their identity and secure their information.`
                },
                {
                    title: `What name should I use for my ${includedUser}?`,
                    body: `Use your ${includedUser}'s full legal first and last names as they appear on a government issued ID.`
                },
                {
                    title: `Why do you need my ${includedUser} email?`,
                    body: `We use your ${includedUser}'s email address to link your request to their account and to secure their personal information. If they have not created an account yet, they will be able to see this request once they have done so.`
                },
                {
                    title: `Will my ${includedUser} be able to see our pre-qualified rates?`,
                    body: `Yes. Your ${includedUser} can access your pre-qualified rates when they log in to their ${isMarketplaceEnv() ? "Sparrow" : "student loan marketplace"} account using the email address you enter.`
                }
            ],
            [userInclusionReviewInviteUrl] : [ 
                {
                title: firstTitle,
                body: `Joining your ${includedUser} on ${companyName} and checking your pre-qualified rates is free and doesn't impact your credit score.`
              },
               {
                title: "What happens if I continue?",
                body: `If everything looks good and you'd like to continue, you'll need to answer a few questions about yourself. It shouldn't take more than a few minutes.`
              },
              {
               title: "What happens if I decline",
               body: `If something looks off and you need to decline your ${includedUser}'s invite we will let them know and no further action is required from you.`
             }
        ],
        [userInclusionManagementUrl]: userInclusionManagementTips(firstTitle, formData?.includedUser?.status as IncludedUserStatus, includedUser),
    };

    return tipsObject[pathName];
};

const userInclusionManagementTips = (
    firstTitle: string,
    status: IncludedUserStatus,
    includedUser: UserType,
) => {

    switch (status) {
        case IncludedUserStatus.DIRECT_STARTED:
            return [
                {
                    title: firstTitle,
                    body: `You need to finish including your ${includedUser}'s information in order to receive pre-qualified rates for your cosigned request.`,
                },
                {
                    title: `How does signing up a ${includedUser} work?`,
                    body: `If you have your ${includedUser}'s permission, the fastest way to include them is to enter their information yourself. If that doesn't work, you can invite your ${includedUser} to fill out their portion of the form independently.`,
                },
                {
                    title: `What happens after I sign up my ${includedUser}?`,
                    body: `As soon as you’ve finished including your ${includedUser}’s information, you will be able to submit your cosigned request for pre-qualified rates. In minutes, you and your ${includedUser} will be able to see any rates you pre-qualify for.`,
                },
            ];
        case IncludedUserStatus.INVITE_PENDING:
            return [
                {
                    title: firstTitle,
                    body: `Your ${includedUser} needs to include their information in order to receive pre-qualified rates for your cosigned request.`,
                },
                {
                    title: `How does signing up a ${includedUser} work?`,
                    body: `If you have your ${includedUser}'s permission, the fastest way to include them is to enter their information yourself. If that doesn't work, you can invite your ${includedUser} to fill out their portion of the form independently.`,
                },
                {
                    title: `What happens after I invite my ${includedUser}?`,
                    body: `We’ll send an invitation to the email address that you provide and you can track the status of the invite from your dashboard.`,
                },
            ];
        case IncludedUserStatus.INVITE_STARTED:
            return [
                {
                    title: firstTitle,
                    body: `Your ${includedUser} needs to include their information in order to receive pre-qualified rates for your cosigned request.`,
                },
                {
                    title: `How does signing up a ${includedUser} work?`,
                    body: `If you have your ${includedUser}'s permission, the fastest way to include them is to enter their information yourself. If that doesn't work, you can invite your ${includedUser} to fill out their portion of the form independently.`,
                },
                {
                    title: `What happens after my ${includedUser} accepts my invite?`,
                    body: `Your ${includedUser} needs to include their information which should take a couple of minutes. We will let you know as soon as they’re done.`,
                },
            ];
        case IncludedUserStatus.INVITE_REJECTED:
            return [
                {
                    title: firstTitle,
                    body: `Your ${includedUser} needs to include their information in order to receive pre-qualified rates for your cosigned request.`,
                },
                {
                    title: `What does a declined invitation mean?`,
                    body: `For some reason, the ${includedUser} you invited declined to submit their information. However, this has no impact on your ability to pre-qualify and you can still invite or sign up a different ${includedUser}.`,
                },
                {
                    title: `What happens after I delete my ${includedUser}?`,
                    body: `You’ll be redirected to include a new ${includedUser}.`,
                },
            ];
        case IncludedUserStatus.CONFIRMED:
            return [
                {
                    title: firstTitle,
                    body: `Your cosigned pre-qualification request is complete. Changing your ${includedUser} information will delete any existing pre-qualified rates for your cosigned request.`,
                },
                {
                    title: `Can I change my ${includedUser}?`,
                    body: `Yes. However, changing your ${includedUser}’s information will delete any pre-qualified rates you received for this request. Instead, consider starting a new pre-qualification request.`,
                },
                {
                    title: `Can my ${includedUser} see the status of our pre-qualification request?`,
                    body: `Yes. Your cosigner has access to any pre-qualified rates you received and can compare rates and apply with a lender through their own dashboard.`,
                },
            ];
        default:
            return [];
    }
};