import { UserType } from '../../../../../common/constants/defaultInputs';

const SliderStyles = ({ userType }: { userType: UserType }) => {
    const sliderColor = userType === UserType.BORROWER ? "rgba(var(--borrowerAccentColor),1)" : "rgba(var(--cosignerAccentColor),1)";
    const railColor = userType === UserType.BORROWER ? "rgba(var(--borrowerAccentColor),0.2)" : "rgba(var(--cosignerAccentColor),0.2)";

    return (
        <style>
            {`
                .rc-slider-handle { border-color: ${sliderColor} !important; }
                .rc-slider-track, .rc-slider-rail { background-color: ${sliderColor}; }
                .rc-slider-step { background-color: ${railColor}; border-radius: 5px; }
            `}
        </style>
    );
};

export default SliderStyles;