import { observer } from "mobx-react";
import { Route, Routes } from "react-router-dom";
import "../assets/styles/global.scss";
import LoginWrapper from "./login/wrapper";
import {
    borrowerBase,
    completeSignUpBase,
    confirmEmailBase,
    confirmEmailLoginBase,
    confirmEmailResetBase,
    confirmPasswordBase,
    loginUrlBase,
    setPreferencesUrl,
    prequalificationBase,
    resetPasswordBase,
    selectUserTypeBase,
    signUpUrlBase,
    ssoBase,
    userInclusionBase,
    membershipElectionUrl,
} from "../common/core/routes/routing-urls";
import {
    dashboardUrl,
    loginStepBorrower,
    paaasLandingUrl,
} from "../common/core/routes/navigation-urls";
import ConfirmResetWrapper from "./confirm-reset/wrapper";
import ResetPasswordWrapper from "./reset-password/wrapper";
import CompleteSignUpWrapper from "./complete-signup/wrapper";
import ConfirmLoginTemplate from "./confirm-login/wrapper";
import ConfirmEmailTemplate from "./confirm-email/wrapper";
import ConfirmPasswordTemplate from "./confirm-password/wrapper";
import { SSOInterceptorPage } from "./sso-auth/page";
import SignUpWrapper from "./sign-up/wrapper";
import { PrequalificationWrapper } from "./prequalification/wrapper";
import { Dashboard } from "./dashboard/page";
import { BorrowerRoutes } from "./borrower/routes";
import { UserInclusionRoutes } from "./user-inclusion/routes";
import { SelectUserType } from "./select-user-type/page";
import { SetPreferences } from "./set-preferences/page";
import { PaaasLandingPage } from "./paaas-landing/page";
import { loginStepType } from "../common/core/routes/navigation-urls";
import { Navigate } from "react-router-dom";
import { isMarketplaceEnv } from "../common/utils/config";
import { PAAASMembershipElection } from "./paaas-membership-election/page";
import { useOnboarding } from "../common/core/providers/onboarding.provider";
import { useCallback, useMemo } from "react";

export const GlobalRoutes = observer(() => {
    const { paaasCustomization } = useOnboarding();

    const displayLandingPageView = useMemo(
        () => paaasCustomization?.toggleableViews?.displayLandingPageView,
        [paaasCustomization]
    );
    
    // Get the root URL for the site based on the environment and the PAAAS customization settings
    const getBaseUrl = useCallback(() => {
        if (isMarketplaceEnv()) {
            return loginStepType;
        }

        return paaasCustomization?.toggleableViews?.displayLandingPageView
            ? paaasLandingUrl
            : loginStepBorrower;
    }, [paaasCustomization]);

    // On initial render, if this is a PAAAS site and we don't yet have the paaasCustomization object, don't render anything
    if (!paaasCustomization && !isMarketplaceEnv()) {
        return <></>;
    }

    return (
        <Routes>
            <Route path={borrowerBase + "/*"} element={<BorrowerRoutes />} />
            <Route path={dashboardUrl} element={<Dashboard />} />
            {displayLandingPageView && (
                <Route path={paaasLandingUrl} element={<PaaasLandingPage />} />
            )}
            <Route path={completeSignUpBase} element={<CompleteSignUpWrapper />} />
            <Route path={confirmEmailBase} element={<ConfirmEmailTemplate />} />
            <Route path={confirmEmailLoginBase} element={<ConfirmLoginTemplate />} />
            <Route path={confirmPasswordBase} element={<ConfirmPasswordTemplate />} />
            <Route path={confirmEmailResetBase} element={<ConfirmResetWrapper />} />
            <Route path={loginUrlBase + "/*"} element={<LoginWrapper />} />
            <Route path={setPreferencesUrl} element={<SetPreferences />} />
            <Route path={membershipElectionUrl} element={<PAAASMembershipElection />} />
            <Route path={prequalificationBase + "/*"} element={<PrequalificationWrapper />} />
            <Route path={resetPasswordBase} element={<ResetPasswordWrapper />} />
            <Route path={selectUserTypeBase} element={<SelectUserType />} />
            <Route path={signUpUrlBase + "/*"} element={<SignUpWrapper />} />
            <Route path={ssoBase} element={<SSOInterceptorPage />} />
            <Route path={userInclusionBase + "/*"} element={<UserInclusionRoutes />} />
            <Route path="/" element={<Navigate to={getBaseUrl()} />} />
        </Routes>
    );
});
