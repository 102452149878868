import { useStore } from "../../../core/providers/global.provider";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import { useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { observer } from "mobx-react";

import { borrowerProfileUrl } from "../../../core/routes/navigation-urls";
import { isMarketplaceEnv } from "../../../utils/config";
import { AdvertiserDisclosureButton } from "../../PAAASHeaderBar/AdvertiserDisclosure";

import "./styles.scss";
import supportIcon from "../../../../assets/images/icons/supportIcon.svg";
import { HeaderButton } from "../../PAAASHeaderBar/HeaderButton";
import { logoutUser } from "../../../core/logout";

interface IFooterBox {
    userName?: {
        firstName: string;
        initials: string;
        name: string;
    };
    setShowSideBarHandlerForMobile: Function;
    setMenuExpandDropdown: Function;
    setMenuExpand: Function;
}

export const FooterBox = observer(
    ({
        userName,
        setShowSideBarHandlerForMobile,
        setMenuExpandDropdown,
        setMenuExpand,
    }: IFooterBox) => {
        const store = useStore();
        const onboarding = useOnboarding();
        const navigate = useNavigate();
        const { show } = useIntercom();

        const logOut = () => logoutUser({ store, onboarding, navigate });

        function goToAccountSettings() {
            navigate(borrowerProfileUrl);
            if (setShowSideBarHandlerForMobile) {
                setShowSideBarHandlerForMobile(false);
            }
            setMenuExpandDropdown(undefined);
            setMenuExpand(false);
        }

        return (
            <div className="footer-box-container">
                {!isMarketplaceEnv() && onboarding.paaasCustomHeader?.button?.isEnableCustomButton && (
                    <div className={"footer-box-custom-button"}>
                        <HeaderButton button={onboarding.paaasCustomHeader?.button} />
                    </div>
                )}

                {!isMarketplaceEnv() && !onboarding.paaasCustomHeader?.disableAdvertiserDisclosure && (
                    <div className="footer-advertiser-disclosure">
                        <AdvertiserDisclosureButton isLeftAligned={true} />
                    </div>
                )}
                <button className="account-user-container" onClick={() => goToAccountSettings()}>
                    <div className="tooltip-account">Go to account settings</div>
                    <div className="initials">{userName?.initials}</div>
                    <span>
                        <span className="muted">Account</span>
                        <span className={"name"}>{userName?.name}</span>
                    </span>
                </button>
                <button className="customer-support" onClick={() => show!()}>
                    <img alt="support" src={supportIcon} />
                    <span>Customer Support</span>
                </button>
                <button className="customer-support customer-support-logout" onClick={logOut}>
                    <span className="icon-logout" />
                    <span>Log out</span>
                </button>
            </div>
        );
    }
);
