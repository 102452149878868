import { useState } from "react";
import cosignerEdit from "../../../assets/images/icons/cosignerEdit.svg";

import { useOnboarding } from "../../core/providers/onboarding.provider";
import { OfferHeaderInputCard } from "./OfferHeaderInputCard";
import { OfferHeaderTabs } from "./OfferHeaderUtilities";
import { PqDecisionsTextHeader } from "./PqDecisionsTextHeader";
import "./style.scss";
import { UserType } from "../../constants/defaultInputs";
import { DollarSignIcon, MagnifyingGlassIcon, AddCosignerIcon } from "../../../sparrow-ui-library/src/assets/icons/pq-decisions-icons/header-icons";
import { GradHatIcon } from "../../../sparrow-ui-library/src/assets/icons/request-history-icons";


export const OfferHeader = () => {
    const onboarding = useOnboarding();

    function canSeeIncludedUserCard() {
        const hasIncludedUser = !!onboarding.formData.includedUser;
        const hasExploredOffer = onboarding.requestHasExploredRate;
        return !hasExploredOffer || (hasExploredOffer && hasIncludedUser);
    }

    const isBorrowerInitiated = onboarding.formData.userType === UserType.BORROWER;

    // None active to begin with
    const [activeId, setActiveId] = useState(-1);

     // If active user has NoSSN we do not ask for income in prequal, therefore, we should not show the annual income card
     const displayIncomeCard = () => {
        if (onboarding.formData.userType === UserType.COSIGNER) {
            return !!onboarding.formData.cosigner?.income;
        }

        return !!onboarding.formData.income;
    };

    const getIncomeAmount = () => {
        if (onboarding.formData.userType === UserType.COSIGNER) {
            return onboarding.formData.cosigner?.income.amount.display?.split(".")[0] as string;
        }

        return onboarding.formData.income.amount.display?.split(".")[0];
    };

    return (
        <>
            <div className="header-container">
                <PqDecisionsTextHeader />
                <div className="header-body-container">
                    <OfferHeaderInputCard
                        title="Loan Size"
                        dropdownTitle="Loan Size"
                        initialValue={onboarding.formData.loanAmount.display.split(".")[0]}
                        icon={()=><MagnifyingGlassIcon userType={onboarding.formData.userType as UserType} />}
                        tab={OfferHeaderTabs.LoanSize}
                        id={1}
                        setActiveId={setActiveId}
                        activeId={activeId}
                    />
                    {displayIncomeCard() && <OfferHeaderInputCard
                        title="Annual Income"
                        dropdownTitle="Your annual pre-tax income"
                        initialValue={getIncomeAmount() as string}
                        icon={() => <DollarSignIcon userType={onboarding.formData.userType as UserType} />}
                        tab={OfferHeaderTabs.Income}
                        id={2}
                        setActiveId={setActiveId}
                        activeId={activeId}
                    />}
                    {isBorrowerInitiated && (
                        <OfferHeaderInputCard
                            title="Education"
                            dropdownTitle="Your school"
                            initialValue={onboarding.formData.degrees[0].university}
                            icon={() => <GradHatIcon size={20} userType={onboarding.formData.userType as UserType}/>}
                            tab={OfferHeaderTabs.School}
                            id={3}
                            setActiveId={setActiveId}
                            activeId={activeId}
                        />
                    )}

                    {canSeeIncludedUserCard() && (
                        <OfferHeaderInputCard
                            title="Cosigner"
                            dropdownTitle="Your cosigner"
                            initialValue="Add cosigner"
                            icon={() => <AddCosignerIcon userType={onboarding.formData.userType as UserType} />}
                            dropIcon={cosignerEdit}
                            tab={OfferHeaderTabs.Cosigner}
                            id={4}
                            setActiveId={setActiveId}
                            activeId={activeId}
                        />
                    )}
                </div>
            </div>
        </>
    );
};
