import { ReviewDisclaimer } from "./ReviewDisclaimer";
import { ToggleSwitch } from "../../../../../sparrow-ui-library/src/ui-components/buttons";
import { FormFieldName, FormFieldType } from "../../../../../sparrow-ui-library/src/types/form-types";
import { useOnboarding } from "../../../../../common/core/providers/onboarding.provider";
import { UserType } from "../../../../../sparrow-ui-library/src/types/sparrow-types";
import styles from "./review-content.module.scss";

export const ReviewContent = ({ toggle, setToggle }) => {
    const { formData, isRequestSubmission } = useOnboarding();

    return (
        <>
            <div style={{ marginTop: "16px" }} />
            {isRequestSubmission() &&
                <>
                    <ReviewDisclaimer />
                    <div className={styles.toggleContainer}>
                        <span className={styles.font}>I have read and agree to the disclosure above.</span>
                        <ToggleSwitch
                            id="toggle"
                            name={FormFieldName.GenericToggle}
                            formFieldType={FormFieldType.ToggleSwitch}
                            inputValue={toggle}
                            setInputValue={() => setToggle(!toggle)}
                            userType={formData.userType as UserType}
                        />
                    </div>
                </>}
        </>
    );
};
