import React from "react";
import { UserType } from "../../../constants/defaultInputs";
import { Colors } from "../../../../assets/styles/inline-styles";
import "./styles.scss";
import { isMarketplaceEnv } from "../../../utils/config";

interface IButton {
    id?: string;
    type?: string;
    padding?: string;
    width?: string;
    borderWidth?: string;
    float?: "right" | "left";
    style?: React.CSSProperties;
    buttonType: ButtonTypes;
    userType?: UserType | null;
    disabled?: boolean;
    tabIndex?: number;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    onKeyPress?: (e: React.KeyboardEvent<HTMLButtonElement>) => void;
    showIcon?: boolean;
    children?: React.ReactNode;
}

interface IActiveStyles {
    width?: string;
    padding?: string;
    float?: "right" | "left" | "none";
    background?: string;
    border?: string;
    disabled?: string;
    color?: string;
}

export enum ButtonTypes {
    PRIMARY = "primary",
    SECONDARY = "secondary",
}

const getColors = (props: IButton): IActiveStyles => {
    const userTypeColor =
        props.userType === UserType.COSIGNER ? Colors.PRIMARY_COSIGNER : Colors.PRIMARY_GREEN;
    //if userType is null, then we use the green color
    if (props.buttonType === ButtonTypes.PRIMARY) {
        return {
            background: userTypeColor,
            border: "none",
            color: "#fff",
        };
    } else {
        return {
            background: "none",
            border: `${props.borderWidth ?? "1px"} solid ${userTypeColor}`,
            color: userTypeColor,
        };
    }
};

export const Button = (props: IButton) => {
    const activeStyles: IActiveStyles = {};
    const { background, border, color } = getColors(props);

    activeStyles.width = props.width ? `${props.width}` : "fit-content";
    activeStyles.padding = props.padding || "12px 24px";
    activeStyles.disabled = props.disabled ? "true" : "false";
    activeStyles.float = props.float || "right";
    if(isMarketplaceEnv()){
        activeStyles.background = background;
        activeStyles.border = border;
        activeStyles.color = color;
    }

    return (
        <button
            id={props.id}
            className={`oval-button ${!isMarketplaceEnv() ? `paaas ${props.userType ?? ""} ${props.buttonType}` : ""}`}
            style={{...activeStyles , ...props.style}}
            onKeyPress={props.onKeyPress}
            tabIndex={props.tabIndex}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.children}
            {props.showIcon && <span className="icon-arrow-right" />}
        </button>
    );
};
