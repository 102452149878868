import { AuthFlowLayout } from "../../common/components/auth-component/authFlowLayout";
import { ConfirmLoginPage } from "./page";

export default function ConfirmLoginTemplate() {
    return (
        <AuthFlowLayout>
            <ConfirmLoginPage />
        </AuthFlowLayout>
    );
}
