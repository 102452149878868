import logoImg from "../../../assets/images/icons/borrowerBird.svg";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { useStore } from "../../core/providers/global.provider";
import { observer } from "mobx-react";
import { utcToFormattedDateString } from "../../utils/helper_functions/date-functions";
import { Button, ButtonTypes } from "../buttons/button/Button";
import { UserType } from "../../constants/defaultInputs";
import removeDecimals from "../../utils/removeDecimals";
import { getLatestIncompleteStep, getStepRouting } from "../../core/global_routing/route-steps";
import {
    PostClonePrequalificationRequest,
    PostPutPrequalificationRequest,
    GetPrequalificationRequest,
} from "../../core/services/sparrow-api-handlers/prequalification-api-handlers";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import React from "react";
import { PrequalificationRequestInput } from "../../../sparrow-ui-library/src/types/sparrow-types";
import { isMarketplaceEnv } from "../../utils/config";
import { ConfirmIcon } from "../../../sparrow-ui-library/src/assets/icons/confirm-icon/ConfirmIcon";

/**
 * Modal that allows user to auto-fill a prequalification request with their previously
 * existing information, if it exists. If auto-filling request, copy user information
 * into new request and navigate to next step (should be 'Review'). Otherwise, continue
 * with form normally.
 */
export const AutofillRequestModal = observer(
    (props: { loanRequestToClone: PrequalificationRequestInput; loanAmount: number }) => {
        const { loanRequestToClone, loanAmount } = props;

        const { saveFormInStoreAndStorage } = useOnboarding();
        const onboarding = useOnboarding();
        const store = useStore();
        const navigate = useNavigate();
        const pathName = useLocation().pathname;

        // Handles user selects to auto-fill their new request using existing data
        const onAutofill = async () => {
            store.setLoading(true);
            try {
                // Create and write copy of existing request with new amount
                const { id } = await PostClonePrequalificationRequest({
                    loanAmount: loanAmount,
                    requestId: loanRequestToClone.id as string,
                    token: onboarding.jwt as string,
                });

                // Get new request with updated information
                const newRequest = await GetPrequalificationRequest({
                    token: onboarding.jwt as string,
                    requestId: id as string,
                });

                // Once cloned, update form data with new request and navigate to next step
                saveFormInStoreAndStorage(newRequest);
                navigate(getLatestIncompleteStep(onboarding));
            } catch (err) {
                console.error(err);
                store.setSnackError("Something went wrong. Please try again later.", "error");
            }

            store.setModal(null);
            store.setLoading(false);
        };

        // Handles user selects to fill out new request without auto-filling
        const onStartFresh = async () => {
            try {
                store.setLoading(true);

                // Write request at current state without auto-filling
                const PrequalificationRequestGetOutput = await PostPutPrequalificationRequest({
                    userType: onboarding.userType as UserType,
                    prequalificationRequestPostPutInput: {
                        loanAmount: {
                            amount: loanAmount,
                            currency: "USD",
                        },
                        loanSelectionType: onboarding.formData.loanSelectionType,
                        type: onboarding.formData.type,
                    },
                    token: onboarding.jwt as string,
                    requestType: "POST",
                });

                if (PrequalificationRequestGetOutput) {
                    onboarding.saveFormInStoreAndStorage(PrequalificationRequestGetOutput);
                }

                // Once written, navigate to next step
                navigate(getStepRouting(pathName, onboarding).nextStep);
            } catch (err) {
                console.error(err);
                store.setSnackError("Something went wrong. Please try again later.", "error");
            }

            store.setModal(null);
            store.setLoading(false);
        };

        const getLastRequestText = () => {
            if (loanRequestToClone.createdAt) {
                return (
                    "Your last " +
                    loanRequestToClone.loanSelectionType +
                    " request was made on " +
                    utcToFormattedDateString(loanRequestToClone.createdAt) +
                    " for " +
                    removeDecimals(loanRequestToClone.loanAmount.display)
                );
            }
            return "";
        };

        return (
            <div className="modal-content">
                <button className="close-modal" onClick={store.modalResetData}>
                    <span className={"icon-close"} />
                </button>
                {isMarketplaceEnv() ? <img src={logoImg} alt="" /> : <ConfirmIcon userType={onboarding.formData.userType as UserType}/>}
                <div className="h3">{`Autofill with your previous information?`}</div>
                <h4>{getLastRequestText()}</h4>
                <p>
                    If you'd like to use your existing information for your new request, click
                    “Autofill” below. You will be directed to the last step of the form, where you
                    can edit your information if needed.
                </p>
                <div
                    className="button-group"
                    style={{ display: "flex", justifyContent: "center", gap: "1vw" }}
                >
                    <Button
                        onClick={() => {
                            onStartFresh();
                        }}
                        buttonType={ButtonTypes.SECONDARY}
                        userType={onboarding.formData.userType as UserType}
                    >
                        Start fresh
                    </Button>
                    <Button
                        buttonType={ButtonTypes.PRIMARY}
                        userType={onboarding.formData.userType as UserType}
                        onClick={() => {
                            onAutofill();
                        }}
                    >
                        Autofill
                    </Button>
                </div>
            </div>
        );
    }
);
