import { GradHatIcon, StarIcon } from "../../../../../sparrow-ui-library/src/assets/icons/request-history-icons";
import styles from "../styles.module.scss";

export const LoanRequestRow = ({icon, user, loanAmount, date}) => {
    return (
        <div className={"history-body-card"}>
            <div className={styles["flex-gap-8"]}>
                {user === "Borrower" ? <GradHatIcon size={16}/> : <StarIcon />}
                <div className="history-header-user">{user}</div>
            </div>
            <div className={styles["flex-gap-8"]}>
                <div className="history-header-loan-amount">{loanAmount}</div>
                <div className="p-md-14-regular">{date}</div>
            </div>
        </div>
    )
}
