export const basePrefixNew = process.env.REACT_APP_API_BASE_PREFIX ?? "v2/lmd/";
export const base = process.env.REACT_APP_API_URL + basePrefixNew;
export const baseBlog = "https://wordpress.sparrowfi.com/";
export const webSocketUrl = process.env.REACT_APP_OFFERS_WEB_SOCKET_URL;
// rates endpoints
export const offersPin = base + "users/offers/pin";
export const offerBookmark = base + "users/offers/bookmark";
export const offersExploreEndpoint = base + "users/offers/explore";
export const offerRateRangeExploreEndpoint = base + "users/offers/explore/rate-range";

export const SparrowApi_GET_ExpandedSearchMetadata = base + "public/expanded-search/metadata";

export const SparrowApi_PUT_RequestDecline = base + "users/request/decline";

export const SparrowApi_POST_PUT_Request = base + "users/request";
export const SparrowApi_POST_SubmitRequest = base + "users/request/submit";
export const SparrowApi_POST_AcceptInvite = base + "users/request/invite/accept";
export const SparrowApi_PUT_RequestExpandedSearch = base + "users/request/expanded-search/fields";
export const SparrowApi_POST_RequestExpandedSearchSubmit =
    base + "users/request/expanded-search/submit";
export const SparrowApi_POST_RequestExpandedSearchSeen =
    base + "users/request/expanded-search/seen";
export const SparrowApi_PUT_InviteRequestAdd = base + "users/request/invite/add";
export const SparrowApi_POST_InviteRequestDelete = base + "users/request/invite/delete";
export const SparrowApi_PUT_InviteRequestRemind = base + "users/request/invite/remind";
export const SparrowApi_POST_CloneRequest = base + "users/request/clone";

export const SparrowApi_PUT_UpdateUserAttributes = base + "users/attributes";

// get request(s)
export const SparrowApi_GET_SingleRequest = base + "users/request";
export const SparrowApi_GET_RequestResults = base + "users/request/results";
export const SparrowApi_GET_UserProgressRoadmap = base + "users/progress";
export const SparrowApi_PUT_QuizAnswer = base + "users/request/quiz";
export const SparrowApi_GET_LenderLinks = base + "users/request/lenders/links";
export const SparrowApi_GET_CurrentUser = base + "users/current-user";

export const utmUpdatesEndpoint = base + "users/utms";
export const userMissingFields = base + "users/sign-in/missing-fields";
export const offersTooltips = baseBlog + "wp-json/wp/v2/tooltips/";
export const wordpressRateTable = baseBlog + "wp-json/wp/v2/table/";
export const wordpressLender = baseBlog + "wp-json/wp/v2/lenders/";
export const wordpressPaaasLandingFaq = baseBlog + "wp-json/wp/v2/pages/8005/";
export const wordpressDisclosure = baseBlog + "wp-json/acf/v3/options/options";

export const offerExplore = base + "users/offers/explore";
export const offerRateRangeExplore = base + "users/offers/explore/rate-range";

export const major = base + "users/public/majors";
export const degrees = base + "users/public/degrees";
export const visaTypesLink = base + "users/public/visa-types";
export const school = base + "users/public/schools";

export const hypotheticalCosigner = base + "users/request/cosigner/hypothetical-savings";
export const savedCosigner = base + "users/request/cosigner/savings";

export const prequalRequestPin = base + "users/prequal-request/pin-offer";

export type UserMissingFieldsType = {
    isMissingFields: boolean;
    firstName: string;
    lastName: string;
    phoneNumber: string;
};
