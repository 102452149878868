import { FC } from "react";
import { UserType } from "../../../types/sparrow-types";
import { getSvgColors } from "../getSvgColors";

export const PaymentIcon: FC<{ userType?: UserType }> = ({ userType = UserType.BORROWER }) => {
    const { primaryColor, accentColor } = getSvgColors(userType);

    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="10"
                y="25"
                width="22"
                height="17"
                rx="2"
                transform="rotate(-90 10 25)"
                fill={accentColor}
            />
            <rect x="6" y="15" width="29" height="22" rx="4" fill={primaryColor} />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.99997 23C9.99997 24.1046 10.8954 25 12 25H25C26.1045 25 27 24.1046 27 23V15H9.99997V23Z"
                fill={accentColor}
            />
            <path
                d="M29 26C29 24.8954 29.8954 24 31 24H35V28H31C29.8954 28 29 27.1046 29 26Z"
                fill={accentColor}
            />
        </svg>
    );
};
