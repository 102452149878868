import { Button } from "../../buttons/button/Button";
import { ButtonTypes } from "../../buttons/button/Button";
import { useStore } from "../../../core/providers/global.provider";
import { useOnboarding } from "../../../core/providers/onboarding.provider";

import { ModalWrapper } from "../modal-wrapper";
import { errorLogs } from "../../../utils/global";
import { useNavigate } from "react-router-dom";
import { sentenceCase } from "../../../utils/global";
import borrowerBirdIcon from "../../../../assets/images/icons/icon-40-about-us-bird-message-green.png";
import cosignerBirdIcon from "../../../../assets/images/icons/icon-40-parent-about-us-bird-message-orange.png";
import { dashboardUrl } from "../../../core/routes/navigation-urls";
import { PostPrequalificationRequestDecline } from "../../../core/services/sparrow-api-handlers/prequalification-api-handlers";
import { observer } from "mobx-react";
import { ButtonWrapper } from "../../buttons/button/ButtonWrapper";

/*
Description: DeclineInvitationModal is a modal that appears when a user clicks on the "Decline" button on the "Review Request" page 
when a user is invited to be added to a loan request.

Latest Figma Designs:
Decline of invitation modal: https://www.figma.com/file/Vwc8KmfCQ31VS28jOwBwYM/2.1.Sparrow.Borrower.Portal.NEW.Flows?node-id=2907-400960&t=SoFOpWR7xFCtqtgs-0
As of: April 25, 2023
*/

export const DeclineInvitationModal = observer(({ firstName }: { firstName: string }) => {
    const store = useStore();
    const onboarding = useOnboarding();
    const navigate = useNavigate();

    const headerCopy = {
        title: "Are you sure you want to decline?",
        subtitle: `Please confirm that you want to decline the invitation to join your pre-qualification request sent to ${sentenceCase(
            firstName
        )}. You will be redirected to include a new user.`,
        icon: onboarding.formData.userType === "borrower" ? borrowerBirdIcon : cosignerBirdIcon,
    };

    const handleDecline = async () => {
        try {
            store.setLoading(true);
            await PostPrequalificationRequestDecline(
                onboarding.formData.id as string,
                onboarding.jwt as string
            );
            navigate(dashboardUrl);
            store.modalResetData();
        } catch (err) {
            errorLogs("PostRequestReject", err);
            store.setSnackError("Something went wrong. Please try again later.", "error");
        }
        store.setLoading(false);
    };

    return (
        <ModalWrapper>
            <img src={headerCopy.icon} alt="delete-icon" width={40} height={40} />
            <h3
                style={{
                    fontSize: "24px",
                    lineHeight: "32px",
                    fontWeight: "600",
                    marginBottom: "12px",
                    overflowWrap: "anywhere",
                }}
            >
                {headerCopy.title}
            </h3>
            <p
                style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "400",
                    marginBottom: "24px",
                    color: "#878F99",
                    overflowWrap: "anywhere",
                }}
            >
                {headerCopy.subtitle}
            </p>
            <ButtonWrapper centerButtons={true}>
                <Button
                    id="delete-button"
                    onClick={store.modalResetData}
                    buttonType={ButtonTypes.SECONDARY}
                    userType={onboarding.formData.userType}
                >
                    Cancel
                </Button>
                <Button
                    id="cancel-button"
                    tabIndex={0}
                    onClick={handleDecline}
                    buttonType={ButtonTypes.PRIMARY}
                    userType={onboarding.formData.userType}
                >
                    Decline
                </Button>
            </ButtonWrapper>
        </ModalWrapper>
    );
});
