import { dateToEpoch } from "../../../../sparrow-ui-library/src/utils/ui-library-utils/get-function-for-backend-value-format/input-format-for-backend-functions";
import { ExpandedSearchDates } from "../../../../sparrow-ui-library/src/types/sparrow-types";
import { getYearFromDate } from "../../../../common/utils/helper_functions/date-functions";
export const getPreviousSemesterAsString = (): string => {
    // We add one since getMonth() has zero-based index for the months
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();
    const lastYear = currentYear - 1;

    if (currentMonth < 6) {
        return `Fall ${lastYear}`;
    } else {
        return `Spring ${currentYear}`;
    }
};

const getExpandedSearchDates = (expandedSearchDates: ExpandedSearchDates) => {
    const currentYear = new Date().getFullYear();
    
    const esDates = {
        earliestStartDate : expandedSearchDates.startMin ?? dateToEpoch(`08/01/${currentYear}`),
        latestStartDate :expandedSearchDates.startMax ?? dateToEpoch(`03/01/${currentYear + 1}`),
        earliestEndDate: expandedSearchDates.endMin ?? dateToEpoch(`03/01/${currentYear + 1}`),
        latestEndDate: expandedSearchDates.endMax ?? dateToEpoch(`07/01/${currentYear + 1}`),
    }

    sessionStorage.setItem("esDates", JSON.stringify(esDates));

    return esDates;
};

// Returns Labels object for expanded search fields
export const getExpandedSearchFieldLabels = (expandedSearchDates: ExpandedSearchDates) => {
    const previousSemester = getPreviousSemesterAsString();
    const { earliestStartDate, latestEndDate } = getExpandedSearchDates(expandedSearchDates);
    const startYear = getYearFromDate(earliestStartDate);
    const endYear = getYearFromDate(latestEndDate);

    return {
        hoursCompletedCurrentSemester: `Hours completed ${previousSemester} semester`,
        gpaLastSemester: `GPA ${previousSemester} semester`,
        schoolYearStartDate: `${startYear}-${endYear} academic year start date`,
        schoolYearEndDate: `${startYear}-${endYear} academic year end date`,
    };
};