import { cookieDomain } from "../../../core/routes/routing-urls";
import { isDevEnvironment, isMarketplaceEnv } from "../../../utils/config";

const awsConfig = {
    aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
    aws_appsync_authenticationType: process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
    aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_IDENTITY_POOLS_ID,
    aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
    aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
    allowed_oauth_flows_user_pool_client: isMarketplaceEnv(),
};

const oauthConfig = {
    oauth: {
        domain: process.env.REACT_APP_SSO_OAUTH_DOMAIN,
        scope: ["email", "profile", "openid"],
        redirectSignIn: process.env.REACT_APP_SSO_REDIRECT_SIGN_IN,
        redirectSignOut: process.env.REACT_APP_SSO_REDIRECT_SIGN_OUT,
        responseType: "token",
    },
};

export const amplifyConfig = {
    ...awsConfig,
    cookieStorage: {
        domain: cookieDomain,
        secure: !isDevEnvironment(),
    },
    ...(isMarketplaceEnv() ? oauthConfig : {}),
};
