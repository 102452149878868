"use client";
import React, { useState } from "react";
import "./style.scss";
import hintIcon from "../../../assets/images/icons/hint.svg";
import hintDarkIcon from "../../../assets/images/icons/hintDark.svg";

interface ICard {
    title: string;
    content: any;
    dataClassName?: string;
}

export const QuestionTooltip: React.FunctionComponent<ICard> = ({
    title,
    content,
    dataClassName = "",
}) => {
    const renderHTML = (rawHTML: any) =>
        React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
    const [pos, setPos] = useState(-50);

    const [isHover, setIsHover] = useState<boolean>(false);

    // @ts-ignore
    const getPosition = (e) => {
        const trg = e.clientX;
        const pos = trg - 150;
        const finalPos =
            window.innerWidth <= 767
                ? pos > 0
                    ? window.innerWidth - 10 <= trg + 150
                        ? -150 + (window.innerWidth - trg)
                        : 0
                    : 15
                : pos > 0
                ? -50
                : 15;
        setPos(finalPos);
    };

    return (
        <>
            <div
                className="tooltip-wrapper"
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
            >
                <img
                    alt=""
                    src={isHover ? hintDarkIcon : hintIcon}
                    className="tooltip-trigger"
                    onClick={(e) => getPosition(e)}
                />
                <div className="tooltip-content" style={{ left: pos }}>
                    <div className="tooltip-title">{title}</div>
                    <div className={`tooltip-data ${dataClassName}`}>{renderHTML(content)}</div>
                </div>
            </div>
        </>
    );
};
