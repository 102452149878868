import { FC } from "react";
import { UserType } from "../../../types/sparrow-types";
import { getSvgColors } from "../getSvgColors";

export const NoIncomeIcon: FC<{ userType?: UserType }> = ({ userType = UserType.BORROWER }) => {
    const { primaryColor, accentColor } = getSvgColors(userType);

    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.3986 8.56107C18.2783 7.37452 17 4.26392 17 2.99985C17 1.49987 19.9122 2.56473 21 2.99985C23.5 3.99986 23.9042 4.04773 26 2.99985C27.6656 2.16707 29 1.99983 29 2.99985C29 3.89505 27.798 6.79359 26.47 8.46743C30.7357 11.4732 36 18.5088 36 23.9999C36 31.1796 30.1797 34.9999 23 34.9999C15.8203 34.9999 10 31.1796 10 23.9999C10 18.565 15.157 11.6172 19.3986 8.56107Z"
                fill={primaryColor}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M18 29C18 32.3137 15.3137 35 12 35C11.0281 35 10.1101 34.7689 9.29812 34.3587L17.3587 26.2981C17.7689 27.1101 18 28.0281 18 29ZM6.53024 31.4697L14.4697 23.5302C13.7165 23.1896 12.8804 23 12 23C8.68629 23 6 25.6863 6 29C6 29.8804 6.18962 30.7165 6.53024 31.4697ZM21 29C21 33.9706 16.9706 38 12 38C7.02944 38 3 33.9706 3 29C3 24.0294 7.02944 20 12 20C16.9706 20 21 24.0294 21 29Z"
                fill={accentColor}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.059 34.5836C20.2743 33.0492 21 31.1093 21 29C21 24.0294 16.9706 20 12 20C11.5725 20 11.152 20.0298 10.7404 20.0874C10.3541 21.1911 10.102 22.2849 10.025 23.3326C10.6435 23.1171 11.308 23 12 23C12.8804 23 13.7165 23.1896 14.4697 23.5302L10.5183 27.4816C10.9597 28.8612 11.669 30.0577 12.5913 31.0655L17.3587 26.2981C17.7689 27.1101 18 28.0281 18 29C18 30.8024 17.2052 32.4192 15.947 33.5191C16.905 33.9812 17.9498 34.3368 19.059 34.5836Z"
                fill={accentColor}
            />
        </svg>
    );
};
