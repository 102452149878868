import { UserType } from "../../../../types/sparrow-types";

export const MagnifyingGlassIcon = ({userType}: {userType: UserType}) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.5618 3C6.83324 3 3 6.83324 3 11.5618C3 16.2903 6.83324 20.1236 11.5618 20.1236C13.6157 20.1236 15.5008 19.4003 16.9761 18.1946L19.5381 20.7499C19.8733 21.0842 20.416 21.0835 20.7503 20.7483C21.0846 20.4132 21.0839 19.8704 20.7487 19.5361L18.1889 16.983C19.398 15.5066 20.1236 13.6189 20.1236 11.5618C20.1236 6.83324 16.2903 3 11.5618 3ZM4.71432 11.5618C4.71432 7.78003 7.78003 4.71432 11.5618 4.71432C15.3435 4.71432 18.4092 7.78003 18.4092 11.5618C18.4092 15.3435 15.3435 18.4092 11.5618 18.4092C7.78003 18.4092 4.71432 15.3435 4.71432 11.5618Z"
                fill={`rgba(var(--${userType}PrimaryColor), 1)`}
            />
        </svg>
    );
};
