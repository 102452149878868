import { Put, Get } from "../clientApi";
import {
    SparrowApi_GET_RequestResults,
    SparrowApi_PUT_QuizAnswer,
    SparrowApi_GET_LenderLinks,
} from "../sparrow-api-endpoints";
import {
    IQuizPutInput,
    IOffer,
    IIncomeBasedOffer,
    PrequalSubmissionResults,
} from "../types/offers-api-types";

import { OffersTablePreferences } from "../../../../sparrow-ui-library/src/types/sparrow-types";

/**
 * Gets prequalification request results data including offers, validation information, and decline information
 * @param requestId: string
 * @param token: string
 * @returns Promise <IPrequalificationRequestResultGetOutput | null>
 */
export const GetRequestPrequalSubmissionResults = async (
    requestId: string,
    token: string
): Promise<PrequalSubmissionResults | null> => {
    if (!token) return null;

    const PrequalificationRequestResultGetOutput = await Get(
        SparrowApi_GET_RequestResults,
        token,
        requestId
    );
    return PrequalificationRequestResultGetOutput || null;
};

export const GetLenderLinks = async (requestId: string, token: string) => {
    if (!token) return null;

    const PrequalificationRequestLinksGetOutput = await Get(
        SparrowApi_GET_LenderLinks,
        token,
        requestId
    );
    return PrequalificationRequestLinksGetOutput ?? null;
};

export interface IQuizPutOutput {
    offers: IOffer[];
    incomeBasedOffers: IIncomeBasedOffer[];
    offersQuizAnswer: OffersTablePreferences;
}

export const PostQuizAnswer = async (
    requestBody: IQuizPutInput
): Promise<IQuizPutOutput | null> => {
    const { quizAnswerInput, token } = requestBody;
    const quizAnswerPostOutput = await Put(SparrowApi_PUT_QuizAnswer, token, quizAnswerInput);
    return quizAnswerPostOutput || null;
};
