import { FC } from "react";
import { Button } from "../../buttons/button/Button";
import { ButtonTypes } from "../../buttons/button/Button";
import { useStore } from "../../../core/providers/global.provider";
import { useOnboarding } from "../../../core/providers/onboarding.provider";

import { ModalWrapper } from "../modal-wrapper";
import { errorLogs } from "../../../utils/global";
import { useNavigate } from "react-router-dom";
import { sentenceCase } from "../../../utils/global";
import { PostRequestInviteDelete } from "../../../core/services/sparrow-api-handlers/prequalification-api-handlers";
import { userInclusionSelectUrl } from "../../../core/routes/navigation-urls";
import { observer } from "mobx-react";
import { UserType } from "../../../constants/defaultInputs";
import { PrequalUrlsEnum } from "../../../core/global_routing/front-end-urls";
import { ButtonWrapper } from "../../buttons/button/ButtonWrapper";
import { getIncludedUserModalSvg } from "../../../../assets/icons/svg-get-functions/getIncludedUserModalSvg";
import { IncludedUserStatus } from "../../../../sparrow-ui-library/src/types/sparrow-types";


interface IDeleteInvitedUserModal {
    firstName: string;
    email: string;
    status: IncludedUserStatus;
    invitedUserType: string;
    isEditModal?: boolean;
}

export const DeleteEditInvitedUserModal: FC<IDeleteInvitedUserModal> = observer(
    ({ firstName, email, status, invitedUserType, isEditModal = false }) => {
        const store = useStore();
        const onboarding = useOnboarding();
        const navigate = useNavigate();

        const callerUserType =
            invitedUserType === UserType.BORROWER ? UserType.COSIGNER : UserType.BORROWER;

        const getSoloText = () => {
            if (callerUserType === UserType.BORROWER) {
                return "solo borrower";
            }

            return "cosigner";
        };

        const getDeleteModalSubtitle = () => {
            switch (status) {
                case IncludedUserStatus.INVITE_PENDING:
                    return `Please confirm that you want to delete the invitation to join your pre-qualification request sent to ${sentenceCase(
                        firstName
                    )} at ${email}. You will be redirected to include a new ${invitedUserType}.`;
                case IncludedUserStatus.INVITE_STARTED:
                    return `${sentenceCase(
                        firstName
                    )} will no longer be able to access this request and any information they may have included will be deleted. You will be redirected to include a new ${invitedUserType}.`;
                case IncludedUserStatus.CONFIRMED:
                    return `${sentenceCase(
                        firstName
                    )}'s information will be removed from your request and you will be redirected to the final review page to resubmit your request as a ${getSoloText()}. ${sentenceCase(
                        firstName
                    )} will no longer be able to access this request. If you’d like to add a new ${invitedUserType}, you may do so after submitting your request as a ${getSoloText()}.`;
                default:
                    return `${sentenceCase(
                        firstName
                    )}'s information will be removed from your request and you will be redirected to include a new ${invitedUserType}.`;
            }
        };

        const editModalSubtitle = isEditModal
            ? `${onboarding.formData.includedUser?.firstName}'s information has been included. If you edit their information, you will lose access to any rates you are currently pre-qualified for and will need to resubmit your request.`
            : "";

        const handleClick = async () => {
            // If the user is editing an invited user, we redirect them to the review page
            if(isEditModal){
                store.modalResetData();
                return navigate(PrequalUrlsEnum.REVIEW);
            }

            try {
                store.setLoading(true);
                const prequalificationRequestOutput = await PostRequestInviteDelete(
                    onboarding.formData.id as string,
                    onboarding.jwt as string
                );
                if (prequalificationRequestOutput) {
                    onboarding.saveFormInStoreAndStorage(prequalificationRequestOutput, {
                        includedUser: undefined,
                    });
                }

                if (status === IncludedUserStatus.CONFIRMED) {
                    navigate(PrequalUrlsEnum.REVIEW);
                } else {
                    navigate(userInclusionSelectUrl);
                }
                store.modalResetData();
            } catch (err) {
                errorLogs("PutRequestInviteDelete", err);
                store.setSnackError("Something went wrong. Please try again later.", "error");
            }
            store.setLoading(false);
        };

        return (
            <ModalWrapper maxWidth="600px">
                {getIncludedUserModalSvg(isEditModal ? "edit" : "delete")}
                <h3
                    style={{
                        fontSize: "24px",
                        lineHeight: "32px",
                        fontWeight: "600",
                        marginBottom: "12px",
                    }}
                >
                    {`Are you sure you want to ${isEditModal ? "edit" : "delete"}?`}
                </h3>
                <p
                    style={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: "400",
                        marginBottom: "24px",
                        color: "#878F99",
                        overflowWrap: "anywhere",
                    }}
                >
                    {isEditModal ? editModalSubtitle : getDeleteModalSubtitle()}
                </p>
                <ButtonWrapper centerButtons={true}>
                    <Button
                        id="delete-edit-button"
                        onClick={handleClick}
                        buttonType={ButtonTypes.SECONDARY}
                        userType={onboarding.formData.userType}
                    >
                        {isEditModal ? "Edit" : "Delete"}
                    </Button>
                    <Button
                        id="cancel-button"
                        tabIndex={0}
                        onClick={store.modalResetData}
                        buttonType={ButtonTypes.PRIMARY}
                        userType={onboarding.formData.userType}
                    >
                        Cancel
                    </Button>
                </ButtonWrapper>
            </ModalWrapper>
        );
    }
);
