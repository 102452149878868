import { FC } from "react";
import { UserType } from "../../../types/sparrow-types";
import { getSvgColors } from "../getSvgColors";

export const AwardIcon: FC<{ userType?: UserType }> = ({ userType = UserType.BORROWER }) => {
    const { primaryColor, accentColor } = getSvgColors(userType);

    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M20.8772 22.9612L29.3344 19.883L33.4836 31.2829C33.7568 32.0334 33.0833 32.7855 32.3073 32.5965L29.5158 31.9165C29.1235 31.8209 28.7123 31.9706 28.4733 32.2959L26.7719 34.6112C26.299 35.2548 25.2996 35.1116 25.0264 34.361L20.8772 22.9612Z"
                fill={accentColor}
            />
            <path
                d="M10.6655 19.8831L19.1228 22.9612L14.9735 34.3611C14.7004 35.1116 13.701 35.2548 13.228 34.6112L11.5267 32.296C11.2876 31.9707 10.8764 31.821 10.4842 31.9165L7.69268 32.5965C6.91667 32.7856 6.24315 32.0335 6.51632 31.2829L10.6655 19.8831Z"
                fill={accentColor}
            />
            <path
                d="M18.5381 3.56589C19.3287 2.71902 20.6713 2.71902 21.4619 3.56589L22.619 4.80522C23.089 5.30872 23.7851 5.53489 24.4613 5.40385L26.1259 5.0813C27.2633 4.86089 28.3494 5.65002 28.4913 6.79989L28.6989 8.48261C28.7832 9.16625 29.2135 9.75838 29.8376 10.0498L31.3738 10.7673C32.4235 11.2575 32.8384 12.5344 32.2773 13.548L31.4562 15.0314C31.1226 15.634 31.1226 16.366 31.4562 16.9686L32.2773 18.452C32.8384 19.4656 32.4235 20.7425 31.3738 21.2327L29.8376 21.9502C29.2135 22.2416 28.7832 22.8337 28.6989 23.5174L28.4913 25.2001C28.3494 26.35 27.2633 27.1391 26.1259 26.9187L24.4613 26.5962C23.7851 26.4651 23.089 26.6913 22.619 27.1948L21.4619 28.4341C20.6713 29.281 19.3287 29.281 18.5381 28.4341L17.381 27.1948C16.911 26.6913 16.2149 26.4651 15.5387 26.5961L13.8741 26.9187C12.7367 27.1391 11.6506 26.35 11.5087 25.2001L11.3011 23.5174C11.2168 22.8337 10.7865 22.2416 10.1624 21.9502L8.62621 21.2327C7.57647 20.7425 7.1616 19.4656 7.7227 18.452L8.54383 16.9686C8.87743 16.366 8.87743 15.634 8.54383 15.0314L7.7227 13.548C7.1616 12.5344 7.57647 11.2575 8.62621 10.7673L10.1624 10.0498C10.7865 9.75838 11.2168 9.16625 11.3011 8.48261L11.5087 6.79989C11.6506 5.65003 12.7367 4.86089 13.8741 5.0813L15.5386 5.40385C16.2149 5.53489 16.911 5.30872 17.381 4.80522L18.5381 3.56589Z"
                fill={primaryColor}
            />
            <path
                d="M19.0489 10.927C19.3483 10.0057 20.6517 10.0057 20.9511 10.9271L21.5716 12.8369C21.7055 13.2489 22.0894 13.5279 22.5227 13.5279H24.5308C25.4995 13.5279 25.9023 14.7675 25.1186 15.3369L23.494 16.5172C23.1435 16.7719 22.9968 17.2232 23.1307 17.6353L23.7512 19.5451C24.0506 20.4664 22.9961 21.2325 22.2124 20.6631L20.5878 19.4828C20.2373 19.2281 19.7627 19.2281 19.4122 19.4828L17.7876 20.6631C17.0039 21.2325 15.9494 20.4664 16.2488 19.5451L16.8693 17.6353C17.0032 17.2232 16.8565 16.7719 16.506 16.5172L14.8814 15.3369C14.0977 14.7675 14.5005 13.5279 15.4692 13.5279H17.4773C17.9106 13.5279 18.2945 13.2489 18.4284 12.8369L19.0489 10.927Z"
                fill={accentColor}
            />
        </svg>
    );
};
