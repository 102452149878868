import React, { useEffect, useState } from "react";
import {
    DropdownFieldProps,
    DropdownOption,
    InputValue,
    SetInputValue,
} from "../../../types/form-types";
import { useHandleDropdownBlur } from "../../../hooks/use-handle-dropdown-blur";
import { ChevronDownIcon } from "../../../assets/icons";
import { useClickOutside } from "../../../hooks";
import { formatToSentenceCase } from "../../../utils/general-utils";
import "../input-dropdown/Dropdown.css";
import "../form.styles.css";
import { getDropdownValue } from "../input-dropdown/utils";

interface LocalProps {
    secondaryNameFieldInputValue?: InputValue;
    setInputValue: SetInputValue;
}

const Dropdown: React.FC<DropdownFieldProps & LocalProps> = ({
    id,
    label,
    dropdownOptions,
    placeholder,
    style,
    inputValue,
    setInputValue,
    name,
}) => {

    const [dropdownValue, setDropdownValue] = useState<string>(getDropdownValue(inputValue, dropdownOptions));
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = React.useRef<HTMLUListElement>(null);
    //Hooks
    useClickOutside(dropdownRef, () => setIsDropdownOpen(false));
    useHandleDropdownBlur(isDropdownOpen, setIsDropdownOpen, dropdownRef);

    // Handler for when a user clicks on an option
    const handleOptionClick = (option: DropdownOption) => {
        setInputValue(name, option.value);
        setDropdownValue(option.label);
        setIsDropdownOpen(false);
    };

    // Sets the index of the focused option to apply a "focused" class name for styling purposes
    const handleOptionFocus = (index: number) => {
        setFocusedDropdownOptionIndex(index);
    };

    // Track the index of the focused option
    const [focusedDropdownOptionIndex, setFocusedDropdownOptionIndex] = useState<number>(0);

    const handleDropdownKeyboardNavigation = (
        event: React.KeyboardEvent<HTMLLIElement>,
        index: number
        // inputRef: React.RefObject<HTMLInputElement>,
    ) => {
        if (event.key === "ArrowDown") {
            event.preventDefault();
        }

        // If user hits 'Enter', then update the inputValue to the user's selection
        if (event.key === "Enter") {
            handleOptionClick(dropdownOptions[index]);
        }

        if (event.key === "ArrowUp") {
            // If user hits up arrow and they're not on the first dropdown option, bring focus to the previous dropdown option
            if (index > 0 && dropdownRef.current) {
                const previousOption = dropdownRef.current.childNodes[index - 1] as HTMLElement;
                previousOption.focus();
            }

            // If user hits down arrow and they're not on the last dropdown option, bring focus to the next dropdown option
        }

        if (event.key === "ArrowDown" && dropdownRef.current) {
            if (index < dropdownOptions.length - 1) {
                const nextOption = dropdownRef.current.childNodes[index + 1] as HTMLElement;
                nextOption.focus();
                setFocusedDropdownOptionIndex(index + 1);
                return;
            }
            const firstOption = dropdownRef.current.childNodes[0] as HTMLElement;
            firstOption.focus();
        }
    };

    const handleDropdownClick = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        const firstOption = dropdownRef.current?.childNodes[0] as HTMLElement;
        firstOption?.focus();
    }, [isDropdownOpen]);

    return (
        <div>
            <label className="FieldLabel" htmlFor={id}>
                {formatToSentenceCase(label)}
            </label>
            <div className="InputFieldContainer" onClick={handleDropdownClick}>
                <input
                    placeholder={formatToSentenceCase(placeholder)}
                    className="TextboxInput InputOverflow"
                    readOnly={true}
                    value={dropdownValue}
                />
                <button
                    className={`ToggleButton ${isDropdownOpen ? "open" : ""}`}
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                    aria-expanded={isDropdownOpen}
                    aria-controls="Dropdown"
                >
                    <ChevronDownIcon />
                </button>
                {isDropdownOpen && (
                    <div className="DropdownContainer">
                        <ul
                            id={`${id}-dropdown`}
                            className={`Dropdown`}
                            role="listbox"
                            ref={dropdownRef}
                        >
                            {dropdownOptions.map((option, index) => (
                                <li
                                    key={`${index}-list-option`}
                                    onClick={() => handleOptionClick(option)}
                                    onKeyDown={(event) =>
                                        handleDropdownKeyboardNavigation(event, index)
                                    }
                                    onFocus={() => handleOptionFocus(index)}
                                    tabIndex={index}
                                    className={`DropdownOption ${
                                        focusedDropdownOptionIndex === index ? "focused" : ""
                                    }`}
                                    role="option"
                                    aria-selected={inputValue === option.value ? "true" : "false"}
                                >
                                    {option.label}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Dropdown;
