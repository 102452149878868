import Lottie, { LottieRefCurrentProps } from "lottie-react";
import { MutableRefObject, useEffect, useRef } from "react";

interface Options {
    height: number;
    width: number;
    autoplay?: boolean;
    play?: boolean;
}

interface LottieIconProps {
    animationData: any;
    options: Options;
}

export const LottieIcon: React.FC<LottieIconProps> = ({ animationData, options }) => {
    const { play = true } = options;
    const lottieRef: MutableRefObject<LottieRefCurrentProps | null> = useRef(null);

    useEffect(() => {
        if (play) {
            lottieRef.current?.play();
        } else {
            lottieRef.current?.pause();
        }
    }, [play]);

    return (
        <>
            <Lottie
                lottieRef={lottieRef}
                loop={true}
                autoplay={options.autoplay ?? true}
                animationData={animationData}
                style={{
                    height: options.height + "px",
                    width: options.width + "px",
                }}
            />
        </>
    );
};
