import { useState, useEffect } from "react";
import { DropdownOption, FormFieldProps } from "../../types/form-types";
import { getAsyncDropdownFunction } from "../../utils/ui-library-utils/get-async-dropdown-function";

//NOTE: THIS IS A MOCK ASYNC FUNCTION. TO BE REPLACED
// Helper function to introduce a delay and return a promise
function delay(callback: () => DropdownOption[], ms: number): Promise<DropdownOption[]> {
    return new Promise((resolve) => setTimeout(() => resolve(callback()), ms));
}

/**
 * @description: Custom hook to handle form fields defined in an inputs file, as well as dependent and independent async dropdown options.
 * Dependent async dropdown options are fetched when the trigger value of the independent field matches the input value of the independent field.
 * Independent async dropdown options are fetched automatically.
 * On change of an Independent dropdown field, the fetchNewAsyncDropdownOptions state variable is set to true to trigger a fetch of the async dropdown options
 * for the dependent field. This is done to prevent unnecessary updates or infinite re-rendering.
 * @param {FormFieldProps[]} initialFormFields - The initial formFields state
 * @returns {[FormFieldProps[], React.Dispatch<React.SetStateAction<FormFieldProps[]>>, React.Dispatch<React.SetStateAction<boolean>>]}
 * Returns the formFields state, setFormFields function, and fetchNewAsyncDropdownOptions state updater
 */
const usePredefinedFormFields = (
    initialFormFields: FormFieldProps[]
): [
    FormFieldProps[],
    React.Dispatch<React.SetStateAction<FormFieldProps[]>>,
    React.Dispatch<React.SetStateAction<boolean>>
] => {
    // State variables for formFields and fetchNewAsyncDropdownOptions
    const [formFields, setFormFields] = useState(initialFormFields);
    const [fetchNewAsyncDropdownOptions, setFetchNewAsyncDropdownOptions] = useState(false);
    const [firstPageLoad, setFirstPageLoad] = useState(true);

    // Effect hook to handle async dropdown options
    useEffect(() => {
        // Only fetch async dropdown options on first page load or if fetchNewAsyncDropdownOptions is true
        if (firstPageLoad || fetchNewAsyncDropdownOptions) {
            formFields.forEach((formField) => {
                // Check if the formField has async dropdown options
                if (!("asyncDropdownOptions" in formField && formField.asyncDropdownOptions))
                    return;
                /*
                For a given formField, check if dropdownOptions is empty or if fetchNewAsyncDropdownOptions is true.
                fetchNewAsyncDropdownOptions is set to true when the input value of an independent field changes.
                */
                // Fetch the asyncDropdownFunction for the current formField. This is a function that returns a promise
                // TODO: pass in whole formField and add optional function to getAsyncDropdownFunction
                const asyncDropdownFunction = getAsyncDropdownFunction(formField.name);
                if (!asyncDropdownFunction) return;

                // Check to see if the current formField depends on the input value of an independent formField
                const independentField = formFields.find(
                    (field) => field.name === formField.independentFieldName
                );
                const independentFieldValue = independentField?.inputValue;

                const fetchAndUpdateDropdownOptions = async () => {
                    // Call the asyncDropdownFunction with the inputValue of the independentField (if available) else an empty string
                    const res = await delay(
                        () => asyncDropdownFunction((independentFieldValue as string) || ""),
                        200
                    );
                    formField.dropdownOptions = res;
                    // Update the formFields state with the modified copy
                    setFormFields([...formFields]);
                };

                fetchAndUpdateDropdownOptions();
                // Reset fetchNewAsyncDropdownOptions to false to prevent unnecessary updates
                setFetchNewAsyncDropdownOptions(false);
            });
        }

        setFirstPageLoad(false);
    }, [firstPageLoad, formFields, fetchNewAsyncDropdownOptions]);

    // Return the formFields state, setFormFields function, and fetchNewAsyncDropdownOptions state updater
    return [formFields, setFormFields, setFetchNewAsyncDropdownOptions];
};

export default usePredefinedFormFields;
