const months = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
};

export const getEpochTimestamp = (date: string): number => {
    return new Date(date).getTime() / 1000;
};

export const getYearFromDate = (time: number): string => {
    const date = new Date(time * 1000);
    return date.toUTCString().split(" ")[3];
};

export const getCurrentYear = () => {
    const date = new Date();
    return date.getFullYear();
};

export const formatDateAsMMMDDYYYY = (date: number) => {
    return new Date(date * 1000).toLocaleString("en-us", {
        month: "short",
        day: "numeric",
        year: "numeric",
    });
};

//used for dob - converts utc timestamp to mm/dd/yyyy format
export const utcToFormattedDateString = (dob: number | string) => {
    const utcDate = new Date(Number(dob) * 1000).toUTCString();
    const mmmFormat = utcDate.split(" ")[2];
    const month = months[mmmFormat];
    const day = utcDate.split(" ")[1];
    const year = utcDate.split(" ")[3];
    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
};

export const getFormattedDateWithoutDay = (dob: number | string) => {
    const utcDate = new Date(Number(dob) * 1000).toUTCString();
    const mmmFormat = utcDate.split(" ")[2];
    const month = months[mmmFormat];
    const year = utcDate.split(" ")[3];
    const formattedDate = `${month}/${year}`;
    return formattedDate;
};

