import { observer } from "mobx-react";
import { FC, PropsWithChildren } from "react";
import { useOnboarding } from "../../../../../../common/core/providers/onboarding.provider";
import { IncludedUserStatusComponent } from "../../SharedPopupComponents/IncludedUserComponents/IncludedUserStatus";

export const PopupIncludedUserContent: FC<PropsWithChildren<{}>> =
    observer(({ children }) => {
        const onboarding = useOnboarding();

        return (
            <div className="flex-container-1">
                {onboarding.formData.includedUser && <IncludedUserStatusComponent />}
                {children}
            </div>
        );
    });
