"use client";
import React from "react";
import "./style.scss";

interface ILinkButton {
    link?: string;
    onClick?: () => void;
    underline: boolean;
    onLeave?: () => void;
    target?: string;
    isGray?: boolean;
    tabIndex?: number;
    children?: React.ReactNode;
}

export const LinkButton: React.FunctionComponent<ILinkButton> = (props) => {
    const wrapper = () =>
        props.link ? (
            <a
                href={props.link}
                onClick={props.onLeave}
                tabIndex={props.tabIndex}
                target={props.target ? props.target : "_self"}
            >
                {props.children}
            </a>
        ) : (
            <div tabIndex={props.tabIndex} onClick={props.onClick}>
                {props.children}
            </div>
        );

    return (
        <div
            className={`link-btn ${props.underline ? "big-link" : "small-link"} ${
                props.isGray ? " gray" : ""
            }`}
        >
            {wrapper()}
        </div>
    );
};
