import { FC, PropsWithChildren } from "react";
import { useStore } from "../../core/providers/global.provider";
import closeIcon from "../../../assets/images/icons/icon-24-cross-close-icon.png";
import "./modal-wrapper.styles.scss";
interface ModalWrapperProps {
    maxWidth?: string;
}

export const ModalWrapper: FC<PropsWithChildren<ModalWrapperProps>> = ({ children, maxWidth }) => {
    const store = useStore();

    return (
        <>
            <button className="btn-mwrap-close"
                onClick={store.modalResetData}
            >
                <img src={closeIcon} alt="close-button" />
            </button>
        <div style={{ maxWidth: maxWidth, height: "100%"}}>
            {children}
        </div>
        </>
    );
};
