import { ModalWrapper } from "../../modals/modal-wrapper";

export const SoftCreditRider = () => {
    return (
        <ModalWrapper>
            <h2>Soft Credit Inquiry</h2>
            <p>
                This pre-qualification process will result in a soft credit check. While a soft
                credit inquiry may appear alongside your credit history on your credit reports from
                the three main credit bureaus (Equifax, Experian and TransUnion), it won’t impact
                your credit score.
            </p>
        </ModalWrapper>
    );
};
