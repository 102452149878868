import { ChangeEvent } from 'react'
import {
  FormFieldName,
  FormFieldType,
  NameFieldProps,
  SetInputValue,
} from '../../../types/form-types/';
import { TextBox, Dropdown } from '..';
import { getInputRestrictionFunction } from '../../../utils/ui-library-utils';
import { formatToSentenceCase } from '../../../utils/general-utils';


interface LocalProps {
  setInputValue: SetInputValue;
}

const NameField = ({
  nameFieldType,
  id,
  label,
  placeholder,
  inputValue,
  secondaryNameFieldInputValue,
  dropdownOptions,
  setInputValue,
  name,

}: NameFieldProps & LocalProps) => {
  const isFirstName = nameFieldType === "firstName";
  const isLastName = nameFieldType === "lastName";

  const handleInputChange = (name: FormFieldName, e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    if(!value) return setInputValue(name, "");
    const inputRestrictionFunction = getInputRestrictionFunction(name);
    const isValueValid = inputRestrictionFunction(value);
    if (!isValueValid) return;

    setInputValue(name, value);
  }

  return (
    <>
      <div style={{ display: "flex", gap: "10px" }}>
      
        <TextBox
          formFieldType={FormFieldType.TextBox}
          id={id}
          name={name}
          label={label}
          placeholder={formatToSentenceCase(placeholder)}
          type={"text"}
          inputValue={inputValue}
          backendValue={inputValue}
          onChange={(e) => handleInputChange(name, e)}
          style={{
            width: "100%",
          }}
        />

        {isFirstName &&

          <TextBox
            formFieldType={FormFieldType.TextBox}
            id={"middleInitial"}
            name={FormFieldName.MiddleInitial}
            label={"Middle Initial"}
            placeholder={"Middle Initial"}
            type={"text"}
            inputValue={secondaryNameFieldInputValue}
            backendValue={secondaryNameFieldInputValue}
            onChange={(e) => handleInputChange(FormFieldName.MiddleInitial, e)}
            style={{
              width: "100px",
              minWidth: "100px",
            }}
          />
        }

        {isLastName &&
          <Dropdown
            id={FormFieldName.Suffix}
            name={FormFieldName.Suffix}
            formFieldType={FormFieldType.LastNameField}
            inputValue={secondaryNameFieldInputValue}
            backendValue={secondaryNameFieldInputValue}
            label={"Suffix"}
            placeholder={"Suffix"}
            dropdownOptions={dropdownOptions ?? []}
            setInputValue={setInputValue}
            style={{
              width: "100px",
              minWidth: "100px",
            }}

          />
        }

      </div>
    </>
  )
}

export default NameField;