import { useState, useEffect } from "react";
import VerticalLines from "./components/vertical-lines/VerticalLines";
import { PAAASHeaderBar } from "../../common/components/PAAASHeaderBar";
import MobileNavigationHeader from "../../common/components/MobileNavigationHeader";
import { HeroHome } from "./page-sections/hero-section/HeroSection";
import { OneFormSection } from "./page-sections/one-form-section/OneFormSection";
import { ZeroFeesSection } from "./page-sections/zero-fees-section/ZeroFeesSection";
import { FaqSection } from "./page-sections/faq-section/FaqSection";
import { Footer } from "./page-sections/footer/Footer";
import styles from "./styles.module.scss";
import ScrollToTop from "../../common/components/ScrollToTop";
import { wordpressDisclosure } from "../../common/core/services/sparrow-api-endpoints";
import { GetPublic } from "../../common/core/services/clientApi";
import { observer } from "mobx-react";
import { useStore } from "../../common/core/providers/global.provider";
import HtmlRenderer from "../../common/components/html-renderer";
import { LeftNavigationBar } from "../../common/components/LeftNavigationBar";
import { useMediaQuery } from "react-responsive";
import { disableDefaultScroll } from "../../common/utils/global";
import { insertCompanyNameInText } from "../../common/utils/helper_functions/insertCompanyNameInText";
import { useOnboarding } from "../../common/core/providers/onboarding.provider";

export const PaaasLandingPage = observer(() => {
    const store = useStore();
    const { companyName } = useOnboarding();
    const [disclosureContent, setDisclosureContent] = useState({ title: "", text: "" });
    const [showSidebarOnMobile, setShowSidebarOnMobile] = useState(false);

    useEffect(() => {
        const getWPData = async () => {
            try {
                const response = await GetPublic(wordpressDisclosure);
                const { title, text } = response.acf.disclosure_modal;

                setDisclosureContent({
                    title: insertCompanyNameInText(title, companyName),
                    text: insertCompanyNameInText(text, companyName),
                });
            } catch (err) {
                console.error(err);
            }
        };

        getWPData();
    }, []);

    const handleDisclosureClick = async () => {
        store.setModal(() => {
            return (
                <div className="modal-content">
                    <button className="close-modal" onClick={store.modalResetData}>
                        <span className={"icon-close"} />
                    </button>
                    <div>
                        <h3>{disclosureContent.title}</h3>
                        <HtmlRenderer htmlString={disclosureContent.text} />
                    </div>
                </div>
            );
        });
    };
    const isLess768px = useMediaQuery({
        query: "(max-width: 768px)",
    });

    const setShowSideBarHandlerForMobile = (state: boolean) => {
        setShowSidebarOnMobile(state);
        if (isLess768px) {
            disableDefaultScroll(state);
        }
    };

    return (
        <>
            <div className={styles["page-wrapper"]}>
                <PAAASHeaderBar />
                <MobileNavigationHeader
                    setShowSideBarHandlerForMobile={setShowSideBarHandlerForMobile}
                />
                <VerticalLines />
                <main>
                    <HeroHome handleDisclosureClick={handleDisclosureClick} />
                    <OneFormSection />
                    <ZeroFeesSection handleDisclosureClick={handleDisclosureClick} />
                    <FaqSection />
                </main>
                <Footer />
                <ScrollToTop />
            </div>
            <LeftNavigationBar
                showSidebarOnMobile={showSidebarOnMobile}
                setShowSideBarHandlerForMobile={setShowSideBarHandlerForMobile}
            />
        </>
    );
});
