import React from "react";
import clearIcon from "../../../assets/images/clear-input.svg";
import "../../../assets/styles/country-restyle.scss";
import { ILimitedPhoneNumber } from "../PhoneInput/type";
import { PhoneInput } from "../PhoneInput/PhoneInput";

interface ICountryCode {
    code?: any;
    name: string;
    value: string;
    onChange: (e: any, v?: any) => void;
    error: string | null;
    onClear?: () => void;
}

export const CountryCode: React.FunctionComponent<ICountryCode> = (props) => {
    return (
        <div className="form-row">
            <div className="form-col form-col-12">
                <div
                    className={`${
                        props.error && props.error.length ? "error" : ""
                    } form-group form-group-phone`}
                >
                    <div className="labels-wrapper">
                        <label className="label-phone">Phone number</label>
                    </div>
                    <PhoneInput
                        form={{
                            // We remove "+1" from the phone number because the PhoneInput component needs it separate
                            phoneNumber: props.value?.slice(2),
                            countryPrefix: props.value?.slice(0, 2),
                        }}
                        setForm={(phoneNumber: ILimitedPhoneNumber) => {
                            const formattedPhoneNumber =
                                (phoneNumber.countryPrefix ?? "") + (phoneNumber.phoneNumber ?? "");
                            props.onChange(formattedPhoneNumber);
                        }}
                        name="phoneNumber"
                        defaultCountryCode="us"
                        countries={["us"]}
                    />
                    {props.value && props.value.length > 0 && (
                        <div className="form-group--navigation clear-btn" onClick={props.onClear}>
                            <img src={clearIcon} alt="" />
                        </div>
                    )}
                    {props.error && props.error.length > 0 && (
                        <p className="error-text">{props.error}</p>
                    )}
                </div>
            </div>
        </div>
    );
};
