import { Post } from "../../../../common/core/services/clientApi";
import { errorLogs } from "../../../../common/utils/global";
import { SparrowApi_POST_RequestExpandedSearchSeen } from "../../../../common/core/services/sparrow-api-endpoints";
import { OnboardingStore } from "../../../../common/store/onboarding";
import { ExpandedSearchInput } from "../../../../sparrow-ui-library/src/types/sparrow-types";

export const seenExpandedSearch = async (
    requestBody: { id: string } & ExpandedSearchInput,
    onboarding: OnboardingStore
) => {
    if (!onboarding.jwt) return;

    try {
        return Post(SparrowApi_POST_RequestExpandedSearchSeen, onboarding.jwt, {
            id: requestBody.id,
        });
    } catch (err) {
        errorLogs("submitExpandedSearch", err);
    }
};

