import { ReactNode, createContext, useContext } from "react";
import { useState } from "react";
import { Forms } from "../types/form-types";

interface SparrowUIFormsContextProps {
    resetSparrowUIFormsContext: () => void;
    forms: Forms;
    setForms: React.Dispatch<React.SetStateAction<Forms>>;
    activeFormIndex: number;
    setActiveFormIndex: React.Dispatch<React.SetStateAction<number>>;
    showMailingAddress: boolean;
    setShowMailingAddress: React.Dispatch<React.SetStateAction<boolean>>;
    isMailingAddressSameAsPermanentAddress: boolean;
    setIsMailingAddressSameAsPermanentAddress: React.Dispatch<React.SetStateAction<boolean>>; 
}

const defaultSparrowUIFormsContext: SparrowUIFormsContextProps = {
    resetSparrowUIFormsContext: () => null,
    forms: [],
    setForms: () => null,
    activeFormIndex: 0,
    setActiveFormIndex: () => null,
    isMailingAddressSameAsPermanentAddress: true,
    setIsMailingAddressSameAsPermanentAddress: () => null,
    showMailingAddress: false,
    setShowMailingAddress: () => null,
};

export const SparrowUIFormsContext = createContext<SparrowUIFormsContextProps>(defaultSparrowUIFormsContext);

export const SparrowUIFormsProvider = ({ children }: { children: ReactNode }) => {
    const [forms, setForms] = useState<Forms>([]);
    const [activeFormIndex, setActiveFormIndex] = useState<number>(0);
    const [showMailingAddress, setShowMailingAddress] = useState(false);
    const [isMailingAddressSameAsPermanentAddress, setIsMailingAddressSameAsPermanentAddress] = useState(true);
    
    const resetSparrowUIFormsContext = () => {
        setForms([]);
        setActiveFormIndex(0);
        setShowMailingAddress(false);
        setIsMailingAddressSameAsPermanentAddress(true);
    };


    const value = {
        forms,
        setForms,
        activeFormIndex,
        setActiveFormIndex,
        showMailingAddress,
        setShowMailingAddress,
        isMailingAddressSameAsPermanentAddress,
        setIsMailingAddressSameAsPermanentAddress,
        resetSparrowUIFormsContext,
    };
    
    return <SparrowUIFormsContext.Provider value={value}>{children}</SparrowUIFormsContext.Provider>;
};

export const useSparrowUIFormsContext = () => {
    return useContext(SparrowUIFormsContext);
}



