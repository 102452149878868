import React from "react";
import { LinkButton } from "../../../common/components/buttons/link_button";
import {
    loginStepBorrower,
    loginStepCrest,
    signUpStepTypeUrl,
} from "../../../common/core/routes/navigation-urls";
import AuthInitStep from "../../../common/views/sign-in";

export const SignInInitStep = () => {
    return (
        <div className="auth-flow-container--inner">
            <AuthInitStep
                stepTitle="Select your account type"
                stepSubTitle={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "5px",
                            alignItems: "center",
                        }}
                    >
                        <div className="subtitle">Don't have an account? </div>{" "}
                        <LinkButton link={signUpStepTypeUrl} underline={true}>
                            {" "}
                            Sign up
                        </LinkButton>
                    </div>
                }
                nextStepUrl={loginStepBorrower}
                crestUrl={loginStepCrest}
                type="sign-in"
            />
        </div>
    );
};
