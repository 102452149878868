import { Address, Citizenship, Housing, Income } from "..";
import { CosignerInput } from "../../../types/sparrow-types";

export const Cosigner = (): CosignerInput => {
    return {
        firstName: "",
        lastName: "",
        dob: "",
        email: "",
        citizenshipStatus: Citizenship(),
        permanentAddress: Address(),
        mailingAddress: Address(),
        income: Income(),
        additionalIncome: Income(),
        employment: "",
        housing: Housing(),
        ssn: "",
        offersQuizAnswer: null,
    };
};
