import { FC, MouseEventHandler } from "react";
import { SectionWrapper } from "../../components/section-wrapper/SectionWrapper";
import { SectionHeader } from "../../components/section-header/SectionHeader";
import { HomeCard } from "../../components/home-card/HomeCard";
import { zeroFeesSectionContent } from "../../constants/home-cards-content";
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider";
import { getPossesiveCase } from "../../../../common/utils/global";
import { Button } from "../../../../common/components/buttons/button/Button";
import { UserType } from "../../../../sparrow-ui-library/src/types/sparrow-types";
import { ButtonTypes } from "../../../../common/components/buttons/button/Button";
import { signUpStepBorrowerUrl } from "../../../../common/core/routes/navigation-urls";
import { useNavigate } from "react-router-dom";
import InterfaceDashboard from "../../components/InterfaceDashboard";
import { IllustrativeDisclosure } from "../../components/illustrative-disclosure/IllustrativeDisclosure";
import styles from "./styles.module.scss";
import { observer } from "mobx-react";


export const ZeroFeesSection: FC<{handleDisclosureClick: MouseEventHandler<HTMLImageElement>}> = observer((props) => {
    const onboarding = useOnboarding();
    const navigate = useNavigate();
    const content = zeroFeesSectionContent(onboarding.companyName);

    return (
        <SectionWrapper>
            <div className={styles.topSection}>
                <div className={styles.leftContent}>
                    <SectionHeader
                        sectionText="Zero Fees"
                        title="Find the best student loan for free with no impact on your credit"
                        subtitle={`${getPossesiveCase(
                            onboarding.companyName
                        )} student loan marketplace is completely free. No bait-and-switch or smoke and mirrors. ${
                            onboarding.companyName
                        } makes student loan borrowing safe, transparent, and secure.`}
                    />
                </div>
                <div className={styles.contentGridContainer}>
                    {content.map((card) => {
                        return <HomeCard {...card} />;
                    })}
                </div>
            </div>
            <Button
                buttonType={ButtonTypes.PRIMARY}
                userType={UserType.BORROWER}
                showIcon={true}
                style={{ fontSize: "16px", fontWeight: "500", float: "right", marginTop: "90px", marginRight: "35px" }}
                onClick={() => navigate(signUpStepBorrowerUrl)}
            >
                Find the best rate
            </Button>
            <div className={styles.ratesPageContainer}>
            <InterfaceDashboard size={'medium'} type={'main'} customClass="zero-fees-section"/>
            <IllustrativeDisclosure className="left" handleDisclosureClick={props.handleDisclosureClick} />
            </div>
        </SectionWrapper>
    );
});
