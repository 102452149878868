import { UserType } from "../../../types/sparrow-types";
import { getSvgColors } from "../getSvgColors";

export const IncompleteStatusIcon = ({userType}: {userType?: UserType}) => {
    const { primaryColor } = userType ? getSvgColors(userType) : {primaryColor: undefined};

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.3"
                d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM3.6 12C3.6 16.6392 7.36081 20.4 12 20.4C16.6392 20.4 20.4 16.6392 20.4 12C20.4 7.36081 16.6392 3.6 12 3.6C7.36081 3.6 3.6 7.36081 3.6 12Z"
                fill={primaryColor ?? "var(--error-main)"}
            />
        </svg>
    );
};
