"use client";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { LinkButton } from "../../components/buttons/link_button";
import { RegularButtonWithLoading } from "../../components/buttons/regular_button/RegularButtonWithLoading";
import { useStore } from "../../core/providers/global.provider";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import {
    loginStepBorrower,
    signUpSuccessUrl,
    signUpStepTypeUrl,
} from "../../core/routes/navigation-urls";
import { checkObjectProperty, sendUtms } from "../../utils/global";
import { useLocation } from "react-router-dom";
import {
    confirmEmailLoginBase,
    confirmEmailResetBase,
    confirmPasswordBase,
    resetPasswordBase,
} from "../../core/routes/routing-urls";
import { AuthHandler } from "../../core/auth";

export default observer(function CheckEmail() {
    const { registerData, loginData, setAuthSnackError } = useStore();
    const { routeState, setRouteState, paaasCustomization } = useOnboarding();
    const [valid, setIsValid] = useState(false);
    const [emailFromReset, setEmailFromReset] = useState<string>();
    const [fromReset, setFromReset] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const routePathname = useLocation().pathname;

    const mockLength = ["", "", "", "", "", ""];
    const [form, setForm] = useState<any>(mockLength);

    const partnerId = paaasCustomization?.partnerId;

    useEffect(() => {
        const checkComesFrom = routePathname;
        setFromReset(checkComesFrom === confirmEmailResetBase);

        if (routeState && routeState.email !== undefined) {
            setEmailFromReset(checkComesFrom ? routeState.email : undefined);
        }

        if (!routeState) {
            navigate(
                checkComesFrom === confirmEmailResetBase
                    ? resetPasswordBase
                    : checkComesFrom === confirmEmailLoginBase
                    ? loginStepBorrower
                    : signUpStepTypeUrl
            );
        }
    }, []);

    //@ts-ignore
    const onKeyDown = (e, i) => {
        if (e.keyCode === 8) {
            const arr = [...form];
            if (e.target.value !== "" && (i === 5 || e.target.parentElement?.nextSibling)) {
                arr[i] = "";
                setForm(arr);
            } else if (e.target.parentElement.previousSibling) {
                e.target.parentElement.previousSibling.querySelector("input").focus();
                arr[i - 1] = "";
                setForm(arr);
            }
        }
    };

    // @ts-ignore
    const onChange = (v, i) => {
        if (v.target.value.length === 6) {
            const _str = v.target.value;
            const value = _str.toString();
            let arr: string[] = [...mockLength];
            for (let i = 0; i < value.length; i++) {
                arr[i] = value[i];
            }
            v.target.parentElement.parentElement.lastChild.querySelector("input").focus();
            setForm(arr);
        } else {
            if (v.target.value.length === 1) {
                if (v.target.value.match(/^\d+$/)) {
                    if (v.target.parentElement.nextSibling) {
                        v.target.parentElement.nextSibling.querySelector("input").focus();
                    }
                    if (form[i] === "") {
                        const _form = [...form];
                        _form[i] = v.target.value;
                        setForm(_form);
                    }
                }
            }
        }
    };

    useEffect(() => {
        checkValid();
    }, [form]);

    const checkValid = () => {
        var j = form.join("");
        // const errorMess = j.length <= 6 && j.length > 0 ? '' : '';
        // setCodeError(errorMess);
        setIsValid(j.length === 6);
    };

    const onSubmit = async () => {
        setLoading(true);
        if (fromReset) {
            // { pathname: confirmPasswordUrl, state: { email: emailFromReset, code: form.join('') } }
            setRouteState({ email: emailFromReset, code: form.join("") });
            setLoading(false);
            setAuthSnackError("Email verified successfully", "success");
            navigate(confirmPasswordBase);
        } else {
            const data = routePathname === confirmEmailLoginBase ? loginData : registerData;
            try {
                await AuthHandler.confirmSignUp(data.email, form.join(""), partnerId);
                await AuthHandler.signIn({
                    username: data.email,
                    password: data.password,
                    partnerId,
                }).then((dataLogin) => {
                    setRouteState({ userData: dataLogin });
                    if (checkObjectProperty(window, "dataLayer")) {
                        (window as any).dataLayer.push({
                            userId: dataLogin.attributes.sub,
                            event: "registration",
                        });
                    }
                    sendUtms(dataLogin.signInUserSession.idToken.jwtToken);
                });
                setLoading(false);
                setAuthSnackError("Email verified successfully", "success");
                navigate(signUpSuccessUrl);
            } catch (e: any) {
                setLoading(false);
                if (e.message === "Invalid verification code provided, please try again.") {
                    setAuthSnackError("The code is invalid", "error");
                } else {
                    setAuthSnackError(e.message, "error");
                }
            }
        }
    };

    const resendConfirmationCode = async () => {
        if (fromReset && emailFromReset) {
            setLoading(true);
            try {
                await AuthHandler.forgotPassword(emailFromReset, partnerId);
                setAuthSnackError("Verification code has been sent", "success");
                setLoading(false);
            } catch (e) {
                setAuthSnackError("Verification code has been sent", "success");
                setLoading(false);
            }
        } else {
            const data = routePathname === confirmEmailLoginBase ? loginData : registerData;
            if (data.email) {
                setLoading(true);
                try {
                    await AuthHandler.resendSignUp(data.email, partnerId);
                    setAuthSnackError("Verification code has been sent", "success");
                    setLoading(false);
                } catch (err) {
                    setAuthSnackError("Verification code has been sent", "success");
                    setLoading(false);
                }
            }
        }
    };

    const PageTexts = () => {
        if (fromReset) {
            return (
                // <>
                //   <p>If an account associated with the email <span className='highlighted'>{emailFromReset}</span> exists, a 6-digit code has been sent to <span className='highlighted'>{emailFromReset}</span>.
                //     In order to continue please enter the 6-digit code below.
                //   </p>
                //   <div><p>Didn't receive an email? Check that your email address is correct or</p> <LinkButton onClick={resendConfirmationCode} isGray underline={true}>resend</LinkButton>.</div>
                // </>
                <>
                    <p>
                        In order to continue, please enter the 6-digit code we just sent to{" "}
                        <span className="highlighted">{emailFromReset}</span>
                    </p>
                    <div>
                        <p>Didn't receive an email? Check your spam folder or</p>{" "}
                        <LinkButton onClick={resendConfirmationCode} isGray underline={true}>
                            Resend
                        </LinkButton>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <p>
                        In order to continue, please enter the 6-digit code we just sent to{" "}
                        <span className="highlighted">{emailFromReset}</span>
                    </p>
                    <div>
                        <p>Didn't receive an email? Check your spam folder or</p>{" "}
                        <LinkButton onClick={resendConfirmationCode} isGray underline={true}>
                            Resend
                        </LinkButton>
                    </div>
                </>
            );
        }
    };

    return (
        <>
            <div className="step-form-overhead" onClick={() => navigate(-1)}>
                <span className="icon-arrow-right" />
                Previous step
            </div>
            <div className="step-form-header">
                <h2 className="h2">Check your email</h2>
                <PageTexts />
            </div>

            <div className="step-form-body">
                <div className="step-form-block">
                    <div className="code-input-email">
                        {mockLength.map((value, index) => (
                            <div className="input" key={index + "_value"}>
                                <input
                                    onKeyDown={(e: any) => onKeyDown(e, index)}
                                    value={form[index]}
                                    type="text"
                                    pattern="[0-9]*"
                                    name="code-verification"
                                    onChange={(v) => onChange(v, index)}
                                    maxLength={6}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="step-form-footer">
                <div className="button-group text-center">
                    {/* {!fromReset && <RegularButton onSubmit={goBack} bordered={true}>Previous step</RegularButton>} */}
                    <RegularButtonWithLoading
                        loading={loading}
                        id="confirmation-code-submit"
                        onSubmit={onSubmit}
                        disabled={!valid}
                    >
                        Continue
                    </RegularButtonWithLoading>
                </div>
            </div>
        </>
    );
});
