import { UserType } from "../../../../../../../sparrow-ui-library/src/types/sparrow-types";
import { TipsNewsIcon, TipsSearchIcon } from "../../../../../../../sparrow-ui-library/src/assets/icons/tips-icons"
import { useOnboarding } from "../../../../../../core/providers/onboarding.provider"

export const TipsCard = ({title, body, index}: {title: string, body: string, index: number}) => {
  const {formData} = useOnboarding();

  return (
    <div className='TipCard'>
        <div className='TipCardHeader'>
          <div className="TipsIcon">
            {index === 0 ? <TipsNewsIcon userType={formData.userType as UserType}/> : <TipsSearchIcon userType={formData.userType as UserType}/>}
          </div>
            <span className='TipCardTitle'>{title}</span>
        </div>
        <section className='TipCardContent'>
            <span className='TipCardDescription'>{body}</span>
        </section>
    </div>
  )
}


