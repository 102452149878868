import { GlobalStore } from "../../store/global";
import { OnboardingStore } from "../../store/onboarding";
import { clearLocalStorageData } from "../../utils/global";
import { NavigateFunction } from "react-router-dom";
import { loginUrl } from "../routes/navigation-urls";
import Cookies from "js-cookie";
import { AuthHandler } from "../auth";

export const logoutUser = async (params: {
    store: GlobalStore;
    onboarding: OnboardingStore;
    navigate: NavigateFunction;
}) => {
    const { store, onboarding, navigate } = params;

    const { resetForms, setLoading, clearGLobStoredDate } = store;
    const { reset, setJwt, clearOnbStoredDate } = onboarding;

    try {
        setLoading(true);
        await AuthHandler.signOut();
        navigate(loginUrl);
        clearLocalStorageData();
        resetForms();
        reset(true);
        setJwt(null);
        setLoading(false);
        clearLocalStorageData();
        clearGLobStoredDate();
        clearOnbStoredDate();
        clearCognitoCookies();
    } catch (e) {}

    setLoading(false);
};

export const clearUserData = (params: { store: GlobalStore; onboarding: OnboardingStore }) => {
    const { store, onboarding } = params;

    const { resetForms, clearGLobStoredDate } = store;
    const { reset, setJwt, clearOnbStoredDate } = onboarding;

    clearLocalStorageData();
    resetForms();
    reset(true);
    setJwt(null);
    clearGLobStoredDate();
    clearOnbStoredDate();
    clearCognitoCookies();
};

export const clearCognitoCookies = () => {
    const cookies =
        typeof document !== "undefined" && document.cookie
            ? document.cookie.split(";").filter((c) => c.includes("CognitoIdentityServiceProvider"))
            : [];

    if (cookies?.length) {
        cookies.forEach((c) => {
            const cookieName = c.trim().split("=")[0];
            document.cookie = cookieName + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
            Cookies.remove(cookieName);
        });
    }
};

export const isSuccessfullyLoggedIn = (jwt: string | null) => {
    return document.cookie
        ? document.cookie.includes("CognitoIdentityServiceProvider") && jwt
        : false;
};
