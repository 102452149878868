import { MoneyAmountInput } from '../money-amount';

export enum EmployedTypes {
    FullTime = "Employed - Full Time",
    PartTime = "Employed - Part Time",
    Military = "Employed - Military",
    SelfEmployed = "Self Employed",
    FutureEmployed = "Future Employed",
}

export enum OtherIncomeTypes {
    Retired = "Retired",
    Unemployed = "Unemployed",
}

export interface IncomeInput {
    amount: MoneyAmountInput;
    type: string | undefined;
}


