import { useNavigate } from "react-router-dom";
import { UserType } from "../../../../../common/constants/defaultInputs";
import { useOnboarding } from "../../../../../common/core/providers/onboarding.provider";
import { Button, ButtonTypes } from "../../../../../common/components/buttons/button/Button";
import { sentenceCase } from "../../../../../common/utils/global";
import {
    userInclusionManagementUrl,
    userInclusionReviewInviteUrl,
} from "../../../../../common/core/routes/navigation-urls";
import { observer } from "mobx-react";
import { IncludedUserStatus } from "../../../../../sparrow-ui-library/src/types/sparrow-types";

export const IncludedUserButton: React.FC<{isMobileButton?: boolean}> = observer(({isMobileButton}) => {
    const navigate = useNavigate();
    const onboarding = useOnboarding();
    const { formData } = onboarding;

    const callerIsCreator = formData.isCreator && formData.includedUser;
    
    const getTextAndUrlForButton = () => {
        if (onboarding.includedUserIsInvitedAndHasNotAcceptedInvite()) {
            return {
                text: "Accept Invitation",
                url: userInclusionReviewInviteUrl,
            }
        }

        if (callerIsCreator && formData.includedUser?.status !== IncludedUserStatus.DIRECT_STARTED) {
            return {
                text: `${sentenceCase(formData.includedUser?.userType)} page`,
                url: userInclusionManagementUrl,
            }
        }
        
        return {
            text: "Finish sign up",
            url: userInclusionManagementUrl,
        }
    }

    const { text, url } = getTextAndUrlForButton();

    return (
        <div className={`${isMobileButton ? "btn-group incl-user-mgmt-btn-mobile" : "btn-group"}`}>
            <Button
                buttonType={ButtonTypes.SECONDARY}
                showIcon={true}
                userType={onboarding.formData.userType as UserType}
                onClick={() => navigate(url)}
                padding="8px 16px"
                borderWidth="2px"
                style={{
                    fontWeight: 500,
                    fontSize: "12px",
                    lineHeight: "16px",
                }}
            >
                {text}
            </Button>
        </div>
    );
});
