import React, { Fragment, useEffect, useState } from "react";
import { FormContent } from "..";
import { InputValue, RadioButtonFormProps, RadioButtonProps } from "../../../types/form-types";
import { FormFieldName } from "../../../types/form-types";
import {
    getSpecialFormattingFunction,
    getErrorMessageFunction,
    handleDependentFields,
} from "../../../utils/ui-library-utils";
import { updateFieldInputValue, getCheckedButtonFields } from "./utils";
import { useOnboarding } from "../../../../../common/core/providers/onboarding.provider";
import "./RadioButtonsContainer.css";

interface RadioButtonsContainerProps {
    form: RadioButtonFormProps;
    formRadioButtons: RadioButtonProps[];
    forms: RadioButtonFormProps[];
    setForms: React.Dispatch<React.SetStateAction<RadioButtonFormProps[]>>;
    formIndex: number;
    getNewFormsInstance: () => RadioButtonFormProps[];
}

//TODO: include support for async dependent fields using the usePredefinedFormFields hook. Not a priority given that Marketplace/PAAAS doesn't use async dependent fields.

const RadioButtonsContainer = ({
    formRadioButtons,
    form,
    forms,
    setForms,
    formIndex,
    getNewFormsInstance,
}: RadioButtonsContainerProps) => {
    const {formData} = useOnboarding();

    const [activeRadioButtons, setActiveRadioButtons] = useState(formRadioButtons);
    
    /* IMPORTANT: radio buttons only update if activeRadioButtons is updated. If formInputs changes
    at the parent scope, we need to update activeRadioButtons to reflect the new formInputs.
    E.g., submission of a form may trigger updating error messages for independent fields, which
    are found within formInputs.*/
    useEffect(() => {
        setActiveRadioButtons([...activeRadioButtons]);
    }, [form]);

    // Helper - Update the forms array with the new radio button options for this particular form
    const updateForms = (
        radioButtonOptions: RadioButtonProps[],
        optionSelected?: { optionSelected: boolean }
    ) => {
        const updatedForms = forms.map((form, i) => {
            if(i === formIndex){
                return {
                    ...form,
                    radioButtonOptions,
                }
            }

            return form;
        });

        if (optionSelected) {
            /* Option selected is used to determine whether any radio button selection has been made for the form to prevent submission if no radio button has been selected.
            By default, if this function is being invoked, an option has been selected*/
            updatedForms[formIndex].optionSelected = optionSelected.optionSelected;
            updatedForms[formIndex].errorMessage = "";
        }

        setForms(updatedForms);
    };
    
    // Handles radio button option selection
    const handleOptionChange = (id: string) => {
        /* Reset forms to default configuration to create a new reference in memory to the forms array so that a re-render occurs*/
        const newFormsInstance = getNewFormsInstance();
        const form = newFormsInstance[formIndex] as RadioButtonFormProps;
        form.optionSelected = true;
        const { radioButtonOptions } = form;

        radioButtonOptions.forEach((radioButton) => {
            radioButton.checked = radioButton.id === id;
            // If the radio button does not have any independent fields i.e., does not have any additional fields to be shown when the radio button is selected, set the input value to the radio button value
            if (radioButton.checked && radioButton.defaultButtonValue) {
                radioButton.inputValue = radioButton.defaultButtonValue;
            }
        });

        setActiveRadioButtons(radioButtonOptions);
        updateForms(radioButtonOptions, { optionSelected: true });
    };

    // Handles input change for independent fields - radio button options with additional fields to be shown when the radio button is selected
    const handleInputChange = (name: FormFieldName, value: InputValue) => {
        const specialFormattingFunction = getSpecialFormattingFunction(name);

        // Apply the special formatting function to the input value, if available
        const independentFieldValue = specialFormattingFunction
            ? specialFormattingFunction(value as string)
            : value;
        // Get the independent field/fields and dependent fields for the radio button option that is currently selected
        const { independentField, independentFields, dependentFields } = getCheckedButtonFields(
            name,
            activeRadioButtons
        );
        // Update the input value for the independent field
        independentField.inputValue = updateFieldInputValue(
            independentField,
            independentFieldValue
        );

        const messageFunction = getErrorMessageFunction(name);
        // If the field has an error message function, then update the error message for the field
        if (messageFunction) {
            // Delayed error message for effect
            setTimeout(() => {
                independentField.errorMessage = messageFunction(
                    independentFieldValue as string,
                    name,
                    independentFields
                ) as string;
                updateForms(activeRadioButtons);
            }, 1000);
        }

        if (dependentFields) {
            // Handle dependent fields to be shown for based on the dropdown selection of an independent field. Dependent fields are added to the independentFields array.
            handleDependentFields({
                independentField,
                independentFieldValue,
                independentFields,
                dependentFields,
            });
        }

        updateForms(activeRadioButtons);
    };

    const applySelectedClass = (radioButton: RadioButtonProps) => {
        return (radioButton.checked && radioButton.independentFields) ? "Selected Line"  : (radioButton.checked ? "Selected" : "");
    };

    return (
        <div>
            {activeRadioButtons.map((radioButton) => {
                return (
                    <Fragment key={radioButton.id}>
                        <div
                            className={`RadioItem ${applySelectedClass(radioButton)} ${formData.userType}`}
                        >
                            <label
                                htmlFor={radioButton.id}
                                className={`RadioLabel ${applySelectedClass(radioButton)}`}
                            >
                                <input
                                    id={radioButton.id}
                                    type="radio"
                                    name="radio"
                                    onChange={() => handleOptionChange(radioButton.id)}
                                    checked={radioButton.checked}
                                />
                                <span>{radioButton.label}</span>
                            </label>
                            {radioButton.checked && radioButton?.independentFields?.length && (
                                <div style={{ padding: "16px" }}>
                                    <FormContent
                                        formFields={radioButton.independentFields}
                                        handleInputChange={handleInputChange}
                                    />
                                </div>
                            )}
                        </div>
                        <div style={{ marginTop: "20px" }}></div>
                    </Fragment>
                );
            })}
            {/* Form level error message displayed if user attempts to submit form without having made any selections */}
            {form.errorMessage && <span className="ErrorMessage">{form.errorMessage}</span>}
        </div>
    );
};

export default RadioButtonsContainer;
