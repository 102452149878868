import { UserType } from "../../../common/constants/defaultInputs";
import {
    FormFieldType,
    FormFieldName,
    FormInputsConfig,
    FormsConfig,
} from "../../../sparrow-ui-library/src/types/form-types";
import { PrequalificationRequestInput } from "../../../sparrow-ui-library/src/types/sparrow-types";
import { amountFormat } from "../../../sparrow-ui-library/src/utils/general-utils";
import { getMaxLoanAmount } from "../../../common/utils/global";
/* NOTE: we use formData as opposed to applicableFormData because loanAmount is a top-level field in formData, 
agnostic if the request is initiated by cosigner*/

export const getFormsConfig = (inputs: FormsConfig): FormInputsConfig => {
    const { formData } = inputs as {
        formData: PrequalificationRequestInput;
    };
    
    return {
        forms: [
            {
                independentFields: [
                    {
                        formFieldType: FormFieldType.LoanAmount,
                        userType: formData?.userType as UserType,
                        id: FormFieldName.LoanAmount,
                        name: FormFieldName.LoanAmount,
                        label: "Loan size",
                        placeholder: "",
                        minValue: 1000,
                        maxValue: getMaxLoanAmount(formData.type),
                        inputValue: amountFormat(formData.loanAmount.amount),
                        backendValue: "",
                        errorMessage: "",
                    },
                ],
            },
        ],
    };
};
