import { FormFieldName } from "../../../types/form-types";
import {
    dateToEpoch,
    includeTimeOfResponse,
    removeSsnFormatting,
    removePhoneNumberFormatting,
} from "./input-format-for-backend-functions";
import { removeDollarFormatting } from "../../general-utils";

export const inputFormatForBackendFunction: { [key: string]: (value: any) => any } = {
    [FormFieldName.DegreesGradDate]: dateToEpoch,
    [FormFieldName.DOB]: dateToEpoch,
    [FormFieldName.ECommunicationAllowed]: includeTimeOfResponse,
    [FormFieldName.EmploymentIncome]: removeDollarFormatting,
    [FormFieldName.ExpandedSearchHoursCompletedCurrentSemester]: (value: string) => Number(value),
    [FormFieldName.ExpandedSearchSchoolYearEndDate]: dateToEpoch,
    [FormFieldName.ExpandedSearchSchoolYearStartDate]: dateToEpoch,
    [FormFieldName.HousingPaymentAmount]: removeDollarFormatting,
    [FormFieldName.IncomeAmount]: removeDollarFormatting,
    [FormFieldName.LenderPrivacyPolicyAllowed]: includeTimeOfResponse,
    [FormFieldName.LoanAmount]: removeDollarFormatting,
    [FormFieldName.PhoneNumber]: removePhoneNumberFormatting,
    [FormFieldName.PhoneNumberMobileCommunicationAllowed]: includeTimeOfResponse,
    [FormFieldName.SSN]: removeSsnFormatting,
    [FormFieldName.WhatsAppNumber]: removePhoneNumberFormatting,
};

export const getFunctionForBackendValueFormat = (fieldName: string) => {
    return inputFormatForBackendFunction[fieldName];
};
