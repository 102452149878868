import { observer } from "mobx-react";
import { LoanRequestCard } from "./LoanRequestCard";
import { LoanRequestRow } from "./LoanRequestRow";
import styles from "./styles.module.scss";
import hatWhiteIcon from "../../../../assets/images/icons/hatWhite.svg";
import cosignerStarWhiteIcon from "../../../../assets/images/icons/cosignerStarWhite.svg";
import hatIcon from "../../../../assets/images/icons/graduationCap.svg";
import cosignerStarIcon from "../../../../assets/images/icons/cosignerStar.svg";

interface LoanRequestContainerProps {
    user?: string;
    date?: string;
    loanAmount?: string;
    id?: string;
    expanded?: boolean;
    isBorrower?: boolean;
    activeId?: string;
}
const getIcon = (isBorrower: boolean, id: string, activeId: string ) => {
    if (isBorrower) {
        return id === activeId ? hatWhiteIcon : hatIcon;    
    }

    return id === activeId ? cosignerStarWhiteIcon : cosignerStarIcon;
}

export const LoanRequestContainer: React.FunctionComponent<LoanRequestContainerProps> = observer(
    ({ user, date, loanAmount, id, activeId, expanded, isBorrower }) => {

        const icon = getIcon(isBorrower as boolean, id as string, activeId as string);

        return (
            <div className={styles["loan-request-container"]}>
                {expanded ? (
                    <LoanRequestCard
                        icon={icon}
                        user={user}
                        date={date}
                        loanAmount={loanAmount}
                        id={id}
                    />
                ) : (
                    <LoanRequestRow icon={icon} user={user} date={date} loanAmount={loanAmount} />
                )}
            </div>
        );
    }
);
