import React, { useState, useEffect, useRef } from "react";
import "./style.scss";

import smallBlackDropdownArrowIcon from "../../../assets/images/icons/smallBlackDropdownArrow.svg";
import addCosignerIcon from "../../../assets/images/icons/addCosigner.svg";

import { OfferHeaderNumberInput } from "./OfferHeaderNumberInput";
import { OfferHeaderSchoolSearch } from "./OfferHeaderSchoolSearch";
import { OfferHeaderIncludedUserInput } from "./OfferHeaderIncludedUserInput";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { StatusLabel } from "../../../app/user-inclusion/components/StatusLabel";
import { UserType } from "../../constants/defaultInputs";
import { getStatusBasedText } from "../../../app/user-inclusion/utils/helper-functions";
import { GenericBubble } from "../Bubble";
import { OfferHeaderTabs } from "./OfferHeaderUtilities";
import { isDefined } from "../../utils/helper_functions/validation-functions";
import { observer } from "mobx-react";
import { CheckCircle } from "../../../sparrow-ui-library/src/assets/icons/check-circle/CheckCircle";
interface OfferHeaderInputProps {
    title: string;
    dropdownTitle: string;
    initialValue: string;
    icon: any;
    tab: string;
    dropIcon?: any;
    id: number;
    setActiveId: Function;
    activeId: number;
}

export const OfferHeaderInputCard: React.FunctionComponent<OfferHeaderInputProps> = observer(
    ({ title, initialValue, dropdownTitle, icon, tab, dropIcon, id, setActiveId, activeId }) => {
        const onboarding = useOnboarding();
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [dropdownIcon, setDropdownIcon] = useState<any>(dropIcon);

        const [cardDisplayValue, setCardDisplayValue] = useState<any>(initialValue);
        const [inputValue, setInputValue] = useState<any>(initialValue);
        const [toggle, setToggle] = useState<boolean>(false);

        const isBorrowerInitiated = onboarding.formData.userType === UserType.BORROWER;

        useEffect(() => {
            if (tab === OfferHeaderTabs.Cosigner && onboarding.formData.includedUser) {
                const { firstName, status } = onboarding.formData.includedUser;
                const invitedUserType =
                    onboarding.formData.userType === UserType.BORROWER
                        ? UserType.COSIGNER
                        : UserType.BORROWER;

                const statusBasedText = getStatusBasedText({
                    invitedUserName: firstName ?? "",
                    invitedUserType,
                    status,
                });
                setCardDisplayValue(statusBasedText.status);
            } else {
                setDropdownIcon(addCosignerIcon);
            }
        }, [onboarding.formData.includedUser]);

        const onChange = (val) => {
            setInputValue(val);
        };

        function renderCorrectType() {
            // This input component is not active.
            if (id !== activeId) {
                setToggle(false);
                return <></>;
            }

            // Loan amount
            if (tab === OfferHeaderTabs.LoanSize) {
                return (
                    <OfferHeaderNumberInput
                        title={dropdownTitle}
                        toggle={toggle}
                        value={inputValue}
                        setToggle={(v) => {
                            setToggle(v);
                            setActiveId(id);
                        }}
                        onChange={onChange}
                        maxValue={1000000}
                    />
                );
            }

            // Annual pre-tax income
            if (tab === OfferHeaderTabs.Income) {
                return (
                    <OfferHeaderNumberInput
                        title={dropdownTitle}
                        toggle={toggle}
                        value={inputValue}
                        setToggle={(v) => {
                            setToggle(v);
                            setActiveId(id);
                        }}
                        onChange={onChange}
                        maxValue={20000000}
                    />
                );
            }

            // University
            if (tab === OfferHeaderTabs.School) {
                return (
                    <OfferHeaderSchoolSearch
                        title={dropdownTitle}
                        toggle={toggle}
                        value={inputValue}
                        setToggle={(v) => {
                            setToggle(v);
                            setActiveId(id);
                        }}
                        onChange={onChange}
                    />
                );
            }

            // Cosigner
            if (tab === OfferHeaderTabs.Cosigner) {
                if (onboarding.formData.includedUser) {
                    const { firstName, status } = onboarding.formData.includedUser!;
                    const invitedUserType =
                        onboarding.formData.userType === UserType.BORROWER
                            ? UserType.COSIGNER
                            : UserType.BORROWER;

                    const statusBasedText = getStatusBasedText({
                        invitedUserName: firstName ?? "",
                        invitedUserType,
                        status,
                    });
                    return (
                        <OfferHeaderIncludedUserInput
                            text={statusBasedText.status}
                            toggle={toggle}
                            onExit={onExit}
                        />
                    );
                }
                return (
                    <OfferHeaderIncludedUserInput
                        text={"Add cosigner"}
                        toggle={toggle}
                        onExit={onExit}
                    />
                );
            }
        }

        function onExit() {
            setToggle(false);
        }

        function renderCardContent() {
            if (tab === OfferHeaderTabs.Cosigner) {
                if (isDefined(onboarding.formData.includedUser)) {
                    const { firstName, status } = onboarding.formData.includedUser!;
                    const invitedUserType =
                        onboarding.formData.userType === UserType.BORROWER
                            ? UserType.COSIGNER
                            : UserType.BORROWER;

                    const statusBasedText = getStatusBasedText({
                        invitedUserName: firstName ?? "",
                        invitedUserType,
                        status,
                    });

                    return (
                        <StatusLabel
                            status={statusBasedText.status}
                            primaryColor={statusBasedText.primaryColor}
                            secondaryColor={statusBasedText.secondaryColor}
                        />
                    );
                }
            }
            return cardDisplayValue;
        }

        const cardRef = useRef<HTMLDivElement>(null);
        const dropDownRef = useRef<HTMLDivElement>(null);
        const positionRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            function handleClickOutside(event) {
                if (
                    dropDownRef.current &&
                    cardRef.current &&
                    !dropDownRef.current.contains(event.target as Node) &&
                    !cardRef.current.contains(event.target as Node)
                ) {
                    setToggle(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [dropDownRef, cardRef]);

    
        return (
            <>
                <div className="header-body-card" ref={positionRef}>
                    <div
                        className="header-body-card-container"
                        ref={cardRef}
                        onClick={() => {
                            setToggle(!toggle);
                            setActiveId(id);
                        }}
                        style={{ cursor: tab === OfferHeaderTabs.Cosigner ? "pointer" : "default" }}
                    >
                        <div className="header-body-card-title-container">
                            <div className="header-body-card-icon">{icon()}</div>
                            <div className="header-body-card-title">
                                {!isBorrowerInitiated && title === "Cosigner" ? "Borrower" : title}
                            </div>
                        </div>
                        <div className="header-body-card-input-container">
                            <div className="header-body-card-input">{renderCardContent()}</div>
                            <div className="header-body-card-input-dropdown">
                                {tab === OfferHeaderTabs.Cosigner && (
                                    <>
                                        {!onboarding.formData.includedUser ? (
                                            <CheckCircle
                                                userType={onboarding.formData.userType as UserType}
                                            />
                                        ) : (
                                            <img
                                                src={dropdownIcon ?? smallBlackDropdownArrowIcon}
                                                alt="dropdownArrow"
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {toggle && tab === OfferHeaderTabs.Cosigner && (
                        <div
                            className="bubble-wrapper bubble-wrapper__cosigner bubble-wrapper__enable-mobile-view"
                            ref={dropDownRef}
                        >
                            <GenericBubble style={{position: "static"}}>{renderCorrectType()}</GenericBubble>
                        </div>
                    )}
                </div>
            </>
        );
    }
);
