import { DependentFormFieldProps, FormFieldProps, DropdownFieldProps, InputValue } from "../../../../types/form-types";


const recursiveRemoval = (dependentField: DependentFormFieldProps | FormFieldProps, dependentFieldIndex: number, formFields: FormFieldProps[]) => {
    // const dependentFieldIndex = formFields.findIndex((field) => field.name === dependentField.name);
    if (dependentFieldIndex === -1) return;

    formFields.splice(dependentFieldIndex, 1)

    if (!("dependentFieldNames" in dependentField && dependentField.dependentFieldNames)) return;

    dependentField.dependentFieldNames.forEach((dependentFieldName) => {
        const dependentFieldIndex = formFields.findIndex((field) => field.name === dependentFieldName);
        const dependentField = formFields[dependentFieldIndex];

        if (dependentField) {
            recursiveRemoval(dependentField, dependentFieldIndex, formFields);
        }
    });
}


export const handleDependentFields = (params: { independentField: DropdownFieldProps, independentFieldValue: InputValue, independentFields: FormFieldProps[], dependentFields: DependentFormFieldProps[] | FormFieldProps[] }) => {
    const { independentField, independentFieldValue, independentFields, dependentFields } = params;
    const { dependentFieldNames} = independentField;
    if (!dependentFieldNames?.length) return;

    dependentFieldNames.forEach((dependentFieldNameImpactedByIndependentField) => {
        /* Find the dependent field in the dependent fields array that matches the dependent field name found 
        in the dependent field names array from the independent field
        */
        const dependentField = dependentFields?.find((dependentField) => dependentField.name === dependentFieldNameImpactedByIndependentField) as DependentFormFieldProps | undefined;
        
        if (!dependentField) return;

        // Check to see if the dependent field is already in the form fields array
        const dependentFieldIndex = independentFields.findIndex((field) => field.name === dependentFieldNameImpactedByIndependentField);
        const dependentFieldIsInFormFields = dependentFieldIndex !== -1;

        const { dependentTriggerValue } = dependentField;
        /*Some dependent fields are triggered by multiple values of an independent field 
        e.g., homeCountry is triggered by multiple values of citizenshipStatus type. Therefore we check to see if this is true*/ 
        const hasManyTriggerValues = Array.isArray(dependentTriggerValue);

        // If the dependent field has only one trigger value
        if (!hasManyTriggerValues) {
            // Check to see if the independent field value matches the dependent field trigger value
            if (independentFieldValue === dependentTriggerValue) {
                // If the dependent field is not already in the form fields array, then add it
                if (!dependentFieldIsInFormFields) return independentFields.push(dependentField);
            } else {
                // If the independent field value does not match the dependent field trigger value, then remove the dependent field from the independentFields array
                return recursiveRemoval(dependentField, dependentFieldIndex, independentFields);
            }
        // If the dependent field has multiple trigger values
        } else {
            // Get the list of trigger values - dependentTriggerValue is an array of DropdownOptions
            const dependentTriggerValues = dependentTriggerValue.map(
                (triggerValue) => triggerValue.value
            );
            // Check to see if the independent field value matches any of the dependent field trigger values
            if (dependentTriggerValues.includes(independentFieldValue as string)) {
                // If the dependent field is not already in the form fields array, then add it
                if (!dependentFieldIsInFormFields) return independentFields.push(dependentField);
            } else {
                // If the independent field value does not match any of the dependent field trigger values, then remove the dependent field from the independentFields array
                return recursiveRemoval(dependentField, dependentFieldIndex, independentFields);
            }
        }
    });
};
