import { ProgressRoadmap } from "../../../models/request";
import { Get, Post, Put } from "../clientApi";
import {
    SparrowApi_PUT_RequestDecline,
    SparrowApi_POST_PUT_Request,
    SparrowApi_PUT_InviteRequestAdd,
    SparrowApi_POST_InviteRequestDelete,
    SparrowApi_PUT_InviteRequestRemind,
    SparrowApi_GET_UserProgressRoadmap,
    SparrowApi_POST_CloneRequest,
    SparrowApi_GET_SingleRequest,
    SparrowApi_POST_AcceptInvite,
    SparrowApi_GET_ExpandedSearchMetadata,
} from "../sparrow-api-endpoints";
import { formVersion } from "../../../utils/global";
import { UserType } from "../../../constants/defaultInputs";
import { enumUserInclusionType } from "../../../store/onboarding";
import { ExpandedSearchDates } from "../../../../sparrow-ui-library/src/types/sparrow-types";
import { PrequalificationRequestInput } from "../../../../sparrow-ui-library/src/types/sparrow-types";
import { MembershipElection } from '../../../../sparrow-ui-library/src/types/sparrow-types/membership-election';

export interface IPostPutPrequalificationRequest {
    userType: UserType;
    formVersion?: number;
    prequalificationRequestPostPutInput: {
        id?: string;
        [key: string]: any;
    };
    token: string;
    requestType?: "POST" | "PUT";
}

export interface IPostPrequalificationSubmitRequest {
    userType: UserType;
    prequalificationRequestPostPutInput: {
        id: string;
        [key: string]: any;
    };
    token: string;
}
export interface IPrequalificationRequestSubmitOutput {
    request: PrequalificationRequestInput;
    isFullySubmitted: boolean; // if true, poll for offers
}

export interface IUserInput {
    firstName: string;
    lastName: string;
    email: string;
    id: string;
}

export interface IPostInviteAddRequest {
    inclusionType: enumUserInclusionType;
    newRequestFields: IUserInput | Partial<PrequalificationRequestInput>;
    token: string;
}

export interface IExpandedSearchMetadata {
    dueDates?: ExpandedSearchDates;
    isOutOfSeason: boolean;
}

export const GetExpandedSearchMetadata = async (): Promise<IExpandedSearchMetadata> => {
    const expandedSearchMetadata = await Get(SparrowApi_GET_ExpandedSearchMetadata);
    return expandedSearchMetadata;
};

export const GetProgressRoadmaps = async (
    token: string
): Promise<{ roadmaps: ProgressRoadmap[], membershipElection: MembershipElection }> => {
    return Get(SparrowApi_GET_UserProgressRoadmap, token);
};

/**
 * Sends a POST or PUT request to the API to create or update a prequalification request
 * @param requestBody: IPostPutPrequalificationRequest
 * @returns IPrequalificationRequest | null
 */
export const PostPutPrequalificationRequest = async (
    requestBody: IPostPutPrequalificationRequest
): Promise<PrequalificationRequestInput | null> => {
    requestBody.formVersion = formVersion;
    const { userType, prequalificationRequestPostPutInput, token, requestType } = requestBody;

    let PrequalificationRequestGetOutput: { request: PrequalificationRequestInput } | null = null;

    if (requestType === "POST") {
        PrequalificationRequestGetOutput = await Post(SparrowApi_POST_PUT_Request, token, {
            ...prequalificationRequestPostPutInput,
            userType: userType,
            formVersion: formVersion,
        });
    } else {
        PrequalificationRequestGetOutput = await Put(SparrowApi_POST_PUT_Request, token, {
            ...prequalificationRequestPostPutInput,
            formVersion: formVersion,
        });
    }

    return PrequalificationRequestGetOutput ? PrequalificationRequestGetOutput.request : null;
};

/**
 * Deletes a loan request
 * @param requestId: string
 * @param token: string
 * @returns Promise <void>
 */
export const PostPrequalificationRequestDecline = async (
    requestId: string,
    token: string
): Promise<void> => {
    await Post(SparrowApi_PUT_RequestDecline, token, { id: requestId });
};
/**
 * Deletes a loan request
 * @param requestId: string
 * @param token: string
 * @returns Promise <void>
 */
export const PostPrequalificationAcceptInvite = async (
    requestId: string,
    token: string
): Promise<void> => {
    try {
        await Post(SparrowApi_POST_AcceptInvite, token, { id: requestId });
    } catch (err) {
        console.error(err);
    }
};

/**
 * Sends a PUT request to the API to include a user to a prequalification request
 *
 * @param requestBody: IPostInviteAddRequest
 * @returns Promise <IPrequalificationRequest>
 *
 */
export const PutRequestInviteAdd = async (
    requestBody: IPostInviteAddRequest
): Promise<PrequalificationRequestInput> => {
    const { inclusionType, newRequestFields, token } = requestBody;
    const PrequalificationRequestOutput = await Put(SparrowApi_PUT_InviteRequestAdd, token, {
        inclusionType,
        newRequestFields,
    });

    return PrequalificationRequestOutput.request;
};
/**
 * Sends a PUT request to the API to delete an included user from a prequalification request
 * @param requestId
 * @param token
 * @returns Promise <IPrequalificationRequest>
 */
export const PostRequestInviteDelete = async (
    requestId: string,
    token: string
): Promise<PrequalificationRequestInput> => {
    const PrequalificationRequestOutput = await Post(SparrowApi_POST_InviteRequestDelete, token, {
        id: requestId,
    });

    return PrequalificationRequestOutput.request;
};

/**
 * Sends a PUT request to the API to remind an included user from a prequalification request
 * @param requestId
 * @param token
 * @returns Promise <void>
 *
 */
export const PutRequestInviteRemind = async (requestId: string, token: string): Promise<void> => {
    await Put(SparrowApi_PUT_InviteRequestRemind, token, {
        id: requestId,
    });
};

/**
 * Clones a loan request. Optionally changes loan amount in new request.
 * @param {number | undefined} params.loanAmount loan amount for new request
 * @param {string} params.requestId ID of request to be cloned
 * @param {string} params.token
 * @return {Promise<IPrequalificationRequest>} new request
 */
export const PostClonePrequalificationRequest = async (params: {
    loanAmount?: number;
    requestId: string;
    token: string;
}): Promise<PrequalificationRequestInput> => {
    return Post(SparrowApi_POST_CloneRequest, params.token, {
        loanAmount: params.loanAmount,
        id: params.requestId,
    });
};

/**
 * Retrieves a single loan request by ID.
 * @param {string} params.requestId ID of request to get
 * @param {string} params.token
 * @return {Promise<IPrequalificationRequest>} new request
 */
export const GetPrequalificationRequest = async (params: {
    requestId: string;
    token: string;
}): Promise<PrequalificationRequestInput> => {
    const getRequestOutput = await Get(
        SparrowApi_GET_SingleRequest,
        params.token,
        params.requestId
    );
    return getRequestOutput.request;
};
