import { isMarketplaceEnv } from "../../../utils/config";
import { LeftNavigationExpandedMenu } from "./LeftNavigationExpandedMenu";
import { LeftNavigationFAQButton } from "./Buttons/LeftNavigationFAQButton";
import { LeftNavigationDashboardButton } from "./Buttons/LeftNavigationDashboardButton";
import { LeftNavigationCustomButtons } from "./Buttons/LeftNavigationCustomButtons";
import { LeftNavigationStartRequestButton } from "./Buttons/LeftNavigationStartRequestButton";
import { MenuItems } from "../../../types/paaas/custom-menu-props";
import { borrowerOffersRoutingUrl } from "../../../core/routes/routing-urls";
import { useCheckPathName } from "../../../../sparrow-ui-library/src/hooks";
import { Button } from "../../buttons/button/Button";
import { ButtonTypes } from "../../buttons/button/Button";
import { UserType } from "../../../../sparrow-ui-library/src/types/sparrow-types";
import { useNavigate } from "react-router-dom";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import { dashboardUrl, loginStepBorrower } from "../../../core/routes/navigation-urls";
import { useLocation } from "react-router-dom";
import { isPAAASLandingUrl } from "../../../utils/routing";

interface ILeftNavigationMenuTop {
    menuExpand: boolean;
    menuExpandDropdown?: MenuItems;
    handleClick: () => void;
    setMenuExpand: Function;
    getMenuItemClassAndFill: Function;
    setMenuExpandDropdown: Function;
    setShowSideBarHandlerForMobile: Function;
}

export const LeftNavigationMenuTop = ({
    menuExpand,
    menuExpandDropdown,
    handleClick,
    setMenuExpand,
    getMenuItemClassAndFill,
    setMenuExpandDropdown,
    setShowSideBarHandlerForMobile,
}: ILeftNavigationMenuTop) => {
    const isRatePage = useCheckPathName(borrowerOffersRoutingUrl);
    const navigate = useNavigate();
    const {jwt} = useOnboarding();

    const isPAAASLandingPage = isPAAASLandingUrl(useLocation().pathname);

    const showStartRequestBtn = !isPAAASLandingUrl(useLocation().pathname) && !isRatePage;

    return (
        <div className={"left-navigation-menu-top"}>
            <div
                className={`left-navigation-menu-expanded replaced ${menuExpand ? "expanded" : ""}`}
            >
                <LeftNavigationExpandedMenu menuExpandDropdown={menuExpandDropdown} />
            </div>
            <div
                className={`left-navigation-menu-expanded not-replaced ${
                    menuExpand ? "expanded" : ""
                }`}
            >
                <ul>
                    {!isPAAASLandingPage && <LeftNavigationDashboardButton
                        setMenuExpandDropdown={setMenuExpandDropdown}
                        setMenuExpand={setMenuExpand}
                        setShowSideBarHandlerForMobile={setShowSideBarHandlerForMobile}
                    />}
                    {!isMarketplaceEnv() && !isRatePage && (
                        <LeftNavigationCustomButtons
                            setMenuExpandDropdown={setMenuExpandDropdown}
                            setMenuExpand={setMenuExpand}
                        />
                    )}
                    {isMarketplaceEnv() && (
                        <LeftNavigationFAQButton
                            getMenuItemClassAndFill={getMenuItemClassAndFill}
                        />
                    )}
                </ul>
                { showStartRequestBtn && (
                    <LeftNavigationStartRequestButton handleClick={handleClick} />
                )}
                {isPAAASLandingUrl(useLocation().pathname) && (
                    <Button
                        buttonType={ButtonTypes.PRIMARY}
                        userType={UserType.BORROWER}
                        showIcon={true}
                        onClick={() => navigate(jwt ? dashboardUrl : loginStepBorrower)}
                        float="left"
                        style={{marginTop: "32px"}}
                    >
                        {jwt ? "Dashboard" : "Sign In"}
                    </Button>
                )}
            </div>
        </div>
    );
};
