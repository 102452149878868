import { observer } from "mobx-react";
import { useEffect } from "react";
import { useStore } from "../../common/core/providers/global.provider";
import { useOnboarding } from "../../common/core/providers/onboarding.provider";
import { LoadingOffersPage } from "../../common/views/offers/components/loading_offers";
import { Loading } from "../../common/components/loading";
import { getActivePrequalSteps } from "../../common/core/global_routing/route-steps";
import { useLocation, useNavigate } from "react-router-dom";
import { completeSignUpBase } from "../../common/core/routes/routing-urls";
import { PrequalificationRoutes } from "./routes";
import { getStepData } from "./utils/get-step-data";
import ContentLayout from "../../common/components/layouts/content-layout";
import { UserType } from "../../common/constants/defaultInputs";
import "../../common/components/CosignerCard/style.scss";
import "../../common/views/review/style.scss";

export const PrequalificationWrapper = observer(() => {
    const onboarding = useOnboarding();
    const routePathname = useLocation().pathname;
    const navigate = useNavigate();

    const { showOffersLoader, fetchFormFieldsValues, setActiveSteps } = useOnboarding();
    const store = useStore();

    if (store.userHasMissingFields) {
        navigate(completeSignUpBase, { replace: true });
        return <Loading shown />;
    }

    useEffect(() => {
        const activeSteps = getActivePrequalSteps(onboarding);
        setActiveSteps(activeSteps);
    }, [
        onboarding.formData,
        onboarding.creatorIsIncludingUser,
        onboarding.formData.isIncludedUser,
        onboarding.formData.type,
        onboarding.useShortSteps,
    ]);

    useEffect(() => {
        // legacy - gets values for select fields e.g. degrees, visaTypes, etc.
        fetchFormFieldsValues();
    }, []);

    const checkDisplayProgressBar = () => {
        const progressBarSteps = onboarding.activeSteps.slice(0, onboarding.activeSteps.length - 1);
        return progressBarSteps.length > 1 && progressBarSteps.includes(routePathname);
    };

    const stepDataInputs = {
        userType: onboarding.formData.userType as UserType,
        pathName: routePathname,
        onboarding,
    };

    const stepData = getStepData(stepDataInputs);
    
    const displayProgressBar = checkDisplayProgressBar();
    const activeProgressBar = onboarding.activeSteps.slice(0, onboarding.activeSteps.length - 1);
    const currentStep = activeProgressBar.indexOf(routePathname);



    return (
        <>
            {showOffersLoader && <LoadingOffersPage />}
            <ContentLayout
            pageText={stepData?.pageText as { title: string; subtitle: string; }}
            progressBarData={{displayProgressBar, activeProgressBar, currentStep}}
            >
                <PrequalificationRoutes 
                pathName={routePathname}
                />
            </ContentLayout>
        </>
    );
});
