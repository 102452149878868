export enum FormFieldType {
    AddressAutocomplete = "AddressAutocomplete",
    InputDropdown = "InputDropdown",
    LastNameField = "LastNameField",
    LoanAmount = "LoanAmount",
    NameField = "NameField",
    PhoneInput = "PhoneInput",
    ReadonlyDropdown = "Dropdown",
    TextBox = "TextBox",
    ToggleSwitch = "ToggleSwitch",
}