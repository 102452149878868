import { BrowserRouter } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import useInitViewportHeight from "./hooks/use-init-viewport-height";
import { GlobalStoreProvider } from "./common/core/providers/global.provider";
import { StoreProvider } from "./common/core/providers/onboarding.provider";
import {
    AwsAuthInitializationProvider,
    CookiesInitializationProvider,
} from "./common/components/app-providers";
import AppStylesWrapper from "./common/components/app-styles-wrapper";
import { Loader } from "./common/components/loading";
import ScrollToTop from "./common/components/ScrollToTop";
import { GlobalRoutes } from "./app/routes";
import { GlobalModal } from "./common/components/modals/globalModal";
import { observer } from "mobx-react";
import SparrowProvider from "./sparrow-ui-library/src/ui-components/sparrow-provider";
import { AppTitle } from "./common/components/app-title";

const InnerComponents = () => {
    const sharedComponents = (
        <>
            <ScrollToTop />
            <GlobalRoutes />
            <GlobalModal />
        </>
    );
    
    return (
        <IntercomProvider
            appId={process.env.REACT_APP_INTERCOM_APP_ID as string}
            autoBoot
            initializeDelay={3000}
        >
            {sharedComponents}
        </IntercomProvider>
    );
};

const App = observer(() => {
    // Captures the height of the viewport on render and sets it as a CSS variable
    useInitViewportHeight();

    return (
        <CookiesInitializationProvider>
            <GlobalStoreProvider>
                <StoreProvider>
                    <AwsAuthInitializationProvider>
                        <SparrowProvider>
                            <AppStylesWrapper>
                                <AppTitle />
                                <BrowserRouter>
                                    <InnerComponents />
                                    <Loader />
                                </BrowserRouter>
                            </AppStylesWrapper>
                        </SparrowProvider>
                    </AwsAuthInitializationProvider>
                </StoreProvider>
            </GlobalStoreProvider>
        </CookiesInitializationProvider>
    );
});

export default App;
