import React, { useEffect } from 'react';

export const useHandleDropdownBlur = (
  isDropdownOpen: boolean,
  setIsDropdownOpen: (isDropdownOpen: boolean) => void,
  dropdownRef: React.RefObject<HTMLUListElement>
) => {
  useEffect(() => {
    const handleBlur = (event: FocusEvent) => {
      const isFocusInsideDropdown = dropdownRef.current?.contains(event.target as Node);
      if (!isFocusInsideDropdown) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('focus', handleBlur, true);

    return () => {
      document.removeEventListener('focus', handleBlur, true);
    };
  }, [isDropdownOpen, setIsDropdownOpen, dropdownRef]);
};
