import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ButtonWrapper } from "../../../common/components/buttons/button/ButtonWrapper";
import { Button } from "../../../common/components/buttons/button/Button";
import { ButtonTypes } from "../../../common/components/buttons/button/Button";
import { UserType } from "../../../common/constants/defaultInputs";
import { PrequalUrlsEnum } from "../../../common/core/global_routing/front-end-urls";
import { useOnboarding } from "../../../common/core/providers/onboarding.provider";

interface NavigationFooterProps {
    userType: UserType;
    steps: {prevStep: string | undefined, nextStep: string | undefined};
    handleSubmit: () => void;
}

export const NavigationFooter = (props: NavigationFooterProps) => {
    const onboarding = useOnboarding();
    const navigate = useNavigate();
    const pathname = useLocation().pathname;

    const prevButtonProps = {
        onClick: () => navigate(props.steps.prevStep as string),
        buttonType: ButtonTypes.SECONDARY,
        userType: props.userType,
    };

    const nextButtonProps = {
        onClick: props.handleSubmit,
        buttonType: ButtonTypes.PRIMARY,
        userType: props.userType,
        showIcon: true,
    };

    const displayPrevButton = !!props.steps.prevStep;

    const nextButtonText = () => {
        if(pathname === PrequalUrlsEnum.REVIEW && onboarding.isRequestSubmission()){
            return "I agree and submit"
        }

        if(pathname === PrequalUrlsEnum.EXPANDED){
            return "Submit"
        }

        if(onboarding.reviewStepReached && (pathname !== PrequalUrlsEnum.REVIEW && pathname !== PrequalUrlsEnum.EXPANDED)){
            return "Return to review"
        }

        return "Continue"
    }
     
    return (
        <ButtonWrapper>
            {displayPrevButton && <Button id="previous-button" {...prevButtonProps}> Previous step </Button>}
            <Button id="next-button" {...nextButtonProps}>{nextButtonText()}</Button>
        </ButtonWrapper>
    );
};
