"use client";
import { useEffect, useState } from "react";
import { InputText } from "../../common/components/inputs/input_text/input_text";
import { useStore } from "../../common/core/providers/global.provider";
import { EmailEXP } from "../../common/utils/global";
import { useOnboarding } from "../../common/core/providers/onboarding.provider";
import { RegularButtonWithLoading } from "../../common/components/buttons/regular_button/RegularButtonWithLoading";
import { useNavigate } from "react-router-dom";
import { confirmEmailResetBase } from "../../common/core/routes/routing-urls";
import { AuthHandler } from "../../common/core/auth";

export const ResetPassword = () => {
    const { setAuthSnackError } = useStore();
    const { setRouteState, paaasCustomization } = useOnboarding();
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [valid, setIsValid] = useState(false);
    const [loading, setLoading] = useState(false);
    let timeout: NodeJS.Timer;

    const navigate = useNavigate();

    const RegEXP = EmailEXP;

    const onEmailChange = (value: string) => {
        const errorMess = !value.match(RegEXP) && value.length > 0 ? "Email is not valid" : "";
        setEmailError(errorMess);
        setEmail(value);
        setIsValid(value.length > 0 && !errorMess.length);
    };

    const onSubmit = async () => {
        setLoading(true);
        try {
            await AuthHandler.forgotPassword(email, paaasCustomization?.partnerId);
            setAuthSnackError("Verification code sent", "success");
            setRouteState({ email: email });
            timeout = setTimeout(() => {
                setLoading(false);
                navigate(confirmEmailResetBase);
            }, 2000);
        } catch (e) {
            setAuthSnackError("Email doesn't exist", "error");
            timeout = setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    };

    useEffect(() => {
        return () => clearTimeout(timeout);
    }, []);

    return (
        <div className="auth-flow-container--inner">
            <div className="step-form-header">
                <h2 className="h2">Reset your password</h2>
                <div className="subtitle">
                    Enter the email address associated with your account and we'll send you a link
                    to reset your password.
                </div>
            </div>

            <div className="step-form-body">
                <div className="step-form-block">
                    <div className="form-row">
                        <InputText
                            value={email}
                            name="email"
                            type="email"
                            label="Email"
                            placeholder="Your Email"
                            error={emailError}
                            onChange={(e: any) => onEmailChange(e.target.value)}
                            onClear={() => onEmailChange("")}
                        />
                    </div>
                </div>
            </div>

            <div className="step-form-footer">
                <div className="button-group text-center">
                    <RegularButtonWithLoading
                        loading={loading}
                        onSubmit={onSubmit}
                        disabled={!valid}
                    >
                        Continue
                    </RegularButtonWithLoading>
                </div>
            </div>
        </div>
    );
};
