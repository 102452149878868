"use client";
import React, { useEffect, useRef, useState } from "react";
import { OfferFilterTab } from "./OfferFilterTab";

import loanTypeFilterDefault from "../../../assets/images/icons/loanTypeFilterDefault.svg";
import bookmarkFilterDefault from "../../../assets/images/icons/bookmarkFilterDefault.svg";
import rateTypeFilterDefault from "../../../assets/images/icons/rateTypeFilterDefault.svg";
import repaymentPlanFilterDefault from "../../../assets/images/icons/repaymentPlanFilterDefault.svg";
import lenderFilterDefault from "../../../assets/images/icons/lenderFilterDefault.svg";
import termFilterDefault from "../../../assets/images/icons/termFilterDefault.svg";
import sapFilterDefault from "../../../assets/images/icons/sapFilterDefault.svg";
import pastDueBalancesFilterDefault from "../../../assets/images/icons/pastDueBalancesFilterDefault.svg";

import loanTypeFilterActive from "../../../assets/images/icons/loanTypeFilterActive.svg";
import bookmarkFilterActive from "../../../assets/images/icons/bookmarkFilterActive.svg";
import rateTypeFilterActive from "../../../assets/images/icons/rateTypeFilterActive.svg";
import repaymentPlanFilterActive from "../../../assets/images/icons/repaymentPlanFilterActive.svg";
import lenderFilterActive from "../../../assets/images/icons/lenderFilterActive.svg";
import termFilterActive from "../../../assets/images/icons/termFilterActive.svg";
import sapFilterActive from "../../../assets/images/icons/sapFilterActive.svg";
import pastDueBalancesFilterActive from "../../../assets/images/icons/pastDueBalancesFilterActive.svg";

import XIcon from "../../../assets/images/icons/XIconHover.svg";

import "./style.scss";
import { OfferFiltersOptions, OfferFilterTabs } from "./utilities";
import { isDefined } from "../../utils/helper_functions/validation-functions";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { useMediaQuery } from "react-responsive";
import { observer } from "mobx-react";
import MobileFilter from "./MobileFilter";
import { LenderTypeIcon, LoanTypeIcon, PastDueIcon, RateTypeIcon, RepaymentPlanIcon, SatisfactoryIcon, TermIcon } from "../../../sparrow-ui-library/src/assets/icons/pq-decisions-icons/filter-icons";

const filterConfig = [
    {
        name: OfferFilterTabs.LoanType,
        defaultIcon: loanTypeFilterDefault,
        activeIcon: loanTypeFilterActive,
        icon: (props) => <LoanTypeIcon {...props} />,
    },
    {
        name: OfferFilterTabs.RateType,
        defaultIcon: rateTypeFilterDefault,
        activeIcon: rateTypeFilterActive,
        icon: (props) => <RateTypeIcon {...props} />,
    },
    {
        name: OfferFilterTabs.RepaymentPlan,
        defaultIcon: repaymentPlanFilterDefault,
        activeIcon: repaymentPlanFilterActive,
        icon: (props) => <RepaymentPlanIcon {...props} />,
    },
    {
        name: OfferFilterTabs.Lender,
        defaultIcon: lenderFilterDefault,
        activeIcon: lenderFilterActive,
        icon: (props) => <LenderTypeIcon {...props} />,
    },
    {
        name: OfferFilterTabs.Term,
        defaultIcon: termFilterDefault,
        activeIcon: termFilterActive,
        icon: (props) => <TermIcon {...props} />,
    },
    {
        name: OfferFilterTabs.SAP,
        defaultIcon: sapFilterDefault,
        activeIcon: sapFilterActive,
        icon: (props) => <SatisfactoryIcon {...props} />,
    },
    {
        name: OfferFilterTabs.PastDueBalances,
        defaultIcon: pastDueBalancesFilterDefault,
        activeIcon: pastDueBalancesFilterActive,
        icon: (props) => <PastDueIcon {...props} />,
    },
];

interface OfferFilterProps {
    filterValues: {
        sapCheckboxMapping: {};
        lenderCheckboxMapping: {};
        pastDueBalanceCheckboxMapping: {};
        termCheckboxMapping: {};
        paymentPlanCheckboxMapping: {};
        loanTypeCheckboxMapping: {};
        rateTypeCheckboxMapping: {};
    };
    filterSetters: {
        setSAPCheckboxMapping: Function;
        setLenderCheckboxMapping: Function;
        setPastDueBalanceCheckboxMapping: Function;
        setTermCheckboxMapping: Function;
        setPaymentPlanCheckboxMapping: Function;
        setLoanTypeCheckboxMapping: Function;
        setRateTypeCheckboxMapping: Function;
    };
    filterOptions: OfferFiltersOptions;
    updateFilterOptions: Function;
    hasBookmarked: boolean;
    bookmarkActive: boolean;
    setBookmarkActive: Function;
}

export const OfferFilters: React.FunctionComponent<OfferFilterProps> = observer(
    ({
        filterOptions,
        updateFilterOptions,
        filterValues,
        filterSetters,
        hasBookmarked,
        bookmarkActive,
        setBookmarkActive,
    }) => {
        const { formData, setShowMobileFilter, showMobileFilter, setHasActiveFilters } =
            useOnboarding();

        const {
            sapCheckboxMapping,
            lenderCheckboxMapping,
            pastDueBalanceCheckboxMapping,
            termCheckboxMapping,
            paymentPlanCheckboxMapping,
            loanTypeCheckboxMapping,
            rateTypeCheckboxMapping,
        } = filterValues;

        const {
            setSAPCheckboxMapping,
            setLenderCheckboxMapping,
            setPastDueBalanceCheckboxMapping,
            setTermCheckboxMapping,
            setPaymentPlanCheckboxMapping,
            setLoanTypeCheckboxMapping,
            setRateTypeCheckboxMapping,
        } = filterSetters;

        function clearFilters() {
            updateFilterOptions({
                sapOptions: [],
                lenderOptions: [],
                pastDueBalanceOptions: [],
                termOptions: [],
                paymentPlanOptions: [],
                loanTypeOptions: [],
                rateTypeOptions: [],
                otherOptions: [],
            });
            setBookmarkActive(false);
            setSAPCheckboxMapping(resetMapping(sapCheckboxMapping));
            setLenderCheckboxMapping(resetMapping(lenderCheckboxMapping));
            setPastDueBalanceCheckboxMapping(resetMapping(pastDueBalanceCheckboxMapping));
            setTermCheckboxMapping(resetMapping(termCheckboxMapping));
            setPaymentPlanCheckboxMapping(resetMapping(paymentPlanCheckboxMapping));
            setLoanTypeCheckboxMapping(resetMapping(loanTypeCheckboxMapping));
            setRateTypeCheckboxMapping(resetMapping(rateTypeCheckboxMapping));
            setHasActiveFilters(false);
        }

        function resetMapping(mapping: { [key: string]: String }) {
            const newMapping = {};
            for (const key in mapping) {
                newMapping[key] = false;
            }
            return newMapping;
        }

        function hasFilters(name: OfferFilterTabs) {
            switch (name) {
                case OfferFilterTabs.RepaymentPlan:
                    return (
                        Object.keys(paymentPlanCheckboxMapping).length > 1 &&
                        formData.type === "PSL"
                    );
                case OfferFilterTabs.SAP:
                    return Object.keys(sapCheckboxMapping).length > 1 && formData.type === "PSL";
                case OfferFilterTabs.Lender:
                    return Object.keys(lenderCheckboxMapping).length > 1;
                case OfferFilterTabs.PastDueBalances:
                    return Object.keys(pastDueBalanceCheckboxMapping).length > 1;
                case OfferFilterTabs.RateType:
                    return Object.keys(rateTypeCheckboxMapping).length > 1;
                case OfferFilterTabs.LoanType:
                    return Object.keys(loanTypeCheckboxMapping).length > 1;
                case OfferFilterTabs.Term:
                    return Object.keys(termCheckboxMapping).length > 1;
                default:
                    return false;
            }
        }

        function hasAnyFilter() {
            return (
                !!filterOptions.sapOptions.length ||
                !!filterOptions.lenderOptions.length ||
                !!filterOptions.pastDueBalanceOptions.length ||
                !!filterOptions.termOptions.length ||
                !!filterOptions.paymentPlanOptions.length ||
                !!filterOptions.loanTypeOptions.length ||
                !!filterOptions.rateTypeOptions.length ||
                !!filterOptions.otherOptions?.length
            );
        }

        function getCheckboxMapping(name: OfferFilterTabs) {
            switch (name) {
                case OfferFilterTabs.RepaymentPlan:
                    return {
                        mapping: paymentPlanCheckboxMapping,
                        setter: setPaymentPlanCheckboxMapping,
                    };
                case OfferFilterTabs.SAP:
                    return {
                        mapping: sapCheckboxMapping,
                        setter: setSAPCheckboxMapping,
                    };
                case OfferFilterTabs.Lender:
                    return {
                        mapping: lenderCheckboxMapping,
                        setter: setLenderCheckboxMapping,
                    };
                case OfferFilterTabs.PastDueBalances:
                    return {
                        mapping: pastDueBalanceCheckboxMapping,
                        setter: setPastDueBalanceCheckboxMapping,
                    };
                case OfferFilterTabs.RateType:
                    return {
                        mapping: rateTypeCheckboxMapping,
                        setter: setRateTypeCheckboxMapping,
                    };
                case OfferFilterTabs.LoanType:
                    return {
                        mapping: loanTypeCheckboxMapping,
                        setter: setLoanTypeCheckboxMapping,
                    };
                case OfferFilterTabs.Term:
                    return {
                        mapping: termCheckboxMapping,
                        setter: setTermCheckboxMapping,
                    };
                default:
                    return {
                        mapping: {},
                        setter: () => {},
                    };
            }
        }
        const [openMapping, setOpenMapping] = useState<{ [Key: string]: boolean }>({});
        useEffect(() => {
            closeAllFilters();
        }, []);

        function closeAllFilters() {
            const nextOpenMapping = {};
            filterConfig.forEach((config) => {
                nextOpenMapping[config.name] = false;
            });
            setOpenMapping(nextOpenMapping);
        }

        function openSingleFilter(filter: string) {
            const nextOpenMapping = {};
            filterConfig.forEach((config) => {
                if (config.name === filter) {
                    nextOpenMapping[config.name] = true;
                } else {
                    nextOpenMapping[config.name] = false;
                }
            });
            setOpenMapping(nextOpenMapping);
        }
        const positionRef = useRef<HTMLInputElement>(null);

        // Media Constants
        const isLess767px = useMediaQuery({
            query: "(max-width: 767px)",
        });
        // Bookmark Handler
        const bookmarkHandler = () => {
            if (bookmarkActive) {
                // remove bookmark filter
                const newOtherOptions: string[] = [];
                if (isDefined(filterOptions.otherOptions)) {
                    for (const otherOption of filterOptions!.otherOptions!) {
                        if (otherOption !== "bookmarked") {
                            newOtherOptions.push(otherOption);
                        }
                    }
                }

                updateFilterOptions({
                    ...filterOptions,
                    otherOptions: newOtherOptions,
                });
            } else {
                // add bookmark filter
                updateFilterOptions({
                    ...filterOptions,
                    otherOptions: isDefined(filterOptions.otherOptions)
                        ? [...filterOptions!.otherOptions!, "bookmarked"]
                        : ["bookmarked"],
                });
            }

            setBookmarkActive(!bookmarkActive);
        };

        const hasFiltersMapping = filterConfig.map((config) => hasFilters(config.name));
        const firstFilterWithMapping = hasFiltersMapping.findIndex((hasFilter) => hasFilter);

        return (
            <>
                {isLess767px ? (
                    <>
                        <MobileFilter
                            showMobileFilter={showMobileFilter}
                            setShowMobileFilter={setShowMobileFilter}
                            hasBookmarked={hasBookmarked}
                            bookmarkActive={bookmarkActive}
                            bookmarkHandler={bookmarkHandler}
                            filterConfig={filterConfig}
                            hasFilters={hasFilters}
                            getCheckboxMapping={getCheckboxMapping}
                            filterOptions={filterOptions}
                            updateFilterOptions={updateFilterOptions}
                            closeAllFilters={closeAllFilters}
                            openSingleFilter={closeAllFilters}
                            positionRef={positionRef}
                            clearFilters={clearFilters}
                            openMapping={openMapping}
                        />
                    </>
                ) : (
                    <>
                        <div className="offer-filter-row-container" ref={positionRef}>
                            <div className="offer-filter-row-container-list">
                                {hasAnyFilter() && (
                                    <div className="filter-container">
                                        <div
                                            className={`offer-filter-container clear-filters`}
                                            onClick={() => clearFilters()}
                                        >
                                            <div className="offer-filter-name">Clear filters</div>
                                            <div className="offer-filter-icon">
                                                <img src={XIcon} alt="icon" />
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {hasBookmarked && (
                                    <div className={"filter-container"}>
                                        <div
                                            className={`offer-filter-container ${
                                                bookmarkActive
                                                    ? "selected"
                                                    : hasAnyFilter()
                                                    ? ""
                                                    : "first-item"
                                            } ${hasBookmarked ? "bookmark" : ""}`}
                                            onClick={bookmarkHandler}
                                        >
                                            <div className="offer-filter-icon">
                                                <img
                                                    src={
                                                        bookmarkActive
                                                            ? bookmarkFilterActive
                                                            : bookmarkFilterDefault
                                                    }
                                                    alt="icon"
                                                />
                                            </div>
                                            <div className="offer-filter-name">Bookmarked</div>
                                        </div>
                                    </div>
                                )}
                                {filterConfig.map((config, index) => {
                                    const shouldRender = hasFiltersMapping[index];
                                    const isFirst = index === firstFilterWithMapping;

                                    return (
                                        <div
                                            key={config.name}
                                            className={
                                                shouldRender
                                                    ? `offer-filter-row-filter ${
                                                          isFirst && !hasBookmarked
                                                              ? "first-item"
                                                              : ""
                                                      }`
                                                    : "offer-filter-row-empty-filter"
                                            }
                                        >
                                            {shouldRender && (
                                                <OfferFilterTab
                                                    config={config}
                                                    checkboxMapping={
                                                        getCheckboxMapping(config.name).mapping
                                                    }
                                                    setCheckboxMapping={
                                                        getCheckboxMapping(config.name).setter
                                                    }
                                                    filterOptions={filterOptions}
                                                    updateFilterOptions={updateFilterOptions}
                                                    isOpen={openMapping[config.name]}
                                                    closeAllFilters={closeAllFilters}
                                                    openSingleFilter={openSingleFilter}
                                                    maxXPos={
                                                        positionRef?.current
                                                            ? positionRef?.current?.clientWidth
                                                            : 0
                                                    }
                                                />
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                            <div id={"filter-dropdown-container"} style={{ width: "100%" }} />
                        </div>
                    </>
                )}
            </>
        );
    }
);
