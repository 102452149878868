import { useOnboarding } from "../../core/providers/onboarding.provider";
import { DeclineReasonsDisplay } from "./DeclineReasonsDisplay/DeclineReasonsDisplay";
import { DeclinedLendersContainer } from "./DeclinedLendersContainer/DeclinedLendersContainer";

/*
Description: This component contains the RateTablesContainer and DeclineReasonsDisplay components. It is used on the Pq Decisions page.

Figma (sample): https://www.figma.com/file/Vwc8KmfCQ31VS28jOwBwYM/2.1.Sparrow.Borrower.Portal.NEW.Flows?type=design&node-id=4141-258107&t=mT9XKv5WS4l8r9j3-0
As of: 5/15/2023
*/

//NOTE: RateTables should display above the DeclineReasonsDisplay if there are no offers and below if there are offers

interface UnableToPrequalifyProps {
    applyDirectlyRef: any;
}

export const UnableToPrequalifyContainer: React.FunctionComponent<UnableToPrequalifyProps> = ({
    applyDirectlyRef,
}) => {
    const onboarding = useOnboarding();

    const hasDeclineReasons =
        !!onboarding.pqRequestResults?.declineInformation.reasonsFailed.length;

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            {hasDeclineReasons && <DeclinedLendersContainer />}

            <DeclineReasonsDisplay applyDirectlyRef={applyDirectlyRef} />
        </div>
    );
};
