import { useMediaQuery } from "react-responsive";

import { OfferDetail } from "../OfferCardUtilities";
import { OfferCardBodyDetail } from "./OfferCardBodyDetail";

import "../style.scss";

interface DetailsProps {
    offerDetails: OfferDetail[];
    lenderDisclosure: string;
}

export const OfferCardBodyDetailSection: React.FunctionComponent<DetailsProps> = ({
    offerDetails,
    lenderDisclosure,
}) => {
    const isMore1200px = useMediaQuery({
        query: "(min-width: 1200px)",
    });
    const offerDetailsToRender = isMore1200px
        ? offerDetails
        : offerDetails.slice(3, offerDetails.length);
    return (
        <>
            {offerDetailsToRender.map((detail) => (
                <OfferCardBodyDetail
                    key={detail.title}
                    detail={detail}
                    lenderDisclosure={lenderDisclosure}
                />
            ))}
        </>
    );
};
