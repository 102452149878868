import { faqUrl } from "../../../../core/routes/navigation-urls";

import { LEFT_NAVIGATION_BAR_ICONS } from "../../../../../assets/icons/SVG_Left_Nav";
import { SvgIcon } from "../../../../../assets/icons/SvgIcons";

import "./styles.scss";

interface ILeftNavigationFAQButton {
    getMenuItemClassAndFill: Function;
}

export const LeftNavigationFAQButton = ({ getMenuItemClassAndFill }: ILeftNavigationFAQButton) => {
    return (
        <li className={`${getMenuItemClassAndFill(faqUrl).class}`}>
            <a href={faqUrl} target="_blank" rel="noreferrer">
                <SvgIcon
                    pathDefinition={LEFT_NAVIGATION_BAR_ICONS.FAQ}
                    styles={{
                        fill: `${getMenuItemClassAndFill(faqUrl).fill}`,
                    }}
                />
                <span>FAQs</span>
            </a>
        </li>
    );
};
