"use client";
import React, { memo } from "react";

import { RateCardDefault } from "./variations/default/RateCardDefault";

import "./style.scss";
import { IOffer, IIncomeBasedOffer } from "../../core/services/types/offers-api-types";
import { OffersTablePreferencesFrontend } from "../../../sparrow-ui-library/src/types/sparrow-types/offers-table-preferences";

interface OfferCardProps {
    offer: IOffer | IIncomeBasedOffer;
    staredCount: number;
    staredChange: Function;
    onPinned: Function;
    bookmarkedCount: number;
    bookmarkedChange: Function;
    onBookmarked: Function;
    lenderDisclosure: string;
    pinnedOfferIndex?: number;
    bestRateLabel?: OffersTablePreferencesFrontend;
    chartSelectedRate: number | null;
    setChartSelectedRate: (v: number | null) => void;
    isDefault: boolean;
}

const OfferCard: React.FunctionComponent<OfferCardProps> = ({
    offer,
    staredCount,
    staredChange,
    onPinned,
    bookmarkedCount,
    bookmarkedChange,
    onBookmarked,
    lenderDisclosure,
    pinnedOfferIndex,
    bestRateLabel,
    chartSelectedRate,
    setChartSelectedRate,
    isDefault,
}) => {
    return isDefault ? (
        <RateCardDefault
            offer={offer}
            staredCount={staredCount}
            staredChange={staredChange}
            onPinned={onPinned}
            bookmarkedCount={bookmarkedCount}
            bookmarkedChange={bookmarkedChange}
            onBookmarked={onBookmarked}
            lenderDisclosure={lenderDisclosure}
            pinnedOfferIndex={pinnedOfferIndex}
            bestRateLabel={bestRateLabel}
            chartSelectedRate={chartSelectedRate}
            setChartSelectedRate={setChartSelectedRate}
        />
    ) : (
        <div></div>
    );
};

export default memo(OfferCard);
