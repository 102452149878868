const months: {
    [Key: string]: number;
} = {
    Jan: 1,
    Feb: 2,
    Mar: 3,
    Apr: 4,
    May: 5,
    Jun: 6,
    Jul: 7,
    Aug: 8,
    Sep: 9,
    Oct: 10,
    Nov: 11,
    Dec: 12,
};

export function getNextDate(inputDate?: string) {
    if (inputDate !== undefined) {
        const dateComponents = inputDate.split("/");
        const todayMonth = parseInt(dateComponents[0]);
        let nextMonth = todayMonth + 1;
        if (nextMonth > 12) {
            nextMonth = 1;
            let todayYear = parseInt(dateComponents[2]);
            todayYear += 1;
            dateComponents[2] = todayYear + "";
        }
        let nextMonthStr = "" + nextMonth;
        if (nextMonth < 10) {
            nextMonthStr = "0" + nextMonthStr;
        }
        return nextMonthStr + "/01/" + dateComponents[2];
    } else {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth() + 1;

        let nextMonth = currentMonth + 1;
        let nextYear = currentYear;

        if (nextMonth > 12) {
            nextMonth = 1;
            nextYear += 1;
        }

        return nextMonth.toString().padStart(2, "0") + "/01/" + nextYear.toString();
    }
}

export function calculateMonthsLeftUntilGraduation(
    gradDate: number,
    currentTime = currentTimeInSeconds()
) {
    const today = new Date(currentTime * 1000).toLocaleDateString();

    const todayDateComponents = today.split("/");
    const todayMonth = parseInt(todayDateComponents[0]);
    const todayYear = parseInt(todayDateComponents[2]);
    const gradDate2 = new Date(gradDate * 1000).toLocaleDateString();
    const gradDateComponents = gradDate2.split("/");
    const gradDateMonth = parseInt(gradDateComponents[0]);
    const gradDateYear = parseInt(gradDateComponents[2]);

    const yearsLeft = gradDateYear - todayYear;
    const monthsLeft = yearsLeft * 12 + gradDateMonth - todayMonth;

    return monthsLeft;
}

export function getMMslashDDslashYYYYFromTime(time: number) {
    return new Date(time * 1000).toLocaleDateString();
}

export function getReportParsingTimeFromMMslashDDslashYYYY(date: string) {
    if (!date) {
        return 0;
    }

    const dateArray = date.split("/");
    const newDate = new Date(+dateArray[2], +dateArray[0] - 1, +dateArray[1]);
    return getTimeFromDateClass(newDate);
}

export function getNextGraphTimeFromCurrentTime(inputTime: number = currentTimeInSeconds()) {
    return getReportParsingTimeFromMMslashDDslashYYYY(
        getNextDate(getMMslashDDslashYYYYFromTime(inputTime))
    );
}

export function getMonthsUntilIncomeBasedOfferDate(
    date: number,
    currentTime = currentTimeInSeconds()
) {
    return calculateMonthsLeftUntilGraduation(date, currentTime);
}

export function getTimeFromDateString(date: string) {
    const d = new Date(date);
    const time = Math.floor(d.getTime() / 1000);
    return time;
}

export function currentTimeInSeconds() {
    const d = new Date();
    const time = Math.floor(d.getTime() / 1000);
    return time;
}

export function getTimeFromDateClass(d: Date) {
    const time = Math.floor(d.getTime() / 1000);
    return time;
}

export function getTimeFromMMMspaceDDcommaSpaceYYYY(date: string) {
    if (!date) {
        return 0;
    }

    const dateArray = date.split(" ");
    const newDate = new Date(
        +dateArray[2],
        months[dateArray[0]] - 1,
        +dateArray[1].replace(",", "")
    );

    return getTimeFromDateClass(newDate);
}

export function calculateAge(dob: number, dateInput?: string) {
    if (dateInput !== undefined) {
        const d = new Date(dateInput);
        return Math.floor((d.getTime() - dob * 1000) / (1000 * 3600 * 24 * 365));
    } else {
        const d = new Date();
        return Math.floor((d.getTime() - dob * 1000) / (1000 * 3600 * 24 * 365));
    }
}

export function calculateAgeFromTime(dob: number, time: number) {
    return calculateAge(dob, getMMslashDDslashYYYYFromTime(time));
}
