import { useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { getFormsConfig } from "./forms-config";
import { useOnboarding } from "../../common/core/providers/onboarding.provider";
import { useSparrowUIContext, useSparrowUIFormsContext } from "../../sparrow-ui-library/src/context";
import { UserType } from "../../common/constants/defaultInputs";
import { Button } from "../../common/components/buttons/button/Button";
import { ButtonTypes } from "../../common/components/buttons/button/Button";
import { FormWrapper } from "../../sparrow-ui-library/src/ui-components/form-components";
import { handleSubmission } from "../../sparrow-ui-library/src/utils/ui-library-utils";
import { Put } from "../../common/core/services/clientApi";


import ContentLayout from "../../common/components/layouts/content-layout";
import { RadioButtonFormProps } from "../../sparrow-ui-library/src/types/form-types";
import { paaasMembershipElectionEndpoint } from "../../services/paaas/paaas-base-url";
import { borrowerOffersUrl } from "../../common/core/routes/navigation-urls";
import { MembershipElection } from "../../sparrow-ui-library/src/types/sparrow-types/membership-election";

export const PAAASMembershipElection = observer(() => {
    const onboarding = useOnboarding();
    const navigate = useNavigate();
    const { displayToastPopup } = useSparrowUIContext();
    const { forms, setForms } = useSparrowUIFormsContext();
    const getNewFormsInstance = () => {
        const formsConfig = getFormsConfig({ companyName: onboarding.companyName });
        return formsConfig.forms as RadioButtonFormProps[];
    };

    useEffect(() => {
        // Need to clear out the prior forms before running the rest of the code, otherwise radioButtons may be undefined
        return setForms(getNewFormsInstance());
    }, []);

    const handleSubmit = () =>
        handleSubmission({ forms, setForms },
            async (formattedBackendForms) => {
                try {
                    onboarding.setPqLoader(true);
                    const response: { succeeded: boolean, membershipElection: MembershipElection } = await Put(
                        paaasMembershipElectionEndpoint,
                        onboarding.jwt as string,
                        formattedBackendForms
                    );

                    if (response.succeeded) {
                        navigate(borrowerOffersUrl)
                    } else {
                        displayToastPopup(
                            "Your membership election was not recorded. Please make an election or try again later.",
                            true
                        );
                    }

                } catch (err: any) {
                    displayToastPopup(err.userDescription as string, true);
                }

                onboarding.setPqLoader(false);
            }
        );


    return (
        <ContentLayout pageText={
            {
                title: `Are you a member of ${onboarding.companyName}?`,
                leftTitleText: `Access member benefits for free with ${onboarding.companyName}`,
            }
        } >
            {!!forms.length && <FormWrapper forms={forms} setForms={setForms} getNewFormsInstance={getNewFormsInstance} />}
            <Button
                id="continue-button"
                tabIndex={0}
                onClick={handleSubmit}
                showIcon={true}
                buttonType={ButtonTypes.PRIMARY}
                userType={onboarding.formData.userType as UserType}
            >
                Next
            </Button>
        </ContentLayout>
    );
});
