import { baseUrl } from "../services/baseUrl";
import {
    borrowerBase,
    borrowerOffersRoutingUrl, borrowerProfileRoutingUrl,
    loginBorrowerRoutingUrl,
    loginTypeRoutingUrl,
    loginUrlBase,
    prequalificationAddressRoutingUrl,
    prequalificationAmountRoutingUrl,
    prequalificationBase,
    prequalificationCitizenshipRoutingUrl,
    prequalificationDegreesRoutingUrl,
    prequalificationIncomeRoutingUrl,
    prequalificationReviewRoutingUrl,
    prequalificationExpandedSearchRoutingUrl,
    signUpBorrowerRoutingUrl,
    signUpSuccessRoutingUrl,
    signUpTypeRoutingUrl,
    signUpUrlBase,
    userInclusionBase,
    userInclusionInformationRoutingUrl,
    userInclusionManagementRoutingUrl,
    userInclusionReviewInviteRoutingUrl,
    userInclusionSelectRoutingUrl,
} from "./routing-urls";

export const privacyUrl = "https://www.sparrowfi.com/privacy";
export const termsUrl = "https://www.sparrowfi.com/terms";
export const faqUrl = "https://www.sparrowfi.com/faqs";

export const loginUrl = "/login/borrower";

export const paaasLandingUrl = "/landing";

export const signUpStepTypeUrl = signUpUrlBase + signUpTypeRoutingUrl;
export const signUpStepBorrowerUrl = signUpUrlBase + signUpBorrowerRoutingUrl;
export const signUpStepCrestUrl = `https://www.enterprise.${baseUrl}/sign-up`;
export const signUpSuccessUrl = signUpUrlBase + signUpSuccessRoutingUrl;

export const loginStepType = loginUrlBase + loginTypeRoutingUrl;
export const loginStepBorrower = loginUrlBase + loginBorrowerRoutingUrl;
export const loginStepCrest = `https://www.enterprise.${baseUrl}/sign-in`;

// FORM
export const prequalificationAmountUrl = prequalificationBase + prequalificationAmountRoutingUrl;
export const prequalificationCitizenshipUrl =
    prequalificationBase + prequalificationCitizenshipRoutingUrl;
export const prequalificationAddressUrl = prequalificationBase + prequalificationAddressRoutingUrl;
export const prequalificationIncomeUrl = prequalificationBase + prequalificationIncomeRoutingUrl;
export const prequalificationDegreesUrl = prequalificationBase + prequalificationDegreesRoutingUrl;
export const prequalificationReviewUrl = prequalificationBase + prequalificationReviewRoutingUrl;
export const prequalificationExpandedSearchUrl = prequalificationBase + prequalificationExpandedSearchRoutingUrl;

// DASHBOARD
export const dashboardUrl = "/dashboard";
export const borrowerOffersUrl = borrowerBase + borrowerOffersRoutingUrl;
export const borrowerProfileUrl = borrowerBase + borrowerProfileRoutingUrl;
export const userInclusionSelectUrl = userInclusionBase + userInclusionSelectRoutingUrl;
export const userInclusionInformationUrl = userInclusionBase + userInclusionInformationRoutingUrl;
export const userInclusionManagementUrl = userInclusionBase + userInclusionManagementRoutingUrl;
export const userInclusionReviewInviteUrl = userInclusionBase + userInclusionReviewInviteRoutingUrl;
