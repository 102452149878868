import { useMediaQuery } from "react-responsive";

import { OfferCardHeaderInteractionButtons } from "./OfferCardHeaderInteractionButtons";
import { OfferCardHeaderExpandButton } from "./buttons/OfferCardHeaderExpandButton";

import "../../style.scss";

interface InteractionContainerProps {
    offer: any;
    toggle: Function;
    progress: number;
    pinToggle: () => void;
    bookmarkToggle: () => void;
    pinCurrentValue: boolean;
    bookmarkCurrentValue: boolean;
    pinnedOfferIndex?: number;
    personalizedURL: string;
    onApply: (boolean) => void;
    applyWithoutCosigner: boolean;
}

export const OfferCardHeaderInteractionContainer: React.FunctionComponent<
    InteractionContainerProps
> = ({
    toggle,
    offer,
    progress,
    pinToggle,
    bookmarkToggle,
    pinCurrentValue,
    bookmarkCurrentValue,
    pinnedOfferIndex,
    personalizedURL,
    onApply,
    applyWithoutCosigner,
}) => {
    const isMore1200px = useMediaQuery({
        query: "(min-width: 1200px)",
    });
    return (
        <>
            <div className={`apply-container ${progress !== 0 ? "active" : ""}`}>
                {isMore1200px && (
                    <OfferCardHeaderInteractionButtons
                        offer={offer}
                        progress={progress}
                        pinToggle={pinToggle}
                        bookmarkToggle={bookmarkToggle}
                        pinCurrentValue={pinCurrentValue}
                        bookmarkCurrentValue={bookmarkCurrentValue}
                        pinnedOfferIndex={pinnedOfferIndex}
                        personalizedURL={personalizedURL}
                        onApply={onApply}
                        applyWithoutCosigner={applyWithoutCosigner}
                    ></OfferCardHeaderInteractionButtons>
                )}
                <div className="interaction-button-container">
                    {isMore1200px && (
                        <OfferCardHeaderExpandButton toggle={toggle} progress={progress} />
                    )}
                </div>
            </div>
        </>
    );
};
