export enum ModalMenuType {
    Link = "link",
    Dropdown = "dropdown",
}

export enum MenuAlign {
    LEFT = "left",
    RIGHT = "right",
    CENTER = "center",
}

export interface MenuItems {
    type: ModalMenuType;
    name: string;
    url: string;
    isOpenNewTab?: boolean;
    id: number;
    children?: MenuItems[];
}

export interface CustomMenuProps {
    items: MenuItems[];
    menuAlign: MenuAlign;
}
