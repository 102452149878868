import { UserType } from "../../../../types/sparrow-types";

export const DollarSignIcon = ({ userType }: { userType: UserType }) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M10 0C4.48864 0 0 4.48864 0 10C0 15.5114 4.48864 20 10 20C15.5114 20 20 15.5114 20 10C20 4.48864 15.5114 0 10 0ZM10 1.81818C14.5277 1.81818 18.1818 5.4723 18.1818 10C18.1818 14.5277 14.5277 18.1818 10 18.1818C5.4723 18.1818 1.81818 14.5277 1.81818 10C1.81818 5.4723 5.4723 1.81818 10 1.81818ZM9.09091 4.54545V5.625C8.03977 6.00497 7.27273 7.00639 7.27273 8.18182C7.27273 9.67685 8.50497 10.9091 10 10.9091C10.5149 10.9091 10.9091 11.3033 10.9091 11.8182C10.9091 12.3331 10.5149 12.7273 10 12.7273C9.48509 12.7273 9.09091 12.3331 9.09091 11.8182H7.27273C7.27273 12.9936 8.03977 13.995 9.09091 14.375V15.4545H10.9091V14.375C11.9602 13.995 12.7273 12.9936 12.7273 11.8182C12.7273 10.3232 11.495 9.09091 10 9.09091C9.48509 9.09091 9.09091 8.69673 9.09091 8.18182C9.09091 7.6669 9.48509 7.27273 10 7.27273C10.5149 7.27273 10.9091 7.6669 10.9091 8.18182H12.7273C12.7273 7.00639 11.9602 6.00497 10.9091 5.625V4.54545H9.09091Z"
                fill={`rgba(var(--${userType}PrimaryColor), 1)`}

            />
        </svg>
    );
};
