"use client";
import React, { useRef, useState } from "react";
import starIcon from "../../../../../assets/images/icons/star.svg";
import questionIcon from "../../../../../assets/images/icons/grayQuestion.svg";
import questionDarkIcon from "../../../../../assets/images/icons/grayDarkQuestion.svg";

import { useOnboarding } from "../../../../core/providers/onboarding.provider";
import { useOnClickOutside } from "../../../../core/helpers/events";
import { GenericBubble } from "../../../../components/Bubble";
import { SetPreferencesModal } from "../../../../components/modals/OffersPageModals/SetPreferencesModal";
import { useStore } from "../../../../core/providers/global.provider";
import { observer } from "mobx-react";
import { SortBy } from "./SortBy";
import { OffersTablePreferences } from "../../../../../sparrow-ui-library/src/types/sparrow-types";
import "./style.scss";

function getTextBasedOnQuizAnswer(answer: OffersTablePreferences, type: string) {
    const isPSL = type === "PSL";

    switch (answer) {
        case OffersTablePreferences.LOWEST_TOTAL_COST:
            return {
                title: "Save as much as possible (pre-qualified rates with the lowest total cost)",
                sortBy: "Lowest total cost",
            };
        case OffersTablePreferences.SHORTEST_TERM:
            return {
                title: "Get out of debt ASAP (pre-qualified rates with the shortest terms)",
                sortBy: "Shortest term",
            };
        case OffersTablePreferences.LOWEST_IN_SCHOOL:
            return {
                title: "Keep in school payments low (pre-qualified rates with the lowest monthly in school payments)",
                sortBy: "Lowest in school payments",
            };
        case OffersTablePreferences.LOWEST_MONTHLY_PAYMENT:
            return {
                title: `Keep ${
                    isPSL ? "after school payments" : "monthly payments"
                } low (Rates with lowest monthly ${isPSL ? "after school " : ""}payments)`,
                sortBy: isPSL ? "Lowest after school payments" : "Lowest monthly payment",
            };
        case OffersTablePreferences.LOWEST_APR:
            return {
                title: "N/A",
                sortBy: "Lowest APR",
            };
        default:
            return {
                title: "",
                sortBy: "",
            };
    }
}

interface ForYouProps {
    setSecondaryRank: Function;
    secondaryRank: OffersTablePreferences;
    numberOfForYouOffers: number;
    totalNumberOfOffers: number;
    primaryRank: OffersTablePreferences;
}

export const ForYouSection: React.FunctionComponent<ForYouProps> = observer(
    ({
        secondaryRank,
        
        setSecondaryRank,
        numberOfForYouOffers,
        totalNumberOfOffers,
        primaryRank,
    }) => {
        const onboarding = useOnboarding();
        const { setModal, modalResetData } = useStore();
        
        const dropDownRef = useRef<HTMLDivElement>(null);
        useOnClickOutside(dropDownRef, () => modalResetData());

        const [isHovering, setIsHovering] = useState(false);


        function handleClick() {
            setModal(() => <SetPreferencesModal />, false, true, "lg", true);
            return;
        }

        return (
            <>
                <div className="for-you-section">
                    <div className="for-you-container">
                        <div className="for-you-title-container">
                            <img src={starIcon} alt="star" />
                            <div className="for-you-title-text">
                                Best pre-qualified rates for you
                            </div>
                            <div className="for-you-title-display-text">{`(displaying ${numberOfForYouOffers} of ${totalNumberOfOffers} rates)`}</div>
                        </div>

                        <div className="for-you-sort-container">
                            <div className="for-you-quiz-text-container">
                                <div className="for-you-quiz-description-container">
                                    {
                                        getTextBasedOnQuizAnswer(
                                            onboarding.formData.offersQuizAnswer!,
                                            onboarding.formData.type as string
                                        ).title
                                    }
                                    <div className="for-you-quiz-description-popup">
                                        <img
                                            onMouseOver={() => setIsHovering(true)}
                                            onMouseOut={() => setIsHovering(false)}
                                            onClick={() => setIsHovering(!isHovering)}
                                            src={isHovering ? questionDarkIcon : questionIcon}
                                            alt="question"
                                        />

                                        <div
                                            className={
                                                "bubble-wrapper-tooltip" +
                                                (isHovering ? " hover" : "")
                                            }
                                        >
                                            <GenericBubble>
                                                <div className="offer-page-popup">
                                                    The best rates section shows the top 5 rates
                                                    based on your preferences and objectively
                                                    prioritizes the pre-qualified rates with the
                                                    lowest total cost.
                                                </div>
                                            </GenericBubble>
                                        </div>
                                    </div>
                                </div>

                                <div className="for-you-quiz-change" onClick={() => handleClick()}>
                                    Change my preference
                                </div>
                            </div>
                            <SortBy
                                secondaryRank={secondaryRank}
                                setSecondaryRank={setSecondaryRank}
                                primaryRank={primaryRank}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
);
