export const insertCompanyNameInText = (text: string, companyName: string) => {
    // Note: the regex below is sensitive to the character used for the apostrophe
    return text.replace(/\b(Sparrow’s|Sparrow)\b/g, (match) => {
        if (match === "Sparrow’s") {
            return `${companyName}'s`.replace("s's", "s'");
        }

        if (match === "Sparrow") {
            return companyName;
        }

        return match; // If no match found, return the original text
    });
};