import { observer } from "mobx-react";
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider";
import { RoadmapStep } from "../../components/RoadmapStep/RoadmapStep";
import { ProgressPopupWrapper } from "../../components/ProgressPopupWrapper/ProgressPopupWrapper";
import { UserType } from "../../../../common/constants/defaultInputs";
import { useMediaQuery } from "react-responsive";
import { RoadmapStepsContainerMobile } from "./RoadmapStepsContainerMobile";
import "./styles.scss";

export const RoadmapStepsContainer = observer(() => {
    const onboarding = useOnboarding();
    if (!onboarding.activeProgressRoadmap) return <></>;
    
    // Media Constants
    const isLess1300px = useMediaQuery({
        query: "(max-width: 1300px)",
    });
    
    //on click of any step, set isActive to true for that step and false for all other steps. This is so a hover bubble displays only for an active step
    const onStepClick = (e, isIncompleteStep: boolean) => {
        if (isIncompleteStep || !onboarding.activeProgressRoadmap) return;
        const copyActiveSteps = [...onboarding.activeProgressRoadmap];

        copyActiveSteps.forEach((step) => {
            if (step.progressStatus === e.currentTarget.id) {
                step.isActive = true;
            } else {
                step.isActive = false;
            }
        });

        onboarding.setActiveProgressRoadmap(copyActiveSteps);
    };

    

    return (
        <>
            {isLess1300px ? (
                <RoadmapStepsContainerMobile />
            ) : (
                <div className="rm-steps-container">
                    {onboarding.activeProgressRoadmap.map((roadmapStep, index) => {
                        const stateOfStep = {
                            isActive: roadmapStep.isActive,
                            isLatestIncompleteStep: roadmapStep.isLatestIncompleteStep,
                            isCompleteStep: roadmapStep.isCompleteStep,
                        }

                        // if step is incomplete, do not allow user to click on it
                        const isIncompleteStep = !roadmapStep.isCompleteStep && !roadmapStep.isLatestIncompleteStep;

                        return (
                            <>
                                <RoadmapStep
                                    id={roadmapStep.progressStatus}
                                    key={roadmapStep.progressStatus}
                                    stepNum={index + 1}
                                    progressStatus={roadmapStep.progressStatus}
                                    stateOfStep={stateOfStep}
                                    userType={onboarding.formData.userType as UserType}
                                    onStepClick={(e) =>
                                        onStepClick(
                                            e,
                                            isIncompleteStep
                                        )
                                    }
                                />
                                {roadmapStep.isActive && (
                                    <ProgressPopupWrapper progressStatus={roadmapStep.progressStatus} />
                                )}
                            </>
                        );
                    })}
                </div>
            )}
        </>
    );
});