import { observer } from "mobx-react";
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider";
import { UserType } from "../../../../common/constants/defaultInputs";
import { formatDateAsMMMDDYYYY } from "../../../../common/utils/helper_functions/date-functions";
import { LoanRequestContainer } from "./LoanRequestContainer";
import { LegacyRef, useEffect, useRef } from "react";
import { sentenceCase } from "../../../../common/utils/global";
import { RequestHistoryHeader } from "./RequestHistoryHeader";
import styles from "./styles.module.scss"
import { GradHatIcon, StarIcon } from "../../../../sparrow-ui-library/src/assets/icons/request-history-icons";

interface RequestHistoryContainerProps {
    setCollapsibleElement: LegacyRef<HTMLDivElement> | undefined;
    toggle: Function;
    progress?: number;
    loanRequests: any;
    toggleState: "COLLAPSED" | "EXPANDED" | "COLLAPSING" | "EXPANDING";
}

export const RequestHistoryContainer: React.FunctionComponent<RequestHistoryContainerProps> = observer(
    ({ toggle, setCollapsibleElement, progress, loanRequests, toggleState }) => {
        const { formData } = useOnboarding();
        const onboarding = useOnboarding();

        const dropDownRef = useRef<HTMLDivElement>(null);

        const updateActiveRequest = (id: string) => {
            onboarding.setActiveLoanRequestData(id);
        };

        useEffect(() => {
            function handleClickOutside(event) {
                if (
                    toggleState === "EXPANDED" &&
                    dropDownRef.current &&
                    !dropDownRef.current.contains(event.target as Node)
                ) {
                    toggle();
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [dropDownRef, toggleState]);

        return (
            <>
                <div style={{ width: "100%" }} ref={dropDownRef}>
                    <RequestHistoryHeader
                        toggle={toggle}
                        icon={onboarding.checkIsBorrowerCreator() ? <GradHatIcon size={16}/> : <StarIcon />}
                        user={sentenceCase(onboarding.formData.userType as UserType)}
                        date={formatDateAsMMMDDYYYY(formData.createdAt!)}
                        progress={progress}
                    />
                    <div className={styles["overflow-allowed"]} ref={setCollapsibleElement}>
                        {loanRequests?.map((value) => {
                            return (
                                <div onClick={() => updateActiveRequest(value.id)} key={value.id}>
                                    <LoanRequestContainer
                                        activeId={formData.id}
                                        user={sentenceCase(value.userType as UserType)}
                                        loanAmount={value.loanAmount.display.split(".")[0]}
                                        date={formatDateAsMMMDDYYYY(value.createdAt!)}
                                        id={value.id}
                                        expanded={value.id === formData.id}
                                        isBorrower={onboarding.checkIsBorrower(value)}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </>
        );
    }
);