import { FC } from "react";
import { UserType } from "../../../types/sparrow-types";
import { getSvgColors } from "../getSvgColors";
import styles from "./styles.module.scss";

interface DashboardIconProps {
    isActive: boolean;
}

    export const DashboardIcon: FC<DashboardIconProps> = ({isActive}) => {
    const { primaryColor } = getSvgColors(UserType.BORROWER);

    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.dashboardIcon}
        >
            <path
                d="M2.25 0C1.01625 0 0 1.01625 0 2.25V3.75C0 4.98375 1.01625 6 2.25 6H6.25C7.48375 6 8.5 4.98375 8.5 3.75V2.25C8.5 1.01625 7.48375 0 6.25 0H2.25ZM11.75 0C10.5162 0 9.5 1.01625 9.5 2.25V8.75C9.5 9.98375 10.5162 11 11.75 11H15.75C16.9838 11 18 9.98375 18 8.75V2.25C18 1.01625 16.9838 0 15.75 0H11.75ZM2.25 1.5H6.25C6.67325 1.5 7 1.82675 7 2.25V3.75C7 4.17325 6.67325 4.5 6.25 4.5H2.25C1.82675 4.5 1.5 4.17325 1.5 3.75V2.25C1.5 1.82675 1.82675 1.5 2.25 1.5ZM11.75 1.5H15.75C16.1733 1.5 16.5 1.82675 16.5 2.25V8.75C16.5 9.17325 16.1733 9.5 15.75 9.5H11.75C11.3268 9.5 11 9.17325 11 8.75V2.25C11 1.82675 11.3268 1.5 11.75 1.5ZM2.25 7C1.01625 7 0 8.01625 0 9.25V15.75C0 16.9838 1.01625 18 2.25 18H6.25C7.48375 18 8.5 16.9838 8.5 15.75V9.25C8.5 8.01625 7.48375 7 6.25 7H2.25ZM2.25 8.5H6.25C6.67325 8.5 7 8.82675 7 9.25V15.75C7 16.1733 6.67325 16.5 6.25 16.5H2.25C1.82675 16.5 1.5 16.1733 1.5 15.75V9.25C1.5 8.82675 1.82675 8.5 2.25 8.5ZM11.75 12C10.5162 12 9.5 13.0162 9.5 14.25V15.75C9.5 16.9838 10.5162 18 11.75 18H15.75C16.9838 18 18 16.9838 18 15.75V14.25C18 13.0162 16.9838 12 15.75 12H11.75ZM11.75 13.5H15.75C16.1733 13.5 16.5 13.8268 16.5 14.25V15.75C16.5 16.1733 16.1733 16.5 15.75 16.5H11.75C11.3268 16.5 11 16.1733 11 15.75V14.25C11 13.8268 11.3268 13.5 11.75 13.5Z"
                fill={isActive ? primaryColor : "#9EA9B0"}
            />
        </svg>
    );
};
