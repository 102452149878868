import crossIcon from "../../../assets/images/icons/cross.svg";

import NumberFormat from "react-number-format";

interface NumberInputProps {
    title: string;
    toggle: boolean;
    value: number;
    setToggle: Function;
    onChange: Function;
    maxValue: number;
}

export const OfferHeaderNumberInput: React.FunctionComponent<NumberInputProps> = ({
    title,
    toggle,
    setToggle,
    value,
    onChange,
    maxValue,
}) => {
    return (
        <>
            <div className={`header-dropdown-popup ${toggle ? "active" : ""}`}>
                <div className="header-dropdown-input-container">
                    <div className="header-dropdown-input-title-container">
                        <div className="header-dropdown-input-title">{title}</div>
                        <div>
                            <img
                                src={crossIcon}
                                alt="crossIcon"
                                onClick={() => setToggle(!toggle)}
                            />
                        </div>
                    </div>
                </div>
                <NumberFormat
                    style={{ textAlign: "left" }}
                    value={value}
                    thousandSeparator={true}
                    onValueChange={(v: any) => onChange(v.value > maxValue ? maxValue : v.value)}
                    placeholder={""}
                    prefix={"$"} // NOTE: we need to ensure this prefix is always present
                    fixedDecimalScale={true}
                    decimalScale={0}
                />
            </div>
        </>
    );
};
