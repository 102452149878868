import { IUserData } from "../../../models/userData";
import { Get, Put } from "../clientApi";
import {
    SparrowApi_GET_CurrentUser,
    SparrowApi_PUT_UpdateUserAttributes,
} from "../sparrow-api-endpoints";

export interface IPutUpdateUserAttributesRequest {
    body: {
        firstName: string;
        lastName: string;
    };

    token: string;
}

export interface IPutUpdateUserAttributesResult {
    success: true;
    message: "User Attributes Updated";
}

export const PutUpdateUserAttributes = async (
    requestBody: IPutUpdateUserAttributesRequest
): Promise<IPutUpdateUserAttributesResult | null> => {
    const { body, token } = requestBody;

    const updateAttributesOutput: IPutUpdateUserAttributesResult | null = await Put(
        SparrowApi_PUT_UpdateUserAttributes,
        token,
        body
    );

    return updateAttributesOutput;
};

export const GetCurrentUserAndUpdateStore = async (params: {
    jwt: string;
    setUserData: (userData: IUserData) => void;
}) => {
    const { jwt, setUserData } = params;

    const userAttributesOutput = await Get(SparrowApi_GET_CurrentUser, jwt);
    if (userAttributesOutput) {
        setUserData(userAttributesOutput as IUserData);
    }
};
