import { 
    getAcademicPeriodOptions, 
    getDegreeTypeOptions, 
    getMajorOptions, 
    getSchoolOptions, 
    getYearInSchoolOptions, 
    getPhoneNumberTypeOptions,
    getTimeZoneOptions
} from "./async-dropdown-functions";
import { DropdownOption } from "../../../types/form-types";

export const asyncDropdownFunctionObject: {[key: string]: (value: string) => DropdownOption[]} = {
    "highestDegree.academicPeriod": getAcademicPeriodOptions,
    "highestDegree.degreeType": getDegreeTypeOptions,
    "highestDegree.yearInSchool": getYearInSchoolOptions,
    "highestDegree.major": getMajorOptions,
    "highestDegree.university": getSchoolOptions,
    "phoneNumber.type": getPhoneNumberTypeOptions,
    "phoneNumber.timeZone": getTimeZoneOptions,
}

export const getAsyncDropdownFunction = (fieldName: string) => {
    return asyncDropdownFunctionObject[fieldName];
}
