import "./styles.scss";

export const StatusLabel = ({
    status,
    primaryColor,
    secondaryColor,
}: {
    [key: string]: string;
}) => {
    return (
        <div className="status-label" style={{ background: secondaryColor }}>
            <div className="status-circle" style={{ background: primaryColor }}></div>
            <p className="status-text" style={{ color: primaryColor }}>
                {status}
            </p>
        </div>
    );
};
