interface IProfileCardInfo {
    title: string;
    subtitle?: string | null;
    onEdit?: () => void;
    children?: any;
    info?:
        | {
              title: string;
              value: string;
          }[]
        | null;
}
const ProfileCardInfo = ({
    title,
    onEdit,
    subtitle = null,
    children = null,
    info = null,
}: IProfileCardInfo) => {
    return (
        <div className={"profile-card-info"}>
            <div className="profile-card-info__head">
                <div className="profile-card-info__head-title">
                    <h3>{title}</h3>
                    {subtitle && <p>{subtitle}</p>}
                </div>
                {onEdit && (
                    <button className={"edit-btn"} onClick={() => onEdit()}>
                        <span className={"icon-pen"} />
                    </button>
                )}
            </div>
            <div className="profile-card-info__content">
                {children && <div className={"profile-card-info__content-child"}>{children}</div>}
                {info && info.length > 0 && (
                    <div className={"profile-card-info__content-info"}>
                        {info.map(({ title, value }, index) => {
                            return (
                                <div
                                    className={"profile-card-info__content-info-row"}
                                    key={`profile-card-info__content-info-row-${index}-${title}`}
                                >
                                    <div className="profile-card-info__content-info-title">
                                        {title}
                                    </div>
                                    <div className="profile-card-info__content-info-value">
                                        {value}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
};
export default ProfileCardInfo;
