import { PrequalificationRequestInput } from "../../../sparrow-ui-library/src/types/sparrow-types";
import { UserType } from "../../constants/defaultInputs";
import { OnboardingStore } from "../../store/onboarding";
import { PrequalUrlsEnum } from "./front-end-urls";

const userInitiated_PrequalSteps = [PrequalUrlsEnum.AMOUNT, PrequalUrlsEnum.CITIZENSHIP];

const borrowerSteps = [
    PrequalUrlsEnum.ADDRESS,
    PrequalUrlsEnum.INCOME,
    PrequalUrlsEnum.DEGREES,
    PrequalUrlsEnum.REVIEW,
];

const cosignerSteps = [PrequalUrlsEnum.ADDRESS, PrequalUrlsEnum.INCOME, PrequalUrlsEnum.REVIEW];

/*
Prequalification flows
******************************************************************************************************************************************************************************
*/
export const borrowerInitiated_SSN_PrequalSteps = [...userInitiated_PrequalSteps, ...borrowerSteps];

export const borrowerInitiated_NoSSN_PrequalSteps = [
    ...userInitiated_PrequalSteps,
    PrequalUrlsEnum.DEGREES,
    PrequalUrlsEnum.REVIEW,
];

export const borrowerIncluded_SSN_PrequalSteps = [PrequalUrlsEnum.CITIZENSHIP, ...borrowerSteps];

export const borrowerIncluded_NoSSN_PrequalSteps = [
    PrequalUrlsEnum.CITIZENSHIP,
    PrequalUrlsEnum.DEGREES,
    PrequalUrlsEnum.REVIEW,
];

export const cosignerInitiated_SSN_PrequalSteps = [...userInitiated_PrequalSteps, ...cosignerSteps];

export const cosignerInitiated_NoSSN_PrequalSteps = [...userInitiated_PrequalSteps];

export const cosignerIncluded_SSN_PrequalSteps = [PrequalUrlsEnum.CITIZENSHIP, ...cosignerSteps];

export const cosignerIncluded_NoSSN_PrequalSteps = [
    PrequalUrlsEnum.CITIZENSHIP,
    PrequalUrlsEnum.REVIEW,
];

/*
Get active prequal steps
******************************************************************************************************************************************************************************
*/
interface IActiveSteps {
    formData: PrequalificationRequestInput;
    creatorIsIncludingUser: boolean;
    useShortSteps: boolean;
}

const getActiveBorrowerSteps = ({
    formData,
    creatorIsIncludingUser,
    useShortSteps,
}: IActiveSteps) => {
    //Borrower is directly including cosigner
    if (creatorIsIncludingUser) {
        return !useShortSteps
            ? cosignerIncluded_SSN_PrequalSteps
            : cosignerIncluded_NoSSN_PrequalSteps;
    }
    //Borrower is the invited user
    if (formData.isIncludedUser) {
        return !useShortSteps
            ? borrowerIncluded_SSN_PrequalSteps
            : borrowerIncluded_NoSSN_PrequalSteps;
    }
    //Borrower is the creator
    return !useShortSteps
        ? borrowerInitiated_SSN_PrequalSteps
        : borrowerInitiated_NoSSN_PrequalSteps;
};

const getActiveCosignerSteps = ({
    formData,
    creatorIsIncludingUser,
    useShortSteps,
}: IActiveSteps) => {
    //Cosigner is directly including borrower
    if (creatorIsIncludingUser) {
        return !useShortSteps
            ? borrowerIncluded_SSN_PrequalSteps
            : borrowerIncluded_NoSSN_PrequalSteps;
    }
    //Cosigner is the invited user
    if (formData.isIncludedUser) {
        return !useShortSteps
            ? cosignerIncluded_SSN_PrequalSteps
            : cosignerIncluded_NoSSN_PrequalSteps;
    }
    //Cosigner is the creator
    return !useShortSteps
        ? cosignerInitiated_SSN_PrequalSteps
        : cosignerInitiated_NoSSN_PrequalSteps;
};

export const getActivePrequalSteps = (onboarding: OnboardingStore) => {
    if (onboarding.formData.userType === UserType.BORROWER) {
        return getActiveBorrowerSteps(onboarding);
    }
    return getActiveCosignerSteps(onboarding);
};

/*
Get latest incomplete step, for when a user returns to their request and they can pick up where they left off
******************************************************************************************************************************************************************************
*/
const getStepIndex = (onboarding: OnboardingStore) => {
    return onboarding.creatorIsIncludingUser
        ? onboarding.formData.includedStepsFinished
        : onboarding.formData.stepsFinished;
};

const getLatestIncompleteBorrowerStep = (onboarding: OnboardingStore) => {
    const stepIndex = getStepIndex(onboarding) as number;
    return getActiveBorrowerSteps(onboarding)[stepIndex];
};

const getLatestIncompleteCosignerStep = (onboarding: OnboardingStore) => {
    const stepIndex = getStepIndex(onboarding) as number;
    return getActiveCosignerSteps(onboarding)[stepIndex];
};

export const getLatestIncompleteStep = (onboarding: OnboardingStore) => {
    if (onboarding.formData.userType === UserType.BORROWER) {
        return getLatestIncompleteBorrowerStep(onboarding);
    }

    return getLatestIncompleteCosignerStep(onboarding);
};

/*
Get step routing (prev/next) buttons
******************************************************************************************************************************************************************************
*/

/**
 * Gets the previous and next step relative to the current pathname, based on the active prequal steps for the user
 * @param pathname
 * @param onboarding
 * @returns { prevStep: PrequalUrlsEnum, nextStep: PrequalUrlsEnum}
 */
export const getStepRouting = (pathname: string, onboarding: OnboardingStore) => {
    const currentStepIndex = onboarding.activeSteps.indexOf(pathname);

    const nextStep = onboarding.reviewStepReached
        ? PrequalUrlsEnum.REVIEW
        : onboarding.activeSteps[currentStepIndex + 1] ??
          onboarding.activeSteps[onboarding.activeSteps.length - 1];

    return {
        prevStep: onboarding.activeSteps[currentStepIndex - 1] ?? onboarding.activeSteps[0],
        nextStep,
    };
};
