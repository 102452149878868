import { UserType } from "../../../../types/sparrow-types";
import { getSvgColors } from "../../getSvgColors";

export const CompareBarsIcon = ({
    userType,
    isPAAAS = false,
}: {
    userType: UserType;
    isPAAAS?: boolean;
}) => {
    const { primaryColor, accentColor } = getSvgColors(userType);

    const PAAASVersion = (
        <>
            <rect x="4" y="19" width="12" height="14" rx="1" fill={primaryColor} />
            <rect x="23" y="9" width="13" height="24" rx="1" fill={primaryColor} />
            <rect x="12" y="3" width="16" height="32" rx="2" fill={accentColor} />
        </>
    );

    const marketplaceBorrowerVersion = (
        <>
            <rect x="4" y="19" width="12" height="14" rx="1" fill="#185155" />
            <rect x="23" y="9" width="13" height="24" rx="1" fill="#185155" />
            <rect x="12" y="3" width="16" height="32" rx="2" fill="#8CCE56" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 33H15C15.5523 33 16 32.5523 16 32V20C16 19.4477 15.5523 19 15 19H12V33ZM28 9V33H24C23.4477 33 23 32.5523 23 32V10C23 9.44772 23.4477 9 24 9H28Z"
                fill="#4EAC6A"
            />
        </>
    );
// TODO: currently the same as marketplaceBorrowerVersion because cosigner version was never designed. This has been approved for now but should be swapped for cosigner version when available
    const marketplaceCosignerVersion = (
        <>
            <rect x="4" y="19" width="12" height="14" rx="1" fill="#185155" />
            <rect x="23" y="9" width="13" height="24" rx="1" fill="#185155" />
            <rect x="12" y="3" width="16" height="32" rx="2" fill="#8CCE56" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 33H15C15.5523 33 16 32.5523 16 32V20C16 19.4477 15.5523 19 15 19H12V33ZM28 9V33H24C23.4477 33 23 32.5523 23 32V10C23 9.44772 23.4477 9 24 9H28Z"
                fill="#4EAC6A"
            />
        </>
    );
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <>
                {isPAAAS
                    ? PAAASVersion
                    : userType === UserType.BORROWER
                    ? marketplaceBorrowerVersion
                    : marketplaceCosignerVersion}
            </>
        </svg>
    );
};
