import {
    CitizenshipStatus,
    College,
    UserType,
} from "../../../common/constants/defaultInputs";
import { Income, Address } from "../../../sparrow-ui-library/src/constants/default-inputs";
import {
    prequalificationAddressUrl,
    prequalificationAmountUrl,
    prequalificationCitizenshipUrl,
    prequalificationDegreesUrl,
    prequalificationIncomeUrl,
} from "../../../common/core/routes/navigation-urls";
import { OnboardingStore } from "../../../common/store/onboarding"
import { PQStepContent } from "../../../common/types/user-dashboard";
import removeDecimals from "../../../common/utils/removeDecimals";
import { amountFormat } from "../../../sparrow-ui-library/src/utils/general-utils";
import { PrequalificationRequestInput } from "../../../sparrow-ui-library/src/types/sparrow-types";


/*
The purpose of getPqStepContent function is to return the content for each PqStepCard for the PqStepPopup component i.e., title: "Loan size", formData: "$1,000", formUrls: "/prequalification/amount"
*/

const activeUserFormData = (onboarding: OnboardingStore, isIncludedUser?: boolean) => {
    if (isIncludedUser) {
        return onboarding.formData.includedUser?.userType === UserType.BORROWER
            ? onboarding.formData
            : onboarding.formData.cosigner;
    }

    return onboarding.formData.userType === UserType.BORROWER
        ? onboarding.formData
        : onboarding.formData.cosigner;
};

export const getPqStepContent = (
    onboarding: OnboardingStore,
    isIncludedUser?: boolean
): PQStepContent => {
    const userFormData = activeUserFormData(onboarding, isIncludedUser);

    const { loanAmount } = onboarding.formData;
    let { citizenshipStatus, permanentAddress, income } = (userFormData ??
        {}) as PrequalificationRequestInput;

    //intialize default values if they are null;
    citizenshipStatus = citizenshipStatus ?? CitizenshipStatus();
    permanentAddress = permanentAddress ?? Address();
    income = income ?? Income();

    //format citizenship data for display
    const citizenshipStatusType = citizenshipStatus.type?.includes("US Citizen")
        ? "U.S. Citizen"
        : citizenshipStatus.type;

    const hasNoSsn = citizenshipStatus.hasSSN === false;

    // only display loan size to creator user
    const loanSize = isIncludedUser
        ? []
        : [
              {
                  title: "Loan size",
                  formData: removeDecimals(loanAmount.display),
                  formUrl: prequalificationAmountUrl,
              },
          ];
    // fields displayed to both borrowers and cosigners if they have an ssn
    const otherFields = hasNoSsn
        ? []
        : [
              {
                  title: "Address",
                  formData: permanentAddress.addressLine1,
                  formUrl: prequalificationAddressUrl,
              },
              {
                  title: "Income",
                  formData: amountFormat(income.amount.amount),
                  formUrl: prequalificationIncomeUrl,
              },
          ];

    const isBorrower =
        (!isIncludedUser && onboarding.formData.userType === UserType.BORROWER) ||
        (isIncludedUser && onboarding.formData.includedUser?.userType === UserType.BORROWER);
    //only show education to borrowers
    const education =
        isBorrower && onboarding.formData.degrees
            ? [
                  {
                      title: "Education",
                      formData: onboarding.formData.degrees[0].university ?? [College()],
                      formUrl: prequalificationDegreesUrl,
                  },
              ]
            : [];

    return [
        ...loanSize,
        {
            title: "Citizenship status",
            formData: citizenshipStatusType,
            formUrl: prequalificationCitizenshipUrl,
        },
        ...otherFields,
        ...education,
    ];
};
