import { useRef, useEffect } from "react";

const useClickOutside = (elementRef, callback) => {
    const callbackRef = useRef(callback);
  
    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);
  
    useEffect(() => {
        const handleClickOutside = event => {
            if (elementRef.current && !elementRef.current.contains(event.target)) {
                callbackRef.current();
            }
        };
  
        document.addEventListener('click', handleClickOutside);
  
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [elementRef]);
  };
  
export default useClickOutside;