import { FC } from "react";
import { Button } from "../../../buttons/button/Button";
import { ButtonTypes } from "../../../buttons/button/Button";
import { useOnboarding } from "../../../../core/providers/onboarding.provider";
import approveIcon from "../../../../../assets/images/icons/icon_approve.png";

import { ModalWrapper } from "../../modal-wrapper";
import { useNavigate } from "react-router-dom";
import { dashboardUrl } from "../../../../core/routes/navigation-urls";
import { useStore } from "../../../../core/providers/global.provider";
import { observer } from "mobx-react";

interface ISentModal {
    firstName: string;
    email: string;
    isReminder: boolean;
}

export const SentModal: FC<ISentModal> = observer(({ firstName, email, isReminder }) => {
    const store = useStore();
    const onboarding = useOnboarding();
    const navigate = useNavigate();

    const text = {
        title: isReminder ? "Reminder sent" : "Invitation sent",
        subTitle: (
            <p
                style={{
                    fontSize: "16px",
                    lineHeight: "24px",
                    fontWeight: "400",
                    marginBottom: "24px",
                    color: "#878F99",
                    overflowWrap: "anywhere",
                }}
            >
                {`We sent ${firstName} at `} <strong>{email}</strong>
                {` a ${
                    isReminder ? "reminder" : "new invitation"
                } to join your pre-qualification request.`}
            </p>
        ),
    };

    const handleClick = () => {
        navigate(dashboardUrl);
        store.modalResetData();
    };

    return (
        <ModalWrapper>
            <img src={approveIcon} alt={"approveIcon-icon"} width={40} height={40} />
            <h3
                style={{
                    fontSize: "24px",
                    lineHeight: "32px",
                    fontWeight: "600",
                    marginBottom: "12px",
                    overflowWrap: "anywhere",
                }}
            >
                {text.title}
            </h3>
            {text.subTitle}
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                    id="continue-button"
                    tabIndex={0}
                    onClick={handleClick}
                    buttonType={ButtonTypes.PRIMARY}
                    userType={onboarding.formData.userType}
                >
                    Return to dashboard
                </Button>
            </div>
        </ModalWrapper>
    );
});
