interface TrashIconSvgProps {
    className?: string;
    onClick?: () => void;
}

export const TrashIconSvg: React.FC<TrashIconSvgProps> = ({ className, onClick }) => {
    return (
        <div className={`trash-svg-icon ${className}`} onClick={onClick}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M4.375 5H15.625V17C15.625 17.5523 15.1773 18 14.625 18H5.375C4.82272 18 4.375 17.5523 4.375 17V5Z"
                    stroke="#878F99"
                    strokeWidth="1.5"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.75 5H17.25"
                    stroke="#878F99"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
                <path
                    d="M7.5 4.99998V2.875C7.5 2.32272 7.94772 1.875 8.5 1.875H11.5C12.0523 1.875 12.5 2.32272 12.5 2.875V4.99998"
                    stroke="#878F99"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};
