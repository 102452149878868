import { observer } from "mobx-react";
import { ProgressStatuses } from "../../../../common/models/request";
import { PopupContentTopSection } from "../../components/PopupContent/PopupContentTopSection";
import { PrequalifyPopup } from "../../components/PopupContent/PrequalifyContent";
import { CompareAndSelectPopup } from "../../components/PopupContent/CompareAndSelectContent";
import { ApplyWithLenderPopup } from "../../components/PopupContent/ApplyWithLenderContent";
import "./styles.scss";

interface StepContentContainerProps {
    progressStatus: ProgressStatuses;
}

/* 
NOTE: finalize loan and receive funcds, steps 4 and 5 display identical info to step 3 once there is lender reported data available. 
They will just display statuses of signed and disbursed respectively
*/
const getPopupToDisplay = (progressStatus) => {
    switch (progressStatus) {
        case ProgressStatuses.Prequalify:
            return <PrequalifyPopup />;
        case ProgressStatuses.CompareAndSelect:
            return <CompareAndSelectPopup />;
        case ProgressStatuses.ApplyWithLender:
            return <ApplyWithLenderPopup />;
        case ProgressStatuses.FinalizeLoan:
            return <ApplyWithLenderPopup />;
        case ProgressStatuses.ReceiveFunds:
            return <ApplyWithLenderPopup />;
        default:
            return <PrequalifyPopup />;
    }
};

export const PopupContentContainer: React.FC<StepContentContainerProps> = observer(
    ({ progressStatus }) => {
    
        return (
            <div className={`${progressStatus} wrap-content`}>
                <PopupContentTopSection stepContent={progressStatus} />
                {getPopupToDisplay(progressStatus)}
            </div>
        );
    }
);
