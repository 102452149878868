import { GlobalStore } from "../../../common/store/global";
import { ExpandedSearchModal } from "../../../common/components/modals/ExpandedSearchModal";
import { OnboardingStore } from "../../../common/store/onboarding";

export const displayExpandedSearchModal = (params: {
    store: GlobalStore;
    onboarding: OnboardingStore;
}) => {
    const { store, onboarding } = params;
    //if not expanded search eligible or expanded search fields already completed, return
    if (!onboarding.isEligibleAndHasNotSubmittedExpandedSearch()){
        return;
    }

    store.setModal(() => <ExpandedSearchModal />);
};
