"use client";
import React, { useState } from "react";
import "./style.scss";
import XIcon from "../../../assets/images/icons/X.svg";
import XIconHover from "../../../assets/images/icons/XIconHover.svg";

import { DropdownTooltip } from "./DropdownTooltip";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { CheckBox } from "../../../sparrow-ui-library/src/assets/icons/check-box/CheckBox";
import { UserType } from "../../constants/defaultInputs";
interface MultiSelectDropdownProps {
    title: string;
    onExit: Function;
    checkboxMapping: {};
    setCheckboxMapping: Function;
    onChangeExtraProcessing?: Function;
    headerTooltipContent?: {
        title: string;
        content: string;
    };
    optionTooltipContent?: {
        [key: string]: {
            title: string;
            content: string;
        };
    };
    isMobile?: boolean;
    icon?: string | null;
}

export const MultiSelectDropdown: React.FunctionComponent<MultiSelectDropdownProps> = ({
    title,
    onExit,
    checkboxMapping,
    setCheckboxMapping,
    onChangeExtraProcessing,
    headerTooltipContent,
    optionTooltipContent,
    isMobile = false,
    icon = null,
}) => {
    const [exitHover, setExitHover] = useState<boolean>(false);

    function handleOnChange(checkOption: string) {
        const initialCheckboxMapping = { ...checkboxMapping };
        initialCheckboxMapping[checkOption] = !checkboxMapping[checkOption];
        setCheckboxMapping(initialCheckboxMapping);

        if (onChangeExtraProcessing) {
            onChangeExtraProcessing([checkOption]);
        }
    }

    function handleClear() {
        const initialCheckboxMapping = { ...checkboxMapping };
        for (const option of Object.keys(checkboxMapping)) {
            initialCheckboxMapping[option] = false;
        }
        setCheckboxMapping(initialCheckboxMapping);

        const fieldsToCloear: string[] = [];
        for (const option of Object.keys(checkboxMapping)) {
            if (checkboxMapping[option]) {
                fieldsToCloear.push(option);
            }
        }

        if (onChangeExtraProcessing) {
            onChangeExtraProcessing(fieldsToCloear);
        }
    }

    function hasActiveFilter() {
        for (const option of Object.keys(checkboxMapping)) {
            if (checkboxMapping[option]) return true;
        }
        return false;
    }

    const {formData} = useOnboarding();

    return (
        <>
            <div className="offer-filter-dropdown-select-container">
                <div className="offer-filter-dropdown-select-title-container">
                    <div className="offer-filter-dropdown-select-title">
                        {isMobile && icon && (
                            <div className={"filter-img"}>
                                <img src={icon} alt={title} />
                            </div>
                        )}
                        {title}
                        {headerTooltipContent && (
                            <DropdownTooltip
                                title={headerTooltipContent?.title}
                                content={headerTooltipContent?.content}
                            />
                        )}
                        {isMobile && (
                            <div
                                className={
                                    "offer-filter-dropdown-select-footer-text" +
                                    (hasActiveFilter() ? " active" : "")
                                }
                                onClick={() => handleClear()}
                            >
                                Clear
                            </div>
                        )}
                    </div>
                    {!isMobile && (
                        <img
                            onMouseEnter={() => setExitHover(true)}
                            onMouseLeave={() => setExitHover(false)}
                            style={{ cursor: "pointer" }}
                            src={exitHover ? XIconHover : XIcon}
                            alt="exit"
                            onClick={() => onExit()}
                        />
                    )}
                </div>
                {Object.keys(checkboxMapping).map((option, index) => {
                    return (
                        <div
                            className="dropdown-checkbox"
                            key={option}
                            onClick={() => handleOnChange(option)}
                        >
                            {checkboxMapping[option] ? (
                                <CheckBox userType={formData.userType as UserType} /> 
                            ) : (
                                <div
                                    className={"legend_select_icon_multi"}
                                    style={{
                                        borderColor: checkboxMapping[option]
                                            ? "#6DB353"
                                            : "#abb6bd",
                                        opacity: checkboxMapping[option] ? 1 : 0.4,
                                    }}
                                />
                            )}

                            <p
                                className={
                                    "checkbox-label-scrollable " +
                                    (checkboxMapping[option] ? `selected ${formData.userType}` : "")
                                }
                            >
                                {option}
                            </p>
                            {optionTooltipContent && !isMobile && (
                                <DropdownTooltip
                                    title={optionTooltipContent[option].title}
                                    content={optionTooltipContent[option].content}
                                />
                            )}
                        </div>
                    );
                })}
                {!isMobile && (
                    <div className="offer-filter-dropdown-select-footer-container">
                        <div
                            className={
                                "offer-filter-dropdown-select-footer-text" +
                                (hasActiveFilter() ? " active" : "")
                            }
                            onClick={() => handleClear()}
                        >
                            Clear
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
