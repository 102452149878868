import { getPossesiveCase } from "../../../common/utils/global";
import { TwoPersonIcon } from "../../../sparrow-ui-library/src/assets/icons/two-person-icon/TwoPersonIcon";
import { NoIncomeIcon } from "../../../sparrow-ui-library/src/assets/icons/no-income-icon/NoIncomeIcon";
import { CreditHistoryIcon } from "../../../sparrow-ui-library/src/assets/icons/credit-history-icon/CreditHistoryIcon";
import { TimerIcon } from "../../../sparrow-ui-library/src/assets/icons/timer-icon/TimerIcon";
import { CompareIcon } from "../../../sparrow-ui-library/src/assets/icons/compare-icon/CompareIcon";
import { PaymentIcon } from "../../../sparrow-ui-library/src/assets/icons/payment-icon/PaymentIcon";
import { BalanceIcon } from "../../../sparrow-ui-library/src/assets/icons/balance-icon copy/BalanceIcon";
import { AwardIcon } from "../../../sparrow-ui-library/src/assets/icons/award-icon/AwardIcon";



export const homeCardsHeroContent = [
    {
        icon: <TwoPersonIcon />,
        mainText: "No cosigner, no problem",
        subText: "You may pre-qualify with or without a cosigner.",
    },
    {
        icon: <NoIncomeIcon />,
        mainText: "No income necessary",
        subText: "You're not required to have an income to submit a request.",
    },
    {
        icon: <CreditHistoryIcon />,
        mainText: "No credit history needed",
        subText: "You're not expected to have years' worth of credit history.",
    },
    {
        icon: <TimerIcon />,
        mainText: "Pre-qualify quickly",
        subText: "Pre-qualification within 20 seconds of submission.",
    },
];

export const oneFormSectionContent = [
    {
        icon: "01",
        mainText: "Check your eligibility",
        subText: "Log in to your Dashboard to pre-qualify for a student loan. It takes three minutes.",
    }, 
    {
        icon: "02",
        mainText: "Compare your rates",
        subText: "Compare the interest rates, total costs, and average monthly payments across your pre-qualified rates.",
    }, 
    {
        icon: "03",
        mainText: "Select your repayment plan",
        subText: "Settle on the repayment plan that matches your financial situation and we’ll bring you to the lender’s website to apply for your loan.",
    }, 
    {
        icon: "04",
        mainText: "Receive your funds",
        subText: "Depending on the type of loan, your funds will either be disbursed to you, your school, or your previous lenders.",
    }
]

export const zeroFeesSectionContent = (companyName: string) => [
    {
        icon: <CompareIcon />,
        mainText: "Compare Real Rates",
        subText: `${getPossesiveCase(companyName)} student loan marketplace shows you real, pre-qualified rates and loan costs.`,
    },
    {
        icon: <PaymentIcon />,
        mainText: "No Pre-Payment Penalties",
        subText: "There’s no prepayment penality if you decide to pay off your loans faster than originally intended.",
    },
    {
        icon: <BalanceIcon />,
        mainText: "May the Best Rate Win",
        subText: "When lenders compete to offer you the best student loan rate, you win.",
    },
    {
        icon: <AwardIcon />,
        mainText: "Automate Your Search",
        subText: `${getPossesiveCase(companyName)} student loan marketplace takes the guess work out of the search process.`,
    }
]
