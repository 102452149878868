import { College } from "../../../../../common/constants/defaultInputs";
import { Address } from "../address";
import { Citizenship } from "../citizenship";
import { Housing } from "../housing";
import { Income } from "../income";
import { MoneyAmount } from "../money-amount";
import { PrequalificationRequestInput } from "./../../../types/sparrow-types/prequalification-request/index";

export const PrequalificationRequest = (): PrequalificationRequestInput => {
    return {
        userType: null,
        loanRefinanceType: "",
        id: undefined,
        loanAmount: MoneyAmount(100000),
        permanentAddress: Address(),
        mailingAddress: Address(),
        dob: "",
        citizenshipStatus: Citizenship(),
        income: Income(),
        enrollment: "",
        housing: Housing(),
        cosigner: null,
        degrees: [College()],
        offers: [],
        type: null,
        status: null,
        loanSelectionType: "", //todo: questions and declined fields missing
        submitted: false,
        gpaLastSemester: undefined,
        gpaCumulative: undefined,
        hoursCompletedCurrentSemester: undefined,
        schoolYearStartDate: undefined,
        schoolYearEndDate: undefined,
        expandedSearch: false,
        ssn: "",
        stepsFinished: 0,
        stepsTotal: 5, //todo: this might be variable
        hasExtraFieldsSet: false,
        offerData: undefined,
        incomeBasedOffers: [],
        hasTakenOffersQuiz: false,
        offersQuizAnswer: null,
        isIncludedUser: false,
        includedUser: undefined,
        isCreator: undefined,
        isIneligibleCosignerCreator: undefined,
        creator: undefined,
        userId: undefined,
        cosignerId: undefined,
        submittedToTrigger: undefined,
        resubmissionRequiresCreditPull: undefined,
        declined: undefined,
        createdAt: undefined,
        updatedAt: undefined,
        updatedAtDays: undefined,
        includedStepsFinished: undefined,
        includedStepsTotal: undefined,
        extraFieldsAreSubmitted: undefined,
        isFullySubmitted: undefined,
    };
};
