import { UserType } from "../../../../types/sparrow-types";
import { getSvgColors } from "../../getSvgColors";

export const CreditCardIcon = ({
    userType,
    isPAAAS = false,
}: {
    userType: UserType;
    isPAAAS?: boolean;
}) => {
    const { primaryColor, accentColor } = getSvgColors(userType);

    const PAAASVersion = (
        <>
            <path
                d="M12 4C12 2.89543 12.8954 2 14 2H29H34C35.1046 2 36 2.89543 36 4V8.125V28C36 29.1046 35.1046 30 34 30H18H14C12.8954 30 12 29.1046 12 28V4Z"
                fill={primaryColor}
            />
            <path
                d="M4 25C4 23.8954 4.89543 23 6 23H19.5833H24C25.1046 23 26 23.8954 26 25V26.5V37C26 38.1046 25.1046 39 24 39H9.5H6C4.89543 39 4 38.1046 4 37V25Z"
                fill={accentColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26 30H18H14C12.8954 30 12 29.1046 12 28V23H19.5833H24C25.1046 23 26 23.8954 26 25V26.5V30Z"
                fill={accentColor}
            />
            <path d="M4 33H19.5833H26V33.6562V36H9.5H4V33Z" fill={primaryColor} />
            <line
                x1="17"
                y1="14"
                x2="31"
                y2="14"
                stroke={accentColor}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <line
                x1="17"
                y1="19"
                x2="25"
                y2="19"
                stroke={accentColor}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <line
                x1="17"
                y1="9"
                x2="31"
                y2="9"
                stroke={accentColor}
                strokeWidth="2"
                strokeLinecap="round"
            />
        </>
    );

    const marketplaceBorrowerVersion = (
        <>
                <path
                    d="M12 4C12 2.89543 12.8954 2 14 2H29H34C35.1046 2 36 2.89543 36 4V8.125V28C36 29.1046 35.1046 30 34 30H18H14C12.8954 30 12 29.1046 12 28V4Z"
                    fill="#185155"
                />
                <path
                    d="M4 25C4 23.8954 4.89543 23 6 23H19.5833H24C25.1046 23 26 23.8954 26 25V26.5V37C26 38.1046 25.1046 39 24 39H9.5H6C4.89543 39 4 38.1046 4 37V25Z"
                    fill="#8CCE56"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26 30H18H14C12.8954 30 12 29.1046 12 28V23H19.5833H24C25.1046 23 26 23.8954 26 25V26.5V30Z"
                    fill="#4EAC6A"
                />
                <path d="M4 33H19.5833H26V33.6562V36H9.5H4V33Z" fill="#185155" />
                <line
                    x1="17"
                    y1="14"
                    x2="31"
                    y2="14"
                    stroke="#4EAC6A"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <line
                    x1="17"
                    y1="19"
                    x2="25"
                    y2="19"
                    stroke="#4EAC6A"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <line
                    x1="17"
                    y1="9"
                    x2="31"
                    y2="9"
                    stroke="#4EAC6A"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
        </>
    );
    // TODO: currently the same as marketplaceBorrowerVersion because cosigner version was never designed. This has been approved for now but should be swapped for cosigner version when available
    const marketplaceCosignerVersion = (
        <>
                <path
                    d="M12 4C12 2.89543 12.8954 2 14 2H29H34C35.1046 2 36 2.89543 36 4V8.125V28C36 29.1046 35.1046 30 34 30H18H14C12.8954 30 12 29.1046 12 28V4Z"
                    fill="#185155"
                />
                <path
                    d="M4 25C4 23.8954 4.89543 23 6 23H19.5833H24C25.1046 23 26 23.8954 26 25V26.5V37C26 38.1046 25.1046 39 24 39H9.5H6C4.89543 39 4 38.1046 4 37V25Z"
                    fill="#8CCE56"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M26 30H18H14C12.8954 30 12 29.1046 12 28V23H19.5833H24C25.1046 23 26 23.8954 26 25V26.5V30Z"
                    fill="#4EAC6A"
                />
                <path d="M4 33H19.5833H26V33.6562V36H9.5H4V33Z" fill="#185155" />
                <line
                    x1="17"
                    y1="14"
                    x2="31"
                    y2="14"
                    stroke="#4EAC6A"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <line
                    x1="17"
                    y1="19"
                    x2="25"
                    y2="19"
                    stroke="#4EAC6A"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
                <line
                    x1="17"
                    y1="9"
                    x2="31"
                    y2="9"
                    stroke="#4EAC6A"
                    strokeWidth="2"
                    strokeLinecap="round"
                />
        </>
    );

    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
             <>
                {isPAAAS
                    ? PAAASVersion
                    : userType === UserType.BORROWER
                    ? marketplaceBorrowerVersion
                    : marketplaceCosignerVersion}
            </>
        </svg>
    );
};
