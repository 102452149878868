"use client";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { RegularButtonWithLoading } from "../../common/components/buttons/regular_button/RegularButtonWithLoading";
import { CountryCode } from "../../common/components/country_code";
import { InputText } from "../../common/components/inputs/input_text/input_text";
import { capitalize } from "../../common/core/helpers/events";
import { useStore } from "../../common/core/providers/global.provider";
import { useOnboarding } from "../../common/core/providers/onboarding.provider";
import { dashboardUrl, signUpStepTypeUrl } from "../../common/core/routes/navigation-urls";
import { Post } from "../../common/core/services/clientApi";
import { userMissingFields } from "../../common/core/services/sparrow-api-endpoints";
import { AuthHandler } from "../../common/core/auth";

export const CompleteSignUpPage = observer(() => {
    const [phoneValid, setPhoneValid] = useState("");
    const { ssoUserData, setSsoUserData, setSnackError, setUserHasMissingFields } = useStore();
    const { jwt } = useOnboarding();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [valid, setIsValid] = useState(false);
    const [form, setForm] = useState({
        firstName: ssoUserData?.firstName ? ssoUserData.firstName : "",
        lastName: ssoUserData?.lastName ? ssoUserData.lastName : "",
        phoneNumber: ssoUserData?.phoneNumber ? ssoUserData.phoneNumber : "",
    });

    const missingFields = useMemo(() => {
        let missingFields = Object.keys(form)
            .filter((formKey) => !ssoUserData.hasOwnProperty(formKey))
            .map((field) =>
                field === "firstName"
                    ? "first name"
                    : field === "lastName"
                    ? "last name"
                    : field === "phoneNumber"
                    ? "phone number"
                    : undefined
            );

        return {
            length: missingFields.length,
            string: missingFields.length
                ? missingFields.length === 1
                    ? missingFields.join("")
                    : missingFields.length === 2
                    ? missingFields.join(" and ")
                    : missingFields.slice(0, missingFields.length - 1).join(", ") +
                      " and " +
                      missingFields[missingFields.length - 1]
                : "information",
        };
    }, [ssoUserData]);

    const reg = "^[a-zA-Z ,.'-]*$";

    const validationMsg = {
        firstName:
            form.firstName.length > 0 && form.firstName.length < 2
                ? "The minimum length must be 2 characters"
                : null,
        lastName:
            form.lastName?.length > 0 && form.lastName.length < 2
                ? "The minimum length must be 2 characters"
                : null,
        phoneNumber: form.phoneNumber?.length === 11 ? null : "",
    };

    const onValueChange = (value: string, key: any) => {
        if (key === "firstName" || key === "lastName") {
            if (value.match(reg) == null) return;
            setForm({ ...form, [key]: capitalize(value) });
        } else {
            if (key === "phoneNumber") {
                setPhoneValid(value.length > 0 && value.length < 11 ? "Invalid phone number" : "");
            }
            setForm({ ...form, [key]: value });
        }
    };

    const onSubmit = useCallback(() => {
        setLoading(true);
        if (jwt) {
            Post(userMissingFields, jwt, form)
                .then(() => {
                    setUserHasMissingFields(false);
                    navigate(dashboardUrl);
                    setSsoUserData({});
                })
                .catch((e) => {
                    setLoading(false);
                    setSnackError("Missing fields saving error", "error");
                    console.log(e);
                });
        } else {
            AuthHandler.currentAuthenticatedUser()
                .then((res) => {
                    if (res) {
                        Post(userMissingFields, res.signInUserSession?.idToken?.jwtToken, form)
                            .then(() => {
                                setUserHasMissingFields(false);
                                navigate(dashboardUrl);
                                setSsoUserData({});
                            })
                            .catch((e) => {
                                setLoading(false);
                                setSnackError("Fields saving error", "error");
                                console.log(e);
                            });
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    setIsValid(false);
                    e?.message
                        ? setSnackError(e.message, "error")
                        : setSnackError("Authentication error", "error");
                });
        }
    }, [form, valid, jwt]);

    const checkValidation = () => {
        const errArr = Object.entries(form).filter(([key, value]) => {
            // @ts-ignore
            return validationMsg[key] !== null || value.length === 0;
        });
        setIsValid(!errArr.length);
    };

    useEffect(() => {
        checkValidation();
    }, [form]);

    useEffect(() => {
        const onSubmitEnterKey = (e: KeyboardEvent) => {
            if (valid && e.key === "Enter") {
                onSubmit();
            }
        };
        document.addEventListener("keypress", onSubmitEnterKey);
        return () => {
            document.removeEventListener("keypress", onSubmitEnterKey);
        };
    }, [valid]);

    return (
        <div className="auth-flow-container--inner">
            <a className="step-form-overhead" href={signUpStepTypeUrl}>
                <span className="icon-arrow-right" />
                Previous step
            </a>
            <div className="step-form-header">
                <h2 className="h2">Complete registration</h2>
                <div className="subtitle">
                    Your Gmail account {ssoUserData?.email ? ssoUserData.email : ""} is missing your{" "}
                    {missingFields.string}. Please enter{" "}
                    {missingFields.length === 1 ? "it" : "them"} below to complete registration.
                </div>
            </div>

            <div className="step-form-body">
                <div className="step-form-block">
                    <div className="form-row">
                        <div className="form-col-12 form-col">
                            <CountryCode
                                name="phoneNumber"
                                value={form.phoneNumber}
                                onChange={(value: any) => onValueChange(value, "phoneNumber")}
                                error={phoneValid}
                                onClear={() => onValueChange("1", "phoneNumber")}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <InputText
                            value={form.firstName}
                            name="firstName"
                            type="text"
                            label="First name"
                            placeholder="Enter first name"
                            onChange={(e: any) => onValueChange(e.target.value, e.target.name)}
                            onClear={() => onValueChange("", "firstName")}
                            maxLength={60}
                        />
                    </div>
                    <div className="form-row">
                        <InputText
                            value={form.lastName}
                            name="lastName"
                            type="text"
                            label="Last name"
                            placeholder="Enter last name"
                            onChange={(e: any) => onValueChange(e.target.value, e.target.name)}
                            onClear={() => onValueChange("", "lastName")}
                            maxLength={60}
                        />
                    </div>
                </div>
            </div>
            <div className="step-form-footer">
                <div className="button-group button-group-privacy">
                    <RegularButtonWithLoading
                        loading={loading}
                        id="registration-submission-link"
                        onSubmit={() => onSubmit()}
                        disabled={!valid}
                    >
                        Complete Registration
                    </RegularButtonWithLoading>
                </div>
            </div>
        </div>
    );
});
