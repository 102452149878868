import { isMarketplaceEnv } from "../../../../../common/utils/config";
import { UserType } from "../../../types/sparrow-types";
import { getSvgColors } from "../getSvgColors";

export const TipsNewsIcon = ({ userType }: { userType: UserType }) => {
    const { primaryColor, accentColor } = getSvgColors(userType);

    const borrowerMarketplaceVersion = (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.80078 17.5992C8.80078 16.2737 9.8753 15.1992 11.2008 15.1992H27.2008C27.6426 15.1992 28.0008 15.5574 28.0008 15.9992V27.1992C28.0008 28.0829 27.2844 28.7992 26.4008 28.7992H11.2008C9.8753 28.7992 8.80078 27.7247 8.80078 26.3992V17.5992Z" fill="#8CCE56" />
            <path d="M4.80078 5.59922C4.80078 4.27374 5.8753 3.19922 7.20078 3.19922H20.8008C22.5681 3.19922 24.0008 4.63191 24.0008 6.39922V26.7992C24.0008 27.9038 24.8962 28.7992 26.0008 28.7992H7.20078C5.8753 28.7992 4.80078 27.7247 4.80078 26.3992V5.59922Z" fill="#185155" />
            <path d="M8.80078 22.3984H15.2008" stroke="#4EAC6A" strokeWidth="2" strokeLinecap="round" />
            <path d="M8.80078 17.6016H20.0008" stroke="#4EAC6A" strokeWidth="2" strokeLinecap="round" />
            <path d="M8 7.99922C8 7.55739 8.35817 7.19922 8.8 7.19922H16.8C17.2418 7.19922 17.6 7.55739 17.6 7.99922V11.9992C17.6 12.441 17.2418 12.7992 16.8 12.7992H8.8C8.35817 12.7992 8 12.441 8 11.9992V7.99922Z" fill="#4EAC6A" />
        </svg>
    )

    const cosignerMarketplaceVersion = (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.80078 17.5992C8.80078 16.2737 9.8753 15.1992 11.2008 15.1992H27.2008C27.6426 15.1992 28.0008 15.5574 28.0008 15.9992V27.1992C28.0008 28.0829 27.2844 28.7992 26.4008 28.7992H11.2008C9.8753 28.7992 8.80078 27.7247 8.80078 26.3992V17.5992Z" fill="#EEBCB0" />
            <path d="M4.80078 5.59922C4.80078 4.27374 5.8753 3.19922 7.20078 3.19922H20.8008C22.5681 3.19922 24.0008 4.63191 24.0008 6.39922V26.7992C24.0008 27.9038 24.8962 28.7992 26.0008 28.7992H7.20078C5.8753 28.7992 4.80078 27.7247 4.80078 26.3992V5.59922Z" fill="#A0584A" />
            <path d="M8.80078 22.4004H15.2008" stroke="#DC7962" strokeWidth="2" strokeLinecap="round" />
            <path d="M8.80078 17.5996H20.0008" stroke="#DC7962" strokeWidth="2" strokeLinecap="round" />
            <path d="M8.5 7.99922C8.5 7.83353 8.63431 7.69922 8.8 7.69922H16.8C16.9657 7.69922 17.1 7.83353 17.1 7.99922V11.9992C17.1 12.1649 16.9657 12.2992 16.8 12.2992H8.8C8.63431 12.2992 8.5 12.1649 8.5 11.9992V7.99922Z" fill="#DC7962" stroke="#DC7962" />
        </svg>
    )

    const getMarketplaceVersion = () => {
        return userType === UserType.BORROWER ? borrowerMarketplaceVersion : cosignerMarketplaceVersion;
    }

    const paaasVersion = (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="TipsNewsIcon"
        >
            <path
                d="M6.12891 11.6742C6.12891 10.8396 6.80545 10.1631 7.64002 10.1631H17.7141C17.9923 10.1631 18.2178 10.3886 18.2178 10.6668V17.7186C18.2178 18.275 17.7668 18.726 17.2104 18.726H7.64002C6.80545 18.726 6.12891 18.0495 6.12891 17.2149V11.6742Z"
                fill={accentColor}
            />
            <path
                d="M3.61328 4.11853C3.61328 3.28397 4.28983 2.60742 5.12439 2.60742H13.6874C14.8001 2.60742 15.7022 3.50949 15.7022 4.62224V17.4667C15.7022 18.1621 16.266 18.7259 16.9614 18.7259H5.12439C4.28983 18.7259 3.61328 18.0494 3.61328 17.2148V4.11853Z"
                fill={primaryColor}
            />
            <path
                d="M6.12891 14.6963H10.1585"
                stroke={accentColor}
                strokeWidth="1.25926"
                strokeLinecap="round"
            />
            <path
                d="M6.12891 11.6743H13.1808"
                stroke={accentColor}
                strokeWidth="1.25926"
                strokeLinecap="round"
            />
            <path
                d="M5.625 5.62968C5.625 5.35149 5.85052 5.12598 6.1287 5.12598H11.1657C11.4439 5.12598 11.6694 5.35149 11.6694 5.62968V8.1482C11.6694 8.42639 11.4439 8.6519 11.1657 8.6519H6.1287C5.85052 8.6519 5.625 8.42639 5.625 8.1482V5.62968Z"
                fill={accentColor}
            />
        </svg>
    )

    return (isMarketplaceEnv() ? getMarketplaceVersion() : paaasVersion);
};

