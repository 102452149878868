"use client";
import CheckEmail from "../../common/views/check_email";

export const ConfirmLoginPage = () => {
    return (
        <div className="auth-flow-container--inner">
            <CheckEmail />
        </div>
    );
};
