
import "./styles.scss";

export const ButtonWrapper = ({children, centerButtons = false}) => {
  return (
    <div className={`btn-group-wrapper ${centerButtons ? "center-buttons" : ""}`}>
        {children}
    </div>
  )
}

