import { createContext, useContext } from 'react';
import { GlobalStore } from '../../store/global';


let store: any;
export const GlobalStoreContext = createContext<GlobalStore>({} as GlobalStore)

export function useStore() {
  const context = useContext(GlobalStoreContext)
  if (context === undefined) {
    throw new Error('useStore must be used within StoreProvider')
  }

  return context
}

function initializeStore(initialData:any = null) {
  const _store = store ?? new GlobalStore()

  // If your page has Next.js data fetching methods that use a Mobx store, it will
  if (initialData) {
    _store.hydrate(initialData)
  }
  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store
  // Create the store once in the client
  if (!store) store = _store

  return _store
}

export function GlobalStoreProvider({ children, initialState: initialData }: { children: React.ReactNode, initialState?: any }) {
  const store = initializeStore(initialData)

  return <GlobalStoreContext.Provider value={store}> {children} </GlobalStoreContext.Provider>
}