import { observer } from "mobx-react";
import { useEffect } from "react";
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider";
import { RoadmapStep } from "../../components/RoadmapStep/RoadmapStep";
import { ProgressPopupWrapper } from "../../components/ProgressPopupWrapper/ProgressPopupWrapper";
import { UserType } from "../../../../common/constants/defaultInputs";
import { useMediaQuery } from "react-responsive";
import { Scrollbar } from "react-scrollbars-custom";
import { AppModal } from "../../../../common/components/modals/AppModal";
import { useRef, useState } from "react";
import { ProgressStatuses } from "../../../../common/models/request";


export const RoadmapStepsContainerMobile = observer(() => {
    const onboarding = useOnboarding();
    const scrollContainerRef = useRef<Scrollbar>();
    const [showModal, setShowModal] = useState(false);
    const [showStep, setShowStep] = useState(true);
    const [arrowXPosition, setArrowXPosition] = useState(0);

    // close modal on click
    const onClickCloseModal = () => {
        setShowModal(false);
    };

    //on click of any step, set isActive to true for that step and false for all other steps. This is so a hover bubble displays only for an active step
    const onStepClick = (e, isIncompleteStep: boolean) => {
        if (isIncompleteStep || !onboarding.activeProgressRoadmap) return;
        const copyActiveSteps = [...onboarding.activeProgressRoadmap];

        if (scrollContainerRef.current) {
            let position = e.target.getBoundingClientRect();
            if (position.left <= 0) {
                scrollContainerRef.current.scrollTo(
                    scrollContainerRef.current.scrollLeft + position.left - 40
                );
            }
        }

        copyActiveSteps.forEach((step) => {
            if (step.progressStatus === e.currentTarget.id) {
                step.isActive = true;
                setShowStep(true);
            } else {
                step.isActive = false;
            }
        });
        onboarding.setActiveProgressRoadmap(copyActiveSteps);
    };

    const isLess768px = useMediaQuery({
        query: "(max-width: 767px)",
    });


    //TODO: this feels like a custom hook
    const setArrowPosition = () => {
        let currentActive = document.querySelector(".rm-step-container.active");
        let popupContainer = document.querySelector(".rm-popup-content-container");
        if (!currentActive) return;
        let { left, width, right } = currentActive.getBoundingClientRect();
        if (popupContainer) {
            let { left: pupLeft, width: pupWidth } = popupContainer.getBoundingClientRect();
            let activePos = left + width / 2 - 40;
            setArrowXPosition(
                activePos < pupLeft + 40
                    ? pupLeft + 40
                    : activePos > pupLeft + pupWidth - 80
                        ? pupLeft + pupWidth - 80
                        : activePos
            );
            if (activePos < pupLeft - 40 || activePos > pupLeft + pupWidth + 40) {
                setShowStep(false);
            }
        } else {
            setArrowXPosition(left + width / 2 - 40);
            if (right < 150) {
                setShowStep(false);
            }
        }
    };

    useEffect(() => {
        setArrowPosition();
    }, [onboarding.activeProgressRoadmap]);

    return (
        <>
        {onboarding.activeProgressRoadmap && <div className="rm-steps-container__small-device">
            <Scrollbar
                ref={(ref) => {
                    scrollContainerRef.current = ref;
                }}
                noScrollY={true}
                style={{ height: "185px" }}
                className={"rm-scrollbar"}
                // scrollLeft={defaultScrollPosition}
                onScroll={(e) => {
                    setArrowPosition();
                }}
            >
                <div className="rm-steps-container__small-device-items">
                    {onboarding.activeProgressRoadmap.map((step, index) => {
                        const stateOfStep = {
                            isActive: step.isActive,
                            isLatestIncompleteStep: step.isLatestIncompleteStep,
                            isCompleteStep: step.isCompleteStep,
                        }

                        // if step is incomplete, do not allow user to click on it
                        const isIncompleteStep = !step.isCompleteStep && !step.isLatestIncompleteStep;
                        return (
                            <div
                                className={`rm-steps-container__small-device-item ${step.isActive
                                        ? "rm-steps-container__small-device-item"
                                        : ""
                                    }`}
                                key={`rm-steps-container__small-device-item-${index}`}
                            >
                                <RoadmapStep
                                     id={step.progressStatus}
                                     key={step.progressStatus}
                                     stepNum={index + 1}
                                     progressStatus={step.progressStatus}
                                     stateOfStep={stateOfStep}
                                     userType={onboarding.formData.userType as UserType}
                                    // if step is incomplete, do not allow user to click on it
                                    onStepClick={(e) => {
                                        onStepClick(
                                            e,
                                            isIncompleteStep
                                        );
                                        step.isActive && setShowModal(true);
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
            </Scrollbar>
            <div className="rm-steps-container__small-device-step-content">
                {onboarding.activeProgressRoadmap.map((step, index) => {
                    return isLess768px
                        ? step.isActive && showModal && (
                            <AppModal
                                key={`rm-steps-container__small-device-step-content-modal-${index}`}
                                enableMobileView
                                onClose={onClickCloseModal}
                                showCloseIcon
                                noPadding
                                customClass={
                                    `rm-steps-container__small-device-step-content-modal 
                                                  ${onboarding.formData.includedUser
                                        && onboarding.formData.includedUser.hasOwnProperty("status")
                                        && onboarding.formData.includedUser.status
                                        ? onboarding.formData.includedUser.status
                                        : ''
                                    }
                                                  ${onboarding.formData.includedUser
                                        && onboarding.formData.includedUser.hasOwnProperty("userType")
                                        && onboarding.formData.includedUser.userType
                                        ? `invite-type-${onboarding.formData.includedUser.userType}`
                                        : ''
                                    }
                                                  ${onboarding.formData.userType
                                        ? onboarding.formData.userType
                                        : ''
                                    }
                                                  `
                                }
                                closeButtonPosition={
                                    (step.progressStatus ===
                                        ProgressStatuses.Prequalify && !onboarding.activePostPrequalRequestData?.compareAndSelect?.offerExplored)
                                        ? "left"
                                        : "right"
                                }
                            >
                                <ProgressPopupWrapper
                                    isMobile
                                    progressStatus={step.progressStatus}
                                    disableEditButton={onboarding.activePostPrequalRequestData?.compareAndSelect?.offerExplored}
                                />
                            </AppModal>
                        )
                        : step.isActive && showStep && (
                            <div className={`rm-steps-container-step-${index}`} key={`rm-steps-container-step-${index}`} >
                                <span
                                    className="arrow"
                                    style={
                                        arrowXPosition ? { left: arrowXPosition } : {}
                                    }
                                >
                                    <svg
                                        width="14"
                                        height="10"
                                        viewBox="0 0 14 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M5.26842 0.999999C6.03822 -0.333335 7.96272 -0.333333 8.73252 1L13.9287 10H0.0722656L5.26842 0.999999Z"
                                            fill="white"
                                        />
                                    </svg>
                                </span>
                                <ProgressPopupWrapper
                                    progressStatus={step.progressStatus}
                                />
                            </div>
                        );
                })}
            </div>
        </div>
    }
    </>
    )
});
