import { UserType } from "../../../types/sparrow-types";

export const UserMgmtIcon = ({ userType }: { userType: UserType }) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="40" height="40" rx="12" fill={`rgba(var(--${userType}PrimaryColor), 1)`} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4473 21.8481C12.5997 21.5432 12.9114 21.3506 13.2523 21.3506H26.7523C26.991 21.3506 27.2199 21.4454 27.3887 21.6142C27.5334 21.7589 27.604 21.9184 27.638 22.0081C27.6765 22.11 27.6998 22.2101 27.7149 22.2954C27.7453 22.4667 27.756 22.6556 27.7545 22.8442C27.7515 23.2257 27.6975 23.6948 27.5814 24.1966C27.3514 25.1913 26.8513 26.4245 25.8888 27.387C25.0341 28.2417 24.1928 28.8839 23.2257 29.3043C22.2547 29.7263 21.2166 29.8989 20.0035 29.9006C18.7543 29.9023 17.6447 29.7256 16.6472 29.3013C15.6428 28.874 14.8006 28.2163 14.0604 27.326C13.3396 26.4592 12.7796 25.4892 12.4669 24.5688C12.1684 23.6904 12.035 22.6727 12.4473 21.8481ZM13.9908 23.1506C14.0032 23.3676 14.0545 23.6464 14.1712 23.9897C14.403 24.6718 14.843 25.4519 15.4444 26.1752C16.0263 26.875 16.6442 27.3439 17.3518 27.6449C18.0662 27.9488 18.9205 28.1021 20.001 28.1006C21.0389 28.0992 21.8198 27.9527 22.5082 27.6535C23.2004 27.3526 23.8588 26.8714 24.616 26.1142C25.2655 25.4648 25.6456 24.5783 25.8277 23.7911C25.8824 23.5547 25.9168 23.3374 25.9358 23.1506H13.9908Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.8031 14.0002C24.8031 16.6512 22.6541 18.8002 20.0031 18.8002C17.3522 18.8002 15.2031 16.6512 15.2031 14.0002C15.2031 11.3492 17.3522 9.2002 20.0031 9.2002C22.6541 9.2002 24.8031 11.3492 24.8031 14.0002ZM20.0031 17.0002C21.66 17.0002 23.0031 15.657 23.0031 14.0002C23.0031 12.3433 21.66 11.0002 20.0031 11.0002C18.3463 11.0002 17.0031 12.3433 17.0031 14.0002C17.0031 15.657 18.3463 17.0002 20.0031 17.0002Z"
                fill="white"
            />
        </svg>
    );
};
