import "./cross-close-icon.css";

const CrossCloseIcon = ({onClick}: {onClick: () => void}) => {
    return (
        <button onClick={onClick}>
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="CrossCloseIcon"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.29298 4.29289C4.68351 3.90237 5.31667 3.90237 5.7072 4.29289L19.7072 18.2929C20.0977 18.6834 20.0977 19.3166 19.7072 19.7071C19.3167 20.0976 18.6835 20.0976 18.293 19.7071L4.29298 5.70711C3.90246 5.31658 3.90246 4.68342 4.29298 4.29289Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.7071 4.2929C20.0976 4.68342 20.0976 5.31659 19.7071 5.70711L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L18.2929 4.2929C18.6834 3.90237 19.3166 3.90237 19.7071 4.2929Z"
                fill="white"
            />
        </svg>
        </button>
    );
};

export default CrossCloseIcon;
