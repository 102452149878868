import { FC } from "react";
import { TipsCard } from "../tips-card";
import { Tip } from "../../../types/tip";
import "./tips-content.styles.scss";

export const TipsContent:FC<{tips: Tip[]}> = ({tips}) => {
  
  return (
    <div className="TipCardsContainer">
      {tips.map((tip, i) => {
        const { title, body } = tip;
        return <TipsCard key={`tip-${i}`} title={title} body={body} index={i} />
      })}
    </div>
  );
};


