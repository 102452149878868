import { AuthFlowLayout } from "../../common/components/auth-component/authFlowLayout";
import { SignUpRoutes } from "./routes";

export default function SignUpWrapper() {
    return (
        <AuthFlowLayout>
            <SignUpRoutes />
        </AuthFlowLayout>
    );
}
