import {
    FormFieldType,
    FormFieldName,
    FormInputsConfig,
    DependentFormFieldProps,
    FormsConfig,
} from "../../../sparrow-ui-library/src/types/form-types";
import { Citizenship } from "../../../sparrow-ui-library/src/constants/default-inputs/citizenship";
import { citizenshipTypes} from "../../../sparrow-ui-library/src/constants/dropdown-options";
import { getDependentFieldsConfig } from "./dependent-fields-config";
import { PrequalificationRequestInput } from "../../../sparrow-ui-library/src/types/sparrow-types";
import { AsyncDropdownValues } from "../../../sparrow-ui-library/src/types/marketplace-paaas-types";

export const getFormsConfig = (inputs: FormsConfig): FormInputsConfig => {
    const { applicableFormData, getApplicableUserText, asyncDropdownValues } = inputs as {
        applicableFormData: PrequalificationRequestInput;
        getApplicableUserText: (text: string) => string;
        asyncDropdownValues:  AsyncDropdownValues;
      };
    
    const hasCitizenshipType = !!applicableFormData?.citizenshipStatus.type;
    const isUsCitizen = applicableFormData?.citizenshipStatus.type === "US Citizen or National";
    const hasVisaType = !!applicableFormData?.citizenshipStatus.visaType;
    const hasHomeCountry = !!applicableFormData?.citizenshipStatus.homeCountry;

    //Dependent fields
    const { visaStatus, homeCountry } = getDependentFieldsConfig({applicableFormData, getApplicableUserText, asyncDropdownValues});

    return {
        forms: [
            {
                formType: "radioButtons",
                // For error messaging purposes
                optionSelected: hasCitizenshipType,
                errorMessage: "",
                radioButtonOptions: [
                    {
                        id: "citizenshipStatusType-0",
                        name: FormFieldName.CitizenshipStatus,
                        label: "Yes",
                        defaultButtonValue: Citizenship({
                            type: "US Citizen or National",
                            hasSSN: true,
                        }),
                        inputValue: isUsCitizen
                            ? Citizenship({ type: "US Citizen or National", hasSSN: true })
                            : "",
                        checked: hasCitizenshipType && isUsCitizen,
                    },
                    {
                        id: "citizenshipStatusType-1",
                        name: FormFieldName.CitizenshipStatus,
                        label: "Other",
                        checked: hasCitizenshipType && !isUsCitizen,
                        independentFields: [
                            {
                                id: FormFieldName.CitizenshipStatusHasSSN,
                                formFieldType: FormFieldType.ReadonlyDropdown,
                                name: FormFieldName.CitizenshipStatusHasSSN,
                                label: getApplicableUserText("Do you have a SSN?"),
                                placeholder: "Select",
                                inputValue: applicableFormData.citizenshipStatus.hasSSN ?? "",
                                backendValue: "",
                                errorMessage: "",
                                dropdownOptions: [
                                    { label: "Yes", value: true },
                                    { label: "No", value: false },
                                ],
                            },
                            {
                                id: FormFieldName.CitizenshipStatusType,
                                formFieldType: FormFieldType.ReadonlyDropdown,
                                name: FormFieldName.CitizenshipStatusType,
                                label: getApplicableUserText("What's your citizenship status?"),
                                placeholder: "Select",
                                inputValue: applicableFormData.citizenshipStatus.type ?? "",
                                backendValue: "",
                                errorMessage: "",
                                dropdownOptions: citizenshipTypes.slice(1),
                                dependentFieldNames: [
                                    FormFieldName.CitizenshipStatusHomeCountry,
                                    FormFieldName.CitizenshipStatusVisaType,
                                ],
                            },
                            // Conditional fields based on previously submitted citizenship status selections
                            ...(hasHomeCountry ? [homeCountry] : []),
                            ...(hasVisaType ? [visaStatus] : []),
                        ],
                        dependentFields: [visaStatus, homeCountry] as DependentFormFieldProps[],
                    },
                ],
            },
        ],
    };
};
