import { MembershipElection } from "../../../../sparrow-ui-library/src/types/sparrow-types/membership-election";
import { ProgressRoadmap } from "../../../models/request";

import {
    GetExpandedSearchMetadata,
    GetProgressRoadmaps,
    IExpandedSearchMetadata,
} from "../sparrow-api-handlers/prequalification-api-handlers";

enum SortByField {
    CREATED_AT = "createdAt",
    UPDATED_AT = "updatedAt",
    EDITED_AT = "editedAt",
}

/**
 * Sorts loan request by a specific field
 * @param requests
 * @param sortByField
 * @returns ProgressRoadmap[]
 */
export const sortLoanRequests = (
    roadmaps: ProgressRoadmap[],
    sortByField: SortByField
): ProgressRoadmap[] => {
    return roadmaps.sort((a, b): any => {
        if (
            a.progress.prequalify.request[sortByField] &&
            b.progress.prequalify.request[sortByField]
        ) {
            return (
                b.progress.prequalify.request[sortByField] -
                a.progress.prequalify.request[sortByField]
            );
        }
        return 0;
    });
};
/**
 * Gets and sets the user's loan requests
 * @param contextStore
 * @returns Promise<ProgressRoadmap[] | null>
 */
export const getSortedUserProgressRoadmaps = async (
    token: string
): Promise<{ sortedRoadmaps: ProgressRoadmap[]; membershipElection?: MembershipElection }> => {
    const response = await GetProgressRoadmaps(token);
    const sortedRoadmaps = response.roadmaps.length
        ? sortLoanRequests(response.roadmaps, SortByField.UPDATED_AT)
        : [];

    return {
        sortedRoadmaps,
        membershipElection: response.membershipElection,
    };
};

export const getExpandedSearchMetadata = async (): Promise<IExpandedSearchMetadata | null> => {
    try {
        const expandedSearchMetadata = await GetExpandedSearchMetadata();
        return expandedSearchMetadata;
    } catch {}

    return null;
};
