import { PopupContentContainer } from "../../containers/PopupContentContainer";
import { IncludedUserStatus } from "../../../../sparrow-ui-library/src/types/sparrow-types";
import { ProgressStatuses } from "../../../../common/models/request";
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider";
import { observer } from "mobx-react";
import { CSSProperties } from "react";
import { OnboardingStore } from "../../../../common/store/onboarding";
import { UserType } from "../../../../common/constants/defaultInputs";


interface ProgressPopupProps {
    progressStatus: ProgressStatuses;
    isMobile?: boolean;
    disableEditButton?: boolean;
  }

//Popup container width is dependent on popup to display
const getProgressPopupWidth = (onboarding: OnboardingStore, stepContent: ProgressStatuses) => {

    const offersCount = Number(onboarding.activePostPrequalRequestData?.compareAndSelect?.offersData.numberOfOffers);
    const lenderData = onboarding.activePostPrequalRequestData?.applyWithLender?.lenderData;
    const cosignerNotBeingIncluded = onboarding.formData.includedUser?.userType === UserType.COSIGNER && onboarding.formData.includedUser?.status === IncludedUserStatus.DIRECT_STARTED;

    const exploreOtherOptionsWithoutCosigner = stepContent === ProgressStatuses.CompareAndSelect && offersCount === 0 && !cosignerNotBeingIncluded;
    const offerExplored = stepContent === ProgressStatuses.Prequalify && lenderData;



    if (exploreOtherOptionsWithoutCosigner || offerExplored) {
        //  5 steps, spanning 3 steps
        const width = 100 / 5 * 3;
        return `${width}%`;
    }

    return "100%";
}

const getWrapperClass = (onboarding, stepContent: ProgressStatuses, isMobile?: boolean ) => {
    return {
        position: isMobile ? "initial" : "absolute" as CSSProperties["position"],
        left: "0px",
        top: 'calc(100% - 20px)',
        width: getProgressPopupWidth(onboarding, stepContent),
    }
}

export const ProgressPopupWrapper = observer(({progressStatus: stepContent, isMobile, disableEditButton = false}: ProgressPopupProps) => {
    const onboarding = useOnboarding();

    
    
    return (
        <div className={`rm-popup-content-container rm-popup-content-container-${stepContent.replace(/ /g,'')} ${disableEditButton ? 'rm-popup-content-container__disable-edit' : ''}`} style={{ ...getWrapperClass(onboarding, stepContent, isMobile)}}>
            <PopupContentContainer progressStatus={stepContent} />
        </div>
    )
});