import React from "react";
import { ReviewCardText } from "../../../utils/dynamic-page-text";
import { IconTypeEnum } from "../../../../../common/components/icon-buttons/IconButton";
import { IconButton } from "../../../../../common/components/icon-buttons/IconButton";
import { useOnboarding } from "../../../../../common/core/providers/onboarding.provider";
import { getReviewCardSvg } from "../../../../../assets/icons/svg-get-functions/getReviewCardSvg";
import "./styles.scss";
/*
Description: Review Card is a component used in the Review Page, such that a user can review the information they have entered for any given step in prequalification

Latest Figma Designs:
Solo Borrower: https://www.figma.com/file/Vwc8KmfCQ31VS28jOwBwYM/2.1.Sparrow.Borrower.Portal.NEW.Flows?type=design&node-id=2790-402268&t=l5AF565XGIWK2Tai-0
Cosigned Borrower: https://www.figma.com/file/Vwc8KmfCQ31VS28jOwBwYM/2.1.Sparrow.Borrower.Portal.NEW.Flows?type=design&node-id=2790-410557&t=l5AF565XGIWK2Tai-0
Cosigner: https://www.figma.com/file/Vwc8KmfCQ31VS28jOwBwYM/2.1.Sparrow.Borrower.Portal.NEW.Flows?type=design&node-id=2790-402141&t=l5AF565XGIWK2Tai-0
Included Borrower: https://www.figma.com/file/Vwc8KmfCQ31VS28jOwBwYM/2.1.Sparrow.Borrower.Portal.NEW.Flows?type=design&node-id=2790-410305&t=l5AF565XGIWK2Tai-0
As of: May 9, 2023
*/

interface ReviewCardProps extends ReviewCardText {
    onEdit: (editUrl?: string) => void;
}

const RowInformation = ({ leftText, rightText }) => {
    return (
        <div className="rc-row">
            <p className="rc-left">{leftText}</p>
            <p className="rc-right">{rightText}</p>
        </div>
    );
};

export const ReviewCard: React.FC<ReviewCardProps> = (props) => {
    const onboarding = useOnboarding();
    const { creatorIsIncludingUser } = onboarding;

    return (
        <div className="step-form-block">
            <div className="rc-first-row">
                <h2 style={{ fontSize: "20px", lineHeight: "28px", fontWeight: "600" }}>
                    {props.title}
                </h2>
                {/* We do not want to allow editing of ssn on the direct inclusion review view */}
                {!(creatorIsIncludingUser && props.id === "review-ssn") && (
                    <IconButton
                        isReview={true}
                        iconType={IconTypeEnum.edit}
                        onClick={() => props.onEdit(props.editUrl)}
                    />
                )}
            </div>
            <div className="rc-second-row">
                <div style={{ display: "flex", gap: "16px" }}>
                    {props.icon && (
                        <div className="rc-icon-container">
                            {getReviewCardSvg(props.icon)}
                        </div>
                    )}
                    <div>
                        {props.reviewFieldTitle && (
                            <p className="rc-field-title">{props.reviewFieldTitle}</p>
                        )}
                        {props.reviewFieldValue && (
                            <p className="rc-field-value">{props.reviewFieldValue}</p>
                        )}
                    </div>
                </div>
            </div>
            {props.subFields && (
                <>
                    <div className="rc-form-information">
                        {props.subFields.map((subField, index) => {
                            return (
                                <RowInformation
                                    key={index}
                                    leftText={subField.reviewFieldTitle}
                                    rightText={subField.reviewFieldValue}
                                />
                            );
                        })}
                    </div>
                </>
            )}
        </div>
    );
};
