import { useEffect, useState } from "react";
import { RadioButtons } from "../../../common/components/RadioButtons/RadioButtons";
import { useNavigate } from "react-router-dom";
import { useOnboarding } from "../../../common/core/providers/onboarding.provider";
import { Button, ButtonTypes } from "../../../common/components/buttons/button/Button";
import { UserType } from "../../../common/constants/defaultInputs";
import { enumUserInclusionType } from "../../../common/store/onboarding";
import { useStore } from "../../../common/core/providers/global.provider";
import { ConfirmDirectPermissionModal } from "../../../common/components/modals/User_Inclusion_Modals/ConfirmDirectPermissionModal";
import { prequalificationReviewUrl, userInclusionInformationUrl } from "../../../common/core/routes/navigation-urls";
import { observer } from "mobx-react";
import { ButtonWrapper } from "../../../common/components/buttons/button/ButtonWrapper";

const getRadioButtonOptions = (userType: UserType) => {
    return [
        {
            id: "sign-up",
            name: enumUserInclusionType.DIRECT,
            isChecked: false,
            value: { title: `Sign up my ${userType}` },
        },
        {
            id: "invite",
            name: enumUserInclusionType.INVITED,
            isChecked: false,
            value: { title: `Invite my ${userType}` },
        },
    ];
};

export const UserInclusionSelectUser = observer(() => {
    const navigate = useNavigate();
    const store = useStore();
    const onboarding = useOnboarding();

    const inviteeUserType = onboarding.getOppositeUserType();

    const [userInclusionType, setUserInclusionType] = useState<enumUserInclusionType | null>(null);
    const [radioButtonOptions, setRadioButtonOptions] = useState<any[]>(
        getRadioButtonOptions(inviteeUserType as UserType)
    );
    onboarding.setReviewStepReached(false);

    const onKeyEnterPressOnButton = (e: React.KeyboardEvent | KeyboardEvent) => {
        if (e.key === "Enter") {
            return handleContinue();
        }
    };

    const handleContinue = () => {
        if (userInclusionType === enumUserInclusionType.DIRECT) {
            onboarding.setInvitedUserType(inviteeUserType as UserType);
            onboarding.setUserInclusionType(userInclusionType);
            return store.setModal(
                () => (
                    <ConfirmDirectPermissionModal
                        invitedUserType={inviteeUserType}
                        emailRegistered={false}
                    />
                ),
                false,
                true,
                "sm"
            );
        }

        onboarding.setInvitedUserType(inviteeUserType as UserType);
        onboarding.setUserInclusionType(userInclusionType);
        navigate(userInclusionInformationUrl);
    };

    useEffect(() => {
        const invitedUserType = onboarding.getOppositeUserType();

        setRadioButtonOptions(getRadioButtonOptions(invitedUserType as UserType));
    }, [onboarding.formData.userType]);

    return (
        <>
            <div className="step-form-body">
                <RadioButtons
                    options={radioButtonOptions}
                    setForm={setUserInclusionType}
                    userType={onboarding.formData.userType as UserType}
                    customCheckBorderRadius={7}
                />
            </div>
            <div className="step-form-footer">
                <ButtonWrapper>
                {/* Include a prev step button when a cosigner creator is including their borrower. This should take them back to the review step*/}
                {onboarding.formData.userType === UserType.COSIGNER && <Button
                    id="previous-button"
                    tabIndex={1}
                    onKeyPress={(e) => onKeyEnterPressOnButton(e)}
                    onClick={() => navigate(prequalificationReviewUrl)}
                    buttonType={ButtonTypes.SECONDARY}
                    userType={onboarding.formData.userType}
                >
                    Previous step
                </Button>}
                    <Button
                        id="button"
                        tabIndex={0}
                        onKeyPress={(e) => onKeyEnterPressOnButton(e)}
                        onClick={handleContinue}
                        showIcon={true}
                        buttonType={ButtonTypes.PRIMARY}
                        userType={onboarding.formData.userType as UserType}
                    >
                        Continue
                    </Button>
                </ButtonWrapper>
            </div>
        </>
    );
});
