import RateCardDirectApply from "../OfferCard/variations/direct-apply/RateCardDirectApply";

import greenLock from "../../../assets/images/icons/greenLock.svg";
import { FC, RefObject } from "react";
import {
    LenderToolTip,
    getLenderDisclosure,
} from "../../../app/borrower/offers/lender-offers/page";
import { WordpressRateTable } from "../../views/offers/components/table/table";

interface ApplyDirectlyContainerProps {
    applyDirectlyRef: RefObject<HTMLInputElement>;
    wordpressLenderContent: WordpressRateTable[];
    lenderLinks: any;
    lendersRanPrequalificationOn: string[];
    tooltips: LenderToolTip[];
}

export const ApplyDirectlyContainer: FC<ApplyDirectlyContainerProps> = ({
    applyDirectlyRef,
    wordpressLenderContent,
    lenderLinks,
    lendersRanPrequalificationOn,
    tooltips,
}) => {
    return (
        <div className="direct-apply-container" ref={applyDirectlyRef}>
            <div className="direct-apply-title-section">
                <div className="direct-apply-title-container">
                    <img src={greenLock} alt="green lock" />
                    <div className="direct-apply-title">
                        You can apply directly with these lenders without pre-qualifying
                    </div>
                </div>
                <div className="direct-apply-subtitle">
                    These lenders don't offer pre-qualification on Sparrow and you may be eligible.
                </div>
            </div>

            {wordpressLenderContent.map((row, index) => {
                const lenderName = row.lender_name_from_tooltip_table || row.lender_name;
                const lowercaseLenderName = lenderName.toLowerCase();
                const shouldRender =
                    !lendersRanPrequalificationOn.includes(lowercaseLenderName) &&
                    lenderLinks[lowercaseLenderName];

                return (
                    <div
                        key={lenderName}
                        className={shouldRender ? `direct-apply-row` : "direct-apply-empty-row"}
                    >
                        {shouldRender && (
                            <RateCardDirectApply
                                logo={row.lender_logo}
                                lenderName={lenderName}
                                tooltip={getLenderDisclosure(lenderName, tooltips)}
                                ficoInfo={{
                                    title: "Minimum credit score",
                                    value: row.minimum_credit_score,
                                }}
                                fixedAPRInfo={{
                                    title: row.fixed_apr_title,
                                    value: row.fixed_apr,
                                }}
                                variableAPRInfo={{
                                    title: row.variable_apr_title,
                                    value: row.variable_apr,
                                }}
                                personalizedURL={lenderLinks[lowercaseLenderName]}
                            />
                        )}
                    </div>
                );
            })}
        </div>
    );
};