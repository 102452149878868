import { MenuItems } from "../../../types/paaas/custom-menu-props";

interface IHeaderBarLinks {
    button: MenuItems;
}

export const HeaderBarLinks = ({ button }: IHeaderBarLinks) => {
    return (
        <div className="links-item">
            <div className="header__links__wrap-link">
                <a
                    href={button.url ? button.url : "#"}
                    target={button.isOpenNewTab ? "_blank" : "_self"}
                    rel="noopener noreferrer"
                >
                    {button.name}
                </a>
            </div>
        </div>
    );
};
