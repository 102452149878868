import { useOnboarding } from "../../../core/providers/onboarding.provider";
import { DeclineReasonsContainer } from "./DeclineReasonsContainer";
import { getValidationAndDeclineReasonsData } from "../../../core/services/sparrow-api-helper-functions/decline-reasons/validation-and-decline-reasons-data";
import { PrequalSubmissionResults } from "../../../core/services/types/offers-api-types";
import "./styles.scss";

interface DeclineReasonsDisplayProps {
    applyDirectlyRef: any;
}

export const DeclineReasonsDisplay: React.FunctionComponent<DeclineReasonsDisplayProps> = ({
    applyDirectlyRef,
}) => {
    const onboarding = useOnboarding();
    //get the reasons to display based on our criteria and the associated content and ctas
    const validationAndDeclineReasonsData = getValidationAndDeclineReasonsData(
        onboarding.pqRequestResults as PrequalSubmissionResults,
        onboarding
    );

    const consolidatedDeclineReasons = {
        ...validationAndDeclineReasonsData.validationErrorsOutput,
        ...validationAndDeclineReasonsData.declineReasonsOutput,
    };

    //return the array of reasons associated with each decline reason category that has at least one reason. DeclineReasonsContainer will handle the display of the reasons
    return (
        <>
            {Object.keys(consolidatedDeclineReasons).map((declineReasonsCategory) => {
                if (!consolidatedDeclineReasons[declineReasonsCategory]?.length) return undefined;
                return (
                    <DeclineReasonsContainer
                        applyDirectlyRef={applyDirectlyRef}
                        key={declineReasonsCategory}
                        reasons={consolidatedDeclineReasons[declineReasonsCategory]}
                    />
                );
            })}
        </>
    );
};
