import { UserType } from "../../../types/sparrow-types";
import { getSvgColors } from "../getSvgColors";

export const CheckCircle = ({ userType }: { userType?: UserType }) => {
    const { primaryColor } = userType ? getSvgColors(userType) : {primaryColor: undefined};

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="11.999" cy="12" r="11" fill={userType ? primaryColor : "#6DB353"} />
            <g clip-path="url(#clip0_7995_74163)">
                <path
                    d="M11.9997 7.33331C12.2988 7.33331 12.5454 7.5585 12.5791 7.84862L12.583 7.91665V11.4166H16.083C16.4052 11.4166 16.6663 11.6778 16.6663 12C16.6663 12.2991 16.4412 12.5457 16.151 12.5794L16.083 12.5833H12.583V16.0833C12.583 16.4055 12.3218 16.6666 11.9997 16.6666C11.7005 16.6666 11.454 16.4415 11.4203 16.1513L11.4163 16.0833V12.5833H7.91634C7.59418 12.5833 7.33301 12.3221 7.33301 12C7.33301 11.7008 7.5582 11.4543 7.84831 11.4206L7.91634 11.4166H11.4163V7.91665C11.4163 7.59448 11.6775 7.33331 11.9997 7.33331Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_7995_74163">
                    <rect width="14" height="14" fill="white" transform="translate(5 5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
