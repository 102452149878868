export const housingTypes = [
    {
        label: "Rent",
        value: "Rent",
    },
    {
        label: "Own with mortgage",
        value: "Own with mortgage",
    },
    {
        label: "Own without mortgage",
        value: "Own without mortgage",
    },
    {
        label: "Live with family or friends",
        value: "Live with family or friends",
    },
    {
        label: "Other",
        value: "Other",
    },
];
