import { Navigate, Route, Routes } from "react-router-dom";
import {
    signUpBorrowerRoutingUrl,
    signUpSuccessRoutingUrl,
    signUpTypeRoutingUrl,
} from "../../common/core/routes/routing-urls";
import { CreateAccountSparrow } from "./borrower/page";
import { SuccessRegistrationPage } from "./success/page";
import { SignUpInitStep } from "./type/page";
import { isMarketplaceEnv } from "../../common/utils/config";
import { signUpStepBorrowerUrl } from "../../common/core/routes/navigation-urls";

export const SignUpRoutes = () => {
    return (
        <>
            <Routes>
                <Route path={signUpBorrowerRoutingUrl} element={<CreateAccountSparrow />} />
                <Route path={signUpSuccessRoutingUrl} element={<SuccessRegistrationPage />} />
                <Route
                    path={signUpTypeRoutingUrl}
                    element={
                        isMarketplaceEnv() ? (
                            <SignUpInitStep />
                        ) : (
                            <Navigate to={signUpStepBorrowerUrl} replace />
                        )
                    }
                />
            </Routes>
        </>
    );
};
