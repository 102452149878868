import { UserType } from "../../../common/constants/defaultInputs";
import { getPossesiveCase, sentenceCase } from "../../../common/utils/global";
import { IncludedUserStatus } from "../../../sparrow-ui-library/src/types/sparrow-types";
export interface IStatusBasedText {
    title: string;
    subTitle: string;
    status: string;
    primaryColor: string;
    secondaryColor: string;
    primaryButtonText: string | null;
    secondaryButtonText: string | null;
}

interface IInvitedUser {
    invitedUserName: string;
    invitedUserType: UserType;
    status: IncludedUserStatus;
}

export const getStatusBasedText = (invitedUser: IInvitedUser) => {
    let { invitedUserName, invitedUserType, status } = invitedUser;

    const statusBasedText: IStatusBasedText = {
        title: "",
        subTitle: "",
        status: "",
        primaryColor: "",
        secondaryColor: "",
        primaryButtonText: null,
        secondaryButtonText: null,
    };

    invitedUserName = sentenceCase(invitedUserName);

    const cosigned = invitedUserType === UserType.COSIGNER ? "cosigned" : "";

    switch (status) {
        case IncludedUserStatus.DIRECT_STARTED:
            statusBasedText.title = `Finish signing up ${invitedUserName}`;
            statusBasedText.subTitle = `You need to finish including ${invitedUserName}'s information before we can continue your ${cosigned} student loan search.`;
            statusBasedText.status = "Sign up in progress";
            statusBasedText.primaryColor = "#F19048";
            statusBasedText.secondaryColor = "#FFEFC6";
            statusBasedText.primaryButtonText = `Finish sign up`;
            break;
        case IncludedUserStatus.INVITE_PENDING:
            statusBasedText.title = `We're waiting on ${invitedUserName}`;
            statusBasedText.subTitle = `${invitedUserName} needs to accept your invitation before we can continue your ${cosigned} student loan search.`;
            statusBasedText.status = "Invitation sent";
            statusBasedText.primaryColor = "#6177E8";
            statusBasedText.secondaryColor = "#C2DAF5";
            statusBasedText.primaryButtonText = `Sign up ${invitedUserType}`;
            statusBasedText.secondaryButtonText = `Resend invitation`;
            break;
        case IncludedUserStatus.INVITE_STARTED:
            statusBasedText.title = `${invitedUserName} accepted your invitation`;
            statusBasedText.subTitle = `${invitedUserName} needs to include their information before we can continue your ${cosigned} student loan search.`;
            statusBasedText.status = `Pending ${invitedUserType} submission`;
            statusBasedText.primaryColor = "#F19048";
            statusBasedText.secondaryColor = "#FFEFC6";
            statusBasedText.primaryButtonText = `Sign up ${invitedUserType}`;
            statusBasedText.secondaryButtonText = `Send a reminder`;
            break;
        case IncludedUserStatus.INVITE_REJECTED:
            statusBasedText.title = `${invitedUserName} declined your invitation`;
            statusBasedText.subTitle = `${invitedUserName} to accept your invitation before we can continue your ${cosigned} student loan search.`;
            statusBasedText.status = `${sentenceCase(invitedUserType)} invitation declined`;
            statusBasedText.primaryColor = "#F32B32";
            statusBasedText.secondaryColor = "#FFE4E5";
            statusBasedText.primaryButtonText = `Delete my ${invitedUserType}`;
            break;
        case IncludedUserStatus.CONFIRMED:
            statusBasedText.title = `${invitedUserName} has been included`;
            statusBasedText.subTitle = `${getPossesiveCase(invitedUserName)} portion of the form has been submitted and they have been included on your request for pre-qualification.`;
            statusBasedText.status = `${sentenceCase(invitedUserType)} included`;
            statusBasedText.primaryColor = "#8CCE56";
            statusBasedText.secondaryColor = "#EFF7EA";
            statusBasedText.primaryButtonText = `Edit ${invitedUserType}'s information`;

            break;
        default:
    }

    return statusBasedText;
};
