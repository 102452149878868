"use client";

import React from "react";
import NumberFormat from "react-number-format";
import iconSucc from "../../../../assets/images/check.svg";
import clearIcon from "../../../../assets/images/clear-input.svg";
import { InputTooltip } from "../../InputTooltip";
import "./style.scss";

interface IInputText {
    placeholder: string;
    type: string;
    name: string;
    label: string | React.ReactNode;
    value: string | number | any;
    maxLength?: number;
    minLength?: number;
    successIcon?: boolean;
    error?: string | null;
    onChange?: any;
    onClear?: () => void;
    col?: number;
    prefix?: string;
    mask?: string;
    passwordType?: boolean;
    passwordShown?: boolean;
    showPassword?: () => void;
    isNumberMask?: boolean;
    allowZero?: boolean;
    controlZero?: boolean;
    readonly?: boolean;
    ref?: any;
    onBlur?: () => void;
    onKeyDown?: any;
    recommendLabel?: boolean;
    tabIndex?: number;
    tooltip?: {
        content: string;
    };
}

export const InputText: React.FunctionComponent<IInputText> = ({ allowZero = false, ...props }) => {
    const labelWrapper = () => {
        if (props.recommendLabel) {
            return (
                <div className="layout-row space-between align-center">
                    <label>{props.label}</label>
                    <div className="recommend-label">Recommended</div>
                </div>
            );
        }
        return (
            <label>
                {props.label}
                {props.tooltip && (
                    <div style={{ display: "inline" }}>
                        <InputTooltip content={props.tooltip.content} />
                    </div>
                )}
            </label>
        );
    };

    return (
        <div className={`form-col-${props.col ? props.col : 12} form-col`}>
            <div
                className={`${props.error && props.error.length ? "error" : ""} form-group ${
                    props.prefix ? "has-prefix" : ""
                }`}
            >
                {/*{props.prefix && props.value && <span className="prefix">{props.prefix}</span>}*/}
                {props.mask || props.isNumberMask || props.type === "date" ? (
                    <NumberFormat
                        tabIndex={props.tabIndex}
                        format={props.mask}
                        allowLeadingZeros={false}
                        allowNegative={false}
                        autoFocus={props.tabIndex && props.tabIndex === 1 ? true : false}
                        isAllowed={(val) =>
                            !props.controlZero
                                ? allowZero && val.value.length === 1
                                    ? true
                                    : val.floatValue !== 0
                                : true
                        }
                        value={props.value}
                        thousandSeparator={true}
                        onValueChange={(v) =>
                            props.onChange(v.floatValue, v.formattedValue, v.value)
                        }
                        onBlur={props.onBlur}
                        placeholder={props.placeholder}
                        prefix={props.prefix}
                        fixedDecimalScale={true}
                        decimalScale={0}
                        readOnly={props.readonly}
                        name={props.name}
                    />
                ) : (
                    <input
                        type={props.type}
                        className={`${props.value && props.value.length > 0 ? "has-value" : ""}`}
                        tabIndex={props.tabIndex}
                        autoFocus={props.tabIndex && props.tabIndex === 1 ? true : false}
                        placeholder={props.placeholder}
                        value={props.value}
                        minLength={props.minLength}
                        maxLength={props.maxLength}
                        required
                        name={props.name}
                        onChange={props.onChange}
                        onKeyDown={props.onKeyDown}
                    />
                )}
                {labelWrapper()}
                {typeof props.value == "string" &&
                    props.value &&
                    props.value.length > 0 &&
                    !props.successIcon &&
                    !props.passwordType &&
                    !props.readonly && (
                        <div className="form-group--navigation clear-btn" onClick={props.onClear}>
                            <img src={clearIcon} alt="" />
                        </div>
                    )}
                {props.passwordType && (
                    <div className="form-group--navigation" onClick={props.showPassword}>
                        <div className={`${props.passwordShown ? "active" : ""} toggle-password`} />
                    </div>
                )}
                {props.error && props.error.length > 1 && (
                    <p className="error-text">{props.error}</p>
                )}
                {props.successIcon && (
                    <div className="form-group--navigation">
                        <img className="input-success" src={iconSucc} alt="" />
                    </div>
                )}
            </div>
        </div>
    );
};
