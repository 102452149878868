import { useState, useRef, useMemo } from "react";
import { observer } from "mobx-react";
import { UserType } from "../../../constants/defaultInputs";
import { useStore } from "../../../core/providers/global.provider";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import { useSparrowUIContext } from "../../../../sparrow-ui-library/src/context";
import { useOnClickOutside } from "../../../core/helpers/events";
import { Put } from "../../../core/services/clientApi";
import { paaasRatingOfExperienceEndpoint } from "../../../../services/paaas/paaas-base-url";
import { Button, ButtonTypes } from "../../buttons/button/Button";
import { FeedbackStar } from "../../../../sparrow-ui-library/src/assets/icons/feedback-star-icon";
import styles from "./styles.module.scss";

export const FeedbackModal = observer(() => {
    const onboarding = useOnboarding();
    const store = useStore();
    const {displayToastPopup} = useSparrowUIContext();
    const modalRef = useRef<HTMLInputElement>(null);
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
    
    //Close modal if user clicks outside
    useOnClickOutside(modalRef, () => {
        store.modalResetData();
    });

    // Creates an array of 5 objects with id and isStarSelected properties
    const defaultFeedbackStars = useMemo(() => {
        return Array(5).fill(null).map((el, i) => {
          return { id: String(i), isStarSelected: false };
        });
      }, []);    

    const [feedbackStars, setFeedbackStars] = useState({activeStars: defaultFeedbackStars, rating: 0});

    const handleStarClick = (e) => {
        const activeStars = feedbackStars.activeStars.map((star) => {
            return {
                id: star.id,
                isStarSelected: Number(star.id) <= Number(e.currentTarget.id),
            };
        });

        setFeedbackStars({ activeStars, rating: Number(e.currentTarget.id) + 1 });
    };
  
    const handleSubmit = async () => {
        if (!onboarding.jwt) return;

        try {
            await Put(paaasRatingOfExperienceEndpoint, onboarding.jwt, {ratingOfExperience: feedbackStars.rating})
            setFeedbackSubmitted(true);
            setTimeout(() => {
                store.modalResetData();
            }, 1000);

        } catch {
            displayToastPopup("Your rating was not recorded. Please try again later.", true);
            store.modalResetData();
        }

    };


    return (
        <>
            <div className={styles.container} ref={modalRef}>
                <div className={styles.headerContainer}>
                    <div className={`${styles.background} ${styles[onboarding.formData.userType as string]}`} />
                    <div className={styles.header}>
                        <div className={styles.subtitle}>How was your experience finding a student loan with</div>
                        <div className={styles.title}>{onboarding.companyName}?</div>
                    </div>
                </div>
                {!feedbackSubmitted ? <ul className={styles.ratingContainer}>
                    {feedbackStars.activeStars.map((star) => {
                        return (
                            <li key={star.id}>
                                <button>
                                    <FeedbackStar
                                        id={star.id}
                                        isSelected={star.isStarSelected}
                                        handleClick={handleStarClick}
                                    />
                                </button>
                            </li>
                        );
                    })}
                </ul>: <div className={styles.tyTxt}>Thank you for your feedback!</div>}
                <Button
                    id="continue-button"
                    tabIndex={0}
                    onClick={handleSubmit}
                    showIcon={true}
                    buttonType={ButtonTypes.PRIMARY}
                    userType={onboarding.formData.userType as UserType}
                >
                    Submit
                </Button>
            </div>
        </>
    );
});
