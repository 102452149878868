import { FC, PropsWithChildren } from "react";
import { CustomBackground } from "../custom-background/CustomBackground";
import styles from "./styles.module.scss";

export const SectionWrapper: FC<PropsWithChildren<{hasBackground?: boolean}>> = (props) => {
    return (
        <section className={styles.section}>
            <div className={styles.innerSection}>
                {props.hasBackground && <CustomBackground />}
                <div className={styles.contentSection}>{props.children}</div>
            </div>
        </section>
    );
};
