import { Logo } from "../logo";
import CrossCloseIcon from "../../../../../../sparrow-ui-library/src/assets/icons/cross-close-icon"
import { useSparrowUIContext } from "../../../../../../sparrow-ui-library/src/context";
import styles from "../../styles.module.scss";

export const LeftSideMobileTop = () => {
    const { showMobileTips, setShowMobileTips } = useSparrowUIContext();

    return (
        <>
            {showMobileTips && (
                <div className={styles.leftSideInnerTop}>
                    <CrossCloseIcon
                        onClick={() => setShowMobileTips(!showMobileTips)}
                    />
                    <Logo />
                </div>
            )}
        </>
    )
}

