

import { UserType } from "../../../types/sparrow-types";
import { getSvgColors } from "../getSvgColors";

export const StepOneIcon = ({userType}: {userType: UserType}) => {
    const { primaryColor, accentColor } = getSvgColors(userType);

    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M4 17C4 15.3431 5.34315 14 7 14H33C34.6569 14 36 15.3431 36 17V33C36 34.6569 34.6569 36 33 36H7C5.34315 36 4 34.6569 4 33V17Z"
                fill={primaryColor}
            />
            <path
                d="M4 15.9519C4 15.3497 4.27139 14.7795 4.73881 14.3997L18.7388 3.02471C19.4736 2.42768 20.5264 2.42768 21.2612 3.02471L35.2612 14.3997C35.7286 14.7795 36 15.3497 36 15.9519V16.7639C36 17.5215 35.572 18.214 34.8944 18.5528L20.8944 25.5528C20.3314 25.8343 19.6686 25.8343 19.1056 25.5528L5.10557 18.5528C4.428 18.214 4 17.5215 4 16.7639V15.9519Z"
                fill={primaryColor}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32 20L20.8944 25.5528C20.3314 25.8343 19.6686 25.8343 19.1056 25.5528L8 20V10C8 8.89543 8.89543 8 10 8H30C31.1046 8 32 8.89543 32 10V20Z"
                fill={accentColor}
            />
        </svg>
    );
};
