import { FC } from "react";
import { UserType } from "../../../constants/defaultInputs";
import "./styles.scss";

interface IRoundCheckBox {
    isChecked: boolean;
    userType?: UserType;
}

export const RoundCheckBox: FC<IRoundCheckBox> = ({isChecked, userType}) => {

    //NOTE: because userType may be undefined, we'll always default to the borrower theme (sparrow green)
    const isCheckedClass = userType === UserType.COSIGNER ? "isActive-cosigner" : "isActive-borrower" ;
  
    return (
      <div className={`round-checkbox ${isChecked ? isCheckedClass : ""}`}>
        <div className={`${isChecked ? "round-checkbox-dot" : ""}`}/>
      </div>
    )
  }
