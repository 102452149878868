import { LinkButton } from "../../../common/components/buttons/link_button";
import {
    loginStepType,
    signUpStepBorrowerUrl,
    signUpStepCrestUrl,
} from "../../../common/core/routes/navigation-urls";
import AuthInitStep from "../../../common/views/sign-in";

export const SignUpInitStep = () => {
    return (
        <div className="auth-flow-container--inner">
            <AuthInitStep
                stepTitle="Choose an account type"
                stepSubTitle={
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "5px",
                            alignItems: "center",
                        }}
                    >
                        <div className="subtitle">Already have an account?</div>{" "}
                        <LinkButton link={loginStepType} underline={true}>
                            {" "}
                            Sign in
                        </LinkButton>
                    </div>
                }
                nextStepUrl={signUpStepBorrowerUrl}
                crestUrl={signUpStepCrestUrl}
            />
        </div>
    );
};
