import { useStore } from "../../core/providers/global.provider";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { Get } from "../../core/services/clientApi";
import {
    userMissingFields,
    UserMissingFieldsType,
} from "../../core/services/sparrow-api-endpoints";
import { GetCurrentUserAndUpdateStore } from "../../core/services/sparrow-api-handlers/profile-api-handlers";


const useSaveJWT = () => {
    const { setUserData, setProtectedForm, setUserHasMissingFields, setSsoUserData } = useStore();
    const { setJwt } = useOnboarding();

    const saveUser = async (value) => {
        setJwt(value.signInUserSession.idToken.jwtToken);
        await GetCurrentUserAndUpdateStore({
            jwt: value.signInUserSession?.idToken.jwtToken,
            setUserData,
        });

        if (value.hasOwnProperty("attributes")) {
            setProtectedForm(true);
        } else {
            Get(userMissingFields, value.signInUserSession.idToken.jwtToken).then(
                async (result: Partial<UserMissingFieldsType>) => {
                    setUserHasMissingFields(Boolean(result.isMissingFields));
                    if (result.isMissingFields) {
                        setSsoUserData({
                            ...result,
                            email: value.signInUserSession?.idToken?.payload?.email,
                        });
                    }
                    setJwt(value.signInUserSession.idToken.jwtToken);
                    await GetCurrentUserAndUpdateStore({
                        jwt: value.signInUserSession?.idToken.jwtToken,
                        setUserData,
                    });
                    setProtectedForm(true);
                }
            );
        }
    };
    return saveUser;
}

export default  useSaveJWT;
