import { useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";

import { GenericBubble } from "../../Bubble";

import "../style.scss";

import tooltipQuestionGray from "../../../../assets/images/tooltip-question-grey.svg";
import tooltipQuestionDarkGray from "../../../../assets/images/tooltip-question-dark-grey.svg";
import { useOnClickOutside } from "../../../core/helpers/events";

interface TooltipProps {
    content: string;
    id: string;
}

export const OfferCardBodyTooltip: React.FunctionComponent<TooltipProps> = ({ content, id }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isClickOpen, setIsClickOpen] = useState(false);

    useEffect(() => {
        /**
         * ReactTooltip.rebuild() will re-map "data-for" to ensure that new tool tips that are
         * rendered in as the user scrolls or interacts with the page will have the correct tool tip
         */
        ReactTooltip.rebuild();
    }, []);

    const dropDownRef = useRef<HTMLDivElement>(null);
    useOnClickOutside(dropDownRef, (e) => setIsOpen(false));

    function handleClick() {
        if (id.includes("APR")) {
            setIsOpen(!isClickOpen);
            setIsClickOpen(!isClickOpen);
        }
    }

    function handleMouseEnter() {
        setIsOpen(true);
    }

    function handleMouseLeave() {
        if (!isClickOpen) {
            setIsOpen(false);
        }
    }

    return (
        <>
            <div
                className={`tooltip-offer-card tooltip-offer-card-${id}`}
                onClick={() => handleClick()}
                ref={dropDownRef}
            >
                <img
                    onMouseEnter={() => handleMouseEnter()}
                    onMouseLeave={() => handleMouseLeave()}
                    data-tip
                    data-for={"tooltip-detail-" + id}
                    className="offer-card-detail-popup"
                    src={isOpen ? tooltipQuestionDarkGray : tooltipQuestionGray}
                    alt="pin"
                    style={{ cursor: "pointer", height: "12px", width: "12px" }}
                />

                <div className={"bubble-wrapper-tooltip" + (isOpen ? " hover" : "")}>
                    <GenericBubble>
                        <div className="offer-page-popup">
                            <div className="offer-page-popup-title">{id}</div>
                            <div
                                className="text-description"
                                dangerouslySetInnerHTML={{
                                    __html: content,
                                }}
                            />
                        </div>
                    </GenericBubble>
                </div>
            </div>
        </>
    );
};
