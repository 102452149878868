import { FC, useState } from "react";
import { useOnboarding } from "../../../common/core/providers/onboarding.provider";
import { sentenceCase } from "../../../common/utils/global";
import { StatusLabel } from "./StatusLabel";
import { useStore } from "../../../common/core/providers/global.provider";
import { DeleteEditInvitedUserModal } from "../../../common/components/modals/User_Inclusion_Modals/DeleteEditInvitedUserModal";
import removeDecimals from "../../../common/utils/removeDecimals";
import { IStatusBasedText } from "../utils/helper-functions";

import { observer } from "mobx-react";
import trashIcon from "../../../assets/images/icons/trashcan.svg";
import trashRedIcon from "../../../assets/images/icons/trashcanRed.svg";
import { IncludedUser } from "../../../sparrow-ui-library/src/types/sparrow-types";
import { UserMgmtIcon } from "../../../sparrow-ui-library/src/assets/icons/user-mgmt-icon";
import "./styles.scss";

interface IUserContactCardProps {
    isManagementCard?: boolean;
    statusBasedText?: IStatusBasedText;
}
/*
Description: UserContactCard is a component used in User Management Page and the page displayed to an invited user.
If isManagementCard is true, then the component is being used in the User Management Page, otherwise it is being used in the page displayed to an invited user.
Sections were isManagementCard is true will display only when the component is used for the User Management Page.
Sections were isManagementCard is false will display only when the component is used for the page displayed to an invited user.

Latest Figma Designs:
User Management Page: https://www.figma.com/file/Vwc8KmfCQ31VS28jOwBwYM/2.1.Sparrow.Borrower.Portal.NEW.Flows?node-id=2790-408767&t=OuvjeuCpOY6oT29P-0 
Invited User Page: https://www.figma.com/file/Vwc8KmfCQ31VS28jOwBwYM/2.1.Sparrow.Borrower.Portal.NEW.Flows?node-id=2790-404340&t=OuvjeuCpOY6oT29P-0
As of: April 25, 2023
*/
export const UserContactCard: FC<IUserContactCardProps> = observer(
    ({ statusBasedText, isManagementCard }) => {
        const onboarding = useOnboarding();
        const store = useStore();

        const { firstName, lastName, email, status, userType } = onboarding.formData
            .includedUser as IncludedUser;

        const loanTypeText = `${
            onboarding.formData.type === "PSL" ? "Private Student Loan" : "Student Loan Refinance"
        }`;

        const onDelete = () => {
            store.setModal(() => (
                <DeleteEditInvitedUserModal
                    firstName={firstName}
                    email={email}
                    status={status}
                    invitedUserType={userType}
                />
            ));
        };

        const [isTrashHover, setIsTrashHover] = useState<boolean>();

        return (
            <div className="step-form-block">
                {isManagementCard && (
                    <div className="ucc-first-row">
                        <h2
                            style={{
                                color: "#151B26",
                                fontStyle: "normal",
                                fontSize: "20px",
                                lineHeight: "28px",
                                fontWeight: "600",
                            }}
                        >{`${sentenceCase(userType)} contact information`}</h2>
                        <img
                            alt="delete"
                            src={isTrashHover ? trashRedIcon : trashIcon}
                            onClick={() => onDelete()}
                            style={{ width: "24px", height: "24px", cursor: "pointer" }}
                            onMouseEnter={() => setIsTrashHover(true)}
                            onMouseLeave={() => setIsTrashHover(false)}
                        />
                    </div>
                )}
                <div className="ucc-second-row">
                    <div className="ucc-incl-user-box">
                        <div className="ucc-icon-container">
                            <UserMgmtIcon userType={userType} />
                        </div>
                        <div>
                            <p className="ucc-contact-name">{`${firstName} ${lastName}`}</p>
                            {isManagementCard && <p className="ucc-contact-email">{email}</p>}
                            {!isManagementCard && (
                                <p className="ucc-contact-usertype">{`${sentenceCase(
                                    userType
                                )}`}</p>
                            )}
                        </div>
                    </div>
                    {/* StatusLabel only used in UserManagementPage */}
                    {statusBasedText && (
                        <div className="ucc-status-label-container">
                            <StatusLabel
                                status={statusBasedText.status}
                                primaryColor={statusBasedText.primaryColor}
                                secondaryColor={statusBasedText.secondaryColor}
                            />
                        </div>
                    )}
                </div>
                {!isManagementCard && (
                    <>
                        <div className="ucc-loan-information">
                            <RowInformation
                                leftText="Loan Size"
                                rightText={removeDecimals(onboarding.formData.loanAmount.display)}
                            />
                            <RowInformation
                                leftText="Loan Product"
                                rightText={onboarding.formData.loanSelectionType}
                            />
                            <RowInformation leftText="Loan Type" rightText={loanTypeText} />
                        </div>
                    </>
                )}
            </div>
        );
    }
);

const RowInformation = ({ leftText, rightText }) => {
    return (
        <div className="ucc-row">
            <p>{leftText}</p>
            <p>{rightText}</p>
        </div>
    );
};
