import { UserType } from "../../../../types/sparrow-types";

export const RepaymentPlanIcon = ({
    userType,
    isActive,
}: {
    userType: UserType;
    isActive: boolean;
}) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.47368 3C0.667387 3 0 3.66044 0 4.45833V11.5417C0 12.3396 0.667387 13 1.47368 13H14.5263C15.3326 13 16 12.3396 16 11.5417V4.45833C16 3.66044 15.3326 3 14.5263 3H1.47368ZM2.52632 4.25H13.4737C13.4737 4.94167 14.0379 5.5 14.7368 5.5V10.5C14.0379 10.5 13.4737 11.0583 13.4737 11.75H2.52632C2.52632 11.0583 1.96211 10.5 1.26316 10.5V5.5C1.96211 5.5 2.52632 4.94167 2.52632 4.25ZM8 5.08333C7.17544 5.08333 6.48221 5.49338 6.06826 6.05664C5.6543 6.6199 5.47368 7.31345 5.47368 8C5.47368 8.68655 5.6543 9.3801 6.06826 9.94336C6.48221 10.5066 7.17544 10.9167 8 10.9167C8.82456 10.9167 9.51779 10.5066 9.93174 9.94336C10.3457 9.3801 10.5263 8.68655 10.5263 8C10.5263 7.31345 10.3457 6.6199 9.93174 6.05664C9.51779 5.49338 8.82456 5.08333 8 5.08333ZM8 6.33333C8.4386 6.33333 8.69274 6.49621 8.91036 6.79232C9.12798 7.08843 9.26316 7.54072 9.26316 8C9.26316 8.45928 9.12798 8.91157 8.91036 9.20768C8.69274 9.50379 8.4386 9.66667 8 9.66667C7.5614 9.66667 7.30726 9.50379 7.08964 9.20768C6.87202 8.91157 6.73684 8.45928 6.73684 8C6.73684 7.54072 6.87202 7.08843 7.08964 6.79232C7.30726 6.49621 7.5614 6.33333 8 6.33333ZM2.94737 7.16667C2.72403 7.16667 2.50984 7.25446 2.35191 7.41074C2.19398 7.56703 2.10526 7.77899 2.10526 8C2.10526 8.22101 2.19398 8.43298 2.35191 8.58926C2.50984 8.74554 2.72403 8.83333 2.94737 8.83333C3.17071 8.83333 3.3849 8.74554 3.54283 8.58926C3.70075 8.43298 3.78947 8.22101 3.78947 8C3.78947 7.77899 3.70075 7.56703 3.54283 7.41074C3.3849 7.25446 3.17071 7.16667 2.94737 7.16667ZM13.0526 7.16667C12.8293 7.16667 12.6151 7.25446 12.4572 7.41074C12.2992 7.56703 12.2105 7.77899 12.2105 8C12.2105 8.22101 12.2992 8.43298 12.4572 8.58926C12.6151 8.74554 12.8293 8.83333 13.0526 8.83333C13.276 8.83333 13.4902 8.74554 13.6481 8.58926C13.806 8.43298 13.8947 8.22101 13.8947 8C13.8947 7.77899 13.806 7.56703 13.6481 7.41074C13.4902 7.25446 13.276 7.16667 13.0526 7.16667Z"
                fill={isActive ? `rgba(var(--${userType}PrimaryColor), 1)` : "#525966"}
            />
        </svg>
    );
};
