import { useState } from "react";
import { LottieIcon } from "../../LottieIcon/LottieIcon";
import { isMarketplaceEnv } from "../../../utils/config";
import "./styles.scss";

export const RequestCard = ({ id, title, loanType, animationData, clickHandler, icon }) => {
    const [play, setPlay] = useState(false);

    return (
        <div
            className="request-card-container"
            id={id}
            onMouseEnter={() => setPlay(true)}
            onMouseLeave={() => setPlay(false)}
            onClick={() => clickHandler(loanType, title)}
        >
            {isMarketplaceEnv() ?
                <LottieIcon
                options={{ height: 40, width: 40, autoplay: false, play: play }}
                animationData={animationData}
            /> : icon()}
            <div className="request-card-name">{title}</div>
        </div>
    );
};
