export const ExpiredIconSvg: React.FC<{fillColor: string}>= ({fillColor}) => {
    return (
        <div>
            <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="20" cy="20" r="20" fill={fillColor} />
                <path
                    d="M25.0009 17.5H15.4109L16.7109 16.21C16.8992 16.0217 17.005 15.7663 17.005 15.5C17.005 15.2337 16.8992 14.9783 16.7109 14.79C16.5226 14.6017 16.2672 14.4959 16.0009 14.4959C15.7346 14.4959 15.4792 14.6017 15.2909 14.79L12.2909 17.79C12.1998 17.8851 12.1285 17.9973 12.0809 18.12C11.9809 18.3635 11.9809 18.6365 12.0809 18.88C12.1285 19.0028 12.1998 19.1149 12.2909 19.21L15.2909 22.21C15.3838 22.3037 15.4944 22.3781 15.6163 22.4289C15.7382 22.4797 15.8689 22.5058 16.0009 22.5058C16.1329 22.5058 16.2636 22.4797 16.3854 22.4289C16.5073 22.3781 16.6179 22.3037 16.7109 22.21C16.8046 22.117 16.879 22.0064 16.9298 21.8846C16.9805 21.7627 17.0067 21.632 17.0067 21.5C17.0067 21.368 16.9805 21.2373 16.9298 21.1154C16.879 20.9936 16.8046 20.883 16.7109 20.79L15.4109 19.5H25.0009C25.2661 19.5 25.5204 19.6054 25.708 19.7929C25.8955 19.9804 26.0009 20.2348 26.0009 20.5V24.5C26.0009 24.7652 26.1062 25.0196 26.2938 25.2071C26.4813 25.3946 26.7357 25.5 27.0009 25.5C27.2661 25.5 27.5204 25.3946 27.708 25.2071C27.8955 25.0196 28.0009 24.7652 28.0009 24.5V20.5C28.0009 19.7044 27.6848 18.9413 27.1222 18.3787C26.5596 17.8161 25.7965 17.5 25.0009 17.5V17.5Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};
