import CustomScrollbar from "../../../../../common/components/Scrollbar/CustomScrollbar";
import { privacyUrl, termsUrl } from "../../../../../common/core/routes/navigation-urls";
import { useOnboarding } from "../../../../../common/core/providers/onboarding.provider";
import { UserType } from "../../../../../sparrow-ui-library/src/types/sparrow-types";
import { getPossesiveCase } from "../../../../../common/utils/global";
import { paaasCustomization } from "../../../../../services/paaas/paaas-base-url";

export const ReviewDisclaimer = () => {
    const { formData, companyName, paaasTopLevelFields } = useOnboarding();
    
    const elements = document.querySelectorAll<HTMLElement>(
        ".ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY"
    );
    // Loop through each element and modify the color
    elements.forEach((element) => {
        element.style.backgroundColor = `rgb(var(--${formData.userType as UserType}AccentColor)`;
    });

    const { privacyPolicyUrl, termsOfServiceUrl } = paaasTopLevelFields ?? {};

    const PAAASClientText = () => {
        if (!paaasTopLevelFields) return <></>;

        return (
            <>
                {getPossesiveCase(companyName)}{" "}
                <a
                    className={`${formData.userType as UserType}`}
                    href={termsOfServiceUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    Terms of Service
                </a>{" "}
                and{" "}
                <a
                    className={`${formData.userType as UserType}`}
                    href={privacyPolicyUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    Privacy Policy
                </a>
                , as well as their partner,
            </>
        );
    };

    return (
        <div className="step-form-block">
            <CustomScrollbar height={150} customCLassName={"apply-disclosure"}>
                <p>
                    By clicking “I agree and submit” you and anyone else you include on your request
                    confirm that you have read and agree to <PAAASClientText /> Sparrow Labs, Inc.'s{" "}
                    <a
                        className={`${formData.userType as UserType}`}
                        href={termsUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Terms of Service
                    </a>{" "}
                    and{" "}
                    <a
                        className={`${formData.userType as UserType}`}
                        href={privacyUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Privacy Policy
                    </a>
                    . You and anyone else you include on your request consent to receiving all
                    communications and disclosures from Sparrow and its Providers electronically.
                </p>
                <p>
                    You and anyone else you include on your request understand and agree that
                    Sparrow is authorized to share your information with Providers on your behalf.
                    You and anyone else you include on your request also authorize these Providers
                    to obtain consumer reports and related information about you and anyone else you
                    include on your request from one or more consumer reporting agencies, such as
                    TransUnion, Experian, and Equifax.
                </p>
                <p>
                    If you apply for credit jointly, you and anyone else you include on your request
                    acknowledge that personal information provided during this process will be
                    shared between the borrower and the cosigner on this and any future
                    prequalification submissions.
                </p>
                <p>
                    You and anyone else you include on your request acknowledge that this form is
                    not for a loan application and that receiving a pre-qualified rate does not
                    guarantee approval or receipt of a loan. You and anyone else you include on your
                    request understand that the rates displayed on Sparrow are estimates of what you
                    and anyone else you include on your request may be eligible for when applying
                    with Sparrow's lending providers.
                </p>
            </CustomScrollbar>
        </div>
    );
};
