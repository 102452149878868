import { AddressInput } from "../../../types/sparrow-types";

export const RequiredAddressFields = (params?: { includeLine2?: boolean }) => {
    const { includeLine2 } = params || {};
  
    return {
      addressLine1: "",
      ...(includeLine2 ? { addressLine2: "" } : {}),
      city: "",
      state: "",
      zip: "",
      countryCode: "",
    };
  };

export const Address = (): AddressInput => {
    return {
        // fullAddress is only used for display purposes. Backend will ignore this field.
        fullAddress: "",
        addressLine2: "",
        addressLine1: "",
        city: "",
        state: "",
        zip: "",
        countryCode: "",
    };
}
