import { Helmet } from "react-helmet";
import { observer } from 'mobx-react'
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { isMarketplaceEnv } from "../../utils/config";
import { getPossesiveCase } from "../../utils/global";


export const AppTitle = observer(() => {
    const {companyName, paaasCustomHeader} = useOnboarding();
    const isPAAAS = !isMarketplaceEnv();
    
    return (
        <Helmet>
            {isPAAAS && <link rel="icon" type="image/png" sizes="96x96" href={paaasCustomHeader?.favicon?.url}/>}
            <title>{`${getPossesiveCase(companyName)} ${isPAAAS ? "Student Loan Marketplace" : ""}` }</title>
            <meta name="description" content={`${companyName} is the best way to compare & save on private student loans and student loan refinancing. No impact on your credit score. 100% free.`} />
        </Helmet>
    )
});
