import React from "react";
import { AuthFlowLayout } from "../../common/components/auth-component/authFlowLayout";
import { ConfirmResetPage } from "./page";

export default function ConfirmResetWrapper() {
    return (
        <AuthFlowLayout>
            <ConfirmResetPage />
        </AuthFlowLayout>
    );
}
