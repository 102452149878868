import { FC, MouseEventHandler } from "react";
import { PlainChecks } from "./icons/PlainChecks";
import { homeCardsHeroContent } from "../../constants/home-cards-content";
import { HomeCard } from "../../components/home-card/HomeCard";
import { SectionHeader } from "../../components/section-header/SectionHeader";
import styles from "./styles.module.scss";
import InterfaceDashboard from "../../components/InterfaceDashboard";
import { LoanAmountButton } from "./components/loan-amount-button/LoanAmountButton";
import { StudentCard } from "../../components/student-card/StudentCard";
import { IllustrativeDisclosure } from "../../components/illustrative-disclosure/IllustrativeDisclosure";

export const HeroHome: FC<{handleDisclosureClick: MouseEventHandler<HTMLImageElement>}> = (props) => {

    return (
        <section className={styles.section}>
            <div className={styles.homeContainer}>
                <div className={styles.topSection}>
                    <div className={styles.leftContent}>
                        <SectionHeader
                            sectionText="Student Loan Marketplace"
                            title="Automate your search for the best private student loan"
                            subtitle="Pre-qualify with multiple student lenders across the U.S. through a single, three minute form."
                        />
                        <LoanAmountButton />
                        <div className={styles.benefitsContainer}>
                            <div className={styles.flexCol}>
                                <span>
                                    <PlainChecks />
                                    100% free
                                </span>
                                <span>
                                    <PlainChecks />
                                    No credit score impact
                                </span>
                            </div>
                            <div className={styles.flexCol}>
                                <span>
                                    <PlainChecks />
                                    $0 In school payments
                                </span>
                                <span>
                                    <PlainChecks />
                                    Real pre-qualifed rates
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.heroCornerContainer}>
                        <InterfaceDashboard size={'medium'} type={'main'} />
                        <StudentCard />
                        <div className={styles.heroCornerBackground}>
                        </div>
                        <div className={styles.heroCornerStraight}></div>
                    </div>
                </div>
                <IllustrativeDisclosure handleDisclosureClick={props.handleDisclosureClick} />

                <div className={styles.flex}>
                    {homeCardsHeroContent.map((card) => {
                        return <HomeCard {...card} />;
                    })}
                </div>
            </div>

        </section>
    );
};
