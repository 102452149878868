"use client";
import React, { useEffect, useRef, useState } from "react";
import "./style.scss";

import tooltipQuestionGray from "../../../assets/images/icons/grayQuestion.svg";
import tooltipQuestionDarkGray from "../../../assets/images/icons/grayDarkQuestion.svg";
import ReactTooltip from "react-tooltip";
import { GenericBubble } from "../Bubble";
interface DropdownTooltipProps {
    content: string;
    title: string;
}

export const DropdownTooltip: React.FunctionComponent<DropdownTooltipProps> = ({
    content,
    title,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        /**
         * ReactTooltip.rebuild() will re-map "data-for" to ensure that new tool tips that are
         * rendered in as the user scrolls or interacts with the page will have the correct tool tip
         */
        ReactTooltip.rebuild();
    }, []);

    const dropDownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (isOpen) {
                if (dropDownRef.current && !dropDownRef.current.contains(event.target as Node)) {
                    setIsOpen(false);
                }
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropDownRef, isOpen]);

    function handleMouseEnter() {
        setIsOpen(true);
    }

    function handleMouseLeave() {
        setIsOpen(false);
    }

    return (
        <>
            <div className={'filter-tooltip'} ref={dropDownRef}>
                <img
                    className="dropdown-tooltip-icon"
                    onMouseEnter={() => handleMouseEnter()}
                    onMouseLeave={() => handleMouseLeave()}
                    src={isOpen ? tooltipQuestionDarkGray : tooltipQuestionGray}
                    alt="pin"
                />
                <div className={"bubble-wrapper-tooltip" + (isOpen ? " hover" : " ")}>
                    <GenericBubble>
                        <div className="dropdown-tooltip-container" style={{ zIndex: 905 }}>
                            <div className="dropdown-tooltip-title">{title}</div>
                            <div
                                className="dropdown-tooltip-text"
                                dangerouslySetInnerHTML={{
                                    __html: content,
                                }}
                            />
                        </div>
                    </GenericBubble>
                </div>
            </div>
        </>
    );
};
