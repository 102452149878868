import { generateIncomeBasedOfferGraphData } from "./income-based";
import { generateStandardOfferGraphData } from "./standard";
import { IncomeBasedOfferGraphDataInput, StandardOfferGraphDataInput } from "./utils/types";

export function generateGraphDataForOffers(params: {
    offers: StandardOfferGraphDataInput[];
    incomeBasedOffers: IncomeBasedOfferGraphDataInput[];
    gradDate: number;
    dob: number;
    isBorrowerInitiated: boolean;
    iboIncome?: number;
}) {
    const { offers, gradDate, incomeBasedOffers, dob, iboIncome, isBorrowerInitiated } = params;

    const pinnedOffers = offers.filter((offer) => {
        if (isBorrowerInitiated) {
            return !!offer.pinned;
        } else {
            return !!offer.cosignerPinned;
        }
    });
    const pinnedIncomeOffers = incomeBasedOffers.filter((offer) => {
        if (isBorrowerInitiated) {
            return !!offer.pinned;
        } else {
            return !!offer.cosignerPinned;
        }
    });

    const standardOfferOutput = generateStandardOfferGraphData({
        offers: pinnedOffers,
        gradDate: gradDate,
        dob: dob,
    });

    const incomeBasedOfferOutput = generateIncomeBasedOfferGraphData({
        offers: pinnedIncomeOffers,
        dob: dob,
        income: iboIncome,
    });

    return {
        standardGraphData: standardOfferOutput,
        incomeBasedGraphData: incomeBasedOfferOutput,
    };
}
