import { useMemo, useState } from "react";
import AccordionItem from "./AccordionItem";
import { Button, ButtonTypes } from "../../../buttons/button/Button";
import { useNavigate } from "react-router-dom";
import { DeclineReasonsTitleSection } from "./DeclineReasonsTitleSection";
import { observer } from "mobx-react";
import { useOnboarding } from "../../../../core/providers/onboarding.provider";

interface IAccordionProps {
    reasons: {
        title: string;
        textContent: string;
        cta?: string;
        ctaUrl?: string;
        isClicked: boolean;
        scrollToApplyDirectly?: boolean;
    }[];
    applyDirectlyRef: any;
}

export const DeclineReasonsContainer: React.FC<IAccordionProps> = observer((props) => {
    const navigate = useNavigate();
    const onboarding = useOnboarding();
    //attach an isClicked property to the decline reasons;
    const defaultReasons = useMemo(() => {
        return props.reasons.map((reason) => {
            reason.isClicked = false;
            return reason;
        });
    }, [props.reasons]);

    const [reasons, setReasons] = useState(defaultReasons);
    const hasOnlyOneDeclineReason = props.reasons.length === 1;
    //using this logic, multiple accordion items can be open at the same time
    const handleToggle = (index: number) => {
        const copyReasons = reasons.map((reason, i) => {
            if (i === index) {
                reason.isClicked = !reason.isClicked;
            }
            return reason;
        });

        setReasons(copyReasons);
    };

    function handleClick(reason: {
        title: string;
        textContent: string;
        cta?: string;
        ctaUrl?: string;
        isClicked: boolean;
        scrollToApplyDirectly?: boolean;
    }) {
        if (reason.scrollToApplyDirectly) {
            window.scrollTo({
                top: props.applyDirectlyRef.current.offsetTop,
                behavior: "smooth",
            });
        } else if ((reasons[0].cta as string).includes("credit file")) {
            //open a new tab to the Transunion site
            window.open(reasons[0].ctaUrl as string);
        } else {
            navigate(reasons[0].ctaUrl as string);
        }
    }

    return (
        <div className="dc-container">
            {/* title section */}
            <DeclineReasonsTitleSection
                hasOnlyOneDeclineReason={hasOnlyOneDeclineReason}
                reasons={reasons}
            />
            {/* main content */}
            <div style={{ marginBottom: "24px" }}>
                {!hasOnlyOneDeclineReason ? (
                    //for multiple reasons, use the accordion component
                    <div className="accordion">
                        {reasons.map((reason, index) => (
                            <AccordionItem
                                onToggle={() => handleToggle(index)}
                                active={reason.isClicked}
                                key={index}
                                text={reason}
                            />
                        ))}
                    </div>
                ) : (
                    //for one reason use a simple paragraph
                    <p style={{ marginTop: "8px" }}>{reasons[0].textContent}</p>
                )}
            </div>
            {/* button component will either route to another page on the app or an external link */}
            <Button
                buttonType={ButtonTypes.PRIMARY}
                userType={onboarding.formData.userType}
                float="left"
                showIcon={true}
                onClick={() => handleClick(reasons[0])}
            >
                <>{reasons[0].cta}</>
            </Button>
        </div>
    );
});
