import { BackendValue, FormFieldName } from "../../types/form-types";

export const flattenDeepObject = (obj: { [key: string]: any }, parentKey: string | null = null) => {
    const flattenedObject = Object.keys(obj).reduce((acc: { [key: string]: string | number | undefined | null }, key) => {
        if (key === null) return acc;
        const value = obj[key];
        /* want to concatenate the parent key with the current key to create unique keys from nested objects with duplicate keys 
        e.g. permanentAddress_addressLine1, mailingAddress_addressLine1 */
        if (parentKey) key = parentKey + "." + key;
        // if value is an object, recursively flatten it
        if (typeof value === "object" && value !== null && !Array.isArray(value)) {
            // assigns the flattened nested object to the current acc object. Effectively it spreads the nested object into the current acc object without making copies i.e. acc = {...acc, ...flattenedNestedObject}
            Object.assign(acc, flattenDeepObject(value, key));
        } else {
            acc[key] = value;
        }

        return acc;
    }, {});

    return flattenedObject;
};

export const unflattenObject = (flattenedObject: { [key: string]: BackendValue }): { [key: string]: BackendValue } => {
    const unflattenedObject = {};

    for (const flattenedKey in flattenedObject) {
        // for each flattened key, split into an array of subkeys
        const subkeys = flattenedKey.split(".");
        // reduce the subkeys array to a single object that is added to the unflattenedObject
        subkeys.reduce((acc: any, key, index) => {
            // if the current subkey exists in the unflattened object then return the value of that subkey
            if (key in acc) {
                return acc[key];
            }
            // if the current subkey is the last subkey in the array then add the value associated with the flattened key to the unflattened object
            if (subkeys.length - 1 === index) {
                acc[key] = flattenedObject[flattenedKey];
            } else {
                // if the current subkey is not the last subkey in the array then add an empty object to the unflattened object
                acc[key] = {};
            }
            return acc[key];
        }, unflattenedObject);
    }

    return unflattenedObject;
};

export const deepClone = (obj: {[key: string]: any}) => {
    if (obj === null || typeof obj !== "object") {
      // If obj is null or not an object, return it as is
      return obj;
    }
  
    // Create an empty object or array based on the type of obj
    const clonedObj: any = Array.isArray(obj) ? [] : {};
  
    // Iterate through all properties of obj
    for (const key in obj) {
      // Recursively clone each property and assign it to the cloned object
      clonedObj[key] = deepClone(obj[key]);
    }
  
    return clonedObj;
  }

  /**
   * @description Merges an array of objects into a single object.
   * @example Used to merge the data from multiple forms into a single object before sending to the backend
   * @param arr An array of objects to merge
   * @returns a single object with the properties of all the objects in the array
   */
export const mergeObjectsFromArray = (arr: {[key: string]: any}[]): {} => {
    // Initialize an empty object to store the merged data
    let mergedObject = {};

    // Iterate through the array of objects
    for (let obj of arr) {
        // Merge each object's properties into the mergedObject
        mergedObject = { ...mergedObject, ...obj };
    }

    return mergedObject;
}

const sentenceCaseInputsToExclude = new Set(["SSN", "SSN?", "MM/YYYY", "MM/DD/YYYY", "WhatsApp account"]);

export const formatToSentenceCase = (input: string) => {
    const words = input.split(' ');
    // Convert all words, except for excluded words, to lowercase
    words.forEach((word, index) => {
        return sentenceCaseInputsToExclude.has(input) ? word : word.toLowerCase();
    });
    // Capitalize the first letter of the first word
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);

    return words.join(' ');
}

export const checkNameIsMiddleInitialOrSuffix = (name: FormFieldName) => {
    const SecondaryNameFieldId = new Set(["middleInitial", "suffix"]);
    return SecondaryNameFieldId.has(name);
}

export const getCurrentUnixTime = () => {
    const time = Date.now();
    const timeZoneOffset = new Date(time).getTimezoneOffset() * 60 * 1000;
    return Math.round((time - timeZoneOffset) / 1000);
}