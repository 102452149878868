import { observer } from "mobx-react";
import styles from "./styles.module.scss";
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider";

export const RequestHistoryHeader = observer((props) => {
    const onboarding = useOnboarding();

    return (
        <div
            className={styles["request-history-header"]}
            onClick={() => {
                props.toggle!();
            }}
        >
            {!onboarding.formData.id && <p>Select a loan request</p>}
            <div className={styles["flex-gap-8"]}>
                {onboarding.formData.id && (
                    <>
                        {props.icon}
                        <div className="history-header-user">{props.user}</div>
                    </>
                )}
            </div>
            <div className={styles["flex-gap-8"]}>
                {onboarding.formData.id && (
                    <>
                        <div className="history-header-loan-amount">{props.loanAmount}</div>
                        <div className="p-md-14-regular">{props.date}</div>
                    </>
                )}

                <button className={`expand-button ${props.progress && "active"}`}>
                    <span className="icon-arrow-bottom" />
                </button>

            </div>
        </div>
    )
});
