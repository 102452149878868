import ReactGA from "react-ga";

import { useStore } from "../../../../core/providers/global.provider";
import { useOnboarding } from "../../../../core/providers/onboarding.provider";
import { Post } from "../../../../core/services/clientApi";
import { offersExploreEndpoint } from "../../../../core/services/sparrow-api-endpoints";
import { OfferCardHeaderApplyButton } from "./buttons/OfferCardHeaderApplyButton";
import { OfferCardHeaderPinButton } from "./OfferCardHeaderPinButton";
import { OfferCardHeaderBookmarkButton } from "./buttons/OfferCardHeaderBookmarkButton";
import "../../style.scss";

import "../../style.scss";

import bookmarkIcon from "../../../../../assets/images/icons/bookmark.svg";
import bookmarkActiveIcon from "../../../../../assets/images/icons/bookmarkActive.svg";
import threeBarIcon from "../../../../../assets/images/icons/threebars.svg";
import threeBarYellowIcon from "../../../../../assets/images/icons/threebarsYellow.svg";
import threeBarOrangeIcon from "../../../../../assets/images/icons/threebarsOrange.svg";
import threeBarGreenIcon from "../../../../../assets/images/icons/threebarsGreen.svg";
import threeBarPurpleIcon from "../../../../../assets/images/icons/threebarsPurple.svg";
import threeBarBlueIcon from "../../../../../assets/images/icons/threebarsBlue.svg";
import { iOS } from "../../../../utils/global";
import { useSparrowUIContext } from "../../../../../sparrow-ui-library/src/context";
import { FeedbackModal } from "../../../modals/OffersPageModals/FeedbackModal";

interface InteractionButtonsProps {
    offer: any;
    progress: number;
    pinToggle: () => void;
    bookmarkToggle: () => void;
    pinCurrentValue: boolean;
    bookmarkCurrentValue: boolean;
    pinnedOfferIndex?: number;
    personalizedURL: string;
    onApply: (isValid: boolean) => void;
    applyWithoutCosigner: boolean;
}

export const OfferCardHeaderInteractionButtons: React.FunctionComponent<
    InteractionButtonsProps
> = ({
    progress,
    pinToggle,
    offer,
    bookmarkToggle,
    pinCurrentValue,
    bookmarkCurrentValue,
    pinnedOfferIndex,
    personalizedURL,
    onApply,
    applyWithoutCosigner,
}) => {
    const { formData, jwt, paaasCustomization } = useOnboarding();
    const {setModal} = useStore();
    const{ displayToastPopup } = useSparrowUIContext();

    function getPinIcon() {
        switch (pinnedOfferIndex) {
            case 0:
                return threeBarYellowIcon;
            case 1:
                return threeBarOrangeIcon;
            case 2:
                return threeBarGreenIcon;
            case 3:
                return threeBarPurpleIcon;
            case 4:
                return threeBarBlueIcon;
            default:
                return threeBarIcon;
        }
    }

    const { setLoading } = useStore();

    async function handleExplore() {
        ReactGA.event({
            category: "Explored",
            action: offer.type + " Explored",
            label: offer.lender,
            value: offer.amount.amount / 100,
        });
        setLoading(true);
        let body: any = {
            requestId: formData.id,
            offerId: offer.id,
        };

        let tab: any = null;
        if (iOS()) {
            tab = window.open("about:blank");
        }

        try {
            const response: {offer: any, externalUrl: string, ratingOfExperience?: number} = await Post(offersExploreEndpoint, jwt as string, body);
            if (iOS()) {
                tab.location = response.externalUrl;
                tab.focus();
            } else {
                window.open(response.externalUrl, "_blank");
            }
            setLoading(false);

            const displayFeedbackView = paaasCustomization?.toggleableViews.displayFeedbackView;

            if(!response.ratingOfExperience && displayFeedbackView){
                return setModal(() => <FeedbackModal /> , false, true, "lg", true);
            }
            
        } catch {
            displayToastPopup(`We weren't able to redirect you to the chosen lender's website. Please try again later.`, true);
        }

        setLoading(false);
    }

    function handleApply() {
        onApply(true);
        handleExplore();
    }

    return (
        <>
            <div className={`mobile-view-offer-button-container ${progress !== 0 ? "active" : ""}`}>
                <div className={`interaction-button-container ${progress !== 0 ? "active" : ""}`}>
                    <OfferCardHeaderPinButton
                        icon={getPinIcon()}
                        toggle={pinToggle}
                        isExpand={progress !== 0}
                    />
                    <OfferCardHeaderBookmarkButton
                        icon={bookmarkCurrentValue ? bookmarkActiveIcon : bookmarkIcon}
                        toggle={bookmarkToggle}
                        isExpand={progress !== 0}
                    />
                </div>
                <div
                    style={
                        progress === 0
                            ? {
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  width: "100%",
                              }
                            : { width: "100%" }
                    }
                >
                    <OfferCardHeaderApplyButton
                        handleApply={handleApply}
                        isDefault={true}
                        isActive={progress !== 0}
                        applyWithoutCosigner={applyWithoutCosigner}
                    />
                </div>
            </div>
        </>
    );
};
