import {
    OffersTablePreferences,
    OffersTablePreferencesFrontend,
} from "../../../../../../sparrow-ui-library/src/types/sparrow-types";
import { IIncomeBasedOffer, IOffer } from "../../../../../core/services/types/offers-api-types";
import { isDefined } from "../../../../../utils/helper_functions/validation-functions";
import {
    getOfferValueForInSchoolPayment,
    getOfferValueForTotalCost,
    getOfferValueForAfterSchoolPayment,
    getOfferValueForShortestTerm,
    getOfferValueForLowestAPR,
} from "./SortingUtilities";

/**
 * This function converts the best rate label to a frontend string
 * @param label
 * @returns
 */
export function convertBestRateLabelToFrontendString(
    isPSL: boolean,
    label?: OffersTablePreferences
) {
    switch (label) {
        case OffersTablePreferences.LOWEST_APR:
            return OffersTablePreferencesFrontend.LOWEST_APR;
        case OffersTablePreferences.LOWEST_IN_SCHOOL:
            return OffersTablePreferencesFrontend.LOWEST_IN_SCHOOL;
        case OffersTablePreferences.LOWEST_MONTHLY_PAYMENT:
            return isPSL
                ? OffersTablePreferencesFrontend.LOWEST_MONTHLY_PAYMENT_PSL
                : OffersTablePreferencesFrontend.LOWEST_MONTHLY_PAYMENT_SLR;
        case OffersTablePreferences.SHORTEST_TERM:
            return OffersTablePreferencesFrontend.SHORTEST_TERM;
        case OffersTablePreferences.LOWEST_TOTAL_COST:
            return OffersTablePreferencesFrontend.LOWEST_TOTAL_COST;
        case OffersTablePreferences.AUTO_ROUNDED:
            return OffersTablePreferencesFrontend.AUTO_ROUNDED;
        default:
            return "";
    }
}

/**
 * This function parses the offer sap variable into the frontend string
 * @param allowed
 * @returns
 */
export function parseOfferSAP(allowed?: boolean) {
    if (!isDefined(allowed)) return "Not reported";
    return allowed ? "Yes" : "No";
}

export type PaymentPlanFormattedString =
    | "Immediate"
    | "Interest only"
    | "Fixed (partial interest)"
    | "Fully deferred";

export function parseOfferRepaymentPlan(repaymentPlan: string): PaymentPlanFormattedString {
    switch (repaymentPlan) {
        case "Immediate":
            return "Immediate";
        case "Immediate interest and principal payments":
            return "Immediate";
        case "Immediate Repay":
            return "Immediate";
        case "Interest Only":
            return "Interest only";
        case "Minimum $25 Payment":
            return "Fixed (partial interest)";
        case "Flat Payment":
            return "Fixed (partial interest)";
        case "Deferred":
            return "Fully deferred";
        default:
            return repaymentPlan as PaymentPlanFormattedString;
    }
}

/**
 * This function parses the offer past due balance variable into the frontend string
 * @param allowed
 * @returns
 */
export function parseOfferPastDueBalance(years?: number) {
    if (!isDefined(years)) return "Not reported";
    switch (years) {
        case 0:
            return "Not allowed";
        case 6:
            return "Within 6 months";
        case 12:
            return "Within 1 year";
        default:
            return "Not allowed";
    }
}

/**
 * This function obtains the primary rank value of an offer based on the rank type.
 * @param offer
 * @returns
 */
export function getPrimaryRankValue(offer: any, primaryRank: OffersTablePreferences) {
    switch (primaryRank) {
        case OffersTablePreferences.LOWEST_IN_SCHOOL:
            return getOfferValueForInSchoolPayment(offer);
        case OffersTablePreferences.LOWEST_TOTAL_COST:
            return getOfferValueForTotalCost(offer);
        case OffersTablePreferences.LOWEST_MONTHLY_PAYMENT:
            return getOfferValueForAfterSchoolPayment(offer);
        case OffersTablePreferences.SHORTEST_TERM:
            return getOfferValueForShortestTerm(offer);
        default:
            return 0;
    }
}

/**
 * This function obtains the secondary rank value of an offer based on the rank type.
 * @param offer
 * @returns
 */
export function getSecondaryRankValue(params: {
    offer: any;
    secondaryRank: OffersTablePreferences;
    substituteSecondaryRank?: OffersTablePreferences;
}) {
    const { offer, secondaryRank, substituteSecondaryRank } = params;
    const rank = isDefined(substituteSecondaryRank) ? substituteSecondaryRank! : secondaryRank;
    switch (rank) {
        case OffersTablePreferences.LOWEST_IN_SCHOOL:
            return getOfferValueForInSchoolPayment(offer);
        case OffersTablePreferences.LOWEST_TOTAL_COST:
            return getOfferValueForTotalCost(offer);
        case OffersTablePreferences.LOWEST_MONTHLY_PAYMENT:
            return getOfferValueForAfterSchoolPayment(offer);
        case OffersTablePreferences.SHORTEST_TERM:
            return getOfferValueForShortestTerm(offer);
        case OffersTablePreferences.LOWEST_APR:
            return getOfferValueForLowestAPR(offer);
        case OffersTablePreferences.AUTO_ROUNDED:
            return offer.amountWasRounded ? 0 : 1; // Note: we assign a higher value when amountWasRounded is false in order to prioritize non rounded loans first
        default:
            return 0;
    }
}

/**
 * This function determines pinned colors
 * @param forYouOffers
 * @param allOtherOffers
 * @returns
 */
export function getPinnedMapping(params: {
    forYouOffers: (IOffer | IIncomeBasedOffer)[];
    allOtherOffers: (IOffer | IIncomeBasedOffer)[];
    isCosigner: boolean;
}) {
    const { forYouOffers, allOtherOffers, isCosigner } = params;
    const nextMapping = {};
    for (const offer of [...forYouOffers, ...allOtherOffers]) {
        if ((!isCosigner && offer.pinned) || (isCosigner && offer.cosignerPinned))
            nextMapping[offer.id] = Object.keys(nextMapping).length;
    }
    return nextMapping;
}
