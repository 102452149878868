import React, { useRef } from "react";

import "./style.scss";

import { OfferFiltersOptions, OfferFilterTabs } from "./utilities";
import { isDefined } from "../../utils/helper_functions/validation-functions";
import { OfferFilterDropdownTab } from "./OfferFilterDropdownTab";
import { useOnboarding } from "../../core/providers/onboarding.provider";

interface OfferFilterTabProps {
    config: {
        name: OfferFilterTabs;
        defaultIcon: any;
        activeIcon: any;
        icon: ({isActive, userType}) => any;
    };
    checkboxMapping: {};
    setCheckboxMapping: Function;
    updateFilterOptions: Function;
    filterOptions: OfferFiltersOptions;
    isOpen: boolean;
    closeAllFilters: Function;
    openSingleFilter: Function;
    maxXPos: number;
    isMobile?: boolean;
}

export const OfferFilterTab: React.FunctionComponent<OfferFilterTabProps> = ({
    config,
    checkboxMapping,
    setCheckboxMapping,
    updateFilterOptions,
    filterOptions,
    isOpen,
    closeAllFilters,
    openSingleFilter,
    maxXPos,
    isMobile = false,
}) => {
    const onboarding = useOnboarding();

    function isActive() {
        for (const key in checkboxMapping) {
            if (checkboxMapping[key]) return true;
        }
        return false;
    }

    function isMultiSelect() {
        return [
            OfferFilterTabs.PastDueBalances,
            OfferFilterTabs.RepaymentPlan,
            OfferFilterTabs.Lender,
            OfferFilterTabs.RateType,
            OfferFilterTabs.LoanType,
        ].includes(config.name);
    }

    function toggleFilterOptionsAndReturnCopy(filters: string[], filterList: string[]) {
        const newSetOfFilters: string[] = [];
        for (const newFilter of filters) {
            if (!filterList.includes(newFilter)) {
                newSetOfFilters.push(newFilter);
            }
        }
        for (const oldFilter of filterList) {
            if (!filters.includes(oldFilter)) {
                newSetOfFilters.push(oldFilter);
            }
        }
        return newSetOfFilters;
    }

    function addNewFilter(filters: string[], target: string) {
        if (target === OfferFilterTabs.Lender) {
            return {
                ...filterOptions,
                lenderOptions: toggleFilterOptionsAndReturnCopy(
                    filters,
                    filterOptions.lenderOptions
                ),
            };
        }
        if (target === OfferFilterTabs.SAP) {
            return {
                ...filterOptions,
                sapOptions: filters,
            };
        }
        if (target === OfferFilterTabs.PastDueBalances) {
            return {
                ...filterOptions,
                pastDueBalanceOptions: toggleFilterOptionsAndReturnCopy(
                    filters,
                    filterOptions.pastDueBalanceOptions
                ),
            };
        }
        if (target === OfferFilterTabs.Term) {
            return {
                ...filterOptions,
                termOptions: filters,
            };
        }
        if (target === OfferFilterTabs.RepaymentPlan) {
            return {
                ...filterOptions,
                paymentPlanOptions: toggleFilterOptionsAndReturnCopy(
                    filters,
                    filterOptions.paymentPlanOptions
                ),
            };
        }
        if (target === OfferFilterTabs.LoanType) {
            return {
                ...filterOptions,
                loanTypeOptions: toggleFilterOptionsAndReturnCopy(
                    filters,
                    filterOptions.loanTypeOptions
                ),
            };
        }
        if (target === OfferFilterTabs.RateType) {
            return {
                ...filterOptions,
                rateTypeOptions: toggleFilterOptionsAndReturnCopy(
                    filters,
                    filterOptions.rateTypeOptions
                ),
            };
        }
    }

    function getAdditionalOnChangeFunction(target: string) {
        return (filters: string[]) => {
            const nextFilterOptions = addNewFilter(filters, target);
            if (isDefined(nextFilterOptions)) {
                updateFilterOptions(nextFilterOptions);
            }
        };
    }

    function handleClick() {
        closeAllFilters();
        if (!isOpen) {
            openSingleFilter(config.name);
        }
    }
    const positionRef = useRef<HTMLDivElement>(null);

    return (
        <>
            {isMobile ? (
                <>
                    <div
                        className={"mobile-filter-block"}
                        id={"filter-container-" + config.name}
                        ref={positionRef}
                    >
                        <div className="mobile-filter-block__body">
                            <OfferFilterDropdownTab
                                isMultiSelect={isMultiSelect()}
                                getAdditionalOnChangeFunction={getAdditionalOnChangeFunction}
                                closeAllFilters={closeAllFilters}
                                setCheckboxMapping={setCheckboxMapping}
                                checkboxMapping={checkboxMapping}
                                config={config}
                                xPos={0}
                                maxXPos={maxXPos}
                                isOpen={isOpen}
                                positionRef={positionRef}
                                isMobile={true}
                                icon={config.defaultIcon}
                            />
                        </div>
                    </div>
                </>
            ) : (
                <div
                    className="filter-container"
                    id={"filter-container-" + config.name}
                    ref={positionRef}
                >
                    <div
                        onClick={() => handleClick()}
                        className={`offer-filter-container ${isActive() ? `selected ${onboarding.formData.userType}` : ""}`}
                    >
    
                            {config.icon({isActive: isActive(), userType: onboarding.formData.userType})}

                        <div className={`offer-filter-name`}>{config.name}</div>
                    </div>

                    {isOpen && (
                        <OfferFilterDropdownTab
                            isMultiSelect={isMultiSelect()}
                            getAdditionalOnChangeFunction={getAdditionalOnChangeFunction}
                            closeAllFilters={closeAllFilters}
                            setCheckboxMapping={setCheckboxMapping}
                            checkboxMapping={checkboxMapping}
                            config={config}
                            xPos={
                                positionRef?.current
                                    ? positionRef?.current?.getBoundingClientRect().x
                                    : 0
                            }
                            maxXPos={maxXPos}
                            isOpen={isOpen}
                            positionRef={positionRef}
                        />
                    )}
                </div>
            )}
        </>
    );
};
