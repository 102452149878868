import { UserType } from "..";

export enum IncludedUserStatus {
    DIRECT_STARTED = "direct started",
    INVITE_PENDING = "invite pending",
    INVITE_STARTED = "invite started",
    INVITE_REJECTED = "invite rejected",
    CONFIRMED = "confirmed",
}

export interface IncludedUser {
    firstName: string;
    lastName: string;
    email: string;
    status: IncludedUserStatus;
    userType: UserType;
    inclusionType: "direct" | "invited";
}