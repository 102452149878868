import CardDashboard from "./Components/Card";
import OfferCard from "./Components/OfferCard";
import './style.scss'

// Assets
import lines from '../../assets/interface_dashboard/Lines.png';
import logo1 from '../../assets/interface_dashboard/student-loan.svg';
import logo2 from '../../assets/interface_dashboard/student-loan-crest.svg';
import logo3 from '../../assets/interface_dashboard/student-loan-parent.svg';
import { PAAASLogo } from "../../../../common/components/PAAASLogo";

// Constants
const cards = [
    {
        icon: 'icon-dashboard-search',
        title: 'Loan size',
        value: '$20,000',
        isCosigner: false
    },
    {
        icon: 'icon-dashboard-anual-income',
        title: 'Annual income',
        value: '$50,000',
        isCosigner: false
    },
    {
        icon: 'icon-dashboard-education',
        title: 'Education',
        value: 'Stanford University',
        isCosigner: false
    },
    {
        icon: 'icon-dashboard-add-cosigner',
        title: 'Cosigner',
        value: 'Add cosigner',
        isCosigner: true
    }
]
const filter = [
    {
        icon: 'icon-dashboard-bookmark',
        title: 'Bookmarked'
    },
    {
        icon: 'icon-dashboard-search',
        title: 'Loan type'
    },
    {
        icon: 'icon-dashboard-rate-type',
        title: 'Rate type'
    },
    {
        icon: 'icon-dashboard-payment-plan',
        title: 'Repayment plan'
    },
    {
        icon: 'icon-dashboard-lender',
        title: 'Lender'
    },
    {
        icon: 'icon-dashboard-term',
        title: 'Term'
    },
    {
        icon: 'icon-dashboard-satisfactory-academic-progress',
        title: 'Satisfactory academic progress'
    },
    {
        icon: 'icon-refi',
        title: 'Past due balance'
    }
]
const offers = [
    {
        logo: logo1,
        lender: 'Lender 1',
        termType: 'Shortest term',
        apr: '6.88%',
        term: '5 years',
        total: '$23,693.56',
        isStared: "#6DB353"
    },
    {
        logo: logo2,
        lender: 'Lender 2',
        termType: 'Lowest APR',
        apr: '6.24%',
        term: '10 years',
        total: '$26,935.09',
        isStared: '#6177E8'
    },
    {
        logo: logo3,
        lender: 'Lender 3',
        termType: null,
        apr: '6.47%',
        term: '10 years',
        total: '$27,214.89',
        isStared: '#E6C414'
    },
    {
        logo: logo1,
        lender: 'Lender 4',
        termType: null,
        apr: '6.82%',
        term: '10 years',
        total: '$27,643.90',
        isStared: '#A461E8'
    },
    {
        logo: logo2,
        lender: 'Lender 5',
        termType: null,
        apr: '6.90%',
        term: '10 years',
        total: '$27,742.50',
        isStared: null
    }
]
const chartCards = [
    {
        color: '#6DB353',
        value: '6.88%, 5 years',
        lender: 'Lender 1'
    },
    {
        color: '#6177E8',
        value: '6.24% 10 years',
        lender: 'Lender 2'
    },
    {
        color: '#E6C414',
        value: '6.47%, 10 years',
        lender: 'Lender 3'
    },
    {
        color: '#A461E8',
        value: '6.82%, 10 years',
        lender: 'Lender 4'
    },
    {
        color: '#F19048',
        value: '4.98%, 15 years',
        lender: 'Lender 5'
    },

]
interface IInterfaceDashboard {
    customClass?: string
    type?: 'default' | 'main' | 'crest' | 'small',
    size?: 'default' | 'medium' | 'slider'
}
const InterfaceDashboard = ({customClass = '', type = 'default', size = 'default'}:IInterfaceDashboard) => {
    return <div className={`interfaces-dashboard-wrapper interfaces-dashboard-wrapper-${size} ${customClass} interfaces-dashboard-wrapper-${type}`}>
        <div className={`interfaces-dashboard-inner ${customClass}`}>
            <div className={`interfaces-dashboard-sidebar ${customClass}`}>
                {customClass === "zero-fees-section" &&<PAAASLogo logoClassName="landingFullSizePreview"/>}
                <div style={{marginTop: "34px", display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%"}}>
                <div className="interfaces-dashboard-sidebar__top">
                    <div className={`interfaces-dashboard-sidebar__menu ${customClass}`}>
                        <ul>
                            <li className={`${customClass}`}>
                                <span className={`icon-dashboard ${customClass}`}/> Dashboard
                            </li>
                            <li className={`${customClass}`}>
                            <span className={`icon-dashboard-faq ${customClass}`}/> FAQ
                            </li>
                        </ul>
                    </div>
                    <div className={`interfaces-dashboard-sidebar__steps ${customClass}`}>
                        <div className={`interfaces-dashboard-sidebar__step interfaces-dashboard-sidebar__step-complete ${customClass}`}>
                            <span className={`icon-check ${customClass}`}/> Pre-qualify
                        </div>
                        <div className={`interfaces-dashboard-sidebar__step interfaces-dashboard-sidebar__step-current ${customClass}`}>
                            <span className={`icon-check ${customClass}`}/> Compare and select
                        </div>
                        <div className={`interfaces-dashboard-sidebar__step ${customClass}`}>
                            <span className={`icon-check ${customClass}`}/> Apply with a lender
                        </div>
                        <div className={`interfaces-dashboard-sidebar__step ${customClass}`}>
                            <span className={`icon-check ${customClass}`}/> Finalize your loan
                        </div>
                        <div className={`interfaces-dashboard-sidebar__step ${customClass}`}>
                            <span className={`icon-check ${customClass}`}/> Receive your funds
                        </div>
                    </div>
                </div>
                <div className="interfaces-dashboard-sidebar__bottom">
                    <div className="interfaces-dashboard-sidebar__user">
                        <div className={`interfaces-dashboard-sidebar__user-ava ${customClass}`}>JS</div>
                        <div className={`interfaces-dashboard-sidebar__user-name ${customClass}`}>Jack S.</div>
                    </div>
                    <div className={`interfaces-dashboard-sidebar__support ${customClass}`}>
                        <span className={`icon-dashboard-chat ${customClass}`}/> 
                        Customer support
                    </div>
                </div>

                </div>
            </div>
            <div className={`interfaces-dashboard-content ${customClass}`}>
                <div className={`interfaces-dashboard-content__head ${customClass}`}>
                    <div className={`interfaces-dashboard-content__head-left ${customClass}`}>
                        <span className={`icon-dashboard-arrow-left ${customClass}`}/>
                        <div className={`h2 ${customClass}`}>Jack, you pre-qualified for <span>22 rates!</span></div>
                        <p className={`${customClass}`}>Once you have found the best pre-qualified rate for you, click <b>Apply</b> and we’ll take you to the right place.   </p>
                    </div>
                </div>
                <div className="interfaces-dashboard-content__cardInfo">
                    <div className="interfaces-dashboard-content__cards">
                        {cards.map(({icon, isCosigner, title, value}, index) => {
                            return <div className={`col-3 col-3-ovr ${customClass}`} key={`interfaces-dashboard-content__card-${index}`}>
                                <CardDashboard icon={icon} title={title} value={value} isCosigner={isCosigner} customClass={customClass}/>
                            </div>
                        })}
                    </div>
                    <div className="interfaces-dashboard-content__filters">
                        {filter.map(({title, icon}, i) => {
                            return <div className={'interfaces-dashboard-content__filter'} key={`interfaces-dashboard-content__filter-${i}`}>
                                <span className={icon}/> {title}
                            </div>
                        })}
                        <div className={'interfaces-dashboard-content__filter-scroll'}><span className={'icon-dashboard-arrow-right'}/></div>
                    </div>
                </div>
                <div className="interfaces-dashboard-content__charts">
                    <div className="col interfaces-dashboard-content__chart interfaces-dashboard-content__chart-line">
                        <div className="interfaces-dashboard-content__chart-head">
                            <div className="interfaces-dashboard-content__chart-title">Your payments over time <span className={'icon-dashboard-tooltip'}/></div>
                        </div>
                        <div className={`interfaces-dashboard-content__chart-content ${customClass}`}>
                        <div className={`interfaces-dashboard-content__chart-content-left ${customClass}`}>
                                <span>$30,000</span>
                                <span>25,000</span>
                                <span>$20,000</span>
                                <span>$10,000</span>
                                <span>$0</span>
                            </div>
                            <div className={`interfaces-dashboard-content__chart-content-right ${customClass}`}>
                                <span/>
                                <span/>
                                <span/>
                                <span/>
                                <span/>
                                <img src={lines} alt={''} className={'chart-img-interface'} />
                            </div>
                            <div className="interfaces-dashboard-content__chart-content-bottom">
                                <span>‘23</span>
                                <span>‘24</span>
                                <span>‘25</span>
                                <span>‘26</span>
                                <span>‘27</span>
                                <span>‘28</span>
                                <span>‘29</span>
                                <span>‘30</span>
                                <span>‘31</span>
                                <span>‘32</span>
                                <span>‘33</span>
                                <span>‘34</span>
                                <span>‘35</span>
                                <span>‘36</span>
                                <span>‘37</span>
                                <span>‘38</span>
                            </div>
                        </div>
                    </div>
                    <div className="col interfaces-dashboard-content__chart interfaces-dashboard-content__chart-bar">
                        <div className="interfaces-dashboard-content__chart-head">
                            <div className="interfaces-dashboard-content__chart-title">Estimated cost <span className={'icon-dashboard-tooltip'}/></div>
                            <div className="interfaces-dashboard-content__chart-tab">
                                <span className={'active'}>Total cost</span>
                                <span>Payment after school</span>
                            </div>
                        </div>
                        <div className={`interfaces-dashboard-content__chart-content ${customClass}`}>
                            <div className={`interfaces-dashboard-content__chart-content-left ${customClass}`}>
                                <span>$30,000</span>
                                <span>25,000</span>
                                <span>$20,000</span>
                                <span>$10,000</span>
                                <span>$0</span>
                            </div>
                            <div className={`interfaces-dashboard-content__chart-content-right ${customClass}`}>
                                <span/>
                                <span/>
                                <span/>
                                <span/>
                                <span/>
                                <div className={'bar bar-1'}/>
                                <div className={'bar bar-2'}/>
                                <div className={'bar bar-3'}/>
                                <div className={'bar bar-4'}/>
                                <div className={'bar bar-5'}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="interfaces-dashboard-content__charts-cards">
                    {chartCards.map(({color, value, lender}, index) => {
                        return <div className={'interfaces-dashboard-content__charts-card'} key={`interfaces-dashboard-content__charts-card-${index}`}>
                            <span style={{borderColor: `${color}`}}/>
                            <div className="value">{value}</div>
                            <div className="lender">{lender}</div>
                        </div>
                    })}
                </div>
                <div className="interfaces-dashboard-content__offers">
                    <div className="interfaces-dashboard-content__offers-head">
                        <div className="h3"><span className={'icon-dashboard-star'}/> Best pre-qualified rates for you</div>
                        <p>Get out of debt ASAP (pre-qualified rates with the shortest terms) <span className={'icon-dashboard-tooltip'}/> <span className="href">Change my preferences</span></p>
                        <div className="interfaces-dashboard-content__offers-sort">Sort by: <span className={'interfaces-dashboard-content__offers-sort-selected'}>Lowest total cost</span> <span className={'icon-arrow-bottom'}/></div>
                    </div>
                    {offers.map(({logo, lender, termType, apr, term ,total ,isStared}, index) => {
                        return <OfferCard
                            logo={logo}
                            lender={lender}
                            termType={termType}
                            apr={apr}
                            term={term}
                            total={total}
                            isStared={isStared}
                            key={`OfferCard-item-${index}`}
                        />
                    })}
                </div>
            </div>
        </div>
    </div>
}

export default InterfaceDashboard