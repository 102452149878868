import {
    FormFieldProps,
    InputValue,
    FormFieldName,
    RadioButtonProps,
    DropdownFieldProps,
} from "../../../types/form-types";

export const updateFieldInputValue = (field: FormFieldProps, value: InputValue) => {
    // For input values that have additional fields populated only after a given input value e.g. country code, country prefix, etc. is inferred from phone number input
    if (typeof field.inputValue === "object" && typeof value === "object" && value !== null) {
        return { ...field.inputValue, ...value };
    }

    return value;
};

export const getCheckedButtonFields = (name: FormFieldName, activeRadioButtons: RadioButtonProps[]) => {
    const checkedRadioButton = activeRadioButtons.find(
        (radioButton) => radioButton.checked
    ) as RadioButtonProps;
    // This function is used for radio buttons with independent fields, so we can assume that independentFields is always defined
    const independentFields = checkedRadioButton.independentFields as FormFieldProps[];
    //Find the input field that corresponds to the input change
    const independentField = independentFields.find(
        (field) => field.name === name
    ) as DropdownFieldProps;
    // dependentFields may be undefined
    const { dependentFields } = checkedRadioButton;

    return { independentField, independentFields, dependentFields };
};
