import {InputText} from "../../../../common/components/inputs/input_text/input_text";
import {AppModal} from "../../../../common/components/modals/AppModal";
import {useEffect, useState} from "react";
import { Button } from "../../../../common/components/buttons/button/Button";
import { ButtonTypes } from "../../../../common/components/buttons/button/Button";
import { UserType } from "../../../../common/constants/defaultInputs";
import { ButtonGroup } from "../../../../sparrow-ui-library/src/ui-components/buttons";

interface IProfilePersonalInfoModal {
    modalToggle: (show: boolean) => void;
    form: {
        email: string;
        firstName: string;
        lastName: string;
        currentPassword: string;
        newPassword: string;
        confirmPassword: string;
        phone_number: string;
    };
    onSubmit: () => void;
    onChange: (key: string, value: any) => void
}
const ProfilePersonalInfoModal = ({modalToggle, form, onSubmit, onChange}: IProfilePersonalInfoModal) => {
    const [isValidPersonalInfo, setIsValidPersonalInfo] = useState(false)

    useEffect(() => {
        validationUserInfo();
    }, [form])

    const validationUserInfo = () => {
        setIsValidPersonalInfo(form.firstName.length > 1 && form.lastName.length > 1)
    }

    return <AppModal onClose={() => modalToggle(false)} size={'md'} showCloseIcon={true} customClass={'profile-modal profile-modal-edit'} enableMobileView={true}>
        <div className="profile-modal-content profile-modal-content-edit">
            <div className="profile-modal-content__head">
                <h3 className="h3">Personal Information</h3>
            </div>
            <div className="form-row">
                <InputText
                    placeholder={'First name'}
                    type={'text'}
                    name={'firstName'}
                    label={'First name'}
                    value={form.firstName}
                    onClear={() => onChange('firstName', '')}
                    onChange={(e) => onChange('firstName', e.target.value)}
                    error={form.firstName.length < 2 ? 'The minimum length must be 2 characters' : ''}
                />
            </div>
            <div className="form-row">
                <InputText
                    placeholder={'Last name'}
                    type={'text'}
                    name={'lastName'}
                    label={'Last name'}
                    value={form.lastName}
                    onClear={() => onChange('lastName', '')}
                    onChange={(e) => onChange('lastName', e.target.value)}
                    error={form.lastName.length < 2 ? 'The minimum length must be 2 characters' : ''}
                />
            </div>
            <ButtonGroup styles={{ marginTop: "32px" }}>
                    <Button
                        disabled={!isValidPersonalInfo}
                        style={{ fontSize: "16px", fontWeight: "500", lineHeight: "24px", height: "fit-content" }}
                        padding={"16px 25px"}
                        userType={UserType.BORROWER}
                        buttonType={ButtonTypes.PRIMARY}
                        onClick={onSubmit}
                    >
                        Update my personal information
                    </Button>
                </ButtonGroup>
        </div>
    </AppModal>
}

export default ProfilePersonalInfoModal