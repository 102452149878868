import { PrequalUrlsEnum } from "../../../common/core/global_routing/front-end-urls";
import { UserType } from "../../../common/constants/defaultInputs";
import { OnboardingStore } from "../../../common/store/onboarding";
import {
    utcToFormattedDateString,
    getFormattedDateWithoutDay,
} from "../../../common/utils/helper_functions/date-functions";
import removeDecimals from "../../../common/utils/removeDecimals";
import { ReviewCardSvg } from "../../../assets/icons/svg-get-functions/getReviewCardSvg";
import { getExpandedSearchFieldLabels } from "../expanded-search/hooks/get-es-field-labels";
import { ExpandedSearchDates } from "../../../sparrow-ui-library/src/types/sparrow-types";

interface SubFields {
    reviewFieldTitle: string;
    reviewFieldValue?: string;
}

export interface ReviewCardText {
    id: string;
    title: string;
    reviewFieldTitle?: string;
    reviewFieldValue?: string;
    icon?: ReviewCardSvg;
    editUrl?: string;
    subFields?: SubFields[];
}
const borrowOrRefinance = (onboarding) => (onboarding.formData.type === "PSL" ?? onboarding.type === "PSL") ? "borrow" : "refinance";

const getLoanAmountPageTitle = (
    onboarding: OnboardingStore,
): { currentPageTitle: string; reviewCardsTitle: string } => {

    const title = `How much ${
        onboarding.formData.userType === UserType.BORROWER
            ? "do you"
            : "does your borrower"
    } need?`;

    return {
        currentPageTitle: title,
        reviewCardsTitle: title,
    };
}

const getCitizenshipPageTitle = (
    onboarding: OnboardingStore,
    getIncludedUserTitle: boolean = false
): { currentPageTitle: string; reviewCardsTitle: string } => {
    return {
        currentPageTitle: onboarding.creatorIsIncludingUser
            ? `Is your ${onboarding.formData.includedUser?.userType} a U.S. citizen?`
            : "Are you a U.S. citizen?",
        reviewCardsTitle: getIncludedUserTitle
            ? `Is your ${onboarding.formData.includedUser?.userType} a U.S. citizen?`
            : "Are you a U.S. citizen?",
    };
};

const getAddressPageTitle = (
    onboarding: OnboardingStore,
    getIncludedUserTitle: boolean = false
): { currentPageTitle: string; reviewCardsTitle: string } => {
    return {
        currentPageTitle: `What's your ${
            onboarding.creatorIsIncludingUser
                ? `${onboarding.formData.includedUser?.userType}'s`
                : ""
        } address?`,
        reviewCardsTitle: getIncludedUserTitle
            ? `What's your ${onboarding.formData.includedUser?.userType}'s address?`
            : "What's your address?",
    };
};

const getIncomePageTitle = (
    onboarding: OnboardingStore,
    getIncludedUserTitle: boolean = false
): { currentPageTitle: string; reviewCardsTitle: string } => {
    return {
        currentPageTitle: `How much ${
            onboarding.creatorIsIncludingUser
                ? `does your ${onboarding.formData.includedUser?.userType}`
                : "do you"
        } earn?`,
        reviewCardsTitle: getIncludedUserTitle
            ? `How much does your ${onboarding.formData.includedUser?.userType} earn?`
            : "How much do you earn?",
    };
};

const getDegreePageTitle = (
    onboarding: OnboardingStore,
    getIncludedUserTitle: boolean = false
): { currentPageTitle: string; reviewCardsTitle: string } => {
    return {
        currentPageTitle: `What and where ${
            onboarding.creatorIsIncludingUser ? "does/did your borrower" : "do/did you"
        } study?`,
        reviewCardsTitle: getIncludedUserTitle
            ? `What and where does/did your ${onboarding.formData.includedUser?.userType} study?`
            : "What and where do/did you study?",
    };
};

const getSsnPageTitle = (
    onboarding: OnboardingStore
): { currentPageTitle: string; reviewCardsTitle: string } => {
    return {
        currentPageTitle: `Submit your ${
            onboarding.creatorIsIncludingUser ? "cosigned" : ""
        } request to pre-qualify`,
        reviewCardsTitle: `${onboarding.companyName} never shares your personal information`,
    };
};

const getExpandedSearchPageTitle = (
    onboarding: OnboardingStore
): { currentPageTitle: string; reviewCardsTitle: string } => {
    const title = `Tell us more about ${
        onboarding.formData.userType === UserType.COSIGNER ? `${onboarding.formData.includedUser?.firstName}'s` : "your"
    } time at ${onboarding.formData.degrees[0]?.university}`;
    return {
        currentPageTitle: title,
        reviewCardsTitle: title,
    };
};

export const getLoanAmountPageText = (
    onboarding: OnboardingStore
): { title: string; subtitle: string; nextStep: string } => {

    return {
        title: getLoanAmountPageTitle(onboarding).currentPageTitle,
        subtitle: `Estimate how much ${onboarding.formData.userType === UserType.BORROWER ? "you need" : "your borrower needs"} to  ${borrowOrRefinance(onboarding)}. You can always change this later.`,
        nextStep: onboarding.reviewStepReached ? "Review" : "Continue",
        };
};

export const getCitizenshipPageText = (
    onboarding: OnboardingStore
): { title: string; subtitle: string; nextStep: string } => {
    return {
        title: getCitizenshipPageTitle(onboarding).currentPageTitle,
        subtitle: `Lenders on the ${onboarding.companyName} platform support U.S. and foreign citizens.`,
        nextStep: onboarding.reviewStepReached ? "Review" : "Continue",
    };
};

export const getAddressPageText = (
    onboarding: OnboardingStore
): { title: string; subtitle: string; nextStep: string } => {
    return {
        title: getAddressPageTitle(onboarding).currentPageTitle,
        subtitle: `Your ${
            onboarding.creatorIsIncludingUser
                ? `${onboarding.formData.includedUser?.userType}'s`
                : ""
        } address is used to verify ${
            onboarding.creatorIsIncludingUser ? "their" : "your"
        } identity.`,
        nextStep: onboarding.reviewStepReached ? "Review" : "Continue",
    };
};

export const getIncomePageText = (
    onboarding: OnboardingStore
): { title: string; subtitle: string; nextStep: string } => {
    return {
        title: getIncomePageTitle(onboarding).currentPageTitle,
        subtitle: `Include your ${
            onboarding.creatorIsIncludingUser
                ? `${onboarding.formData.includedUser?.userType}'s`
                : ""
        } primary annual pre-tax income and any additional pre-tax earnings. Alimony, child support, or separate maintenance income do not need to be disclosed if you don’t want lenders to consider it.`,
        nextStep: onboarding.reviewStepReached ? "Review" : "Continue",
    };
};

export const getDegreePageText = (
    onboarding: OnboardingStore
): { title: string; subtitle: string; nextStep: string } => {
    return {
        title: getDegreePageTitle(onboarding).currentPageTitle,
        subtitle: `Tell us more about the highest degree that ${
            onboarding.creatorIsIncludingUser
                ? "your borrower has earned or is in the process of"
                : "you've earned or are in the process of"
        } earning.`,
        nextStep: onboarding.reviewStepReached ? "Review" : "Continue",
    };
};

export const getReviewPageText = (
    onboarding: OnboardingStore
): { title: string; subtitle: string; subheader: string } => {
    return {
        //if there is an includedUser and we're back on the review page, by default it's been cosigner
        title: getSsnPageTitle(onboarding).currentPageTitle,
        subtitle: `We will need to verify your ${
            onboarding.creatorIsIncludingUser
                ? `${onboarding.formData.includedUser?.userType}'s`
                : ""
        } identity in order to submit your request for pre-qualified rates.`,
        subheader: `${onboarding.companyName}  never shares your ${
            onboarding.creatorIsIncludingUser
                ? `${onboarding.formData.includedUser?.userType}'s`
                : "personal"
        } information`,
    };
};

export const getExpandedSearchPageText = (
    onboarding: OnboardingStore
): { title: string; subtitle: string;} => {
    return {
        title: getExpandedSearchPageTitle(onboarding).currentPageTitle,
        subtitle: `This information will help us expand our search for pre-qualified rates.`,
    };
};

/**
 * Returns the text for the review cards for the pre-qualification steps shared by both Borrowers and Cosigners, such as citizenship, address, and income
 * Depending on if the user is on the review page after including their own information or the included user's information, the text will be different for the included user. Therefore we need to indicate getIncludedUserReviewText true to get the included user's version of the text.
 * @param onboarding: OnboardingStore
 * @param getIncludedUserReviewText: boolean
 * @returns ReviewCardText[]
 */
export const getAllUserTypesText = (
    onboarding: OnboardingStore,
    getIncludedUserReviewText: boolean = false
): ReviewCardText[] => {
    
    const activeUser = getIncludedUserReviewText ? onboarding.getOppositeUserType() : onboarding.formData.userType as UserType;
    const activeFormData = onboarding.getUserTypeFormData(activeUser);
    const hasSSN = !!onboarding.checkActiveUserTypeHasSSN(activeUser);

    //Include only if active user has SSN
    const addressAndIncomeReviewText = hasSSN ? [
        {
            id: getIncludedUserReviewText ? "review-included-address" : "review-address",
            title: getAddressPageTitle(onboarding, getIncludedUserReviewText).reviewCardsTitle,
            reviewFieldTitle: "Permanent address",
            reviewFieldValue: activeFormData?.permanentAddress?.addressLine1,
            icon: ReviewCardSvg.ADDRESS,
            subFields: [
                {
                    reviewFieldTitle: "Housing situation",
                    reviewFieldValue: activeFormData?.housing?.type,
                },
                {
                    reviewFieldTitle: "Monthly housing expense",
                    reviewFieldValue: activeFormData?.housing?.payment.display,
                },
            ],
            editUrl: PrequalUrlsEnum.ADDRESS as string,
        },
        {
            id: getIncludedUserReviewText ? "review-included-income" : "review-income",
            title: getIncomePageTitle(onboarding, getIncludedUserReviewText).reviewCardsTitle,
            reviewFieldTitle: "Annual pre-tax income",
            reviewFieldValue: removeDecimals(activeFormData?.income?.amount.display as string),
            icon: ReviewCardSvg.INCOME,
            subFields: [
                {
                    reviewFieldTitle: "Employment status",
                    reviewFieldValue: activeFormData?.income.type
                },
            ],
            editUrl: PrequalUrlsEnum.INCOME as string,
        },
    ] : [];
    
    //Review text applicable to both Borrowers and Cosigners
    return [
        {
            id: getIncludedUserReviewText ? "review-included-citizenship" : "review-citizenship",
            title: getCitizenshipPageTitle(onboarding, getIncludedUserReviewText).reviewCardsTitle,
            reviewFieldTitle: "Citizenship status",
            reviewFieldValue: activeFormData?.citizenshipStatus?.type,
            icon: ReviewCardSvg.CITIZENSHIP,
            editUrl: PrequalUrlsEnum.CITIZENSHIP as string,
        },
        ...addressAndIncomeReviewText,
    ];
};

//Review text applicable to only Creators
const loanAmountText = (
    onboarding: OnboardingStore,
): ReviewCardText => {
    const { formData } = onboarding;

    return {
        id: "review-loan-amount",
        title: getLoanAmountPageTitle(onboarding).reviewCardsTitle,
        reviewFieldTitle: "Loan size",
        reviewFieldValue: removeDecimals(formData.loanAmount.display),
        icon: ReviewCardSvg.LOAN_SIZE,
        editUrl: PrequalUrlsEnum.AMOUNT as string,
    };
}

//Review text applicable to only Borrowers
const degreesText = (
    onboarding: OnboardingStore,
    getIncludedUserReviewText: boolean = false
): ReviewCardText => {
    const { formData } = onboarding;

    return {
        id: getIncludedUserReviewText ? "review-included-degrees" : "review-degrees",
        title: getDegreePageTitle(onboarding, getIncludedUserReviewText).reviewCardsTitle,
        reviewFieldTitle: "School",
        reviewFieldValue: formData.degrees[0].university,
        icon: ReviewCardSvg.SCHOOL,
        subFields: [
            {
                reviewFieldTitle: "Degree type",
                reviewFieldValue: formData.degrees[0].degreeType,
            },
            {
                reviewFieldTitle: "Major or concentration",
                reviewFieldValue: formData.degrees[0].major,
            },
            {
                reviewFieldTitle: "Estimate graduation date",
                reviewFieldValue: getFormattedDateWithoutDay(formData.degrees[0].gradDate),
            },
            {
                reviewFieldTitle: "Enrollment status",
                reviewFieldValue: formData.enrollment,
            },
        ],
        editUrl: PrequalUrlsEnum.DEGREES as string,
    };
};

const ssnText = (onboarding: OnboardingStore): ReviewCardText => {
    const {
        formData: { cosigner },
        formData,
    } = onboarding;
    const isActiveUserBorrower = formData.userType === UserType.BORROWER;

    return {
        id: "review-ssn",
        title: getSsnPageTitle(onboarding).reviewCardsTitle,
        reviewFieldTitle: "SSN",
        reviewFieldValue: `●●● - ●● - ${isActiveUserBorrower ? formData.ssn : cosigner?.ssn}`,
        icon: ReviewCardSvg.SSN,
        subFields: [
            {
                reviewFieldTitle: "Date of birth",
                reviewFieldValue: utcToFormattedDateString(
                    isActiveUserBorrower ? formData.dob : (cosigner?.dob as number)
                ),
            },
        ],
    };
};

const expandedSearchText = (onboarding: OnboardingStore): ReviewCardText => {
    const {
        formData,
    } = onboarding;
    const {expandedSearchDates} = onboarding;
    const esFieldLables = getExpandedSearchFieldLabels(expandedSearchDates as ExpandedSearchDates);
    
    return {
        id: "review-expanded-search",
        title: getExpandedSearchPageTitle(onboarding).reviewCardsTitle,
        reviewFieldTitle: "Expanded search",
        reviewFieldValue: formData.gpaCumulative,
        icon: ReviewCardSvg.EXPANDED_SEARCH,
        subFields: [
            {
                reviewFieldTitle: esFieldLables.hoursCompletedCurrentSemester,
                reviewFieldValue: formData.hoursCompletedCurrentSemester?.toString(),
            },
            {
                reviewFieldTitle: esFieldLables.gpaLastSemester,
                reviewFieldValue: formData.gpaLastSemester,
            },
            {
                reviewFieldTitle: esFieldLables.schoolYearStartDate,
                reviewFieldValue: utcToFormattedDateString(formData.schoolYearStartDate as number),
            },
            {
                reviewFieldTitle: esFieldLables.schoolYearEndDate,
                reviewFieldValue: utcToFormattedDateString(formData.schoolYearEndDate as number),
            },
        ],
        editUrl: PrequalUrlsEnum.EXPANDED as string,
    };
}

export const getReviewCardsText = (
    onboarding: OnboardingStore
): {
    activeUser: ReviewCardText[];
    includedUser?: ReviewCardText[];
} => {
    const {
        formData: { cosigner },
        formData,
    } = onboarding;
    const isActiveUserBorrower = formData.userType === UserType.BORROWER;
    const isActiveUserCosigner = formData.userType === UserType.COSIGNER;
    const isIncludedUserBorrower = formData.includedUser?.userType === UserType.BORROWER;

    //only return an array if the included user's information has been included.
    const getIncludedUser = onboarding.formData.includedUser && formData.includedStepsFinished === formData.includedStepsTotal;

    return {
        activeUser: [
            ...(onboarding.formData.isCreator ? [loanAmountText(onboarding)] : []),
            ...getAllUserTypesText(onboarding),
            //if the active user is a borrower, include the degrees text
            ...(isActiveUserBorrower ? [degreesText(onboarding)] : []),
            //if the active user has included their dob/ssn information, include the ssn text in the review cards for the direct inclusion view
            ...(onboarding.creatorIsIncludingUser &&
            ((isActiveUserBorrower && formData.ssn) || (isActiveUserCosigner && cosigner?.ssn))
                ? [ssnText(onboarding)]
                : []),
            ...((isActiveUserBorrower && onboarding.formData.gpaCumulative) ? [expandedSearchText(onboarding)] : []),
        ],
        includedUser: getIncludedUser
            ? [
                  ...getAllUserTypesText(onboarding, true),
                  //if the active user is a borrower, include the degrees text
                  ...(isIncludedUserBorrower ? [degreesText(onboarding, true)] : []),
                  ...((isIncludedUserBorrower && onboarding.formData.gpaCumulative) ? [expandedSearchText(onboarding)] : []),
              ]
            : [],
    };
};
