import React, { useEffect } from "react";
import { Document, pdfjs } from "react-pdf";
import { PdfRender } from "../../types/ui-components";
import { useSparrowUIContext } from "../../context";
import Pages from "./Pages";

const PdfContent: React.FC<PdfRender> = ({ fileName, numberOfPages }) => {
    const setModalRequiresReadThrough = useSparrowUIContext().setModalContentRequiresReadThrough;

    useEffect(() => {
        const pdfjsConfig = pdfjs.GlobalWorkerOptions;
        // Sets the workerSrc property to the URL that points to the PDF.js worker script
        pdfjsConfig.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, []);

    return (
        <Document
            onLoadError={() => {
                alert("An error occurred while loading the PDF.");
                setModalRequiresReadThrough(false);
            }}
            // For testing purposes in development use `/src/assets/documents/${fileName}`:
            file={`/documents/${fileName}`}
        >
            <Pages numberOfPages={numberOfPages} />
        </Document>
    );
};

export default PdfContent;
