import * as DOMPurify from 'dompurify';
//https://www.npmjs.com/package/dompurify?activeTab=readme
//DOMPurify is a library that helps sanitize HTML content to prevent XSS attacks by removing potentially unsafe elements and attributes.

const HtmlRenderer = ({ htmlString }: {htmlString: string}) => {
    // Sanitize the provided HTML string using DOMPurify
    const sanitizedHtml = DOMPurify.sanitize(htmlString, {ADD_ATTR: ['target']});
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
};

export default HtmlRenderer;
  