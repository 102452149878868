import { useSparrowUIContext } from "../../context";
import PdfContent from "../../ui-components/pdf-content";
import { LinkContent } from "../../types/ui-components";

/**
 * @description This hook is used to return a text component for toggle switches. The text component can be a string or a string with a link that
 * opens a modal or a link that opens a new tab. The linkContent prop is used to determine which type of text component to return. Include a tags
 * in the text prop to indicate where the link should be placed.
 * @param text
 * @param linkContent
 * @returns A text component that can be a string or a string with a link that opens a modal or a link that opens a new tab.
 */
const useToggleTextComponent = (text: string, linkContent?: LinkContent) => {
    const { showModal, setShowModal, setModalContent, setModalContentRequiresReadThrough } =
        useSparrowUIContext();
    // If there are no links on the text, return the text as is.
    if (!linkContent) return <>{text}</>;

    const parseText = (sentence: string) => {
        // Given the sentence: "word words words <a>link</a> words words words"
        // Split the sentence into an array of two parts:
        // 1. [words words words, link </a> words words words]
        const [wordsBeforeLink, linkAndWordsAfterLink] = sentence.split("<a>");

        // Split the second part (link and words after link) into an array of two parts:
        // 2. [link, words words words]
        const [link, wordsAfterLink] = linkAndWordsAfterLink.split("</a>");

        const handleClick = () => {
            if (!linkContent.modal) return;
            const { html, pdf, modalContentRequiresReadThrough = false } = linkContent.modal;
            if (html) {
            const { content } = html;
            setModalContent({ component: { child: content } });
            setModalContentRequiresReadThrough(modalContentRequiresReadThrough);
            setShowModal(!showModal);
            return;
            }

            if (pdf) {
                const{fileName, numberOfPages} = pdf;
                const pdfContent = <PdfContent fileName={fileName}numberOfPages={numberOfPages}/>
                setModalContent({ component: { child: pdfContent } });
                setModalContentRequiresReadThrough(modalContentRequiresReadThrough);
                setShowModal(!showModal);
            }
        };

        // Props if the link is a link that opens a new tab.
        const linkProps = linkContent.hrefLink
            ? {
                  href: linkContent.hrefLink,
                  target: "_blank",
              }
            : {};

        // Props if the link is a link that opens a modal.
        const modalProps = linkContent.modal
            ? {
                  onClick: handleClick,
              }
            : {};

        return (
            <>
                {wordsBeforeLink}
                <a {...linkProps} {...modalProps}>
                    {link}
                </a>
                {wordsAfterLink}
            </>
        );
    };

    return <>{parseText(text)}</>;
};

export default useToggleTextComponent;
