import { FormFieldName, FormFieldProps } from "../../../types/form-types";
import {
    dropdownMessage,
    employerNameMessage,
    textBoxMessage,
    phoneNumberMessage,
} from "./error-message-functions";

export const errorMessageFunctionObject: {
    [key: string]: (value: any, name: FormFieldName, form?: FormFieldProps[]) => string | null;
} = {
    [FormFieldName.CitizenshipStatusHasSSN]: dropdownMessage,
    [FormFieldName.CitizenshipStatusHomeCountry]: dropdownMessage,
    [FormFieldName.CitizenshipStatusType]: dropdownMessage,
    [FormFieldName.CitizenshipStatusVisaType]: dropdownMessage,
    [FormFieldName.EmployerName]: employerNameMessage,
    [FormFieldName.EmploymentStatus]: dropdownMessage,
    [FormFieldName.EmploymentIncome]: textBoxMessage,
    [FormFieldName.EmploymentOccupation]: dropdownMessage,
    [FormFieldName.HousingPaymentAmount]: textBoxMessage,
    [FormFieldName.HousingType]: dropdownMessage,
    [FormFieldName.IncomeAmount]: textBoxMessage,
    [FormFieldName.IncomeType]: dropdownMessage,
    [FormFieldName.LoanAmount]: textBoxMessage,
    [FormFieldName.PhoneNumberTimeZone]: textBoxMessage,
    [FormFieldName.PhoneNumberType]: textBoxMessage,
    [FormFieldName.PhoneNumber]: phoneNumberMessage,
    [FormFieldName.WhatsAppNumber]: phoneNumberMessage,
    [FormFieldName.DegreesGradDate]: textBoxMessage,
    [FormFieldName.DegreesDegreeType]: dropdownMessage,
    [FormFieldName.DegreesMajor]: dropdownMessage,
    [FormFieldName.DegreesUniversity]: dropdownMessage,
    [FormFieldName.Enrollment]: dropdownMessage,
    [FormFieldName.AddressLine1]: textBoxMessage,
    [FormFieldName.AddressLine2]: textBoxMessage,
    [FormFieldName.City]: textBoxMessage,
    [FormFieldName.CountryCode]: dropdownMessage,
    [FormFieldName.DOB]: textBoxMessage,
    [FormFieldName.Email]: textBoxMessage,
    [FormFieldName.FirstName]: textBoxMessage,
    [FormFieldName.LastName]: textBoxMessage,
    [FormFieldName.SSN]: textBoxMessage,
    [FormFieldName.State]: textBoxMessage,
    [FormFieldName.Zip]: textBoxMessage,
    [FormFieldName.ExpandedSearchGpaCumulative]: textBoxMessage,
    [FormFieldName.ExpandedSearchGpaLastSemester]: textBoxMessage,
    [FormFieldName.ExpandedSearchHoursCompletedCurrentSemester]: textBoxMessage,
    [FormFieldName.ExpandedSearchSchoolYearStartDate]: textBoxMessage,
    [FormFieldName.ExpandedSearchSchoolYearEndDate]: textBoxMessage,
    [FormFieldName.PAAASMembershipElection]: dropdownMessage,
};

export const getErrorMessageFunction = (fieldName: string): (value: any, name: FormFieldName, form?: FormFieldProps[]) => string | null => {
    return errorMessageFunctionObject[fieldName];
};
