import { useNavigate } from "react-router-dom";
import { GeneralUrlsEnum } from "../../../core/global_routing/front-end-urls";
import { useOnboarding } from "../../../core/providers/onboarding.provider";

import graduationHat from "../../../../assets/icons/lottie-icons/graduation-hat-(student loan).json";
import globe from "../../../../assets/icons/lottie-icons/globe-(international).json";
import dollarSignArrow from "../../../../assets/icons/lottie-icons/dollar-sign-arrow-(refinance).json";
import creditCardSwipe from "../../../../assets/icons/lottie-icons/credit-card-swipe-(credit history).json";
import creditCard from "../../../../assets/icons/lottie-icons/credit-card-(payment).json";
import ribbon from "../../../../assets/icons/lottie-icons/ribbon-(top rated).json";
import { GradLoanIcon, UndergradLoanIcon, RefinanceLoanIcon, InternationalRefinanceIcon, InternationalLoanIcon, ProfessionalLoanIcon } from "../../../../sparrow-ui-library/src/assets/icons/menu-icons";

import "./styles.scss";
import { RequestCard } from "./RequestCard";

export const RequestPopup = () => {
    const onboarding = useOnboarding();
    const navigate = useNavigate();

    const clickHandler = (loanType, title) => {
        onboarding.setLoanTypeAndSelection(loanType, title);
        return navigate(GeneralUrlsEnum.SELECT_USER_TYPE);
    };
    return (
        <div className="request-popup-container">
            <div className="request-popup-title-container">
                <div className="request-popup-title">Start a new request</div>
                <div className="request-popup-subtitle">Pre-qualify in as little as 3 minutes</div>
            </div>
            <RequestCard
                id="Undergraduate Loan"
                title="Undergraduate Loan"
                loanType="PSL"
                animationData={graduationHat}
                icon={() => <UndergradLoanIcon />}
                clickHandler={clickHandler}
            />
            <RequestCard
                id="Graduate Loan"
                title="Graduate Loan"
                loanType="PSL"
                animationData={creditCard}
                icon={() => <GradLoanIcon />}
                clickHandler={clickHandler}
            />
            <RequestCard
                id="Professional Degree Loan"
                title="Professional Degree Loan"
                loanType="PSL"
                animationData={ribbon}
                icon={() => <ProfessionalLoanIcon />}
                clickHandler={clickHandler}
            />
            <RequestCard
                id="Refinance Student Debt"
                title="Refinance Student Debt"
                loanType="PSL"
                animationData={creditCardSwipe}
                icon={() => <RefinanceLoanIcon />}
                clickHandler={clickHandler}
            />
            <div className="line" />
            <RequestCard
                id="International Loan"
                title="International Loan"
                loanType="PSL"
                animationData={globe}
                icon={() => <InternationalLoanIcon />}
                clickHandler={clickHandler}
            />

            <RequestCard
                id="International Refinance"
                title="International Refinance"
                loanType="SLR"
                animationData={dollarSignArrow}
                icon={() => <InternationalRefinanceIcon />}
                clickHandler={clickHandler}
            />
        </div>
    );
};