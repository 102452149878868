import { useOnboarding } from "../../core/providers/onboarding.provider";

import { CosignerCard } from "../CosignerCard/cosigner_card";

interface CosignerDropdownProps {
    text: string;
    onExit?: Function;
}

export const OfferHeaderCosignerDropdown: React.FunctionComponent<CosignerDropdownProps> = ({
    text,
    onExit,
}) => {
    const { formData } = useOnboarding();

    return (
        <>
            <CosignerCard id={formData.id as string} type={text} onExit={onExit}/>
        </>
    );
};
