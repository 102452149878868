import { useEffect } from "react";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { getFormsConfig } from "./forms-config";
import { FormWrapper } from "../../../sparrow-ui-library/src/ui-components/form-components";
import { handleSubmission } from "../../../sparrow-ui-library/src/utils/ui-library-utils";
import { useOnboarding } from "../../../common/core/providers/onboarding.provider";
import { PrequalUrlsEnum } from "../../../common/core/global_routing/front-end-urls";
import {  useSparrowUIFormsContext, useSparrowUIContext } from "../../../sparrow-ui-library/src/context";
import { NavigationFooter } from "../components/NavigationFooter";
import { UserType } from "../../../sparrow-ui-library/src/types/sparrow-types";
import { useStore } from "../../../common/core/providers/global.provider";
import { AutofillRequestModal } from "../../../common/components/modals/AutofillRequestModal";

export const PqAmount = observer(({ pathName }: { pathName: string }) => {
    // Store variables and methods
    //Note: for this page we don't need applicableFormData from getApplicableFormData because loan amount is a top level field in formData i.e., if a cosigner initiates a request, the loan amount is not in the cosigner field
    const onboarding = useOnboarding();
    const store = useStore();
    const { displayToastPopup } = useSparrowUIContext();
    const {forms, setForms} = useSparrowUIFormsContext();
    // Page specific forms
    const formsConfig = getFormsConfig({formData: onboarding.formData});

    useEffect(() => { 
        setForms(formsConfig.forms);
    },[]);

    // Page routing
    const navigate = useNavigate();
    const { nextStep } = onboarding.getStepRouting(pathName);

    // Submit handler
    const handleSubmit = () =>
        handleSubmission(
            {
                forms,
                setForms,
                checkEditsMadePostPrequalSubmission: onboarding.checkEditsMadePostPrequalSubmission,
            },
            // Callback logic
            async (formattedBackendForms) => {
                const loanAmount = (formattedBackendForms as any).loanAmount.amount as number;
                // If the user is eligible for auto-fill, present modal
                const autofillProps = onboarding.handleAutofillRequest(loanAmount);
                if(autofillProps){
                    store.setModal(() => <AutofillRequestModal {...autofillProps}/>);
                    return; 
                }
                //Otherwise, send request to backend
                try {
                    const response = await onboarding.prequalRequestHandler({
                        formattedBackendForms,
                        pathName: PrequalUrlsEnum.AMOUNT,
                    });
                    if (!response) return;
                    // If successful, navigate to next step
                    navigate(nextStep);
                } catch (err: any) {
                    onboarding.setPqLoader(false);
                    displayToastPopup(err.userDescription as string, true);
                }
            }
        );

  /*this is needed to determine if short steps should be used in case a user moves back to this page from the citizenship page after having made an SSN election (hasSSN: true/false), 
  but NOT submitting it to the backend.
  e.g. on Citizenship page user selects no SSN, but doesn't submit (go to next step), and instead navigates back to this step. In this case, we should use the steps associated only
  with their SUBMITTED (previously sent to backend) SSN election.*/
  
    useEffect(() => {
        onboarding.setUseShortSteps();
    }, []);

    return (
        <>
            {!!forms.length && <FormWrapper forms={forms} setForms={setForms} />}
            <div style={{ marginTop: "32px" }}>
                <NavigationFooter
                    userType={onboarding.formData.userType as UserType}
                    steps={{ prevStep: undefined, nextStep}}
                    handleSubmit={handleSubmit}
                />               
            </div>
        </>
    );
});
