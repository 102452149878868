import { useState, useEffect } from "react";
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider";
import { wordpressPaaasLandingFaq } from "../../../../common/core/services/sparrow-api-endpoints";
import { GetPublic } from "../../../../common/core/services/clientApi";
import FaqItem from "./components/FaqItem";
import styles from "./styles.module.scss";
import { SectionWrapper } from "../../components/section-wrapper/SectionWrapper";
import { observer } from "mobx-react";
import { insertCompanyNameInText } from "../../../../common/utils/helper_functions/insertCompanyNameInText";


export const FaqSection = observer(() => {
    const onboarding = useOnboarding();
    const [sideHeaderContent, setSideHeaderContent] = useState({ title: "", dateUpdated: "", description: "" });
    const [faqItems, setFaqItems] = useState([]);

    useEffect(() => {
        const getWPData = async () => {
            const response = await GetPublic(wordpressPaaasLandingFaq);
            const faqContent = response.acf.blog_content[0].faq_blocks[0];

            setSideHeaderContent({
                title: faqContent.block_title,
                dateUpdated: faqContent.faq_date,
                description: faqContent.block_sidebar_desctiption,
            });

            setFaqItems(faqContent.faqs);
        }

        getWPData();

    }, []);


    const getFaqItemsWithClientName = (faqItems) => {

        return faqItems.map((item) => {
            return {
                ...item,
                question: insertCompanyNameInText(item.qustion, onboarding.companyName),
                answer: insertCompanyNameInText(item.answer, onboarding.companyName),
            };
        });
    };

    return (
        <SectionWrapper hasBackground={true}>
            <div className={styles.faqContainer}>
                <div className={styles.sideContent}>
                    <h2 className={styles.title}>{sideHeaderContent.title}</h2>
                    <p className={styles.dateUpdated}>{sideHeaderContent.dateUpdated}</p>
                    <p className={styles.description}>{sideHeaderContent.description}</p>
                </div>
                {!!faqItems.length && <div style={{ display: "flex", flexDirection: "column", gap: "24px", width: "100%" }}>
                    {getFaqItemsWithClientName(faqItems).map((item) => (
                        <FaqItem question={item.question} answer={item.answer} />
                    ))}
                </div>}
            </div>
        </SectionWrapper>
    );
});

