import { TrashIconSvg } from '../../../../../assets/icons/TrashIconSvg';
import styles from '../styles.module.scss'
import { observer } from 'mobx-react';
import { useOnboarding } from '../../../../../common/core/providers/onboarding.provider';
import { DeleteLoanModal } from '../../../../../common/components/modals/DeleteLoanModal';
import { useStore } from '../../../../../common/core/providers/global.provider';
import { useState } from 'react';
import { QuestionMarkIconSvg } from '../../../../../assets/icons/QuestionMarkIconSvg';

export const LoanRequestCard = observer(({ icon, user, date, loanAmount, id }) => {
    const onboarding = useOnboarding();
    const store = useStore();

    const onDelete = () => {
        store.setModal(() => <DeleteLoanModal id={onboarding.formData.id} />);
    };

    const [displayToolTip, setDisplayToolTip] = useState(false);

    const displayToolTipInfo = () => {
        const { isExpired, isRefreshable } = onboarding.formData;
        if (!isExpired && !isRefreshable) return {
            display: false,
        };

        if (isExpired) {
            return {
                display: true,
                cssClass: styles.expired,
                tooltipText: "More than six months have passed and the information you submitted may be outdated. Please start a new request with your most recent information."
            }
        }

        
        return {
            display: false,
        }
        
    }

    const tooltipInfo = displayToolTipInfo();

    return (
        <div
            className={`${styles["loan-request-card"]} ${styles[onboarding.formData.userType as string]}`}
        >
            <div className="history-body-expanded-row">
                <div className={styles["flex-gap-8"]}>
                    <img src={icon} alt="icon" />
                    <div className="p-md-14-medium clr-white">{user}</div>
                </div>
                <div className={styles["flex-gap-8"]}>
                    <div className={`p-md-14-regular ${styles.date} ${tooltipInfo?.cssClass}`} onClick={() => setDisplayToolTip(!displayToolTip)} >
                        {date}
                        {tooltipInfo?.display &&
                            <>
                                <QuestionMarkIconSvg className={`${displayToolTip ? "active" : ""}`} />
                                <div className={`${styles["tooltip-content"]} ${displayToolTip ? styles["active"] : ""}`}>
                                    <p className="p-sm-12-regular">{tooltipInfo.tooltipText}</p>
                                </div>
                            </>
                        }
                    </div>

                </div>
            </div>
            <div className="history-body-expanded-row">
                <div className={styles["flex-gap-8"]}>
                    <p className="p-md-14-regular clr-neutral-50">
                        {onboarding.formData.loanSelectionType}
                    </p>
                </div>
                <div className={styles["flex-gap-8"]}>
                    <p className="p-md-14-regular clr-neutral-50">{loanAmount}</p>
                </div>
            </div>
            <div className="history-body-expanded-row">
                <div className={styles["flex-gap-8"]}>
                    <p className="p-md-14-regular clr-neutral-50">ID</p>
                </div>
                <div className={styles["flex-gap-8"]}>
                    <p className="p-md-14-regular clr-neutral-50">
                        ...{id!.slice(-12)}
                    </p>
                </div>
            </div>
            <button className="history-expanded-button" onClick={onDelete}>
                <TrashIconSvg className="ti-svg-loan-request-card" />
                <div>Delete</div>
            </button>
        </div>
    )
});
