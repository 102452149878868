import { MoneyAmount } from "../money-amount"
import { MoneyAmountInput } from "../../../types/sparrow-types";

export interface IncomeInput {
    amount: MoneyAmountInput;
    type: string | undefined;
}

export const Income = (): IncomeInput => {
    return {
        amount: MoneyAmount(),
        type: undefined,
    }
}