"use client";
import { RegularButton } from "../../../common/components/buttons/regular_button";
import { dashboardUrl, loginUrl } from "../../../common/core/routes/navigation-urls";
import IconAnimated from "../../../staticComponents/IconAnimation";
import congrat from "../../../assets/lottieJSON/icons/congrat/data.json";
import { observer } from "mobx-react";
import { useOnboarding } from "../../../common/core/providers/onboarding.provider";
import { useStore } from "../../../common/core/providers/global.provider";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { GetCurrentUserAndUpdateStore } from "../../../common/core/services/sparrow-api-handlers/profile-api-handlers";
import { StepFiveIcon } from "../../../sparrow-ui-library/src/assets/icons/dashboard-roadmap-icons";
import { UserType } from "../../../sparrow-ui-library/src/types/sparrow-types";
import { isMarketplaceEnv } from "../../../common/utils/config";

export const SuccessRegistrationPage = observer(() => {
    const navigate = useNavigate();
    const { setUserData, reset } = useStore();
    const { routeState, setJwt, companyName } = useOnboarding();

    const onSuccessSubmit = useCallback(async () => {
        if (routeState && routeState.userData) {
            setJwt(routeState.userData.signInUserSession.idToken.jwtToken);
            await GetCurrentUserAndUpdateStore({
                jwt: routeState.userData.signInUserSession.idToken.jwtToken,
                setUserData,
            });
            reset();
            navigate(dashboardUrl);
        } else {
            reset();
            navigate(loginUrl);
        }
    }, [routeState]);

    return (
        <div className="auth-flow-container--inner">
            <div className="signup-congrat text-center ">
                {isMarketplaceEnv() ? <IconAnimated
                    animateJson={congrat}
                    loop={true}
                    autoplay={true}
                    size={40}
                    isPaused={false}
                /> :
                <StepFiveIcon userType={UserType.BORROWER}/>}
                <h3>Congratulations!</h3>
                <p className="little text-grey">You’ve created a {companyName} profile!</p>
                <div className="step-form-footer">
                    <div className="button-group text-center">
                        <RegularButton id="congratulations-button" onSubmit={onSuccessSubmit}>
                            Let's start!
                        </RegularButton>
                    </div>
                </div>
            </div>
        </div>
    );
});
