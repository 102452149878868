export function hexToRgb(hex: string, opacity?: number) {
    // Remove hash symbol if present
    hex = hex.replace(/^#/, '');

    // Parse the hex value to integers
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `${r}, ${g}, ${b}`;
}