import { UserType } from "../../../../types/sparrow-types";

export const LoanTypeIcon = ({userType, isActive}:{userType: UserType, isActive: boolean}) => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.21675 1C3.78989 1 0.998535 3.79136 0.998535 7.21822C0.998535 10.6451 3.78989 13.4364 7.21675 13.4364C8.70695 13.4364 10.0755 12.9073 11.1481 12.0288L13.9281 14.8088C13.9854 14.8685 14.054 14.9161 14.1299 14.949C14.2059 14.9818 14.2876 14.9991 14.3703 15C14.453 15.0008 14.5351 14.9851 14.6117 14.9539C14.6883 14.9226 14.7579 14.8764 14.8164 14.8179C14.8749 14.7594 14.9211 14.6898 14.9524 14.6132C14.9837 14.5366 14.9993 14.4545 14.9985 14.3718C14.9977 14.2891 14.9803 14.2073 14.9475 14.1314C14.9147 14.0554 14.867 13.9868 14.8073 13.9295L12.0274 11.1495C12.9058 10.077 13.435 8.70842 13.435 7.21822C13.435 3.79136 10.6436 1 7.21675 1ZM7.21675 2.24364C9.9715 2.24364 12.1913 4.46347 12.1913 7.21822C12.1913 9.97296 9.9715 12.1928 7.21675 12.1928C4.46201 12.1928 2.24218 9.97296 2.24218 7.21822C2.24218 4.46347 4.46201 2.24364 7.21675 2.24364Z"
                fill={isActive ? `rgba(var(--${userType}PrimaryColor), 1)` : "#525966"}
            />
        </svg>
    );
};
