import { useState } from "react";
import "../../../style.scss";
import { UserType } from "../../../../../constants/defaultInputs";
import { useOnboarding } from "../../../../../core/providers/onboarding.provider";
import { Button, ButtonTypes } from "../../../../buttons/button/Button";
import { isMarketplaceEnv } from "../../../../../utils/config";

interface ApplyProps {
    handleApply: Function;
    isDefault: boolean;
    isActive: boolean;
    applyWithoutCosigner?: boolean;
}

export const OfferCardHeaderApplyButton: React.FunctionComponent<ApplyProps> = ({
    handleApply,
    isDefault,
    isActive,
    applyWithoutCosigner,
}) => {
    const onboarding = useOnboarding();

    const isBorrowerInitiated = onboarding.formData.userType === UserType.BORROWER;

    const [isHover, setIsHover] = useState<boolean>(false);

    function handleClick() {
        handleApply();
        onboarding.requestHasExploredRate = true;
    }
    return (
        <>
            {isMarketplaceEnv() ? (
                // TODO: this is the marketplace button. Does it have any specific features that require this complexity? To discuss with LZ.
                <div
                    className={
                        "apply-button" +
                        (isDefault ? "" : " apply-direct") +
                        (isActive ? " active" : "") +
                        (isBorrowerInitiated ? "" : " cosigner")
                    }
                    onClick={() => handleClick()}
                    onMouseEnter={() => setIsHover(true)}
                    onMouseLeave={() => setIsHover(false)}
                    style={
                        applyWithoutCosigner ? { background: isHover ? "#EC7D2A" : "#F19048" } : {}
                    }
                >
                    Apply
                    <div
                        className={
                            "icon-arrow-right smooth-entry-apply-arrow" + (isHover ? "-hover" : "")
                        }
                    ></div>
                </div>
            ) : (
                <Button
                    buttonType={ButtonTypes.PRIMARY}
                    onClick={() => handleClick()}
                    userType={onboarding.formData.userType}
                    showIcon={true}
                >
                    Apply
                </Button>
            )}
        </>
    );
};
