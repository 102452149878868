import { formatMoneyAmount } from "../money";
import {
    calculateAgeFromTime,
    currentTimeInSeconds,
    getMonthsUntilIncomeBasedOfferDate,
    getNextGraphTimeFromCurrentTime,
    getTimeFromMMMspaceDDcommaSpaceYYYY,
} from "./utils/time";
import {
    IncomeBasedOfferGraphDataInput,
    IncomeBasedStudentLoanOfferGraphDataPoint,
    IncomeBasedStudentLoanOfferGraphOutput,
} from "./utils/types";

function calculateMonthlyPaymentFromIncomeAndSharePercentage(
    income: number,
    incomeSharePercentage: number
): number {
    // Income is in cents
    // Income share is a percentage (not a decimal)
    // So we divide by 12 to get monthly payment
    // And by 100 to get to cents
    // And then by 100 again to get to dollars (since its ceiling in dollars)
    // We then multiply back by 100 to get to cents again
    return Math.ceil((income * incomeSharePercentage) / 120000) * 100;
}

function calculateMonthlyPayment(params: {
    offer: IncomeBasedOfferGraphDataInput;
    monthsLeftUntilDefermentEnd: number;
    monthsLeftUntilMonthlyPayment: number;
    income: number;
}) {
    const { offer, monthsLeftUntilDefermentEnd, monthsLeftUntilMonthlyPayment, income } = params;

    if (income < offer.minimumIncomeThreshold.amount || monthsLeftUntilDefermentEnd > 0) {
        return formatMoneyAmount(0);
    } else if (monthsLeftUntilMonthlyPayment > 0) {
        return offer.fixedMonthlyPayment45k;
    } else {
        return formatMoneyAmount(
            calculateMonthlyPaymentFromIncomeAndSharePercentage(income, offer.incomeSharePercentage)
        );
    }
}

function generateOfferDataPoint(params: {
    offer: IncomeBasedOfferGraphDataInput;
    dob: number;
    income: number;
}) {
    const { offer, dob, income } = params;
    const dataPoints: IncomeBasedStudentLoanOfferGraphDataPoint[] = [];
    const currentTimeToday = currentTimeInSeconds();

    const defermentEndDate = getTimeFromMMMspaceDDcommaSpaceYYYY(offer.defermentEndDate as string);

    // First date of monthly payment instead of fixed payment
    const monthlyPaymentStartDate = getTimeFromMMMspaceDDcommaSpaceYYYY(
        offer.monthlyPaymentStartDate as string
    );

    const initialMonthsLeftUntilDefermentEnd = getMonthsUntilIncomeBasedOfferDate(
        defermentEndDate,
        currentTimeToday
    );

    let currentTime = currentTimeToday;
    let totalAmountOfPayments = 0;
    let totalMonthsInDebt = 0;
    let totalToPay = 0;

    // Months until we start repayment
    let monthsLeftUntilDefermentEnd = initialMonthsLeftUntilDefermentEnd;

    // This variable is how we determine when to stop
    let paymentToLoanIsFinished = false;
    while (!paymentToLoanIsFinished) {
        const monthsLeftUntilMonthlyPayment = getMonthsUntilIncomeBasedOfferDate(
            monthlyPaymentStartDate,
            currentTime
        );

        const monthlyPayment = calculateMonthlyPayment({
            offer: offer,
            monthsLeftUntilDefermentEnd: monthsLeftUntilDefermentEnd,
            monthsLeftUntilMonthlyPayment: monthsLeftUntilMonthlyPayment,
            income: income,
        });

        if (monthlyPayment.amount > 0) {
            totalToPay += monthlyPayment.amount;
            totalAmountOfPayments += 1;
        }

        // Reached end because got to end of term
        // totalMonthsInDebt + 1 because index by 0, and are counting until we have gone through the loop
        // a number of times equal to the term + how many months we were deferred
        if (totalMonthsInDebt + 1 === offer.term * 12 + initialMonthsLeftUntilDefermentEnd) {
            paymentToLoanIsFinished = true;
        }

        // Reached end because paid more or equal to the maximum amount allowed to pay
        // In this case, set the amount to pay to the maximum allowed to pay
        // And set finished to true
        if (totalToPay >= offer.maximumPaymentAmount.amount) {
            totalToPay = offer.maximumPaymentAmount.amount;
            paymentToLoanIsFinished = true;
        }

        // Reached end because made sixty payments
        // In this case, set finished to true
        if (totalAmountOfPayments === 60) {
            paymentToLoanIsFinished = true;
        }

        // Add case where irr crosses 23 and set true

        currentTime = getNextGraphTimeFromCurrentTime(currentTime);
        totalMonthsInDebt += 1;
        monthsLeftUntilDefermentEnd -= 1;
    }

    currentTime = currentTimeToday;
    monthsLeftUntilDefermentEnd = initialMonthsLeftUntilDefermentEnd;

    let totalPaidSoFar = 0;
    let totalLeftToPay = totalToPay;
    for (let j = 0; j < totalMonthsInDebt; j++) {
        const monthsLeftUntilMonthlyPayment = getMonthsUntilIncomeBasedOfferDate(
            monthlyPaymentStartDate,
            currentTime
        );

        const monthlyPayment = calculateMonthlyPayment({
            offer: offer,
            monthsLeftUntilDefermentEnd: monthsLeftUntilDefermentEnd,
            monthsLeftUntilMonthlyPayment: monthsLeftUntilMonthlyPayment,
            income: income,
        });

        // Handle last payment if amount left is less than the monthly payment
        const monthlyPaymentWithLastPaymentCheck = Math.min(monthlyPayment.amount, totalLeftToPay);

        totalPaidSoFar += monthlyPaymentWithLastPaymentCheck;
        totalLeftToPay -= monthlyPaymentWithLastPaymentCheck;
        monthsLeftUntilDefermentEnd -= 1;

        dataPoints.push({
            date: currentTime,
            age: calculateAgeFromTime(dob, currentTime),
            totalTimeRemaining: (totalMonthsInDebt - (j + 1)) * 30,
            monthlyPayment: formatMoneyAmount(monthlyPaymentWithLastPaymentCheck),
            totalAmountRemaining: formatMoneyAmount(totalLeftToPay),
            totalPaid: formatMoneyAmount(totalPaidSoFar),
        });

        currentTime = getNextGraphTimeFromCurrentTime(currentTime);
    }

    return {
        totalCost: formatMoneyAmount(totalToPay),
        lender: offer.lender,
        offerId: offer.id,
        term: offer.term,
        totalTimeInDebt: totalMonthsInDebt,
        dataPoints: dataPoints,
    };
}

export function generateIncomeBasedOfferGraphData(params: {
    offers?: IncomeBasedOfferGraphDataInput[];
    dob: number;
    income?: number;
}) {
    const { offers, dob, income: possibleIncome } = params;
    const income = possibleIncome ?? 45000_00;

    if (!offers?.length) {
        return undefined;
    }

    const output: IncomeBasedStudentLoanOfferGraphOutput[] = [];
    for (const offer of offers) {
        output.push(
            generateOfferDataPoint({
                offer: offer,
                dob: dob,
                income: income,
            })
        );
    }

    return output;
}
