import { useLocation } from "react-router-dom";
import ToastPopup from "../../../../sparrow-ui-library/src/ui-components/toast-popup";
import { useSparrowUIContext } from "../../../../sparrow-ui-library/src/context";
import { SparrowIcon } from "../../../../sparrow-ui-library/src/assets/icons/sparrow-icon/SparrowIcon";
import { UserType } from "../../../../sparrow-ui-library/src/types/sparrow-types";
import { useGetTips } from "../../../../hooks/useGetTips";
import MobileNavigationHeader from "../../MobileNavigationHeader";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import { getPaaasClass } from "../../../utils/global";
import { isMarketplaceEnv } from "../../../utils/config";
import { ContentLayoutProps } from "./types/content-layout-props";
import { PAAASHeaderBar } from "../../PAAASHeaderBar";
import {RightMainContent} from "./components/right-main-content";
import {PAAASFooter} from "./components/paaas-footer";
import {LeftSideMobileTop} from "./components/left-side-mobile-top";
import {LeftSideContent} from "./components/left-side-content";
import styles from "./styles.module.scss";

const ContentLayout: React.FC<ContentLayoutProps> = ({ children, pageText, progressBarData }) => {
    const { formData } = useOnboarding();
    const pathName = useLocation().pathname;
    const { showToastPopup, showMobileTips } = useSparrowUIContext();

    const tips = useGetTips(pathName) ?? [];

    return (
        <>
            <div className={styles.container}>
                {!isMarketplaceEnv() && <PAAASHeaderBar />}
                <MobileNavigationHeader />
                {/* Left side content - Mobile view - positioned on top of main content and spans width of the page */}
                <aside className={`${styles.leftSideContainerMobile} ${showMobileTips ? `${styles.open}` : ""} ${isMarketplaceEnv() ? `${styles.marketplace}` : ""}`}>
                    {/* Hidden in tablet/mobile views - require clicking to open in header */}
                    <section
                        className={`${styles.leftSideInner} ${styles[getPaaasClass()]} ${styles[formData.userType ?? UserType.BORROWER]}`}
                    >
                        <LeftSideMobileTop />
                        <LeftSideContent {...{tips, pageText}} />
                    </section>
                    {/* Blurred screen appears for tablet/mobile views */}
                    <div className={styles.blurredScreen} />
                </aside>

                <div className={styles.primaryContentContainer}>
                    {/* Left side content - exists next to main content */}
                    <aside className={`${styles.leftSideContainer} ${showMobileTips ? `${styles.open}` : ""}`}>
                        {/* Hidden in tablet/mobile views - require clicking to open in header */}
                        <section
                            className={`${styles.leftSideInner} ${styles[getPaaasClass()]} ${styles[formData.userType ?? UserType.BORROWER]}`}
                        >
                            {isMarketplaceEnv() && <><div style={{ marginBottom: "48px" }}>
                                <SparrowIcon color="#fff" />
                            </div></>}
                            <LeftSideMobileTop />
                            <LeftSideContent {...{tips, pageText}} />
                        </section>
                    </aside>
                    {/* Right side content */}
                    <RightMainContent pageText={pageText} progressBarData={progressBarData}>
                        {children}
                        {showToastPopup && <ToastPopup />}
                    </RightMainContent>
                </div>
                {!isMarketplaceEnv() && <PAAASFooter />}
            </div>
        </>
    );
};

export default ContentLayout;
