import { useOnboarding } from "../../../core/providers/onboarding.provider";
import { MenuItems } from "../../../types/paaas/custom-menu-props";

import "./styles.scss";

interface IMenuPopup {
    menuExpandDropdown?: MenuItems;
}

// TODO: Update to utilize Alex's new modal component
export const MenuPopup = ({ menuExpandDropdown }: IMenuPopup) => {
    const {companyName} = useOnboarding();

    return (
        <div className="request-popup-container">
            <div className="request-popup-title-container">
                <div className="request-popup-title">{menuExpandDropdown?.name}</div>
                <div className="request-popup-subtitle">{`Explore more from ${companyName}`}</div>
            </div>
            {menuExpandDropdown?.children?.map((child) => {
                return (
                    <a
                        className={"request-popup-item"}
                        target="_blank"
                        href={child.url}
                        rel="noreferrer"
                    >
                        {child.name}
                    </a>
                );
            })}
        </div>
    );
};
