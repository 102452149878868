//borrower versions
import mail from '../mail-(mail).json';
import search from '../search-(search).json';
import handshake from '../handshake-(handshake).json';
import creditCard from '../credit-card-(payment).json';
import partyCone from '../party-cone-(congrats).json';
//cosigner versions
import mailCosigner from '../mail-cosigner-(mail-parent).json';
import searchCosigner from '../search-cosigner-(search-parent).json';
import handshakeCosigner from '../handshake-cosigner-(handshake-parent).json';
import creditCardCosigner from '../credit-card-cosigner-(payment-parent).json';
import partyConeCosigner from '../party-cone-cosigner-(congrats-parent).json';
import { ProgressStatuses } from '../../../../common/models/request';
import { UserType } from '../../../../common/constants/defaultInputs';
import { StepOneIcon, StepTwoIcon, StepThreeIcon, StepFourIcon, StepFiveIcon} from '../../../../sparrow-ui-library/src/assets/icons/dashboard-roadmap-icons';

export const getRoadmapStepLottie = (stepContent: ProgressStatuses, userType: UserType) => {
    const isBorrower = userType === UserType.BORROWER;
    
    switch (stepContent) {
        case ProgressStatuses.Prequalify:
            return isBorrower ? mail : mailCosigner;
        case ProgressStatuses.CompareAndSelect:
            return isBorrower ? search : searchCosigner;
        case ProgressStatuses.ApplyWithLender:
            return isBorrower ? handshake : handshakeCosigner;
        case ProgressStatuses.FinalizeLoan:
            return isBorrower ? creditCard : creditCardCosigner;
        case ProgressStatuses.ReceiveFunds:
            return isBorrower ? partyCone : partyConeCosigner;
        default:
            return isBorrower ? mail : mailCosigner;
    }
};

export const getRoadmapStepIcon = (stepContent: ProgressStatuses, userType: UserType) => {
    switch (stepContent) {
        case ProgressStatuses.Prequalify:
            return  <StepOneIcon userType={userType}/>;
        case ProgressStatuses.CompareAndSelect:
            return <StepTwoIcon userType={userType}/>;
        case ProgressStatuses.ApplyWithLender:
            return <StepThreeIcon userType={userType}/>;
        case ProgressStatuses.FinalizeLoan:
            return <StepFourIcon userType={userType}/>;
        case ProgressStatuses.ReceiveFunds:
            return <StepFiveIcon userType={userType}/>;
        default:
            return null;
    }
}
