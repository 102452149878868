import {
    nameFormat,
    emailFormat,
    ssnFormat,
    standardDateFormat,
    gradDateFormat,
    zipCodeFormat,
    gpaFormat,
    hoursCompletedFormat
} from "./special-formatting-functions";
import { amountFormat } from "../../general-utils";
import { FormFieldName } from "../../../types/form-types";

export const specialFormattingFunctionObject: { [key: string]: (value: string) => any } = {
    [FormFieldName.EmploymentIncome]: amountFormat,
    [FormFieldName.LoanAmount]: amountFormat,
    [FormFieldName.HousingPaymentAmount]: amountFormat,
    [FormFieldName.IncomeAmount]: amountFormat,
    [FormFieldName.GradDate]: gradDateFormat,
    [FormFieldName.DegreesGradDate]: gradDateFormat,
    [FormFieldName.DOB]: (value) => standardDateFormat(value, true),
    [FormFieldName.Email]: emailFormat,
    [FormFieldName.FirstName]: nameFormat,
    [FormFieldName.LastName]: nameFormat,
    [FormFieldName.MiddleInitial]: nameFormat,
    [FormFieldName.SSN]: ssnFormat,
    [FormFieldName.Zip]: zipCodeFormat,
    [FormFieldName.ExpandedSearchGpaCumulative]: gpaFormat, 
    [FormFieldName.ExpandedSearchGpaLastSemester]: gpaFormat,
    [FormFieldName.ExpandedSearchSchoolYearStartDate]: standardDateFormat,
    [FormFieldName.ExpandedSearchSchoolYearEndDate]: standardDateFormat,
    [FormFieldName.ExpandedSearchHoursCompletedCurrentSemester]: hoursCompletedFormat,
};

export const getSpecialFormattingFunction = (fieldName: string) => {
    return specialFormattingFunctionObject[fieldName];
};
