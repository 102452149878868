import {FC} from "react";
import { useOnboarding } from "../../core/providers/onboarding.provider";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { observer } from "mobx-react";

export const PAAASLogo: FC<{navigateTo?: string, logoClassName?: string}> 
= observer(({navigateTo, logoClassName}) => {
    const navigate = useNavigate();
    const { paaasCustomHeader } = useOnboarding();
    const logo = <img src={paaasCustomHeader?.logo?.url ?? ""} alt="logo" />

    return (
        <>
            {/* If navigateTo is defined, then the logo is clicable and will navigate to correct url  */}
            {!!navigateTo ? 
            <button className="logo" onClick={() => navigate(navigateTo)}>{logo}</button> :
            <div className={`logo ${logoClassName}`}>{logo}</div>
            }
        </>
    );
});
