import { Route, Routes, useLocation } from "react-router-dom";
import {
    userInclusionInformationRoutingUrl,
    userInclusionManagementRoutingUrl,
    userInclusionReviewInviteRoutingUrl,
    userInclusionSelectRoutingUrl,
} from "../../common/core/routes/routing-urls";
import { UserInclusionInformation } from "./information/page";
import { UserInclusionManagement } from "./management/page";
import { UserInclusionReviewInvite } from "./review-invite/page";
import { UserInclusionSelectUser } from "./select/page";
import ContentLayout from "../../common/components/layouts/content-layout";
import { useGetPageText } from "./utils/useGetPageText";

export const UserInclusionRoutes = () => {
    const pathName = useLocation().pathname;
    const pageText = useGetPageText(pathName);

    return (
        <ContentLayout
            pageText={{
                title: pageText.title,
                subtitle: pageText.subtitle,
            }}
        >
            <Routes>
                <Route
                    path={userInclusionInformationRoutingUrl}
                    element={<UserInclusionInformation />}
                />
                <Route
                    path={userInclusionManagementRoutingUrl}
                    element={<UserInclusionManagement />}
                />
                <Route
                    path={userInclusionReviewInviteRoutingUrl}
                    element={<UserInclusionReviewInvite />}
                />
                <Route path={userInclusionSelectRoutingUrl} element={<UserInclusionSelectUser />} />
            </Routes>
        </ContentLayout>
    );
};
