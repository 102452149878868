"use client";
import React, { useState } from "react";
import "./style.scss";
import XIcon from "../../../assets/images/icons/X.svg";
import XIconHover from "../../../assets/images/icons/XIconHover.svg";

import { DropdownTooltip } from "./DropdownTooltip";
import { useOnboarding } from "../../core/providers/onboarding.provider";

interface SingleSelectDropdownProps {
    title: string;
    onExit: Function;
    checkboxMapping: {};
    setCheckboxMapping: Function;
    onChangeExtraProcessing?: Function;
    headerTooltipContent?: {
        title: string;
        content: string;
    };
    optionTooltipContent?: {
        [key: string]: {
            title: string;
            content: string;
        };
    };
    isMobile?: boolean;
    icon?: string | null;
}

export const SingleSelectDropdown: React.FunctionComponent<SingleSelectDropdownProps> = ({
    title,
    onExit,
    checkboxMapping,
    setCheckboxMapping,
    onChangeExtraProcessing,
    headerTooltipContent,
    optionTooltipContent,
    isMobile = false,
    icon = null,
}) => {
    const [exitHover, setExitHover] = useState<boolean>(false);

    function handleOnChange(checkOption: string) {
        const initialCheckboxMapping = { ...checkboxMapping };
        for (const option of Object.keys(checkboxMapping)) {
            initialCheckboxMapping[option] = false;
        }

        if (!checkboxMapping[checkOption]) {
            initialCheckboxMapping[checkOption] = true;
            if (onChangeExtraProcessing) {
                onChangeExtraProcessing([checkOption]);
            }
        } else {
            if (onChangeExtraProcessing) {
                onChangeExtraProcessing([]);
            }
        }

        setCheckboxMapping(initialCheckboxMapping);
    }

    function handleClear() {
        const initialCheckboxMapping = { ...checkboxMapping };
        for (const option of Object.keys(checkboxMapping)) {
            initialCheckboxMapping[option] = false;
        }

        if (onChangeExtraProcessing) {
            onChangeExtraProcessing([]);
        }

        setCheckboxMapping(initialCheckboxMapping);
    }

    function hasActiveFilter() {
        for (const option of Object.keys(checkboxMapping)) {
            if (checkboxMapping[option]) return true;
        }
        return false;
    }

    const{formData} = useOnboarding()

    return (
        <>
            <div className="offer-filter-dropdown-select-container">
                <div className="offer-filter-dropdown-select-title-container">
                    <div className="offer-filter-dropdown-select-title">
                        {isMobile && icon && (
                            <div className={"filter-img"}>
                                <img src={icon} alt={title} />
                            </div>
                        )}
                        {title}
                        {headerTooltipContent && (
                            <DropdownTooltip
                                title={headerTooltipContent?.title}
                                content={headerTooltipContent?.content}
                            />
                        )}
                        {isMobile && (
                            <div
                                className={
                                    "offer-filter-dropdown-select-footer-text" +
                                    (hasActiveFilter() ? " active" : "")
                                }
                                onClick={() => handleClear()}
                            >
                                Clear
                            </div>
                        )}
                    </div>
                    {!isMobile && (
                        <img
                            onMouseEnter={() => setExitHover(true)}
                            onMouseLeave={() => setExitHover(false)}
                            style={{ cursor: "pointer" }}
                            src={exitHover ? XIconHover : XIcon}
                            alt="exit"
                            onClick={() => onExit()}
                        />
                    )}
                </div>
                {Object.keys(checkboxMapping).map((option, index) => (
                    <div
                        className="dropdown-checkbox"
                        key={option}
                        onClick={() => handleOnChange(option)}
                    >
                        <div
                            className={
                                "legend_select_icon " + (checkboxMapping[option] ? "selected" : "")
                            }
                            style={{
                                borderColor: checkboxMapping[option] ? `var(--${formData.userType}PrimaryColor)` : "#abb6bd",
                                opacity: checkboxMapping[option] ? 1 : 0.4,
                            }}
                        />

                        <p
                            className={
                                "checkbox-label-scrollable " +
                                (checkboxMapping[option] ? `selected ${formData.userType}` : "")
                            }
                        >
                            {option}
                        </p>
                        {optionTooltipContent && (
                            <DropdownTooltip
                                title={optionTooltipContent[option].title}
                                content={optionTooltipContent[option].content}
                            />
                        )}
                    </div>
                ))}
                {!isMobile && (
                    <div className="offer-filter-dropdown-select-footer-container">
                        <div
                            className={
                                "offer-filter-dropdown-select-footer-text" +
                                (hasActiveFilter() ? " active" : "")
                            }
                            onClick={() => handleClear()}
                        >
                            Clear
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
