import { FC, PropsWithChildren, useEffect } from "react";
import Cookies from "js-cookie";

const CookiesInitializationProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
    useEffect(() => {
        // fetch all the cookies that are currently present in the browser's storage and store them in the cookies variable
        const cookies = Cookies.get();

        for (const cookieName in cookies) {
            if (cookieName.includes("CognitoIdentityServiceProvider")) {
                continue;
            }
            // Set the value of the current cookie in the local storage
            localStorage.setItem(cookieName, cookies[cookieName]);
        }
    }, []);

    return <>{children}</>;
};

export default CookiesInitializationProvider;
