import successIcon from "../../../../assets/images/icons/icon_approve.svg";
import {dashboardUrl} from "../../../../common/core/routes/navigation-urls";
import deleteIcon from "../../../../assets/images/icons/icon_delete.svg";
import {InputText} from "../../../../common/components/inputs/input_text/input_text";
import {AppModal} from "../../../../common/components/modals/AppModal";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider";

interface IProfileDeleteModal {
    modalToggle: (show: boolean) => void;
    form: {
        email: string;
        firstName: string;
        lastName: string;
        currentPassword: string;
        newPassword: string;
        confirmPassword: string;
        phone_number: string;
    };
    onSubmit: () => void;
    isSuccessDelete: boolean;
}
const ProfileDeleteModal = ({modalToggle, form, onSubmit, isSuccessDelete}: IProfileDeleteModal) => {
    const {companyName} = useOnboarding();

    const [deleteConfirmText, setDeleteConfirmText] = useState('');
    const navigate = useNavigate();
    return <AppModal onClose={() => modalToggle(false)} size={'md'} showCloseIcon={true} customClass={'profile-modal'} enableMobileView={true}>
        <div className="profile-modal-content">
            {isSuccessDelete ? <>
                <div className="profile-modal-content__head">
                    <img src={successIcon} alt=""/>
                    <h3 className="h3">Account deleted</h3>
                    <p className="muted">Your {companyName} Borrower account registered to <span>{form.email}</span> has been deleted.</p>
                    <p className={'muted'}>We’re sorry to see you go and are always here to support your student loan journey should you decide to come back</p>
                </div>
                <div className="button-group">
                    <button className={'btn btn-green'} onClick={() => {
                        navigate(dashboardUrl);
                        modalToggle(false);
                    }}>Return to home</button>
                </div>
            </>:<>
                <div className="profile-modal-content__head">
                    <img src={deleteIcon} alt=""/>
                    <h3 className="h3">Are you sure you want to delete?</h3>
                    <p className="muted">Deleting your account will erase all of your existing requests, pre-qualified
                        rates, and any lender reported data linked to your {companyName} Borrower account. This cannot be
                        undone.</p>
                </div>
                <div className="form-row">
                    <InputText
                        placeholder={'delete'}
                        type={'text'}
                        name={'delete-input'}
                        label={'To proceed, please type "delete" below'}
                        value={deleteConfirmText}
                        onChange={(e) => setDeleteConfirmText(e.target.value)}
                        onClear={() => setDeleteConfirmText('')}
                    />
                </div>
                <div className="button-group">
                    <button className={`btn btn-${deleteConfirmText === 'delete' ? 'red' : 'bordered'} ${deleteConfirmText !== 'delete' ? 'disable' : ''}`} onClick={onSubmit}>Delete</button>
                    <button className={'btn btn-black'} onClick={() => modalToggle(false)}>Cancel</button>
                </div>
            </>}
        </div>
    </AppModal>
}
export default ProfileDeleteModal