import { ExpandedSearchDates } from './../../../sparrow-ui-library/src/types/sparrow-types/expanded-search/index';
import {
    FormFieldType,
    FormFieldName,
    FormInputsConfig,
    FormsConfig
} from "../../../sparrow-ui-library/src/types/form-types";
import { getExpandedSearchFieldLabels } from './hooks/get-es-field-labels';
import { PrequalificationRequestInput } from '../../../sparrow-ui-library/src/types/sparrow-types';
import { utcToFormattedDateString } from '../../../common/utils/helper_functions/date-functions';

export const getFormsConfig = (inputs: FormsConfig): FormInputsConfig => {
    const { formData, expandedSearchDates} = inputs as { formData: PrequalificationRequestInput, expandedSearchDates: ExpandedSearchDates };
    // Field labels for expanded search contain variable dates
    const esFieldLabels= getExpandedSearchFieldLabels(expandedSearchDates);

    return {
        forms: [
            {
                independentFields: [
                    {
                        formFieldType: FormFieldType.TextBox,
                        id: FormFieldName.ExpandedSearchGpaLastSemester,
                        name: FormFieldName.ExpandedSearchGpaLastSemester,
                        label: esFieldLabels.gpaLastSemester,
                        placeholder: "-.--",
                        inputValue: formData.gpaLastSemester ?? "",
                        backendValue: "",
                        errorMessage: "",
                        type: "text",
                    },
                    {
                        formFieldType: FormFieldType.TextBox,
                        id: FormFieldName.ExpandedSearchGpaCumulative,
                        name: FormFieldName.ExpandedSearchGpaCumulative,
                        label: "Cumulative GPA",
                        placeholder: "-.--",
                        inputValue: formData.gpaCumulative ?? "",
                        backendValue: "",
                        errorMessage: "",
                        type: "text",
                    },
                    {
                        formFieldType: FormFieldType.TextBox,
                        id: FormFieldName.ExpandedSearchSchoolYearStartDate,
                        name: FormFieldName.ExpandedSearchSchoolYearStartDate,
                        label: esFieldLabels.schoolYearStartDate,
                        placeholder: "MM/DD/YYYY",
                        inputValue: formData.schoolYearStartDate ? utcToFormattedDateString(formData.schoolYearStartDate) : "",
                        backendValue: "",
                        errorMessage: "",
                        type: "text",
                    },
                    {
                        formFieldType: FormFieldType.TextBox,
                        id: FormFieldName.ExpandedSearchSchoolYearEndDate,
                        name: FormFieldName.ExpandedSearchSchoolYearEndDate,
                        label: esFieldLabels.schoolYearEndDate,
                        placeholder: "MM/DD/YYYY",
                        inputValue: formData.schoolYearEndDate ? utcToFormattedDateString(formData.schoolYearEndDate) : "",
                        backendValue: "",
                        errorMessage: "",
                        type: "text",
                    },
                    {
                        formFieldType: FormFieldType.TextBox,
                        id: FormFieldName.ExpandedSearchHoursCompletedCurrentSemester,
                        name: FormFieldName.ExpandedSearchHoursCompletedCurrentSemester,
                        label: esFieldLabels.hoursCompletedCurrentSemester,
                        placeholder: "Hours completed",
                        inputValue: formData.hoursCompletedCurrentSemester?.toString() ?? "",
                        backendValue: "",
                        errorMessage: "",
                        type: "text",
                    },
                ],
            },
        ],
    };
};
