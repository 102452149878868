import { UserType } from "../../../../types/sparrow-types";
import { getSvgColors } from "../../getSvgColors";

export const WalletIcon = ({
    userType,
    isPAAAS = false,
}: {
    userType: UserType;
    isPAAAS?: boolean;
}) => {
    const { primaryColor, accentColor } = getSvgColors(userType);

    const PAAASVersion = (
        <>
            <rect
                x="10"
                y="25"
                width="22"
                height="17"
                rx="2"
                transform="rotate(-90 10 25)"
                fill={accentColor}
            />
            <rect x="6" y="15" width="29" height="22" rx="4" fill={primaryColor} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 23C10 24.1046 10.8954 25 12 25H25C26.1046 25 27 24.1046 27 23V15H10V23Z"
                fill={accentColor}
            />
            <path
                d="M29 26C29 24.8954 29.8954 24 31 24H35V28H31C29.8954 28 29 27.1046 29 26Z"
                fill={accentColor}
            />
        </>
    );

    const marketplaceBorrowerVersion = (
        <>
            <rect
                x="10"
                y="25"
                width="22"
                height="17"
                rx="2"
                transform="rotate(-90 10 25)"
                fill="#8CCE56"
            />
            <rect x="6" y="15" width="29" height="22" rx="4" fill="#185155" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 23C10 24.1046 10.8954 25 12 25H25C26.1046 25 27 24.1046 27 23V15H10V23Z"
                fill="#4EAC6A"
            />
            <path
                d="M29 26C29 24.8954 29.8954 24 31 24H35V28H31C29.8954 28 29 27.1046 29 26Z"
                fill="#4EAC6A"
            />
        </>
    );

    const marketplaceCosignerVersion = (
        <>
            <rect
                x="10"
                y="25"
                width="22"
                height="17"
                rx="2"
                transform="rotate(-90 10 25)"
                fill="#8CCE56"
            />
            <rect x="6" y="15" width="29" height="22" rx="4" fill="#185155" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 23C10 24.1046 10.8954 25 12 25H25C26.1046 25 27 24.1046 27 23V15H10V23Z"
                fill="#4EAC6A"
            />
            <path
                d="M29 26C29 24.8954 29.8954 24 31 24H35V28H31C29.8954 28 29 27.1046 29 26Z"
                fill="#4EAC6A"
            />
        </>
    );

    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <>
                {isPAAAS
                    ? PAAASVersion
                    : userType === UserType.BORROWER
                    ? marketplaceBorrowerVersion
                    : marketplaceCosignerVersion}
            </>
        </svg>
    );
};
