import { useNavigate } from "react-router-dom";
import { ProgressBar } from "../progress-bar";
import { ChevronLeftIcon } from "../../../../../../assets/icons";
import { dashboardUrl } from "../../../../../core/routes/navigation-urls";
import { useSparrowUIContext } from "../../../../../../sparrow-ui-library/src/context";
import styles from "../../styles.module.scss";
interface RightMainContentProps {
    pageText: {
        title: string;
        subtitle?: string;
    };
    progressBarData?: {
        displayProgressBar: boolean;
        activeProgressBar: string[];
        currentStep: number;
    }
}

export const RightMainContent: React.FC<RightMainContentProps> = ({ children, pageText, progressBarData }) => {
    const navigate = useNavigate();
    const {showReviewCards, reviewHeaderText} = useSparrowUIContext();
    const { displayProgressBar, activeProgressBar, currentStep } = progressBarData ?? {};
    const hasProgressBarData = displayProgressBar && activeProgressBar && (currentStep as number >= 0);

    const navigateToDashboard = () => {
        navigate(dashboardUrl);
    };

    return (
        <main className={styles.rightSideContainer}>
            <div className={styles.rightSideTop}>
                <button className={styles.dashboardButton} onClick={navigateToDashboard}>
                    <ChevronLeftIcon />
                    <span>Dashboard</span>
                </button>
                {/* Optional progress bar */}
                {hasProgressBarData && (
                    <ProgressBar
                        numSteps={activeProgressBar.length}
                        activeStep={currentStep as number}
                    />
                )}
            </div>
            <div className={styles.rightSideContent}>
                <section className={styles.rightSideHeader}>
                    <h2 className={styles.textTitle}>{showReviewCards ? reviewHeaderText.title : pageText.title}</h2>
                    <p className={styles.textSubtitle}>{showReviewCards ? reviewHeaderText.subtitle : pageText.subtitle}</p>
                </section>
                <section className={styles.rightSideBody}>{children}</section>
            </div>
        </main>
    )
}

