import { GetPublic } from "../../../../common/core/services/clientApi";
import { paaasAffiliateId } from "../../paaas-base-url";

export interface PAAASAffiliateIdResp {
    affiliateId: string;
    affiliateDomain: string;
}

export const getPAAASAffiliateId = async () => {
    // Domain Name to be sent to backend
    const domainName = window.location.hostname.toString().split(".")[0].includes("localhost")
        ? process.env.REACT_APP_FALLBACK_DOMAIN_NAME
        : window.location.hostname.toString();

    try {
        // Get affiliate id from backend
        const affiliateIdResp: PAAASAffiliateIdResp = await GetPublic(
            paaasAffiliateId,
            null,
            `domainName=${domainName}`
        );
        return affiliateIdResp;
    } catch (error) {
        console.log(error);
    }
};
