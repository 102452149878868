import { observer } from "mobx-react";
import { useOnboarding } from "../../../../../core/providers/onboarding.provider";
import HtmlRenderer from "../../../../html-renderer";
import { AdvertiserRider } from "../../../../Riders/riders/AdvertiserRider";
import { SoftCreditRider } from "../../../../Riders/riders/SoftCredit";
import { IntendedAudienceRider } from "../../../../Riders/riders/IntendedAudience";
import { useStore } from "../../../../../core/providers/global.provider";
import "./footer.styles.scss";
import { PoweredBySparrowIcon } from "../../../../../../sparrow-ui-library/src/assets/icons/powered-by-sparrow/PoweredBySparrowIcon";
import { useEffect } from "react";

type TextAlign = "left" | "center" | "right";

const getFlexAlignment = (textAlign: TextAlign) => {
    switch (textAlign) {
        case "left":
            return "flex-start";
        case "center":
            return "center";
        case "right":
            return "flex-end";
    }
};

 export const PAAASFooter = observer(() => {
    const { setModal } = useStore();
    const { paaasCustomFooter, companyName } = useOnboarding();
    const { alignment: textAlign, text } = paaasCustomFooter ?? {};

    const getButtonElement = (text: string, modal: JSX.Element) => {
        return <button onClick={() => setModal(() => modal)}>{text}</button>;
    };

    // Once the footer is rendered, we need to set the CSS variable for the height of the footer
    useEffect(() => {
        const paaasFooterHeight = document.querySelector(".paaas-app-footer");
        document.documentElement.style.setProperty("--paaas-footer-height", `${paaasFooterHeight?.clientHeight}px`);
    }, []);

    return (
        <footer className="paaas-app-footer">
            <div className={`footer-content ${textAlign}`}>
                <div className="paaas-footer-links-wrapper" style={{ justifyContent: getFlexAlignment(textAlign as TextAlign) }}>
                    <div>
                        <span style={{ textAlign }}>{companyName} Policies</span>
                        <span>
                            <HtmlRenderer htmlString={text as string} />
                        </span>
                    </div>
                </div>{" "}
            </div>
            <div className={`footer-content ${textAlign}`}>
                <div className="paaas-footer-links-wrapper" style={{ justifyContent: getFlexAlignment(textAlign as TextAlign) }}>
                    <div>
                        <span style={{ textAlign }}>Disclosures</span>
                        <span>
                            {getButtonElement("Advertiser Disclosure", <AdvertiserRider />)}{" "}
                            | {getButtonElement("Soft Credit Inquiry", <SoftCreditRider />)}{" "}
                            | {getButtonElement("Intended Audience", <IntendedAudienceRider />)}
                        </span>
                    </div>
                </div>
            </div>
            <span className="sparrow-footer-icon"><PoweredBySparrowIcon /></span>
        </footer>
    );
});
