
export const PlainChecks = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_7972_14737)">
        <path d="M1.78241 7.13409L6.36573 11.7174L13.8333 4.24982" stroke="rgba(var(--borrowerPrimaryColor), 1)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_7972_14737">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
