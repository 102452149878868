import { FC } from "react";
import { UserType } from "../../../types/sparrow-types";
import { getSvgColors } from "../getSvgColors";

export const TimerIcon: FC<{ userType?: UserType }> = ({ userType = UserType.BORROWER }) => {
    const { primaryColor, accentColor } = getSvgColors(userType);

    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_7972_13408)">
                <circle cx="18" cy="23" r="13" fill={primaryColor} />
                <circle cx="18" cy="23" r="9" stroke={accentColor} strokeWidth="2" />
                <path
                    d="M33.2664 30.4791C34.2583 30.965 35.4673 30.5575 35.8463 29.52C36.4709 27.8104 36.8478 26.0166 36.9625 24.1932C37.1192 21.703 36.7839 19.2063 35.9757 16.8457C35.1675 14.4851 33.9022 12.3068 32.2522 10.4352C31.044 9.06471 29.6468 7.87831 28.1055 6.91026C27.1701 6.32278 25.965 6.74166 25.4791 7.73359L18.8799 21.204C18.3939 22.1959 18.8041 23.3939 19.796 23.8799L33.2664 30.4791Z"
                    fill={accentColor}
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M29.6769 28.7205C30.5242 26.9943 31 25.0527 31 22.9999C31 17.873 28.0321 13.4392 23.7206 11.323L18.8799 21.2039C18.3939 22.1958 18.8041 23.3939 19.796 23.8798L29.6769 28.7205Z"
                    fill={accentColor}
                />
                <rect x="16" y="7" width="4" height="5" fill={primaryColor} />
                <rect
                    x="7.82129"
                    y="11.885"
                    width="4"
                    height="3"
                    rx="1"
                    transform="rotate(-35 7.82129 11.885)"
                    fill={primaryColor}
                />
                <rect x="13" y="6" width="10" height="2" rx="1" fill={primaryColor} />
            </g>
            <defs>
                <clipPath id="clip0_7972_13408">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
