import { useEffect } from "react";

export const setViewportHeight = () => {
  document.documentElement.style.setProperty("--viewport-height", `${window.innerHeight}px`);
};

const useInitViewportHeight = () => {
  
    useEffect(() => {
      // Define the handler function for resizing
      const handleResize = () => {
        setViewportHeight();
      };
  
      // Initial setup
      setViewportHeight();
  
      // Attach the resize event listener
      window.addEventListener("resize", handleResize);
  
      // Cleanup: Remove the event listener when the component unmounts
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  };

  export default useInitViewportHeight;
  