import { FC } from "react";
import { Button } from "../../../buttons/button/Button";
import { ButtonTypes } from "../../../buttons/button/Button";
import { useStore } from "../../../../core/providers/global.provider";
import { useOnboarding } from "../../../../core/providers/onboarding.provider";
import { UserType } from "../../../../constants/defaultInputs";

import { ModalWrapper } from "../../modal-wrapper";
import { PutRequestInviteRemind } from "../../../../core/services/sparrow-api-handlers/prequalification-api-handlers";
import { errorLogs } from "../../../../utils/global";
import { SentModal } from "./SentModal";
import { observer } from "mobx-react";
import { StepOneIcon } from "../../../../../sparrow-ui-library/src/assets/icons/dashboard-roadmap-icons";

interface IResendReminderModal {
    firstName: string;
    email: string;
    isReminder: boolean;
}

export const ResendReminderModal: FC<IResendReminderModal> = observer(
    ({ firstName, email, isReminder }) => {
        const store = useStore();
        const onboarding = useOnboarding();

        const text = isReminder
            ? {
                  title: "Send a reminder",
                  subTitle: "The reminder will be sent to",
                  buttonText: "Send a reminder",
              }
            : {
                  title: "Resend Invitation",
                  subTitle: "The invitation will be sent to",
                  buttonText: "Resend invitation",
              };

        const handleReminder = async () => {
            try {
                store.setLoading(true);
                await PutRequestInviteRemind(onboarding.formData.id as string, onboarding.jwt as string);
                store.setModal(() => (
                    <SentModal firstName={firstName} email={email} isReminder={isReminder} />
                ));
            } catch (err) {
                errorLogs("PutRequestInviteRemind", err);
                store.setSnackError("Something went wrong. Please try again later.", "error");
            }
            store.setLoading(false);
        };

        return (
            <ModalWrapper>
                <StepOneIcon userType={onboarding.formData.userType as UserType}/>
                
                <h3
                    style={{
                        fontSize: "24px",
                        lineHeight: "32px",
                        fontWeight: "600",
                        marginBottom: "12px",
                        overflowWrap: "anywhere",
                    }}
                >
                    {text.title}
                </h3>
                <p
                    style={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: "400",
                        marginBottom: "24px",
                        color: "#878F99",
                        overflowWrap: "anywhere",
                    }}
                >
                    {text.subTitle}
                </p>
                <p
                    style={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        fontWeight: "400",
                        marginBottom: "24px",
                        color: "#151B26",
                        overflowWrap: "anywhere",
                    }}
                >
                    {email}
                </p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                        id="continue-button"
                        tabIndex={0}
                        onClick={handleReminder}
                        buttonType={ButtonTypes.PRIMARY}
                        userType={onboarding.formData.userType}
                    >
                        {text.buttonText}
                    </Button>
                </div>
            </ModalWrapper>
        );
    }
);
