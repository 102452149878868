import { PaymentPlanFormattedString } from "../../../views/offers/components/table/utilities/TableUtilities";
import { formatMoneyAmount } from "../../money";
import { calculateMonthsLeftUntilGraduation } from "./time";

export function calculateTotalInterestExpense(params: {
    interestRate: number;
    lengthInMonths: number;
    principal: number;
    paymentPlan: PaymentPlanFormattedString;
    gradDate?: number;
    gracePeriod?: number;
}) {
    const { principal } = params;

    const totalAmountPaid = calculateTotalPaidForLoan(params);
    const { totalInterestAccruedBeforeImmediateRepayment } =
        calculateTotalInterestAccruedAndAmountPaidBeforeImmediateRepayment(params);

    const totalInterestPaid =
        totalAmountPaid.amount - totalInterestAccruedBeforeImmediateRepayment.amount - principal;

    return formatMoneyAmount(totalInterestPaid, true);
}

export function calculateTotalInterestAccruedAndAmountPaidBeforeImmediateRepayment(params: {
    interestRate: number;
    lengthInMonths: number;
    principal: number;
    paymentPlan: PaymentPlanFormattedString;
    gradDate?: number;
    gracePeriod?: number;
}) {
    const { interestRate, principal, paymentPlan, gradDate, gracePeriod } = params;

    if (paymentPlan === "Immediate") {
        return {
            totalInterestAccruedBeforeImmediateRepayment: formatMoneyAmount(0, false),
            totalAmountPaidBeforeImmediateRepayment: formatMoneyAmount(0, false),
        };
    }

    const principalAtStartOfLoan = principal / 100;

    const interestRateAsDecimal = interestRate / 100;
    const monthlyInterestRate = interestRateAsDecimal / 12;

    const monthsLeftUntilImmediateRepayment = calculateMonthsLeftUntilImmediateRepayment(
        gradDate!,
        gracePeriod!
    );

    const flatPaymentAmount = calculateMonthlyInSchool(params).amount / 100;

    let interestAccruedDuringSchool = 0;
    let currentPrincipalOnLoan = principalAtStartOfLoan;
    let totalAmountPaid = 0;

    for (let i = 0; i < monthsLeftUntilImmediateRepayment; i++) {
        const interestPayment = monthlyInterestRate * currentPrincipalOnLoan;

        const interestAccruedAfterPayment = interestPayment - flatPaymentAmount;

        // If you are accruing interest at a rate faster than 25 dollars per month
        // Then the entire 25 dollar payment is going towards interest
        // If you are accruing less than 25 a month, then only the interest is going towards interest
        // And the rest is going towards principal
        interestAccruedDuringSchool += Math.max(0, interestAccruedAfterPayment);

        totalAmountPaid += Math.min(flatPaymentAmount, currentPrincipalOnLoan + interestPayment);

        // If you accrue less than 25 in interest per month
        // Then you are paying off the principal
        // But if you accrue more than 25, then your principal is constant
        currentPrincipalOnLoan = Math.min(
            currentPrincipalOnLoan,
            interestAccruedAfterPayment + currentPrincipalOnLoan
        );

        // If you have principal less than 0, then you have paid off the loan
        if (currentPrincipalOnLoan < 0) {
            return {
                totalInterestAccruedBeforeImmediateRepayment: formatMoneyAmount(
                    interestAccruedDuringSchool,
                    false
                ),
                totalAmountPaidBeforeImmediateRepayment: formatMoneyAmount(totalAmountPaid, false),
            };
        }
    }

    return {
        totalInterestAccruedBeforeImmediateRepayment: formatMoneyAmount(
            interestAccruedDuringSchool,
            false
        ),
        totalAmountPaidBeforeImmediateRepayment: formatMoneyAmount(totalAmountPaid, false),
    };
}

export function calculateTotalPaidForLoan(params: {
    interestRate: number;
    lengthInMonths: number;
    principal: number;
    paymentPlan: PaymentPlanFormattedString;
    gradDate?: number;
    gracePeriod?: number;
}) {
    const { interestRate, lengthInMonths, principal, paymentPlan } = params;

    // If repayment type is immediate repayment
    // That is if you have to start repaying immediately after receiving the money
    if (paymentPlan === "Immediate") {
        const monthlyPayment = calculateMonthlyPaymentOfAmortizedStudentLoan(params);
        // We multiply the monthly payment by the length of the loan to get the total amount paid on the loan
        const totalAmountPaid = monthlyPayment * lengthInMonths;
        return formatMoneyAmount(totalAmountPaid, false);
    }

    const principalAtStartOfLoan = principal / 100;

    const interestRateAsDecimal = interestRate / 100;
    const monthlyInterestRate = interestRateAsDecimal / 12;

    const {
        totalAmountPaidBeforeImmediateRepayment,
        totalInterestAccruedBeforeImmediateRepayment,
    } = calculateTotalInterestAccruedAndAmountPaidBeforeImmediateRepayment(params);

    const principalAfterSchool =
        principalAtStartOfLoan + totalInterestAccruedBeforeImmediateRepayment.amount / 100;
    const monthlyPaymentAfterSchool = calculateLoanPaymentFromPrincipalAndInterest({
        lengthInMonths: lengthInMonths,
        principalInDollars: principalAfterSchool,
        monthlyInterestRate: monthlyInterestRate,
    });

    const totalAmountPaid =
        totalAmountPaidBeforeImmediateRepayment.amount / 100 +
        monthlyPaymentAfterSchool * lengthInMonths;
    return formatMoneyAmount(totalAmountPaid, false);
}

export function calculateMonthlyInSchool(params: {
    interestRate: number;
    lengthInMonths: number;
    principal: number;
    paymentPlan: PaymentPlanFormattedString;
}) {
    const { interestRate, lengthInMonths, principal, paymentPlan } = params;

    if (paymentPlan === "Immediate") {
        const monthlyPayment = calculateMonthlyPaymentOfAmortizedStudentLoan({
            lengthInMonths: lengthInMonths,
            interestRate: interestRate,
            principal: principal,
        });

        return formatMoneyAmount(monthlyPayment, false);
    } else if (paymentPlan === "Interest only") {
        return formatMoneyAmount(((principal / 100) * interestRate) / 1200, false);
    } else if (paymentPlan === "Fixed (partial interest)") {
        return formatMoneyAmount(25, false);
    } else {
        return formatMoneyAmount(0, false);
    }
}

export function calculateMonthlyPaymentAfterGrad(params: {
    interestRate: number;
    lengthInMonths: number;
    principal: number;
    paymentPlan: PaymentPlanFormattedString;
    gradDate: number;
    gracePeriod: number;
}) {
    const { interestRate, lengthInMonths, principal, paymentPlan, gradDate, gracePeriod } = params;

    if (paymentPlan === "Fully deferred") {
        const totalAmountPaid = calculateTotalPaidForLoan(params);
        const monthlyPayment = totalAmountPaid.amount / lengthInMonths;

        if (monthlyPayment < 0) {
            return formatMoneyAmount(0, false);
        }

        return formatMoneyAmount(monthlyPayment, true);
    }

    if (paymentPlan === "Fixed (partial interest)") {
        const flatPaymentAmount = 25;

        const monthsLeftOfFlatPayment = calculateMonthsLeftUntilGraduation(gradDate) + gracePeriod;

        const totalAmountPaid = calculateTotalPaidForLoan(params);
        const totalAmountPaidAfterGrad =
            totalAmountPaid.amount - flatPaymentAmount * 100 * monthsLeftOfFlatPayment;
        const monthlyPayment = totalAmountPaidAfterGrad / lengthInMonths;

        if (monthlyPayment < 0) {
            return formatMoneyAmount(0, false);
        }

        return formatMoneyAmount(monthlyPayment, true);
    }

    const monthlyPayment = calculateMonthlyPaymentOfAmortizedStudentLoan({
        lengthInMonths: lengthInMonths,
        interestRate: interestRate,
        principal: principal,
    });

    if (monthlyPayment < 0) {
        return formatMoneyAmount(0, false);
    }
    return formatMoneyAmount(monthlyPayment, false);
}

// This function calculates the monthly payment of a student loan
// This is a general function to calculate the payment once the borrower has started
// The full repayment of the loan
export function calculateMonthlyPaymentOfAmortizedStudentLoan(params: {
    interestRate: number;
    lengthInMonths: number;
    principal: number;
}) {
    const { interestRate, lengthInMonths, principal } = params;

    const interestRateAsDecimal = interestRate / 100;
    const monthlyInterestRate = interestRateAsDecimal / 12;

    // Converts principal in cents to principal in dollars
    const principalInDollars = principal / 100;

    return calculateLoanPaymentFromPrincipalAndInterest({
        lengthInMonths: lengthInMonths,
        monthlyInterestRate: monthlyInterestRate,
        principalInDollars: principalInDollars,
    });
}

// The below calculates the monthly payment of the loan
// Assuming that interest rate is monthly
// And the principal is in dollars (that is we have divided by 100 already as it is normally in cents)
function calculateLoanPaymentFromPrincipalAndInterest(params: {
    monthlyInterestRate: number;
    lengthInMonths: number;
    principalInDollars: number;
}) {
    const { monthlyInterestRate, lengthInMonths, principalInDollars } = params;

    // The formula below calculates the monthly payment of the loan
    // This formula can be found under the amortization section on this web page
    // https://www.thebalance.com/loan-payment-calculations-315564
    const poweredInterestRate = Math.pow(1.0 + monthlyInterestRate, lengthInMonths);
    const amortizedNumerator = principalInDollars * monthlyInterestRate * poweredInterestRate;
    return amortizedNumerator / (poweredInterestRate - 1);
}

function calculateMonthsLeftUntilImmediateRepayment(gradDate: number, gracePeriod: number) {
    return Math.max(0, calculateMonthsLeftUntilGraduation(gradDate) + gracePeriod);
}
