import { UserType } from "../../../types/sparrow-types";
import { getSvgColors } from "../getSvgColors";

export const CheckBox = ({ userType }: { userType: UserType }) => {
    const { primaryColor } = getSvgColors(userType);

    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="24" height="24" rx="4" fill={primaryColor} />
            <path
                d="M17.5917 8.00822C17.5142 7.93011 17.4221 7.86811 17.3205 7.82581C17.219 7.7835 17.11 7.76172 17 7.76172C16.89 7.76172 16.7811 7.7835 16.6796 7.82581C16.578 7.86811 16.4858 7.93011 16.4084 8.00822L10.2 14.2249L7.59171 11.6082C7.51127 11.5305 7.41632 11.4694 7.31227 11.4284C7.20823 11.3874 7.09713 11.3673 6.98531 11.3692C6.87349 11.3712 6.76315 11.3951 6.66058 11.4397C6.55802 11.4843 6.46524 11.5486 6.38754 11.6291C6.30984 11.7095 6.24875 11.8044 6.20774 11.9085C6.16674 12.0125 6.14663 12.1236 6.14856 12.2354C6.1505 12.3473 6.17444 12.4576 6.21902 12.5602C6.2636 12.6627 6.32794 12.7555 6.40837 12.8332L9.60837 16.0332C9.68584 16.1113 9.77801 16.1733 9.87956 16.2156C9.98111 16.2579 10.09 16.2797 10.2 16.2797C10.3101 16.2797 10.419 16.2579 10.5205 16.2156C10.6221 16.1733 10.7142 16.1113 10.7917 16.0332L17.5917 9.23322C17.6763 9.15518 17.7438 9.06047 17.79 8.95506C17.8361 8.84964 17.86 8.7358 17.86 8.62072C17.86 8.50563 17.8361 8.39179 17.79 8.28638C17.7438 8.18096 17.6763 8.08625 17.5917 8.00822Z"
                fill="white"
            />
        </svg>
    );
};
