import { useMediaQuery } from "react-responsive";

import { OfferCardHeaderExpandButton } from "./interaction/buttons/OfferCardHeaderExpandButton";

import "../style.scss";

import LogoGreen from "../../../../assets/images/logo-green.svg";
import { UserType } from "../../../constants/defaultInputs";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import { GenericBubble } from "../../Bubble";
import { getBestRateLabelTooltipContent } from "../../../views/offers/components/table/utilities/BestRateUtilities";
import { OffersTablePreferencesFrontend } from "../../../../sparrow-ui-library/src/types/sparrow-types/offers-table-preferences";

interface LenderContainerProps {
    toggle?: Function;
    progress?: number;
    lenderName: string;
    bestRateLabel?: OffersTablePreferencesFrontend;
    imageUrl?: string;
    isDefault: boolean;
}

export const OfferCardHeaderLenderContainer: React.FunctionComponent<LenderContainerProps> = ({
    imageUrl,
    toggle,
    progress,
    bestRateLabel,
    lenderName,
    isDefault,
}) => {

    const isMore1200px = useMediaQuery({
        query: "(min-width: 1200px)",
    });
    const { formData } = useOnboarding();

    const isBorrowerInitiated = formData.userType === UserType.BORROWER;

    return (
        <>
            <div className={"lender-container" + (isDefault ? "" : " direct-apply")}>
                <div className={"lender-icon" + (isDefault ? "" : " direct-apply")}>
                    <img loading="eager" src={imageUrl ?? LogoGreen} alt="" />
                </div>
                <div className="lender-section-container">
                    <div className="lender-name-container">
                        <div className="lender-name">{lenderName}</div>
                        {bestRateLabel !== undefined && (
                            <div className={`tooltip-offer-card`}>
                                <div
                                    className={
                                        "offer-label" +
                                        (isBorrowerInitiated ? "" : " cosigner") +
                                        (bestRateLabel ===
                                        OffersTablePreferencesFrontend.AUTO_ROUNDED
                                            ? " auto-rounded"
                                            : "")
                                    }
                                    data-tip
                                    data-for={"tooltip-detail"}
                                >
                                    {bestRateLabel}
                                </div>
                                <div className={"best-rate-bubble-wrapper-tooltip"}>
                                    <GenericBubble>
                                        <div className="offer-page-popup">
                                            <div className="offer-page-popup-title">
                                                {bestRateLabel}
                                            </div>
                                            <div
                                                className="text-description"
                                                dangerouslySetInnerHTML={{
                                                    __html: getBestRateLabelTooltipContent(
                                                        bestRateLabel
                                                    ),
                                                }}
                                            />
                                        </div>
                                    </GenericBubble>
                                </div>
                            </div>
                        )}
                    </div>
                    {!isMore1200px && isDefault && (
                        <OfferCardHeaderExpandButton toggle={toggle!} progress={progress!} />
                    )}
                </div>
            </div>
        </>
    );
};
