import { FC } from "react";
import { UserType } from "../../../types/sparrow-types";
import { getSvgColors } from "../getSvgColors";

export const BalanceIcon: FC<{ userType?: UserType }> = ({ userType = UserType.BORROWER }) => {
    const { primaryColor, accentColor } = getSvgColors(userType);

    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M5 23L35 29" stroke={accentColor} strokeWidth="2" strokeLinecap="round" />
            <path
                d="M17.768 26C18.5378 24.6667 20.4623 24.6667 21.2321 26L24.2631 31.25C25.0329 32.5833 24.0707 34.25 22.5311 34.25H16.4689C14.9293 34.25 13.9671 32.5833 14.7369 31.25L17.768 26Z"
                fill={accentColor}
            />
            <circle cx="30" cy="16" r="8" fill={primaryColor} />
            <circle cx="9.5" cy="15.5" r="4.5" fill={accentColor} />
        </svg>
    );
};
