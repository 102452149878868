import { ModalWrapper } from "../../../../../../common/components/modals/modal-wrapper";
import awlApproveIcon from "../../../../../../assets/icons/awl-approve-icon.svg";
import { LenderStatuses } from "../../../../../../common/models/request";
import { useOnboarding } from "../../../../../../common/core/providers/onboarding.provider";

const textCopy = (lenderStatus: LenderStatuses, lenderName: string, companyName: string) => {
    if (lenderStatus === LenderStatuses.CANCELLED) {
        return {
            title: "Why was my request cancelled?",
            content: (
                <>
                    <p>
                        A lender may cancel a formal request for credit for several reasons.
                        Generally this occurs when a borrower asks the lender to cancel the loan or
                        when an incomplete request exceeds a prolonged period of inactivity. If
                        you'd like more information as to why your student loan with {lenderName}{" "}
                        was cancelled, please contact {lenderName} directly.
                    </p>
                    <p>
                        If you'd like more information as to why your student loan with {lenderName}
                        , was cancelled please contact {lenderName} directly.
                    </p>
                </>
            ),
        };
    }
    if (lenderStatus === LenderStatuses.DENIED) {
        return {
            title: `Why was I denied if I pre-qualified on ${companyName}?`,
            content: (
                <>
                    <p>
                        Pre-qualification is not as thorough as a formal request for credit.
                        Occasionally, individuals who pre-qualified on {companyName} are ultimately denied
                        by a lender during a formal request for credit. This typically happens if
                        the information you entered on {companyName} is different than the information you
                        submitted with a lender or if a lender is unable to formally verify the
                        information you submitted.
                    </p>
                    <p>
                        If you'd like more information as to why you were denied with {lenderName},
                        please contact {lenderName} directly.
                    </p>
                </>
            ),
        };
    }
};

export const CancelledDeniedModal = ({ lenderStatus, lenderName }) => {
    const {companyName} = useOnboarding();

    const text = textCopy(lenderStatus, lenderName, companyName);

    return (
        <ModalWrapper maxWidth="652px">
            <img src={awlApproveIcon} alt="approve-icon" width={40} height={40} />
            <h3>{text?.title}</h3>
            <p>{text?.content}</p>
        </ModalWrapper>
    );
};
