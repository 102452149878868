import { Navigate, Route, Routes } from "react-router-dom";
import { SignInInitStep } from "./type";
import { SignInPage } from "./borrower";
import {
    loginBorrowerRoutingUrl,
    loginTypeRoutingUrl,
} from "../../common/core/routes/routing-urls";
import { isMarketplaceEnv } from "../../common/utils/config";
import { loginStepBorrower } from "../../common/core/routes/navigation-urls";

export const LoginRoutes = () => {
    return (
        <>
            <Routes>
                <Route
                    path={loginTypeRoutingUrl}
                    element={
                        isMarketplaceEnv() ? (
                            <SignInInitStep />
                        ) : (
                            <Navigate to={loginStepBorrower} replace />
                        )
                    }
                />
                <Route path={loginBorrowerRoutingUrl} element={<SignInPage />} />
            </Routes>
        </>
    );
};
