import { dashboardUrl, loginStepBorrower, paaasLandingUrl } from "../../core/routes/navigation-urls";

export const isPAAASLandingUrl = (pathname: string) => {
    return pathname === paaasLandingUrl;
};

export const isDashboardPage = (pathname: string) => {
    return pathname === dashboardUrl;
};

// Logo clicks within the app should go to the dashboard. If on the dashboard, the logo should only be clickable if the PAAAS app has a landing page view.
export const getRouteForPAAASLogo = (params: {
    pathname: string;
    displayLandingPageView?: boolean;
}) => {
    const { pathname, displayLandingPageView } = params;
    
    // If on dashboard and toggle on, go to landing page
    if (displayLandingPageView && (pathname === loginStepBorrower || pathname === dashboardUrl)) {
        return paaasLandingUrl;
    }
    
    if (!isDashboardPage(pathname) && pathname !== loginStepBorrower && pathname !== paaasLandingUrl) {
        return dashboardUrl;
    }
    // If on (dashboard or login page) and toggle off, do nothing (logo should be unclickable)
    return undefined;
};
