import CustomScrollbar from "../../../../common/components/Scrollbar/CustomScrollbar";
import { useOnboarding } from "../../../../common/core/providers/onboarding.provider";
import { isMarketplaceEnv } from "../../../../common/utils/config";
import { getPossesiveCase } from "../../../../common/utils/global";

export const DecisionPageDisclosure = () => {
    const { companyName } = useOnboarding();

    return (
        <>
            <h3>Disclaimers</h3>
            <div className="h4">APR & Discounts:</div>
            <p className="text-grey little disclosures-description">
                All APRs reflect all applicable discounts where available. Lowest rates are only
                available to the most creditworthy applicants.
            </p>
            <CustomScrollbar height={500}>
                <h4>Prequalified rates are potential, not offers of credit.</h4>
                <p>
                    {getPossesiveCase(companyName)} prequalification process is intended to provide
                    you with preliminary information regarding potential options for a loan with one
                    of our Lender Partners. {companyName} is not a lender and does not make credit
                    decisions or employ its own credit criteria. A prequalification inquiry is not a
                    form for credit.
                </p>
                <h4>No guarantees:</h4>
                <p>
                    {companyName} makes no representation, warranties or guarantees about your
                    eligibility for credit or for a particular loan. {companyName} and its Lender
                    Partners do not have prequalified information for all circumstances and may be
                    unable to provide a prequalified rate in response to every inquire. Lender
                    Partners reserve the right to change or withdraw the prequalified rates at any
                    time. Additionally, receiving a prequalified rate does not guarantee that your
                    chosen Lender Partner will extend an offer of credit. All credit decisions,
                    including loan approval, if any, are determined by our Lender Partners, in their
                    sole discretion. There are some Lender Partners on the {companyName} platform
                    who do not participate in providing prequalified rates to users; in these
                    circumstances, you may apply with these Lender Partners directly.
                </p>
                <h4>Discounts:</h4>
                <p>
                    If a Lender Partner offers a discount (typically 0.25% for automatic payment
                    “autopay”), the discounted rate will be displayed with a green tag. When viewing
                    the collapsed list of Lender Partner rates, the rates with discounts are
                    depicted with a green tag placed on the upper left hand corner of the Lender
                    Partner’s logo. The rate provided directly to you from the Lender Partner may
                    not include the autopay discount and you may not qualify for the autopay
                    discount. Note that autopay discounts are applied differently by different
                    Lender Partners. Some Lender Partners will apply the discount to lower the
                    principal amount of the loan, instead of applying the discount to the monthly
                    payment. When the Lender Partner does this your monthly payment will appear
                    higher, but your loan balance gets paid off sooner. Other Lender Partners will
                    apply the discount to the monthly payment, making your monthly payment lower.
                    The manner in which these discounts are determined and applied are at the Lender
                    Partner’s sole discretion. Please inquire about the autopay discount directly
                    with the Lender Partner. For consistency, for the rates displayed with
                    discounts, {companyName} displays the discount as thought the discount is
                    applied to the monthly payment. Accordingly, the manner in which {companyName}{" "}
                    displays the monthly payment information is for illustrative purposes only; the
                    actual monthly payment information will be provided directly to you by the
                    Lender Partner.
                </p>
                <h4>
                    Post-prequalification process for obtaining an offer of credit from a Lender
                    Partner.
                </h4>
                <p>
                    If you are provided with prequalified rates and you choose an option on your
                    {companyName} Dashboard, you will be prompted to complete your form with the
                    Lender Partner (which process may be either on Sparrowfi.com or the Lender
                    Partner’s website) and will require reading and consenting to the Lender
                    Partner’s terms of use, privacy policies, relevant disclosures, credit pull
                    authorization and potentially other lender-specific documents. The Lender
                    Partner will then conduct a hard credit inquiry to further assess your
                    creditworthiness according to their criteria (as determined in their sole
                    discretion). Then, if you are still eligible for the Lender Partner’s loan
                    product, the rate you are eligible for will be displayed on your{" "}
                    {isMarketplaceEnv() ? "Sparrow" : "student loan marketplace"} dashboard and via
                    email or other electronic means. Lenders may decide not to extend you an offer
                    of credit if the Lender Partner(s) determine that you do not meet their
                    criteria. Lenders reserve the right to approve or decline individual credit
                    applications.
                </p>
                <h4>Rates from Lender Partners may differ from Prequalified Rates.</h4>
                <p>
                    Rates from Lender Partners may differ from Prequalified Rates due to a number of
                    factors which may include, but are not limited to: (i) changes in your personal
                    credit circumstances; (ii) additional information you provide (or are unable to
                    provide) to the Lender Partner during the application process; and/or (iii)
                    changes ini APRs (e.g., there may be an increase in thee rate index between the
                    time you go through the prequalification process and the time you submit your
                    form for the loan and/or the time you loan closes. Or, if the loan option is a
                    variable rate loan, then the interest rate index used to set the APR is subject
                    to increases or decreases at any time). Lowest advertised rates typically
                    require a credit-worthy cosigner and/or highest degree held by the borrower.
                </p>
                <h4>Errors in information displayed or provided to you:</h4>
                <p>
                    Neither {companyName} nor its Lender Partners assume responsibility for
                    typographical or other errors or omissions in the information provided to you.
                </p>
                <h4>Additional Information:</h4>
                <p>
                    When making a decision on the options provided to you by our Lender Partners,
                    {companyName} encourages you to seek professional advice before making a
                    decision to ensure that you are making an informed decision based on your
                    personal financial circumstances. Your decision to move forward with a
                    particular Lender Partner and loan product is solely your decision and your
                    responsibility.
                </p>
                <p>
                    While {companyName} seeks to provide you with information on a wide choice of
                    potential loan options, remember that there may be additional borrowing,
                    consolidation and/or refinancing options available.
                </p>
                <p>
                    Also, with respect to student loans and student loan refinancing, keep in mind
                    that Federal loans come with many benefits and protections such as deferment,
                    forbearance, and loan forgiveness programs which will be lost when refinancing
                    into a private loan option. While some private lenders offer some of these same
                    benefits, not all do. Therefore, it is your responsibility to review the Lender
                    Partner’s disclosures carefully before selecting a private student loan option
                    or refinancing your federal loans with private loans. For more information on
                    Federal student loans visit studentaid.ed.gov.
                </p>
                <h4>Compensation Disclosure:</h4>
                <p>
                    {companyName} may receive fees when a user selects a product and completes a
                    loan with a Lender Partner. These fees do not impact the rate that you receive
                    or the cost of your loan. The loan products above are examples of this
                    arrangement.
                </p>
            </CustomScrollbar>
        </>
    );
};
