import { FC } from "react";
import { UserType } from "../../../types/sparrow-types";
import { getSvgColors } from "../getSvgColors";
import "./request-history-icons.styles.css";


export const StarIcon: FC<{ userType?: UserType }> = ({ userType = UserType.COSIGNER }) => {
    const { primaryColor } = getSvgColors(userType);
    
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="StarIcon"
        >
            <path
                d="M14.667 6.44676C14.6249 6.32482 14.5483 6.21771 14.4465 6.13843C14.3448 6.05915 14.2222 6.01109 14.0936 6.0001L10.3003 5.44676L8.60031 2.0001C8.54572 1.88738 8.46049 1.79232 8.35437 1.72581C8.24825 1.6593 8.12555 1.62402 8.00031 1.62402C7.87507 1.62402 7.75237 1.6593 7.64625 1.72581C7.54014 1.79232 7.4549 1.88738 7.40031 2.0001L5.70031 5.4401L1.90698 6.0001C1.78359 6.01763 1.66759 6.06941 1.57214 6.14954C1.47669 6.22967 1.40562 6.33495 1.36698 6.45343C1.33161 6.56921 1.32843 6.69244 1.3578 6.80989C1.38716 6.92734 1.44795 7.03458 1.53364 7.1201L4.28698 9.78676L3.62031 13.5734C3.59339 13.699 3.60335 13.8298 3.64897 13.9499C3.69459 14.0699 3.77394 14.1743 3.87746 14.2504C3.98099 14.3264 4.10429 14.371 4.23253 14.3786C4.36077 14.3862 4.48848 14.3567 4.60031 14.2934L8.00031 12.5134L11.4003 14.2934C11.4939 14.3462 11.5996 14.3738 11.707 14.3734C11.8482 14.3739 11.9859 14.3296 12.1003 14.2468C12.2037 14.1727 12.2838 14.0705 12.3311 13.9524C12.3783 13.8343 12.3908 13.7051 12.367 13.5801L11.7003 9.79343L14.4536 7.12676C14.5499 7.04522 14.621 6.93803 14.6588 6.81766C14.6966 6.69729 14.6994 6.56868 14.667 6.44676ZM10.567 9.11343C10.4898 9.18837 10.4319 9.28086 10.3983 9.38302C10.3646 9.48518 10.3562 9.59397 10.3736 9.7001L10.8536 12.5001L8.34698 11.1668C8.24956 11.1186 8.14233 11.0935 8.03364 11.0935C7.92496 11.0935 7.81773 11.1186 7.72031 11.1668L5.21364 12.5001L5.69364 9.7001C5.71114 9.59397 5.70269 9.48518 5.66902 9.38302C5.63536 9.28086 5.57747 9.18837 5.50031 9.11343L3.50031 7.11343L6.30698 6.70676C6.41498 6.69174 6.51764 6.65046 6.60598 6.58653C6.69431 6.5226 6.76562 6.43799 6.81364 6.3401L8.00031 3.8001L9.25364 6.34676C9.30167 6.44465 9.37298 6.52927 9.46131 6.5932C9.54965 6.65712 9.65231 6.69841 9.76031 6.71343L12.567 7.1201L10.567 9.11343Z"
                fill={primaryColor}
            />
        </svg>
    );
};
