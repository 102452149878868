import { isDefined } from "../../../../utils/helper_functions/validation-functions";
import { OfferCardBodyTooltip } from "../../body/OfferCardBodyTooltip";

import "../../style.scss";

interface DetailsProps {
    content?: string;
    value?: string;
    title?: string;
}

export const OfferCardHeaderDetail: React.FunctionComponent<DetailsProps> = ({
    content,
    value,
    title,
}) => {
    return (
        <div className="offer-information-container">
            <div className="offer-information-title-container">
                <div className="offer-information-title">{title}</div>
                {isDefined(content) && title && (
                    <OfferCardBodyTooltip content={content!} id={title} />
                )}
            </div>
            <div className="offer-information">{value}</div>
        </div>
    );
};
