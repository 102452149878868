import {
    OffersTablePreferences,
    OffersTablePreferencesFrontend,
} from "../../../../../../sparrow-ui-library/src/types/sparrow-types";
import {
    getPrimaryRankValue,
    convertBestRateLabelToFrontendString,
    getSecondaryRankValue,
} from "./TableUtilities";

function getRankValue(params: {
    offer: any;
    isPrimary: boolean;
    rank: OffersTablePreferences;
    optionalSecondaryRank?: OffersTablePreferences;
}) {
    const { offer, isPrimary, rank, optionalSecondaryRank } = params;
    return isPrimary
        ? getPrimaryRankValue(offer, rank)
        : getSecondaryRankValue({
              offer,
              secondaryRank: rank,
              substituteSecondaryRank: optionalSecondaryRank,
          });
}
function mapSingleRank(params: {
    isPSL: boolean;
    offersData: any[];
    isPrimary: boolean;
    currentMapping: any;
    rank: OffersTablePreferences;
}) {
    const { isPSL, offersData, isPrimary, currentMapping, rank } = params;

    // We filter offer data to remove auto rounded rates because labels should be calculated
    // on the set of offers that are not rounded.
    let filteredOffersData = offersData;
    if (rank !== OffersTablePreferences.AUTO_ROUNDED) {
        filteredOffersData = offersData.filter((offer) => !offer.amountWasRounded);
    }

    const offersSortedByRank = [...filteredOffersData].sort(function (offerA, offerB) {
        const valueA = getRankValue({
            offer: offerA,
            isPrimary,
            rank,
            optionalSecondaryRank: rank,
        });
        const valueB = getRankValue({
            offer: offerB,
            isPrimary,
            rank,
            optionalSecondaryRank: rank,
        });
        if (valueA < valueB) {
            return -1;
        } else if (valueA > valueB) {
            return 1;
        }

        return 0;
    });

    let firstRankValue = Number.MAX_SAFE_INTEGER;
    const offersToLabel: string[] = [];
    for (const offer of offersSortedByRank) {
        // If the rank is auto rounding, we only care about offers with amountWasRounded as true
        // All auto rounded rates are equivalent to each other in terms of rank.
        if (rank === OffersTablePreferences.AUTO_ROUNDED) {
            if (!offer.amountWasRounded) continue;
            if (!(offer.id in currentMapping)) {
                offersToLabel.push(offer.id);
            }
        } else {
            const rankValue = getRankValue({ offer, isPrimary, rank, optionalSecondaryRank: rank });
            if (rankValue <= firstRankValue) {
                firstRankValue = rankValue;
                if (!(offer.id in currentMapping)) {
                    offersToLabel.push(offer.id);
                }
            } else {
                break;
            }
        }
    }

    if (offersToLabel.length === 1) {
        currentMapping[offersToLabel[0]] = convertBestRateLabelToFrontendString(isPSL, rank);
    } else {
        for (const id of offersToLabel) {
            // We do not include (tied) with the auto rounded best rate label
            const frontendBestRateLabel = convertBestRateLabelToFrontendString(isPSL, rank);
            if (rank === OffersTablePreferences.AUTO_ROUNDED) {
                currentMapping[id] = frontendBestRateLabel;
            } else {
                currentMapping[id] = frontendBestRateLabel + " (tied)";
            }
        }
    }
}
/**
 * This function obtains the best rate label mapping for the user's offers.
 * @param labelMapping
 * @param offersData
 * @param primaryRank
 * @param secondaryRank
 * @returns
 */
export function getBestRateLabels(params: {
    isPSL: boolean;
    labelMapping: {};
    offersData: any[];
    primaryRank: OffersTablePreferences | null;
    secondaryRank: OffersTablePreferences;
}) {
    const { isPSL, labelMapping, offersData, primaryRank } = params;
    const nextLabelMapping = { ...labelMapping };

    // Priority 0. Auto Rounding
    // Auto rounded loans must be marked first to ensure that
    // all labels are based on non rounded rates.
    mapSingleRank({
        isPSL,
        offersData,
        isPrimary: false,
        currentMapping: nextLabelMapping,
        rank: OffersTablePreferences.AUTO_ROUNDED,
    });

    // Priority 1. Primary Rank
    // Primary rank is always the #1 priority
    if (primaryRank) {
        mapSingleRank({
            isPSL,
            offersData,
            isPrimary: true,
            currentMapping: nextLabelMapping,
            rank: primaryRank,
        });
    }

    // Priority 2. APR
    // APR is always the #2 priority because it cannot be Primary Rank
    mapSingleRank({
        isPSL,
        offersData,
        isPrimary: false,
        currentMapping: nextLabelMapping,
        rank: OffersTablePreferences.LOWEST_APR,
    });

    // Priority 3. Term
    // Term is the #3 priority if and only if it is not already the primary rank.
    if (primaryRank !== OffersTablePreferences.SHORTEST_TERM) {
        mapSingleRank({
            isPSL,
            offersData,
            isPrimary: false,
            currentMapping: nextLabelMapping,
            rank: OffersTablePreferences.SHORTEST_TERM,
        });
    }

    // Priority 4. Lowest total cost
    // Lowest total cost is the #4 priority if and only if it is not already the primary rank.
    if (primaryRank !== OffersTablePreferences.LOWEST_TOTAL_COST) {
        mapSingleRank({
            isPSL,
            offersData,
            isPrimary: false,
            currentMapping: nextLabelMapping,
            rank: OffersTablePreferences.LOWEST_TOTAL_COST,
        });
    }

    // Priority 5. Lowest in school
    // Lowest in school is the #5 priority if and only if it is not already the primary rank.
    if (isPSL && primaryRank !== OffersTablePreferences.LOWEST_IN_SCHOOL) {
        mapSingleRank({
            isPSL,
            offersData,
            isPrimary: false,
            currentMapping: nextLabelMapping,
            rank: OffersTablePreferences.LOWEST_IN_SCHOOL,
        });
    }

    // Priority 6. Lowest monthly payment
    // Lowest monthly payment is the #6 priority if and only if it is not already the primary rank.
    if (primaryRank !== OffersTablePreferences.LOWEST_MONTHLY_PAYMENT) {
        mapSingleRank({
            isPSL,
            offersData,
            isPrimary: false,
            currentMapping: nextLabelMapping,
            rank: OffersTablePreferences.LOWEST_MONTHLY_PAYMENT,
        });
    }
    return nextLabelMapping;
}

/**
 * Obtain tool tip content for best rate label.
 * @param bestRateLabel OffersTablePreferencesFrontend
 * @returns string content
 */
export function getBestRateLabelTooltipContent(bestRateLabel: string) {
    if (bestRateLabel === OffersTablePreferencesFrontend.AUTO_ROUNDED) {
        return "Your requested loan size is outside of the lender's range. All terms (i.e. APR, Term, Total Cost, and Monthly Payment(s)) associated with this pre-qualified rate are under the assumption that you adjust your requested loan size to be within the lender's range. To see the lender's loan limit, click the down arrow next to this rate.";
    } else if (
        bestRateLabel === OffersTablePreferencesFrontend.LOWEST_APR ||
        bestRateLabel === OffersTablePreferencesFrontend.LOWEST_APR + " (tied)"
    ) {
        return "Of all your pre-qualified rates, this rate has the lowest APR.";
    } else if (
        bestRateLabel === OffersTablePreferencesFrontend.LOWEST_MONTHLY_PAYMENT_PSL ||
        bestRateLabel === OffersTablePreferencesFrontend.LOWEST_MONTHLY_PAYMENT_PSL + " (tied)"
    ) {
        return "Of all your pre-qualified rates, this rate has the lowest monthly payment.";
    } else if (
        bestRateLabel === OffersTablePreferencesFrontend.LOWEST_IN_SCHOOL ||
        bestRateLabel === OffersTablePreferencesFrontend.LOWEST_IN_SCHOOL + " (tied)"
    ) {
        return "Of all your pre-qualified rates, this rate has the lowest in school payment.";
    } else if (
        bestRateLabel === OffersTablePreferencesFrontend.LOWEST_MONTHLY_PAYMENT_SLR ||
        bestRateLabel === OffersTablePreferencesFrontend.LOWEST_MONTHLY_PAYMENT_SLR + " (tied)"
    ) {
        return "Of all your pre-qualified rates, this rate has the lowest monthly payment.";
    } else if (
        bestRateLabel === OffersTablePreferencesFrontend.LOWEST_TOTAL_COST ||
        bestRateLabel === OffersTablePreferencesFrontend.LOWEST_TOTAL_COST + " (tied)"
    ) {
        return "Of all your pre-qualified rates, this rate has the lowest total cost.";
    } else if (
        bestRateLabel === OffersTablePreferencesFrontend.SHORTEST_TERM ||
        bestRateLabel === OffersTablePreferencesFrontend.SHORTEST_TERM + " (tied)"
    ) {
        return "Of all your pre-qualified rates, this rate has the shortest term.";
    }
    return "";
}
