import approved from './approved.svg';
import canceledDenied from './canceled.svg';
import disbursed from './disbursed.svg';
import signed from './signed.svg';
import started from './started.svg';
import submitted from './submitted.svg';

export const SVG_LENDER_STATUS = {
    approved,
    canceledDenied,
    disbursed,
    signed,
    started,
    submitted,
}
