import {
    FormFieldType,
    FormFieldName,
    FormInputsConfig,
    FormsConfig,
    FormFieldProps
} from "../../../sparrow-ui-library/src/types/form-types";
import { PrequalificationRequestInput } from "../../../sparrow-ui-library/src/types/sparrow-types";
import { epochToFormattedDateString } from "../../../sparrow-ui-library/src/utils/general-utils";

export const getFormsConfig = (inputs: FormsConfig): FormInputsConfig => {
    const { companyName, applicableFormData, getApplicableUserText, creatorIsIncludingUser, formData } = inputs as {
        companyName: string;
        applicableFormData: PrequalificationRequestInput;
        getApplicableUserText: (text: string) => string;
        creatorIsIncludingUser: boolean;
        formData: PrequalificationRequestInput;
      };

      const hasSSN = !!applicableFormData.citizenshipStatus.hasSSN;

      const ssnField = () : FormFieldProps => {
        return {
            formFieldType: FormFieldType.TextBox,
            id: FormFieldName.SSN,
            name: FormFieldName.SSN,
            label: "SSN",
            placeholder: "•••-••-••••",
            inputValue: applicableFormData.ssn ? `•••-••-${applicableFormData.ssn}` : "",
            backendValue: "",
            errorMessage: "",
            type: "text",
            hasToolTip: true,
            toolTipText: `We use your ${creatorIsIncludingUser ? `${formData.includedUser?.userType}'s` : ""} SSN to verify ${creatorIsIncludingUser ? "their" : "your"} identity and perform a soft pull of ${creatorIsIncludingUser ? "their" : "your"} credit, which will not affect ${creatorIsIncludingUser ? "their" : "your"} credit score.`,
        }
      }

    return {
        forms: [
            {
                header: getApplicableUserText(`${companyName} never shares your personal information`),
                independentFields: [
                    {
                        formFieldType: FormFieldType.TextBox,
                        id: FormFieldName.DOB,
                        name: FormFieldName.DOB,
                        label: "Date of birth",
                        placeholder: "MM/DD/YYYY",
                        inputValue: applicableFormData.dob ? epochToFormattedDateString(applicableFormData.dob as number, true) : "",
                        backendValue: "",
                        errorMessage: "",
                        type: "text",
                    },
                    // Do not display the ssn field if the user does not have one
                    ...(hasSSN ? [ssnField()] :[]),
                ],
            },
        ],
    };
};
