import { useEffect, useState, useRef } from "react";
import { useOnboarding } from "../../../core/providers/onboarding.provider";
import "./styles.scss";
import { GetProgressRoadmaps } from "../../../core/services/sparrow-api-handlers/prequalification-api-handlers";
import { observer } from "mobx-react";
import { CompleteStatusIcon, IncompleteStatusIcon } from "../../../../sparrow-ui-library/src/assets/icons/dashboard-status-icons";
import { UserType } from "../../../constants/defaultInputs";

export const RequestProgress = observer(() => {
    const onboarding = useOnboarding();
    const [progress, setProgress] = useState<{ [key: string]: any }>({});
    const [popupType, setPopupType] = useState<string>("");
    const [popupClicked, setPopupClicked] = useState<boolean>(false);

    const getUserProgress = async () => {
        if (!onboarding.jwt) return;
        const response = await GetProgressRoadmaps(onboarding.jwt);
        if (response.roadmaps.length) {
            onboarding.setLoanRequests(response.roadmaps, true);
            const roadmap = response.roadmaps.find(
                (roadmap) => roadmap.requestId === onboarding.formData.id
            );
            if (roadmap) {
                setProgress(roadmap?.progress);
            }
        }
    };

    useEffect(() => {
        getUserProgress();
    }, []);

    function renderProgressBubble(status: string) {
        if (status === " completed") {
            return (
                <CompleteStatusIcon userType={onboarding.formData.userType as UserType} />
            );
        } else if (status === " current") {
            return <IncompleteStatusIcon userType={onboarding.formData.userType as UserType} />;
        }
        return <div className={"incomplete-progress-step"} />;
    }

    function getModifierBasedOnCurrentStep(stepName: string) {
        const steps = [
            "prequalify",
            "compareAndSelect",
            "applyWithLender",
            "finalizeLoan",
            "receiveFunds",
        ];
        const stepIndex = steps.indexOf(stepName);
        if (steps[stepIndex] in progress) {
            // step exists in progress
            if (stepIndex < 4 && !(steps[stepIndex + 1] in progress)) {
                // step is not last step and next step does not exist in progress
                return " current";
            }

            return " completed"; // next step is in progress or we are at last step
        }
        return " incomplete"; // step does not exist in progress
    }

    function handleMouseEnter(step: string) {
        setPopupType(step);
    }

    function handleMouseLeave() {
        if (!popupClicked) {
            setPopupType("");
        }
    }

    const step1Ref = useRef<HTMLInputElement>(null);
    const step2Ref = useRef<HTMLInputElement>(null);
    const step3Ref = useRef<HTMLInputElement>(null);
    const step4Ref = useRef<HTMLInputElement>(null);
    const step5Ref = useRef<HTMLInputElement>(null);

    return (
        <>
            <div className="request-progress-divider" />
            <div className="request-progress-container">
                <div
                    ref={step1Ref}
                    className={
                        "request-progress-step" +
                        (popupType === "prequalify" ? " clicked" : "") +
                        getModifierBasedOnCurrentStep("prequalify")
                    }
                    onMouseEnter={() => handleMouseEnter("prequalify")}
                    onMouseLeave={() => handleMouseLeave()}
                    onClick={() => setPopupClicked(!popupClicked)}
                >
                    {renderProgressBubble(getModifierBasedOnCurrentStep("prequalify"))}
                    Pre-qualify
                </div>
                <div className="request-progress-line" />
                <div
                    ref={step2Ref}
                    className={
                        "request-progress-step" +
                        (popupType === "compareAndSelect" ? " clicked" : "") +
                        getModifierBasedOnCurrentStep("compareAndSelect")
                    }
                    onMouseEnter={() => handleMouseEnter("compareAndSelect")}
                    onMouseLeave={() => handleMouseLeave()}
                    onClick={() => setPopupClicked(!popupClicked)}
                >
                    {renderProgressBubble(getModifierBasedOnCurrentStep("compareAndSelect"))}
                    Compare and select
                </div>
                <div className="request-progress-line" />
                <div
                    ref={step3Ref}
                    className={
                        "request-progress-step" +
                        (popupType === "applyWithLender" ? " clicked" : "") +
                        getModifierBasedOnCurrentStep("applyWithLender")
                    }
                    onMouseEnter={() => handleMouseEnter("applyWithLender")}
                    onMouseLeave={() => handleMouseLeave()}
                    onClick={() => setPopupClicked(!popupClicked)}
                >
                    {renderProgressBubble(getModifierBasedOnCurrentStep("applyWithLender"))}
                    Apply with a lender
                </div>
                <div className="request-progress-line" />
                <div
                    ref={step4Ref}
                    className={
                        "request-progress-step" +
                        (popupType === "finalizeLoan" ? " clicked" : "") +
                        getModifierBasedOnCurrentStep("finalizeLoan")
                    }
                    onMouseEnter={() => handleMouseEnter("finalizeLoan")}
                    onMouseLeave={() => handleMouseLeave()}
                    onClick={() => setPopupClicked(!popupClicked)}
                >
                    {renderProgressBubble(getModifierBasedOnCurrentStep("finalizeLoan"))}
                    Finalize your loan
                </div>
                <div className="request-progress-line" />
                <div
                    ref={step5Ref}
                    className={
                        "request-progress-step" +
                        (popupType === "receiveFunds" ? " clicked" : "") +
                        getModifierBasedOnCurrentStep("receiveFunds")
                    }
                    onMouseEnter={() => handleMouseEnter("receiveFunds")}
                    onMouseLeave={() => handleMouseLeave()}
                    onClick={() => setPopupClicked(!popupClicked)}
                >
                    {renderProgressBubble(getModifierBasedOnCurrentStep("receiveFunds"))}
                    Receive your funds
                </div>
            </div>
        </>
    );
});
