import { CSSProperties, ReactNode } from 'react'
import "./button-group.styles.css";

const ButtonGroup = ({children, styles}: {children: ReactNode, styles: CSSProperties}) => {
  return (
    <div className='ButtonGroup' style={styles}>{children}</div>
  )
};

export default ButtonGroup;
