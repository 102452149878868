import { useEffect, useState } from "react";
import Slider from "rc-slider";
import { LoanTypeButtons } from "./LoanTypeButtons";
import { UserType } from "../../../../../../../common/constants/defaultInputs";
import { amountFormat } from "../../../../../../../sparrow-ui-library/src/utils/general-utils"
import SliderStyles from "../../../../../../../sparrow-ui-library/src/ui-components/form-components/loan-amount/SliderStyles"
import closeIcon from "../../../../../../../assets/images/icons/icon-24-cross-close-icon.png";

import styles from "../../styles.module.scss"
import { getMaxLoanAmount } from "../../../../../../../common/utils/global";
import { LoanType } from "../../../../../../../sparrow-ui-library/src/types/sparrow-types";
import { SearchIcon } from "../../../icons/SearchIcon";
import { useNavigate } from "react-router-dom";
import {  signUpStepBorrowerUrl } from "../../../../../../../common/core/routes/navigation-urls";

export const SearchAmountModal = ({ setShowModal }: { setShowModal: (value: boolean) => void }) => {
    const navigate = useNavigate();

    const minValue = 1000;
    // Input values are strings represented as dollars
    const [sliderValue, setSliderValue] = useState(32000);
    const [loanType, setLoanType] = useState<LoanType>("PSL");
    const [maxValue, setMaxValue] = useState(getMaxLoanAmount(loanType));

    useEffect(() => {
        setMaxValue(getMaxLoanAmount(loanType));
    }, [loanType]);

    const handleSliderChange = (value: number) => {
        setSliderValue(value);
    };

    return (
        <div className={styles.searchModalWrapper}>
            <button className={styles.closeIconPos} onClick={() => setShowModal(false)}>
                <img src={closeIcon} alt="close-button" />
            </button>
            <div className={styles.howMuchModalTxt}>
                How much do you want to borrow?
            </div>
            <div className={styles.valueContainer}>
                {amountFormat(sliderValue * 100)}
            </div>
            <SliderStyles userType={UserType.BORROWER} />
            <Slider
                value={sliderValue}
                max={maxValue}
                min={minValue}
                onChange={handleSliderChange}
                className={`Slider ${UserType.BORROWER}}`}
            />
            <div className="SliderBounds">
                <span>{amountFormat(String(minValue))}</span>
                <span>{amountFormat(String(maxValue))}</span>
            </div>
            <LoanTypeButtons setLoanType={setLoanType} />
            <button className={`${styles.searchWrapper} ${styles.hoverable}`} style={{ width: "100%" }} onClick={() => navigate(signUpStepBorrowerUrl)}>
                <SearchIcon />
                <span className={`${styles.searchTxt} ${styles.alwaysShowTxt}`}>Search</span>
            </button>
        </div>
    )
}
