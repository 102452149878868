import { Button, ButtonTypes } from "../../../../common/components/buttons/button/Button";
import { CitizenshipStatus, UserType } from "../../../../common/constants/defaultInputs";
import { DeleteLoanModal } from "../../../../common/components/modals/DeleteLoanModal";
import { getLatestIncompleteStep } from "../../../../common/core/global_routing/route-steps";
import { IRefCosigner } from "../../../../common/models/request";
import {
    prequalificationCitizenshipUrl,
    userInclusionSelectUrl,
} from "../../../../common/core/routes/navigation-urls";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../../common/core/providers/global.provider";
import { observer } from "mobx-react";
import { OnboardingStore } from "../../../../common/store/onboarding";
import { PencilIcon } from "../../../../sparrow-ui-library/src/assets/icons";
import { TrashIcon } from "../../../../sparrow-ui-library/src/assets/icons/trash-icon/TrashIcon";

const getCosignerCitizenshipStatus = (cosigner: IRefCosigner) => {
    let citizenshipStatus = cosigner.citizenshipStatus;
    citizenshipStatus = citizenshipStatus ?? CitizenshipStatus();
    return citizenshipStatus;
};

export const PrequalifyContinueButton: React.FC<{ onboarding: OnboardingStore }> = observer(
    ({ onboarding }) => {
        const navigate = useNavigate();

        //Cosigner Creator has passed the review step but has NOT yet included a user
        const cosignerHasNotIncludedBorrower = () => {
            const isCosignerCreator =
                onboarding.formData.isCreator && onboarding.formData.userType === UserType.COSIGNER;
            const passedReviewStep =
                typeof onboarding.formData.cosigner?.dob === "number" &&
                onboarding.formData.cosigner?.citizenshipStatus.hasSSN === true;
            return isCosignerCreator && passedReviewStep && !onboarding.formData.includedUser;
        };

        const handleContinue = () => {
            return cosignerHasNotIncludedBorrower()
                ? navigate(userInclusionSelectUrl)
                : navigate(getLatestIncompleteStep(onboarding));
        };

        const continueButtonText = () => {
            return cosignerHasNotIncludedBorrower() ? "Include your borrower" : "Continue";
        };

        return (
            <div className="flex-container-1" style={{ gap: "8px" }}>
                <Button
                    buttonType={ButtonTypes.SECONDARY}
                    showIcon={true}
                    userType={onboarding.formData.userType as UserType}
                    onClick={handleContinue}
                    padding="8px 16px"
                    borderWidth="2px"
                    style={{
                        fontWeight: 500,
                        fontSize: "12px",
                        lineHeight: "16px",
                    }}
                >
                    {continueButtonText()}
                </Button>
            </div>
        );
    }
);

export const PrequalifyEditButton: React.FC<{
    onboarding: OnboardingStore;
    offerExplored?: boolean;
}> = observer(({ onboarding, offerExplored }) => {
    const navigate = useNavigate();

    const handleEdit = () => {
        onboarding.setCreatorIsIncludingUser(false);
        onboarding.setEditsMadePostPrequalSubmission(false);
        if (onboarding.formData.userType === UserType.COSIGNER && onboarding.formData.isCreator) {
            const cosignerCitizenshipStatus = getCosignerCitizenshipStatus(
                onboarding.formData.cosigner as IRefCosigner
            );
            if (cosignerCitizenshipStatus.hasSSN === false) {
                return navigate(prequalificationCitizenshipUrl);
            }
        }

        return navigate(getLatestIncompleteStep(onboarding));
    };

    return (
        <>
            {!offerExplored && (
                <PencilIcon onClick={handleEdit} userType={onboarding.formData.userType as UserType}/>
            )}
        </>
    );
});

export const PrequalifyDeleteButton: React.FC<{
    onboarding: OnboardingStore;
    offerExplored?: boolean;
}> = observer(({ onboarding, offerExplored }) => {
    const store = useStore();

    const onDelete = () => {
        store.setModal(() => <DeleteLoanModal id={onboarding.formData.id} />);
    };
    return (
        <>
            {!offerExplored && <TrashIcon onClick={onDelete}/>}
        </>
    );
});

export const PostPrequalButtons: React.FC<{ onboarding: OnboardingStore, buttonText: string }> = observer(
    ({ onboarding, buttonText }) => {
        const navigate = useNavigate();

        return (
            <div className={"btn-group"}>
                <Button
                    buttonType={ButtonTypes.PRIMARY}
                    userType={onboarding.formData.userType as UserType}
                    onClick={() => {
                        const url = onboarding.getPostSubmissionUrl();
                        navigate(url);
                    }}
                >
                    {buttonText}
                </Button>
            </div>
        );
    }
);
