import { PrequalificationRequestInput } from './../../sparrow-ui-library/src/types/sparrow-types/prequalification-request/index';
import { runInAction, makeAutoObservable } from "mobx";
import { enableStaticRendering } from "mobx-react";
import { getCredSocket } from "../core/services/clientApi";
import { ISocketCredentials, IsQuizPassedType, IUserData } from "../models/userData";
import {
    clearPersistedStore,
    isHydrated,
    makePersistable,
    stopPersisting,
} from "mobx-persist-store";
import { UserMissingFieldsType } from "../core/services/sparrow-api-endpoints";

enableStaticRendering(typeof window === "undefined");

const isServer = typeof window === "undefined";
export class GlobalStore {
    currentUser: IUserData | null = null;
    socketCredentials: ISocketCredentials | null = null;
    notif: { title: string | null; type: string | null } = {
        title: null,
        type: null,
    };
    authNotif: { title: string | null; type: string | null; linkUrl?: string | null } = {
        title: null,
        type: null,
        linkUrl: null,
    };
    loginData: any = null;
    loading: boolean = false;
    protectedForm: boolean = false;
    formType: "refinance" | "loan" | null = "loan";
    baseUrl: any = false;
    loanScheme: number | null = null;
    sessionStarted: boolean = false;
    id: string | null = null;
    registerData: any = null;
    modal: {
        child: (() => JSX.Element) | null;
        blur: boolean;
        resetOutside: boolean;
        size: string;
        noPadding: boolean;
        enableMobileView: boolean;
    } = {
        child: null,
        blur: false,
        resetOutside: true,
        size: "",
        noPadding: false,
        enableMobileView: false,
    };
    refinanceStep: number = 0;
    loanStep: number = 0;
    skipFetch = false;
    loanStatus = null;
    alreadyHasLoan: PrequalificationRequestInput | null = null;
    refinanceStatus = null;
    fromReview: boolean = false;
    activeOfferTab: any = {
        SLR: "forYou",
        PSL: "forYou",
    };
    ssoUserData: Partial<UserMissingFieldsType> & { email?: string } = {};
    userHasMissingFields: boolean = false;
    isPSLQuizPassed: IsQuizPassedType | null = null;
    isSLRQuizPassed: IsQuizPassedType | null = null;
    lenderDisclosures: {
        PSL: { [key: string]: any } | null;
        SLR: { [key: string]: any } | null;
    } | null = null;
    entryPointUrl: string | null = null;

    constructor() {
        makeAutoObservable(this);
        if (!isServer) {
            const _loanScheme = localStorage.getItem("loanScheme");
            if (_loanScheme) {
                this.loanScheme = parseInt(_loanScheme);
            }

            const _data = sessionStorage.getItem("formData");
            if (_data) {
                this.sessionStarted = true;
            }
            makePersistable(
                this,
                {
                    name: "GS",
                    properties: [
                        "notif",
                        "authNotif",
                        // 'loading',
                        "currentUser",
                        "protectedForm",
                        "formType",
                        "baseUrl",
                        "sessionStarted",
                        "registerData",
                        "loginData",
                        // 'refinanceData',
                        // 'loanData',
                        "refinanceStep",
                        "loanStep",
                        "skipFetch",
                        "loanStatus",
                        "alreadyHasLoan",
                        "refinanceStatus",
                        "fromReview",
                        // 'activeOfferTab',
                        "ssoUserData",
                        "userHasMissingFields",
                        "entryPointUrl",
                    ],
                    storage: window.sessionStorage,
                },
                { delay: 100, fireImmediately: true }
            );

            makePersistable(this, {
                name: "isQzPsd",
                properties: ["isPSLQuizPassed", "isSLRQuizPassed", "lenderDisclosures"],
                storage: window.localStorage,
            });
        }
        this.getSocket();
    }

    get isGlobalHydrated() {
        return isHydrated(this);
    }

    setUserData = (data: IUserData) => {
        this.currentUser = data;
    };

    setSnackError = (title: string, type: "success" | "error") => {
        this.notifSet(title, type);
    };
    setAuthSnackError = (title: string, type: "success" | "error", linkUrl?: string) => {
        this.authNotifSet(title, type, linkUrl);
    };

    notifSet = (title: string, type: string) => {
        this.notif.title = title;
        this.notif.type = type;
    };
    authNotifSet = (title: string, type: string, linkUrl?: string) => {
        this.authNotif.title = title;
        this.authNotif.type = type;
        this.authNotif.linkUrl = linkUrl;
    };

    notifReset = () => {
        this.notif = {
            title: null,
            type: null,
        };
    };
    authNotifReset = () => {
        this.authNotif = {
            title: null,
            type: null,
        };
    };

    saveLoginToStore = (data: any) => {
        this.loginData = data;
    };

    getSocket = () => {
        getCredSocket().then((val) => {
            const responseSocketCred = {
                access_key: val.accessKeyId,
                secret_key: val.secretAccessKey,
                session_token: val.sessionToken,
            };
            runInAction(() =>
                this.saveSocketCredentials({
                    socketCredentials: responseSocketCred,
                })
            );
        });
    };

    saveSocketCredentials = (data: ISocketCredentials) => {
        this.socketCredentials = data;
    };

    setLoading = (state: boolean) => {
        this.loading = state;
    };

    setProtectedForm = (state: boolean) => {
        this.protectedForm = state;
    };

    getUserName = () => {
        if (!this.currentUser) return;
        const { firstName, lastName } = this.currentUser as IUserData;
        if (firstName && lastName) {
            return {
                firstName: firstName,
                initials: firstName[0] + lastName[0],
                name: firstName + " " + lastName[0] + ".",
            };
        }

        return;
    };

    modalResetData = () => {
        this.modal = {
            child: null,
            blur: false,
            resetOutside: true,
            size: "",
            noPadding: false,
            enableMobileView: false,
        };
    };

    resetForms = () => {
        this.currentUser = null;
        this.refinanceStep = 0;
        this.loanStep = 0;
        this.formType = null;
        this.skipFetch = false;
        this.id = null;
        this.loanStatus = null;
        this.alreadyHasLoan = null;
        this.refinanceStatus = null;
        this.sessionStarted = false;
        this.protectedForm = false;
        this.fromReview = false;
        this.userHasMissingFields = false;
        this.entryPointUrl = null;
        this.setLoanSchema(null);
        this.setLenderDisclosures(null, "ALL");

        sessionStorage.removeItem("formData");
        sessionStorage.removeItem("loanRequests");
    };

    setLoanSchema = (state: number | null) => {
        this.loanScheme = state;
        if (state && isServer) {
            localStorage.setItem("loanScheme", state.toString());
        } else {
            localStorage.removeItem("loanScheme");
        }
    };

    setModal = (
        child,
        blur = false,
        resetOutside = true,
        size = "",
        noPadding = false,
        enableMobileView = false
    ) => {
        this.modalSetData(child, blur, resetOutside, size, noPadding, enableMobileView);
    };

    modalSetData = (
        child: () => JSX.Element,
        blur: boolean,
        resetOutside: boolean,
        size: string,
        noPadding: boolean,
        enableMobileView: boolean
    ) => {
        this.modal.child = child;
        this.modal.blur = blur;
        this.modal.resetOutside = resetOutside;
        this.modal.size = size;
        this.modal.noPadding = noPadding;
        this.modal.enableMobileView = enableMobileView;
    };

    setSkipFetch = (state: boolean) => {
        this.skipFetch = state;
    };

    setActiveTab = (type: string, tab: string) => {
        this.activeOfferTab[type] = tab;
    };

    // LOGIN AND REGISTER ACTIONS
    saveToStore = (data: any) => {
        this.registerData = data;
    };
    reset = () => {
        this.registerData = null;
        this.loginData = null;
    };

    setSsoUserData = (fields: Partial<UserMissingFieldsType> & { email?: string }) => {
        this.ssoUserData = fields;
    };

    setUserHasMissingFields = (v: boolean) => {
        this.userHasMissingFields = v;
    };

    setLenderDisclosures = (
        disclosures: { [key: string]: any } | null,
        formType: "PSL" | "SLR" | "ALL"
    ) => {
        if (formType === "ALL") {
            this.lenderDisclosures = null;
            return;
        }
        let temp = Object.assign({}, this.lenderDisclosures);
        temp[formType] = disclosures;
        this.lenderDisclosures = temp;
    };

    setEntryPointUrl = (v: string | null) => {
        this.entryPointUrl = v;
    };

    clearGLobStoredDate() {
        stopPersisting(this);
        clearPersistedStore(this);
    }
}
