import { isDevEnvironment } from "../../utils/config";
import { baseUrl, getBaseUrl } from "../services/baseUrl";

export const baseDomainUrl = isDevEnvironment()
    ? "http://localhost:3001"
    : `https://www.${baseUrl}`;
export const cookieDomain = isDevEnvironment() ? "localhost" : `.${getBaseUrl()}`;

export const loginUrlBase = "/login";
export const loginTypeRoutingUrl = "/type";
export const loginBorrowerRoutingUrl = "/borrower";

export const userInclusionBase = "/user-inclusion";
export const userInclusionSelectRoutingUrl = "/select";
export const userInclusionInformationRoutingUrl = "/information";
export const userInclusionManagementRoutingUrl = "/management";
export const userInclusionReviewInviteRoutingUrl = "/review-invite";

export const resetPasswordBase = "/reset-password";
export const confirmEmailLoginBase = "/confirm-login";
export const confirmPasswordBase = "/confirm-password";
export const ssoBase = "/sso-auth";
export const completeSignUpBase = "/complete-signup";
export const confirmEmailBase = "/confirm-email";
export const confirmEmailResetBase = "/confirm-reset";
export const setPreferencesUrl = "/set-preferences";
export const selectUserTypeBase = "/select-user-type";

export const signUpUrlBase = "/sign-up";
export const signUpTypeRoutingUrl = "/type";
export const signUpBorrowerRoutingUrl = "/borrower";
export const signUpSuccessRoutingUrl = "/success";

export const prequalificationBase = "/prequalification";
export const prequalificationAmountRoutingUrl = "/amount";
export const prequalificationCitizenshipRoutingUrl = "/citizenship";
export const prequalificationAddressRoutingUrl = "/address";
export const prequalificationIncomeRoutingUrl = "/income";
export const prequalificationDegreesRoutingUrl = "/degrees";
export const prequalificationReviewRoutingUrl = "/review";
export const prequalificationExpandedSearchRoutingUrl = "/expanded-search";

export const borrowerBase = "/borrower";
export const borrowerProfileRoutingUrl = "/profile";
export const borrowerOffersRoutingUrl = "/rates/prequalification-decision";

export const membershipElectionUrl = "/membership-election";
